import React from 'react'
import { Tab } from '../../../../../fluxio-components/Tabs/BobHorizontalTabs/BobHorizontalTabs'
import ComponentHandler from '../../../../../componentHandler'
import WarningDefaultSystem from '../../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem'
import { handleObjectType } from "../../../../../../../modules/shared-modules/utilities/components"
import { TabsContentProps } from "./interface"

const TabsContent: React.FC<TabsContentProps> = (props) => {
  const renderTabContent = () => {
    let childsId: any = props.obj.children
    if (childsId.length > 0) {
      return childsId.map((child: any, idx: any) => {
        let handledItem = handleObjectType(props.obj, child, props.pageResponse, props.expManager.emEditorType)
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={props.obj.selectedInstanceId}
            key={idx}
            pageResponse={props.pageResponse}
            instanceId={handledItem}
            component={handledItem}
            matchOrder={props.matchOrder + 1}
            expManager={props.expManager}
          />
        )
      })
    } else {
      return renderNoChilds()
    }
  }

  const renderNoChilds = () => {
    /**
     * render no content placeholder
     */
    if (props.expManager.enable) {
      let childs: number = 1
      let components: any[] = []
      for (let i = 0; i < childs; i++) {
        let columns = 12
        components = [
          ...components,
          <div key={i} className={`col-${columns}`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                verticalAlign: "middle",
                height: "100px",
                border: "3px dashed #dbe0e7",
              }}
            >
              <div className='alert warning m-0' role='alert'>
                Add a child component to show content.
              </div>
            </div>
          </div>,
        ]
      }
      return (
        <WarningDefaultSystem>
          <div className='container'>
            <div className={`row`}>{components}</div>
          </div>
        </WarningDefaultSystem>
      )
    }
  }

  return (
    <section className='tabs tabs-content'>
      <Tab
        className=''
        name={props.tabName}
        label={props.tabLabel}
        icon={props.tabIcon}
        eventKey={props.tabEventKey}
        globalStyles={props.pageResponse.globalStyles}
        expManager={props.expManager}
        obj={props.obj}
        symbols={props.symbols}
      >
        {renderTabContent()}
      </Tab>
    </section>
  )
}


export default TabsContent;
