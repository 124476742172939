import React from "react"
import { Site } from "../../../pagesInterfaces/pageName"
import InnerHTML from "dangerously-set-html-content"

interface Props {
  site: Site
}
const InjectBody1Analytics = ({ site }: Props) => {
  const isBody1Unlocked = site.cookieBar?.body1Consent?.enable === true ? false : true
  const [cookieState, setCookieState] = React.useState({
    body1: isBody1Unlocked,
  })

  React.useEffect(() => {
    const handleCookieChange = () => {
      const body1Cookie = `${"cookie-body1-" + window.location.hostname}`
      const cookieString = document.cookie
      const isBody1Accepted = cookieString.includes(body1Cookie)
      if (isBody1Accepted) {
        setCookieState({ body1: isBody1Accepted })
      }
    }

    handleCookieChange() //initial load check
    //@ts-ignore
    if (typeof cookieStore !== "undefined") {
      //@ts-ignore
      cookieStore.addEventListener("change", ({ changed }) => {
        for (const { name, value } of changed) {
          handleCookieChange()
        }
      })
      return () => {
        //@ts-ignore
        cookieStore.removeEventListener("change", handleCookieChange)
      }
    }
  }, [])

  if (site.analyticsInjectBodyTop && cookieState.body1) {
    return <InnerHTML html={site.analyticsInjectBodyTop} />
  }
  return <></>
}

export default InjectBody1Analytics
