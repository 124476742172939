import {
  union,
  Type,
  TypeOf,
  intersection,
  literal,
  null as nullC,
  number as numberC,
  string as stringC,
  partial,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { ColorLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import { handleBobStylesheetLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const ThicknessUnitCodec = union([literal("auto"), literal("from-font"), literal("px"), literal("%")])
const LineCodec = union([
  literal("none"),
  literal("underline"),
  literal("overline"),
  literal("line-through"),
  literal("blink"),
])
const StyleCodec = union([literal("solid"), literal("double"), literal("dotted"), literal("dashed"), literal("wavy")])
const SkipInkCodec = union([literal("auto"), literal("all"), literal("none")])
const ThicknessCodec = partial({ value: nullable(numberC), unit: nullable(ThicknessUnitCodec) })

const TextDecorationOptCodec = partial({
  color: nullable(stringC),
  line: nullable(LineCodec),
  style: nullable(StyleCodec),
  thickness: ThicknessCodec,
  skipInk: nullable(SkipInkCodec),
})

const StylesTextDecorationCodec = intersection([
  partial({ textDecorationV2: TextDecorationOptCodec }),
  partial({
    behaviour: partial({
      active: partial({ textDecorationV2: TextDecorationOptCodec }),
      hover: partial({ textDecorationV2: TextDecorationOptCodec }),
    }),
    mobile: intersection([
      partial({ textDecorationV2: TextDecorationOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ textDecorationV2: TextDecorationOptCodec }),
          hover: partial({ textDecorationV2: TextDecorationOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ textDecorationV2: TextDecorationOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ textDecorationV2: TextDecorationOptCodec }),
          hover: partial({ textDecorationV2: TextDecorationOptCodec }),
        }),
      }),
    ]),
  }),
])

type TextDecorationOpt = TypeOf<typeof TextDecorationOptCodec>
type StylesTextDecoration = TypeOf<typeof StylesTextDecorationCodec>
type TextDecorationThickness = TypeOf<typeof ThicknessCodec>
type TextDecorationCSS = {
  "text-decoration-color": string
  "text-decoration-line": string
  "text-decoration-style": string
  "text-decoration-thickness": string
  "text-decoration-skip-ink": string
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  stylesheetLabel: ColorLabel | undefined
): Partial<TextDecorationCSS> {
  const styles = StylesTextDecorationCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, stylesheetLabel)
  console.warn(breakpoint, behaviourState, decoderErrors(styles))
  return {}
}

export function cssRender(
  stylesObj: StylesTextDecoration,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  stylesheetLabel: ColorLabel | undefined
): Partial<TextDecorationCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.textDecorationV2, stylesheetLabel)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.textDecorationV2,
        stylesObj?.behaviour?.[behaviourState]?.textDecorationV2,
        stylesheetLabel
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.textDecorationV2, stylesObj?.[breakpoint]?.textDecorationV2, stylesheetLabel)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.textDecorationV2,
        stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.textDecorationV2,
        stylesheetLabel
      )
    }
  }
}

export function mergedValue(
  desktopValue: number | undefined | null,
  value: number | undefined | null
): number | undefined | null {
  return value || desktopValue
}

export function checkForValidThicknessOptFallBack(
  desktopThickness: TextDecorationOpt["thickness"],
  thickness: TextDecorationOpt["thickness"]
): boolean | string {
  if (!desktopThickness && !thickness) return false

  if (thickness?.unit === "auto" || thickness?.unit === "from-font") return thickness.unit

  const mergedThicknessValue = mergedValue(desktopThickness?.value, thickness?.value)
  if ((thickness?.unit === "%" || thickness?.unit === "px") && mergedThicknessValue)
    return `${mergedThicknessValue}${thickness.unit}`

  return false
}

export function checkForValidThicknessOpt(thickness: TextDecorationOpt["thickness"]): boolean | string {
  if (!thickness) return false

  if (thickness.unit === "auto" || thickness.unit === "from-font") return thickness.unit

  if ((thickness.unit === "%" || thickness.unit === "px") && thickness.value)
    return `${thickness.value}${thickness.unit}`

  return false
}

export function renderBob(
  textDecorationObj: TextDecorationOpt | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<TextDecorationCSS> {
  const thicknessExists = checkForValidThicknessOpt(textDecorationObj?.thickness)

  let css = {}
  if (valueExists(textDecorationObj?.color || stylesheetLabel))
    css = { ...css, "text-decoration-color": handleBobStylesheetLabel(stylesheetLabel, textDecorationObj?.color) }
  if (valueExists(textDecorationObj?.line)) css = { ...css, "text-decoration-line": textDecorationObj?.line }
  if (valueExists(textDecorationObj?.style)) css = { ...css, "text-decoration-style": textDecorationObj?.style }
  if (thicknessExists) css = { ...css, "text-decoration-thickness": thicknessExists }
  if (valueExists(textDecorationObj?.skipInk)) css = { ...css, "text-decoration-skip-ink": textDecorationObj?.skipInk }

  return css
}

export function renderBobOpt(
  textDecorationDesktopObj: TextDecorationOpt | undefined,
  textDecorationObj: TextDecorationOpt | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<TextDecorationCSS> {
  const thicknessExists = checkForValidThicknessOptFallBack(
    textDecorationDesktopObj?.thickness,
    textDecorationObj?.thickness
  )

  let css = {}
  if (valueExists(textDecorationObj?.color || stylesheetLabel))
    css = { ...css, "text-decoration-color": handleBobStylesheetLabel(stylesheetLabel, textDecorationObj?.color) }
  if (valueExists(textDecorationObj?.line)) css = { ...css, "text-decoration-line": textDecorationObj?.line }
  if (valueExists(textDecorationObj?.style)) css = { ...css, "text-decoration-style": textDecorationObj?.style }
  if (thicknessExists) css = { ...css, "text-decoration-thickness": thicknessExists }
  if (valueExists(textDecorationObj?.skipInk)) css = { ...css, "text-decoration-skip-ink": textDecorationObj?.skipInk }

  return css
}

export type { TextDecorationCSS, StylesTextDecoration, TextDecorationOpt, TextDecorationThickness }
