import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  string as stringC,
  partial,
  boolean as booleanC,
  literal,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { get2WithNull, get2WithNull4Enable, get3WithNull4Enable } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */

// props only, no enable
const VideoPropsCodec = type({
  enable: booleanC,
  size: union([literal("cover"), literal("contain"), literal("auto")]),
  position: stringC,
})
const VideoCodec = intersection([type({ enable: booleanC }), VideoPropsCodec])

// props only, no enable
const VideoPropsOptCodec = partial({
  size: nullable(union([literal("cover"), literal("contain"), literal("auto")])),
  position: nullable(stringC),
})
const VideoOptCodec = intersection([partial({ enable: nullable(booleanC) }), VideoPropsOptCodec])

const StylesVideoCodec = intersection([
  type({ video: VideoCodec }),
  partial({
    behaviour: partial({
      active: partial({
        video: VideoOptCodec,
      }),
      hover: partial({
        video: VideoOptCodec,
      }),
    }),
    mobile: partial({
      video: VideoOptCodec,
      behaviour: partial({
        active: partial({
          video: VideoOptCodec,
        }),
        hover: partial({
          video: VideoOptCodec,
        }),
      }),
    }),
    tablet: partial({
      video: VideoOptCodec,
      behaviour: partial({
        active: partial({
          video: VideoOptCodec,
        }),
        hover: partial({
          video: VideoOptCodec,
        }),
      }),
    }),
  }),
])

const GSVideoCodec = intersection([
  VideoPropsCodec,
  partial({
    behaviour: partial({
      active: VideoPropsOptCodec,
      hover: VideoPropsOptCodec,
    }),
    mobile: intersection([
      VideoPropsOptCodec,
      partial({
        behaviour: partial({
          active: VideoPropsOptCodec,
          hover: VideoPropsOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      VideoPropsOptCodec,
      partial({
        behaviour: partial({
          active: VideoPropsOptCodec,
          hover: VideoPropsOptCodec,
        }),
      }),
    ]),
  }),
])

type Video = TypeOf<typeof VideoCodec>
type VideoProps = TypeOf<typeof VideoPropsCodec>
type VideoOpt = TypeOf<typeof VideoOptCodec>
type VideoPropsOpt = TypeOf<typeof VideoPropsOptCodec>
type StylesVideo = TypeOf<typeof StylesVideoCodec>
type GSVideo = TypeOf<typeof GSVideoCodec>
type VideoCSS = { "object-fit": string; "object-position": string }

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined // TODO: fix when working on other bobs
): Partial<VideoCSS> {
  const styles = StylesVideoCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, foundationStyle)
  console.warn(decoderErrors(styles))
  return {}
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined // TODO: Work on when foundation is added to global styles
): Partial<VideoCSS> {
  const gs = GSVideoCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, foundationStyle)
  console.warn(decoderErrors(gs))
  return {}
}

export function cssRender(
  stylesObj: StylesVideo,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined
): Partial<VideoCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.video, foundationStyle)
    }
    //hover | active
    else {
      return renderBobOpt(mergeBob2(stylesObj?.behaviour?.[behaviourState]?.video, stylesObj.video), foundationStyle)
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(mergeBob2(stylesObj?.[breakpoint]?.video, stylesObj.video), foundationStyle)
    }
    //hover | active
    else {
      return renderBobOpt(
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.video,
          stylesObj?.behaviour?.[behaviourState]?.video,
          stylesObj?.[breakpoint]?.video,
          stylesObj.video
        ),
        foundationStyle
      )
    }
  }
}

export function globalStyleCssRender(
  stylesObj: GSVideo,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined
): Partial<VideoCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj, foundationStyle)
    }
    //hover | active
    else {
      return renderOpt(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj), foundationStyle)
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(merge2(stylesObj?.[breakpoint], stylesObj), foundationStyle)
    }
    //hover | active
    else {
      return renderOpt(
        merge3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
          stylesObj.behaviour?.[behaviourState],
          stylesObj?.[breakpoint],
          stylesObj
        ),
        foundationStyle
      )
    }
  }
}

export function renderBob(videoObj: Video, foundationStyle: FColor | undefined): Partial<VideoCSS> {
  return render(videoObj, foundationStyle)
}

export function renderBobOpt(videoObj: VideoOpt, foundationStyle: FColor | undefined): Partial<VideoCSS> {
  if (videoObj) return renderOpt(videoObj, foundationStyle)

  return {}
}

export function render(videoObj: VideoProps, foundationStyle: FColor | undefined): VideoCSS {
  return {
    "object-position": videoObj.position,
    "object-fit": videoObj.size,
  }
}

export function renderOpt(videoObj: VideoPropsOpt, foundationStyle: FColor | undefined): Partial<VideoCSS> {
  let css = {}
  if (valueExists(videoObj?.position)) css = { ...css, "object-position": videoObj?.position }
  if (valueExists(videoObj?.size)) css = { ...css, "object-fit": videoObj?.size }

  return css
}

export function cssProperty(cssValue: string): VideoCSS {
  return { "object-fit": cssValue, "object-position": cssValue }
}

/**
 *
 * @param videoObj
 * @param defaultVideoObj
 * @returns Video
 *
 */
export function mergeBob2(videoObj: VideoOpt | undefined, defaultVideoObj: Video): VideoOpt {
  const enable = get2WithNull4Enable(videoObj?.enable, defaultVideoObj.enable)

  return {
    enable,
    position: videoObj?.position,
    size: videoObj?.size,
  }
}

/**
 *
 * @param videoObj
 * @param defaultVideoObj
 * @returns VideoProps
 *
 */
export function merge2(videoObj: VideoPropsOpt | undefined, defaultVideoObj: VideoProps): VideoPropsOpt {
  const position = get2WithNull(videoObj?.position, defaultVideoObj.position)
  const size = get2WithNull(videoObj?.size, defaultVideoObj.size)

  return {
    position,
    size,
  }
}

/**
 *
 * @param videoObj
 * @param videoDefaultBreakpoint
 * @param defaultVideoObj
 * @returns Video
 */
export function mergeBob3(
  videoObj: VideoOpt | undefined,
  videoDesktopBehaviour: VideoOpt | undefined,
  videoDefaultBreakpoint: VideoOpt | undefined,
  defaultVideoObj: Video
): VideoOpt {
  const enable = get3WithNull4Enable(
    videoObj?.enable,
    videoDesktopBehaviour?.enable,
    videoDefaultBreakpoint?.enable,
    defaultVideoObj.enable
  )

  return {
    enable,
    position: videoObj?.position,
    size: videoObj?.size,
  }
}

/**
 *
 * @param videoObj
 * @param videoDefaultBreakpoint
 * @param defaultVideoObj
 * @returns Video
 */
export function merge3(
  videoObj: VideoPropsOpt | undefined,
  videoDesktopBehaviour: VideoPropsOpt | undefined,
  videoDefaultBreakpoint: VideoPropsOpt | undefined,
  defaultVideoObj: VideoProps
): VideoPropsOpt {
  const position =
    videoObj?.position ??
    videoDesktopBehaviour?.position ??
    videoDefaultBreakpoint?.position ??
    defaultVideoObj.position
  const size = videoObj?.size ?? videoDesktopBehaviour?.size ?? videoDefaultBreakpoint?.size ?? defaultVideoObj.size

  return {
    position,
    size,
  }
}

export type { VideoCSS, StylesVideo, GSVideo, VideoProps, VideoPropsOpt, Video, VideoOpt }
