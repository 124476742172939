import { FontType } from "../../../areas/public-page/shared-public-page/bob/bobStyles/textStyle"

type ErrorMsg = { msg: string }

export function validHtml(value: string) {
  return /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(
    value
  )
}

export function validateNumber(value: string): ErrorMsg | number {
  const v = parseFloat(value)
  return isNaN(v) ? { msg: `Invalid Number ${value}` } : v
}

export function orElse<A>(a: ErrorMsg | A, onError: () => A): A {
  return isError(a) ? onError() : a
}

export function isError<A>(a: ErrorMsg | A): a is ErrorMsg {
  return a != null && typeof a === "object" && "msg" in a
}

export function validateFontType(value: string): ErrorMsg | FontType {
  if (value === "google-fonts") return "google-fonts"
  else if (value === "custom") return "custom"
  else return { msg: `Invalid font type ${value}` }
}

export type { ErrorMsg }
