import React, { Fragment } from "react"
import {
  handleImageFilter,
  handleRadius,
  getOverrideInlineContentValues,
  getMediaStringField,
  getResponsiveOverrideInlineContentValues,
  datasetRender,
  getOverrideInlineContentString,
} from "../../../../../modules/shared-modules/utilities/components"
// @ts-ignore
import Style from "style-it"
import DangerousHtml from "../../dangerousHtml/dangerousHtml"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { handleDOMid } from "../../../../../modules/shared-modules/utilities/symbols"
import { responsiveProperties, responsiveGroupStylesProperties } from "../bobHandler"
import styled from "styled-components"
import { ExpManager, PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { bobMediaRenderReadFix as Deprecated_bobMediaRenderReadFix } from "../../../../../modules/shared-modules/deprecatedHandlers"
import CustomMediaPlayer from "./customMediaPlayer"
import GlobalStylesUtils from "../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { GS_BOUNDARY, GS_FILTER, GS_SHADOW } from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import { boxShadowCss as ShadowStyleBoxShadowCss } from "../bobStyles/shadowStyle"
import { renderCSSString as renderCSSStringBoundary } from "../bobStyles/boundaryStyle"
import {
  conditionalBoundaryRenderUnsafe as cssRenderUnsafeBoundary,
  conditionalShadowRenderUnsafe,
  conditionalBorderRenderUnsafe as cssRenderUnsafeBorder,
  conditionalBorderCss as renderCSSStringBorder,
  conditionalColorsRenderUnsafe as cssRenderUnsafeColors,
  alignmentWithAP,
} from "../../../../../modules/shared-modules/utilities/conditionalController"
import { ComponentSpecificAlignment, AlignmentCssRenderUnsafe } from "../bobStyles/alignmentStyle"
import {
  CMS,
  CONTENT_PAGE,
  WRITE,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/textController/textContentHelper"
import { DummyPost } from "../../../../../modules/shared-modules/experienceManager/finder/content/contentPageHelper"
import BobLoading from "../bobLoading"
import { loadThreshold, getComposedUuid } from "../../../../../modules/shared-modules/utilities/utils"
import { MediaObject } from "../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import {
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { cssRenderUnsafe as WidthStyleCssRenderUnsafe } from "../bobStyles/widthStyle"
import { cssRenderUnsafe as cssRenderUnsafeHeight } from "../bobStyles/heightStyle"
import { analyticsLinkLabel, getLinkAttributes } from "../bobUtils"
import { GoogleEvent } from "../../analytics/analytics"
import { Breakpoint } from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import VideoPlayerSplit from "../../../../../split/shared-public-page/bobMediaVideoPlayer/videoPlayerSplit"
import {
  handleSrcLink,
  handleMediaSrc,
  handleMediaFields,
} from "../../../../../modules/shared-modules/mediaLibrary/mediaLibraryUtils"
import { renderCSSString as renderCSSStringColors } from "../bobStyles/colorsStyle"
import * as inlineContentTypes from "../../../../../modules/shared-modules/experienceManager/finder/content/inlineContent/inlineContentTypes"
import { RawUrl, mkRawUrl } from "../../../../../api/shared-api/types/cmsContentTypes"

type BobMediaState = {
  isHover: boolean
}

interface BobMediaProps {
  id: string
  className?: string
  rule: any
  template: any
  object: SingleObject
  globalStyles: any
  pageResponse: PageTypes
  replacedMediaList?: { [id: string]: MediaObject }
  instanceId: string | undefined
  post?: Post
  src?: string
  inlineContent: ObjectContent["inlineContent"]
  overrides?: ObjectContentOverride
  field: string
  expManager: ExpManager
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

interface BobMediaStyledProps {
  desktop: any
  tablet: any
  mobile: any
}

const BobMediaOuterContainer: any = styled.div`
  ${(props: BobMediaStyledProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: BobMediaStyledProps) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: BobMediaStyledProps) => props.mobile}
  }
`

const BobMediaDiv: any = styled.div`
  ${(props: BobMediaStyledProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: BobMediaStyledProps) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: BobMediaStyledProps) => props.mobile}
  }
`

const BobMediaImg: any = styled.div`
  ${(props: BobMediaStyledProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: BobMediaStyledProps) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: BobMediaStyledProps) => props.mobile}
  }
`

const BobMediaFilterOverlay: any = styled.div`
  ${(props: BobMediaStyledProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: BobMediaStyledProps) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: BobMediaStyledProps) => props.mobile}
  }
`

const EmbedContainer: any = styled.div`
  ${(props: BobMediaStyledProps["desktop"]) => props.styles}
`
class BobMedia extends React.Component<BobMediaProps, BobMediaState> {
  constructor(props: BobMediaProps) {
    super(props)
    this.state = {
      isHover: false,
    }
  }

  handleActiveStateStyles(
    defaultTemplate: any,
    templateObj: any,
    templateObjMobile: any,
    templateObjTablet: any,
    classPrefix: string
  ) {
    if (templateObj) {
      const widthTabletActiveStyle = WidthStyleCssRenderUnsafe(defaultTemplate, "tablet", "active")
      const widthMobileActiveStyle = WidthStyleCssRenderUnsafe(defaultTemplate, "mobile", "active")
      const heightTabletActiveStyle = cssRenderUnsafeHeight(defaultTemplate, "tablet", "active")
      const heightMobileActiveStyle = cssRenderUnsafeHeight(defaultTemplate, "mobile", "active")
      // Properties for BobImage
      let bobMediaImgProperties = ["radius", "filter", "size", "position"]
      let templateMobile: any = responsiveProperties(
        { mobile: templateObj.mobile?.behaviour?.active },
        "mobile",
        {},
        [""],
        bobMediaImgProperties
      )
      templateMobile = {
        ...templateMobile,
        ...conditionalShadowRenderUnsafe(defaultTemplate, "mobile", "active", "box", undefined),
        ...cssRenderUnsafeColors(defaultTemplate, "mobile", "active", true),
        ...widthMobileActiveStyle,
        ...heightMobileActiveStyle,
      }
      let templateTablet: any = responsiveProperties(
        { tablet: templateObj.tablet?.behaviour?.active },
        "tablet",
        {},
        [""],
        bobMediaImgProperties
      )
      templateTablet = {
        ...templateTablet,
        ...conditionalShadowRenderUnsafe(defaultTemplate, "tablet", "active", "box", undefined),
        ...cssRenderUnsafeColors(defaultTemplate, "tablet", "active", true),
        ...widthTabletActiveStyle,
        ...heightTabletActiveStyle,
      }
      const alignmentAdittionalProperties = {
        flexDirection: "row",
        self: false,
      } as ComponentSpecificAlignment
      // Properties for BobImage container
      const bobImageProperties = ["width", "height", "radius", "border"]
      let containerTemplateMobile: any = responsiveProperties(
        { mobile: templateObj.mobile?.behaviour?.active },
        "mobile",
        {},
        [""],
        bobImageProperties
      )
      containerTemplateMobile = {
        ...containerTemplateMobile,
        ...AlignmentCssRenderUnsafe(defaultTemplate, "mobile", "active", alignmentAdittionalProperties),
        ...cssRenderUnsafeBoundary(defaultTemplate, "mobile", "active"),
        ...cssRenderUnsafeBorder(defaultTemplate, "mobile", "active", true),
      }
      let containerTemplateTablet: any = responsiveProperties(
        { tablet: templateObj.tablet?.behaviour?.active },
        "tablet",
        {},
        [""],
        bobImageProperties
      )
      containerTemplateTablet = {
        ...containerTemplateTablet,
        ...AlignmentCssRenderUnsafe(defaultTemplate, "tablet", "active", alignmentAdittionalProperties),
        ...cssRenderUnsafeBoundary(defaultTemplate, "tablet", "active"),
        ...cssRenderUnsafeBorder(defaultTemplate, "tablet", "active", true),
      }
      const mediaQueryRestrictions = this.handleMediaQueryRestrictions(templateObjMobile, templateObjTablet)
      const backgroundColorStyles = cssRenderUnsafeColors(defaultTemplate, "desktop", "active", true)
      const heightStyles = cssRenderUnsafeHeight(defaultTemplate, "desktop", "active")
      const widthStyles = WidthStyleCssRenderUnsafe(defaultTemplate, "desktop", "active")
      const alignmentStyles = AlignmentCssRenderUnsafe(
        defaultTemplate,
        "desktop",
        "active",
        alignmentAdittionalProperties
      )
      const boundaries = cssRenderUnsafeBoundary(defaultTemplate, "desktop", "active")
      const borders = cssRenderUnsafeBorder(defaultTemplate, "desktop", "active", true)
      const shadow = conditionalShadowRenderUnsafe(defaultTemplate, "desktop", "active", "box", undefined)
      const imageFilterStyles = handleImageFilter(templateObj, defaultTemplate)
      const radiusStyles = handleRadius(templateObj, defaultTemplate)
      return `
      ${
        templateObj.enable
          ? `@media all ${mediaQueryRestrictions}{
            .active .${classPrefix}-bob-media{                      
              justify-content: ${alignmentStyles["justify-content"]};
              align-items: ${alignmentStyles["align-items"]};
              align-self: ${alignmentStyles["align-self"]}; 
              display: ${alignmentStyles["display"]};
              flex-direction: ${alignmentStyles["flex-direction"]};
            }
            .active .${classPrefix}-bob-image{                      
              width: ${widthStyles.width};
              height: ${heightStyles.height};
              ${renderCSSStringBoundary(boundaries)}    
              ${renderCSSStringBorder(borders, this.props.template.controllerVersion?.border)}
              border-bottom-left-radius: ${radiusStyles.borderBottomLeftRadius}; 
              border-bottom-right-radius: ${radiusStyles.borderBottomRightRadius}; 
              border-top-left-radius: ${radiusStyles.borderTopLeftRadius}; 
              border-top-right-radius: ${radiusStyles.borderTopRightRadius}; 
            }
            .active .${classPrefix}-bob-image img,
            .active .${classPrefix}-bob-image picture{
              ${renderCSSStringColors(backgroundColorStyles)}  
              object-fit: ${templateObj.size ? templateObj.size + " !important" : ""};
              object-position: ${templateObj.position ? templateObj.position + " !important" : ""};
              max-width: ${this.handleImgSizePropInPercentage(widthStyles.width)};
              max-height: ${this.handleImgSizePropInPercentage(heightStyles.height)};
              filter: ${imageFilterStyles.filter.filter};
              webkit-filter: ${imageFilterStyles.filter.WebkitFilter};
              ${ShadowStyleBoxShadowCss(shadow)}
              border-bottom-left-radius: ${radiusStyles.borderBottomLeftRadius}; 
              border-bottom-right-radius: ${radiusStyles.borderBottomRightRadius}; 
              border-top-left-radius: ${radiusStyles.borderTopLeftRadius}; 
              border-top-right-radius: ${radiusStyles.borderTopRightRadius}; 
            }
            .active .${classPrefix}-bob-image .image-filter-overlay{
              background-color: ${imageFilterStyles.overlay.backgroundColor};
            }
          }`
          : ""
      }
      ${
        (templateObj.enable || templateObjTablet?.enable) && templateObjTablet?.enable !== false
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            .active .${classPrefix}-bob-media{                      
              justify-content: ${templateTablet["justify-Content"]};
              align-items: ${templateTablet["align-items"]};
              align-self: ${templateTablet["align-self"]}; 
              display: ${templateTablet["display"]};
              flex-direction: ${templateTablet["flex-direction"]};
            }
            .active .${classPrefix}-bob-image{  
              width: ${containerTemplateTablet.width};
              height: ${containerTemplateTablet.height};
              ${renderCSSStringBoundary(containerTemplateTablet)}       
              ${renderCSSStringBorder(containerTemplateTablet, this.props.template.controllerVersion?.border)}
              border-bottom-left-radius: ${containerTemplateTablet["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${containerTemplateTablet["border-bottom-right-radius"]}; 
              border-top-left-radius: ${containerTemplateTablet["border-top-left-radius"]}; 
              border-top-right-radius: ${containerTemplateTablet["border-top-left-radius"]}; 
            }
            .active .${classPrefix}-bob-image img,
            .active .${classPrefix}-bob-image picture{
              ${renderCSSStringColors(templateTablet)}  
              object-fit: ${templateTablet["object-fit"]};
              object-position: ${templateTablet["object-position"]};
              max-width: ${this.handleImgSizePropInPercentage(templateTablet.width)};
              max-height: ${this.handleImgSizePropInPercentage(templateTablet.height)};
              filter: ${templateTablet.filter?.filter};
              ${ShadowStyleBoxShadowCss(templateTablet)}
              border-bottom-left-radius: ${templateTablet["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${templateTablet["border-bottom-right-radius"]}; 
              border-top-left-radius: ${templateTablet["border-top-left-radius"]}; 
              border-top-right-radius: ${templateTablet["border-top-left-radius"]}; 
            }
            .active .${classPrefix}-bob-image .image-filter-overlay{
              background-color: ${templateTablet["background-color"]};
            }
          }`
          : ""
      }
      ${
        (templateObj.enable || templateObjMobile?.enable) && templateObjMobile?.enable !== false
          ? `@media all and (max-width: 766px){
            .active .${classPrefix}-bob-media{                      
              justify-content: ${templateTablet["justify-Content"]};
              align-items: ${templateTablet["align-items"]};
              align-self: ${templateTablet["align-self"]}; 
              display: ${templateTablet["display"]};
              flex-direction: ${templateTablet["flex-direction"]};
            }
            .active .${classPrefix}-bob-image{  
              width: ${containerTemplateMobile.width};
              height: ${containerTemplateMobile.height};
              ${renderCSSStringBoundary(containerTemplateMobile)}
              ${renderCSSStringBorder(containerTemplateMobile, this.props.template.controllerVersion?.border)}
              border-bottom-left-radius: ${containerTemplateMobile["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${containerTemplateMobile["border-bottom-right-radius"]}; 
              border-top-left-radius: ${containerTemplateMobile["border-top-left-radius"]}; 
              border-top-right-radius: ${containerTemplateMobile["border-top-left-radius"]}; 
            }
            .active .${classPrefix}-bob-image img,
            .active .${classPrefix}-bob-image picture{
              ${renderCSSStringColors(templateMobile)}  
              object-fit: ${templateMobile["object-fit"]};
              object-position: ${templateMobile["object-position"]};
              max-width: ${this.handleImgSizePropInPercentage(templateMobile.width)};
              max-height: ${this.handleImgSizePropInPercentage(templateMobile.height)};
              filter: ${templateMobile.filter?.filter};
              ${ShadowStyleBoxShadowCss(templateMobile)}
              border-bottom-left-radius: ${templateMobile["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${templateMobile["border-bottom-right-radius"]}; 
              border-top-left-radius: ${templateMobile["border-top-left-radius"]}; 
              border-top-right-radius: ${templateMobile["border-top-left-radius"]}; 
            }
            .active .${classPrefix}-bob-image .image-filter-overlay{
              background-color: ${templateMobile["background-color"]};
            }
          }`
          : ""
      }
      `
    }
    return ""
  }

  handleSize(size: { enable: boolean; value: number; unit: string }): string | undefined {
    if (size.enable) {
      return `${size.value}${size.unit}`
    }
    return undefined
  }

  /**
   * TODO: check for behaviour status for each breakpoint before computing logic
   * @param templateObj
   * @param templateObjMobile
   * @param templateObjTablet
   * @param classPrefix
   */
  handleHoverStateStyles(
    defaultTemplate: any,
    templateObj: any,
    templateObjMobile: any,
    templateObjTablet: any,
    classPrefix: string
  ) {
    if (templateObj) {
      const widthTabletActiveStyle = WidthStyleCssRenderUnsafe(defaultTemplate, "tablet", "hover")
      const widthMobileActiveStyle = WidthStyleCssRenderUnsafe(defaultTemplate, "mobile", "hover")
      const heightTabletActiveStyle = cssRenderUnsafeHeight(defaultTemplate, "tablet", "hover")
      const heightMobileActiveStyle = cssRenderUnsafeHeight(defaultTemplate, "mobile", "hover")
      const alignmentAdittionalProperties = {
        flexDirection: "column",
        self: false,
      } as ComponentSpecificAlignment
      let templateMobile: any = responsiveProperties(
        { mobile: templateObjMobile },
        "mobile",
        { shadow: { shadowType: "text" } },
        [""],
        ["behaviour"]
      )
      templateMobile = {
        ...templateMobile,
        ...AlignmentCssRenderUnsafe(defaultTemplate, "mobile", "hover", alignmentAdittionalProperties),
        ...conditionalShadowRenderUnsafe(defaultTemplate, "mobile", "hover", "box", undefined),
        ...cssRenderUnsafeBoundary(defaultTemplate, "mobile", "hover"),
        ...cssRenderUnsafeBorder(defaultTemplate, "mobile", "hover", true),
        ...cssRenderUnsafeColors(defaultTemplate, "mobile", "hover", true),
        ...responsiveGroupStylesProperties({ mobile: templateObjMobile }, "mobile", "text"),
        ...widthMobileActiveStyle,
        ...heightMobileActiveStyle,
      }
      let templateTablet: any = responsiveProperties(
        { tablet: templateObjTablet },
        "tablet",
        { shadow: { shadowType: "text" } },
        [""],
        ["behaviour"]
      )
      templateTablet = {
        ...templateTablet,
        ...AlignmentCssRenderUnsafe(defaultTemplate, "tablet", "hover", alignmentAdittionalProperties),
        ...conditionalShadowRenderUnsafe(defaultTemplate, "tablet", "hover", "box", undefined),
        ...cssRenderUnsafeBoundary(defaultTemplate, "tablet", "hover"),
        ...cssRenderUnsafeBorder(defaultTemplate, "tablet", "hover", true),
        ...cssRenderUnsafeColors(defaultTemplate, "tablet", "hover", true),
        ...responsiveGroupStylesProperties({ tablet: templateObjTablet }, "tablet", "text"),
        ...widthTabletActiveStyle,
        ...heightTabletActiveStyle,
      }

      const mediaQueryRestrictions = this.handleMediaQueryRestrictions(templateObjMobile, templateObjTablet)
      const backgroundColorStyles = cssRenderUnsafeColors(defaultTemplate, "desktop", "hover", true)
      const heightStyles = cssRenderUnsafeHeight(defaultTemplate, "desktop", "hover")
      const widthStyles = WidthStyleCssRenderUnsafe(defaultTemplate, "desktop", "hover")
      const alignmentStyles = AlignmentCssRenderUnsafe(
        defaultTemplate,
        "desktop",
        "hover",
        alignmentAdittionalProperties
      )
      const boundaries = cssRenderUnsafeBoundary(defaultTemplate, "desktop", "hover")
      const borders = cssRenderUnsafeBorder(defaultTemplate, "desktop", "hover", true)
      const shadow = conditionalShadowRenderUnsafe(defaultTemplate, "desktop", "hover", "box", undefined)
      const imageFilterStyles = handleImageFilter(templateObj, defaultTemplate)
      const radiusStyles = handleRadius(templateObj, defaultTemplate)

      return `
      ${
        templateObj.enable
          ? `@media all ${mediaQueryRestrictions}{ 
            ${classPrefix}-bob-media:hover{                      
              justify-content: ${alignmentStyles["justify-content"]};
              align-items: ${alignmentStyles["align-items"]};
              align-self: ${alignmentStyles["align-self"]}; 
              display: ${alignmentStyles.display};
              flex-direction: ${alignmentStyles["flex-direction"]};
            }
            ${classPrefix}-bob-image:hover, 
            ${classPrefix}-bob-image.isHover, 
            ${classPrefix}-bob-media.isHover{                      
              width: ${widthStyles.width};
              height: ${heightStyles.height};
              ${renderCSSStringBoundary(boundaries)}
              ${renderCSSStringBorder(borders, this.props.template.controllerVersion?.border)}
              border-bottom-left-radius: ${radiusStyles.borderBottomLeftRadius}; 
              border-bottom-right-radius: ${radiusStyles.borderBottomRightRadius}; 
              border-top-left-radius: ${radiusStyles.borderTopLeftRadius}; 
              border-top-right-radius: ${radiusStyles.borderTopRightRadius}; 
            }
            ${classPrefix}-bob-image:hover img,
            ${classPrefix}-bob-image:hover picture,
            ${classPrefix}-bob-image.isHover img,
            ${classPrefix}-bob-image.isHover picture,
            ${classPrefix}-bob-media.isHover picture,
            ${classPrefix}-bob-media.isHover img{
              ${renderCSSStringColors(backgroundColorStyles)}  
              object-fit: ${templateObj.size ? templateObj.size + " !important" : ""};
              object-position: ${templateObj.position ? templateObj.position + " !important" : ""};
              max-width: ${this.handleImgSizePropInPercentage(widthStyles.width)};
              max-height: ${this.handleImgSizePropInPercentage(heightStyles.height)};
              filter: ${imageFilterStyles.filter.filter};
              webkit-filter: ${imageFilterStyles.filter.WebkitFilter};
              ${ShadowStyleBoxShadowCss(shadow)}
              border-bottom-left-radius: ${radiusStyles.borderBottomLeftRadius}; 
              border-bottom-right-radius: ${radiusStyles.borderBottomRightRadius}; 
              border-top-left-radius: ${radiusStyles.borderTopLeftRadius}; 
              border-top-right-radius: ${radiusStyles.borderTopRightRadius};
            }
            ${classPrefix}-bob-image:hover .image-filter-overlay{
              background-color: ${imageFilterStyles.overlay.backgroundColor};
            }
          }`
          : ""
      }
      ${
        (templateObj.enable || templateTablet?.enable) && templateTablet?.enable !== false
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${classPrefix}-bob-media:hover{                      
              justify-content: ${templateTablet["justify-Content"]};
              align-items: ${templateTablet["align-items"]};
              align-self: ${templateTablet["align-self"]}; 
              display: ${templateTablet["display"]};
              flex-direction: ${templateTablet["flex-direction"]};
            }
            ${classPrefix}-bob-image:hover, 
            ${classPrefix}-bob-image.isHover, 
            ${classPrefix}-bob-media.isHover{                 
              width: ${templateTablet.width};
              height: ${templateTablet.height};              
              ${renderCSSStringBoundary(templateTablet)}
              ${renderCSSStringBorder(templateTablet, this.props.template.controllerVersion?.border)}
              border-bottom-left-radius: ${templateTablet["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${templateTablet["border-bottom-right-radius"]}; 
              border-top-left-radius: ${templateTablet["border-top-left-radius"]}; 
              border-top-right-radius: ${templateTablet["border-top-left-radius"]}; 
            }
            ${classPrefix}-bob-image:hover img,
            ${classPrefix}-bob-image:hover picture,
            ${classPrefix}-bob-image.isHover img,
            ${classPrefix}-bob-image.isHover picture,
            ${classPrefix}-bob-media.isHover picture,
            ${classPrefix}-bob-media.isHover img{
              ${renderCSSStringColors(templateTablet)}  
              object-fit: ${templateTablet.size} !important;
              object-position: ${templateTablet.position} !important;              
              border-bottom-left-radius: ${templateTablet["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${templateTablet["border-bottom-right-radius"]}; 
              border-top-left-radius: ${templateTablet["border-top-left-radius"]}; 
              border-top-right-radius: ${templateTablet["border-top-left-radius"]}; 
              filter: ${templateTablet.filter?.filter};
              ${ShadowStyleBoxShadowCss(templateTablet)}
            }
            ${classPrefix}-bob-image:hover .image-filter-overlay{
              background-color: ${templateTablet["background-color"]};
            }
          }`
          : ""
      }
      ${
        (templateObj.enable || templateMobile?.enable) && templateMobile?.enable !== false
          ? `@media all and (max-width: 766px){
            ${classPrefix}-bob-media:hover{                      
              justify-content: ${templateMobile["justify-Content"]};
              align-items: ${templateMobile["align-items"]};
              align-self: ${templateMobile["align-self"]}; 
              display: ${templateMobile["display"]};
              flex-direction: ${templateMobile["flex-direction"]};
            }
            ${classPrefix}-bob-image:hover, 
            ${classPrefix}-bob-image.isHover, 
            ${classPrefix}-bob-media.isHover{  
              width: ${templateMobile.width};
              height: ${templateMobile.height};
              ${renderCSSStringBoundary(templateMobile)}
              ${renderCSSStringBorder(templateMobile, this.props.template.controllerVersion?.border)}
              border-bottom-left-radius: ${templateMobile["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${templateMobile["border-bottom-right-radius"]}; 
              border-top-left-radius: ${templateMobile["border-top-left-radius"]}; 
              border-top-right-radius: ${templateMobile["border-top-left-radius"]}; 
            }
            ${classPrefix}-bob-image:hover img,
            ${classPrefix}-bob-image:hover picture,
            ${classPrefix}-bob-image.isHover img,
            ${classPrefix}-bob-image.isHover picture,
            ${classPrefix}-bob-media.isHover picture,
            ${classPrefix}-bob-media.isHover img{
              ${renderCSSStringColors(templateMobile)}  
              object-fit: ${templateMobile.size} !important;
              object-position: ${templateMobile.position} !important;              
              border-bottom-left-radius: ${templateMobile["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${templateMobile["border-bottom-right-radius"]}; 
              border-top-left-radius: ${templateMobile["border-top-left-radius"]}; 
              border-top-right-radius: ${templateMobile["border-top-left-radius"]}; 
              filter: ${templateMobile.filter?.filter};
              ${ShadowStyleBoxShadowCss(templateMobile)}
            }
            ${classPrefix}-bob-image:hover .image-filter-overlay{
              background-color: ${templateMobile["background-color"]};
            }
          }`
          : ""
      }
      `
    }
    return ""
  }

  /**
   * returns media queries to apply styles based on enable prop value on the diferent breakpoints
   */
  handleMediaQueryRestrictions(mobileStyles: any, tabletStyles: any) {
    if (tabletStyles?.enable === false && mobileStyles?.enable) return "and (max-width: 766px), (min-width: 993px)"
    if (tabletStyles?.enable === false) return "and (min-width: 993px)"
    if (mobileStyles?.enable === false) return "and (min-width: 767px)"
    return ""
  }

  imageOnHover(template: any) {
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.hover)
      if (template.behaviour.hover?.enable) {
        // DEPRECATED CHECK
        this.setState({
          ...this.state,
          isHover: true,
        })
      }
  }

  imageUnHover(template: any) {
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.hover)
      if (template.behaviour.hover?.enable) {
        // DEPRECATED CHECK
        this.setState({
          ...this.state,
          isHover: false,
        })
      }
  }

  mergeMedias(): PageTypes {
    if (!this.props.object.content?.formula?.media)
      return {
        ...this.props.pageResponse,
        media: {
          ...this.props.pageResponse.media,
          ...this.props.replacedMediaList,
        },
      }
    return {
      ...this.props.pageResponse,
      media: {
        ...this.props.pageResponse.media,
        ...this.props.object.content.formula.media,
        ...this.props.replacedMediaList,
      },
    }
  }

  handleImageValue(postObj: any): {
    mediaSrc: RawUrl | undefined
    mediaAlt: string
    fallbackExtension?: string
    mediaInfo?: MediaObject
  } {
    if (this.props.src) {
      return { mediaSrc: mkRawUrl(this.props.src), mediaAlt: "" }
    }
    // custom content
    else if (getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props) === WRITE) {
      let mediaUrl = getOverrideInlineContentString(`${this.props.field}.src`, this.props)
      return handleSrcLink(this.props.pageResponse, mediaUrl)
    }
    // cms content
    else if (
      getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props) === CMS &&
      postObj &&
      this.props.inlineContent
    ) {
      const mediaUrl = getMediaStringField(postObj, this.props.inlineContent, this.props.field, this.props.overrides)
      return handleSrcLink(this.mergeMedias(), mediaUrl)
    }
    // content page
    else if (getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props) === CONTENT_PAGE) {
      const postObj = this.props.pageResponse.contentPage?.content || DummyPost
      let mediaUrl = getMediaStringField(postObj, this.props.inlineContent, this.props.field, this.props.overrides)
      return handleSrcLink(this.mergeMedias(), mediaUrl)
    } else {
      return { mediaSrc: undefined, mediaAlt: "" }
    }
  }

  handleWidthAndHeightReplace(cssPropertiesObj: any) {
    if (cssPropertiesObj.width) {
      cssPropertiesObj.maxWidth =
        cssPropertiesObj.width !== "auto !important" ? cssPropertiesObj.width : "auto !important"
      cssPropertiesObj.width = cssPropertiesObj.width === "auto !important" && cssPropertiesObj.width
    }
    if (cssPropertiesObj.height) {
      cssPropertiesObj.maxHeight =
        cssPropertiesObj.height !== "auto !important" ? cssPropertiesObj.height : "auto !important"
      cssPropertiesObj.height = cssPropertiesObj.height === "auto !important" && cssPropertiesObj.height
    }
    return cssPropertiesObj
  }

  /**
   * img must be 100% of it's bob-image if the style dimension is a % value
   *
   * @param style
   * @returns style
   */
  handleImgSizeInPercentage(style: any) {
    if (style.width?.includes("%")) style.width = "100% !important"
    if (style.height?.includes("%")) style.height = "100% !important"
    return style
  }

  /**
   * img must be 100% of it's bob-image if the style dimension is a % value
   *
   * @param style
   * @returns style
   */
  handleImgSizePropInPercentage(size: any) {
    if (size?.includes("%")) size = "100% !important"
    return size
  }

  handleImageHoverValue(postObj: any) {
    if (this.props.src) return { mediaSrc: this.props.src, mediaAlt: "" }
    // fallback to default image when state doenst have it written
    if (!this.props.inlineContent.hasOwnProperty(`${this.props.field}.src`))
      return handleSrcLink(
        this.props.pageResponse,
        getOverrideInlineContentValues(`${this.props.field}.src`, this.props)
      )
    // custom content
    if (getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props) === WRITE)
      return getOverrideInlineContentValues(`${this.props.field}.behaviour-hover-src`, this.props) &&
        getOverrideInlineContentValues(`${this.props.field}.behaviour-hover-src`, this.props) !== ""
        ? handleSrcLink(
            this.props.pageResponse,
            getOverrideInlineContentValues(`${this.props.field}.behaviour-hover-src`, this.props)
          )
        : handleSrcLink(this.props.pageResponse, getOverrideInlineContentValues(`${this.props.field}.src`, this.props))
    // cms content
    else if (
      getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props) === CMS &&
      postObj &&
      this.props.inlineContent
    ) {
      const mediaUrl = getMediaStringField(postObj, this.props.inlineContent, this.props.field, this.props.overrides)
      return handleSrcLink(this.mergeMedias(), mediaUrl)
    }
    return { mediaSrc: "", mediaAlt: "" }
  }

  isHoverEnabled = (templateObj: any) => {
    if (
      templateObj.behaviour.hover?.enable ||
      templateObj.tablet?.behaviour?.hover?.enable ||
      templateObj.mobile?.behaviour?.hover?.enable
    ) {
      return true
    }
    return false
  }

  isFilterEnabled = (templateObj: any) => {
    if (templateObj.filter.enable || templateObj.tablet?.filter?.enable || templateObj.mobile?.filter?.enable) {
      return true
    }
    return false
  }

  /**
   *
   * @param media
   * @param style
   * @param validMediaObject When its a validMediaObject we need to create the link
   * When its invalid, the link must be supported by the player, or else the video wont play
   */
  renderVideo(
    templateObj: any,
    media: any,
    style: any,
    inlineContent: any,
    classPrefix: string,
    datasetRender: { "data-trackinfo": string } | {}
  ) {
    // Properties for BobImage container
    const bobVideoProperties = ["width", "height", "radius"]
    let bobVideoMobileStyle = responsiveProperties(templateObj, "mobile", {}, [""], bobVideoProperties)
    bobVideoMobileStyle = {
      ...bobVideoMobileStyle,
      ...cssRenderUnsafeBoundary(templateObj, "mobile", "default"),
      ...cssRenderUnsafeBorder(templateObj, "mobile", "default", true),
      ...cssRenderUnsafeBorder(templateObj, "mobile", "default", true),
    }
    let bobVideoTabletStyle = responsiveProperties(templateObj, "tablet", {}, [""], bobVideoProperties)
    bobVideoTabletStyle = {
      ...bobVideoTabletStyle,
      ...cssRenderUnsafeBoundary(templateObj, "tablet", "default"),
      ...cssRenderUnsafeBorder(templateObj, "tablet", "default", true),
      ...cssRenderUnsafeBorder(templateObj, "tablet", "default", true),
    }
    const bobVideoStyle = {
      width: style.width,
      height: style.height,
      "margin-top": style["margin-top"],
      "margin-bottom": style["margin-bottom"],
      "margin-right": style["margin-right"],
      "margin-left": style["margin-left"],
      "padding-top": style["padding-top"],
      "padding-bottom": style["padding-bottom"],
      "padding-right": style["padding-right"],
      "padding-left": style["padding-left"],
      "border-bottom-style": style["border-bottom-style"],
      "border-bottom-width": style["border-bottom-width"],
      "border-bottom-color": style["border-bottom-color"],
      "border-top-style": style["border-top-style"],
      "border-top-width": style["border-top-width"],
      "border-top-color": style["border-top-color"],
      "border-left-style": style["border-left-style"],
      "border-left-width": style["border-left-width"],
      "border-left-color": style["border-left-color"],
      "border-right-style": style["border-right-style"],
      "border-right-width": style["border-right-width"],
      "border-right-color": style["border-right-color"],
      "border-bottom-left-radius": style.borderBottomLeftRadius,
      "border-bottom-right-radius": style.borderBottomRightRadius,
      "border-top-left-radius": style.borderTopLeftRadius,
      "border-top-right-radius": style.borderTopRightRadius,
    }
    /**
     * Here we get the thumbnail to be used has a poster.
     * The poster attribute is sended to the ReactPlayer
     * when a valid string is present.
     */
    const Poster = getOverrideInlineContentValues(`${this.props.field}.thumbnail`, {
      inlineContent,
      overrides: this.props.overrides,
    })
    const config = {
      file: {
        attributes: {
          poster: Poster,
          preload: Poster && Poster !== "" ? "none" : "auto",
        },
      },
    }
    /**
     * The controls must come with a false written, to disable them, else we show it
     * since the inlineContent can come empty
     */
    const controls = getOverrideInlineContentValues(`${this.props.field}.controls`, {
      inlineContent,
      overrides: this.props.overrides,
    })
    /**
     * For the playPauseAsset to be used, the controls must be false,
     * since the user loses the ability to play/pause the video with the browser player,
     * this asset will control that.
     */
    const playPauseAsset: string = getOverrideInlineContentValues(`${this.props.field}.playPauseAsset`, {
      inlineContent,
      overrides: this.props.overrides,
    })
    //We load a component, since this will use the state to control the player
    if (controls === false && playPauseAsset && playPauseAsset !== "") {
      return (
        <BobMediaDiv
          {...datasetRender}
          desktop={bobVideoStyle}
          mobile={bobVideoMobileStyle}
          tablet={bobVideoTabletStyle}
          id={handleDOMid(this.props.field, this.props.id, this.props.instanceId)}
          className={`bob-image ${classPrefix}-bob-image image-filter ${GlobalStylesUtils.handleClassName(
            this.props.pageResponse.nGlobalStyles,
            this.props.template,
            [GS_BOUNDARY]
          )}`}
        >
          <CustomMediaPlayer
            url={handleMediaSrc(media)}
            controls={false}
            config={config}
            playPauseAsset={playPauseAsset}
          />
        </BobMediaDiv>
      )
    }
    //render the player with defaults
    return (
      <BobMediaDiv
        {...datasetRender}
        desktop={bobVideoStyle}
        mobile={bobVideoMobileStyle}
        tablet={bobVideoTabletStyle}
        id={handleDOMid(this.props.field, this.props.id, this.props.instanceId)}
        className={`bob-image ${classPrefix}-bob-image image-filter`}
      >
        <VideoPlayerSplit
          url={handleMediaSrc(media)}
          controls={controls === false ? false : true}
          config={config}
          videoType='lazy'
        />
      </BobMediaDiv>
    )
  }

  renderEmbed(inlineContent: any, templateObj: any, style: any, datasetRender: { "data-trackinfo": string } | {}) {
    return (
      <EmbedContainer
        as={DangerousHtml}
        {...datasetRender}
        id={handleDOMid(this.props.field, this.props.id, this.props.instanceId) + "lalalalala"}
        onMouseEnter={() => this.imageOnHover(templateObj)}
        onMouseLeave={() => this.imageUnHover(templateObj)}
        styles={{ textAlign: "center", ...style }}
        html={getOverrideInlineContentValues(`${this.props.field}.embed`, {
          inlineContent,
          overrides: this.props.overrides,
        })}
        expManager={this.props.expManager}
      />
    )
  }

  LazyMedia({
    src,
    alt,
    tabletMedia,
    mobileMedia,
    mediaInfo,
    className,
    style,
    onError,
    visibleByDefault,
    fallbackExtension,
    onMouseEnter,
    onMouseLeave,
    datasetRender,
  }: {
    src: string
    tabletMedia:
      | {
          mediaSrc: string
          mediaAlt: string
          fallbackExtension?: string
          mediaInfo?: MediaObject
        }
      | undefined
    mobileMedia:
      | {
          mediaSrc: string
          mediaAlt: string
          fallbackExtension?: string
          mediaInfo?: MediaObject
        }
      | undefined
    alt: string
    className: string
    style: any
    onError: () => void
    visibleByDefault?: boolean
    fallbackExtension?: string
    mediaInfo?: MediaObject
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    datasetRender: { "data-trackinfo": string } | {}
  }) {
    const fallbackSrc =
      fallbackExtension && mediaInfo
        ? `https://static-media.fluxio.cloud/${mediaInfo.profileName}/${mediaInfo.id}${
            mediaInfo.version ? `_${mediaInfo.version}` : ""
          }.${fallbackExtension}`
        : src
    // RESPONSIVE_MEDIA const fallbackTabletSrc =
    //   tabletMedia?.fallbackExtension && tabletMedia?.mediaInfo
    //     ? `https://static-media.fluxio.cloud/${
    //         tabletMedia.mediaInfo.profileName
    //       }/${tabletMedia.mediaInfo.id}${
    //         tabletMedia.mediaInfo.version
    //           ? `_${tabletMedia.mediaInfo.version}`
    //           : ""
    //       }.${fallbackExtension}`
    //     : undefined
    // const fallbackMobileSrc =
    //   mobileMedia?.fallbackExtension && mobileMedia?.mediaInfo
    //     ? `https://static-media.fluxio.cloud/${
    //         mobileMedia.mediaInfo.profileName
    //       }/${mobileMedia.mediaInfo.id}${
    //         mobileMedia.mediaInfo.version
    //           ? `_${mobileMedia.mediaInfo.version}`
    //           : ""
    //       }.${fallbackExtension}`
    //     : undefined

    return (
      <LazyLoadComponent threshold={loadThreshold(mediaInfo?.size)} visibleByDefault={visibleByDefault}>
        <picture
          {...datasetRender}
          style={style}
          className={className}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onError={onError}
        >
          {/* RESPONSIVE_MEDIA {{mobileMedia && (
            <>
              <source
                type={mobileMedia.mediaInfo?.contentType}
                media="(max-width: 767px)"
                srcSet={mobileMedia.mediaSrc}
              />
              {fallbackMobileSrc && (
                <source
                  type={mobileMedia.fallbackExtension}
                  media="(max-width: 767px)"
                  srcSet={fallbackMobileSrc}
                />
              )}
            </>
          )}
          {tabletMedia && (
            <>
              <source
                type={tabletMedia.mediaInfo?.contentType}
                media="(max-width: 992px) and (min-width: 768px)"
                srcSet={tabletMedia.mediaSrc}
              />
              {fallbackTabletSrc && (
                <source
                  type={tabletMedia.fallbackExtension}
                  media="(max-width: 992px) and (min-width: 768px)"
                  srcSet={fallbackTabletSrc}
                />
              )}
            </>
          )}} */}
          <source {...datasetRender} srcSet={src} type={mediaInfo?.contentType} />
          <img {...datasetRender} style={style} className={className} src={fallbackSrc} alt={alt} />
        </picture>
      </LazyLoadComponent>
    )
  }

  properMediaAlt(mediaAlt: string, postObj: Post) {
    if (mediaAlt && mediaAlt !== "") return mediaAlt
    if (postObj) {
      if (postObj.picture_orig_alt && postObj.picture_orig_alt !== "") return postObj.picture_orig_alt
      if (postObj.title && postObj.title !== "") return postObj.title
    }
    return ""
  }

  responsiveMediaLink(breakpoint: Breakpoint):
    | {
        mediaSrc: string
        mediaAlt: string
        fallbackExtension?: string
        mediaInfo?: MediaObject
      }
    | undefined {
    const inlineContent = Deprecated_bobMediaRenderReadFix(this.props.inlineContent, this.props.field)
    const mediaSrc = getResponsiveOverrideInlineContentValues(`${this.props.field}.${breakpoint}-src`, {
      inlineContent,
      overrides: this.props.overrides,
    })
    if (mediaSrc) {
      return handleSrcLink(this.props.pageResponse, mediaSrc)
    }

    return undefined
  }

  renderImage(
    templateObj: any,
    postObj: any,
    imageFilterStyles: any,
    style: any,
    classPrefix: string,
    datasetRender: { "data-trackinfo": string } | {}
  ) {
    const widthTabletStyle = WidthStyleCssRenderUnsafe(templateObj, "tablet", "default")
    const widthMobileStyle = WidthStyleCssRenderUnsafe(templateObj, "mobile", "default")
    const heightTabletStyle = cssRenderUnsafeHeight(templateObj, "tablet", "default")
    const heightMobileStyle = cssRenderUnsafeHeight(templateObj, "mobile", "default")
    // Properties for BobMediaImg
    const bobMediaImgProperties = ["radius", "filter", "size", "position"]
    let bobMediaImgMobileStyle = {
      ...responsiveProperties(templateObj, "mobile", {}, [""], bobMediaImgProperties),
      ...conditionalShadowRenderUnsafe(templateObj, "mobile", "default", "box", undefined),
      ...cssRenderUnsafeColors(templateObj, "mobile", "default", true),
      ...heightMobileStyle,
      ...widthMobileStyle,
    }
    let bobMediaImgTabletStyle = {
      ...responsiveProperties(templateObj, "tablet", {}, [""], bobMediaImgProperties),
      ...conditionalShadowRenderUnsafe(templateObj, "tablet", "default", "box", undefined),
      ...cssRenderUnsafeColors(templateObj, "tablet", "default", true),
      ...heightTabletStyle,
      ...widthTabletStyle,
    }
    // Properties for BobImage container
    const bobImageProperties = ["radius"]
    const bobImageMobileStyle = {
      ...responsiveProperties(templateObj, "mobile", {}, [""], bobImageProperties),
      ...cssRenderUnsafeBoundary(templateObj, "mobile", "default"),
      ...cssRenderUnsafeBorder(templateObj, "mobile", "default", true),
      ...heightMobileStyle,
      ...widthMobileStyle,
    }
    const bobImageTabletStyle = {
      ...responsiveProperties(templateObj, "tablet", {}, [""], bobImageProperties),
      ...cssRenderUnsafeBoundary(templateObj, "tablet", "default"),
      ...cssRenderUnsafeBorder(templateObj, "tablet", "default", true),
      ...heightTabletStyle,
      ...widthTabletStyle,
    }
    // Properties for BobImage filter
    const bobImageFilterOverlayProperties = ["filter"]
    const bobImageFilterOverlayMobileStyle = responsiveProperties(
      templateObj,
      "mobile",
      { filter: { type: "overlay" } },
      [""],
      bobImageFilterOverlayProperties
    )
    const bobImageFilterOverlayTabletStyle = responsiveProperties(
      templateObj,
      "tablet",
      { filter: { type: "overlay" } },
      [""],
      bobImageFilterOverlayProperties
    )

    const containerWidth = style.width
    const containerHeight = style.height
    style = this.handleImgSizeInPercentage(style)
    bobMediaImgMobileStyle = this.handleImgSizeInPercentage(bobMediaImgMobileStyle)
    bobMediaImgTabletStyle = this.handleImgSizeInPercentage(bobMediaImgTabletStyle)

    // must have the hover img seperatly so that the browser caches the images and doenst download them multiple times
    let { mediaSrc, mediaAlt, fallbackExtension, mediaInfo } = this.handleImageValue(postObj)
    mediaAlt = this.properMediaAlt(mediaAlt, postObj)
    const mediaHoverObj = templateObj.behaviour.hover?.enable
      ? this.handleImageHoverValue(postObj)
      : { mediaSrc: "", mediaAlt: "" }
    let mediaSrcHover = mediaHoverObj.mediaSrc,
      mediaAltHover = this.properMediaAlt(mediaHoverObj.mediaAlt, postObj)
    // fallback to placeholder when necesssary
    mediaSrc = mediaSrc ? mediaSrc : mkRawUrl("https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png")
    mediaSrcHover = mediaSrcHover === "" ? "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png" : mediaSrcHover

    const tabletMedia = this.responsiveMediaLink("tablet")
    const mobileMedia = this.responsiveMediaLink("mobile")

    const bobImageStyle = {
      width: containerWidth,
      height: containerHeight,
      "margin-top": style["margin-top"],
      "margin-bottom": style["margin-bottom"],
      "margin-right": style["margin-right"],
      "margin-left": style["margin-left"],
      "padding-top": style["padding-top"],
      "padding-bottom": style["padding-bottom"],
      "padding-right": style["padding-right"],
      "padding-left": style["padding-left"],
      "border-bottom-style": style["border-bottom-style"],
      "border-bottom-width": style["border-bottom-width"],
      "border-bottom-color": style["border-bottom-color"],
      "border-top-style": style["border-top-style"],
      "border-top-width": style["border-top-width"],
      "border-top-color": style["border-top-color"],
      "border-left-style": style["border-left-style"],
      "border-left-width": style["border-left-width"],
      "border-left-color": style["border-left-color"],
      "border-right-style": style["border-right-style"],
      "border-right-width": style["border-right-width"],
      "border-right-color": style["border-right-color"],
      "border-bottom-left-radius": style.borderBottomLeftRadius,
      "border-bottom-right-radius": style.borderBottomRightRadius,
      "border-top-left-radius": style.borderTopLeftRadius,
      "border-top-right-radius": style.borderTopRightRadius,
    }

    const imgStyle = {
      ...style,
      maxHeight: style.height !== "auto !important" && style.height,
      maxWidth: style.width !== "auto !important" && style.width,
      height: style.height === "auto !important" && style.height,
      width: style.width === "auto !important" && style.width,
      margin: "0 !important", // must be set on bobImage div
      padding: "0 !important", // must be set on bobImage div
      "border-top": "0 !important",
      "border-bottom": "0 !important",
      "border-left": "0 !important",
      "border-right": "0 !important",
    }
    //Apply same workaround, seen above, to the responsive styles
    bobMediaImgMobileStyle = this.handleWidthAndHeightReplace(bobMediaImgMobileStyle)
    bobMediaImgTabletStyle = this.handleWidthAndHeightReplace(bobMediaImgTabletStyle)

    return (
      <BobMediaDiv
        {...datasetRender}
        desktop={bobImageStyle}
        mobile={bobImageMobileStyle}
        tablet={bobImageTabletStyle}
        id={handleDOMid(this.props.field, this.props.id, this.props.instanceId)}
        className={`bob-image ${classPrefix}-bob-image spacing-preview-${
          this.props.template.uuid
        } image-filter ${GlobalStylesUtils.handleClassName(this.props.pageResponse.nGlobalStyles, this.props.template, [
          GS_BOUNDARY,
        ])}`}
      >
        <BobMediaImg
          {...datasetRender}
          datasetRender={datasetRender}
          as={this.LazyMedia}
          desktop={imgStyle}
          mobile={bobMediaImgMobileStyle}
          tablet={bobMediaImgTabletStyle}
          visibleByDefault={this.props.expManager.enable}
          className={`card-img-top image-filter-placeholder ${GlobalStylesUtils.handleClassName(
            this.props.pageResponse.nGlobalStyles,
            this.props.template,
            [GS_FILTER, GS_SHADOW],
            "-box"
          )}`}
          src={mediaSrc}
          tabletMedia={tabletMedia}
          mobileMedia={mobileMedia}
          fallbackExtension={fallbackExtension}
          mediaInfo={mediaInfo}
          alt={mediaAlt}
          style={{ display: !this.state.isHover ? "block" : "none" }}
          onError={(node: any) =>
            templateObj.behaviour && !templateObj.behaviour.hover.enable
              ? (node.target.style.display = "none")
              : undefined
          }
          onMouseEnter={() => this.imageOnHover(templateObj)}
          onMouseLeave={() => this.imageUnHover(templateObj)}
        />
        {/* must have the hover img seperatly so that the browser caches the images and doenst download them multiple times*/}
        {this.isHoverEnabled(templateObj) && (
          <BobMediaImg
            {...datasetRender}
            as={this.LazyMedia}
            desktop={imgStyle}
            mobile={bobMediaImgMobileStyle}
            tablet={bobMediaImgTabletStyle}
            className={`card-img-top image-filter-placeholder hovered ${GlobalStylesUtils.handleClassName(
              this.props.pageResponse.nGlobalStyles,
              this.props.template,
              [GS_FILTER, GS_SHADOW],
              "-box"
            )}`}
            src={mediaSrcHover}
            fallbackExtension={fallbackExtension}
            mediaInfo={mediaInfo}
            alt={mediaAltHover}
            style={{ display: this.state.isHover ? "block" : "none" }}
            onError={(node: any) =>
              templateObj.behaviour && !templateObj.behaviour.hover.enable
                ? (node.target.style.display = "none")
                : undefined
            }
            onMouseEnter={() => this.imageOnHover(templateObj)}
            onMouseLeave={() => this.imageUnHover(templateObj)}
          />
        )}
        {this.isFilterEnabled(templateObj) && (
          <BobMediaFilterOverlay
            {...datasetRender}
            desktop={imageFilterStyles.overlay}
            mobile={bobImageFilterOverlayMobileStyle}
            tablet={bobImageFilterOverlayTabletStyle}
            className={`image-filter-overlay ${GlobalStylesUtils.handleClassName(
              this.props.pageResponse.nGlobalStyles,
              this.props.template,
              [GS_FILTER]
            )}`}
          ></BobMediaFilterOverlay>
        )}
      </BobMediaDiv>
    )
  }

  renderMedia(
    templateObj: any,
    postObj: any,
    imageFilterStyles: any,
    style: any,
    classPrefix: string,
    inlineContent: any,
    media: any,
    datasetRender: { "data-trackinfo": string } | {}
  ) {
    const isEmbedEnable = getOverrideInlineContentValues(`${this.props.field}.embedEnable`, this.props)
    /**
     * This will check if the content is managed by us,
     * or its an embed with custom code, added by the user.
     */
    if (!isEmbedEnable) {
      /**
       * Since we know for sure, that the cms option is only used for images,
       * when the media is undefined, the user is using the cms option.
       * TODO: check for cms when declaring media, and remove that logic from
       * the renderImage, so the logic is computed above this and renderImage and renderVideo
       * just receive what they are waiting for.
       */
      if (media === undefined)
        return this.renderImage(templateObj, postObj, imageFilterStyles, style, classPrefix, datasetRender)
      //When its a string
      if (typeof media === "string") {
        const linkType = getOverrideInlineContentValues(`${this.props.field}.linkType`, this.props)
        //The check is done at write time, if it doesnt exist, or it isnt video, we render image
        if (linkType && linkType === "video")
          return this.renderVideo(templateObj, media, style, inlineContent, classPrefix, datasetRender)
        //Its an image here
        return this.renderImage(templateObj, postObj, imageFilterStyles, style, classPrefix, datasetRender)
      }
      //When its an object
      if (media.itemType === "image")
        return this.renderImage(templateObj, postObj, imageFilterStyles, style, classPrefix, datasetRender)
      return this.renderVideo(templateObj, media, style, inlineContent, classPrefix, datasetRender)
    }
    return this.renderEmbed(inlineContent, templateObj, style, datasetRender)
  }

  clickEvent(e: any, TAG: any) {
    if (TAG === "a") {
      const linkLabel = analyticsLinkLabel(
        this.props.field,
        {
          inlineContent: this.props.inlineContent,
          overrides: this.props.overrides,
        },
        this.props.pageResponse,
        this.props.post
      )
      //get parent id to properly know who has the link configured
      const parentId = e.target.parentElement?.id
      if (parentId) {
        GoogleEvent("link_click", linkLabel, "Link Clicked", 1)
      }
    }
  }

  render() {
    const inlineContent = Deprecated_bobMediaRenderReadFix(this.props.inlineContent, this.props.field)
    /**
     * The media can be an object or a string.
     * When its a string, it means the user is using a direct link
     */
    const media = handleMediaFields(
      this.props.pageResponse,
      getOverrideInlineContentValues(`${this.props.field}.src`, {
        inlineContent,
        overrides: this.props.overrides,
      })
    )
    //Handle GlobalStyles
    const templateObj = this.props.template
    const postObj = this.props.post ?? DummyPost
    const classPrefix = `prefix${templateObj.uuid}`

    /**
     * Responsive Styles (mobile/tablet)
     */
    // Properties for BobImage container
    const alignmentAdittionalProperties = {
      flexDirection: "row",
      self: true,
    } as ComponentSpecificAlignment
    const {
      alignmentStyles,
      tabletAlignment: bobImageOuterContainerTabletStyle,
      mobileAlignment: bobImageOuterContainerMobileStyle,
    } = alignmentWithAP(templateObj, alignmentAdittionalProperties)

    /**
     * Desktop Styles
     */
    const borders = cssRenderUnsafeBorder(templateObj, "desktop", "default", true)
    const backgroundColorStyles = cssRenderUnsafeColors(templateObj, "desktop", "default", true)
    const heightStyles = cssRenderUnsafeHeight(templateObj, "desktop", "default")
    const boundaries = cssRenderUnsafeBoundary(templateObj, "desktop", "default")
    const widthStyles = WidthStyleCssRenderUnsafe(templateObj, "desktop", "default")
    const shadows = conditionalShadowRenderUnsafe(templateObj, "desktop", "default", "box", undefined)
    const imageFilterStyles = handleImageFilter(templateObj)
    const radiusStyles = handleRadius(templateObj)

    /**
     * Behaviour Styles
     */
    let hoverStyles: string = ""
    let activeStyles: string = ""
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.hover) {
      hoverStyles = this.handleHoverStateStyles(
        templateObj,
        templateObj.behaviour.hover,
        templateObj.mobile?.behaviour?.hover,
        templateObj.tablet?.behaviour?.hover,
        `.${classPrefix}`
      )
    }
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.active) {
      activeStyles = this.handleActiveStateStyles(
        templateObj,
        templateObj.behaviour.active,
        templateObj.mobile?.behaviour?.active,
        templateObj.tablet?.behaviour?.active,
        `${classPrefix}-bob-image`
      )
    }

    //Styles Tree
    const style = {
      ...heightStyles,
      ...widthStyles,
      ...backgroundColorStyles,
      ...shadows,
      ...borders,
      ...radiusStyles,
      ...imageFilterStyles.filter,
      objectFit: templateObj.size,
      objectPosition: templateObj.position,
      ...boundaries,
    }
    /**
     * The DOM tag changes with the selected behaviour properties.
     * Default is a <div> element, but with anchor properties turns into a<a> element
     */
    const TAG = getOverrideInlineContentValues(`${this.props.field}.behaviour-link-enable`, this.props) ? "a" : "div"
    //Link properties
    const linkAttrs = getLinkAttributes(
      this.props.field,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      },
      this.props.pageResponse,
      this.props.post
    )

    const hasStateStyles = hoverStyles.trim() !== "" || activeStyles.trim() !== ""

    const customAttributesEnable = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_ENABLE}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesMappedField = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_MAPPED_FIELD}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesValue = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_VALUE}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesContentSrc = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_CONTENTSRC}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const datasetValue = datasetRender(
      this.props.post,
      customAttributesEnable,
      customAttributesMappedField,
      customAttributesValue,
      customAttributesContentSrc
    )

    return (
      <Fragment>
        {hasStateStyles && (
          <Style>
            {`          
              ${hoverStyles}
              ${activeStyles}          
            `}
          </Style>
        )}
        <BobMediaOuterContainer
          {...datasetValue}
          as={TAG}
          desktop={alignmentStyles}
          mobile={bobImageOuterContainerMobileStyle}
          tablet={bobImageOuterContainerTabletStyle}
          className={`bob-media ${classPrefix}-bob-media ${this.props.className ? this.props.className : ""}`}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            this.props.onClick && this.props.onClick(e)
            this.clickEvent(e, TAG)
          }}
          {...linkAttrs}
        >
          <BobLoading
            type='media'
            uuid={getComposedUuid(this.props.pageResponse.objects[this.props.id])}
            contentSrc={getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props)}
            mediaWidth={this.handleSize(templateObj.width)}
            mediaHeight={this.handleSize(templateObj.height)}
            mappedValue={getOverrideInlineContentValues(`${this.props.field}.image-mappedValue`, this.props)}
          >
            {this.renderMedia(
              templateObj,
              postObj,
              imageFilterStyles,
              style,
              classPrefix,
              inlineContent,
              media,
              datasetValue
            )}
          </BobLoading>
        </BobMediaOuterContainer>
      </Fragment>
    )
  }
}

export default BobMedia
