import React, { Fragment } from 'react'
import { ExpManager, PageTypes } from '../../../../modules/shared-modules/experienceManager/types/pageTypes'
import ObjectHandler from '../objectHandler/objectHandler'
import styled from 'styled-components'
import GlobalStylesUtils from '../../../../modules/shared-modules/globalStyles/globalStylesUtils'
// @ts-ignore
import Style from 'style-it'
import { getFontImports as TextStyleGetFontImports } from "../bob/bobStyles/textStyle"
import PageLoader from "./pageLoader"
import { writeStylesheetCssVariables } from "../../../../modules/shared-modules/stylesheet/stylesheetUtils"

interface PageBootstrapProps {
  pageResponse: PageTypes
  expManager: ExpManager
}

interface StyledPageBootsrap {
  nGlobalStyles: PageTypes["nGlobalStyles"]
  stylesheet: PageTypes["stylesheet"]
  expManager: ExpManager
}

const PageBootstrapContainer: any = styled.div`
  ${(props: StyledPageBootsrap) => handlePageBootstrapStyles(props)}
`

const handlePageBootstrapStyles = (props: StyledPageBootsrap): string => {
  return `${GlobalStylesUtils.handleStyles(
    props.nGlobalStyles,
    props.stylesheet?.labels,
    props.expManager
  )}\n${writeStylesheetCssVariables(props.stylesheet?.labels)}`
}

const PageBootstrap = (props: PageBootstrapProps) => {
  const renderComponents = () => {
    const fontImports = TextStyleGetFontImports(
      props.pageResponse.nGlobalStyles,
      props.pageResponse.stylesheet?.["labels"],
      props.expManager
    )
    const pageResponse = props.pageResponse
    const rootId = pageResponse.rootId
    const pageRootObject = pageResponse.objects[rootId]
    /**
     * Workaround for possible problem with the uri rules, see issue:
     * https://github.com/MeshApp/web/issues/1169
     */
    const matchOrderNumber = (pageRootObject.uri?.match(/\//g) || []).length + 1
    if (pageRootObject)
      return (
        <Fragment>
          {fontImports !== "" && (
            <Style>
              {`
            ${fontImports}
          `}
            </Style>
          )}
          <ObjectHandler
            component={pageRootObject}
            pageResponse={pageResponse}
            matchOrder={matchOrderNumber}
            expManager={props.expManager}
          />
        </Fragment>
      )

    // handle error/no-content
    return (
      <div className='empty-content'>
        <p className='msg'>No Hub</p>
      </div>
    )
  }
  
  return (
    <PageBootstrapContainer
      nGlobalStyles={props.pageResponse.nGlobalStyles}
      stylesheet={props.pageResponse.stylesheet}
      expManager={props.expManager}
      className='page-container'
    >
      {props.pageResponse.profileName === "davidrosas" &&
        (props.pageResponse.objects[props.pageResponse.rootId].uri === "fine-jewellery" ||
          props.pageResponse.objects[props.pageResponse.rootId].uri === "time-experts") && (
          <PageLoader
            expManager={props.expManager}
            options={{
              enable: true,
              delay: 2000,
              media: "https://static-media.fluxio.cloud/davidrosas/82BJxWAi_5.gif",
              code: undefined,
            }}
          />
        )}
      {renderComponents()}
    </PageBootstrapContainer>
  )
}

export default React.memo(PageBootstrap)