import React from 'react'
import {
  getContent,
  getStringField,
  handleContentBlock,
  handleObjectType,
  hasChildOfType,
} from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import WarningSystem from "../../../../../../modules/shared-modules/experienceManager/warningSystem"
import ComponentHandler from "../../../../componentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { ShowcaseProps } from "."
import { Post } from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import galleryShowcaseStyles from "../../../../../../stylesheets/modules/templates/galleryShowcase.module.sass"

type ShowcaseState = {
  childComponents: any[]
  hasChildModal: boolean
}

class Showcase extends React.Component<ShowcaseProps, ShowcaseState> {
  constructor(props: ShowcaseProps) {
    super(props)
    this.state = {
      childComponents: [],
      hasChildModal: false,
    }
  }

  child: any[] = []

  componentDidMount() {
    this.handleWriteState(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: ShowcaseProps) {
    if (
      (!this.props.obj.children && nextProps.obj.children && nextProps.obj.children.length) ||
      (this.props.obj.children &&
        nextProps.obj.children &&
        this.props.obj.children.length !== nextProps.obj.children.length)
    ) {
      this.handleWriteState(nextProps)
    }
  }

  handleWriteState(props: ShowcaseProps) {
    const components = props.obj.children
    if (components && components.length > 0) {
      this.setState({
        childComponents: components,
        hasChildModal: hasChildOfType("modal", components, props.obj, props.pageResponse, props.expManager.emEditorType),
      })
    }
  }

  /**
   *
   * @param {postIdx} idx
   * We need this idx from the post array
   * to properly call the inner function down the react tree.
   * if we dont use this we overwrite the same ref over and over
   * leaving only the reference to the last rendered object.
   */
  handleCardClick(idx: number) {
    if (this.child && this.child[idx]) this.child[idx].handleInnerFunction()
  }

  renderComponents(post: Post, postIdx: number) {
    const componentsId = this.props.obj.children
    if (componentsId && componentsId.length > 0) {
      return componentsId.map((componentId, idx) => {
        const handledItem = handleObjectType(
          this.props.obj,
          componentId,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={this.props.obj.selectedInstanceId}
            onRef={(ref) => (this.child[postIdx] = ref)}
            component={handleContentBlock(
              handledItem,
              post,
              this.props.obj,
              this.props.pageResponse,
              this.props.overrides
            )}
            pageResponse={this.props.pageResponse}
            matchOrder={this.props.matchOrder}
            expManager={this.props.expManager}
            key={idx}
          />
        )
      })
    }
  }

  renderItems() {
    const content = getContent(this.props.obj, "posts", this.props.overrides)
    if (content && content.length > 0) {
      const background2 = this.props.obj.styles.bobs.background2
      const childs = this.props.obj.children
      const hasChildModal =
        childs.length > 0 &&
        hasChildOfType("modal", childs, this.props.obj, this.props.pageResponse, this.props.expManager.emEditorType)
      const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background2)
      return content.map((post: Post, idx: number) => {
        return (
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            key={idx}
            objectId={this.props.obj.uuid}
            post={post}
            inlineContent={this.props.obj.content.inlineContent}
            overrides={this.props.overrides}
            field={"background2"}
            rules={this.props.componentRule}
            template={this.props.obj.styles}
            expManager={this.props.expManager}
            className={`showcase-item ${galleryShowcaseStyles["showcase-item"]} ${
              idx === 0 ? `item-default ${galleryShowcaseStyles["item-default"]}` : ""
            } ${hasChildModal ? "is-clickable" : ""}`}
            onClick={() => this.handleCardClick(idx)}
            flexDirection='column'
          >
            <AlignmentHOC
              className='gallery-item-wrap'
              desktop={alignmentStyles}
              tablet={tabletAlignment}
              mobile={mobileAlignment}
            >
              <BobComponentHandler
                position={this.props.position}
                pageResponse={this.props.pageResponse}
                replacedMediaList={this.props.replacedMediaList}
                instanceId={this.props.obj.selectedInstanceId}
                object={this.props.obj}
                objectId={this.props.obj.uuid}
                inlineContent={this.props.obj.content.inlineContent}
                overrides={this.props.overrides}
                field='media1'
                template={this.props.obj.styles}
                rules={this.props.componentRule}
                expManager={this.props.expManager}
                network={post.network}
                post={post}
              />
              <div className={`gallery-item-content ${galleryShowcaseStyles["gallery-item-content"]}`}>
                <BobComponentHandler
                  position={this.props.position}
                  pageResponse={this.props.pageResponse}
                  instanceId={this.props.obj.selectedInstanceId}
                  object={this.props.obj}
                  objectId={this.props.obj.uuid}
                  post={post}
                  inlineContent={this.props.obj.content.inlineContent}
                  overrides={this.props.overrides}
                  field='text1'
                  tag='p'
                  value={getStringField(post, this.props.obj, this.props.pageResponse, "text1", this.props.overrides)}
                  template={this.props.obj.styles}
                  rules={this.props.componentRule}
                  expManager={this.props.expManager}
                  network={post.network}
                />
                <BobComponentHandler
                  position={this.props.position}
                  pageResponse={this.props.pageResponse}
                  instanceId={this.props.obj.selectedInstanceId}
                  object={this.props.obj}
                  objectId={this.props.obj.uuid}
                  post={post}
                  inlineContent={this.props.obj.content.inlineContent}
                  overrides={this.props.overrides}
                  field='text2'
                  tag='p'
                  value={getStringField(post, this.props.obj, this.props.pageResponse, "text2", this.props.overrides)}
                  template={this.props.obj.styles}
                  rules={this.props.componentRule}
                  expManager={this.props.expManager}
                  network={post.network}
                />
                {this.renderComponents(post, idx)}
              </div>
            </AlignmentHOC>
          </BobComponentHandler>
        )
      })
    } else {
      if (this.props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Select a formula to show posts' />
          </div>
        )
      }
    }
  }

  render() {
    const background1 = this.props.obj.styles.bobs.background1
    const { alignmentStyles } = alignment(background1)
    return (
      <section className={`gallery ${galleryShowcaseStyles.gallery} showcase ${galleryShowcaseStyles.showcase}`}>
        <div className='d-flex flex-row' style={alignmentStyles}>
          {this.renderItems()}
        </div>
      </section>
    )
  }
}


export default Showcase