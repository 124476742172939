import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  literal,
  null as nullC,
  number as numberC,
  string as stringC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { get2WithNull } from "../bobUtils"

const nullable = <A>(t: Type<A>) => union([t, nullC])

const borderStyleCodec = union([literal("none"), literal("solid"), literal("dashed"), literal("dotted")])

const BorderPropsCodec = type({
  bottomColor: stringC,
  bottomEnable: booleanC,
  bottomStyle: borderStyleCodec,
  bottomWidth: numberC,
  topColor: stringC,
  topEnable: booleanC,
  topStyle: borderStyleCodec,
  topWidth: numberC,
  leftColor: stringC,
  leftEnable: booleanC,
  leftStyle: borderStyleCodec,
  leftWidth: numberC,
  rightColor: stringC,
  rightEnable: booleanC,
  rightStyle: borderStyleCodec,
  rightWidth: numberC,
})

const BorderCodec = intersection([type({ enable: booleanC }), BorderPropsCodec])

const BorderPropsOptCodec = partial({
  bottomColor: nullable(stringC),
  bottomEnable: nullable(booleanC),
  bottomStyle: nullable(borderStyleCodec),
  bottomWidth: nullable(numberC),
  topColor: nullable(stringC),
  topEnable: nullable(booleanC),
  topStyle: nullable(borderStyleCodec),
  topWidth: nullable(numberC),
  leftColor: nullable(stringC),
  leftEnable: nullable(booleanC),
  leftStyle: nullable(borderStyleCodec),
  leftWidth: nullable(numberC),
  rightColor: nullable(stringC),
  rightEnable: nullable(booleanC),
  rightStyle: nullable(borderStyleCodec),
  rightWidth: nullable(numberC),
})
const BorderOptCodec = intersection([partial({ enable: nullable(booleanC) }), BorderPropsOptCodec])

const StylesBorderCodec = intersection([
  type({ border: BorderCodec }),
  partial({
    behaviour: partial({
      active: partial({
        border: BorderOptCodec,
      }),
      hover: partial({
        border: BorderOptCodec,
      }),
    }),
    mobile: partial({
      border: BorderOptCodec,
      behaviour: partial({
        active: partial({
          border: BorderOptCodec,
        }),
        hover: partial({
          border: BorderOptCodec,
        }),
      }),
    }),
    tablet: partial({
      border: BorderOptCodec,
      behaviour: partial({
        active: partial({
          border: BorderOptCodec,
        }),
        hover: partial({
          border: BorderOptCodec,
        }),
      }),
    }),
  }),
])

const GSBorderCodec = intersection([
  BorderPropsCodec,
  partial({
    behaviour: partial({
      active: BorderPropsOptCodec,
      hover: BorderPropsOptCodec,
    }),
    mobile: intersection([
      BorderPropsOptCodec,
      partial({
        behaviour: partial({
          active: BorderPropsOptCodec,
          hover: BorderPropsOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      BorderPropsOptCodec,
      partial({
        behaviour: partial({
          active: BorderPropsOptCodec,
          hover: BorderPropsOptCodec,
        }),
      }),
    ]),
  }),
])

type Border = TypeOf<typeof BorderCodec>
type BorderProps = TypeOf<typeof BorderPropsCodec>
type BorderOpt = TypeOf<typeof BorderOptCodec>
type BorderPropsOpt = TypeOf<typeof BorderPropsOptCodec>
type StylesBorder = TypeOf<typeof StylesBorderCodec>
type GSBorder = TypeOf<typeof GSBorderCodec>
type BorderCSS = {
  "border-bottom-style": string
  "border-bottom-width": string
  "border-bottom-color": string
  "border-top-style": string
  "border-top-width": string
  "border-top-color": string
  "border-left-style": string
  "border-left-width": string
  "border-left-color": string
  "border-right-style": string
  "border-right-width": string
  "border-right-color": string
}

export function responsiveStyle(borderObj: any) {
  if (borderObj.hasOwnProperty("enable") && !objValueExists(borderObj, "enable")) return ""

  if (borderObj.hasOwnProperty("enable") && !borderObj.enable)
    return {
      "border-top": "none !important",
      "border-right": "none !important",
      "border-bottom": "none !important",
      "border-left": "none !important",
    }

  let borderTop = borderObj.topEnable
    ? `${borderObj.topWidth}px ${borderObj.topStyle} ${borderObj.topColor} !important`
    : "none !important"
  let borderRight = borderObj.rightEnable
    ? `${borderObj.rightWidth}px ${borderObj.rightStyle} ${borderObj.rightColor} !important`
    : "none !important"
  let borderBottom = borderObj.bottomEnable
    ? `${borderObj.bottomWidth}px ${borderObj.bottomStyle} ${borderObj.bottomColor} !important`
    : "none !important"
  let borderLeft = borderObj.leftEnable
    ? `${borderObj.leftWidth}px ${borderObj.leftStyle} ${borderObj.leftColor} !important`
    : "none !important"

  return {
    "border-top": borderTop,
    "border-right": borderRight,
    "border-bottom": borderBottom,
    "border-left": borderLeft,
  }
}

export function cssRenderUnsafe(stylesObj: any, breakpoint: Breakpoint, behaviourState: BehaviourState_): BorderCSS {
  const styles = StylesBorderCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(breakpoint, behaviourState, decoderErrors(styles))
  return {
    "border-bottom-style": "none !important",
    "border-bottom-width": "0 !important",
    "border-bottom-color": "#fff !important",
    "border-top-style": "none !important",
    "border-top-width": "0 !important",
    "border-top-color": "#fff !important",
    "border-left-style": "none !important",
    "border-left-width": "0 !important",
    "border-left-color": "#fff !important",
    "border-right-style": "none !important",
    "border-right-width": "0 !important",
    "border-right-color": "#fff !important",
  }
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): BorderCSS {
  const gs = GSBorderCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState)
  console.warn(decoderErrors(gs))
  return {
    "border-bottom-style": "none !important",
    "border-bottom-width": "0 !important",
    "border-bottom-color": "#fff !important",
    "border-top-style": "none !important",
    "border-top-width": "0 !important",
    "border-top-color": "#fff !important",
    "border-left-style": "none !important",
    "border-left-width": "0 !important",
    "border-left-color": "#fff !important",
    "border-right-style": "none !important",
    "border-right-width": "0 !important",
    "border-right-color": "#fff !important",
  }
}

export function cssRender(stylesObj: StylesBorder, breakpoint: Breakpoint, behaviourState: BehaviourState_): BorderCSS {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.border)
    }
    //hover | active
    else {
      return renderBob(mergeBob2(stylesObj?.behaviour?.[behaviourState]?.border, stylesObj.border))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBob(mergeBob2(stylesObj?.[breakpoint]?.border, stylesObj.border))
    }
    //hover | active
    else {
      return renderBob(
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.border,
          stylesObj?.behaviour?.[behaviourState]?.border,
          stylesObj?.[breakpoint]?.border,
          stylesObj.border
        )
      )
    }
  }
}

export function globalStyleCssRender(
  stylesObj: GSBorder,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): BorderCSS {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj)
    }
    //hover | active
    else {
      return render(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return render(merge2(stylesObj?.[breakpoint], stylesObj))
    }
    //hover | active
    else {
      return render(
        merge3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
          stylesObj.behaviour?.[behaviourState],
          stylesObj?.[breakpoint],
          stylesObj
        )
      )
    }
  }
}

export function renderBob(borderObj: Border): BorderCSS {
  if (!borderObj.enable)
    return {
      "border-bottom-style": "none !important",
      "border-bottom-width": "0 !important",
      "border-bottom-color": "#fff !important",
      "border-top-style": "none !important",
      "border-top-width": "0 !important",
      "border-top-color": "#fff !important",
      "border-left-style": "none !important",
      "border-left-width": "0 !important",
      "border-left-color": "#fff !important",
      "border-right-style": "none !important",
      "border-right-width": "0 !important",
      "border-right-color": "#fff !important",
    }

  return render(borderObj)
}

export function render(borderObj: BorderProps): BorderCSS {
  return {
    "border-bottom-style": `${borderObj.bottomEnable ? borderObj.bottomStyle : "none"} !important`,
    "border-bottom-width": `${borderObj.bottomEnable ? borderObj.bottomWidth : "0"}px !important`,
    "border-bottom-color": `${borderObj.bottomEnable ? borderObj.bottomColor : "#fff"} !important`,
    "border-top-style": `${borderObj.topEnable ? borderObj.topStyle : "none"} !important`,
    "border-top-width": `${borderObj.topEnable ? borderObj.topWidth : "0"}px !important`,
    "border-top-color": `${borderObj.topEnable ? borderObj.topColor : "#fff"} !important`,
    "border-left-style": `${borderObj.leftEnable ? borderObj.leftStyle : "none"} !important`,
    "border-left-width": `${borderObj.leftEnable ? borderObj.leftWidth : "0"}px !important`,
    "border-left-color": `${borderObj.leftEnable ? borderObj.leftColor : "#fff"} !important`,
    "border-right-style": `${borderObj.rightEnable ? borderObj.rightStyle : "none"} !important`,
    "border-right-width": `${borderObj.rightEnable ? borderObj.rightWidth : "0"}px !important`,
    "border-right-color": `${borderObj.rightEnable ? borderObj.rightColor : "#fff"} !important`,
  }
}

export function mergeBob2(borderObj: BorderOpt | undefined, defaultBorderObj: Border): Border {
  const enable = get2WithNull(borderObj?.enable, defaultBorderObj.enable)
  const bottomColor = get2WithNull(borderObj?.bottomColor, defaultBorderObj.bottomColor)
  const bottomStyle = get2WithNull(borderObj?.bottomStyle, defaultBorderObj.bottomStyle)
  const bottomWidth = get2WithNull(borderObj?.bottomWidth, defaultBorderObj.bottomWidth)
  const bottomEnable = get2WithNull(borderObj?.bottomEnable, defaultBorderObj.bottomEnable)
  const topColor = get2WithNull(borderObj?.topColor, defaultBorderObj.topColor)
  const topStyle = get2WithNull(borderObj?.topStyle, defaultBorderObj.topStyle)
  const topWidth = get2WithNull(borderObj?.topWidth, defaultBorderObj.topWidth)
  const topEnable = get2WithNull(borderObj?.topEnable, defaultBorderObj.topEnable)
  const leftColor = get2WithNull(borderObj?.leftColor, defaultBorderObj.leftColor)
  const leftStyle = get2WithNull(borderObj?.leftStyle, defaultBorderObj.leftStyle)
  const leftWidth = get2WithNull(borderObj?.leftWidth, defaultBorderObj.leftWidth)
  const leftEnable = get2WithNull(borderObj?.leftEnable, defaultBorderObj.leftEnable)
  const rightColor = get2WithNull(borderObj?.rightColor, defaultBorderObj.rightColor)
  const rightStyle = get2WithNull(borderObj?.rightStyle, defaultBorderObj.rightStyle)
  const rightWidth = get2WithNull(borderObj?.rightWidth, defaultBorderObj.rightWidth)
  const rightEnable = get2WithNull(borderObj?.rightEnable, defaultBorderObj.rightEnable)

  return {
    enable,
    bottomColor,
    bottomStyle,
    bottomWidth,
    bottomEnable,
    topColor,
    topStyle,
    topWidth,
    topEnable,
    leftColor,
    leftStyle,
    leftWidth,
    leftEnable,
    rightColor,
    rightStyle,
    rightWidth,
    rightEnable,
  }
}

export function merge2(borderObj: BorderPropsOpt | undefined, defaultBorderObj: BorderProps): BorderProps {
  const bottomColor = get2WithNull(borderObj?.bottomColor, defaultBorderObj.bottomColor)
  const bottomStyle = get2WithNull(borderObj?.bottomStyle, defaultBorderObj.bottomStyle)
  const bottomWidth = get2WithNull(borderObj?.bottomWidth, defaultBorderObj.bottomWidth)
  const bottomEnable = get2WithNull(borderObj?.bottomEnable, defaultBorderObj.bottomEnable)
  const topColor = get2WithNull(borderObj?.topColor, defaultBorderObj.topColor)
  const topStyle = get2WithNull(borderObj?.topStyle, defaultBorderObj.topStyle)
  const topWidth = get2WithNull(borderObj?.topWidth, defaultBorderObj.topWidth)
  const topEnable = get2WithNull(borderObj?.topEnable, defaultBorderObj.topEnable)
  const leftColor = get2WithNull(borderObj?.leftColor, defaultBorderObj.leftColor)
  const leftStyle = get2WithNull(borderObj?.leftStyle, defaultBorderObj.leftStyle)
  const leftWidth = get2WithNull(borderObj?.leftWidth, defaultBorderObj.leftWidth)
  const leftEnable = get2WithNull(borderObj?.leftEnable, defaultBorderObj.leftEnable)
  const rightColor = get2WithNull(borderObj?.rightColor, defaultBorderObj.rightColor)
  const rightStyle = get2WithNull(borderObj?.rightStyle, defaultBorderObj.rightStyle)
  const rightWidth = get2WithNull(borderObj?.rightWidth, defaultBorderObj.rightWidth)
  const rightEnable = get2WithNull(borderObj?.rightEnable, defaultBorderObj.rightEnable)

  return {
    bottomColor,
    bottomStyle,
    bottomWidth,
    bottomEnable,
    topColor,
    topStyle,
    topWidth,
    topEnable,
    leftColor,
    leftStyle,
    leftWidth,
    leftEnable,
    rightColor,
    rightStyle,
    rightWidth,
    rightEnable,
  }
}

export function mergeBob3(
  borderObj: BorderOpt | undefined,
  borderDesktopBehaviour: BorderOpt | undefined,
  borderDefaultBreakpoint: BorderOpt | undefined,
  defaultBorderObj: Border
): Border {
  const enable =
    borderObj?.enable ?? borderDesktopBehaviour?.enable ?? borderDefaultBreakpoint?.enable ?? defaultBorderObj.enable
  const bottomColor =
    borderObj?.bottomColor ??
    borderDesktopBehaviour?.bottomColor ??
    borderDefaultBreakpoint?.bottomColor ??
    defaultBorderObj.bottomColor
  const bottomStyle =
    borderObj?.bottomStyle ??
    borderDesktopBehaviour?.bottomStyle ??
    borderDefaultBreakpoint?.bottomStyle ??
    defaultBorderObj.bottomStyle
  const bottomWidth =
    borderObj?.bottomWidth ??
    borderDesktopBehaviour?.bottomWidth ??
    borderDefaultBreakpoint?.bottomWidth ??
    defaultBorderObj.bottomWidth
  const bottomEnable =
    borderObj?.bottomEnable ??
    borderDesktopBehaviour?.bottomEnable ??
    borderDefaultBreakpoint?.bottomEnable ??
    defaultBorderObj.bottomEnable
  const topColor =
    borderObj?.topColor ??
    borderDesktopBehaviour?.topColor ??
    borderDefaultBreakpoint?.topColor ??
    defaultBorderObj.topColor
  const topStyle =
    borderObj?.topStyle ??
    borderDesktopBehaviour?.topStyle ??
    borderDefaultBreakpoint?.topStyle ??
    defaultBorderObj.topStyle
  const topWidth =
    borderObj?.topWidth ??
    borderDesktopBehaviour?.topWidth ??
    borderDefaultBreakpoint?.topWidth ??
    defaultBorderObj.topWidth
  const topEnable =
    borderObj?.topEnable ??
    borderDesktopBehaviour?.topEnable ??
    borderDefaultBreakpoint?.topEnable ??
    defaultBorderObj.topEnable
  const leftColor =
    borderObj?.leftColor ??
    borderDesktopBehaviour?.leftColor ??
    borderDefaultBreakpoint?.leftColor ??
    defaultBorderObj.leftColor
  const leftStyle =
    borderObj?.leftStyle ??
    borderDesktopBehaviour?.leftStyle ??
    borderDefaultBreakpoint?.leftStyle ??
    defaultBorderObj.leftStyle
  const leftWidth =
    borderObj?.leftWidth ??
    borderDesktopBehaviour?.leftWidth ??
    borderDefaultBreakpoint?.leftWidth ??
    defaultBorderObj.leftWidth
  const leftEnable =
    borderObj?.leftEnable ??
    borderDesktopBehaviour?.leftEnable ??
    borderDefaultBreakpoint?.leftEnable ??
    defaultBorderObj.leftEnable
  const rightColor =
    borderObj?.rightColor ??
    borderDesktopBehaviour?.rightColor ??
    borderDefaultBreakpoint?.rightColor ??
    defaultBorderObj.rightColor
  const rightStyle =
    borderObj?.rightStyle ??
    borderDesktopBehaviour?.rightStyle ??
    borderDefaultBreakpoint?.rightStyle ??
    defaultBorderObj.rightStyle
  const rightWidth =
    borderObj?.rightWidth ??
    borderDesktopBehaviour?.rightWidth ??
    borderDefaultBreakpoint?.rightWidth ??
    defaultBorderObj.rightWidth
  const rightEnable =
    borderObj?.rightEnable ??
    borderDesktopBehaviour?.rightEnable ??
    borderDefaultBreakpoint?.rightEnable ??
    defaultBorderObj.rightEnable
  return {
    enable,
    bottomColor,
    bottomStyle,
    bottomWidth,
    bottomEnable,
    topColor,
    topStyle,
    topWidth,
    topEnable,
    leftColor,
    leftStyle,
    leftWidth,
    leftEnable,
    rightColor,
    rightStyle,
    rightWidth,
    rightEnable,
  }
}

export function merge3(
  borderObj: BorderPropsOpt | undefined,
  borderDesktopBehaviour: BorderPropsOpt | undefined,
  borderDefaultBreakpoint: BorderPropsOpt | undefined,
  defaultBorderObj: BorderProps
): BorderProps {
  const bottomColor =
    borderObj?.bottomColor ??
    borderDesktopBehaviour?.bottomColor ??
    borderDefaultBreakpoint?.bottomColor ??
    defaultBorderObj.bottomColor
  const bottomStyle =
    borderObj?.bottomStyle ??
    borderDesktopBehaviour?.bottomStyle ??
    borderDefaultBreakpoint?.bottomStyle ??
    defaultBorderObj.bottomStyle
  const bottomWidth =
    borderObj?.bottomWidth ??
    borderDesktopBehaviour?.bottomWidth ??
    borderDefaultBreakpoint?.bottomWidth ??
    defaultBorderObj.bottomWidth
  const bottomEnable =
    borderObj?.bottomEnable ??
    borderDesktopBehaviour?.bottomEnable ??
    borderDefaultBreakpoint?.bottomEnable ??
    defaultBorderObj.bottomEnable
  const topColor =
    borderObj?.topColor ??
    borderDesktopBehaviour?.topColor ??
    borderDefaultBreakpoint?.topColor ??
    defaultBorderObj.topColor
  const topStyle =
    borderObj?.topStyle ??
    borderDesktopBehaviour?.topStyle ??
    borderDefaultBreakpoint?.topStyle ??
    defaultBorderObj.topStyle
  const topWidth =
    borderObj?.topWidth ??
    borderDesktopBehaviour?.topWidth ??
    borderDefaultBreakpoint?.topWidth ??
    defaultBorderObj.topWidth
  const topEnable =
    borderObj?.topEnable ??
    borderDesktopBehaviour?.topEnable ??
    borderDefaultBreakpoint?.topEnable ??
    defaultBorderObj.topEnable
  const leftColor =
    borderObj?.leftColor ??
    borderDesktopBehaviour?.leftColor ??
    borderDefaultBreakpoint?.leftColor ??
    defaultBorderObj.leftColor
  const leftStyle =
    borderObj?.leftStyle ??
    borderDesktopBehaviour?.leftStyle ??
    borderDefaultBreakpoint?.leftStyle ??
    defaultBorderObj.leftStyle
  const leftWidth =
    borderObj?.leftWidth ??
    borderDesktopBehaviour?.leftWidth ??
    borderDefaultBreakpoint?.leftWidth ??
    defaultBorderObj.leftWidth
  const leftEnable =
    borderObj?.leftEnable ??
    borderDesktopBehaviour?.leftEnable ??
    borderDefaultBreakpoint?.leftEnable ??
    defaultBorderObj.leftEnable
  const rightColor =
    borderObj?.rightColor ??
    borderDesktopBehaviour?.rightColor ??
    borderDefaultBreakpoint?.rightColor ??
    defaultBorderObj.rightColor
  const rightStyle =
    borderObj?.rightStyle ??
    borderDesktopBehaviour?.rightStyle ??
    borderDefaultBreakpoint?.rightStyle ??
    defaultBorderObj.rightStyle
  const rightWidth =
    borderObj?.rightWidth ??
    borderDesktopBehaviour?.rightWidth ??
    borderDefaultBreakpoint?.rightWidth ??
    defaultBorderObj.rightWidth
  const rightEnable =
    borderObj?.rightEnable ??
    borderDesktopBehaviour?.rightEnable ??
    borderDefaultBreakpoint?.rightEnable ??
    defaultBorderObj.rightEnable

  return {
    bottomColor,
    bottomStyle,
    bottomWidth,
    bottomEnable,
    topColor,
    topStyle,
    topWidth,
    topEnable,
    leftColor,
    leftStyle,
    leftWidth,
    leftEnable,
    rightColor,
    rightStyle,
    rightWidth,
    rightEnable,
  }
}

export type { BorderCSS, StylesBorder, GSBorder, BorderProps, BorderPropsOpt, Border, BorderOpt }
