import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
} from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { get2WithNull, get2WithNull4Enable } from "../bobUtils"

const nullable = <A>(t: Type<A>) => union([t, nullC])

const UnitCodec = union([literal("px"), literal("%")])
const AlignContentCodec = union([
  literal("flex-start"),
  literal("flex-end"),
  literal("stretch"),
  literal("center"),
  literal("space-around"),
  literal("space-between"),
])
const AlignItemsCodec = union([
  literal("flex-start"),
  literal("flex-end"),
  literal("stretch"),
  literal("center"),
  literal("baseline"),
  literal("first-baseline"),
  literal("last-baseline"),
])
const DirectionCodec = union([literal("row"), literal("row-reverse"), literal("column"), literal("column-reverse")])
const JustifyContentCodec = union([
  literal("flex-start"),
  literal("flex-end"),
  literal("center"),
  literal("space-between"),
  literal("space-around"),
  literal("space-evenly"),
])
const WrapCodec = union([literal("nowrap"), literal("wrap"), literal("wrap-reverse")])
const ColumnGapCodec = type({
  enable: booleanC,
  value: numberC,
  unit: UnitCodec,
})
const RowGapCodec = type({
  enable: booleanC,
  value: numberC,
  unit: UnitCodec,
})
const FlexCodec = type({
  alignContent: AlignContentCodec,
  alignItems: AlignItemsCodec,
  direction: DirectionCodec,
  gap: type({ column: ColumnGapCodec, row: RowGapCodec }),
  justifyContent: JustifyContentCodec,
  wrap: WrapCodec,
})

const ColumnGapOptCodec = partial({
  enable: nullable(booleanC),
  value: nullable(numberC),
  unit: nullable(UnitCodec),
})
const RowGapOptCodec = partial({
  enable: nullable(booleanC),
  value: nullable(numberC),
  unit: nullable(UnitCodec),
})
const FlexOptCodec = partial({
  alignContent: nullable(AlignContentCodec),
  alignItems: nullable(AlignItemsCodec),
  direction: nullable(DirectionCodec),
  gap: partial({
    column: nullable(ColumnGapOptCodec),
    row: nullable(RowGapOptCodec),
  }),
  justifyContent: nullable(JustifyContentCodec),
  wrap: nullable(WrapCodec),
})

const StylesFlexCodec = intersection([
  type({ flex: FlexCodec }),
  partial({
    behaviour: partial({
      active: partial({
        flex: FlexOptCodec,
      }),
      hover: partial({
        flex: FlexOptCodec,
      }),
    }),
    mobile: partial({
      flex: FlexOptCodec,
      behaviour: partial({
        active: partial({
          flex: FlexOptCodec,
        }),
        hover: partial({
          flex: FlexOptCodec,
        }),
      }),
    }),
    tablet: partial({
      flex: FlexOptCodec,
      behaviour: partial({
        active: partial({
          flex: FlexOptCodec,
        }),
        hover: partial({
          flex: FlexOptCodec,
        }),
      }),
    }),
  }),
])

type Flex = TypeOf<typeof FlexCodec>
type FlexOpt = TypeOf<typeof FlexOptCodec>
type StylesFlex = TypeOf<typeof StylesFlexCodec>
type FlexCSS = {
  "align-content": string
  "align-items": string
  "flex-direction": string
  "column-gap": string | undefined
  "row-gap": string | undefined
  "justify-content": string
  "flex-wrap": string
}

const DefaultFlexCSS = {
  "align-content": "flex-start",
  "align-items": "flex-start",
  "flex-direction": "row",
  "column-gap": "0px",
  "row-gap": "0px",
  "justify-content": "flex-start",
  "flex-wrap": "nowrap",
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<FlexCSS> {
  const styles = StylesFlexCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return DefaultFlexCSS
}

export function cssRender(
  stylesObj: StylesFlex,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<FlexCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj.flex)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj.flex, mergeBob2(stylesObj?.behaviour?.[behaviourState]?.flex, stylesObj.flex))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.flex, mergeBob2(stylesObj?.[breakpoint]?.flex, stylesObj.flex))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.flex,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.flex,
          stylesObj?.behaviour?.[behaviourState]?.flex,
          stylesObj?.[breakpoint]?.flex,
          stylesObj.flex
        )
      )
    }
  }
}

export function render(flexObj: Flex): FlexCSS {
  return {
    "flex-direction": flexObj.direction,
    "flex-wrap": flexObj.wrap,
    "justify-content": flexObj.justifyContent,
    "align-items": flexObj.alignItems,
    "align-content": flexObj.alignContent,
    "column-gap": flexObj.gap.column.enable ? `${flexObj.gap.column.value}${flexObj.gap.column.unit}` : undefined,
    "row-gap": flexObj.gap.row.enable ? `${flexObj.gap.row.value}${flexObj.gap.row.unit}` : undefined,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param flexValue
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(defaultSelfFlexObj: Flex, flexValue: FlexOpt | undefined): Partial<FlexCSS> {
  if (flexValue) {
    return renderOpt(defaultSelfFlexObj, flexValue)
  }
  return {}
}

/**
 * Renders FlexOpt css for breakpoints/state templates
 * Returns flex
 * or empty for non written style props
 *
 * @param flexValue
 * @param foundationStyle
 * @returns
 */
export function renderOpt(defaultSelfFlexObj: Flex, flexValue: FlexOpt): Partial<FlexCSS> {
  let css = {}
  if (valueExists(flexValue?.direction)) css = { ...css, "flex-direction": `${flexValue?.direction}` }
  if (valueExists(flexValue?.wrap)) css = { ...css, "flex-wrap": `${flexValue?.wrap}` }
  if (valueExists(flexValue?.justifyContent)) css = { ...css, "justify-content": `${flexValue?.justifyContent}` }
  if (valueExists(flexValue?.alignItems)) css = { ...css, "align-items": `${flexValue?.alignItems}` }
  if (valueExists(flexValue?.alignContent)) css = { ...css, "align-content": `${flexValue?.alignContent}` }
  if (valueExists(flexValue?.gap?.column?.enable)) {
    if (flexValue?.gap?.column?.enable === false) {
      if (defaultSelfFlexObj.gap.column?.enable) css = { ...css, "column-gap": `initial` }
    }
    if (
      flexValue?.gap?.column?.enable === true &&
      (valueExists(flexValue?.gap?.column?.value) || valueExists(flexValue?.gap?.column?.unit))
    )
      css = { ...css, "column-gap": `${flexValue?.gap?.column?.value}${flexValue?.gap?.column?.unit}` }
  }
  if (valueExists(flexValue?.gap?.row?.enable)) {
    if (flexValue?.gap?.row?.enable === false) {
      if (defaultSelfFlexObj.gap.row?.enable) css = { ...css, "row-gap": `initial` }
    }
    if (
      flexValue?.gap?.row?.enable === true &&
      (valueExists(flexValue?.gap?.row?.value) || valueExists(flexValue?.gap?.row?.unit))
    )
      css = { ...css, "row-gap": `${flexValue?.gap?.row?.value}${flexValue?.gap?.row?.unit}` }
  }

  return css
}

export function mergeBob2(flexObj: FlexOpt | undefined, defaultFlexObj: Flex): FlexOpt {
  if (!flexObj) {
    return {
      gap: undefined,
      alignContent: undefined,
      alignItems: undefined,
      direction: undefined,
      justifyContent: undefined,
      wrap: undefined,
    }
  }

  const flex: FlexOpt = {
    gap: {
      column: {
        enable: flexObj?.gap?.column?.enable,
        value: undefined,
        unit: undefined,
      },
      row: {
        enable: flexObj?.gap?.row?.enable,
        value: undefined,
        unit: undefined,
      },
    },
    alignContent: flexObj?.alignContent,
    alignItems: flexObj?.alignItems,
    direction: flexObj?.direction,
    justifyContent: flexObj?.justifyContent,
    wrap: flexObj?.wrap,
  }
  const gapColumnEnable = get2WithNull4Enable(flexObj?.gap?.column?.enable, defaultFlexObj.gap.column.enable)
  const gapRowEnable = get2WithNull4Enable(flexObj?.gap?.row?.enable, defaultFlexObj.gap.row.enable)

  if (valueExists(flexObj?.gap?.column?.value) || valueExists(flexObj?.gap?.column?.unit)) {
    const columnValue = get2WithNull(flexObj?.gap?.column?.value, defaultFlexObj.gap.column.value)
    const columnUnit = get2WithNull(flexObj?.gap?.column?.unit, defaultFlexObj.gap.column.unit)
    flex.gap = {
      ...flex.gap,
      column: {
        enable: gapColumnEnable,
        value: columnValue,
        unit: columnUnit,
      },
    }
  }
  if (valueExists(flexObj?.gap?.row?.value) || valueExists(flexObj?.gap?.row?.unit)) {
    const rowValue = get2WithNull(flexObj?.gap?.row?.value, defaultFlexObj.gap.row.value)
    const rowUnit = get2WithNull(flexObj?.gap?.row?.unit, defaultFlexObj.gap.row.unit)
    flex.gap = {
      ...flex.gap,
      row: {
        enable: gapRowEnable,
        value: rowValue,
        unit: rowUnit,
      },
    }
  }

  return flex
}

export function mergeBob3(
  flexObj: FlexOpt | undefined,
  flexDesktopBehaviour: FlexOpt | undefined,
  flexDefaultBreakpoint: FlexOpt | undefined,
  defaultFlexObj: Flex
): FlexOpt {
  if (!flexObj) {
    return {
      gap: undefined,
      alignContent: undefined,
      alignItems: undefined,
      direction: undefined,
      justifyContent: undefined,
      wrap: undefined,
    }
  }

  const gapColumnEnable =
    flexObj?.gap?.column?.enable ??
    flexDesktopBehaviour?.gap?.column?.enable ??
    flexDefaultBreakpoint?.gap?.column?.enable ??
    defaultFlexObj.gap.column.enable
  const gapRowEnable =
    flexObj?.gap?.row?.enable ??
    flexDesktopBehaviour?.gap?.row?.enable ??
    flexDefaultBreakpoint?.gap?.row?.enable ??
    defaultFlexObj.gap.row.enable

  if (
    valueExists(flexObj?.gap?.column?.value) ||
    valueExists(flexObj?.gap?.column?.unit) ||
    valueExists(flexObj?.gap?.row?.value) ||
    valueExists(flexObj?.gap?.row?.unit)
  ) {
    const columnValue =
      flexObj?.gap?.column?.value ??
      flexDesktopBehaviour?.gap?.column?.value ??
      flexDefaultBreakpoint?.gap?.column?.value ??
      defaultFlexObj.gap.column.value
    const columnUnit =
      flexObj?.gap?.column?.unit ??
      flexDesktopBehaviour?.gap?.column?.unit ??
      flexDefaultBreakpoint?.gap?.column?.unit ??
      defaultFlexObj.gap.column.unit
    const rowValue =
      flexObj?.gap?.row?.value ??
      flexDesktopBehaviour?.gap?.row?.value ??
      flexDefaultBreakpoint?.gap?.row?.value ??
      defaultFlexObj.gap.row.value
    const rowUnit =
      flexObj?.gap?.row?.unit ??
      flexDesktopBehaviour?.gap?.row?.unit ??
      flexDefaultBreakpoint?.gap?.row?.unit ??
      defaultFlexObj.gap.row.unit

    return {
      gap: {
        column: {
          enable: gapColumnEnable,
          value: columnValue,
          unit: columnUnit,
        },
        row: {
          enable: gapRowEnable,
          value: rowValue,
          unit: rowUnit,
        },
      },
      alignContent: flexObj?.alignContent,
      alignItems: flexObj?.alignItems,
      direction: flexObj?.direction,
      justifyContent: flexObj?.justifyContent,
      wrap: flexObj?.wrap,
    }
  }

  return {
    gap: {
      column: {
        enable: flexObj?.gap?.column?.enable,
        value: undefined,
        unit: undefined,
      },
      row: {
        enable: flexObj?.gap?.row?.enable,
        value: undefined,
        unit: undefined,
      },
    },
    alignContent: undefined,
    alignItems: undefined,
    direction: undefined,
    justifyContent: undefined,
    wrap: undefined,
  }
}

export type { FlexCSS, StylesFlex, Flex, FlexOpt }

