import { FormAction } from '../../../modules/shared-modules/content/contentLibraryTypes'
import {
  TOGGLE_CONTENT_FORM_STATE,
  CLEAN_CONTENT_LIBRARY,
  RECEIVE_CONTENT_LIBRARY,
  ERROR_FETCH_CONTENT_LIBRARY,
  FETCHING_CONTENT_LIBRARY,
  VALID_CONTENT_PAGE_POST_LINK,
  INVALID_CONTENT_PAGE_POST_LINK,
  REQUEST_VALIDATE_CONTENT_PAGE_POST_LINK,
  CLEAN_VALIDATE_CONTENT_PAGE_POST_LINK,
  SUCCESS_FETCH_POSTS_TO_TEST_CONTENT_PAGE,
  ERROR_FETCH_POSTS_TO_TEST_CONTENT_PAGE,
  REQUEST_POSTS_TO_TEST_CONTENT_PAGE,
  SUCCESS_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE,
  ERROR_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE,
  REQUEST_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE,
  CLEAN_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE,
  SUCCESS_FETCH_PREVIEW_CONTENT_PAGE_POST,
  ERROR_FETCH_PREVIEW_CONTENT_PAGE_POST,
  REQUEST_PREVIEW_CONTENT_PAGE_POST,
  CLEAN_FETCH_PREVIEW_CONTENT_PAGE_POST,
  SUCCESS_FETCH_PUBLIC_CONTENT_PAGE_POST,
  ERROR_FETCH_PUBLIC_CONTENT_PAGE_POST,
  REQUEST_PUBLIC_CONTENT_PAGE_POST,
  CLEAN_FETCH_PUBLIC_CONTENT_PAGE_POST,
  SUCCESS_DETACH_CONTENT_PAGE_POST,
  ERROR_DETACH_CONTENT_PAGE_POST,
  REQUEST_DETACH_CONTENT_PAGE_POST,
  CLEAN_DETACH_CONTENT_PAGE_POST,
  SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_POST,
  ERROR_FETCH_CONTENT_PAGE_LIST_BY_POST,
  REQUEST_FETCH_CONTENT_PAGE_LIST_BY_POST,
  CLEAN_FETCH_CONTENT_PAGE_LIST_BY_POST,
  SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE,
  ERROR_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE,
  REQUEST_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE,
  CLEAN_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE,
  ERROR_CREATE_POST,
  REQUEST_CREATE_POST,
  SUCCESS_CREATE_POST,
  CLEAN_CREATE_POST,
  ERROR_UPDATE_POST,
  REQUEST_UPDATE_POST,
  SUCCESS_UPDATE_POST,
  CLEAN_UPDATE_POST,
  ADD_MEDIA_TO_CONTENT_LIBRARY_RESPONSE,
  DRAFT_POST,
  SUCCESS_FETCH_SINGLE_POST,
  REQUEST_SINGLE_POST,
  ERROR_FETCH_SINGLE_POST,
  CLEAN_FETCH_SINGLE_POST,
  TOGGLE_DYNAMIC_CONTENT_FORM_STATE,
  SUCCESS_UPDATE_POST_WITH_SCHEMA,
  CLEAN_UPDATE_POST_WITH_SCHEMA,
  ERROR_UPDATE_POST_WITH_SCHEMA,
  REQUEST_UPDATE_POST_WITH_SCHEMA,
  TOGGLE_CONTENT_LIBRARY_PANEL,
} from "./actionTypes"
import {
  getLibrary,
  validContentPagePostUrl,
  getPostsToTest,
  setDefaultPostToTest,
  getPreviewContentPagePost,
  getPublicContentPagePost,
  detachContentPagePost as contentLibraryDetachContentPagePost,
  contentPageListByPost,
  updatePost as contentLibraryUpdatePost,
  createPost as contentLibraryCreatePost,
  updatePostWithSchema as contentLibraryUpdatePostWithSchema,
  fetchContentPageListByWorkspace,
} from "../../../api/shared-api/contentLibraryApi"
import { ApiPostType, MediaObject } from "../../../modules/shared-modules/experienceManager/types/contentTypes"
import { Post } from "../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { AppDispatch } from "../types"
import { singleContentLibraryPost } from "../../../api/contentApi"

type Params = {
  search: string
  limit: number
  order: string
  orderBy: string
  cursor?: string
  shouldRefresh: boolean
}

export function toggleContentFormState(contentLibraryForm: boolean, formAction: FormAction, postObj?: any) {
  return {
    type: TOGGLE_CONTENT_FORM_STATE,
    contentLibraryForm,
    formAction,
    postObj,
  }
}

export function setContentFormState(contentLibraryForm: boolean, formAction: FormAction, postObj?: any) {
  return (dispatch: AppDispatch) => {
    dispatch(toggleContentFormState(contentLibraryForm, formAction, postObj))
  }
}

/************************** */

export function toggleDynamicContentFormState(contentLibraryForm: boolean, formAction: FormAction, postObjId?: string) {
  return {
    type: TOGGLE_DYNAMIC_CONTENT_FORM_STATE,
    contentLibraryForm,
    formAction,
    postObjId,
  }
}

export function setDynamicContentFormState(contentLibraryForm: boolean, formAction: FormAction, postObjId?: string) {
  return (dispatch: AppDispatch) => {
    dispatch(toggleDynamicContentFormState(contentLibraryForm, formAction, postObjId))
  }
}
/************************** */

export function toggleContentLibraryPanel(contentLibraryPanel: boolean) {
  return {
    type: TOGGLE_CONTENT_LIBRARY_PANEL,
    contentLibraryPanel,
  }
}

export function setContentLibraryPanel(contentLibraryPanel: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch(toggleContentLibraryPanel(contentLibraryPanel))
  }
}

export function cleanContentLibrary() {
  return {
    type: CLEAN_CONTENT_LIBRARY,
  }
}

export function clearContentLibrary() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanContentLibrary())
  }
}

export function receiveContentLibrary(response: any, params: Params) {
  return {
    type: RECEIVE_CONTENT_LIBRARY,
    response,
    params,
  }
}

export function errorFetchContentLibrary(error: any) {
  return {
    type: ERROR_FETCH_CONTENT_LIBRARY,
    error,
  }
}

export function fetchingContentLibrary() {
  return {
    type: FETCHING_CONTENT_LIBRARY,
  }
}

export function getContentLibrary(
  profileId: string,
  token: any,
  order: string,
  orderBy: string,
  limit: number,
  search: string,
  shouldRefresh: boolean,
  cursor?: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchingContentLibrary())
    getLibrary(profileId, token, order, orderBy, limit, search, cursor)
      .then((response) => {
        dispatch(
          receiveContentLibrary(response, {
            search,
            order,
            orderBy,
            limit,
            cursor,
            shouldRefresh,
          })
        )
      })
      .catch((error) => {
        dispatch(errorFetchContentLibrary(error))
      })
  }
}

/************************** */

export function validContentPagePostLink(response: any) {
  return {
    type: VALID_CONTENT_PAGE_POST_LINK,
    response,
  }
}

export function invalidContentPagePostLink(error: any) {
  return {
    type: INVALID_CONTENT_PAGE_POST_LINK,
    error,
  }
}

export function requestValidateContentPagePostLink() {
  return {
    type: REQUEST_VALIDATE_CONTENT_PAGE_POST_LINK,
  }
}

export function validateContentPagePostLink(
  postTitle: string,
  postId: string,
  contentPageId: string,
  profileId: string,
  token: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestValidateContentPagePostLink())
    validContentPagePostUrl(postTitle, postId, contentPageId, profileId, token)
      .then((response) => {
        dispatch(validContentPagePostLink(response))
      })
      .catch((error) => {
        dispatch(invalidContentPagePostLink(error))
      })
  }
}

export function cleanValidateContentPagePostLink() {
  return {
    type: CLEAN_VALIDATE_CONTENT_PAGE_POST_LINK,
  }
}

export function clearValidateContentPagePostLink() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanValidateContentPagePostLink())
  }
}

/************************** */

export function successFetchPostsToTestContentPage(response: any) {
  return {
    type: SUCCESS_FETCH_POSTS_TO_TEST_CONTENT_PAGE,
    response,
  }
}

export function errorFetchPostsToTestContentPage(error: any) {
  return {
    type: ERROR_FETCH_POSTS_TO_TEST_CONTENT_PAGE,
    error,
  }
}

export function requestPostsToTestContentPage() {
  return {
    type: REQUEST_POSTS_TO_TEST_CONTENT_PAGE,
  }
}

export function fetchPostsToTestContentPage(objectRootId: string, profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestPostsToTestContentPage())
    getPostsToTest(objectRootId, profileId, token)
      .then((response) => {
        dispatch(successFetchPostsToTestContentPage(response))
      })
      .catch((error) => {
        dispatch(errorFetchPostsToTestContentPage(error))
      })
  }
}

/************************** */

export function successSetDefaultPostToTestContentPage(response: any) {
  return {
    type: SUCCESS_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE,
    response,
  }
}

export function errorSetDefaultPostToTestContentPage(error: any) {
  return {
    type: ERROR_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE,
    error,
  }
}

export function requestSetDefaultPostToTestContentPage() {
  return {
    type: REQUEST_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE,
  }
}

export function setDefaultPostToTestContentPage(objectRootId: string, post: Post, profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSetDefaultPostToTestContentPage())
    setDefaultPostToTest(objectRootId, post, profileId, token)
      .then((response) => {
        dispatch(successSetDefaultPostToTestContentPage({ response, post }))
      })
      .catch((error) => {
        dispatch(errorSetDefaultPostToTestContentPage(error))
      })
  }
}

/* */

export function cleanSetDefaultPostTestContentPage() {
  return {
    type: CLEAN_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE,
  }
}

export function clearSetDefaultPostTestContentPage() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanSetDefaultPostTestContentPage())
  }
}

/************************** */

export function successFetchPreviewContentPagePost(response: any) {
  return {
    type: SUCCESS_FETCH_PREVIEW_CONTENT_PAGE_POST,
    response,
  }
}

export function errorFetchPreviewContentPagePost(error: any) {
  return {
    type: ERROR_FETCH_PREVIEW_CONTENT_PAGE_POST,
    error,
  }
}

export function requestPreviewContentPagePost() {
  return {
    type: REQUEST_PREVIEW_CONTENT_PAGE_POST,
  }
}

export function fetchPreviewContentPagePost(hostId: string, pageUri: string, slug: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestPreviewContentPagePost())
    getPreviewContentPagePost(hostId, pageUri, slug)
      .then((response) => {
        dispatch(successFetchPreviewContentPagePost(response))
      })
      .catch((error) => {
        dispatch(errorFetchPreviewContentPagePost(error))
      })
  }
}

export function cleanFetchPreviewContentPagePost() {
  return {
    type: CLEAN_FETCH_PREVIEW_CONTENT_PAGE_POST,
  }
}

export function clearFetchPreviewContentPagePost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanFetchPreviewContentPagePost())
  }
}
/************************** */

export function successFetchPublicContentPagePost(response: any) {
  return {
    type: SUCCESS_FETCH_PUBLIC_CONTENT_PAGE_POST,
    response,
  }
}

export function errorFetchPublicContentPagePost(error: any) {
  return {
    type: ERROR_FETCH_PUBLIC_CONTENT_PAGE_POST,
    error,
  }
}

export function requestPublicContentPagePost() {
  return {
    type: REQUEST_PUBLIC_CONTENT_PAGE_POST,
  }
}

export function fetchPublicContentPagePost(postId?: string, url?: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestPublicContentPagePost())
    getPublicContentPagePost(postId, url)
      .then((response) => {
        dispatch(successFetchPublicContentPagePost(response))
      })
      .catch((error) => {
        dispatch(errorFetchPublicContentPagePost(error))
      })
  }
}

export function cleanFetchPublicContentPagePost() {
  return {
    type: CLEAN_FETCH_PUBLIC_CONTENT_PAGE_POST,
  }
}

export function clearFetchPublicContentPagePost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanFetchPublicContentPagePost())
  }
}

/************************** */

export function successDetachContentPagePost(response: any) {
  return {
    type: SUCCESS_DETACH_CONTENT_PAGE_POST,
    response,
  }
}

export function errorDetachContentPagePost(error: any) {
  return {
    type: ERROR_DETACH_CONTENT_PAGE_POST,
    error,
  }
}

export function requestDetachContentPagePost() {
  return {
    type: REQUEST_DETACH_CONTENT_PAGE_POST,
  }
}

export function detachContentPagePost(objectRootId: string, profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDetachContentPagePost())
    contentLibraryDetachContentPagePost(objectRootId, profileId, token)
      .then((response) => {
        dispatch(successDetachContentPagePost(response))
      })
      .catch((error) => {
        dispatch(errorDetachContentPagePost(error))
      })
  }
}

export function cleanDetachContentPagePost() {
  return {
    type: CLEAN_DETACH_CONTENT_PAGE_POST,
  }
}

export function clearDetachContentPagePost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanDetachContentPagePost())
  }
}
/************************** */

export function successFetchContentPageListByPost(response: any) {
  return {
    type: SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_POST,
    response,
  }
}

export function errorFetchContentPageListByPost(error: any) {
  return {
    type: ERROR_FETCH_CONTENT_PAGE_LIST_BY_POST,
    error,
  }
}

export function requestFetchContentPageListByPost() {
  return {
    type: REQUEST_FETCH_CONTENT_PAGE_LIST_BY_POST,
  }
}

export function FetchContentPageListByPost(sourceId: string, profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchContentPageListByPost())
    contentPageListByPost(sourceId, profileId, token)
      .then((response) => {
        dispatch(successFetchContentPageListByPost(response))
      })
      .catch((error) => {
        dispatch(errorFetchContentPageListByPost(error))
      })
  }
}

export function cleanFetchContentPageListByPost() {
  return {
    type: CLEAN_FETCH_CONTENT_PAGE_LIST_BY_POST,
  }
}

export function clearFetchContentPageListByPost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanFetchContentPageListByPost())
  }
}
/************************** */

export function successFetchContentPageListByWorkspace(response: any) {
  return {
    type: SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE,
    response,
  }
}

export function errorFetchContentPageListByWorkspace(error: any) {
  return {
    type: ERROR_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE,
    error,
  }
}

export function requestFetchContentPageListByWorkspace() {
  return {
    type: REQUEST_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE,
  }
}

export function FetchContentPageListByWorkspace(profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchContentPageListByWorkspace())
    fetchContentPageListByWorkspace(profileId, token)
      .then((response) => {
        dispatch(successFetchContentPageListByWorkspace(response))
      })
      .catch((error) => {
        dispatch(errorFetchContentPageListByWorkspace(error))
      })
  }
}

export function cleanFetchContentPageListByWorkspace() {
  return {
    type: CLEAN_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE,
  }
}

export function clearFetchContentPageListByWorkspace() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanFetchContentPageListByWorkspace())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCreatePost(error: any) {
  return {
    type: ERROR_CREATE_POST,
    error,
  }
}

export function requestCreatePost() {
  return {
    type: REQUEST_CREATE_POST,
  }
}

export function successCreatePost(response: any) {
  return {
    type: SUCCESS_CREATE_POST,
    response,
  }
}

export function createPost(postObj: any, profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreatePost())
    return contentLibraryCreatePost(postObj, profileId, token)
      .then((response: any) => {
        dispatch(successCreatePost(response))
      })
      .catch((error: any) => {
        dispatch(errorCreatePost(error))
        throw error
      })
  }
}

export function cleanCreatePost() {
  return {
    type: CLEAN_CREATE_POST,
  }
}

export function clearCreatePost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanCreatePost())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUpdatePost(error: any) {
  return {
    type: ERROR_UPDATE_POST,
    error,
  }
}

export function requestUpdatePost() {
  return {
    type: REQUEST_UPDATE_POST,
  }
}

export function successUpdatePost(response: any) {
  return {
    type: SUCCESS_UPDATE_POST,
    response,
  }
}

export function updatePost(postObj: any, profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdatePost())
    return contentLibraryUpdatePost(postObj, profileId, token)
      .then((response: any) => {
        dispatch(successUpdatePost(response))
      })
      .catch((error: any) => {
        dispatch(errorUpdatePost(error))
        throw error
      })
  }
}

export function cleanUpdatePost() {
  return {
    type: CLEAN_UPDATE_POST,
  }
}

export function clearUpdatePost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanUpdatePost())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUpdatePostWithSchema(error: any) {
  return {
    type: ERROR_UPDATE_POST_WITH_SCHEMA,
    error,
  }
}

export function requestUpdatePostWithSchema() {
  return {
    type: REQUEST_UPDATE_POST_WITH_SCHEMA,
  }
}

export function successUpdatePostWithSchema(response: any) {
  return {
    type: SUCCESS_UPDATE_POST_WITH_SCHEMA,
    response,
  }
}

export function updatePostWithSchema(postObj: ApiPostType, postId: string, profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdatePostWithSchema())
    return contentLibraryUpdatePostWithSchema(postObj, postId, profileId, token)
      .then((response: any) => {
        dispatch(successUpdatePostWithSchema(response))
      })
      .catch((error: any) => {
        dispatch(errorUpdatePostWithSchema(error))
        throw error
      })
  }
}

export function cleanUpdatePostWithSchema() {
  return {
    type: CLEAN_UPDATE_POST_WITH_SCHEMA,
  }
}

export function clearUpdatePostWithSchema() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanUpdatePostWithSchema())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function addMediaToContentLibraryResponse(mediaObj: MediaObject) {
  return {
    type: ADD_MEDIA_TO_CONTENT_LIBRARY_RESPONSE,
    mediaObj,
  }
}

export function updateContentLibraryMedia(mediaObj: MediaObject) {
  return (dispatch: AppDispatch) => {
    dispatch(addMediaToContentLibraryResponse(mediaObj))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function draftPost(postObj: any) {
  return {
    type: DRAFT_POST,
    postObj,
  }
}

export function addDraftPost(postObj: any) {
  return (dispatch: AppDispatch) => {
    dispatch(draftPost(postObj))
  }
}

export function errorFetchSinglePost(error: any) {
  return {
    type: ERROR_FETCH_SINGLE_POST,
    error,
  }
}

export function requestSinglePost() {
  return {
    type: REQUEST_SINGLE_POST,
  }
}

export function successFetchSinglePost(response: any) {
  return {
    type: SUCCESS_FETCH_SINGLE_POST,
    response,
  }
}

export function fetchSinglePost(postId: string, profileId: string, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSinglePost())
    return singleContentLibraryPost(postId, profileId, token)
      .then((response: any) => {
        dispatch(successFetchSinglePost(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchSinglePost(error))
        throw error
      })
  }
}

export function cleanFetchSinglePost() {
  return {
    type: CLEAN_FETCH_SINGLE_POST,
  }
}

export function clearFetchSinglePost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanFetchSinglePost())
  }
}