import React, { Fragment } from 'react'
import classes from 'classnames'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { handler } from "../../../../../modules/routeCreator"
import BobComponentHandler from "../../../shared-public-page/bob/bobComponentHandler"
import { getStringField } from "../../../../../modules/shared-modules/utilities/components"
import AlignmentHOC from "../../../shared-public-page/fluxio-components/alignmentHOC"
import { alignmentWithAP } from "../../../../../modules/shared-modules/utilities/conditionalController"
import { BobHorizontalTabsProps } from "./interface"
import tabsContainerDropdownStyle from "../../../../../stylesheets/modules/templates/tabsContainerDropdown.module.sass"

//import ComponentHandler from '../../hub/../ComponentHandler'

/**
 * React Bootstrap Tabs component
 * usage:
 * <Tabs selected="Tab 1">
 *     <Tab label="Tab 1">
 *         <div>Tab 1 contents!</div>
 *     </Tab>
 *     <Tab label="Tab 2">
 *         <div>Tab 2 contents!</div>
 *     </Tab>
 * </Tabs>
 */
class BobHorizontalTabs extends React.Component<BobHorizontalTabsProps, any> {
  static propTypes = {
    /**
     * The selected tab - either the index of it or the label string.  Defaults to tab 0 if not supplied
     */
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * Optional CSS class to apply to the component overall
     */
    className: PropTypes.string,

    /**
     * Optional CSS class to apply to each tab header
     */
    headerClass: PropTypes.string,

    /**
     * Optional CSS class to apply to the active tab header
     */
    activeHeaderClass: PropTypes.string,

    /**
     * Optional CSS class to apply to the content container for the currently selected tab
     */
    contentClass: PropTypes.string,

    /**
     * Optional method to call when a tab is selected.  Receive the tab index and tab label of the selected tab
     */
    onSelect: PropTypes.func,

    /**
     * At least one tab is required - otherwise there's no point rendering this!
     */
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  }

  static defaultProps = {
    selected: 0,
  }

  constructor(props: BobHorizontalTabsProps) {
    super(props)
    let intPageSelected = 0
    if (props.expManager.enable) {
      let tabSelected = localStorage.getItem(`bob-tabs-tab${props.objectUuid}`)
      if (tabSelected) {
        intPageSelected = parseInt(tabSelected, 10)
      }
    }
    this.state = {
      selected: props.expManager.enable ? intPageSelected : props.selected,
      selectOption: undefined,
      dropdownClose: true,
    }

    this.handleSelectedTab = this.handleSelectedTab.bind(this)
    this._renderHeader = this._renderHeader.bind(this)
  }

  componentDidMount() {
    // handle select active option url on init
    if (!this.props.expManager.enable) {
      let activeTab = this.props.children.filter((item: any) => item.props.component.objectUuid === this.props.selected)
      // only write url for first tabs
      // dont write for tabs inside tabs
      if (this.props.matchOrder === 1) {
        if (activeTab && activeTab.length > 0)
          this.props.router?.replace(
            handler(this.props.router, this.props.matchOrder, activeTab[0].tabLabel),
            undefined,
            { scroll: false }
          )
        if (this.props.selected === 0)
          this.props.router?.replace(
            handler(this.props.router, this.props.matchOrder, this.props.children[0].props.tabLabel),
            undefined,
            { scroll: false }
          )
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: BobHorizontalTabsProps) {
    /**
     * To prevent the loss of the selected tab
     * when the user changes this component,
     * we save the selected tab in the localStorage
     */
    let tabSelectedOnEM = localStorage.getItem(`bob-tabs-tab${this.props.objectUuid}`)
    let IntTabSelected = 0
    if (tabSelectedOnEM && this.props.expManager.enable) {
      IntTabSelected = parseInt(tabSelectedOnEM, 10)
      this.setState({ selected: IntTabSelected })
    } else {
      this.setState({ selected: nextProps.selected })
    }
  }

  handleSelectedTab(tab: any) {
    // handle select active option
    if (typeof tab === "string") {
      this.props.router?.replace(handler(this.props.router, this.props.matchOrder, tab), undefined, {
        scroll: false,
      })
    }
    // handle active tab
    localStorage.setItem(`bob-tabs-tab${this.props.objectUuid}`, tab)
    // localStorage.setItem(`bob-tabs-tab-label${this.props.objectUuid}`, tab)
    this.setState({
      ...this.state,
      selected: tab,
      dropdownClose: !this.state.dropdownClose,
    })
  }

  handleToggleDropdown = () => {
    this.setState({
      ...this.state,
      dropdownClose: !this.state.dropdownClose,
    })
  }

  /**
   * Render the tab headers
   * Show tabs on desktop and select on mobile
   * @returns {any} null if there are no children or a <ul> of tabs
   */
  _renderHeaders() {
    const background1 = this.props.componentTemplate.bobs.background1
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignmentWithAP(background1, {
      flexDirection: this.props.isVertical ? "column" : "row",
    })
    return (
      <Fragment>
        {this.renderDropdown()}
        <AlignmentHOC
          as={"ul"}
          role='tablist'
          className={`nav nav-pills ${tabsContainerDropdownStyle["nav-pills"]} nav-justified d-sm-flex ${
            this.state.dropdownClose ? `dropdown-close ${tabsContainerDropdownStyle["dropdown-close"]}` : ""
          }`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {Array.isArray(this.props.children)
            ? this.props.children.map(this._renderHeader.bind(this))
            : this._renderHeader(this.props.children, 0)}
        </AlignmentHOC>
      </Fragment>
    )
  }

  /**
   * Render an individual header
   * @param {any} tab - the actual tab (a <Tab>)
   * @param {number} index - the index of the tab in the set of tabs
   * @returns {any} - the header for the tab
   */
  _renderHeader = (tab: any, index: any) => {
    let isActive = false
    let tabComponent = tab.props.component
    if (typeof this.state.selected === "number") {
      // eslint-disable-next-line
      isActive = this.state.selected === index
    } else {
      isActive = this.state.selected === tab.props.tabLabel
    }
    const customHeaderClass = tab.props.headerClass
      ? tab.props.headerClass
      : this.props.headerClass
      ? this.props.headerClass
      : null
    const customActiveHeaderClass = !isActive
      ? null
      : tab.props.activeHeaderClass || tab.props.activeHeaderClass || `active ${tabsContainerDropdownStyle.active}`
    let linkClasses = classes(
      `nav-link ${tabsContainerDropdownStyle["nav-link"]}`,
      customHeaderClass,
      customActiveHeaderClass,
      {
        disabled: tab.props.disabled,
      }
    )
    return tabComponent.type === "object"
      ? this.renderTabSelector(tabComponent, tab.props.tabLabel, linkClasses, index, isActive)
      : []
  }

  renderTabSelector(tab: any, label: any, linkClasses: any, index: any, isActive: any) {
    let activeStyles = {
      borderTop: `2px solid ${this.props.bgColor}`,
      background: this.props.thirdColor,
      color: this.props.firstColor,
    }
    let inactiveStyles = { background: this.props.bgColorAlt, color: this.props.secondColor }
    let linkStyles = isActive ? activeStyles : inactiveStyles
    // render inner tabs
    // when tab has a tab child, and tabs is vertical
    let innerTabs: any
    if (this.props.isVertical) {
      innerTabs = tab.children.filter(
        (objectId: any) =>
          this.props.pageResponse.components[this.props.pageResponse.objects[objectId].styles.component].name ===
          "tabs_content_vertical"
      )
    }
    // render link inside/outside experience manager
    if (this.props.expManager.enable) {
      return (
        <BobComponentHandler
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={tab.selectedInstanceId}
          object={tab}
          objectId={tab.objectUuid}
          key={index}
          inlineContent={tab.content.inlineContent}
          overrides={tab.content.overrides}
          field={"background2"}
          rules={this.props.pageResponse.components[tab.styles.component]}
          template={tab.styles}
          expManager={this.props.expManager}
          className={`nav-item ${tabsContainerDropdownStyle["nav-item"]} ${
            isActive ? `active ${tabsContainerDropdownStyle.active}` : ""
          }`}
          onClick={() => this.handleSelectedTab(index)}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        >
          {/* eslint-disable-next-line*/}
          <span className={linkClasses} style={linkStyles}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={tab.selectedInstanceId}
              object={tab}
              objectId={tab.objectUuid}
              inlineContent={tab.content.inlineContent}
              overrides={tab.content.overrides}
              field='media1'
              template={tab.styles}
              rules={this.props.pageResponse.components[tab.styles.component]}
              expManager={this.props.expManager}
              network={tab.network}
              post={tab}
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={tab.selectedInstanceId}
              object={tab}
              objectId={tab.objectUuid}
              inlineContent={tab.content.inlineContent}
              overrides={tab.content.overrides}
              field='text1'
              tag='p'
              value={getStringField(tab, tab, this.props.pageResponse, "text1")}
              template={tab.styles}
              rules={this.props.pageResponse.components[tab.styles.component]}
              expManager={this.props.expManager}
              network={tab.network}
              className='network'
            />
            {innerTabs && innerTabs.length > 0
              ? innerTabs.map((innerTab: any, idx: any) => {
                  if (innerTabs.type === "object")
                    return (
                      <div key={idx} className='nav-item-inner'>
                        {this.renderTabSelector(innerTab, innerTab.objectUuid, linkClasses, 999, isActive)}
                      </div>
                    )
                  else return []
                })
              : []}
          </span>
        </BobComponentHandler>
      )
    }

    return (
      <BobComponentHandler
        position={this.props.position}
        pageResponse={this.props.pageResponse}
        instanceId={tab.selectedInstanceId}
        object={tab}
        objectId={this.props.objectUuid}
        key={index}
        inlineContent={tab.content.inlineContent}
        overrides={tab.content.overrides}
        field={"background2"}
        rules={this.props.pageResponse.components[tab.styles.component]}
        template={tab.styles}
        expManager={this.props.expManager}
        className={`nav-item ${tabsContainerDropdownStyle["nav-item"]} ${
          isActive ? `active ${tabsContainerDropdownStyle.active}` : ""
        }`}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        <Link href={handler(this.props.router, this.props.matchOrder, label)} passHref scroll={false}>
          <a className={`${linkClasses}`} style={linkStyles} onClick={() => this.handleToggleDropdown()}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={tab.selectedInstanceId}
              object={tab}
              objectId={this.props.objectUuid}
              inlineContent={tab.content.inlineContent}
              overrides={tab.content.overrides}
              field='media1'
              template={tab.styles}
              rules={this.props.pageResponse.components[tab.styles.component]}
              expManager={this.props.expManager}
              network={tab.network}
              post={tab}
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={tab.selectedInstanceId}
              object={tab}
              objectId={this.props.objectUuid}
              inlineContent={tab.content.inlineContent}
              overrides={tab.content.overrides}
              field='text1'
              tag='p'
              value={getStringField(tab, tab.styles, this.props.pageResponse, "text1", this.props.overrides)}
              template={tab.styles}
              rules={this.props.pageResponse.components[tab.styles.component]}
              expManager={this.props.expManager}
              network={tab.network}
              className='network'
            />
          </a>
        </Link>
      </BobComponentHandler>
    )
  }

  renderDropdown = () => {
    let activeTab = undefined
    if (Array.isArray(this.props.children)) {
      for (let key in this.props.children) {
        const tab = this.props.children[key]
        if (typeof this.state.selected === "number") {
          if (this.state.selected === parseInt(key)) activeTab = tab
        } else {
          if (this.state.selected === tab.props.tabLabel) activeTab = tab
        }
      }
    }

    if (activeTab) {
      let tabComponent = activeTab.props.component
      return (
        <div
          className={`dropdown-active-item ${tabsContainerDropdownStyle["dropdown-active-item"]}`}
          onClick={this.handleToggleDropdown}
        >
          {this.renderDropdownSelector(tabComponent)}
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            objectId={this.props.obj.uuid}
            field='icon1'
            template={this.props.obj.styles}
            rules={this.props.componentRule}
            expManager={this.props.expManager}
            iconClassName='fas fa-chevron-down'
          />
        </div>
      )
    }
    return []
  }

  renderDropdownSelector(tab: any) {
    // render link inside/outside experience manager
    return (
      <BobComponentHandler
        position={this.props.position}
        pageResponse={this.props.pageResponse}
        instanceId={this.props.selectedInstanceId}
        object={this.props.obj}
        objectId={this.props.obj.uuid}
        inlineContent={tab.content.inlineContent}
        overrides={tab.content.overrides}
        field={"background2"}
        rules={this.props.pageResponse.components[this.props.obj.styles.component]}
        template={this.props.obj.styles}
        expManager={this.props.expManager}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        <BobComponentHandler
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.selectedInstanceId}
          object={this.props.obj}
          objectId={this.props.obj.uuid}
          inlineContent={tab.content.inlineContent}
          overrides={tab.content.overrides}
          field='media1'
          template={this.props.obj.styles}
          rules={this.props.pageResponse.components[this.props.obj.styles.component]}
          expManager={this.props.expManager}
          network={tab.network}
          post={tab}
        />
        <BobComponentHandler
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.selectedInstanceId}
          object={this.props.obj}
          objectId={this.props.obj.uuid}
          inlineContent={tab.content.inlineContent}
          overrides={tab.content.overrides}
          field='text1'
          tag='p'
          value={getStringField(tab, tab, this.props.pageResponse, "text1")}
          template={this.props.obj.styles}
          rules={this.props.pageResponse.components[this.props.obj.styles.component]}
          expManager={this.props.expManager}
          network={tab.network}
          className='network'
        />
      </BobComponentHandler>
    )
  }

  /**
   * Renders the content of the currently selected tab
   * @returns {any} The contents of the selected tab
   */
  _renderContent(innerTabContent?: any) {
    if (this.props.children === undefined) {
      return null
    }

    const onlyOneChild = !Array.isArray(this.props.children)

    let selected = this.state.selected

    // Find the tab index - selected could be the index or could be the tab label
    let selectedIndex
    if (typeof selected === "number") {
      selectedIndex = selected
      if (selectedIndex < 0) {
        console.warn(`tab index '${this.state.selected}' < 0, defaulting to first tab`)
        selectedIndex = 0
        selected = selectedIndex
      } else {
        const tabCount = this.props.children.length || 1
        if (selectedIndex > tabCount - 1) {
          console.warn(`tab index '${this.state.selected}' > number of tabs (${tabCount}, defaulting to last tab`)
          selectedIndex = tabCount - 1
          selected = selectedIndex
        }
      }
    } else {
      // selected is a string - should be the tab label so find the index of that tab
      const selectedLabel = this.state.selected
      selectedIndex = onlyOneChild
        ? 0
        : this.props.children.findIndex((child: any) => selectedLabel === child.props.tabLabel)
      if (selectedIndex < 0) {
        console.warn(`tab '${this.state.selected}' not found, defaulting to first tab`)
        selectedIndex = 0
        selected = onlyOneChild ? this.props.children.props.tabLabel : this.props.children[selectedIndex].props.tabLabel
      }
    }

    const contentTab = innerTabContent
      ? innerTabContent.content && innerTabContent.content[selectedIndex]
      : onlyOneChild
      ? this.props.children
      : this.props.children[selectedIndex]
    const contentClassNames = classes("tab-content", contentTab.props.contentClass || this.props.contentClass)
    return <div className={`${contentClassNames}`}>{contentTab}</div>
  }

  render() {
    const classNames = classes("horizontal-tabs tabs", this.props.className)
    return (
      <div
        className={`${classNames} ${this.props.isVertical ? "is-vertical" : ""} ${
          this.props.isVerticalNotMobile ? "is-vertical_notMobile" : ""
        }`}
      >
        <BobComponentHandler
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.obj.selectedInstanceId}
          object={this.props.obj}
          objectId={this.props.objectUuid}
          inlineContent={this.props.obj.content.inlineContent}
          overrides={this.props.obj.content.overrides}
          field={"background1"}
          rules={this.props.componentRule}
          template={this.props.componentTemplate}
          expManager={this.props.expManager}
        >
          {this._renderHeaders()}
        </BobComponentHandler>
        {this._renderContent()}
      </div>
    )
  }
};

export { BobHorizontalTabs as Tabs };