import { objValueExists, valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { isRight } from "fp-ts/lib/Either"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  number as numberC,
  boolean as booleanC,
  partial,
} from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { get2WithNull4Enable, get3WithNull4Enable } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const ZIndexCodec = type({
  value: numberC,
  enable: booleanC,
})
const ZIndexOptCodec = partial({
  value: nullable(numberC),
  enable: nullable(booleanC),
})

const StylesZIndexCodec = intersection([
  type({ zIndex: ZIndexCodec }),
  partial({
    behaviour: partial({
      active: partial({ zIndex: ZIndexOptCodec }),
      hover: partial({ zIndex: ZIndexOptCodec }),
    }),
    mobile: intersection([
      partial({ zIndex: ZIndexOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ zIndex: ZIndexOptCodec }),
          hover: partial({ zIndex: ZIndexOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ zIndex: ZIndexOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ zIndex: ZIndexOptCodec }),
          hover: partial({ zIndex: ZIndexOptCodec }),
        }),
      }),
    ]),
  }),
])

type ZIndex = TypeOf<typeof ZIndexCodec>
type ZIndexProps = TypeOf<typeof ZIndexCodec>
type ZIndexOpt = TypeOf<typeof ZIndexOptCodec>
type ZIndexPropsOpt = TypeOf<typeof ZIndexOptCodec>
type StylesZIndex = TypeOf<typeof StylesZIndexCodec>
type ZIndexCSS = {
  "z-index": string
}

export function responsiveStyle(zIndexObj: any) {
  if (!objValueExists(zIndexObj, "enable")) return ""
  if (!zIndexObj.enable)
    return {
      "z-index": "auto !important",
    }
  let zIndexStyle = {}
  if (zIndexObj.value) {
    zIndexStyle = {
      ...zIndexStyle,
      "z-index": zIndexObj.value + "!important",
    }
  }
  return zIndexStyle
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<ZIndexCSS> {
  const styles = StylesZIndexCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(`ZIndexStyle ${breakpoint} ${behaviourState} ${decoderErrors(styles)}`)
  return {}
}

export function cssRender(
  stylesObj: StylesZIndex,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<ZIndexCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.zIndex)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj.zIndex, mergeBob2(stylesObj?.behaviour?.[behaviourState]?.zIndex, stylesObj.zIndex))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.zIndex, mergeBob2(stylesObj?.[breakpoint]?.zIndex, stylesObj.zIndex))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.zIndex,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.zIndex,
          stylesObj?.behaviour?.[behaviourState]?.zIndex,
          stylesObj?.[breakpoint]?.zIndex,
          stylesObj.zIndex
        )
      )
    }
  }
}

export function renderBob(zIndexObj: ZIndex): Partial<ZIndexCSS> {
  if (!zIndexObj.enable) return {}

  return render(zIndexObj)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param zIndexObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(defaultZIndexObj: ZIndex, zIndexObj: ZIndexOpt | undefined): Partial<ZIndexCSS> {
  if (zIndexObj?.enable === false) {
    if (defaultZIndexObj.enable)
      return {
        "z-index": "0",
      }

    return {}
  }

  if (zIndexObj?.enable) return renderOpt(zIndexObj)

  return {}
}

/**
 * Renders ZIndexOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param zIndexObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(zIndexObj: ZIndexOpt | undefined): Partial<ZIndexCSS> {
  let css = {}
  if (valueExists(zIndexObj?.value)) css = { ...css, "z-index": `${zIndexObj?.value}` }
  return css
}

export function render(zIndexObj: ZIndexProps): ZIndexCSS {
  return {
    "z-index": `${zIndexObj.value}`,
  }
}

export function mergeBob2(zIndexObj: ZIndexOpt | undefined, defaultZIndexObj: ZIndex): ZIndexOpt {
  const enable = get2WithNull4Enable(zIndexObj?.enable, defaultZIndexObj.enable)

  return {
    enable,
    value: zIndexObj?.value,
  }
}

export function mergeBob3(
  zIndexObj: ZIndexOpt | undefined,
  zIndexDesktopBehaviour: ZIndexOpt | undefined,
  zIndexDefaultBreakpoint: ZIndexOpt | undefined,
  defaultZIndexObj: ZIndex
): ZIndexOpt {
  const enable = get3WithNull4Enable(
    zIndexObj?.enable,
    zIndexDesktopBehaviour?.enable,
    zIndexDefaultBreakpoint?.enable,
    defaultZIndexObj.enable
  )

  return {
    enable,
    value: zIndexObj?.value,
  }
}

export type { StylesZIndex, ZIndexProps, ZIndexPropsOpt, ZIndex, ZIndexOpt }

