import React, { useState, useEffect } from 'react'
import Image from 'next/image';
import axios, { AxiosResponse } from 'axios';
import { InstagramMediaEmbedProps } from './interface';

const InstagramMediaEmbed: React.FC<InstagramMediaEmbedProps> = (props): JSX.Element => {

  const [post, setPost] = useState<any>({});

  useEffect(() => {
    handleApiRequests();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleApiRequests = async (): Promise<void> => {
    // get post
    await handleApiRequest(`https://graph.facebook.com/v8.0/instagram_oembed?access_token=1669312576562272|0408296a55ded133d0135900018b2898&url=${props.url}`)
    // get thumbnail
    await handleApiRequest(`https://graph.facebook.com/v8.0/instagram_oembed?access_token=1669312576562272|0408296a55ded133d0135900018b2898&fields=thumbnail_url,author_name,provider_name,provider_url&url=${props.url}`)
  }

  const handleApiRequest = async (url: string): Promise<void> => {
    try {
      let response: AxiosResponse<any> = await axios.get(url);
      if (response.data.html) {
        return setPost({ ...post, ...response });
      }
    } catch (error: any) {
      throw (error);
    }
  }

  return (
    <a className="instagram-media-embed" href={props.url} target="_blank" rel="noopener noreferrer">
      <Image className="instagram-media-embed-cover" src={post.thumbnail_url} alt="instagram logo" />
      <p className="instagram-media-embed-author">{post.author_name}</p>
      <div className="instagram-media-embed-thumbnail">
        <Image src="https://meshapp-user-generated.s3-eu-west-1.amazonaws.com/ricardo/Sx8ZLSeB2qYyeUB3pu0NdJIg9j0f6Q7XPe3oHTor.png" alt={post.author_name} />
      </div>
    </a>
  );
}

export default InstagramMediaEmbed;
