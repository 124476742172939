import { StylesGrid } from "../bobStyles/gridPropertiesStyle"

export function handleColumns(
  idx: number,
  counter: { desktop: number; tablet: number; mobile: number },
  grid: StylesGrid
): { desktop: string; tablet: string; mobile: string; counter: { desktop: number; tablet: number; mobile: number } } {
  const nthCol = grid.grid.columns.length
  const nthColTablet = grid.tablet?.grid?.columns?.length ?? nthCol
  const nthColMobile = grid.mobile?.grid?.columns?.length ?? nthCol

  counter.desktop = idx % nthCol !== 0 ? counter.desktop + 1 : 0
  counter.tablet = idx % nthColTablet !== 0 ? counter.tablet + 1 : 0
  counter.mobile = idx % nthColMobile !== 0 ? counter.mobile + 1 : 0

  const columnsDesktop = grid.grid.columns[counter.desktop]
  const columnsTablet = grid.tablet?.grid?.columns ? grid.tablet?.grid?.columns[counter.tablet] : columnsDesktop
  const columnsMobile = grid.mobile?.grid?.columns ? grid.mobile?.grid?.columns[counter.mobile] : columnsDesktop

  return { desktop: columnsDesktop, tablet: columnsTablet, mobile: columnsMobile, counter }
}

export function getStartPos(
  childComponentsId: string[],
  grid: StylesGrid
): { desktopClass: string; tabletClass: string; mobileClass: string } {
  const totalChilds = childComponentsId.length
  const initialPosDesktop = grid.grid.initialPos.enable ? grid.grid.initialPos.value : undefined
  const columnsTotalDesktop = grid.grid.columnsTotal
  const initialPosTablet = grid.tablet?.grid?.initialPos?.enable ? grid.tablet?.grid?.initialPos?.value : initialPosDesktop
  const columnsTotalTablet = grid.tablet?.grid?.columnsTotal ?? columnsTotalDesktop
  const initialPosMobile = grid.mobile?.grid?.initialPos?.enable ? grid.mobile?.grid?.initialPos?.value : initialPosDesktop
  const columnsTotalMobile = grid.mobile?.grid?.columnsTotal ?? columnsTotalDesktop

  let classes = { desktopClass: "", tabletClass: "", mobileClass: "" }
  if (initialPosDesktop) {
    classes.desktopClass = handleStartPosition(
      classes.desktopClass,
      "-lg",
      initialPosDesktop,
      columnsTotalDesktop,
      totalChilds
    )
  }
  if (initialPosTablet) {
    classes.tabletClass = handleStartPosition(
      classes.tabletClass,
      "-md",
      initialPosTablet,
      columnsTotalTablet,
      totalChilds
    )
  }
  if (initialPosMobile) {
    classes.mobileClass = handleStartPosition(
      classes.mobileClass,
      "",
      initialPosMobile,
      columnsTotalMobile,
      totalChilds
    )
  }

  return classes
}

function handleStartPosition(
  classe: string,
  classBrk: "-lg" | "-md" | "",
  initialPos: string,
  columnsTotal: number,
  totalChilds: number
): string {
  if (initialPos !== "false") {
    classe = ` g-start${classBrk}-${initialPos}`
  }
  return classe
}
