import React, { useEffect, useRef } from "react"
//@ts-ignore
import Style from "style-it"
import { ComponentBoilerplateProps } from "./interface"
import {
  getStringField,
  handleBondaries_DEPRECATED,
  handleLeanSettings,
  handleRadius,
  handleBorder,
  handleShadow,
  handleFlow,
  writeCustomCssSelectors,
} from "../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../bob/bobComponentHandler"
import { handleDOMid } from "../../../../modules/shared-modules/utilities/symbols"
import { objValueExists } from "../../../../modules/shared-modules/utilities/utils"
import cloneDeep from "lodash/cloneDeep"
import { elementLoaded, isElementInViewport, elementInViewport } from "../analytics/analytics"

/**
 * PROPS
 * obj
 * containerBackground
 * containerBoundaries
 * containerLean
 * containerRadius
 * containerBorder
 * containerShadow
 */
const ComponentBoilerplate: React.FC<ComponentBoilerplateProps> = (props): JSX.Element => {
  const elRef = useRef<HTMLDivElement>(null)
  //On viewport logic for analytics
  useEffect(() => {
    if (props.pageResponse.profileName === "patricia" || props.pageResponse.profileName === "ricardo") {
      elementLoaded(elRef.current)
      window.addEventListener("scroll", () => {
        if (elRef && isElementInViewport(elRef.current)) {
          elementInViewport(elRef.current)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /**
   * Handle background color/image on the main container
   * @param {*} color can be a string or array
   * String for a solid background
   * Array for a gradient background. [gradientDirection, color1, color2]
   */
  const handleContainerBackground = (color: any) => {
    if (typeof color === "string") {
      if (
        (color.startsWith("//") || color.startsWith("http") || color.startsWith("www")) &&
        (!color.startsWith("#") || !color.startsWith("rgb"))
      )
        return { background: `url(${color})` }
      return { background: color }
    } else if (color.constructor === Array) {
      let gradientOrientation = color[0] === "interleaved" ? "to bottom" : color[0] === "right" ? "to left" : "to right"
      return {
        background: `linear-gradient(${gradientOrientation}, ${color[1]}, ${color[2]})`,
      }
    }
  }

  /**
   * write css for all bobs customCss prop
   * bobCode has code prop while other bobs have customCss prop
   */
  const handleCustomCss = () => {
    const bobs = cloneDeep(props.obj.styles.bobs)
    let customCss = ""
    for (let key in bobs) {
      //bobCode only has html/css/js code and not ready to use css
      if (!key.startsWith("embed") && (bobs[key].code || bobs[key].code === "")) customCss += bobs[key].code
      else
        bobs[key].customCss =
          writeCustomCssSelectors(
            bobs[key],
            key,
            handleDOMid(key, props.obj.uuid, props.obj.selectedInstanceId),
            false
          ) || bobs[key]
      customCss += handleCustomCssValues(bobs[key].customCss, key)
    }
    return customCss
  }

  /**
   * write customCss values for each bob
   */
  const handleCustomCssValues = (customCss: any, bobType: any) => {
    if (!customCss) return ""
    if (bobType.startsWith("background")) {
      const container = `${customCss.default.container}${customCss.mobile.container}${customCss.tablet.container}${customCss.desktop.container}`
      const innerContainer = `${customCss.default.innerContainer}${customCss.mobile.innerContainer}${customCss.tablet.innerContainer}${customCss.desktop.innerContainer}`
      return `${container}${innerContainer}`
    }
    if (bobType.startsWith("media")) {
      const container = `${customCss.default.container}${customCss.mobile.container}${customCss.tablet.container}${customCss.desktop.container}`
      const image = `${customCss.default.image}${customCss.mobile.image}${customCss.tablet.image}${customCss.desktop.image}`
      return `${container}${image}`
    }
    if (bobType.startsWith("text")) {
      const text = `${customCss.default.text}${customCss.mobile.text}${customCss.tablet.text}${customCss.desktop.text}`
      return `${text}`
    }
    if (bobType.startsWith("icon")) {
      const container = `${customCss.default.container}${customCss.mobile.container}${customCss.tablet.container}${customCss.desktop.container}`
      const icon = `${customCss.default.icon}${customCss.mobile.icon}${customCss.tablet.icon}${customCss.desktop.icon}`
      return `${container}${icon}`
    }
    return ""
  }

  /**
   * Render component title
   * Renders title if title prop exists in the text rule
   */
  const renderTextComponents = () => {
    if (props.obj.styles.component.bob) {
      const field = "text1"
      const stringField = getStringField(props.obj, props.obj, props.pageResponse, field, props.overrides)
      return (
        <BobComponentHandler
          position={props.position}
          pageResponse={props.pageResponse}
          instanceId={props.obj.selectedInstanceId}
          object={props.obj}
          objectId={props.obj.objectUuid}
          value={stringField}
          field={field}
          rules={props.componentRule}
          template={props.obj.styles}
          expManager={props.expManager}
        />
      )
    }
  }

  const css = (componentTemplate: any) => {
    const CustomCss = handleCustomCss()

    let tabletZIndexEnabled =
      componentTemplate?.bobs?.background1?.tablet?.zIndex &&
      objValueExists(componentTemplate.bobs.background1.tablet.zIndex, "enable")
    let tabletZIndexValueDefined =
      tabletZIndexEnabled &&
      componentTemplate.bobs.background1.tablet &&
      componentTemplate.bobs.background1.tablet.zIndex.enable

    let tabletZIndexInner = tabletZIndexValueDefined
      ? `.component-boilerplate{
          z-index: ${componentTemplate.bobs.background1.tablet.zIndex.value};
        }`
      : `.component-boilerplate{
          z-index: auto;
        }`

    let tabletZIndex = tabletZIndexEnabled
      ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${tabletZIndexInner}
          }`
      : ""

    let mobileZIndexEnabled =
      componentTemplate?.bobs?.background1?.mobile?.zIndex &&
      objValueExists(componentTemplate.bobs.background1.mobile.zIndex, "enable")
    let mobileZIndexValueDefined =
      mobileZIndexEnabled &&
      componentTemplate.bobs.background1.mobile &&
      componentTemplate.bobs.background1.mobile.zIndex.enable

    let mobileZIndexInner = mobileZIndexValueDefined
      ? `.component-boilerplate{
          z-index: ${componentTemplate.bobs.background1.mobile.zIndex.value};
        }`
      : `.component-boilerplate{
          z-index: auto;
        }`

    let mobileZIndex = mobileZIndexEnabled
      ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${mobileZIndexInner}
          }`
      : ""

    return `
      ${
        componentTemplate.bobs &&
        componentTemplate.bobs.background1 &&
        componentTemplate.bobs.background1.zIndex &&
        componentTemplate.bobs.background1.zIndex.enable
          ? `.component-boilerplate{
          z-index: ${componentTemplate.bobs.background1.zIndex.value};
        }`
          : ""
      }
      ${tabletZIndex}
      ${mobileZIndex}
      ${componentTemplate.bobs && componentTemplate.bobs.code1 && CustomCss}
    `
  }

  /**
   * Main render
   * Defines the component main structure
   * Handles all the styles to be aplied to the component main containers
   * only apply styles that comes from the props
   */
  const componentRules = props.pageResponse.components[props.obj.styles.component]
  const componentTemplate = props.obj.styles
  const componentName = componentRules.name
  const containerClass =
    props.container && componentTemplate.wrap === "container"
      ? "container"
      : props.container && componentTemplate.wrap !== "container"
      ? "container-fluid"
      : ""

  const boundaries = props.containerBoundaries && handleBondaries_DEPRECATED(componentTemplate)
  const leanStyles = props.containerLean && handleLeanSettings(componentTemplate)
  const radiusStyles = props.containerRadius && handleRadius(componentTemplate)
  const borderStyles = props.containerBorder && handleBorder(componentTemplate)
  const shadowStyles = props.containerShadow && handleShadow(componentTemplate)
  const flowStyles = props.containerFlow && handleFlow(componentTemplate, props.expManager)
  const containerBackground = props.containerBackground && handleContainerBackground(props.containerBackground)

  /**
   * For the css sticky to work, the sticky element must be the direct child of a parent with a height bigger than the child element itself.
   * Boilerplate must have position:stiky on himself because it's the first div of a component
   * and that's the one relative to the parent wich makes him sticky
   */
  const boilerplateFlow =
    props.obj.styles.bobs.background1 && props.obj.styles.bobs.background1.scroll.value === "magnetic"
      ? { position: "sticky", top: 0 }
      : undefined

  const styles = {
    ...boundaries,
    clipPath: leanStyles,
    WebkitClipPath: leanStyles,
    ...radiusStyles,
    ...borderStyles,
    ...shadowStyles,
    ...flowStyles,
    ...containerBackground,
  }

  const container = containerClass ? (
    <div className={containerClass}>
      {props.containerTitle && renderTextComponents()}
      {props.children}
    </div>
  ) : (
    props.children
  )

  const boilerplate = (
    <section
      ref={elRef}
      className='component-boilerplate'
      //@ts-ignore
      name={componentName}
      style={{ ...styles, ...boilerplateFlow }}
      id={handleDOMid("object", props.obj.objectUuid, props.obj.selectedInstanceId)}
    >
      {componentRules.bob && componentRules.bob.background1 && !props.noContainer ? (
        <BobComponentHandler
          position={props.position}
          pageResponse={props.pageResponse}
          instanceId={props.obj.selectedInstanceId}
          object={props.obj}
          objectId={props.obj.objectUuid}
          inlineContent={props.obj.content.inlineContent}
          overrides={props.overrides}
          field={"background1"}
          rules={componentRules}
          template={componentTemplate}
          expManager={props.expManager}
        >
          {container}
        </BobComponentHandler>
      ) : (
        container
      )}
    </section>
  )

  const cssRules = css(componentTemplate)
  if (cssRules.trim() !== "") {
    return (
      <Style>
        {`${cssRules}`}
        {boilerplate}
      </Style>
    )
  } else {
    return boilerplate
  }
}

export default React.memo(ComponentBoilerplate)
