import React, { useEffect, useState } from "react"
import { EmbedContainerProps } from "."
import DangerousHtml from "../../../dangerousHtml/dangerousHtml"
import WarningDefaultSystem from "../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import { handleColumns } from "../../../bob/bobHandler"
import embedContainerStyles from "../../../../../../stylesheets/modules/templates/embedContainer.module.sass"
import {
  isBobBackground,
  isBobCode,
} from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const EmbedContainer: React.FC<EmbedContainerProps> = (props) => {
  const [isMouted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const renderNoChilds = () => {
    /**
     * render no content placeholder
     */
    if (props.expManager.enable) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      const Background1 = isBobBackground(props.obj.styles.bobs.background1)
      let childs = Background1.grid.columns && Background1.grid.columns.length
      let components: any[] = []
      for (let i = 0; i < childs; i++) {
        let columns = handleColumns(i, counter, Background1, props.pageResponse.globalStyles[Background1.globalStyleId])
        counter = columns.counter
        components = [
          ...components,
          <div
            key={i}
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                verticalAlign: "middle",
                height: `${Background1.height.enable ? Background1.height.value : "100"}${
                  Background1.height.enable ? Background1.height.unit : "px"
                }`,
                border: "3px dashed #dbe0e7",
              }}
            >
              <div className='alert warning m-0' role='alert'>
                Go to the elements tab and add your embed code there.
              </div>
            </div>
          </div>,
        ]
      }
      return (
        <WarningDefaultSystem>
          <div className={`row`}>{components}</div>
        </WarningDefaultSystem>
      )
    }

    return []
  }

  const component = props.obj
  const Embed1 = isBobCode(component.styles.bobs.embed1)
  return (
    <div className={`containers embed-container-component ${embedContainerStyles["embed-container-component"]}`}>
      {isMouted && Embed1.code && Embed1.code !== "" ? (
        <DangerousHtml style={{ textAlign: "center" }} html={Embed1.code} expManager={props.expManager} />
      ) : (
        renderNoChilds()
      )}
    </div>
  )
}

export default EmbedContainer
