import React, { useEffect } from "react"
import Head from "next/head"
import { InjectMetaDataProps, Sizes } from "./interface"
import parse from "html-react-parser"

const metaIcons = [
  { iconId: "android-icon-192x192", rel: "icon", size: "192x192" },
  { iconId: "apple-icon-57x57", rel: "apple-touch-icon", size: "57x57" },
  { iconId: "apple-icon-60x60", rel: "apple-touch-icon", size: "60x60" },
  { iconId: "apple-icon-72x72", rel: "apple-touch-icon", size: "72x72" },
  { iconId: "apple-icon-76x76", rel: "apple-touch-icon", size: "76x76" },
  { iconId: "apple-icon-114x114", rel: "apple-touch-icon", size: "114x114" },
  { iconId: "apple-icon-120x120", rel: "apple-touch-icon", size: "120x120" },
  { iconId: "apple-icon-144x144", rel: "apple-touch-icon", size: "144x144" },
  { iconId: "favicon-16x16", rel: "icon", size: "16x16" },
  { iconId: "favicon-32x32", rel: "icon", size: "32x32" },
  { iconId: "favicon-96x96", rel: "icon", size: "96x96" },
  { iconId: "android-icon-192x192", rel: "icon", size: "192x192" },
  { iconId: "ms-icon-144x144", rel: "icon", size: "144x144" },
]

const InjectMetadata: React.FC<InjectMetaDataProps> = ({ site, fonts }) => {
  const isAnalyticsUnlocked = site?.cookieBar?.analyticsConsent === true ? false : true
  const isHeadUnlocked = site?.cookieBar?.headConsent?.enable === true ? false : true
  const [cookieState, setCookieState] = React.useState({
    analytics: isAnalyticsUnlocked,
    head: isHeadUnlocked,
  })
  const { injectHead, keywords, analyticsInjectHead, google_analytics, facebook_pixel, hotjar_id, mixpanel_id } = site

  useEffect(() => {
    const handleCookieChange = () => {
      const analyticsCookie = `${"cookie-analytics-" + window.location.hostname}`
      const headCookie = `${"cookie-head-" + window.location.hostname}`
      const cookieString = document.cookie
      const isAnalyticsAccepted = cookieString.includes(analyticsCookie)
      const isHeadAccepted = cookieString.includes(headCookie)
      if (isAnalyticsAccepted || isHeadAccepted) {
        setCookieState({ analytics: isAnalyticsAccepted, head: isHeadAccepted })
      }
    }

    handleCookieChange() //initial load check
    //@ts-ignore
    if (typeof cookieStore !== "undefined") {
      //@ts-ignore
      cookieStore.addEventListener("change", ({ changed }) => {
        for (const { name, value } of changed) {
          handleCookieChange()
        }
      })
      return () => {
        //@ts-ignore
        cookieStore.removeEventListener("change", handleCookieChange)
      }
    }
  }, [])

  const title = site["og:title"]
  const description = site["og:description"]
  const image = site["og:image"]

  metaIcons.map((icon: { iconId: string; rel: string; size: string; href?: string }) => {
    const iconId = icon.iconId as Sizes
    const key = Object.keys(site).find((k) => k === iconId)
    const item = key ? site[key as Sizes] : undefined
    icon.href = item
  })

  const mergeScripts = (): string => {
    let mergedString = ""
    if (injectHead) {
      mergedString += injectHead + "\n"
    }
    return mergedString
  }
  const mergeAnalytics = (): string => {
    let mergedString = ""
    if (analyticsInjectHead && cookieState.head) {
      mergedString += analyticsInjectHead + "\n"
    }
    if (google_analytics && cookieState.analytics) {
      mergedString += `<script async src='https://www.googletagmanager.com/gtag/js?id=${google_analytics}' />
      <script defer>
          window.dataLayer = window.dataLayer || [];

          function gtag() {
              dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', ${google_analytics});
      </script>`
    }
    if (facebook_pixel) {
      mergedString += `<script defer>
      !function (f, b, e, v, n, t, s) {
          if (f.fbq)
              return;
          n = f.fbq = function () {
              n.callMethod
                  ? n
                      .callMethod
                      .apply(n, arguments)
                  : n
                      .queue
                      .push(arguments)
          };
          if (!f._fbq)
              f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '3.1';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s
              .parentNode
              .insertBefore(t, s)
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${facebook_pixel}');
      fbq('track', 'PageView');
  </script>
  <noscript><img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${facebook_pixel}&ev=PageView&noscript=1" /></noscript>`
    }
    if (hotjar_id) {
      mergedString += `<script defer>
      (function (h, o, t, j, a, r) {
          h.hj = h.hj || function () {
              (h.hj.q = h.hj.q || []).push(arguments)
          };
          h._hjSettings = {
              hjid: ${hotjar_id}, hjsv: 6
          };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script'); r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  </script>`
    }
    if (mixpanel_id) {
      mergedString += `<script type="text/javascript" defer>
      (function (c, a) {
          if (!a.__SV) {
              var b = window;
              try {
                  var d,
                      m,
                      j,
                      k = b.location,
                      f = k.hash;
                  d = function (a, b) {
                      return (m = a.match(RegExp(b + "=([^&]*)")))
                          ? m[1]
                          : null
                  };
                  f && d(f, "state") && (j = JSON.parse(decodeURIComponent(d(f, "state"))), "mpeditor" === j.action && (b.sessionStorage.setItem("_mpcehash", f), history.replaceState(j.desiredHash || "", c.title, k.pathname + k.search)))
              } catch (n) { }
              var l,
                  h;
              window.mixpanel = a;
              a._i = [];
              a.init = function (b, d, g) {
                  function c(b, i) {
                      var a = i.split(".");
                      2 == a.length && (b = b[a[0]], i = a[1]);
                      b[i] = function () {
                          b.push([i].concat(Array.prototype.slice.call(arguments, 0)))
                      }
                  }
                  var e = a;
                  "undefined" !== typeof g
                      ? e = a[g] = []
                      : g = "mixpanel";
                  e.people = e.people || [];
                  e.toString = function (b) {
                      var a = "mixpanel";
                      "mixpanel" !== g && (a += "." + g);
                      b || (a += " (stub)");
                      return a
                  };
                  e.people.toString = function () {
                      return e.toString(1) + ".people (stub)"
                  };
                  l = "disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
                  for (h = 0; h < l.length; h++)
                      c(e, l[h]);
                  var f = "set set_once union unset remove delete".split(" ");
                  e.get_group = function () {
                      function a(c) {
                          b[c] = function () {
                              call2_args = arguments;
                              call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
                              e.push([d, call2])
                          }
                      }
                      for (var b = {}, d = ["get_group"].concat(Array.prototype.slice.call(arguments, 0)), c = 0; c < f.length; c++)
                          a(f[c]);
                      return b
                  };
                  a
                      ._i
                      .push([b, d, g])
              };
              a.__SV = 1.2;
              b = c.createElement("script");
              b.type = "text/javascript";
              b.async = !0;
              b.src = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL
                  ? MIXPANEL_CUSTOM_LIB_URL
                  : "file:" === c.location.protocol && "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)
                      ? "https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js"
                      : "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";
              d = c.getElementsByTagName("script")[0];
              d
                  .parentNode
                  .insertBefore(b, d)
          }
      })(document, window.mixpanel || []);
      mixpanel.init("<?= ${mixpanel_id} ?>");
  </script>`
    }
    return mergedString
  }

  const mergedAll = (): string => {
    let mergedAllString = ""
    if (fonts) mergedAllString += fonts + "\n"

    mergedAllString += mergeAnalytics() + "\n" + mergeScripts()
    return mergedAllString
  }

  return (
    <Head>
      <title>{title}</title>
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width,initial-scale=1,shrink-to-fit=no' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge,chrome=1' />
      <meta name='generator' content='Fluxio' />
      <meta name='author' content='Fluxio' />
      <meta property='og:title' content={title ?? "Fluxio powered website"} />
      <meta name='description' content={description ?? "Created with Fluxio"} />
      <meta property='og:description' content={description ?? "Created with Fluxio"} />
      <meta name='keywords' content={keywords ?? "fluxio, website"} />
      <meta property='keywords' content={keywords ?? "fluxio, website"} />
      <meta property='og:image' content={image} />
      {metaIcons.map((icon: any) =>
        icon.href !== undefined ? <link key={icon.iconId} href={icon.href} rel={icon.rel} sizes={icon.size} /> : null
      )}
      <link rel='dns-prefetch' href='https://apis.google.com' />
      <link rel='dns-prefetch' href='https://www.gstatic.com' />
      <link rel='dns-prefetch' href='https://accounts.google.com' />
      <link rel='dns-prefetch' href='https://connect.facebook.net' />
      <link rel='dns-prefetch' href='https://cdnjs.cloudflare.com' />
      <link
        rel='stylesheet'
        href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css'
        integrity='sha256-YLGeXaapI0/5IgZopewRJcFXomhRMlYYjugPLSyNjTY='
        crossOrigin='anonymous'
      />
      <link
        rel='stylesheet'
        href='https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.6/quill.core.min.css'
        integrity='sha256-w1xA5IxJPjR5nxXpZP0vneJCf62zDzyF6AlXlK7rYdk='
        crossOrigin='anonymous'
        media='all'
      />
      <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css' />
      <script
        async
        id='Typewriter'
        src='https://unpkg.com/typewriter-effect@latest/dist/core.js'
        crossOrigin='anonymous'
      />
      {parse(mergedAll())}
    </Head>
  )
}

export default InjectMetadata
