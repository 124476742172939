import React from 'react'
import ComponentHandler from '../../../../componentHandler'
import WarningDefaultSystem from '../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem'
import {
  getContent,
  handleContentBlock,
  handleGutts,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { GridContainerV2Props } from "."
import { Post } from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import {
  BobBackground,
  isBobBackground,
} from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const GridContainerV2: React.FC<GridContainerV2Props> = (props): JSX.Element => {
  const renderChildComponents = (childComponentsId: string[], post: Post) => {
    let counter = { desktop: 0, tablet: 0, mobile: 0 }
    return childComponentsId.map((item, idx) => {
      const handledItem = handleObjectType(props.obj, item, props.pageResponse, props.expManager.emEditorType)
      const columns = handleColumns(
        idx,
        counter,
        props.obj.styles.bobs.background2,
        props.pageResponse.globalStyles[props.obj.styles.bobs.background2.globalStyleId]
      )
      counter = columns.counter
      return (
        <div
          key={idx}
          className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
        >
          <ComponentHandler
            position={idx}
            key={idx}
            component={handleContentBlock(handledItem, post, props.obj, props.pageResponse, props.overrides)}
            pageResponse={props.pageResponse}
            selectedInstanceId={props.obj.selectedInstanceId}
            matchOrder={props.matchOrder}
            expManager={props.expManager}
          />
        </div>
      )
    })
  }

  const renderContent = (post: Post) => {
    const background2 = isBobBackground(props.obj.styles.bobs.background2)
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background2)
    const childComponentsId = props.obj.children
    const gutters = handleGutts(background2, false)
    if (childComponentsId && childComponentsId.length > 0) {
      return (
        <AlignmentHOC
          className={`row ${responsiveGuttersClass(
            background2,
            props.pageResponse.globalStyles[props.obj.styles.bobs.background2.globalStyleId]
          )}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {renderChildComponents(childComponentsId, post)}
        </AlignmentHOC>
      )
    } else {
      /**
       * render no content placeholder
       */
      if (props.expManager.enable) {
        let counter = { desktop: 0, tablet: 0, mobile: 0 }
        const Background2 = isBobBackground(props.obj.styles.bobs.background2)
        const childs = Background2.grid.columns && Background2.grid.columns.length
        let components: any[] = []
        for (let i = 0; i < childs; i++) {
          const columns = handleColumns(i, counter, Background2)
          counter = columns.counter
          components = [
            ...components,
            <div
              key={i}
              className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            >
              <div
                style={{
                  width: "100%",
                  height: `${Background2.height.enable ? Background2.height.value : "100"}${
                    Background2.height.enable ? Background2.height.unit : "px"
                  }`,
                  border: "3px dashed #dbe0e7",
                }}
              ></div>
            </div>,
          ]
        }
        return (
          <WarningDefaultSystem>
            <div className={`row ${gutters}`} style={alignmentStyles}>
              {components}
            </div>
          </WarningDefaultSystem>
        )
      }
    }
  }

  const renderGrid = (background1TemplateMerged: BobBackground) => {
    const content = getContent(props.obj, "posts", props.overrides)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: Post, idx: number) => {
        const columns = handleColumns(
          idx,
          counter,
          background1TemplateMerged,
          props.pageResponse.globalStyles[background1TemplateMerged.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              post={post}
              inlineContent={props.obj.content.inlineContent}
              overrides={props.overrides}
              field={"background2"}
              rules={props.componentRule}
              template={props.obj.styles}
              expManager={props.expManager}
            >
              {renderContent(post)}
            </BobComponentHandler>
          </div>
        )
      })
    }
  }

  const background1 = isBobBackground(props.obj.styles.bobs.background1)
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
  return (
    <div className='containers'>
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[props.obj.styles.bobs.background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderGrid(background1)}
      </AlignmentHOC>
    </div>
  )
}

export default React.memo(GridContainerV2)