import React from "react"
import { getOverrideInlineContentValues } from "../../../../../modules/shared-modules/utilities/components"
import {
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { ExpManager, PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { isBobFlex } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import styled, { css } from "styled-components"
import { handleDOMid } from "../../../../../modules/shared-modules/utilities/symbols"
import GlobalStylesUtils from "../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { clickEvent, emPreviewClass, emScrollId, getLinkAttributes } from "../bobUtils"
import { MediaObject } from "../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import publicUtils from "../../../../../stylesheets/modules/utils/public-components.module.sass"
import { responsiveStyles } from "./bobContainerHandler"
import { handleMedia, handleMediaId } from "../bobMediaV2/bobMediaV2Handler"
import { handleMediaQueryRestrictions, handleStateStyles } from "../bobHandlerV2"
import { BobContainerStyles, ResponsiveCSSProps } from "../bobTypes"
import { NGS_CLASS_SUFIX } from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import * as gsType from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"

interface OwnProps {
  id: string
  pageResponse: PageTypes
  instanceId: string | undefined
  object: SingleObject
  inlineContent: ObjectContent["inlineContent"]
  overrides: ObjectContentOverride | undefined
  template: any
  post: Post | undefined
  field: string
  children: React.ReactNode | undefined
  expManager: ExpManager
  setRef: (x: React.RefObject<HTMLDivElement>) => void | undefined
  replacedMediaList: { [id: string]: MediaObject } | undefined
}

type BobContainerProps = OwnProps

const ContainerDiv = styled.div`
  ${(props: ResponsiveCSSProps<BobContainerStyles>) => props.desktop}
  ${(props: ResponsiveCSSProps<BobContainerStyles>) =>
    props.hover.isEnabled.desktop
      ? css`
          @media all ${handleMediaQueryRestrictions(props.hover.isEnabled.mobile, props.hover.isEnabled.tablet)} {
            &:hover {
              ${props.hover.desktop}
            }
          }
        `
      : {}}

${(props: ResponsiveCSSProps<BobContainerStyles>) =>
    Object.keys(props.tablet).length > 0 || Object.keys(props.hover.tablet).length > 0
      ? css`
          @media all and (max-width: 992px) and (min-width: 767px) {
            ${(props: ResponsiveCSSProps<BobContainerStyles>) => props.tablet}
            ${(props: ResponsiveCSSProps<BobContainerStyles>) =>
              props.hover.isEnabled.tablet && Object.keys(props.hover.tablet).length > 0
                ? css`
                    &:hover {
                      ${props.hover.tablet}
                    }
                  `
                : {}}
          }
        `
      : {}}

${(props: ResponsiveCSSProps<BobContainerStyles>) =>
    Object.keys(props.mobile).length > 0 || Object.keys(props.hover.mobile).length > 0
      ? css`
          @media all and (max-width: 766px) {
            ${(props: ResponsiveCSSProps<BobContainerStyles>) => props.mobile}
            ${(props: ResponsiveCSSProps<BobContainerStyles>) =>
              props.hover.isEnabled.mobile && Object.keys(props.hover.mobile).length > 0
                ? css`
                    &:hover {
                      ${props.hover.mobile}
                    }
                  `
                : {}}
          }
        `
      : {}}
`

class BobContainer extends React.Component<BobContainerProps> {
  private containerRef: React.RefObject<HTMLDivElement>

  constructor(props: BobContainerProps) {
    super(props)
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.props.setRef && this.props.setRef(this.containerRef)
  }

  render() {
    // const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, templateStyles: any) => {
    //   // // trigger parent onclick
    //   // if (this.props.onClick)
    //   //   this.props.onClick(e)
    //   // // check template onclick action
    //   // // only run outside experience manager
    //   // if (this.props.rule.actions && !this.props.expManager.enable) {
    //   //   if ((templateStyles.actions && templateStyles.actions.enable) && (templateStyles.actions.onClick && templateStyles.actions.onClick !== '')) {
    //   //     // https://stackoverflow.com/questions/40661197/js-check-if-input-string-is-valid-code-and-run-input
    //   //     // eslint-disable-next-line
    //   //     if (eval("typeof " + templateStyles.actions.onClick) === "function") {
    //   //       // https://stackoverflow.com/questions/939326/execute-javascript-code-stored-as-a-string
    //   //       // eslint-disable-next-line
    //   //       let F = new Function(templateStyles.actions.onClick)
    //   //       return (F())
    //   //     }
    //   //   }
    //   // }
    // }

    //@ts-ignore
    const templateStyles = isBobFlex(this.props.object.styles.bobs.container1)
    const inlineContent = this.props.object.content.inlineContent
    const settings = this.props.object.settings
    const pageLabels = this.props.pageResponse.stylesheet?.labels

    const TAG = getOverrideInlineContentValues(`${this.props.field}.behaviour-link-enable`, this.props) ? "a" : "div"
    const linkAttrs = () =>
      getLinkAttributes(
        this.props.field,
        {
          inlineContent: this.props.inlineContent,
          overrides: this.props.overrides,
        },
        this.props.pageResponse,
        this.props.post
      )

    const media = handleMedia(
      this.props.pageResponse,
      this.props.post,
      handleMediaId(
        this.props.post,
        this.props.pageResponse,
        this.props.field,
        this.props.object.content.inlineContent,
        this.props.overrides,
        templateStyles.behaviour?.hover?.enable,
        ".mappedValue"
      ),
      getOverrideInlineContentValues(`${this.props.field}.linkType`, {
        inlineContent: inlineContent,
        overrides: this.props.overrides,
      }),
      this.props.object,
      this.props.replacedMediaList
    )

    const DesktopStyles = responsiveStyles(templateStyles, pageLabels, "desktop", "default", media.defaultMedia.url)
    const TabletStyles = responsiveStyles(templateStyles, pageLabels, "tablet", "default", media.defaultMedia.url)
    const MobileStyles = responsiveStyles(templateStyles, pageLabels, "mobile", "default", media.defaultMedia.url)
    const hoverStyles = handleStateStyles<BobContainer, BobContainerStyles>(
      templateStyles,
      responsiveStyles(templateStyles, pageLabels, "desktop", "hover", media.defaultMedia.url),
      responsiveStyles(templateStyles, pageLabels, "tablet", "hover", media.defaultMedia.url),
      responsiveStyles(templateStyles, pageLabels, "mobile", "hover", media.defaultMedia.url)
    )

    const DOMid = this.props.expManager.enable
      ? { id: `${handleDOMid(this.props.field, this.props.id, this.props.instanceId)}` }
      : {}
    const EMpreviewClass = emPreviewClass(this.props.expManager.enable, templateStyles.uuid)
    const EMScrollId = emScrollId(this.props.expManager.enable, this.props.object.uuid)
    const GlobalStylesClass = GlobalStylesUtils.handleClassNameV2(
      this.props.pageResponse.nGlobalStyles,
      templateStyles,
      "-box",
      {
        [gsType.GS_SHADOW]: NGS_CLASS_SUFIX,
        [gsType.GS_BOUNDARY]: NGS_CLASS_SUFIX,
      }
    )
    const customClasses = settings?.[this.props.field]?.class
      ? ` ${settings?.[this.props.field]?.class?.join(" ")}`
      : ""
    const classes = `${publicUtils["flx_flex"]}${EMpreviewClass ? ` ${EMpreviewClass} ` : ""}${
      GlobalStylesClass ? ` ${GlobalStylesClass} ` : ""
    }${customClasses}`

    return (
      <ContainerDiv
        as={TAG}
        ref={this.containerRef}
        desktop={DesktopStyles}
        mobile={MobileStyles}
        tablet={TabletStyles}
        hover={hoverStyles}
        {...EMScrollId}
        {...DOMid}
        className={classes}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          //handleOnClick(e, templateStyles)
          clickEvent(
            e,
            TAG,
            this.props.field,
            this.props.inlineContent,
            this.props.overrides,
            this.props.pageResponse,
            this.props.post
          )
        }}
        {...linkAttrs()}
      >
        {this.props.children}
      </ContainerDiv>
    )
  }
}

export default BobContainer
