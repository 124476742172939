import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tippy'
import { WarningDefaultSystemProps } from './interface'
import { warningSystemEM } from "../../../store/shared-store/actions/publicPageActions"

const WarningDefaultSystem: React.FC<WarningDefaultSystemProps> = ({ children, toggle }): JSX.Element => {

  const dispatch = useDispatch();
  const { warningState } = useSelector((state: any) => state.experienceManagerReducer.server);

  if (toggle) {
    return (
      <Tooltip
        title={warningState ? "Hide Information Notes on Preview" : "Show Information Notes on Preview"}
        position="bottom"
        trigger="mouseenter"
        arrow={true}
        theme="light"
        animation='fade'
        style={{ display: 'flex' }}
      >
        <i onClick={() => dispatch(warningSystemEM(!warningState))} className="fas fa-exclamation-circle" style={{ color: warningState ? 'rgb(33, 37, 41)' : 'rgb(118, 122, 129)' }}></i>
      </Tooltip>
    )
  }
  if (warningState) return <>{children}</>
  else return <></>;
}

export default WarningDefaultSystem;
