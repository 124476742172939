import React, { useState } from 'react'
import axios from "axios";
import apiEnv from "../../../../../../../api/shared-api/apiEnv";
import { FieldKeys, FormDefinition } from "../../formTypes";
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import TextInput from "../textInput/textInput";
import SelectInput from "../selectInput/selectInput";
import FileInput from "../fileInput/fileInput";
import CheckboxInput from "../checkboxInput/checkboxInput";
import * as inputTypes from "../../inputTypes";
import SubmitInput from "../submitInput/submitInput";
import TextAreaInput from "../textAraeaInput/textAreaInput";
import { handleColumns, responsiveGuttersClass } from "../../../../bob/bobHandler"
import { alignment } from "../../../../../../../modules/shared-modules/utilities/conditionalController"
import { FormContainerProps, FieldKeysLoop } from "./interface"
import simpleFormStyles from "../../../../../../../stylesheets/modules/templates/simpleForm.module.sass"
import { isBobForm } from "../../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const FormContainerChild: React.FC<FormContainerProps> = (props): JSX.Element => {
  const handleFieldsValues = (props: FormContainerProps) => {
    const bobFormId = isBobForm(props.obj.styles.bobs.form1).formId
    const formObject = props.pageResponse.forms && props.pageResponse.forms[bobFormId]
    if (bobFormId && formObject) {
      const fields = formObject.fields
      let formData = {}
      for (let inputObj in fields) {
        if (fields[inputObj].value) {
          formData = {
            ...formData,
            [fields[inputObj].id]: fields[inputObj].value,
          }
        }
      }
      return formData
    }
    return {}
  }

  const [formData, setFormData] = useState<any>(handleFieldsValues(props))
  const [formDataErrors, setFormDataErrors] = useState<any>({})
  const [submitFeedback, setSubmitFeedback] = useState<any>({})

  const handleProperBobField = (bobType: string) => {
    const objStyles = props.obj.styles
    const bobRule = props.pageResponse.components[objStyles.component].bob
    let properBobField = {},
      component
    for (component in bobRule) {
      if (bobRule[component].name === bobType) {
        properBobField = {
          ...properBobField,
          [bobRule[component].type]: objStyles.bobs[component],
        }
      }
    }
    return properBobField
  }

  const handleInputTypes = (inputObj: FieldKeysLoop, formObject: FormDefinition) => {
    const inputs: any = {
      [inputTypes.TEXT]: () => {
        return (
          <TextInput
            key={inputObj.idx}
            position={props.position}
            obj={props.obj}
            inputObj={inputObj}
            componentRule={props.componentRule}
            error={formDataErrors[inputObj.id]}
            handleValueChange={(value: string) => handleInputValue(inputObj.id, value)}
            bobStyle={handleProperBobField("text_input")}
            overrides={props.overrides}
            lang={formObject.lang}
            pageResponse={props.pageResponse}
            expManager={props.expManager}
          />
        )
      },
      [inputTypes.TEXTAREA]: () => {
        return (
          <TextAreaInput
            key={inputObj.idx}
            position={props.position}
            obj={props.obj}
            inputObj={inputObj}
            componentRule={props.componentRule}
            error={formDataErrors[inputObj.id]}
            handleValueChange={(value: string) => handleInputValue(inputObj.id, value)}
            bobStyle={handleProperBobField("text_input")}
            overrides={props.overrides}
            lang={formObject.lang}
            pageResponse={props.pageResponse}
            expManager={props.expManager}
          />
        )
      },
      [inputTypes.SELECT]: () => {
        return (
          <SelectInput
            key={inputObj.idx}
            position={props.position}
            obj={props.obj}
            componentRule={props.componentRule}
            inputObj={inputObj}
            error={formDataErrors[inputObj.id]}
            handleValueChange={(value: any) => handleInputValue(inputObj.id, value)}
            bobStyle={handleProperBobField("text_input")}
            overrides={props.overrides}
            lang={formObject.lang}
            pageResponse={props.pageResponse}
            expManager={props.expManager}
          />
        )
      },
      [inputTypes.FILE]: () => {
        return (
          <FileInput
            key={inputObj.idx}
            position={props.position}
            obj={props.obj}
            componentRule={props.componentRule}
            inputObj={inputObj}
            error={formDataErrors[inputObj.id]}
            handleValueChange={(value: any) => handleInputValue(inputObj.id, value)}
            bobStyle={handleProperBobField("file_input")}
            overrides={props.overrides}
            lang={formObject.lang}
            pageResponse={props.pageResponse}
            expManager={props.expManager}
            formId={isBobForm(props.obj.styles.bobs.form1).formId}
          />
        )
      },
      [inputTypes.CHECKBOX]: () => {
        return (
          <CheckboxInput
            key={inputObj.idx}
            position={props.position}
            obj={props.obj}
            componentRule={props.componentRule}
            inputObj={inputObj}
            error={formDataErrors[inputObj.id]}
            bobText={props.obj.styles.bobs.text3}
            overrides={props.overrides}
            handleValueChange={(value: any) => handleInputValue(inputObj.id, value)}
            bobStyle={handleProperBobField("checkbox_input")}
            lang={formObject.lang}
            pageResponse={props.pageResponse}
            expManager={props.expManager}
          />
        )
      },
    }
    return inputs[inputObj.fieldType]()
  }

  const handleFields = (fields: Array<FieldKeys>, formObject: FormDefinition) => {
    return fields.map((inputObj, idx) => handleInputTypes({ ...inputObj, idx }, formObject))
  }

  const handleInputValue = (prop: string, value: any) => {
    setFormData({
      ...formData,
      [prop]: value,
    })
  }

  /**
   *
   * @param formObjectFieldsArr
   * Returns an array with the missing fields
   */
  const checkRequiredValues = (formObjectFieldsArr: any) => {
    return formObjectFieldsArr.filter(
      (input: any) => input.required && (!formData[input.id] || formData[input.id] === "")
    )
  }

  const convertArrayToObject = (array: Array<any>, key: string) => {
    const initialValue = {}
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      }
    }, initialValue)
  }

  const handleSubmitErrors = (res: any, formObject: any) => {
    // validation refers only to form validation errors
    // message: "invalid-input-secret"
    // type: "recaptchaFailed"
    let errorsArray = res.data.validationErrors
    if (errorsArray && errorsArray.length > 0) {
      for (let error in errorsArray) {
        if (errorsArray[error].type === "recaptchaFailed")
          setSubmitFeedback({
            type: "error",
            text:
              formObject.lang === "en"
                ? "Something went wrong, please try again."
                : "Algo correu mal, por favor tente de novo.",
          })
      }
    } else {
      setSubmitFeedback({
        type: "error",
        text: formObject.lang === "en" ? "Something went wrong." : "Algo correu mal.",
      })
    }
  }

  const requestApi = (reacaptchToken: any) => {
    const bobFormId = isBobForm(props.obj.styles.bobs.form1).formId
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
      },
    }
    const body = {
      token: reacaptchToken,
      values: formData,
    }
    axios
      .post(`${apiEnv()}v1/forms/${bobFormId}/submit`, JSON.stringify(body), config)
      .then((res) => {
        if (res.data?.error) {
          handleSubmitErrors(res, formObject)
        }
        // success response
        else {
          setSubmitFeedback({
            type: "success",
            text: formObject.lang === "en" ? "Form submitted successfully." : "Formulário enviado com sucesso.",
          })
        }
      })
      .catch((error) => {
        console.warn("err", error)
        setSubmitFeedback({
          type: "error",
          text: formObject.lang === "pt" ? "Algo correu mal." : "Something went wrong.",
        })
      })
  }

  const handleSubmitForm = async (e: any, formObject: any): Promise<any> => {
    e.preventDefault()
    const reacaptchToken = await props.googleReCaptchaProps.executeRecaptcha("form")
    const missingFieldsArr = checkRequiredValues(formObject.fields)
    //Missing fields Form
    if (missingFieldsArr.length > 0) {
      const newFormDataErrors = convertArrayToObject(missingFieldsArr, "id")
      setFormDataErrors(newFormDataErrors)
    } else {
      //Clear errors
      setFormDataErrors({})
      requestApi(reacaptchToken)
    }
  }

  const handleGetSubmitBob = () => {
    for (let bob in props.obj.component.bob) {
      if (props.obj.component.bob[bob].name === "submit_input") return props.obj.component.bob[bob]
    }
  }

  const bobFormId = isBobForm(props.obj.styles.bobs.form1).formId
  const formObject = props.pageResponse.forms && props.pageResponse.forms[bobFormId]
  if (bobFormId && !formObject) {
    return <p>Form not Found!</p>
  }
  if (bobFormId && formObject) {
    const background1 = props.obj.styles.bobs.background1
    const { alignmentStyles } = alignment(background1)
    const columns = handleColumns(
      0,
      { desktop: 0, tablet: 0, mobile: 0 },
      background1,
      props.pageResponse.globalStyles[background1.globalStyleId]
    )
    return (
      <form
        name={formObject.name}
        className={`simple-form-component ${simpleFormStyles["simple-form-component"]}`}
        onSubmit={(e) => handleSubmitForm(e, formObject)}
        id={bobFormId}
      >
        <div
          className={`row  ${responsiveGuttersClass(
            background1,
            props.pageResponse.globalStyles[background1.globalStyleId]
          )}`}
          style={{ ...alignmentStyles }}
        >
          <div className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}>
            {handleFields(formObject.fields, formObject)}
            <div className='d-flex align-items-center'>
              <SubmitInput
                inputObj={handleGetSubmitBob}
                bobStyle={handleProperBobField("submit_input")}
                pageResponse={props.pageResponse}
                expManager={props.expManager}
              />
              <div className='submit-feedback'>
                <span
                  className={
                    submitFeedback.type ? (submitFeedback.type === "error" ? "submit-error" : "submit-success") : ""
                  }
                >
                  {submitFeedback.text}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  return <p>Please Select a Form</p>
}

const RecaptchaFormContainerChild = withGoogleReCaptcha(FormContainerChild)

const FormContainer: React.FC<FormContainerProps> = (props): JSX.Element => {
  const bobFormId = isBobForm(props.obj.styles.bobs.form1).formId
  const formObject = props.pageResponse.forms && props.pageResponse.forms[bobFormId]
  return (
    <div>
      {bobFormId && formObject?.captcha?.captchaType === "recaptcha" ? (
        <GoogleReCaptchaProvider reCaptchaKey={formObject.captcha.key}>
          <RecaptchaFormContainerChild {...props} />
        </GoogleReCaptchaProvider>
      ) : (
        <p>Missing Repatcha Key</p>
      )}
    </div>
  )
}

export default FormContainer
