import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { cssRenderUnsafe as FlexStyleCssRenderUnsafe } from "../bobStyles/flexStyle"
import { cssRenderUnsafe as SelfFlexStyleCssRenderUnsafe } from "../bobStyles/selfFlexStyle"
import { cssRenderUnsafe as HeightStyleCssRenderUnsafe } from "../bobStyles/heightStyleV2"
import { cssRenderUnsafe as MaxHeightStyleCssRenderUnsafe } from "../bobStyles/maxHeightStyle"
import { cssRenderUnsafe as MinHeightStyleCssRenderUnsafe } from "../bobStyles/minHeightStyle"
import { cssRenderUnsafe as WidthStyleCssRenderUnsafe } from "../bobStyles/widthStyleV2"
import { cssRenderUnsafe as MaxWidthStyleCssRenderUnsafe } from "../bobStyles/maxWidthStyle"
import { cssRenderUnsafe as MinWidthStyleCssRenderUnsafe } from "../bobStyles/minWidthStyle"
import { cssRenderUnsafe as ShadowStyleCssRenderUnsafe } from "../bobStyles/shadowStyleV2"
import { cssRenderUnsafe as BorderStyleCssRenderUnsafe } from "../bobStyles/borderStyleV2"
import { cssRenderUnsafe as RadiusStyleCssRenderUnsafe } from "../bobStyles/radiusStyle"
import { cssRenderUnsafe as BoundaryStyleCssRenderUnsafe } from "../bobStyles/boundaryStyleV2"
import { cssRenderUnsafe as BackgroundColorCssRenderUnsafe } from "../bobStyles/colorsStyleV2"
import { cssRenderUnsafe as ZIndexCssRenderUnsafe } from "../bobStyles/zindexStyle"
import { cssRenderUnsafe as PositionCssRenderUnsafe } from "../bobStyles/positionStyle"
import { cssRenderUnsafe as cssRenderUnsafeBoundary } from "../bobStyles/boundaryStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeTextShadow } from "../bobStyles/textShadowStyle"
import { cssRenderUnsafe as cssRenderUnsafeText } from "../bobStyles/textStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeTextArrange } from "../bobStyles/textArrangeStyle"
import { cssRenderUnsafe as cssRenderUnsafeColor } from "../bobStyles/colorStyle"
import { cssRenderUnsafe as cssRenderUnsafeCursor } from "../bobStyles/cursorStyle"
import { cssRenderUnsafe as TextDecorationCssRenderUnsafe } from "../bobStyles/textDecorationStyle"
import { BobInput } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { ColorLabel, FontLabel, PageStylesheet } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import {
  handleBobBorderLabel,
  handleBobColorsLabel,
  handleBobLabel,
  handleBobShadowLabel,
  handleBobTextDecorationLabel,
} from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

const responsiveStyles = (
  styles: BobInput,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
) => {
  const shadowColorLabel = handleBobShadowLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const borderColorLabel = handleBobBorderLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const colorLabel = handleBobLabel(styles, stylesheetLabels, breakpoint, behaviourState, "color") as ColorLabel
  const decorationColorLabel = handleBobTextDecorationLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const colorsLabel = handleBobColorsLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const textLabel = handleBobLabel(styles, stylesheetLabels, breakpoint, behaviourState, "fontFamily") as FontLabel

  return {
    ...HeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...WidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...FlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...SelfFlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...ShadowStyleCssRenderUnsafe(styles, breakpoint, behaviourState, undefined, shadowColorLabel),
    ...BoundaryStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...BorderStyleCssRenderUnsafe(styles, breakpoint, behaviourState, borderColorLabel),
    ...RadiusStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...ZIndexCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...PositionCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeCursor(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeText(styles, breakpoint, behaviourState, textLabel),
    ...cssRenderUnsafeTextArrange(styles, breakpoint, behaviourState),
    ...HeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...WidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...SelfFlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeBoundary(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeColor(styles, breakpoint, behaviourState, undefined, colorLabel, false),
    ...cssRenderUnsafeCursor(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeTextShadow(styles, breakpoint, behaviourState, undefined, shadowColorLabel),
    ...PositionCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...TextDecorationCssRenderUnsafe(styles, breakpoint, behaviourState, decorationColorLabel),
    ...BackgroundColorCssRenderUnsafe(styles, breakpoint, behaviourState, undefined, colorsLabel),
  }
}

export { responsiveStyles }
