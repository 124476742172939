import React, { Fragment, RefObject, useState } from "react"
import {
  ObjectContentOverride,
  Post,
} from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { MediaKeyed, PageTypes } from "../../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { SingleObject } from "../../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import WarningSystem from "../../../../../../modules/shared-modules/experienceManager/warningSystem"
import BobMedia from "../../../bob/bobMediaV2/bobMediaV2"
import ComponentSelectorEMv2 from "../../../componentSelectorEM/ComponentSelectorEMv2"
import { MediaV2Props } from "./interface"
import * as defaultObj from "../../../../../../modules/shared-modules/experienceManager/defaultObj/defaultObj"

function getPosts(
  obj: SingleObject,
  bobName: string,
  ov: ObjectContentOverride | undefined,
  page: PageTypes
): [Post[], MediaKeyed] {
  if (ov?.formula?.posts) {
    // old api
    return [ov.formula.posts, {}]
  } else if (obj.content.formula?.posts) {
    // object with formula
    return [obj.content.formula.posts, {}]
  } else if (page.contentPage?.content && obj.content?.inlineContent?.[`${bobName}.contentSrc`] === "content-page") {
    // content page
    return [[page.contentPage.content.post], page.contentPage.content.media]
  } else return [[defaultObj.main.staticPost], {}]
}

const MediaV2: React.FC<MediaV2Props> = (props): JSX.Element => {
  const [elRef, setElRef] = useState<RefObject<HTMLDivElement> | undefined>(undefined)
  const bobName = "nmedia1"
  const [items, extraMedia] = getPosts(props.obj, bobName, props.overrides, props.pageResponse)
  const replaceMedia = { ...props.replacedMediaList, ...extraMedia }

  if (items && items.length > 0) {
    return (
      <>
        {items.map((item: Post, idx: number) => (
          <Fragment key={idx}>
            {props.expManager.enable && !props.expManager.content && (
              <ComponentSelectorEMv2
                uuid={props.obj.uuid}
                masterBob={bobName}
                obj={props.obj}
                editing={props.editing}
                elRef={elRef}
              />
            )}
            <BobMedia
              setRef={(containerRef) => setElRef(containerRef)}
              replacedMediaList={replaceMedia}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              overrides={props.overrides}
              field={bobName}
              post={item}
              expManager={props.expManager}
            />
          </Fragment>
        ))}
      </>
    )
  } else {
    if (props.expManager.enable) {
      return <WarningSystem alertType='alert-secondary w-100 text-center' messages='Add a single post or a feed' />
    }
  }

  return <Fragment />
}

export default MediaV2
