import apiEnv from './apiEnv'

class PublicPageApi {
  static getDataSet(datasetId: string, filters: any, nextCursor?: string) {
    // when filters are not present, body must not exist
    let bodyObj = JSON.stringify(filters) !== '{}' ? { filters } : undefined
    let endpoint = `${apiEnv()}v1/datasets/${datasetId}/content`
    if (nextCursor)
      endpoint = `${apiEnv()}v1/datasets/${datasetId}/content?cursor=${nextCursor}`
    const request = new Request(endpoint, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
      body: JSON.stringify(bodyObj)
    })
    return fetch(request)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        throw (response)
      })
      .catch(error => {
        throw (error)
      })
  }

  static getDataSetList(profileId: string, token: any) {
    const request = new Request(`${apiEnv()}v1/profiles/${profileId}/datasetsEM`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'MeshApp-host': `${profileId}`,
        'Authorization': `Bearer ${token.access_token}`,
      })
    })
    return fetch(request)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        throw (response)
      })
      .catch(error => {
        throw (error)
      })
  }

  static getDataSetFiltersList(objectId: string) {
    const request = new Request(`${apiEnv()}v1/`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
    })
    return fetch(request)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        throw (response)
      })
      .catch(error => {
        throw (error)
      })
  }
}

export default PublicPageApi