import React from 'react'
// @ts-ignore
import Style from 'style-it'
import styled from 'styled-components'
import { responsiveProperties, responsiveGroupStylesProperties } from "../../../../bob/bobHandler"
import { handleBackgroundStyles, handleTextStyles } from "../../../../bob/bobFormComponents"
import { SubmitInputProps, ResponsiveCSSProps } from "./interface"
import { cssRenderUnsafe as cssRenderUnsafeColor } from "../../../../bob/bobStyles/colorStyle"
import { conditionalColorsRenderUnsafe as cssRenderUnsafeColors } from "../../../../../../../modules/shared-modules/utilities/conditionalController"

const SubmitInputElement = styled.div`
  ${(props: ResponsiveCSSProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: ResponsiveCSSProps) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: ResponsiveCSSProps) => props.mobile}
  }
`

const SubmitInput: React.FC<SubmitInputProps> = (props): JSX.Element => {
  const createMarkup = (html: any) => {
    return { __html: html }
  }

  let backgroundStyles: any = handleBackgroundStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles)
  let textStyles: any = handleTextStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles, props.expManager)

  let styles = { ...backgroundStyles, ...textStyles.styles }
  let mobileStyles = responsiveProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", undefined, [
    "colors",
  ])
  mobileStyles = {
    ...mobileStyles,
    ...responsiveGroupStylesProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "text"),
    ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "default", true),
    ...cssRenderUnsafeColor(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "mobile",
      "default",
      undefined,
      undefined,
      true
    ),
  }
  let tabletStyles = responsiveProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", undefined, [
    "colors",
  ])
  tabletStyles = {
    ...tabletStyles,
    ...responsiveGroupStylesProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "text"),
    ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "default", true),
    ...cssRenderUnsafeColor(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "tablet",
      "default",
      undefined,
      undefined,
      true
    ),
  }
  return (
    <Style>
      {`
      ${textStyles.fontLoaded}          
    `}
      <SubmitInputElement
        as='button'
        desktop={styles}
        mobile={mobileStyles}
        tablet={tabletStyles}
        type='submit'
        className='btn btn-primary'
      >
        <span
          ref={() => "dangerousHTMLGenericPost"}
          className='dangerousHTML'
          dangerouslySetInnerHTML={createMarkup(props.bobStyle.bobInput.customText)}
        />
      </SubmitInputElement>
    </Style>
  )
}

export default SubmitInput