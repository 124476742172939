import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  number as numberC,
  string as stringC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { get2WithNull, get2WithNullConditional, showIfWritten } from "../bobUtils"
import { ColorLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import { handleBobStylesheetLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */

// props only, no enable
const ColorsPropsCodec = type({
  colorFirst: stringC,
  colorSecond: stringC,
  gradientAngle: numberC,
  gradientDirection: stringC,
  isGradient: booleanC,
})
const ColorsCodec = intersection([type({ enable: booleanC }), ColorsPropsCodec])

// props only, no enable
const ColorsPropsOptCodec = partial({
  colorFirst: nullable(stringC),
  colorSecond: nullable(stringC),
  gradientAngle: nullable(numberC),
  gradientDirection: nullable(stringC),
  isGradient: nullable(booleanC),
})
const ColorsOptCodec = intersection([partial({ enable: nullable(booleanC) }), ColorsPropsOptCodec])

const StylesColorsCodec = intersection([
  type({ colors: ColorsCodec }),
  partial({
    behaviour: partial({
      active: partial({
        colors: ColorsOptCodec,
      }),
      hover: partial({
        colors: ColorsOptCodec,
      }),
    }),
    mobile: partial({
      colors: ColorsOptCodec,
      behaviour: partial({
        active: partial({
          colors: ColorsOptCodec,
        }),
        hover: partial({
          colors: ColorsOptCodec,
        }),
      }),
    }),
    tablet: partial({
      colors: ColorsOptCodec,
      behaviour: partial({
        active: partial({
          colors: ColorsOptCodec,
        }),
        hover: partial({
          colors: ColorsOptCodec,
        }),
      }),
    }),
  }),
])

// const GSColorsCodec = intersection([
//   ColorsPropsCodec,
//   partial({
//     behaviour: partial({
//       active: ColorsPropsOptCodec,
//       hover: ColorsPropsOptCodec,
//     }),
//     mobile: intersection([
//       ColorsPropsOptCodec,
//       partial({
//         behaviour: partial({
//           active: ColorsPropsOptCodec,
//           hover: ColorsPropsOptCodec,
//         }),
//       }),
//     ]),
//     tablet: intersection([
//       ColorsPropsOptCodec,
//       partial({
//         behaviour: partial({
//           active: ColorsPropsOptCodec,
//           hover: ColorsPropsOptCodec,
//         }),
//       }),
//     ]),
//   }),
// ])

type Colors = TypeOf<typeof ColorsCodec>
type ColorsProps = TypeOf<typeof ColorsPropsCodec>
type ColorsOpt = TypeOf<typeof ColorsOptCodec>
type ColorsPropsOpt = TypeOf<typeof ColorsPropsOptCodec>
type StylesColors = TypeOf<typeof StylesColorsCodec>
// type GSColors = TypeOf<typeof GSColorsCodec>
type ColorsCSS = { "background-color": string } | { "background-image": string }
type ColorsColorLabels = "color-first" | "color-second"

export function colorsCss(css: ColorsCSS | ""): string {
  if (css.hasOwnProperty("background-color")) return (css as { "background-color": string })["background-color"]
  return ""
}

export function responsiveStyle(colorsObj: any, desktopDefaultValues?: any, breakpointDefaultValues?: any) {
  const hasEnable = colorsObj.hasOwnProperty("enable")
  if (hasEnable && !objValueExists(colorsObj, "enable")) return ""

  /**
   * Check if property is available, and fallback to breakpointDefault or the desktopDefault
   */
  const colorFirst = colorsObj?.colorFirst || breakpointDefaultValues?.colorFirst || desktopDefaultValues?.colorFirst
  const colorSecond =
    colorsObj?.colorSecond || breakpointDefaultValues?.colorSecond || desktopDefaultValues?.colorSecond
  const gradientAngle =
    colorsObj?.gradientAngle || breakpointDefaultValues?.gradientAngle || desktopDefaultValues?.gradientAngle
  // TODO: was not used in old method, why?
  // const gradientDirection =
  //   colorsObj?.gradientDirection ||
  //   breakpointDefaultValues?.gradientDirection ||
  //   desktopDefaultValues?.gradientDirection
  const isGradient = colorsObj?.isGradient || breakpointDefaultValues?.isGradient || desktopDefaultValues?.isGradient
  // colors prop
  // TODO: old method returned {} when isGradient was disabled, shouldn't it return background-image: none ?
  if (hasEnable && !colorsObj.enable) return !isGradient ? { "background-color": "transparent !important" } : {}
  if (!isGradient) return { "background-color": colorFirst + " !important" }
  else
    return {
      "background-image": `linear-gradient(${gradientAngle}deg, ${colorFirst}, ${colorSecond}) !important`,
    }
}

export function renderCSSString(stylesObj: Partial<ColorsCSS>): string {
  return `
    ${showIfWritten(stylesObj, "background-color")} 
    `
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined, // TODO: fix when working on other bobs
  stylesheetLabel: { [key in ColorsColorLabels]: ColorLabel | undefined } | undefined
): Partial<ColorsCSS> {
  /**
   * Colors in bobMEdia was optional in the BE, so some bobmedias didn't have colors written on the template.
   * A migration was runed to writte the colors object in all bobMedias,
   * this migration won't affect public pages until they are published again.
   * So, the codec will still break in this cases, which can haven bad effects on future logic, beware.
   */
  const styles = StylesColorsCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, foundationStyle, stylesheetLabel)
  console.warn(decoderErrors(styles))
  return {
    "background-color": "transparent",
  }
}

// export function globalStyleCssRenderUnsafe(
//   gsObj: any,
//   breakpoint: Breakpoint,
//   behaviourState: BehaviourState_,
//   foundationStyle: FColor | undefined = undefined // TODO: Work on when foundation is added to global styles
// ): ColorsCSS | "" {
//   const gs = GSColorsCodec.decode(gsObj)
//   if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, foundationStyle)
//   console.warn(decoderErrors(gs))
//   return ""
// }

export function cssRender(
  stylesObj: StylesColors,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined,
  stylesheetLabel: { [key in ColorsColorLabels]: ColorLabel | undefined } | undefined
): Partial<ColorsCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.colors, foundationStyle, stylesheetLabel)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.colors,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.colors, stylesObj.colors),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(
        stylesObj.colors,
        mergeBob2(stylesObj?.[breakpoint]?.colors, stylesObj.colors),
        foundationStyle,
        stylesheetLabel
      )
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.colors,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.colors,
          stylesObj?.behaviour?.[behaviourState]?.colors,
          stylesObj?.[breakpoint]?.colors,
          stylesObj.colors
        ),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
}

// export function globalStyleCssRender(
//   stylesObj: GSColors,
//   breakpoint: Breakpoint,
//   behaviourState: BehaviourState_,
//   foundationStyle: FColor | undefined
// ): ColorsCSS {
//   if (breakpoint === "desktop") {
//     if (behaviourState === "default") {
//       return render(stylesObj, foundationStyle)
//     }
//     //hover | active
//     else {
//       return render(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj), foundationStyle)
//     }
//   }
//   //tablet | mobile
//   else {
//     if (behaviourState === "default") {
//       return render(merge2(stylesObj?.[breakpoint], stylesObj), foundationStyle)
//     }
//     //hover | active
//     else {
//       return render(
//         merge3(
//           stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
//           stylesObj.behaviour?.[behaviourState],
//           stylesObj?.[breakpoint],
//           stylesObj
//         ),
//         foundationStyle
//       )
//     }
//   }
// }

/**
 * Renders Colors css for desktop template
 *
 * @param colorsObj
 * @param foundationStyle
 * @returns
 */
export function renderBob(
  colorsObj: Colors,
  foundationStyle: FColor | undefined,
  stylesheetLabel: { [key in ColorsColorLabels]: ColorLabel | undefined } | undefined
): Partial<ColorsCSS> {
  if (colorsObj.enable === false) {
    return {}
  }

  return render(colorsObj, foundationStyle, stylesheetLabel)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param colorsObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(
  defaultColorsObj: Colors,
  colorsObj: ColorsOpt,
  foundationStyle: FColor | undefined,
  stylesheetLabel: { [key in ColorsColorLabels]: ColorLabel | undefined } | undefined
): Partial<ColorsCSS> {
  if (colorsObj?.enable === false) {
    if (defaultColorsObj.enable) return { "background-color": `transparent` }

    return {}
  }

  if (colorsObj?.enable === true) return renderOpt(colorsObj, foundationStyle, stylesheetLabel)

  return {}
}

/**
 * Renders Colors css for desktop template
 * Returns background color for nonGradient style
 * or background-image for gradient style
 *
 * @param colorsObj
 * @param foundationStyle
 * @returns
 */
export function render(
  colorsObj: ColorsProps,
  foundationStyle: FColor | undefined,
  stylesheetLabel: { [key in ColorsColorLabels]: ColorLabel | undefined } | undefined
): ColorsCSS {
  // single color css
  if (!colorsObj.isGradient) {
    return { "background-color": handleBobStylesheetLabel(stylesheetLabel?.["color-first"], colorsObj.colorFirst) }
    // gradient color css
  } else {
    return {
      "background-image": `linear-gradient(${colorsObj.gradientAngle}deg, ${handleBobStylesheetLabel(
        stylesheetLabel?.["color-first"],
        colorsObj.colorFirst
      )}, ${handleBobStylesheetLabel(stylesheetLabel?.["color-second"], colorsObj.colorSecond)})`,
    }
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * Returns background color for nonGradient style
 * or background-image for gradient style
 * or empty for non written style props
 *
 * @param colorsObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(
  colorsObj: ColorsPropsOpt,
  foundationStyle: FColor | undefined,
  stylesheetLabel: { [key in ColorsColorLabels]: ColorLabel | undefined } | undefined
): Partial<ColorsCSS> {
  // single color css
  if (!colorsObj.isGradient) {
    if (colorsObj.colorFirst || stylesheetLabel?.["color-first"])
      return { "background-color": handleBobStylesheetLabel(stylesheetLabel?.["color-first"], colorsObj.colorFirst) }
    // gradient color css
  } else if (
    objValueExists(colorsObj, "gradientAngle") &&
    (colorsObj.colorFirst || stylesheetLabel?.["color-first"]) &&
    (colorsObj.colorSecond || stylesheetLabel?.["color-first"])
  ) {
    return {
      "background-image": `linear-gradient(${colorsObj.gradientAngle}deg, ${handleBobStylesheetLabel(
        stylesheetLabel?.["color-first"],
        colorsObj.colorFirst
      )}, ${handleBobStylesheetLabel(stylesheetLabel?.["color-second"], colorsObj.colorSecond)})`,
    }
  }
  // no written style props
  return {}
}

/**
 * Fallback templateObj values to defaultObj value when needed
 * used for breakpoints/states(default) templates
 *
 * For values that are not written on the templateObj and are interpreted as css,
 * the fallback value shouldn't be used as the browser deals with the fallback by itself
 *
 * For values that are not interpreted as css, like isGradient, the fallback must be allways used when needed,
 * as this value will not be dealt by the browser
 *
 * For values that are needed together for a single css property,
 * if one of those values is written on the templateObj the other values must use the fallback value when needed
 *
 * @param colorsObj
 * @param defaultColorsObj
 * @returns Colors
 *
 */
export function mergeBob2(colorsObj: ColorsOpt | undefined, defaultColorsObj: Colors): ColorsOpt {
  const fallbackTemplate = {
    enable: get2WithNull(colorsObj?.enable, defaultColorsObj.enable),
    isGradient: get2WithNull(colorsObj?.isGradient, defaultColorsObj.isGradient),
    colorFirst: get2WithNull(colorsObj?.colorFirst, defaultColorsObj.colorFirst),
    colorSecond: get2WithNull(colorsObj?.colorSecond, defaultColorsObj.colorSecond),
    gradientAngle: get2WithNull(colorsObj?.gradientAngle, defaultColorsObj.gradientAngle),
    gradientDirection: get2WithNull(colorsObj?.gradientDirection, defaultColorsObj.gradientDirection),
  }

  const isGradientValueWritten = handleGradientValueWritten(fallbackTemplate)

  // return fallback for gradient values when at least one exists
  if (isGradientValueWritten) return fallbackTemplate

  return {
    enable: fallbackTemplate.enable,
    isGradient: fallbackTemplate.isGradient,
    colorFirst: colorsObj?.colorFirst,
    colorSecond: colorsObj?.colorSecond,
    gradientAngle: colorsObj?.gradientAngle,
    gradientDirection: colorsObj?.gradientDirection,
  }
}

/**
 * Fallback templateObj values to defaultObj value when needed
 * used for globalstyles breakpoints/states(default) templates
 *
 * For values that are not written on the templateObj and are interpreted as css,
 * the fallback value shouldn't be used as the browser deals with the fallback by itself
 *
 * For values that are not interpreted as css, like isGradient, the fallback must be allways used when needed,
 * as this value will not be dealt by the browser
 *
 * For values that are needed together for a single css property,
 * if one of those values is written on the templateObj the other values must use the fallback value when needed
 *
 * @param colorsObj
 * @param defaultColorsObj
 * @returns ColorsProps
 *
 */
export function merge2(colorsObj: ColorsPropsOpt | undefined, defaultColorsObj: ColorsProps): ColorsPropsOpt {
  const isGradientValueWritten = handleGradientValueWritten(colorsObj)

  const isGradient = get2WithNull(colorsObj?.isGradient, defaultColorsObj.isGradient)
  const colorFirst = get2WithNullConditional(
    colorsObj?.colorFirst,
    defaultColorsObj.colorFirst,
    colorsObj?.isGradient === true && isGradientValueWritten
  )
  const colorSecond = get2WithNullConditional(
    colorsObj?.colorSecond,
    defaultColorsObj.colorSecond,
    colorsObj?.isGradient === true && isGradientValueWritten
  )
  const gradientAngle = get2WithNullConditional(
    colorsObj?.gradientAngle,
    defaultColorsObj.gradientAngle,
    colorsObj?.isGradient === true && isGradientValueWritten
  )
  const gradientDirection = get2WithNullConditional(
    colorsObj?.gradientDirection,
    defaultColorsObj.gradientDirection,
    colorsObj?.isGradient === true && isGradientValueWritten
  )

  return {
    colorFirst,
    colorSecond,
    gradientAngle,
    gradientDirection,
    isGradient,
  }
}

/**
 * Fallback templateObj values to defaultObj value when needed
 * used for states inside breakpoints templates
 *
 * For values that are not written on the templateObj and are interpreted as css,
 * the fallback value shouldn't be used as the browser deals with the fallback by itself
 *
 * For values that are not interpreted as css, like isGradient, the fallback must be allways used when needed,
 * as this value will not be dealt by the browser
 *
 * For values that are needed together for a single css property,
 * if one of those values is written on the templateObj the other values must use the fallback value when needed
 *
 * @param colorsObj
 * @param colorsDefaultBreakpoint
 * @param defaultColorsObj
 * @returns Colors
 */
export function mergeBob3(
  colorsObj: ColorsOpt | undefined,
  colorsDesktopBehaviour: ColorsOpt | undefined,
  colorsDefaultBreakpoint: ColorsOpt | undefined,
  defaultColorsObj: Colors
): ColorsOpt {
  const fallbackTemplate = {
    enable:
      colorsObj?.enable ?? colorsDesktopBehaviour?.enable ?? colorsDefaultBreakpoint?.enable ?? defaultColorsObj.enable,
    isGradient:
      colorsObj?.isGradient ??
      colorsDesktopBehaviour?.isGradient ??
      colorsDefaultBreakpoint?.isGradient ??
      defaultColorsObj.isGradient,
    colorFirst:
      colorsObj?.colorFirst ??
      colorsDesktopBehaviour?.colorFirst ??
      colorsDefaultBreakpoint?.colorFirst ??
      defaultColorsObj.colorFirst,
    colorSecond:
      colorsObj?.colorSecond ??
      colorsDesktopBehaviour?.colorSecond ??
      colorsDefaultBreakpoint?.colorSecond ??
      defaultColorsObj.colorSecond,
    gradientAngle:
      colorsObj?.gradientAngle ??
      colorsDesktopBehaviour?.gradientAngle ??
      colorsDefaultBreakpoint?.gradientAngle ??
      defaultColorsObj.gradientAngle,
    gradientDirection:
      colorsObj?.gradientDirection ??
      colorsDesktopBehaviour?.gradientDirection ??
      colorsDefaultBreakpoint?.gradientDirection ??
      defaultColorsObj.gradientDirection,
  }

  const isGradientValueWritten = handleGradientValueWritten(fallbackTemplate)

  // return fallback for gradient values when at least one exists
  if (isGradientValueWritten) return fallbackTemplate

  return {
    enable: fallbackTemplate.enable,
    isGradient: fallbackTemplate.isGradient,
    colorFirst: colorsObj?.colorFirst,
    colorSecond: colorsObj?.colorSecond,
    gradientAngle: colorsObj?.gradientAngle,
    gradientDirection: colorsObj?.gradientDirection,
  }
}

/**
 * Fallback templateObj values to defaultObj value when needed
 * used for globalstyles states inside breakpoints templates
 *
 * For values that are not written on the templateObj and are interpreted as css,
 * the fallback value shouldn't be used as the browser deals with the fallback by itself
 *
 * For values that are not interpreted as css, like isGradient, the fallback must be allways used when needed,
 * as this value will not be dealt by the browser
 *
 * @param colorsObj
 * @param colorsDefaultBreakpoint
 * @param defaultColorsObj
 * @returns Colors
 */
export function merge3(
  colorsObj: ColorsPropsOpt | undefined,
  colorsDesktopBehaviour: ColorsPropsOpt | undefined,
  colorsDefaultBreakpoint: ColorsPropsOpt | undefined,
  defaultColorsObj: ColorsProps
): ColorsPropsOpt {
  const isGradientValueWritten = handleGradientValueWritten(colorsObj)

  const isGradient =
    colorsObj?.isGradient ??
    colorsDesktopBehaviour?.isGradient ??
    colorsDefaultBreakpoint?.isGradient ??
    defaultColorsObj.isGradient
  const colorFirst =
    colorsObj?.isGradient === true && isGradientValueWritten
      ? colorsObj?.colorFirst ??
        colorsDesktopBehaviour?.colorFirst ??
        colorsDefaultBreakpoint?.colorFirst ??
        defaultColorsObj.colorFirst
      : colorsObj?.colorFirst
  const colorSecond =
    colorsObj?.isGradient === true && isGradientValueWritten
      ? colorsObj?.colorSecond ??
        colorsDesktopBehaviour?.colorSecond ??
        colorsDefaultBreakpoint?.colorSecond ??
        defaultColorsObj.colorSecond
      : colorsObj?.colorSecond
  const gradientAngle =
    colorsObj?.isGradient === true && isGradientValueWritten
      ? colorsObj?.gradientAngle ??
        colorsDesktopBehaviour?.gradientAngle ??
        colorsDefaultBreakpoint?.gradientAngle ??
        defaultColorsObj.gradientAngle
      : colorsObj?.gradientAngle
  const gradientDirection =
    colorsObj?.isGradient === true && isGradientValueWritten
      ? colorsObj?.gradientDirection ??
        colorsDesktopBehaviour?.gradientDirection ??
        colorsDefaultBreakpoint?.gradientDirection ??
        defaultColorsObj.gradientDirection
      : colorsObj?.gradientDirection

  return {
    colorFirst,
    colorSecond,
    gradientAngle,
    gradientDirection,
    isGradient,
  }
}

/**
 * Checks if at least one gradient needed value is written
 *
 * @param colorsObj
 * @returns
 */
export function handleGradientValueWritten(colorsObj: Colors | ColorsOpt | undefined): boolean {
  return colorsObj?.isGradient &&
    (colorsObj?.colorFirst ?? colorsObj?.colorSecond ?? colorsObj?.gradientAngle ?? colorsObj?.gradientDirection)
    ? true
    : false
}

// GSColors
export type { ColorsCSS, StylesColors, ColorsProps, ColorsPropsOpt, Colors, ColorsOpt }
