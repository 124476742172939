import React, { Fragment } from 'react'
import BobComponentHandler from '../../../bob/bobComponentHandler'
import ComponentHandler from '../../../../componentHandler'
import {
  getContent,
  handleContentBlock,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import WarningDefaultSystem from "../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { Post } from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import Dots from "./dots/dots"
import SwipeableViews from "react-swipeable-views"
import { SliderContainerV3Props } from "."
import sliderComponentV3Styles from "../../../../../../stylesheets/modules/templates/sliderComponentV3.module.sass"
import { isBobBackground } from '../../../../../../modules/shared-modules/experienceManager/types/stylesTypes'

type SliderContainerV3State = {
  activeSlide: number
  activeBreakpoint?: "desktop" | "tablet" | "mobile"
}

class SliderContainerV3 extends React.Component<SliderContainerV3Props, SliderContainerV3State> {
  sliderContainer = React.createRef<HTMLDivElement>()
  totalSlides: number = 0
  screenX: number = 0

  constructor(props: SliderContainerV3Props) {
    super(props)
    this.state = {
      activeSlide: 0,
    }
  }

  componentDidMount() {
    // handle window for page builder iframe or public pages
    const iframe = document.getElementById("board-preview-frame") as HTMLIFrameElement
    const handledWindow = iframe?.contentWindow || window

    // Add resize eventListener, check window size on mount
    handledWindow.addEventListener("resize", () => this.windowResized(handledWindow))
    this.windowResized(handledWindow)
  }

  componentWillUnmount() {
    // handle document and window for page builder iframe or public pages
    const iframe = document.getElementById("board-preview-frame") as HTMLIFrameElement
    const handledWindow = iframe?.contentWindow || window

    // Clean resize eventListeners
    handledWindow.removeEventListener("resize", () => this.windowResized(handledWindow))
  }

  windowResized = (window: Window) => {
    let breakpointValue = "desktop" as SliderContainerV3State["activeBreakpoint"]
    if (window.matchMedia("(max-width: 766px)").matches) {
      breakpointValue = "mobile"
    } else if (window.matchMedia("(max-width: 992px) and (min-width: 767px)").matches) {
      breakpointValue = "tablet"
    }
    if (breakpointValue !== this.state.activeBreakpoint) {
      this.setState({
        activeBreakpoint: breakpointValue,
      })
    }
  }

  getChilds = () => {
    if (this.props.obj.isContentBlock) {
      const posts = this.props.obj.content.formula?.posts
      return posts && posts.length > 0 ? posts : this.props.obj.children
    }
    return this.props.obj.children
  }

  getObjectChildrens = () => {
    return this.props.obj.children
  }

  isContentBlockPosts = () => {
    if (this.props.obj.isContentBlock) {
      const posts = this.props.obj.content.formula?.posts
      return posts && posts.length > 0 ? true : false
    }
    return false
  }

  getColumnByBreakpoint = () => {
    const bobBackground1 = isBobBackground(this.props.obj.styles.bobs.background1)
    if (this.state.activeBreakpoint === "mobile")
      return bobBackground1.mobile?.grid?.columns?.length ?? bobBackground1.grid.columns.length
    if (this.state.activeBreakpoint === "tablet")
      return bobBackground1.tablet?.grid?.columns?.length ?? bobBackground1.grid.columns.length
    return bobBackground1.grid.columns.length
  }

  slide = (action: string) => {
    const newSlide = action === "next" ? this.state.activeSlide + 1 : this.state.activeSlide - 1

    // don't slide if on first or last slide
    if (newSlide < 0 || newSlide >= this.totalSlides) return

    this.setState({
      ...this.state,
      activeSlide: newSlide,
    })
  }

  goToSlide = (newSlide: number) => {
    this.setState({
      ...this.state,
      activeSlide: newSlide,
    })
  }

  onSwipeChangeIndex = (index: number) => {
    this.setState({
      ...this.state,
      activeSlide: index,
    })
  }

  isDrag = (e: any) => {
    const delta = Math.abs(e.screenX - this.screenX)
    return delta > 10
  }

  handleMouseDown = (e: any) => {
    this.screenX = e.screenX
  }

  // prevent child click on mouse drag, touch events do this already
  handleMouseUp = (e: any) => {
    const sliderChildren = e.currentTarget.children
    if (sliderChildren) {
      for (let i = 0; i < sliderChildren.length; i++) {
        const child = sliderChildren[i]
        child.onclick = (event: any) => {
          if (this.isDrag(e)) {
            event.preventDefault()
            event.stopPropagation()
            return false
          }
          return
        }
      }
    }
  }

  /**
   * handle navigation arrow visibility
   * if it can not move to that direction, the arrow should be hidden
   * @param action
   * @param childComponentsList
   */
  arrowCanSlide = (direction: string) => {
    if (direction === "prev") return this.state.activeSlide !== 0
    if (direction === "next") {
      return this.state.activeSlide + 1 < this.totalSlides
    }
  }

  groupItemsBySlides(items: any, slidesNumber: any): Array<Array<any>> {
    var group: any = []
    for (var i = 0, j = 0; i < items.length; i++) {
      if (i >= slidesNumber && i % slidesNumber === 0) j++
      group[j] = group[j] || []
      group[j].push(items[i])
    }
    return group
  }

  renderComponents = (childComponentsId: Array<string>, post: any) => {
    return childComponentsId.map((item: string, idx: number) => {
      let handledItem = handleObjectType(
        this.props.obj,
        item,
        this.props.pageResponse,
        this.props.expManager.emEditorType
      )
      return (
        <ComponentHandler
          position={idx}
          selectedInstanceId={this.props.obj.selectedInstanceId}
          key={idx}
          // component={handledItem}
          component={handleContentBlock(
            handledItem,
            post,
            this.props.obj,
            this.props.pageResponse,
            this.props.overrides
          )}
          pageResponse={this.props.pageResponse}
          matchOrder={this.props.matchOrder}
          expManager={this.props.expManager}
        />
      )
    })
  }

  renderContentBlockSlideItems = (slide: any, counter: any, objectChildren: any, background1TemplateMerged: any) => {
    if (slide && slide.length > 0) {
      return slide.map((item: Post, idxPost: number) => {
        const columns = handleColumns(
          idxPost,
          counter,
          background1TemplateMerged,
          this.props.pageResponse.globalStyles[background1TemplateMerged.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            key={idxPost}
            className={`slider-item ${sliderComponentV3Styles["slider-item"]} col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            {this.renderComponents(objectChildren, item)}
          </div>
        )
      })
    }
  }

  renderFormulaContentSlideItems = (
    slide: any,
    counter: any,
    childIs: Array<string>,
    background1TemplateMerged: any
  ) => {
    if (slide && slide.length > 0) {
      return slide.map((post: any, idx: number) => {
        const columns = handleColumns(
          idx,
          counter,
          background1TemplateMerged,
          this.props.pageResponse.globalStyles[background1TemplateMerged.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            key={idx}
            className={`slider-item ${sliderComponentV3Styles["slider-item"]} col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            {this.renderComponents(childIs, post)}
          </div>
        )
      })
    }
  }

  renderChildrenSlideItems = (slide: any, counter: any, background1TemplateMerged: any) => {
    if (slide && slide.length > 0) {
      return slide.map((item: string, idx: number) => {
        const handledItem = handleObjectType(
          this.props.obj,
          item,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        const columns = handleColumns(
          idx,
          counter,
          background1TemplateMerged,
          this.props.pageResponse.globalStyles[background1TemplateMerged.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            key={idx}
            className={`slider-item ${sliderComponentV3Styles["slider-item"]} col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            <ComponentHandler
              position={idx}
              selectedInstanceId={this.props.obj.selectedInstanceId}
              key={idx}
              component={handledItem}
              pageResponse={this.props.pageResponse}
              matchOrder={this.props.matchOrder}
              expManager={this.props.expManager}
            />
          </div>
        )
      })
    }
  }

  renderSlides = (
    contentType: "contentBlock" | "formula" | "children",
    background1TemplateMerged: any,
    slides: any,
    childIds: any
  ) => {
    // using content block
    if (contentType === "contentBlock") {
      const objectChildren = this.getObjectChildrens()
      return slides.map((slide: any, idx: number) => {
        let counter = { desktop: 0, tablet: 0, mobile: 0 }
        return (
          <div
            key={idx}
            className='slide'
            onMouseUp={this.handleMouseUp}
            onMouseLeave={this.handleMouseUp}
            onMouseDown={this.handleMouseDown}
          >
            {this.renderContentBlockSlideItems(slide, counter, objectChildren, background1TemplateMerged)}
          </div>
        )
      })
    }
    // not using content block
    else {
      // using formula
      if (contentType === "formula") {
        return slides.map((slide: any, idx: number) => {
          let counter = { desktop: 0, tablet: 0, mobile: 0 }
          return (
            <div
              key={idx}
              className='slide'
              onMouseUp={this.handleMouseUp}
              onMouseLeave={this.handleMouseUp}
              onMouseDown={this.handleMouseDown}
            >
              {this.renderFormulaContentSlideItems(slide, counter, childIds, background1TemplateMerged)}
            </div>
          )
        })
      }
      // using children
      else {
        return slides.map((slide: any, idx: number) => {
          let counter = { desktop: 0, tablet: 0, mobile: 0 }
          return (
            <div
              key={idx}
              className='slide'
              onMouseUp={this.handleMouseUp}
              onMouseLeave={this.handleMouseUp}
              onMouseDown={this.handleMouseDown}
            >
              {this.renderChildrenSlideItems(slide, counter, background1TemplateMerged)}
            </div>
          )
        })
      }
    }
  }

  renderNoChilds = () => {
    /**
     * render no content placeholder
     */
    const bobBackground1 = isBobBackground(this.props.obj.styles.bobs.background1)
    if (this.props.expManager.enable) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      const childs = bobBackground1.grid.columns && bobBackground1.grid.columns.length
      let components: any = []
      for (let i = 0; i < childs; i++) {
        const columns = handleColumns(
          i,
          counter,
          this.props.obj.styles.bobs.background1,
          this.props.pageResponse.globalStyles[this.props.obj.styles.bobs.background1.globalStyleId]
        )
        counter = columns.counter
        components = [
          ...components,
          <div
            key={i}
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                verticalAlign: "middle",
                height: `${bobBackground1.height.enable ? bobBackground1.height.unit : "px"}`,
                border: "3px dashed #dbe0e7",
              }}
            >
              <div className='alert warning m-0' role='alert'>
                SliderContainer empty, please add childs
              </div>
            </div>
          </div>,
        ]
      }
      return (
        <div className={`slider-body ${sliderComponentV3Styles["slider-body"]}`}>
          <div className={`slider-nav ${sliderComponentV3Styles["slider-nav"]}`}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              field='icon1'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              iconClassName='fas fa-chevron-left'
            />
          </div>
          <div className={`slider-container ${sliderComponentV3Styles["slider-container"]}`}>
            <WarningDefaultSystem>
              <div className={`row`}>{components}</div>
            </WarningDefaultSystem>
          </div>
          <div className={`slider-nav ${sliderComponentV3Styles["slider-nav"]}`} style={{ color: "#888", opacity: 1 }}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              field='icon2'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              iconClassName='fas fa-chevron-right'
            />
          </div>
        </div>
      )
    }
  }

  renderBottomNavigation = () => {
    if (this.totalSlides > 1) {
      return (
        <Dots
          totalSlides={this.totalSlides}
          currentSlide={this.state.activeSlide}
          centerDots={this.totalSlides < 6 ? this.totalSlides : 3}
          goToSlide={this.goToSlide}
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.obj.selectedInstanceId}
          object={this.props.obj}
          objectId={this.props.obj.uuid}
          field='icon3'
          template={this.props.obj.styles}
          rules={this.props.componentRule}
          expManager={this.props.expManager}
          iconClassName='fas fa-chevron-left'
        />
      )
    }
  }

  render = () => {
    const background1 = this.props.obj.styles.bobs.background1
    const childComponentsId = this.getChilds()
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

    let contentType: "contentBlock" | "formula" | "children" = "children"
    let posts
    let slides
    let content
    let childIds
    if (childComponentsId && childComponentsId.length > 0) {
      // using content block
      if (this.isContentBlockPosts()) {
        contentType = "contentBlock"
        posts = childComponentsId
        slides = this.groupItemsBySlides(posts, this.getColumnByBreakpoint())
        this.totalSlides = slides.length
      }
      // not using content block
      else {
        content = getContent(this.props.obj, "posts", this.props.overrides, undefined, false)
        childIds = childComponentsId as Array<string>

        // using formula
        if (content && content.length > 0) {
          contentType = "formula"
          slides = this.groupItemsBySlides(content, this.getColumnByBreakpoint())
          this.totalSlides = slides.length
          // using children
        } else {
          contentType = "children"
          slides = this.groupItemsBySlides(childIds, this.getColumnByBreakpoint())
          this.totalSlides = slides.length
        }
      }
    }

    const prevNavEnabled = this.arrowCanSlide("prev")
    const nextNavEnabled = this.arrowCanSlide("next")

    return (
      <div className={`containers slider-component-v3 ${sliderComponentV3Styles["slider-component-v3"]}`}>
        {childComponentsId && childComponentsId.length > 0 ? (
          <Fragment>
            <div className={`slider-body ${sliderComponentV3Styles["slider-body"]}`}>
              {this.props.obj.styles.bobs.icon1.enable && (
                <div
                  className={`slider-nav ${sliderComponentV3Styles["slider-nav"]} left`}
                  onClick={() => prevNavEnabled && this.slide("prev")}
                  style={prevNavEnabled ? { opacity: 1 } : { opacity: 0 }}
                >
                  <BobComponentHandler
                    position={this.props.position}
                    pageResponse={this.props.pageResponse}
                    instanceId={this.props.obj.selectedInstanceId}
                    object={this.props.obj}
                    objectId={this.props.obj.uuid}
                    field='icon1'
                    template={this.props.obj.styles}
                    rules={this.props.componentRule}
                    expManager={this.props.expManager}
                    iconClassName='fas fa-chevron-left'
                  />
                </div>
              )}
              <AlignmentHOC
                ref={this.sliderContainer}
                className={`slider-container ${sliderComponentV3Styles["slider-container"]} ${responsiveGuttersClass(
                  background1,
                  this.props.pageResponse.globalStyles[background1.globalStyleId]
                )}`}
                desktop={alignmentStyles}
                tablet={tabletAlignment}
                mobile={mobileAlignment}
              >
                <SwipeableViews
                  enableMouseEvents
                  ignoreNativeScroll
                  index={this.state.activeSlide}
                  onChangeIndex={(index) => this.onSwipeChangeIndex(index)}
                >
                  {this.renderSlides(contentType, background1, slides, childIds)}
                </SwipeableViews>
              </AlignmentHOC>
              {this.props.obj.styles.bobs.icon2.enable && (
                <div
                  className={`slider-nav ${sliderComponentV3Styles["slider-nav"]} right`}
                  onClick={() => nextNavEnabled && this.slide("next")}
                  style={nextNavEnabled ? { opacity: 1 } : { opacity: 0 }}
                >
                  <BobComponentHandler
                    position={this.props.position}
                    pageResponse={this.props.pageResponse}
                    instanceId={this.props.obj.selectedInstanceId}
                    object={this.props.obj}
                    objectId={this.props.obj.uuid}
                    field='icon2'
                    template={this.props.obj.styles}
                    rules={this.props.componentRule}
                    expManager={this.props.expManager}
                    iconClassName='fas fa-chevron-right'
                  />
                </div>
              )}
            </div>
            {this.renderBottomNavigation()}
          </Fragment>
        ) : (
          this.renderNoChilds()
        )}
      </div>
    )
  }
}

export default SliderContainerV3