import {
  REQUEST_SYMBOLS_LIST,
  RECEIVE_SYMBOLS_LIST,
  ERROR_SYMBOLS_LIST,
  ADD_SYMBOL_OBJECT,
  CLEAR_ADD_SYMBOL_OBJECT,
  SYMBOL_CONTENT_TYPE,
  SELECTED_SYMBOL_INSTANCE,
  HANDLE_SYMBOL_TREE,
  CLEAR_HANDLE_SYMBOL_TREE,
  REQUEST_DELETE_SYMBOL,
  RECEIVE_DELETE_SYMBOL,
  ERROR_DELETE_SYMBOL,
  CLEAR_DELETE_SYMBOL,
  REQUEST_CHANGE_SYMBOL_LABEL,
  RECEIVE_CHANGE_SYMBOL_LABEL,
  ERROR_CHANGE_SYMBOL_LABEL,
  CLEAR_CHANGE_SYMBOL_LABEL,
  REQUEST_SYMBOL_SCREENSHOT,
  RECEIVE_SYMBOL_SCREENSHOT,
  ERROR_SYMBOL_SCREENSHOT,
  CLEAR_SYMBOL_SCREENSHOT,
} from "./actionTypes"
import {
  getSymbolsList,
  deleteSymbol,
  changeSymbolLabel as changeSymbolLabelApi,
  symbolScreenshot as symbolScreenshotApi,
} from "../../../api/shared-api/symbolsApi"
import { AppDispatch } from "../types"

export function requestSymbolsList() {
  return {
    type: REQUEST_SYMBOLS_LIST,
  }
}

export function receiveSymbolsList(response: any) {
  return {
    type: RECEIVE_SYMBOLS_LIST,
    response,
  }
}

export function errorSymbolsList(error: any) {
  return {
    type: ERROR_SYMBOLS_LIST,
    error,
  }
}
export function fetchSymbolsList(
  profile: any,
  token: any,
  orderby: any,
  limit: any,
  order: any,
  search: any,
  page: any,
  gsType: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSymbolsList())
    return getSymbolsList(profile, token, orderby, limit, order, search, page)
      .then((response: any) => {
        dispatch(receiveSymbolsList(response))
      })
      .catch((error: any) => {
        dispatch(errorSymbolsList(error))
        throw error
      })
  }
}

/************************************************* */

export function addSymbolObject(object: any, addBehaviourType: any) {
  return {
    type: ADD_SYMBOL_OBJECT,
    object,
    addBehaviourType,
  }
}
export function clearAddSymbolObject() {
  return {
    type: CLEAR_ADD_SYMBOL_OBJECT,
  }
}

export function addSymbol(object: any, addBehaviourType: any) {
  return (dispatch: AppDispatch) => {
    return dispatch(addSymbolObject(object, addBehaviourType))
  }
}

export function clearAddSymbol() {
  return (dispatch: AppDispatch) => {
    dispatch(clearAddSymbolObject())
  }
}

/************************************************* */

export function symbolContentType(contentType: any) {
  return {
    type: SYMBOL_CONTENT_TYPE,
    contentType,
  }
}

export function setSymbolContentType(contentType: any) {
  return (dispatch: AppDispatch) => {
    return dispatch(symbolContentType(contentType))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function selectSymbolInstanceToEdit(id: string, object: string) {
  return {
    type: SELECTED_SYMBOL_INSTANCE,
    id,
    object,
  }
}

export function selectSymbolInstance(id?: any, object?: any) {
  return (dispatch: AppDispatch) => {
    dispatch(selectSymbolInstanceToEdit(id, object))
  }
}

/************************************************* */

export function handleNewSymbolTree(symbol: any, symbolInstance: any) {
  return {
    type: HANDLE_SYMBOL_TREE,
    symbol,
    symbolInstance,
  }
}
export function clearHandleNewSymbolTree() {
  return {
    type: CLEAR_HANDLE_SYMBOL_TREE,
  }
}

export function handleSymbolTree(symbol: any, symbolInstance: any) {
  return (dispatch: AppDispatch) => {
    return dispatch(handleNewSymbolTree(symbol, symbolInstance))
  }
}

export function clearHandleSymbolTree() {
  return (dispatch: AppDispatch) => {
    dispatch(clearHandleNewSymbolTree())
  }
}

/************************************************* */

export function requestDeleteSymbol(symbol: any) {
  return {
    type: REQUEST_DELETE_SYMBOL,
    symbol,
  }
}

export function successDeleteSymbol(response: any) {
  return {
    type: RECEIVE_DELETE_SYMBOL,
    response,
  }
}

export function errorDeleteSymbol(error: any) {
  return {
    type: ERROR_DELETE_SYMBOL,
    error,
  }
}
export function removeSymbol(symbol: any, profile: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteSymbol(symbol))
    return deleteSymbol(symbol, profile, token)
      .then((response: any) => {
        dispatch(successDeleteSymbol(response))
      })
      .catch((error: any) => {
        dispatch(errorDeleteSymbol(error))
        throw error
      })
  }
}

export function clearDeleteSymbol() {
  return {
    type: CLEAR_DELETE_SYMBOL,
  }
}

export function cleanDeleteSymbol() {
  return (dispatch: AppDispatch) => {
    return dispatch(clearDeleteSymbol())
  }
}

/************************************************* */

export function requestChangeSymbolLabel(objectId: string) {
  return {
    type: REQUEST_CHANGE_SYMBOL_LABEL,
    objectId,
  }
}

export function successChangeSymbolLabel(response: any) {
  return {
    type: RECEIVE_CHANGE_SYMBOL_LABEL,
    response,
  }
}

export function errorChangeSymbolLabel(error: any) {
  return {
    type: ERROR_CHANGE_SYMBOL_LABEL,
    error,
  }
}
export function changeSymbolLabel(symbolLabel: any, symbolId: string, objectId: string, profile: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestChangeSymbolLabel(objectId))
    return changeSymbolLabelApi(symbolLabel, symbolId, profile, token)
      .then((response: any) => {
        dispatch(successChangeSymbolLabel({ response, symbolLabel, symbolId }))
      })
      .catch((error: any) => {
        dispatch(errorChangeSymbolLabel(error))
        throw error
      })
  }
}

export function clearChangeSymbolLabel() {
  return {
    type: CLEAR_CHANGE_SYMBOL_LABEL,
  }
}

export function cleanChangeSymbolLabel() {
  return (dispatch: AppDispatch) => {
    return dispatch(clearChangeSymbolLabel())
  }
}

/************************************************* */

export function requestSymbolScreenshot() {
  return {
    type: REQUEST_SYMBOL_SCREENSHOT,
  }
}

export function successSymbolScreenshot(response: any) {
  return {
    type: RECEIVE_SYMBOL_SCREENSHOT,
    response,
  }
}

export function errorSymbolScreenshot(error: any) {
  return {
    type: ERROR_SYMBOL_SCREENSHOT,
    error,
  }
}
export function symbolScreenshot(symbolId: string, profile: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSymbolScreenshot())
    return symbolScreenshotApi(symbolId, profile, token)
      .then((response: any) => {
        dispatch(successSymbolScreenshot(response))
      })
      .catch((error: any) => {
        dispatch(errorSymbolScreenshot(error))
        throw error
      })
  }
}

export function clearSymbolScreenshot() {
  return {
    type: CLEAR_SYMBOL_SCREENSHOT,
  }
}

export function cleanSymbolScreenshot() {
  return (dispatch: AppDispatch) => {
    return dispatch(clearSymbolScreenshot())
  }
}

/************************************************* */
