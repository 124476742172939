import React from 'react'
import {
  handleBondaries_DEPRECATED,
  handleObjectType,
  handleObjectUri,
} from "../../../../../modules/shared-modules/utilities/components"
import { Tabs } from "../BobHorizontalTabsDropdown/BobHorizontalTabsDropdown"
import ComponentHandler from "../../../componentHandler"
import { route } from "../../../../../modules/routeCreator"
import WarningDefaultSystem from "../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import BobComponentHandler from "../../../shared-public-page/bob/bobComponentHandler"
import { handleColumns } from "../../../shared-public-page/bob/bobHandler"
import { handleFilterSymbolObject } from "../../../../../modules/shared-modules/utilities/symbols"
import { withRouter } from "next/router"
import { TabsContainerDropdownProps } from "./interface"
import { isBobBackground } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import tabsContainerStyle from "../../../../../stylesheets/modules/templates/tabsContainer.module.sass"
import tabsContainerDropdownStyle from "../../../../../stylesheets/modules/templates/tabsContainerDropdown.module.sass"

const TabsContainerDropdown: React.FC<TabsContainerDropdownProps> = (props) => {
  const renderTabsContent = (childsId: any) => {
    if (childsId.length > 0) {
      return childsId.map((childId: any, idx: any) => {
        let handledItem = handleObjectType(props.obj, childId, props.pageResponse, props.expManager.emEditorType)
        let tabName = childId.objectLabel
        let tabIcon = ""
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={props.obj.selectedInstanceId}
            pageResponse={props.pageResponse}
            matchOrder={props.matchOrder}
            expManager={props.expManager}
            key={idx}
            component={handledItem}
            tabName={tabName}
            tabLabel={handleObjectUri({ ...handledItem, selectedInstanceId: props.obj.selectedInstanceId })} // TODO: review, where to initialize the child selectedInstanceId value
            tabIcon={tabIcon}
            tabEventKey={1}
          />
        )
      })
    } else {
      return "No content"
    }
  }

  const onMouseEnter = (e: MouseEvent) => {
    if (!e || !e.target) return
    let container = e.target as HTMLElement
    let childText = container.querySelector(".bob-text")
    if (!childText) return
    childText.classList.add("isHover")
  }

  const onMouseLeave = (e: MouseEvent) => {
    if (!e || !e.target) return
    let container = e.target as HTMLElement
    let childText = container.querySelector(".bob-text")
    if (!childText) return
    childText.classList.remove("isHover")
  }

  const renderNoChilds = () => {
    /**
     * render no content placeholder
     */
    if (props.expManager.enable) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      let childs =
        isBobBackground(props.obj.styles.bobs.background1).grid.columns &&
        isBobBackground(props.obj.styles.bobs.background1).grid.columns.length
      let components: any[] = []
      for (let i = 0; i < childs; i++) {
        let columns = handleColumns(i, counter, props.obj.styles.bobs.background1)
        counter = columns.counter
        components = [
          ...components,
          <div
            key={i}
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                verticalAlign: "middle",
                height: `${isBobBackground(props.obj.styles.bobs.background1).height.enable ? "100" : "100"}${
                  isBobBackground(props.obj.styles.bobs.background1).height.enable ? "%" : "px"
                }`,
                border: "3px dashed #dbe0e7",
              }}
            >
              <div className='alert warning m-0' role='alert'>
                Add <strong>Tabs Content</strong> components as a child to create tabs
              </div>
            </div>
          </div>,
        ]
      }
      return (
        <WarningDefaultSystem>
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field={"background1"}
            rules={props.componentRule}
            template={props.obj.styles}
            expManager={props.expManager}
          >
            <div className='row no-gutters'>
              <div className='col-4'>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    verticalAlign: "middle",
                    height: `${isBobBackground(props.obj.styles.bobs.background1).height.enable ? "100" : "50"}${
                      isBobBackground(props.obj.styles.bobs.background1).height.enable ? "%" : "px"
                    }`,
                    border: "3px dashed #dbe0e7",
                  }}
                ></div>
              </div>
              <div className='col-4'>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    verticalAlign: "middle",
                    height: `${isBobBackground(props.obj.styles.bobs.background1).height.enable ? "100" : "50"}${
                      isBobBackground(props.obj.styles.bobs.background1).height.enable ? "%" : "px"
                    }`,
                    border: "3px dashed #dbe0e7",
                  }}
                ></div>
              </div>
              <div className='col-4'>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    verticalAlign: "middle",
                    height: `${isBobBackground(props.obj.styles.bobs.background1).height.enable ? "100" : "50"}${
                      isBobBackground(props.obj.styles.bobs.background1).height.enable ? "%" : "px"
                    }`,
                    border: "3px dashed #dbe0e7",
                  }}
                ></div>
              </div>
              {components}
            </div>
          </BobComponentHandler>
        </WarningDefaultSystem>
      )
    }
  }

  const childsId: any = props.obj.children

  const tabsContent: any[] = childsId.filter((objectId: any) => {
    let object = handleObjectType(props.obj, objectId, props.pageResponse, props.expManager.emEditorType)
    object = object.type === "instance" ? handleFilterSymbolObject(object, props.pageResponse.symbols, false) : object
    return (
      props.pageResponse.components[object.styles.component].name === "tabs_content" ||
      props.pageResponse.components[object.styles.component].name === "tabs_content_vertical" ||
      props.pageResponse.components[object.styles.component].name === "tabs_content_vertical_v2"
    )
  })
  const tabsContentVerticalV2 = childsId.filter((objectId: any) => {
    let object = handleObjectType(props.obj, objectId, props.pageResponse, props.expManager.emEditorType)
    object = object.type === "instance" ? handleFilterSymbolObject(object, props.pageResponse.symbols, false) : object
    return props.pageResponse.components[object.styles.component].name === "tabs_content_vertical_v2"
  })
  let tabsContentVertical
  if (!tabsContentVerticalV2 || (tabsContentVerticalV2 && tabsContentVerticalV2.length <= 0))
    tabsContentVertical = childsId.filter((objectId: any) => {
      let object = handleObjectType(props.obj, objectId, props.pageResponse, props.expManager.emEditorType)
      object = object.type === "instance" ? handleFilterSymbolObject(object, props.pageResponse.symbols, false) : object
      return props.pageResponse.components[object.styles.component].name === "tabs_content_vertical"
    })
  const boundarieStyles = handleBondaries_DEPRECATED(props.obj.styles)
  const styles = {
    ...boundarieStyles,
  }
  return (
    <section
      className={`containers tabs tabs-container ${tabsContainerStyle["tabs-container"]} tabs-container_v2 ${tabsContainerDropdownStyle["tabs-container_v2"]}`}
      style={styles}
    >
      {tabsContent && tabsContent.length > 0 ? (
        <Tabs
          className=''
          //@ts-ignore SSR TODO: fix selected type, is only bad on ssr side
          selected={route(props.matchOrder, props.router)}
          expManager={props.expManager}
          router={props.router}
          position={props.position}
          matchOrder={props.matchOrder}
          componentRule={props.componentRule}
          componentTemplate={props.obj.styles}
          objectUuid={props.obj.uuid}
          onMouseEnter={(e: MouseEvent) => onMouseEnter(e)}
          onMouseLeave={(e: MouseEvent) => onMouseLeave(e)}
          isVertical={tabsContentVertical && tabsContentVertical.length > 0 ? true : false}
          isVerticalNotMobile={tabsContentVerticalV2 && tabsContentVerticalV2.length > 0 ? true : false}
          globalStyles={props.pageResponse.globalStyles}
          obj={props.obj}
          symbols={props.symbols}
          pageResponse={props.pageResponse}
          replacedMediaList={props.replacedMediaList}
        >
          {renderTabsContent(childsId)}
        </Tabs>
      ) : (
        props.expManager.enable && renderNoChilds()
      )}
    </section>
  )
}


export default withRouter(TabsContainerDropdown)