import {
  CHANGE_DATA_SET,
  RECEIVE_DATASET,
  ERROR_FETCH_DATASET,
  FETCHING_DATASET,
  RECEIVE_LOADMORE_DATASET,
  ERROR_FETCH_LOADMORE_DATASET,
  FETCHING_LOADMORE_DATASET,
  WARNING_STATE_EM,
  SELECTED_HUB_HANDLER_TO_EDIT,
  SELECTED_FINDER,
  OBJECT_SELECTOR_HOVER,
  RESPONSIVE_TAB,
  VIEW_PORT_SIZE_STATE_EXPERIENCE_MANAGER,
  CLEAR_SELECTED_FINDER,
  SELECTED_FINDER_TYPE,
} from "./actionTypes"
import PublicPageApi from "../../../api/shared-api/publicPageApi"
import { AppDispatch } from "../types"

export function changeDataSet(dataset: any) {
  return {
    type: CHANGE_DATA_SET,
    dataset,
  }
}

export function writeDataSet(dataset: any) {
  return (dispatch: any) => {
    dispatch(changeDataSet(dataset))
  }
}

/*************************************** */

export function receiveDataSet(datasetId: string, filters: any, response: any) {
  return {
    type: RECEIVE_DATASET,
    response,
    datasetId,
    filters,
  }
}

export function errorFetchDataSet(datasetId: string, error: any) {
  return {
    type: ERROR_FETCH_DATASET,
    error,
    datasetId,
  }
}

export function fetchingDataSet(datasetId: string) {
  return {
    type: FETCHING_DATASET,
    datasetId,
  }
}

/**
 *
 * @param objectId? This id should be send by the first dataSet Component
 * Since we wont implement this logic yet, there is going to exist only
 * one dataset at each moment.
 * TODO make it possible to have multiple datasets in the same page
 * @param datasetId: string
 */
export function getDataSet(datasetId: string, filters: any) {
  return (dispatch: any) => {
    dispatch(fetchingDataSet(datasetId))
    PublicPageApi.getDataSet(datasetId, filters)
      .then((response) => {
        dispatch(receiveDataSet(datasetId, filters, response))
      })
      .catch((error) => {
        dispatch(errorFetchDataSet(datasetId, error))
      })
  }
}

/*************************************** */

export function receiveLoadMoreDataSet(datasetId: string, filters: any, response: any, nextCursor: string) {
  return {
    type: RECEIVE_LOADMORE_DATASET,
    response,
    datasetId,
    filters,
    nextCursor,
  }
}

export function errorFetchLoadMoreDataSet(datasetId: string, error: any) {
  return {
    type: ERROR_FETCH_LOADMORE_DATASET,
    error,
    datasetId,
  }
}

export function fetchingLoadMoreDataSet(datasetId: string, nextCursor: string) {
  return {
    type: FETCHING_LOADMORE_DATASET,
    datasetId,
    nextCursor,
  }
}

/**
 *
 * @param objectId? This id should be send by the first dataSet Component
 * Since we wont implement this logic yet, there is going to exist only
 * one dataset at each moment.
 * TODO make it possible to have multiple datasets in the same page
 * @param datasetId: string
 */
export function loadMoreDataSet(datasetId: string, filters: any, nextCursor: string) {
  return (dispatch: any) => {
    dispatch(fetchingLoadMoreDataSet(datasetId, nextCursor))
    PublicPageApi.getDataSet(datasetId, filters, nextCursor)
      .then((response) => {
        dispatch(receiveLoadMoreDataSet(datasetId, filters, response, nextCursor))
      })
      .catch((error) => {
        dispatch(errorFetchLoadMoreDataSet(datasetId, error))
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function warningStateEM(value: any) {
  return {
    type: WARNING_STATE_EM,
    value,
  }
}

export function warningSystemEM(value: any) {
  return (dispatch: AppDispatch) => {
    dispatch(warningStateEM(value))
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function selectedHubHandlerToEdit(id: string, parentId: string, object: any) {
  return {
    type: SELECTED_HUB_HANDLER_TO_EDIT,
    id,
    parentId,
    object,
  }
}

export function hubHandlerSelected(id: string, parentId: any, object: any) {
  return (dispatch: AppDispatch) => {
    dispatch(selectedHubHandlerToEdit(id, parentId, object))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function selectedFinder(typeSelected: any, selectedObject: any) {
  return {
    type: SELECTED_FINDER,
    typeSelected,
    selectedObject,
  }
}

export function selectFinder(type: any, selectedObject: any) {
  return (dispatch: AppDispatch) => {
    dispatch(selectedFinder(type, selectedObject))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearSelectedFinder() {
  return {
    type: CLEAR_SELECTED_FINDER,
  }
}

export function clearSelectFinder() {
  return (dispatch: AppDispatch) => {
    dispatch(clearSelectedFinder())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function objectSelectorHover(value: any) {
  return {
    type: OBJECT_SELECTOR_HOVER,
    value,
  }
}

export function objectSelectionHover(value: any) {
  return (dispatch: AppDispatch) => {
    dispatch(objectSelectorHover(value))
  }
}

// Viewport State
export function viewPortSizeExperienceManager(viewPortSize: any, viewPortScale: any) {
  return {
    type: VIEW_PORT_SIZE_STATE_EXPERIENCE_MANAGER,
    viewPortSize,
    viewPortScale,
  }
}

export function viewPortSizeStateExperienceManager(viewPortSize: any, viewPortScale: any) {
  return (dispatch: AppDispatch) => {
    dispatch(viewPortSizeExperienceManager(viewPortSize, viewPortScale))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function responsiveTab(tab: any) {
  return {
    type: RESPONSIVE_TAB,
    tab,
  }
}

export function changeResponsiveTab(tab: any) {
  return (dispatch: AppDispatch) => {
    dispatch(responsiveTab(tab))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function selectedFinderType(typeSelected: any) {
  return {
    type: SELECTED_FINDER_TYPE,
    typeSelected,
  }
}

export function selectFinderType(typeSelected: any) {
  return (dispatch: AppDispatch) => {
    dispatch(selectedFinderType(typeSelected))
  }
}