import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  handleInstanceObject,
  handleObjectType,
  handleObjectUri,
  handleContentBlock,
} from "../../../../../modules/shared-modules/utilities/components"
import ComponentHandler from "../../../componentHandler"
import LinkManager from "../../../../../modules/shared-modules/routeCreator/LinkManager"
import { handler, route as routeCreator } from "../../../../../modules/routeCreator"
import WarningDefaultSystem from "../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import { handleColumns, responsiveGuttersClass } from "../../../shared-public-page/bob/bobHandler"
import AlignmentHOC from "../../../shared-public-page/fluxio-components/alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../modules/shared-modules/utilities/conditionalController"
//@ts-ignore
import { withRouter } from "next/router"
import { CardExpandContainerProps } from "."
import {
  BobBackground,
  isBobBackground,
} from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const CardExpandContainer: React.FC<CardExpandContainerProps> = (props) => {
  let linkRef: any
  const linkRefToChild = useRef(linkRef)

  const [expSelectedItem, setExpSelectedItem] = useState<any>(undefined)
  const [selectedItem, setSelectedItem] = useState<any>(undefined)
  const [isNotMobileBreakpoint, setIsNotMobileBreakpoint] = useState<boolean>(true)

  useEffect(() => {
    setIsNotMobileBreakpoint(window.matchMedia("(min-width: 768px)").matches)
    setExpSelectedItem(localStorage.getItem(`cascaisPreviewCard-${props.obj.uuid}`))
  }, [])

  useEffect(() => {
    handleSelectedUri(props)
  }, [props.router.asPath])

  const handleSelectedUri = (props: CardExpandContainerProps) => {
    let route = routeCreator(props.matchOrder, props.router)
    if (route && selectedItem !== route) {
      setSelectedItem(route)
    } else return
  }

  const getSelectedUri = () => {
    if (props.expManager.enable) return expSelectedItem
    else return selectedItem
  }

  const handleSelectedItem = (item: any) => {
    /**
     * We save in the local storage the selected card
     * so when the user triggers a new event inside the EM
     * the selected card stays open
     */
    localStorage.setItem(`cascaisPreviewCard-${props.obj.uuid}`, handleObjectUri(item))
    setExpSelectedItem(handleObjectUri(item))
  }

  const renderSelectedContent = (menuItemsGroup: any) => {
    if (selectedItem || expSelectedItem) {
      for (let menuContent of menuItemsGroup) {
        if (menuContent.children && menuContent.children.length > 0) {
          if (handleObjectUri(menuContent) === getSelectedUri()) {
            const menuContentChilds = menuContent.children
            return menuContentChilds.map((menuChildId: string, idx: number) => {
              const handledItem = handleObjectType(
                menuContent,
                menuChildId,
                props.pageResponse,
                props.expManager.emEditorType
              )
              return (
                <ComponentHandler
                  position={idx}
                  selectedInstanceId={handledItem.selectedInstanceId}
                  key={idx}
                  component={handledItem}
                  pageResponse={props.pageResponse}
                  matchOrder={props.matchOrder + 1}
                  expManager={props.expManager}
                />
              )
            })
          } else {
            continue
          }
        } else {
          continue
        }
      }
    }
  }

  const renderMenuItems = (background1Template: BobBackground) => {
    const menuItems = props.obj.children
    if (menuItems && menuItems.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      let menuItemsGroup: any = []
      return menuItems.map((menuItemId: string, idx: number) => {
        const menuItem = handleObjectType(props.obj, menuItemId, props.pageResponse, props.expManager.emEditorType)
        const handledMenuItem = handleInstanceObject(menuItem, props.pageResponse)
        linkRef = handleObjectUri(handledMenuItem)
        const columns = handleColumns(
          idx,
          counter,
          background1Template,
          props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        const itemIsActive = handleObjectUri(handledMenuItem) === getSelectedUri() ? "active" : ""
        let container = undefined
        if (isNotMobileBreakpoint) {
          // group menuItems contents in groups of {columns}
          // and render a container for each group
          if ((idx + 1) % (12 / parseInt(columns.columns)) === 0) {
            menuItemsGroup = [...menuItemsGroup, handledMenuItem]
            container = <div className='expand-content col-12'>{renderSelectedContent(menuItemsGroup)}</div>
            menuItemsGroup = []
          } else if (idx === menuItems.length - 1) {
            menuItemsGroup = [...menuItemsGroup, handledMenuItem]
            container = <div className='expand-content col-12'>{renderSelectedContent(menuItemsGroup)}</div>
            menuItemsGroup = []
          } else {
            menuItemsGroup = [...menuItemsGroup, handledMenuItem]
            container = undefined
          }
        } else {
          menuItemsGroup = [handledMenuItem]
          container = <div className='expand-content col-12'>{renderSelectedContent(menuItemsGroup)}</div>
        }

        const menuCard = (
          <ComponentHandler
            position={idx}
            selectedInstanceId={props.obj.selectedInstanceId}
            key={idx}
            component={handleContentBlock(
              handledMenuItem,
              props.obj.content.formula?.posts[0],
              props.obj,
              props.pageResponse,
              props.overrides
            )}
            pageResponse={props.pageResponse}
            matchOrder={props.matchOrder}
            expManager={props.expManager}
          />
        )

        return (
          <Fragment key={idx}>
            <div className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}>
              {props.expManager.enable ? (
                <div onClick={() => handleSelectedItem(handledMenuItem)} className={`${itemIsActive}`}>
                  {menuCard}
                </div>
              ) : (
                <LinkManager
                  expManager={props.expManager}
                  to={handler(props.router, props.matchOrder, handleObjectUri(handledMenuItem))}
                  ref={linkRefToChild}
                  innerKey={idx}
                  onClick={() => handleSelectedItem(handledMenuItem)}
                  className={`${itemIsActive}`}
                >
                  {menuCard}
                </LinkManager>
              )}
            </div>
            {container ? container : []}
          </Fragment>
        )
      })
    } else if (props.expManager.enable) {
      return renderNoChilds()
    }
    return []
  }

  const renderNoChilds = () => {
    /**
     * render no content placeholder
     */
    if (props.expManager.enable) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      const Background1 = isBobBackground(props.obj.styles.bobs.background1)
      const childs = Background1.grid.columns && Background1.grid.columns.length
      let components: any = []
      for (let i = 0; i < childs; i++) {
        const columns = handleColumns(
          i,
          counter,
          Background1,
          props.pageResponse.globalStyles[Background1.globalStyleId]
        )
        counter = columns.counter
        components = [
          ...components,
          <div
            key={i}
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                verticalAlign: "middle",
                height: `${Background1.height.enable ? Background1.height.value : "100"}${
                  Background1.height.enable ? Background1.height.unit : "px"
                }`,
                border: "3px dashed #dbe0e7",
              }}
            >
              <div className='alert warning m-0' role='alert'>
                Add <strong>Card Expand</strong> component as childs to show menu items.
              </div>
            </div>
          </div>,
        ]
      }
      return <WarningDefaultSystem>{components}</WarningDefaultSystem>
    }
  }

  /**
   * Responsive Alignment Styles
   */
  const background1 = isBobBackground(props.obj.styles.bobs.background1)
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

  return (
    <section className='expands card-expand-container'>
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderMenuItems(background1)}
      </AlignmentHOC>
    </section>
  )
}


export default withRouter(CardExpandContainer)