import React from "react"
import BobText from "./bobText/bobText"
import ErrorBoundary from "../../../../components/shared-components/error/ErrorBoundary"
import { v4 as uuidv4 } from "uuid"
import { rewriteObjectOnRead } from "./inlineContentHandler"
import { ExpManager, PageTypes } from "../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { SingleObject } from "../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import {
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../modules/shared-modules/experienceManager/types/objectContentTypes"

import BobBackgroundImage from "../../bob/bobBackgroundImage/bobBackgroundImage"
import BobMedia from "./bobMedia/bobMedia"
import BobIcon from "./bobIcon/bobIcon"
import { MediaObject } from "../../../../modules/shared-modules/experienceManager/types/contentTypes"

interface OwnProps {
  pageResponse: PageTypes
  replacedMediaList?: { [id: string]: MediaObject }
  instanceId?: string
  className?: string
  value?: any
  network?: string
  post?: Post
  expManager: ExpManager
  inlineContent?: ObjectContent["inlineContent"]
  overrides?: ObjectContentOverride
  position: number
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  rules: any
  template: any
  templatePropsToIgnore?: Array<string>
  styles?: any
  object: SingleObject
  flexDirection?: string
  onMouseEnter?: any
  onMouseLeave?: any
  field: string
  objectId: string
  iconClassName?: string
  tag?: string
  children?: React.ReactNode
  ref: any
  setRef?: (x: React.RefObject<HTMLDivElement>) => void
}

type BobComponentHandlerProps = OwnProps

const BobComponentHandler = (props: BobComponentHandlerProps) => {
  const handleBobType = (rule: any, template: any, field: string, objectId = uuidv4()) => {
    switch (rule.type) {
      case "text":
        return (
          <ErrorBoundary expManager={props.expManager} key={field}>
            <BobText
              id={objectId}
              globalStyles={props.pageResponse.globalStyles}
              pageResponse={props.pageResponse}
              instanceId={props.instanceId}
              className={props.className}
              inlineContent={rewriteObjectOnRead(template, props.inlineContent, field)}
              overrides={props.overrides}
              value={props.value}
              template={template}
              rule={rule}
              field={field}
              network={props.network}
              post={props.post}
              expManager={props.expManager}
            />
          </ErrorBoundary>
        )
      case "bobBackground":
        return (
          <ErrorBoundary expManager={props.expManager} key={field}>
            <BobBackgroundImage
              id={objectId}
              expManager={props.expManager}
              globalStyles={props.pageResponse.globalStyles}
              pageResponse={props.pageResponse}
              objPosition={props.position}
              instanceId={props.instanceId}
              post={props.post}
              className={props.className}
              inlineContent={rewriteObjectOnRead(template, props.inlineContent, field)}
              overrides={props.overrides}
              template={template}
              rule={rule}
              field={field}
              onClick={props.onClick}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              flexDirection={props.flexDirection}
            >
              {props.children}
            </BobBackgroundImage>
          </ErrorBoundary>
        )
      case "bobMedia":
        return (
          <ErrorBoundary expManager={props.expManager} key={field}>
            <BobMedia
              id={objectId}
              globalStyles={props.pageResponse.globalStyles}
              pageResponse={props.pageResponse}
              replacedMediaList={props.replacedMediaList}
              instanceId={props.instanceId}
              className={props.className}
              inlineContent={rewriteObjectOnRead(template, props.inlineContent, field)}
              overrides={props.overrides}
              template={template}
              rule={rule}
              object={props.object}
              field={field}
              post={props.post}
              onClick={props.onClick}
              expManager={props.expManager}
            />
          </ErrorBoundary>
        )
      case "bobIcon":
        //TODO missing inlineCOntent handler and overrides???
        return (
          <ErrorBoundary expManager={props.expManager} key={field}>
            <BobIcon
              id={objectId}
              globalStyles={props.pageResponse.globalStyles}
              pageResponse={props.pageResponse}
              instanceId={props.instanceId}
              className={props.className}
              template={template}
              rule={rule}
              field={field}
              onClick={props.onClick}
              iconClassName={props.iconClassName}
              templatePropsToIgnore={props.templatePropsToIgnore}
            />
          </ErrorBoundary>
        )
      default:
        return (
          <div className='alert alert-warning' role='alert'>
            No bob to render! {rule.type}
          </div>
        )
    }
  }

  const bobRules = props.rules.bob
  const templateValues = props.template.bobs

  //Workaround when bobRules is empty
  if (!bobRules) {
    if (props.expManager.enable) {
      return (
        <div className='alert alert-warning' role='alert'>
          {`No Bob Rules for ${props.styles.component.name}`}
        </div>
      )
    }
    return []
  }
  /**
   * If the field if present we render only this particular field.
   * When this value is undefined we render all the fields inside it
   */
  if (props.field) {
    const fieldRule = bobRules[props.field]
    const fieldTemplateValue = templateValues[props.field]
    if (!fieldTemplateValue || !fieldTemplateValue.enable || !fieldRule) {
      // console.warn(`No rules found for bob: ${props.field}`) // TODO: review rules for each prop in a bob
      return props.children ? props.children : []
    }
    return handleBobType(fieldRule, fieldTemplateValue, props.field, props.objectId)
  }
  let arrFields: any = []
  for (let fieldRule in bobRules) {
    if (bobRules[fieldRule].enable)
      arrFields = [
        ...arrFields,
        handleBobType(bobRules[fieldRule], templateValues[fieldRule], fieldRule, props.objectId),
      ]
  }
  return arrFields
}

export default React.memo(BobComponentHandler)
