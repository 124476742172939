import React from 'react'
import BobComponentHandler from '../../../../bob/bobComponentHandler'
import WarningSystem from '../../../../../../../modules/shared-modules/experienceManager/warningSystem'
import {
  getContent,
  getStringField,
  handleContentBlock,
  handleObjectType,
} from "../../../../../../../modules/shared-modules/utilities/components"
import { clickEvent } from "../../../../analytics/analytics"
import ComponentHandler from "../../../../../componentHandler"
import { handleColumns, responsiveGuttersClass } from "../../../../bob/bobHandler"
import AlignmentHOC from "../../../alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../../../modules/shared-modules/utilities/conditionalController"

class ButtonRight extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      isHover: false,
    }
    this.buttonOnHover = this.buttonOnHover.bind(this)
    this.buttonUnHover = this.buttonUnHover.bind(this)
  }

  child: any[] = []

  buttonOnHover() {
    this.setState({
      ...this.state,
      isHover: true,
    })
  }

  buttonUnHover() {
    this.setState({
      ...this.state,
      isHover: false,
    })
  }

  /**
   *
   * @param {postIdx} idx
   * We need this idx from the post array
   * to properly call the inner function down the react tree.
   * if we dont use this we overwrite the same ref over and over
   * leaving only the reference to the last rendered object.
   */
  handleButtonClick(buttonLabel: any, idx: any) {
    clickEvent(`button-${buttonLabel}`, "CTA")
    if (this.child && this.child[idx]) this.child[idx].handleInnerFunction()
  }

  renderComponents(post: any, postIdx: any) {
    const componentsId = this.props.obj.children
    if (componentsId && componentsId.length > 0) {
      return componentsId.map((componentId: any, idx: any) => {
        const handledItem = handleObjectType(
          this.props.obj,
          componentId,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={this.props.obj.selectedInstanceId}
            onRef={(ref) => (this.child[postIdx] = ref)}
            key={idx}
            component={handleContentBlock(
              handledItem,
              post,
              this.props.obj,
              this.props.pageResponse,
              this.props.overrides
            )}
            pageResponse={this.props.pageResponse}
            matchOrder={this.props.matchOrder}
            expManager={this.props.expManager}
          />
        )
      })
    }
  }

  renderButton(background1Template: any) {
    const content = getContent(this.props.obj, "posts", this.props.overrides)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: any, idx: any) => {
        const columns = handleColumns(
          idx,
          counter,
          background1Template,
          this.props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        counter = columns.counter
        const buttonLabel = getStringField(post, this.props.obj, this.props.pageResponse, "text1", this.props.overrides)
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.objectUuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field={"background2"}
              rules={this.props.componentRule}
              template={this.props.obj.styles}
              expManager={this.props.expManager}
              className='btn btn-fluxio btn-lg'
              tag='a'
              onClick={() => this.handleButtonClick(buttonLabel, idx)}
              onMouseEnter={this.buttonOnHover}
              onMouseLeave={this.buttonUnHover}
            >
              <BobComponentHandler
                position={this.props.position}
                pageResponse={this.props.pageResponse}
                instanceId={this.props.obj.selectedInstanceId}
                object={this.props.obj}
                objectId={this.props.obj.objectUuid}
                post={post}
                inlineContent={this.props.obj.content.inlineContent}
                overrides={this.props.overrides}
                field='text1'
                tag='p'
                value={buttonLabel}
                template={this.props.obj.styles}
                rules={this.props.componentRule}
                expManager={this.props.expManager}
                network={post.network}
                className={`label ${this.state.isHover ? "isHover" : ""}`}
              />
              <BobComponentHandler
                position={this.props.position}
                pageResponse={this.props.pageResponse}
                replacedMediaList={this.props.replacedMediaList}
                instanceId={this.props.obj.selectedInstanceId}
                object={this.props.obj}
                objectId={this.props.obj.objectUuid}
                inlineContent={this.props.obj.content.inlineContent}
                overrides={this.props.overrides}
                field='media1'
                template={this.props.obj.styles}
                rules={this.props.componentRule}
                expManager={this.props.expManager}
                network={post.network}
                post={post}
                className={`${this.state.isHover ? "isHover" : ""}`}
              />
            </BobComponentHandler>
            {this.renderComponents(post, idx)}
          </div>
        )
      })
    } else {
      if (this.props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Add a single post or a feed' />
          </div>
        )
      }
    }
  }

  render() {
    /**
     * Responsive Alignment Styles
     */
    const background1 = this.props.obj.styles.bobs.background1
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

    return (
      <section className='elements button button-right'>
        <AlignmentHOC
          className={`row ${responsiveGuttersClass(
            background1,
            this.props.pageResponse.globalStyles[background1.globalStyleId]
          )}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {this.renderButton(background1)}
        </AlignmentHOC>
      </section>
    )
  }
}


export default ButtonRight