import React, { useEffect, useState, RefObject } from "react"
// @ts-ignore
import { handleDOMid } from "../../../../../../modules/shared-modules/utilities/symbols"
import styled, { css } from "styled-components"
import {
  ObjectContentOverride,
  Post,
} from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { getOverrideInlineContentValues } from "../../../../../../modules/shared-modules/utilities/components"
import { Media4Bob, responsiveStyles } from "../bobMediaV2Handler"
import { ExpManager, PageTypes } from "../../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import GlobalStylesUtils from "../../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { NGS_CLASS_SUFIX } from "../../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import { MediaObject } from "../../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import { SingleObject } from "../../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { BobNmedia, isNbobNmedia } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { cssRenderUnsafe as VideoStyleCssRenderUnsafe } from "../../bobStyles/videoStyle"
import VideoPlayer from "./videoPlayer"
import VideoPlayerLazy from "./videoPlayerLazy"
import VideoPlayerCustom from "./videoPlayerCustom"
import { emPreviewClass, emScrollId } from "../../bobUtils"
import { handleMediaQueryRestrictions, handleStateStyles } from "../../bobHandlerV2"
import { ResponsiveCSSProps, BobMediaStyles } from "../../bobTypes"
import * as gsType from "../../../../../../modules/shared-modules/globalStyles/globalStylesTypes"

interface MediaVideoProps {
  media: { defaultMedia: Media4Bob; hoverMedia: Media4Bob | undefined }
  object: SingleObject
  pageResponse: PageTypes
  replacedMediaList?: { [id: string]: MediaObject }
  instanceId: string | undefined
  post: Post | undefined
  overrides: ObjectContentOverride | undefined
  field: string
  expManager: ExpManager
  setRef: (x: React.RefObject<HTMLDivElement>) => void | undefined
}

const VideoComponent: any = styled.div`
  ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.desktop}
  ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    props.hover.isEnabled.desktop
      ? css`
          @media all ${handleMediaQueryRestrictions(props.hover.isEnabled.mobile, props.hover.isEnabled.tablet)} {
            &:hover {
              ${props.hover.desktop}
            }
          }
        `
      : {}}

  ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    Object.keys(props.tablet).length > 0 || Object.keys(props.hover.tablet).length > 0
      ? css`
          @media all and (max-width: 992px) and (min-width: 767px) {
            ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.tablet}
            ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
              props.hover.isEnabled.tablet && Object.keys(props.hover.tablet).length > 0
                ? css`
                    &:hover {
                      ${props.hover.tablet}
                    }
                  `
                : {}}
          }
        `
      : {}}

  ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    Object.keys(props.mobile).length > 0 || Object.keys(props.hover.mobile).length > 0
      ? css`
          @media all and (max-width: 766px) {
            ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.mobile}
            ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
              props.hover.isEnabled.mobile && Object.keys(props.hover.mobile).length > 0
                ? css`
                    &:hover {
                      ${props.hover.mobile}
                    }
                  `
                : {}}
          }
        `
      : {}}
`

// TODO: reduce props
const MediaVideo: React.FC<MediaVideoProps> = (props): JSX.Element => {
  const [videoRef] = useState<RefObject<HTMLDivElement>>(React.createRef())

  useEffect(() => {
    // only direct link render sets the ref here,
    // the other refs are create inside each video component.
    // this setRef conflicts with other video components setRef, so it must only run for direct link
    if (props.media.defaultMedia.isDirectLink) props.setRef && props.setRef(videoRef)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // TODO: add deps

  const mediaStyles = isNbobNmedia(props.object.styles.bobs.nmedia1)
  const inlineContent = props.object.content.inlineContent
  const settings = props.object.settings
  const pageLabels = props.pageResponse.stylesheet?.labels

  /**
   * Styles
   */
  const desktopStyles = {
    ...VideoStyleCssRenderUnsafe(mediaStyles, "desktop", "default"),
    ...responsiveStyles(mediaStyles, pageLabels, "desktop", "default"),
  }

  const mobileStyles = {
    ...VideoStyleCssRenderUnsafe(mediaStyles, "mobile", "default"),
    ...responsiveStyles(mediaStyles, pageLabels, "mobile", "default"),
  }
  const tabletStyles = {
    ...VideoStyleCssRenderUnsafe(mediaStyles, "tablet", "default"),
    ...responsiveStyles(mediaStyles, pageLabels, "tablet", "default"),
  }
  const hoverStyles = handleStateStyles<BobNmedia, BobMediaStyles>(
    mediaStyles,
    {
      ...VideoStyleCssRenderUnsafe(mediaStyles, "desktop", "hover"),
      ...responsiveStyles(mediaStyles, pageLabels, "desktop", "hover"),
    },
    {
      ...VideoStyleCssRenderUnsafe(mediaStyles, "tablet", "hover"),
      ...responsiveStyles(mediaStyles, pageLabels, "tablet", "hover"),
    },
    {
      ...VideoStyleCssRenderUnsafe(mediaStyles, "mobile", "hover"),
      ...responsiveStyles(mediaStyles, pageLabels, "mobile", "hover"),
    }
  )

  /**
   * Here we get the thumbnail to be used has a poster.
   * The poster attribute is sended to the ReactPlayer
   * when a valid string is present.
   */
  const Poster = getOverrideInlineContentValues(`${props.field}.thumbnail`, {
    inlineContent,
    overrides: props.overrides,
  })

  const Preload = Poster && Poster !== "" ? "none" : "auto"

  const config = {
    file: {
      attributes: {
        poster: Poster,
        preload: Preload,
      },
    },
  }

  /**
   * The controls must come with a false written, to disable them, else we show it
   * since the inlineContent can come empty
   */
  const controls = getOverrideInlineContentValues(`${props.field}.controls`, {
    inlineContent,
    overrides: props.overrides,
  })

  /**
   * For the playPauseAsset to be used, the controls must be false,
   * since the user loses the ability to play/pause the video with the browser player,
   * this asset will control that.
   */
  const playPauseAsset: string = getOverrideInlineContentValues(`${props.field}.playPauseAsset`, {
    inlineContent,
    overrides: props.overrides,
  })

  const DOMid = props.expManager.enable
    ? { id: `${handleDOMid(props.field, props.object.uuid, props.instanceId)}` }
    : {}
  const EMpreviewClass = emPreviewClass(props.expManager.enable, mediaStyles.uuid)
  const EMScrollId = emScrollId(props.expManager.enable, props.object.uuid)
  const GlobalStylesClass = GlobalStylesUtils.handleClassNameV2(props.pageResponse.nGlobalStyles, mediaStyles, "-box", {
    [gsType.GS_SHADOW]: NGS_CLASS_SUFIX,
    [gsType.GS_BOUNDARY]: NGS_CLASS_SUFIX,
  })
  const customClasses = settings?.[props.field]?.class ? ` ${settings?.[props.field]?.class?.join(" ")}` : ""
  const classes = `${EMpreviewClass}${GlobalStylesClass ? ` ${GlobalStylesClass}` : ""}${customClasses}`

  // media library default video player
  let Video = (
    <VideoComponent
      setRef={(mediaRef: RefObject<HTMLDivElement>) => props.setRef(mediaRef)}
      as={VideoPlayer}
      desktop={desktopStyles}
      mobile={mobileStyles}
      tablet={tabletStyles}
      hover={hoverStyles}
      {...DOMid}
      {...EMScrollId}
      className={classes}
      url={props.media.defaultMedia.url}
      controls={controls === false ? false : true}
      poster={Poster}
      preload={Preload}
    />
  )

  // direct link
  if (props.media.defaultMedia.isDirectLink)
    Video = (
      <VideoComponent
        ref={videoRef}
        desktop={desktopStyles}
        mobile={mobileStyles}
        tablet={tabletStyles}
        hover={hoverStyles}
        {...DOMid}
        {...EMScrollId}
        className={classes}
      >
        <VideoPlayerLazy
          url={props.media.defaultMedia.url}
          controls={controls === false ? false : true}
          config={config}
        />
      </VideoComponent>
    )

  // custom video player
  if (controls === false && playPauseAsset && playPauseAsset !== "")
    Video = (
      <VideoPlayerCustom
        setRef={(mediaRef: RefObject<HTMLDivElement>) => props.setRef(mediaRef)}
        mediaStyles={mediaStyles}
        {...DOMid}
        {...EMScrollId}
        className={classes}
        url={props.media.defaultMedia.url}
        controls={false}
        playPauseAsset={playPauseAsset}
        poster={Poster}
        preload={Preload}
        stylesheet={props.pageResponse.stylesheet}
      />
    )

  return Video
}

export default MediaVideo
