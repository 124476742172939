import React, { useEffect } from 'react';
import axios from 'axios';
import { ContentFetchProps } from './interface';
import { bindActionCreators } from 'redux';
import apiEnv from '../../../api/shared-api/apiEnv'
import {
  contentFetch as contentFetchAction,
  loadingContentFetch,
} from "../../../store/shared-store/actions/contentActions"
import cloneDeep from "lodash/cloneDeep"

import { useDispatch, useSelector } from "react-redux"

const ContentFetch: React.FC<ContentFetchProps> = (props): JSX.Element => {
  const dispatch = useDispatch()

  const contentFetch = useSelector((state: any) => state.contentHubReducer)
  const actions = bindActionCreators({ contentFetchAction, loadingContentFetch }, dispatch)

  const getComposedUuid = (obj: any) => {
    if (obj.selectedInstanceId) {
      return `${obj.uuid}-${obj.selectedInstanceId}`
    }
    return obj.uuid
  }

  const checkForItems = (formulaToFetch: any) => {
    if (formulaToFetch.posts.length > 0 && !contentFetch[`content${getComposedUuid(props.obj)}`]) {
      return true
    }
    return false
  }

  const fetchContent = async (id: any) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
      },
    }
    try {
      const content = await axios.get(`${apiEnv()}v1/formulas/${id}/content2`, config)
      actions.contentFetchAction(getComposedUuid(props.obj), content.data)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(
    () => {
      if (!props.expManager.enable || props.expManager.content) {
        if (
          !contentFetch[`content${getComposedUuid(props.obj)}loading`] &&
          !contentFetch[`content${getComposedUuid(props.obj)}content`]
        ) {
          const formulaToFetch = props.overrides?.formula ?? props.obj.content.formula
          if (formulaToFetch && !checkForItems(formulaToFetch)) {
            actions.loadingContentFetch(getComposedUuid(props.obj))
            fetchContent(formulaToFetch.uuid)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (props.expManager.enable && !props.expManager.content) {
    return props.children(props.obj)
  }
  if (contentFetch[`content${getComposedUuid(props.obj)}`]) {
    let newObj = cloneDeep(props.obj)
    if (newObj.content.formula) {
      newObj.content.formula.posts = [...contentFetch[`content${getComposedUuid(props.obj)}content`]]
      //Write media to formula
      if (contentFetch[`content${getComposedUuid(props.obj)}contentMedia`]) {
        newObj.content.formula = {
          ...newObj.content.formula,
          media: {
            ...contentFetch[`content${getComposedUuid(props.obj)}contentMedia`],
          },
        }
      }
    } else {
      console.warn("Missing formula to add fetched content")
    }
    return props.children(newObj)
  }
  return props.children(props.obj)
}

export default ContentFetch;
