import {
  BobBackground,
  BobCode,
  BobForm,
  BobIcon,
  BobMedia,
  BobText,
  Styles,
  BobCodeV2,
} from "../experienceManager/types/stylesTypes"

type BobCustomCss =
  | BobBackground["customCss"]
  | BobText["customCss"]
  | BobForm["customCss"]
  | BobMedia["customCss"]
  | BobIcon["customCss"]
  | BobCode["customCss"]

/**
 * true if a component has css written in any bob->breakpoint->element
 *
 * @param bobs
 * @returns
 */
export function hasComponentCss(bobs: Styles["bobs"]): boolean {
  if (bobs) {
    //New bobs
    if ("ncode1" in bobs) {
      const ncode1Obj = bobs.ncode1 as BobCodeV2
      if (ncode1Obj.code.default !== "") return true
      if (ncode1Obj.code.desktop && ncode1Obj.code.desktop !== "") return true
      if (ncode1Obj.code.tablet && ncode1Obj.code.tablet !== "") return true
      if (ncode1Obj.code.mobile && ncode1Obj.code.mobile !== "") return true
    }
    //Old bobs
    for (let bobKey in bobs) {
      const bob = bobs[bobKey]
      if ("customCss" in bob) {
        const customCss: BobCustomCss = bob.customCss
        if (customCss) {
          for (let cssKey in customCss) {
            const breakpointCss: any = customCss[cssKey]
            for (let brKey in breakpointCss) {
              const elementCss = breakpointCss[brKey]
              if (elementCss && elementCss !== "") return true
            }
          }
        }
      }
    }
  }
  return false
}

/**
 * True if a bob has Css writen
 *
 * @param customCss
 * @returns
 */
export function hasBobCss(customCss: string | undefined): boolean {
  if (customCss && customCss !== "") return true
  return false
}

/**
 * True if an element has css written
 *
 * @param customCss
 * @param element
 * @returns
 */
export function hasElementCss(customCss: BobCustomCss, element: string): boolean {
  if (customCss) {
    for (let cssKey in customCss) {
      const breakpointCss = customCss[cssKey]
      const elementCss = breakpointCss[element]
      if (elementCss && elementCss !== "") return true
    }
  }
  return false
}

/**
 * True if any breakpoint has css written for any element
 *
 * @param customCss
 * @param br
 * @returns
 */
export function hasBreakpointCss(customCss: BobCustomCss, br: "default" | "desktop" | "tablet" | "mobile"): boolean {
  if (customCss) {
    const breakpointCss = customCss[br]
    for (let brKey in breakpointCss) {
      const elementCss = breakpointCss[brKey]
      if (elementCss && elementCss !== "") return true
    }
  }
  return false
}

/**
 * forceWriteQuery is used to write the css rules even
 * if there are no css props to write inside the rule,
 * used in the controller for example
 * @param bobCode
 * @param breakpoint
 * @returns
 */
// TODO: Styles bob type should be completed
export function writeCustomCssSelectors(
  bobCode: BobCodeV2,
  breakpoint: "default" | "desktop" | "tablet" | "mobile",
  forceWriteQuery: boolean
): string {
  if (breakpoint !== "default") {
    const css: string = bobCode.code[breakpoint] || ""

    const mobileMediaQuery: string = "@media all and (max-width: 768px)"
    const tabletMediaQuery: string = "@media all and (max-width: 992px) and (min-width: 767px)"
    const desktopMediaQuery: string = "@media all and (min-width: 992px)"
    const mediaQuery: string =
      breakpoint === "mobile" ? mobileMediaQuery : breakpoint === "tablet" ? tabletMediaQuery : desktopMediaQuery

    if (css.trim() !== "" || forceWriteQuery) return `${mediaQuery}{\n${css}\n}`

    return ""
  }

  const css: string = bobCode.code.default
  return css
}
