import React, { useEffect, useState, RefObject, Fragment } from "react"
import {
  getFormField,
  getOverrideInlineContentValues,
} from "../../../../../modules/shared-modules/utilities/components"
// @ts-ignore
import Style from "style-it"
import styled, { css } from "styled-components"
import { handleDOMid } from "../../../../../modules/shared-modules/utilities/symbols"
import { getComposedUuid } from "../../../../../modules/shared-modules/utilities/utils"
import { validHtml } from "../../../../../modules/shared-modules/utilities/validation"
import BobLoading from "../bobLoading"
import he from "he"
//import moment from "moment"
import GlobalStylesUtils from "../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { ExpManager, PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import {
  CONTENT_PAGE,
  CMS,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/textController/textContentHelper"
import {
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { clickEvent, emPreviewClass, emScrollId, getLinkAttributes } from "../bobUtils"
import { isNbobText, nBobText } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { handleFonts, responsiveStyles } from "./bobTextV2Handler"
import { handleMediaQueryRestrictions, handleStateStyles } from "../bobHandlerV2"
import { BobTextStyles, ResponsiveCSSProps } from "../bobTypes"
import * as gsType from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import { NGS_CLASS_SUFIX } from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import { writeRebootCssOVerride } from "../../../../../modules/shared-modules/utilities/rebootCss"

interface BobTextProps {
  object: SingleObject
  instanceId: string | undefined
  post: Post | undefined
  field: string
  overrides: ObjectContentOverride | undefined
  value: any
  pageResponse: PageTypes
  expManager: ExpManager
  setRef: (x: React.RefObject<HTMLDivElement>) => void | undefined
  form?: SingleObject["content"]["form"]
}

type HtmlTag = "a" | "div"

const TextComponent = styled.div`
  ${(props: ResponsiveCSSProps<BobTextStyles> & { as?: HtmlTag }) =>
    css`
      ${props.desktop}
      ${writeRebootCssOVerride(props.desktop, props.as)}
    `}
  ${(props: ResponsiveCSSProps<BobTextStyles> & { as?: HtmlTag }) =>
    props.hover.isEnabled.desktop
      ? css`
          @media all ${handleMediaQueryRestrictions(props.hover.isEnabled.mobile, props.hover.isEnabled.tablet)} {
            &:hover {
              ${props.hover.desktop}
              ${writeRebootCssOVerride(props.hover.desktop, props.as)}
            }
          }
        `
      : {}}

  ${(props: ResponsiveCSSProps<BobTextStyles> & { as?: HtmlTag }) =>
    Object.keys(props.tablet).length > 0 || Object.keys(props.hover.tablet).length > 0
      ? css`
          @media all and (max-width: 992px) and (min-width: 767px) {
            ${(props: ResponsiveCSSProps<BobTextStyles> & { as?: HtmlTag }) =>
              css`
                ${props.tablet}
                ${writeRebootCssOVerride(props.tablet, props.as)}
              `}
            ${(props: ResponsiveCSSProps<BobTextStyles> & { as?: HtmlTag }) =>
              props.hover.isEnabled.tablet && Object.keys(props.hover.tablet).length > 0
                ? css`
                    &:hover {
                      ${props.hover.tablet}
                      ${writeRebootCssOVerride(props.hover.tablet, props.as)}
                    }
                  `
                : {}}
          }
        `
      : {}}

  ${(props: ResponsiveCSSProps<BobTextStyles> & { as?: HtmlTag }) =>
    Object.keys(props.mobile).length > 0 || Object.keys(props.hover.mobile).length > 0
      ? css`
          @media all and (max-width: 766px) {
            ${(props: ResponsiveCSSProps<BobTextStyles> & { as?: HtmlTag }) => css`
              ${props.mobile}
              ${writeRebootCssOVerride(props.mobile, props.as)}
            `}
            ${(props: ResponsiveCSSProps<BobTextStyles> & { as?: HtmlTag }) =>
              props.hover.isEnabled.mobile && Object.keys(props.hover.mobile).length > 0
                ? css`
                    &:hover {
                      ${props.hover.mobile}
                      ${writeRebootCssOVerride(props.hover.mobile, props.as)}
                    }
                  `
                : {}}
          }
        `
      : {}}
`

const BobText: React.FC<BobTextProps> = (props): JSX.Element => {
  const [textRef] = useState<RefObject<HTMLDivElement>>(React.createRef())

  useEffect(() => {
    props.setRef && props.setRef(textRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const textStyles = isNbobText(props.object.styles.bobs.ntext1)
  const inlineContent = props.object.content.inlineContent
  const settings = props.object.settings
  const pageLabels = props.pageResponse.stylesheet?.labels

  const desktopStyles = responsiveStyles(textStyles, pageLabels, "desktop", "default")
  const tabletStyles = responsiveStyles(textStyles, pageLabels, "tablet", "default")
  const mobileStyles = responsiveStyles(textStyles, pageLabels, "mobile", "default")
  const hoverStyles = handleStateStyles<nBobText, BobTextStyles>(
    textStyles,
    responsiveStyles(textStyles, pageLabels, "desktop", "hover"),
    responsiveStyles(textStyles, pageLabels, "tablet", "hover"),
    responsiveStyles(textStyles, pageLabels, "mobile", "hover")
  )
  // hoverStyles.desktop?.fontSize TODO: typed styles cannot return {}

  const DOMid = props.expManager.enable
    ? { id: `${handleDOMid(props.field, props.object.uuid, props.instanceId)}` }
    : {}
  const EMpreviewClass = emPreviewClass(props.expManager.enable, textStyles.uuid)
  const EMScrollId = emScrollId(props.expManager.enable, props.object.uuid)
  const GlobalStylesClass = GlobalStylesUtils.handleClassNameV2(props.pageResponse.nGlobalStyles, textStyles, "-text", {
    [gsType.GS_TEXT]: NGS_CLASS_SUFIX,
    [gsType.GS_SHADOW]: NGS_CLASS_SUFIX,
    [gsType.GS_BOUNDARY]: NGS_CLASS_SUFIX,
  })
  const customClasses = settings?.[props.field]?.class ? ` ${settings?.[props.field]?.class?.join(" ")}` : ""
  const classes = `${EMpreviewClass}${GlobalStylesClass ? ` ${GlobalStylesClass}` : ""}${customClasses}`

  const loadedFonts = handleFonts(textStyles, pageLabels, props.expManager.enable)
  const tag = getOverrideInlineContentValues(`${props.field}.behaviour-link-enable`, {
    inlineContent: inlineContent,
    overrides: props.overrides,
  })
    ? "a"
    : getOverrideInlineContentValues(`${props.field}.tag`, { inlineContent: inlineContent, overrides: props.overrides })

  const linkAttrs = getLinkAttributes(
    props.field,
    {
      inlineContent: inlineContent,
      overrides: props.overrides,
    },
    props.pageResponse,
    props.post
  )

  let isCmsValidHtml = false
  if (
    getOverrideInlineContentValues(`${props.field}.contentSrc`, {
      inlineContent: inlineContent,
      overrides: props.overrides,
    }) === CMS ||
    getOverrideInlineContentValues(`${props.field}.contentSrc`, {
      inlineContent: inlineContent,
      overrides: props.overrides,
    }) === CONTENT_PAGE
  ) {
    isCmsValidHtml = validHtml(props.value)
  }

  const hasFont = loadedFonts.trim() !== ""

  const renderCmsContent = () => {
    return (
      <TextComponent
        ref={textRef}
        desktop={desktopStyles}
        mobile={mobileStyles}
        tablet={tabletStyles}
        hover={hoverStyles}
        {...DOMid}
        {...EMScrollId}
        dangerouslySetInnerHTML={{ __html: props.value }}
        className={classes}
      />
    )
  }

  const renderCustomContent = () => {
    const customText = getOverrideInlineContentValues(`${props.field}.customText`, {
      inlineContent: inlineContent,
      overrides: props.overrides,
    })

    // when link is enabled tag must be 'a'
    const TAG = tag === "a" ? tag : "div"

    return (
      <TextComponent
        ref={textRef}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          clickEvent(
            e,
            tag,
            props.field,
            props.object.content.inlineContent,
            props.object.content.inlineContent.overrides,
            props.pageResponse,
            props.post
          )
        }}
        as={TAG}
        {...linkAttrs}
        {...DOMid}
        {...EMScrollId}
        dangerouslySetInnerHTML={{ __html: customText }}
        className={classes}
        desktop={desktopStyles}
        mobile={mobileStyles}
        tablet={tabletStyles}
        hover={hoverStyles}
      />
    )
  }

  const renderCmsCustomTag = () => {
    // parse and render timestamp
    const value = props.value
    //if (value !== "" && moment.unix(value).isValid()) value = moment.unix(value).format("DD/MM/YYYY")

    return (
      <TextComponent
        ref={textRef}
        as={tag}
        {...DOMid}
        {...EMScrollId}
        {...linkAttrs}
        className={classes}
        desktop={desktopStyles}
        mobile={mobileStyles}
        tablet={tabletStyles}
        hover={hoverStyles}
      >
        <BobLoading
          type='text'
          uuid={getComposedUuid(props.pageResponse.objects[props.object.uuid])}
          contentSrc={getOverrideInlineContentValues(`${props.field}.contentSrc`, {
            inlineContent: inlineContent,
            overrides: props.overrides,
          })}
          mappedValue={getOverrideInlineContentValues(`${props.field}.mappedValue`, {
            inlineContent: inlineContent,
            overrides: props.overrides,
          })}
          fontSize={desktopStyles["font-size"]}
        >
          {value && value !== "" ? (typeof value !== "number" ? he.decode(value) : value) : ""}
        </BobLoading>
      </TextComponent>
    )
  }

  let TEXT
  // Render Html when coming from CMS/CONTENT_PAGE
  if (isCmsValidHtml) {
    TEXT = renderCmsContent()
  }
  // Render inline custom text
  else if (
    getOverrideInlineContentValues(`${props.field}.contentSrc`, {
      inlineContent: inlineContent,
      overrides: props.overrides,
    }) === "custom"
  ) {
    TEXT = renderCustomContent()
  }
  // Render custom tag and content from CMS and Content Page, with no valid html.
  // or form content
  else {
    if (props.form) {
      const content = props.object.content
      // when link is enabled tag must be 'a'
      const TAG = tag === "a" ? tag : "div"
      TEXT = (
        <TextComponent
          ref={textRef}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            clickEvent(
              e,
              tag,
              props.field,
              props.object.content.inlineContent,
              props.object.content.inlineContent.overrides,
              props.pageResponse,
              props.post
            )
          }}
          as={TAG}
          {...linkAttrs}
          {...DOMid}
          {...EMScrollId}
          className={classes}
          desktop={desktopStyles}
          mobile={mobileStyles}
          tablet={tabletStyles}
          hover={hoverStyles}
        >
          {
            getFormField(
              props.form,
              content.inlineContent[`${props.field}.mappedValue`],
              content,
              props.field
            ) as string
          }
        </TextComponent>
      )
    } else {
      TEXT = renderCmsCustomTag()
    }
  }

  return (
    <Fragment>
      {hasFont && (
        <Style>
          {`
            ${loadedFonts}
          `}
        </Style>
      )}
      {TEXT}
    </Fragment>
  )
}

export default BobText
