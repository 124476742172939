import { MediaObject } from "../../../../modules/shared-modules/experienceManager/types/contentTypes"
import { PageTypes } from "../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { SingleObject } from "../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { bobBehaviour, bobBehaviourBreakpoint, BobV2 } from "../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { ResponsiveCSSProps } from "./bobTypes"
import { get2WithNull4Enable } from "./bobUtils"

const handleStateStyles = <T, A>(
  bobTemplate: BobV2<T>,
  stylesDesktop: Partial<A>,
  stylesTablet: Partial<A>,
  stylesMobile: Partial<A>
): ResponsiveCSSProps<A>['hover'] => {
  const desktopHoverEnable = bobTemplate.behaviour.hover.enable
  const tabletHoverEnable = fallbackBehaviourTemplateEnable<T>(bobTemplate.tablet?.behaviour?.hover, bobTemplate.behaviour.hover)
  const mobilepHoverEnable = fallbackBehaviourTemplateEnable<T>(bobTemplate.mobile?.behaviour?.hover, bobTemplate.behaviour.hover)

  return {
    isEnabled: {
      desktop: desktopHoverEnable,
      tablet: tabletHoverEnable.enable,
      mobile: mobilepHoverEnable.enable,
    },
    desktop: desktopHoverEnable ? stylesDesktop : {},
    tablet: tabletHoverEnable ? stylesTablet : {},
    mobile: mobilepHoverEnable ? stylesMobile : {},
  }
}

const fallbackBehaviourTemplateEnable = <T>(
  behaviourObj: bobBehaviourBreakpoint<T>,
  desktopBehaviour: bobBehaviour<T>,
): { enable: boolean | undefined } => {
  const enable = get2WithNull4Enable(
    behaviourObj?.enable,
    desktopBehaviour.enable
  )

  return {
    enable: enable,
  }
}

/**
 * returns media queries to apply styles based on enable prop value on the diferent breakpoints
 */
const handleMediaQueryRestrictions = <T>(
  mobileStyles: ResponsiveCSSProps<T>['hover']['isEnabled']['mobile'],
  tabletStyles: ResponsiveCSSProps<T>['hover']['isEnabled']['tablet']
): string => {
  if (tabletStyles === false && mobileStyles) return "and (max-width: 766px), (min-width: 993px)"
  if (tabletStyles === false) return "and (min-width: 993px)"
  if (mobileStyles === false) return "and (min-width: 767px)"
  return ""
}

const mergeMedias = (
  singleObject: SingleObject,
  pageResponse: PageTypes,
  replacedMediaList?: { [id: string]: MediaObject }
): PageTypes => {
  if (!singleObject.content?.formula?.media)
    return {
      ...pageResponse,
      media: {
        ...pageResponse.media,
        ...replacedMediaList,
      },
    }
  return {
    ...pageResponse,
    media: {
      ...pageResponse.media,
      ...singleObject.content.formula.media,
      ...replacedMediaList,
    },
  }
}

export { mergeMedias, fallbackBehaviourTemplateEnable, handleMediaQueryRestrictions, handleStateStyles }
