import React, { useState } from 'react'
import BobComponentHandler from '../../../../bob/bobComponentHandler'
import WarningSystem from '../../../../../../../modules/shared-modules/experienceManager/warningSystem'
import {
  getContent,
  getStringField,
  handleObjectType,
} from "../../../../../../../modules/shared-modules/utilities/components"
import { clickEvent } from "../../../../analytics/analytics"
//import CountryCode from '../../../../modules/routeCreator/countryCode';
import ComponentHandler from "../../../../../componentHandler"
import AlignmentHOC from "../../../alignmentHOC/alignmentHOC"
import { handleColumns, responsiveGuttersClass } from "../../../../bob/bobHandler"
import { alignment } from "../../../../../../../modules/shared-modules/utilities/conditionalController"
import { DropdownProps } from "./interface"
import elementDropdownStyles from "../../../../../../../stylesheets/modules/templates/elementDropdown.module.sass"

const Dropdown: React.FC<DropdownProps> = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState<Boolean>(false)
  const [isHover, setIsHover] = useState<Boolean>(false)

  const buttonOnHover = () => setIsHover(true)

  const buttonUnHover = () => setIsHover(false)

  const handleButtonClick = (buttonLabel: string) => {
    clickEvent(`button-${buttonLabel}`, "Dropdown")
    setDropdownOpen(!dropdownOpen)
  }

  const renderDropdownItems = () => {
    const itemsId = props.obj.children
    if (itemsId && itemsId.length > 0) {
      return itemsId.map((objId: any, idx: any) => {
        const handledItem = handleObjectType(props.obj, objId, props.pageResponse, props.expManager.emEditorType)
        return (
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            key={idx}
            objectId={props.obj.uuid}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field={"background3"}
            rules={props.componentRule}
            template={props.obj.styles}
            expManager={props.expManager}
            className={`dropdown-item ${elementDropdownStyles["dropdown-item"]}`}
            tag='button'
          >
            <ComponentHandler
              position={idx}
              selectedInstanceId={props.obj.selectedInstanceId}
              component={handledItem}
              pageResponse={props.pageResponse}
              matchOrder={props.matchOrder}
              expManager={props.expManager}
            />
          </BobComponentHandler>
        )
      })
    }
  }

  const renderDropdown = (background1Template: any) => {
    const content = getContent(props.obj, "posts", props.overrides)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: any, idx: any) => {
        const columns = handleColumns(
          idx,
          counter,
          background1Template,
          props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        counter = columns.counter
        const buttonLabel = getStringField(post, props.obj, props.pageResponse, "text1", props.overrides)
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <div className='dropdown'>
              <BobComponentHandler
                position={props.position}
                pageResponse={props.pageResponse}
                instanceId={props.obj.selectedInstanceId}
                object={props.obj}
                objectId={props.obj.uuid}
                post={post}
                inlineContent={props.obj.content.inlineContent}
                overrides={props.overrides}
                field={"background2"}
                rules={props.componentRule}
                template={props.obj.styles}
                expManager={props.expManager}
                className={`btn btn-fluxio btn-lg dropdown-toggle ${elementDropdownStyles["dropdown-toggle"]} ${
                  dropdownOpen ? `isOpen ${elementDropdownStyles.isOpen}` : ""
                }`}
                tag='button'
                onClick={() => handleButtonClick(buttonLabel)}
                onMouseEnter={buttonOnHover}
                onMouseLeave={buttonUnHover}
              >
                <BobComponentHandler
                  position={props.position}
                  pageResponse={props.pageResponse}
                  replacedMediaList={props.replacedMediaList}
                  instanceId={props.obj.selectedInstanceId}
                  object={props.obj}
                  objectId={props.obj.uuid}
                  inlineContent={props.obj.content.inlineContent}
                  overrides={props.overrides}
                  field='media1'
                  template={props.obj.styles}
                  rules={props.componentRule}
                  expManager={props.expManager}
                  network={post.network}
                  post={post}
                  className={`${isHover ? "isHover" : ""}`}
                />
                <BobComponentHandler
                  position={props.position}
                  pageResponse={props.pageResponse}
                  instanceId={props.obj.selectedInstanceId}
                  object={props.obj}
                  objectId={props.obj.uuid}
                  post={post}
                  inlineContent={props.obj.content.inlineContent}
                  overrides={props.overrides}
                  field='text1'
                  tag='p'
                  value={buttonLabel}
                  template={props.obj.styles}
                  rules={props.componentRule}
                  expManager={props.expManager}
                  network={post.network}
                  className={`label ${elementDropdownStyles.label} ${isHover ? "isHover" : ""}`}
                />
                <BobComponentHandler
                  position={props.position}
                  pageResponse={props.pageResponse}
                  instanceId={props.obj.selectedInstanceId}
                  object={props.obj}
                  objectId={props.obj.uuid}
                  field='icon1'
                  template={props.obj.styles}
                  rules={props.componentRule}
                  expManager={props.expManager}
                  iconClassName='fas fa-chevron-down'
                />
              </BobComponentHandler>
              <div
                className={`dropdown-menu ${elementDropdownStyles["dropdown-menu"]} ${
                  dropdownOpen ? `isOpen ${elementDropdownStyles.isOpen}` : ""
                }`}
              >
                {renderDropdownItems()}
              </div>
            </div>
          </div>
        )
      })
    } else {
      if (props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Add a single post or a feed' />
          </div>
        )
      }
    }
  }

  /**
   * Responsive Alignment Styles
   */
  const background1 = props.obj.styles.bobs.background1
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

  return (
    <section className={`elements ${elementDropdownStyles.elements} button dropdown ${elementDropdownStyles.dropdown}`}>
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderDropdown(background1)}
      </AlignmentHOC>
    </section>
  )
}

export default Dropdown;
