import { useRef } from 'react'
import { Dots as StyledDots, Dot as StyledDot } from './dots.css'
import BobComponentHandler from '../../../../bob/bobComponentHandler'
import { ExpManager, PageTypes } from '../../../../../../../modules/shared-modules/experienceManager/types/pageTypes'
import { SingleObject } from '../../../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes'
import { isBobIcon, Styles } from "../../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

interface DotsProps {
  centerDots: number
  currentSlide: number
  totalSlides: number
  goToSlide: Function
  position: number
  pageResponse: PageTypes
  instanceId: string | undefined
  object: SingleObject
  objectId: string
  field: string
  template: Styles
  rules: any
  expManager: ExpManager
  iconClassName: string
}

export default function Dots({
  centerDots,
  currentSlide,
  totalSlides,
  goToSlide,
  position,
  pageResponse,
  instanceId,
  object,
  objectId,
  field,
  template,
  rules,
  expManager,
  iconClassName,
}: DotsProps) {
  const centerOffset = useRef(0)
  const slideOffset = useRef(0)

  const currentCenterOffset = currentSlide - slideOffset.current
  if (currentCenterOffset >= 0 && currentCenterOffset < centerDots) {
    centerOffset.current = currentCenterOffset
  } else {
    slideOffset.current = currentSlide - centerOffset.current
  }

  const clamp = (value: number, min: number, max: number) => {
    return Math.min(Math.max(min, value), max)
  }

  return (
    <StyledDots className='slider-nav-bottom'>
      {[...Array(totalSlides)].map((_, idx) => {
        const centerPage = centerDots / 2 + slideOffset.current
        const distance = Math.abs(idx - centerPage)

        const scaledDistance = clamp(distance - centerDots / 2, 0, 3)

        return (
          <StyledDot
            active={idx === currentSlide}
            distance={scaledDistance}
            template={isBobIcon(template.bobs.icon3)}
            key={idx}
          >
            <BobComponentHandler
              onClick={() => goToSlide(idx)}
              position={position}
              pageResponse={pageResponse}
              instanceId={instanceId}
              object={object}
              objectId={objectId}
              field={field}
              template={template}
              templatePropsToIgnore={["color", "size", "spacing"]}
              rules={rules}
              expManager={expManager}
              iconClassName={iconClassName}
            />
          </StyledDot>
        )
      })}
    </StyledDots>
  )
}
