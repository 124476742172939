import React from 'react'
import InstagramEmbed from 'react-instagram-embed'
import InstagramMediaEmbed from '../../../../../fluxio-components/Socials/SocialsEmbed/InstagramMediaEmbed/InstagramMediaEmbed'
import { SocialEmbedProps } from './interface';

const SocialEmbed: React.FC<SocialEmbedProps> = (props): JSX.Element => {

  /**
   * Renders diferent type of layouts for diferent social media
   * Layout types: default, media 
   */
  const postObj = props.postObj

  // media layout
  if (props.type === 'media')
    switch (postObj.network) {
      case 'instagram':
        return (
          <InstagramMediaEmbed
            url={postObj.url}
          />
        )
      default:
        return <>{'no social post found'}</>
    }

  // default layout
  switch (postObj.network) {
    case 'instagram':
      return (
        <InstagramEmbed
          url={postObj.url}
          accessToken='1669312576562272|0408296a55ded133d0135900018b2898'
          maxWidth={100}
          hideCaption={false}
          containerTagName='div'
          protocol=''
          injectScript
          onLoading={() => { }}
          onSuccess={() => { }}
          onAfterRender={() => { }}
          onFailure={() => { }}
        />
      )
    default:
      return <>{'no social post found'}</>
  }
}

export default SocialEmbed