import { FormSettings } from "./types/objectContentTypes"
import { EMEditorType, PageTypes } from "./types/pageTypes"
import { SingleObject } from "./types/singleObjectTypes"

class SingleObjectUtils {
  static getObjectByUuid(
    singleObject: SingleObject,
    form: FormSettings | undefined,
    pageResponse: PageTypes,
    emEditorType: EMEditorType
  ): SingleObject {
    /**
     * when in symbolTree we must use symbols list to find the clicked object
     */
    if (singleObject.fromSymbolTree && emEditorType !== "symbol") {
      let symbolObj = {
        ...pageResponse.symbols[singleObject.fromSymbolTree].objects[singleObject.uuid],
        selectedInstanceId: singleObject.selectedInstanceId,
      }
      // write form when needed
      if (form) symbolObj.content.form = form

      return symbolObj
    }
    let obj = pageResponse.objects[singleObject.uuid]
    // write form when needed
    if (form) obj.content.form = form
    return obj
  }
}

export default SingleObjectUtils
