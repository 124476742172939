import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { getDataSet } from "../../../../../store/shared-store/actions/publicPageActions"
import { bindActionCreators } from "redux"
import { handleColumns } from "../../../shared-public-page/bob/bobHandler"
import AlignmentHOC from "../../../shared-public-page/fluxio-components/alignmentHOC/alignmentHOC"
import FormInputHandler from "../../../shared-public-page/fluxio-components/forms/FormInputHandler"
import { alignment } from "../../../../../modules/shared-modules/utilities/conditionalController"
import { DataSetFilterProps } from "."
import { AppDispatch } from "../../../../../store/shared-store/types"
import simpleFormStyles from "../../../../../stylesheets/modules/templates/simpleForm.module.sass"

const DataSetFilter: React.FC<DataSetFilterProps> = (props): JSX.Element => {
  const [filterParams, setFilterParams] = useState<any>({})
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (props.dataSet) props.actions.getDataSet(props.dataSet.uuid, filterParams)
    }
  }, [filterParams])

  const handleInputValue = (selectedOption: any) => {
    // handle clear filter
    if (selectedOption.value === "Sem filtro") {
      let newFilterParams = filterParams
      delete newFilterParams[selectedOption.option.name]
      setFilterParams(newFilterParams)
    } else {
      // handle set filter
      setFilterParams({
        ...filterParams,
        [selectedOption.option.name]: selectedOption.value,
      })
    }
  }

  const renderInputs = (background1TemplateMerged: any) => {
    const columns = handleColumns(
      0,
      { desktop: 0, tablet: 0, mobile: 0 },
      background1TemplateMerged,
      props.pageResponse.globalStyles[background1TemplateMerged.globalStyleId]
    )

    return (
      <div className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}>
        {props.dataSetFilters &&
          props.dataSetFilters.map((filter: any, idx: number) => {
            // add empty opton to clear filter
            filter.selectChoices =
              filter.selectChoices && filter.selectChoices[0] !== "Sem filtro"
                ? ["Sem filtro", ...filter.selectChoices]
                : filter.selectChoices
            return (
              <FormInputHandler
                key={idx}
                obj={props.obj}
                componentRule={props.componentRule}
                inputObj={{ ...filter }}
                lang={"pt"}
                delay={filter.fieldType === "text" ? 1000 : undefined}
                position={props.position}
                formDataErrors={[]}
                pageResponse={props.pageResponse}
                expManager={props.expManager}
                handleInputValue={(value: any) => handleInputValue(value)}
              />
            )
          })}
      </div>
    )
  }

  const background1 = props.obj.styles.bobs.background1
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
      }}
      className={`datase-filter-component simple-form-component ${simpleFormStyles["simple-form-component"]}`}
    >
      <AlignmentHOC
        //TODO: typescript
        //@ts-ignore
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderInputs(background1)}
      </AlignmentHOC>
    </form>
  )
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    actions: bindActionCreators({ getDataSet }, dispatch),
  }
}

function mapStateToProps(state: any) {
  return {
    dataSet: state.publicPageReducer.server.dataSet,
    dataSetFilters: state.publicPageReducer.server.dataSetFilters,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataSetFilter);