import React from 'react'
import BobComponentHandler from '../../../bob/bobComponentHandler'
import WarningSystem from '../../../../../../modules/shared-modules/experienceManager/warningSystem'
import { getContent } from "../../../../../../modules/shared-modules/utilities/components"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { MediaProps } from "./interface"

const Media: React.FC<MediaProps> = (props) => {
  const renderImages = (background1Template: any) => {
    const content = getContent(props.obj, "posts", props.overrides)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: any, idx: any) => {
        const columns = handleColumns(
          idx,
          counter,
          background1Template,
          props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              replacedMediaList={props.replacedMediaList}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              inlineContent={props.obj.content.inlineContent}
              overrides={props.overrides}
              field='media1'
              template={props.obj.styles}
              rules={props.componentRule}
              expManager={props.expManager}
              network={post.network}
              post={post}
            />
          </div>
        )
      })
    } else {
      if (props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Add a single post or a feed' />
          </div>
        )
      }
    }
  }

  /**
   * Responsive Alignment Styles
   */
  const background1 = props.obj.styles.bobs.background1
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

  return (
    <section className='elements image'>
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderImages(background1)}
      </AlignmentHOC>
    </section>
  )
}


export default Media;