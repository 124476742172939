import React, { Fragment } from 'react'
import {
  Modal as BootstrapModal,
} from 'react-bootstrap'
import BobComponentHandler from '../../../../shared-public-page/bob/bobComponentHandler'
import ComponentHandler from '../../../../componentHandler'
import { handler } from "../../../../../../modules/routeCreator"
import {
  getContent,
  getStringField,
  handleContentBlock,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import { withRouter } from "next/router"
import { ModalProps } from "."
import modalStyles from "../../../../../../stylesheets/modules/components/modal.module.sass"
import elementModalStyles from "../../../../../../stylesheets/modules/templates/elementModal.module.sass"

class Modal extends React.Component<ModalProps, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      showModal: false,
    }
    this.handleModal = this.handleModal.bind(this)
    this.handleModalContainerClick = this.handleModalContainerClick.bind(this)
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
    this.handleInnerFunction = this.handleInnerFunction.bind(this)
  }

  componentDidMount() {
    // access inner methods from parent
    if (this.props.onRef) this.props.onRef(this)
    else return
  }

  componentWillUnmount() {
    // access inner methods from parent
    if (this.props.onRef) this.props.onRef(undefined)
    else return
  }

  handleInnerFunction() {
    this.handleModal()
  }

  handleModal(post?: any) {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => {
        if (!this.props.expManager.enable && post) {
          if (this.state.showModal)
            this.props.router.push(handler(this.props.router, this.props.matchOrder, post.slug), undefined, {
              scroll: false,
            })
          else
            this.props.router.push(handler(this.props.router, this.props.matchOrder, ""), undefined, {
              scroll: false,
            })
        }
      }
    )
  }

  handleBackgroundClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    /**
     * This trigger works when the user clicks outside of the modal body
     */
    this.handleModal()
  }

  handleModalContainerClick(e: Event) {
    e.stopPropagation()
  }

  renderModalComponents(post?: any) {
    let componentsId = this.props.obj.children
    if (componentsId && componentsId.length > 0)
      return componentsId.map((componentId: any, idx: any) => {
        let handledItem = handleObjectType(
          this.props.obj,
          componentId,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={this.props.obj.selectedInstanceId}
            key={idx}
            component={handleContentBlock(
              handledItem,
              post,
              this.props.obj,
              this.props.pageResponse,
              this.props.overrides
            )}
            pageResponse={this.props.pageResponse}
            matchOrder={this.props.matchOrder}
            expManager={this.props.expManager}
          />
        )
      })
  }

  renderModalPosts() {
    let postsArr = getContent(this.props.obj, "posts", this.props.overrides, undefined, false)
    if (postsArr.length > 0) {
      return postsArr.map((post: any, idx: any) => {
        return (
          <Fragment key={idx}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              replacedMediaList={this.props.replacedMediaList}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='media1'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              post={post}
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='text1'
              tag='h1'
              value={getStringField(post, this.props.obj, this.props.pageResponse, "text1", this.props.overrides)}
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              className='title'
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              field='text2'
              tag='p'
              value={getStringField(post, this.props.obj, this.props.pageResponse, "text2", this.props.overrides)}
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              className='description'
            />
            {this.renderModalComponents(post)}
          </Fragment>
        )
      })
    }
    return this.renderModalComponents()
  }

  renderModal() {
    let isOpen = this.state.showModal
    return (
      <BootstrapModal
        className={`modal-fluxio ${modalStyles["modal-fluxio"]} ${elementModalStyles["modal-fluxio"]} ${elementModalStyles["modal_v1_ssr"]}`}
        backdropClassName={`modal-component-backdrop ${elementModalStyles["modal_ssr_backdrop"]}`}
        size='xl'
        enforceFocus={false}
        show={isOpen}
        onHide={this.handleModal}
        backdrop
      >
        <BobComponentHandler
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.obj.selectedInstanceId}
          object={this.props.obj}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.handleBackgroundClick(e)}
          objectId={this.props.obj.uuid}
          inlineContent={this.props.obj.content.inlineContent}
          overrides={this.props.overrides}
          field={"background1"}
          rules={this.props.componentRule}
          template={this.props.obj.styles}
          expManager={this.props.expManager}
          className='modal-container'
          flexDirection='column'
        >
          <BootstrapModal.Header closeButton={false}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              field='icon1'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              iconClassName='fas fa-times aaa'
              className={`modal-close`}
              onClick={this.handleModal}
            />
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <div className='modal-boy-container' onClick={() => this.handleModalContainerClick}>
              {this.state.showModal && this.renderModalPosts()}
            </div>
          </BootstrapModal.Body>
        </BobComponentHandler>
      </BootstrapModal>
    )
  }

  render() {
    return (
      <section
        className={`elements ${elementModalStyles.elements} modal-component ${elementModalStyles["modal-component"]}`}
      >
        {this.renderModal()}
      </section>
    )
  }
}

export default withRouter(Modal)