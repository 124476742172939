import React from 'react'
import { getContent, getStringField } from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import WarningSystem from "../../../../../../modules/shared-modules/experienceManager/warningSystem"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { SocialCardShareProps } from "./interface"
import cardStyles from "../../../../../../stylesheets/modules/components/card.module.sass"
import cardsStyles from "../../../../../../stylesheets/modules/templates/cards.module.sass"
import socialCardShareStyles from "../../../../../../stylesheets/modules/templates/socialCardShare.module.sass"

const SocialCardShare: React.FC<SocialCardShareProps> = (props) => {
  const renderShareCard = (post: any) => {
    return (
      <div className={`card ${cardStyles["card"]} ${socialCardShareStyles["card"]}`}>
        <BobComponentHandler
          position={props.position}
          pageResponse={props.pageResponse}
          replacedMediaList={props.replacedMediaList}
          instanceId={props.obj.selectedInstanceId}
          object={props.obj}
          objectId={props.obj.uuid}
          inlineContent={props.obj.content.inlineContent}
          overrides={props.overrides}
          field='media2'
          template={props.obj.styles}
          rules={props.componentRule}
          expManager={props.expManager}
          network={post.network}
          post={post}
        />
        <div className='card-body'>
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            post={post}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field='text5'
            tag='p'
            value={getStringField(post, props.obj, props.pageResponse, "text5", props.overrides)}
            template={props.obj.styles}
            rules={props.componentRule}
            expManager={props.expManager}
            network={post.network}
            className='card-title'
          />
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            post={post}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field='text6'
            tag='p'
            value={getStringField(post, props.obj, props.pageResponse, "text6", props.overrides)}
            template={props.obj.styles}
            rules={props.componentRule}
            expManager={props.expManager}
            network={post.network}
            className='card-desc'
          />
        </div>
      </div>
    )
  }

  const renderCards = (background1Template: any) => {
    const content = getContent(props.obj, "posts")
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: any, idx: any) => {
        const columns = handleColumns(
          idx,
          counter,
          background1Template,
          props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              post={post}
              inlineContent={props.obj.content.inlineContent}
              overrides={props.overrides}
              field={"background2"}
              rules={props.componentRule}
              template={props.obj.styles}
              expManager={props.expManager}
              className={`card ${cardStyles["card"]} ${socialCardShareStyles.card}`}
            >
              <div className={`card-header ${socialCardShareStyles["card-header"]}`}>
                <div className='d-flex w-100 justify-content-between'>
                  <div>
                    <BobComponentHandler
                      position={props.position}
                      pageResponse={props.pageResponse}
                      replacedMediaList={props.replacedMediaList}
                      instanceId={props.obj.selectedInstanceId}
                      object={props.obj}
                      objectId={props.obj.uuid}
                      inlineContent={props.obj.content.inlineContent}
                      overrides={props.overrides}
                      field='media1'
                      template={props.obj.styles}
                      rules={props.componentRule}
                      expManager={props.expManager}
                      network={post.network}
                      post={post}
                    />
                  </div>
                  <div className='flex-column d-flex justify-content-between ml-2'>
                    <BobComponentHandler
                      position={props.position}
                      pageResponse={props.pageResponse}
                      instanceId={props.obj.selectedInstanceId}
                      object={props.obj}
                      objectId={props.obj.uuid}
                      post={post}
                      inlineContent={props.obj.content.inlineContent}
                      overrides={props.overrides}
                      field='text1'
                      tag='p'
                      value={getStringField(post, props.obj, props.pageResponse, "text1", props.overrides)}
                      template={props.obj.styles}
                      rules={props.componentRule}
                      expManager={props.expManager}
                      network={post.network}
                      className='network'
                    />
                    <BobComponentHandler
                      position={props.position}
                      pageResponse={props.pageResponse}
                      instanceId={props.obj.selectedInstanceId}
                      object={props.obj}
                      objectId={props.obj.uuid}
                      post={post}
                      inlineContent={props.obj.content.inlineContent}
                      overrides={props.overrides}
                      field='text2'
                      tag='p'
                      value={getStringField(post, props.obj, props.pageResponse, "text2", props.overrides)}
                      template={props.obj.styles}
                      rules={props.componentRule}
                      expManager={props.expManager}
                      network={post.network}
                      className='entity'
                    />
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <BobComponentHandler
                    position={props.position}
                    pageResponse={props.pageResponse}
                    instanceId={props.obj.selectedInstanceId}
                    object={props.obj}
                    objectId={props.obj.uuid}
                    post={post}
                    inlineContent={props.obj.content.inlineContent}
                    overrides={props.overrides}
                    field='text3'
                    tag='span'
                    value={getStringField(post, props.obj, props.pageResponse, "text3", props.overrides)}
                    template={props.obj.styles}
                    rules={props.componentRule}
                    expManager={props.expManager}
                    network={post.network}
                    className='date'
                  />
                </div>
              </div>
              <div className='card-body'>
                <BobComponentHandler
                  position={props.position}
                  pageResponse={props.pageResponse}
                  instanceId={props.obj.selectedInstanceId}
                  object={props.obj}
                  objectId={props.obj.uuid}
                  post={post}
                  inlineContent={props.obj.content.inlineContent}
                  overrides={props.overrides}
                  field='text4'
                  tag='p'
                  value={getStringField(post, props.obj, props.pageResponse, "text4", props.overrides)}
                  template={props.obj.styles}
                  rules={props.componentRule}
                  expManager={props.expManager}
                  network={post.network}
                  className='message'
                />
                {renderShareCard(post)}
              </div>
            </BobComponentHandler>
          </div>
        )
      })
    } else {
      if (props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Select a formula to show posts' />
          </div>
        )
      }
    }
  }

  const background1 = props.obj.styles.bobs.background1
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
  return (
    <section
      className={`cards ${cardsStyles.cards} social social-card-share ${socialCardShareStyles["social-card-share"]}`}
    >
      <AlignmentHOC
        className={`card-deck ${cardStyles["card-deck"]} row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderCards(background1)}
      </AlignmentHOC>
    </section>
  )
}


export default SocialCardShare;
