import { getDeviceSize } from "../../../../modules/shared-modules/utilities/utils"
import {
  ResponsiveBreakpoint,
  ResponsiveData,
} from "../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import * as viewPort from "../../../../modules/shared-modules/experienceManager/viewPortTypes/viewPortTypes"

class Devices {
  /**
   * Scale viewport when size is bigger than the viewport itself
   * @param {*} size
   * @param {*} scale
   */
  static handleViewPortMaxSize(size: number, scale: number, max: number) {
    if (size >= max) {
      scale = (max * 100) / size
      return { size, scale }
    }
    if (size <= max && size === viewPort.DESKTOP) size = max
    return { size, scale }
  }

  static handleSelectedBreakpoint(viewPortSizeState: number) {
    if (getDeviceSize(viewPortSizeState) === "mobile") return "mobile"
    if (getDeviceSize(viewPortSizeState) === "tablet") return "tablet"
    if (getDeviceSize(viewPortSizeState) === "desktop") return "desktop"
  }

  static handleResponsiveBreakpoint(
    responsiveTab: ResponsiveBreakpoint["type"],
    viewPortSizeState: number
  ): ResponsiveBreakpoint {
    return {
      type: responsiveTab,
      breakpoint: this.handleSelectedBreakpoint(viewPortSizeState) as ResponsiveData<any>["breakpoint"],
    }
  }
}

export default Devices
