import React, { Fragment, RefObject, useState } from "react"
import { GridProps } from "."
import { Post } from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { isBobGrid } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import {
  getContent,
  handleContentBlock,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import ComponentHandler from "../../../../componentHandler"
import ComponentSelectorEMv2 from "../../../componentSelectorEM/ComponentSelectorEMv2"
import { getStartPos, handleColumns } from "../../../bob/bobGrid/bobGridHandler"
//Needs to be module for SSR
//import "../../../../../../stylesheets/bootstrap-grid.css"
import BobGrid from "../../../bob/bobGrid/bobGrid"

const Grid: React.FC<GridProps> = (props): JSX.Element => {
  const [elRef, setElRef] = useState<RefObject<HTMLDivElement> | undefined>(undefined)
  const grid1 = isBobGrid(props.obj.styles.bobs.grid1)

  const renderChilds = () => {
    const content = getContent(props.obj, "posts", props.overrides)
    if (content && content.length > 0) {
      return content.map((post: Post, idx: number) => {
        const childComponentsId = props.obj.children
        if (childComponentsId && childComponentsId.length > 0) {
          return renderChildComponents(childComponentsId, post, idx)
        }
        return <div key={idx}>No Content</div>
      })
    }
  }

  const renderChildComponents = (childComponentsId: string[], post: Post, idx: number) => {
    const startPositionClasses = getStartPos(childComponentsId, grid1)
    let counter = { desktop: 0, tablet: 0, mobile: 0 }
    return childComponentsId.map((item, idx2) => {
      const handledItem = handleObjectType(props.obj, item, props.pageResponse, props.expManager.emEditorType)
      const finalIdx = idx + idx2
      const columns = handleColumns(idx2, counter, grid1)
      counter = columns.counter

      return (
        <div
          key={finalIdx}
          className={`g-col-${columns.mobile} g-col-md-${columns.tablet} g-col-lg-${columns.desktop}${idx2 === 0
            ? `${startPositionClasses.desktopClass}${startPositionClasses.tabletClass}${startPositionClasses.mobileClass}`
            : ""
            }`}
        >
          <ComponentHandler
            position={finalIdx}
            component={handleContentBlock(handledItem, post, props.obj, props.pageResponse, props.overrides)}
            pageResponse={props.pageResponse}
            selectedInstanceId={props.obj.selectedInstanceId}
            matchOrder={props.matchOrder}
            expManager={props.expManager}
          />
        </div>
      )
    })
  }

  const renderMissingChildsPreviews = () => {
    if (props.expManager.enable && !props.expManager.content) {
      const columns = grid1.grid.columns.length
      const childs = props.obj.children.length
      const missingChilds = columns - childs
      const startFrom = columns - missingChilds

      let previews = []
      for (let i = 0; i < missingChilds; i++) {
        const columnsDesktop = grid1.grid.columns[startFrom + i]
        const columnsTablet = grid1.tablet?.grid?.columns
          ? grid1.tablet?.grid?.columns[startFrom + i]
          : columnsDesktop
        const columnsMobile = grid1.mobile?.grid?.columns
          ? grid1.mobile?.grid?.columns[startFrom + i]
          : columnsDesktop

        previews.push(
          <Fragment>
            <div
              key={i}
              className={`g-col-${columnsMobile} g-col-md-${columnsTablet} g-col-lg-${columnsDesktop}`}
              style={{ minHeight: "100px", border: "1px solid rgba(64, 80, 252, 0.3)" }}
            ></div>
          </Fragment>
        )
      }

      return previews
    }
  }

  return (
    <Fragment>
      {props.expManager.enable && !props.expManager.content && (
        <ComponentSelectorEMv2
          uuid={props.obj.uuid}
          masterBob='grid1'
          obj={props.obj}
          editing={props.editing}
          elRef={elRef}
        />
      )}
      <BobGrid
        setRef={(containerRef) => setElRef(containerRef)}
        pageResponse={props.pageResponse}
        instanceId={props.obj.selectedInstanceId}
        object={props.obj}
        id={props.obj.uuid}
        inlineContent={props.obj.content.inlineContent}
        overrides={props.overrides}
        field='grid1'
        post={props.post}
        template={props.obj.styles}
      >
        {renderChilds()}
        {renderMissingChildsPreviews()}
      </BobGrid>
    </Fragment>
  )
}

export default React.memo(Grid)
