import React from 'react'
//import dynamic from 'next/dynamic'
import * as component from '../shared-public-page/componentTypes'
import ErrorBoundary from '../../../components/shared-components/error/ErrorBoundary'
import ContentFetch from '../ContentFetch'
import ComponentSelectorEM from '../shared-public-page/componentSelectorEM'
import { handleFilterSymbolObject } from "../../../modules/shared-modules/utilities/symbols"
import isEqual from "lodash/isEqual"
import JSONpath from "jsonpath"
import { connect } from "react-redux"
import { ComponentHandlerProps } from "./interface"
import { SingleObject } from "../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { ObjectContentOverride } from "../../../modules/shared-modules/experienceManager/types/objectContentTypes"

// Templates2
import InvalidComponent from "../shared-public-page/fluxio-components/invalid/Invalid"
import SocialCardShare from "../shared-public-page/fluxio-components/Socials/SocialCardShare/SocialCardShare"
import TabsContainer from "../fluxio-components/Tabs/TabsContainer/TabsContainer"
import TabsContainerDropdown from "../fluxio-components/Tabs/TabsContainerDropdown/TabsContainerDropdown"
import TabsContent from "../shared-public-page/fluxio-components/Tabs/TabsContents/TabsContent/TabsContent"
import TabsContentVertical from "../shared-public-page/fluxio-components/Tabs/TabsContents/TabsContentVertical/TabsContentVertical"
import TabsContentV2 from "../shared-public-page/fluxio-components/Tabs/TabsContents/TabsContentV2/TabsContentV2"
import TabsContentVerticalV2 from "../shared-public-page/fluxio-components/Tabs/TabsContents/TabsContentVerticalV2/TabsContentVerticalV2"
import CardModal from "../fluxio-components/Cards/CardModal/CardModal"
import CardLeft from "../shared-public-page/fluxio-components/Cards/cardLeft/cardLeft"
import CardLeftV2 from "../shared-public-page/fluxio-components/Cards/cardLeftV2/cardLeftV2"
import CardRight from "../shared-public-page/fluxio-components/Cards/cardRight/cardRight"
import CardRightV2 from "../shared-public-page/fluxio-components/Cards/cardRightV2/cardRightV2"
import CardTop from "../shared-public-page/fluxio-components/Cards/cardTop/cardTop"
import GridContainer from "../shared-public-page/fluxio-components/Structure/gridContainer/gridContainer"
import GridContainerV2 from "../shared-public-page/fluxio-components/Structure/gridContainerV2/gridContainerV2"
import SliderContainer from "../shared-public-page/fluxio-components/Structure/sliderContainer/sliderContainer"
import SliderContainerV2 from "../shared-public-page/fluxio-components/Structure/sliderContainerV2/sliderContainerV2"
import SliderContainerV3 from "../shared-public-page/fluxio-components/Structure/sliderContainerV3/sliderContainerV3"
import EmbedContainer from "../shared-public-page/fluxio-components/Structure/embedContainer/embedContainer"
import EmbedContainerV2 from "../shared-public-page/fluxio-components/Structure/embedContainer/embedContainerV2"
import Media from "../shared-public-page/fluxio-components/Elements/Media/Media"
import TextBlock from "../shared-public-page/fluxio-components/Elements/TextBlock/TextBlock"
import Button from "../shared-public-page/fluxio-components/Elements/Buttons/Button/Button"
import ButtonRight from "../shared-public-page/fluxio-components/Elements/Buttons/ButtonRight/ButtonRight"
import NavbarComponent from "../shared-public-page/fluxio-components/navigation/navbar/navbar"
import NavbarTop from "../shared-public-page/fluxio-components/navigation/navbarTop/navbarTop"
import NavbarV2 from "../shared-public-page/fluxio-components/navigation/navbarV2/navbarV2"
import MenuComponent from "../shared-public-page/fluxio-components/navigation/menu/menu"
import MenuComponentV2 from "../shared-public-page/fluxio-components/navigation/menuV2/menuV2"
import MenuToggle from "../shared-public-page/fluxio-components/navigation/menuToggle/menuToggle"
import MenuToggleV2 from "../shared-public-page/fluxio-components/navigation/menuToggleV2/menuToggleV2"
import Modal from "../fluxio-components/Elements/Modals/Modal/Modal"
import ModalV2 from "../fluxio-components/Elements/Modals/ModalV2/ModalV2"
import ModalV3 from "../fluxio-components/Elements/Modals/ModalV3/ModalV3"
import ModalV4 from "../shared-public-page/fluxio-components/Elements/Modals/ModalV4/ModalV4"
import Showcase from "../shared-public-page/fluxio-components/Gallery/showcase/showcase"
import SocialCardEmbed from "../shared-public-page/fluxio-components/Socials/SocialCardEmbed/SocialCardEmbed"
import SocialMediaEmbed from "../shared-public-page/fluxio-components/Socials/SocialMediaEmbed/SocialMediaEmbed"
import ComponentBoilerplate from "../shared-public-page/componentBoilerplate/componentBoilerplate"
import Dropdown from "../shared-public-page/fluxio-components/Elements/Dropdowns/Dropdown/dropdown"
import DropdownV2 from "../shared-public-page/fluxio-components/Elements/Dropdowns/DropdownV2/dropdownV2"
import CardExpandContainer from "../fluxio-components/Expand/cardExpandContainer/cardExpandContainer"
import ExpandContent from "../shared-public-page/fluxio-components/Expand/cardExpandContent/expandContent"
import ExpandContentV2 from "../shared-public-page/fluxio-components/Expand/cardExpandContentV2/expandContentV2"
import ImageComparationSlider from "../shared-public-page/fluxio-components/Gallery/comparationSlider/comparationSlider"
import FormContainer from "../shared-public-page/fluxio-components/forms/formContainer/FormContainer/FormContainer"
import Container from "../shared-public-page/fluxio-components/Structure/container/container"
import ResultsMap from "../fluxio-components/dataSetFilters/resultsMap/resultsMap"
import DataSetContainer from "../fluxio-components/dataSetFilters/dataSetContainer/dataSetContainer"
import ResultsListContainer from "../fluxio-components/dataSetFilters/resultsList/resultsListContainer"
import DataSetFilter from "../fluxio-components/dataSetFilters/dataSetFilter/dataSetFilter"
import MediaV2 from "../shared-public-page/fluxio-components/Elements/media_v2"
import Text from "../shared-public-page/fluxio-components/Elements/text/text"
import Grid from "../shared-public-page/fluxio-components/Structure/grid"
import CustomCssHandler from "../shared-public-page/customCssHandler"
import Form from "../shared-public-page/fluxio-components/forms/form/form"
import FormInput from "../shared-public-page/fluxio-components/forms/formInput/formInput"
import FormSubmit from "../shared-public-page/fluxio-components/forms/formSubmit/formSubmit"

class ComponentHandler extends React.Component<ComponentHandlerProps> {
  childRef: any

  componentDidMount() {
    // access inner methods from parent
    if (this.props.onRef) this.props.onRef(this)
  }
  componentWillUnmount() {
    // access inner methods from parent
    if (this.props.onRef) this.props.onRef(undefined)
  }

  shouldComponentUpdate(nextProps: ComponentHandlerProps) {
    const areEqual = nextProps === this.props
    if (areEqual) return false
    else {
      let diff = false
      for (let key in nextProps) {
        /*
        We're using object spread to create the `component` prop, which forces rerenders even if there is no change
        A better solution would be to pass the symbol and instance down to the bobs and they could choose which value
        to read from.

        Currently we just optimize (prevent re-render) for the case where this component is an instance, we also
        avoid a deep equals when `component.styles` exists, because styles are too big to use deep equals.
        */

        if (key === "component" && !nextProps.component.styles)
          diff = !isEqual(nextProps.component, this.props.component)
        else {
          //@ts-ignore
          diff = nextProps[key] !== this.props[key]
        }
        if (diff) break
      }
      return diff
    }
  }
  handleInnerFunction(postSlug?:string) {
    if (this.childRef) this.childRef.handleInnerFunction(postSlug)
  }

  handleLogicForEditState(item: SingleObject) {
    //If its outside of EM we dont return the editing class
    if (!this.props.expManager.enable) return false
    /**
     * If there is a selected instance in the page tree we cant let
     * the logic continute, so we force the return to be false
     * if the ids dont match
     */
    if (typeof this.props.selectedSymbolInstanceId === "string") {
      if (this.props.selectedSymbolInstanceId === item.selectedInstanceId) {
        if (!this.props.symbolObj) return true
        return this.props.hubHandlerId === item.uuid ? true : false
      }
      return false
    }
    /**
     * In this case everything works as usual
     */
    return this.props.hubHandlerId === item.uuid ? true : false
  }

  handleKey(item: SingleObject) {
    if (item?.selectedInstanceId) return `${item.uuid}-${item.selectedInstanceId}`
    return item?.uuid
  }

  handleInstances(item: SingleObject) {
    if (item.type === "instance") {
      // must keep parent id to send to pageTree when it needs to expand the instance parents
      const parentId = item.parentId // TODO: needed?
      const symbolObj = handleFilterSymbolObject(item, this.props.pageResponse.symbols, this.props.symbolObj)
      /**
       * On instance mode we send an object with the overrides
       * Each bob reads the override, and the EM can easly control it here
       */
      if (this.props.symbolContentType === "instance") {
        // const clonedSymbolObj = cloneDeep(symbolObj)
        // const overridedSymbolObj = Symbols.handleInstanceOverrides(item, undefined, clonedSymbolObj)
        // return { ...overridedSymbolObj, parentId: parentId }
        return { item: { ...symbolObj, parentId: parentId }, overrides: item.content.overrides?.[symbolObj.uuid] }
      } else {
        return { item: { ...symbolObj, parentId: parentId }, overrides: undefined }
      }
    }
    return { item, overrides: undefined }
  }

  //Experimental, needs better solution
  isConditionalHidden(item: SingleObject, overrides?: ObjectContentOverride) {
    if (item.isContentBlockChild && item.hiddenConditionField) {
      const Posts = overrides?.formula?.posts ?? item.content.formula?.posts
      //No posts we dont hide anything
      if (!Posts) return false
      //we get the first post, since we only inject 1 post when dealing with contentblock
      const postField = JSONpath.query(Posts[0], item.hiddenConditionField)
      //we dont have the required field to show
      if (postField.length <= 0) return true
    }
    return false
  }

  render() {
    const itemComponent = this.props.component
    const pageResponse = this.props.pageResponse
    let { item, overrides } = this.handleInstances(itemComponent)
    /**
     * Hide component with eye icon, and hide by missing post field
     */
    if (item.hidden || this.isConditionalHidden(item, overrides)) {
      return null
    }
    /**
     * add selectedInstanceId to symbol root object childs
     *
     * selectedInstanceId is wirtten to the symbol root object in handleFilterSymbolObject
     * wich will be passed down it's childs
     */
    if (this.props.selectedInstanceId) item.selectedInstanceId = this.props.selectedInstanceId
    const componentRule = pageResponse.components[item.styles.component]
    switch (componentRule.name) {
      case component.SOCIAL_CARD_SHARE:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <SocialCardShare
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.TABS_CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
                noContainer={true}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <TabsContainer
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                      shouldWriteRoute={true}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.TABS_CONTAINER_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
                noContainer={true}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <TabsContainer
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                      shouldWriteRoute={false}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.TABS_CONTAINER_DROPDOWN:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
                noContainer={true}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <TabsContainerDropdown
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.TABS_CONTENT_VERTICAL:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <TabsContentVertical
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                      tabName={this.props.tabName}
                      tabLabel={this.props.tabLabel}
                      tabIcon={this.props.tabIcon}
                      tabEventKey={this.handleKey(this.props.tabEventKey)}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.TABS_CONTENT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <TabsContent
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                      tabName={this.props.tabName}
                      tabLabel={this.props.tabLabel}
                      tabIcon={this.props.tabIcon}
                      tabEventKey={this.handleKey(this.props.tabEventKey)}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.TABS_CONTENT_VERTICAL_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <TabsContentVerticalV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                      tabName={this.props.tabName}
                      tabLabel={this.props.tabLabel}
                      tabIcon={this.props.tabIcon}
                      tabEventKey={this.handleKey(this.props.tabEventKey)}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.TABS_CONTENT_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <TabsContentV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                      tabName={this.props.tabName}
                      tabLabel={this.props.tabLabel}
                      tabIcon={this.props.tabIcon}
                      tabEventKey={this.handleKey(this.props.tabEventKey)}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.CARD_MODAL:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <CardModal
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.CARD_LEFT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <CardLeft
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.CARD_LEFT_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <CardLeftV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.CARD_TOP:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <CardTop
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.CARD_RIGHT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <CardRight
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.CARD_RIGHT_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <CardRightV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.CARD_EXPAND_CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <CardExpandContainer
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.EXPAND_CONTENT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <ExpandContent
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      itemIsActive={this.props.itemIsActive}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.EXPAND_CONTENT_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <ExpandContentV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      itemIsActive={this.props.itemIsActive}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.GRID_CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <GridContainer
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.GRID_CONTAINER_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <GridContainerV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <CustomCssHandler obj={item}>
              <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                {(contentItem: SingleObject) => (
                  <Container
                    pageResponse={pageResponse}
                    obj={contentItem}
                    overrides={overrides}
                    replacedMediaList={this.props.replacedMediaList}
                    editing={this.handleLogicForEditState(contentItem)}
                    matchOrder={this.props.matchOrder}
                    expManager={this.props.expManager}
                    selectedInstanceId={this.props.selectedInstanceId}
                    form={this.props.form}
                  />
                )}
              </ContentFetch>
            </CustomCssHandler>
          </ErrorBoundary>
        )
      case component.MEDIAV2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <CustomCssHandler obj={item}>
              <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                {(contentItem: SingleObject) => (
                  <MediaV2
                    pageResponse={pageResponse}
                    obj={contentItem}
                    overrides={overrides}
                    replacedMediaList={this.props.replacedMediaList}
                    editing={this.handleLogicForEditState(contentItem)}
                    matchOrder={this.props.matchOrder}
                    expManager={this.props.expManager}
                    selectedInstanceId={this.props.selectedInstanceId}
                  />
                )}
              </ContentFetch>
            </CustomCssHandler>
          </ErrorBoundary>
        )
      case component.TEXT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <CustomCssHandler obj={item}>
              <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                {(contentItem: SingleObject) => (
                  <Text
                    pageResponse={pageResponse}
                    obj={contentItem}
                    overrides={overrides}
                    replacedMediaList={this.props.replacedMediaList}
                    editing={this.handleLogicForEditState(contentItem)}
                    matchOrder={this.props.matchOrder}
                    expManager={this.props.expManager}
                    selectedInstanceId={this.props.selectedInstanceId}
                    form={this.props.form}
                  />
                )}
              </ContentFetch>
            </CustomCssHandler>
          </ErrorBoundary>
        )
      case component.GRID:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <CustomCssHandler obj={item}>
              <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                {(contentItem: SingleObject) => (
                  <Grid
                    pageResponse={pageResponse}
                    obj={contentItem}
                    overrides={overrides}
                    replacedMediaList={this.props.replacedMediaList}
                    editing={this.handleLogicForEditState(contentItem)}
                    matchOrder={this.props.matchOrder}
                    expManager={this.props.expManager}
                    selectedInstanceId={this.props.selectedInstanceId}
                  />
                )}
              </ContentFetch>
            </CustomCssHandler>
          </ErrorBoundary>
        )
      case component.FORM:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <CustomCssHandler obj={item}>
              <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                {(contentItem: SingleObject) => (
                  <Form
                    obj={contentItem}
                    overrides={overrides}
                    pageResponse={pageResponse}
                    replacedMediaList={this.props.replacedMediaList}
                    selectedInstanceId={item.selectedInstanceId}
                    matchOrder={this.props.matchOrder}
                    expManager={this.props.expManager}
                    editing={this.handleLogicForEditState(contentItem)}
                  />
                )}
              </ContentFetch>
            </CustomCssHandler>
          </ErrorBoundary>
        )
      case component.INPUT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <CustomCssHandler obj={item}>
              <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                {(contentItem: SingleObject) => (
                  <FormInput
                    obj={contentItem}
                    overrides={overrides}
                    pageResponse={pageResponse}
                    replacedMediaList={this.props.replacedMediaList}
                    selectedInstanceId={item.selectedInstanceId}
                    matchOrder={this.props.matchOrder}
                    expManager={this.props.expManager}
                    editing={this.handleLogicForEditState(contentItem)}
                    form={this.props.form}
                  />
                )}
              </ContentFetch>
            </CustomCssHandler>
          </ErrorBoundary>
        )
      case component.SUBMIT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <CustomCssHandler obj={item}>
              <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                {(contentItem: SingleObject) => (
                  <FormSubmit
                    obj={contentItem}
                    overrides={overrides}
                    pageResponse={pageResponse}
                    replacedMediaList={this.props.replacedMediaList}
                    selectedInstanceId={item.selectedInstanceId}
                    matchOrder={this.props.matchOrder}
                    expManager={this.props.expManager}
                    editing={this.handleLogicForEditState(contentItem)}
                    form={this.props.form}
                  />
                )}
              </ContentFetch>
            </CustomCssHandler>
          </ErrorBoundary>
        )
      case component.SLIDER_CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <SliderContainer
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.SLIDER_CONTAINER_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <SliderContainerV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.SLIDER_CONTAINER_V3:
        if (item.hidden) {
          return null
        }
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <SliderContainerV3
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.EMBED_CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <EmbedContainer
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.EMBED_CONTAINER_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <EmbedContainerV2
              obj={item}
              overrides={overrides}
              pageResponse={pageResponse}
              replacedMediaList={this.props.replacedMediaList}
              position={this.props.position}
              componentRule={componentRule}
              selectedInstanceId={item.selectedInstanceId}
              matchOrder={this.props.matchOrder}
              expManager={this.props.expManager}
            />
          </ErrorBoundary>
        )
      case component.MEDIA:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <Media
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.TEXT_BLOCK:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <TextBlock
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.BUTTON:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <Button
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.BUTTON_RIGHT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <ButtonRight
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.NAVBARCOMPONENTV2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <NavbarV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.NAVBARCOMPONENT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <NavbarComponent
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.NAVBARTOP:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <NavbarTop
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.MENUCOMPONENT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <MenuComponent
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.MENUCOMPONENT_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <MenuComponentV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.MENUTOGGLECOMPONENT:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <MenuToggle
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.MENUTOGGLECOMPONENTV2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <MenuToggleV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.DROPDOWN:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <Dropdown
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.DROPDOWN_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <DropdownV2
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.MODAL:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
                noContainer={true}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <Modal
                      onRef={(ref: any) => (this.childRef = ref)}
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.MODAL_V2:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
                noContainer={true}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <ModalV2
                      onRef={(ref: any) => (this.childRef = ref)}
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.MODAL_V3:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
                noContainer={true}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <ModalV3
                      onRef={(ref: any) => (this.childRef = ref)}
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.MODAL_V4:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
                noContainer={true}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <ModalV4
                      onRef={(ref: any) => (this.childRef = ref)}
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.SHOWCASE:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <Showcase
                      onRef={(ref: any) => (this.childRef = ref)}
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.SOCIAL_CARD_EMBED:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <SocialCardEmbed
                      onRef={(ref: any) => (this.childRef = ref)}
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      selectedInstanceId={item.selectedInstanceId}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.SOCIAL_MEDIA_EMBED:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <SocialMediaEmbed
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      componentRule={componentRule}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.IMAGE_COMPARATION_SLIDER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <ImageComparationSlider
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      replacedMediaList={this.props.replacedMediaList}
                      position={this.props.position}
                      matchOrder={this.props.matchOrder}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.FORM_CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                  {(contentItem: SingleObject) => (
                    <FormContainer
                      obj={contentItem}
                      overrides={overrides}
                      pageResponse={pageResponse}
                      position={this.props.position}
                      componentRule={componentRule}
                      expManager={this.props.expManager}
                    />
                  )}
                </ContentFetch>
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.RESULTS_MAP:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ResultsMap
                  obj={item}
                  overrides={overrides}
                  pageResponse={pageResponse}
                  replacedMediaList={this.props.replacedMediaList}
                  position={this.props.position}
                  componentRule={componentRule}
                  matchOrder={this.props.matchOrder}
                  expManager={this.props.expManager}
                />
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.DATASET_CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <DataSetContainer
                  obj={item}
                  pageResponse={pageResponse}
                  replacedMediaList={this.props.replacedMediaList}
                  position={this.props.position}
                  matchOrder={this.props.matchOrder}
                  expManager={this.props.expManager}
                />
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.RESULTS_LIST_CONTAINER:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <ResultsListContainer
                  obj={item}
                  pageResponse={pageResponse}
                  replacedMediaList={this.props.replacedMediaList}
                  position={this.props.position}
                  matchOrder={this.props.matchOrder}
                  expManager={this.props.expManager}
                />
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      case component.FILTERS:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ComponentBoilerplate
                obj={item}
                pageResponse={pageResponse}
                overrides={overrides}
                position={this.props.position}
                expManager={this.props.expManager}
              >
                <DataSetFilter
                  obj={item}
                  overrides={overrides}
                  pageResponse={pageResponse}
                  replacedMediaList={this.props.replacedMediaList}
                  position={this.props.position}
                  componentRule={componentRule}
                  matchOrder={this.props.matchOrder}
                  expManager={this.props.expManager}
                />
              </ComponentBoilerplate>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
      // case component.GRID:
      //   return (
      //     <ErrorBoundary>
      //       <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
      //         {(contentItem: SingleObject) => (
      //           <Grid
      //             pageResponse={pageResponse}
      //             obj={contentItem}
      //             overrides={overrides}
      //             replacedMediaList={this.props.replacedMediaList}
      //             editing={this.handleLogicForEditState(contentItem)}
      //             matchOrder={this.props.matchOrder}
      //             expManager={this.props.expManager}
      //             selectedInstanceId={item.selectedInstanceId}
      //           />
      //         )}
      //       </ContentFetch>
      //     </ErrorBoundary>
      //   )
      // case component.TEXT:
      //   return (
      //     <ErrorBoundary>
      //       <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
      //         {(contentItem: SingleObject) => (
      //           <Text
      //             pageResponse={pageResponse}
      //             obj={item}
      //             overrides={overrides}
      //             replacedMediaList={this.props.replacedMediaList}
      //             editing={this.handleLogicForEditState(contentItem)}
      //             matchOrder={this.props.matchOrder}
      //             expManager={this.props.expManager}
      //             selectedInstanceId={item.selectedInstanceId}
      //           />
      //         )}
      //       </ContentFetch>
      //     </ErrorBoundary>
      //   )
      // case component.MEDIAV2:
      //   return (
      //     <ErrorBoundary expManager={this.props.expManager}>
      //       <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
      //         {(contentItem: SingleObject) => (
      //           <MediaV2
      //             obj={contentItem}
      //             overrides={overrides}
      //             pageResponse={pageResponse}
      //             replacedMediaList={this.props.replacedMediaList}
      //             selectedInstanceId={item.selectedInstanceId}
      //             matchOrder={this.props.matchOrder}
      //             expManager={this.props.expManager}
      //             editing={this.handleLogicForEditState(contentItem)}
      //           />
      //         )}
      //       </ContentFetch>
      //     </ErrorBoundary>
      //   )
      default:
        return (
          <ErrorBoundary expManager={this.props.expManager}>
            <ComponentSelectorEM
              uuid={item.uuid}
              expManager={this.props.expManager}
              obj={item}
              editing={this.handleLogicForEditState(item)}
            >
              <ContentFetch obj={item} overrides={overrides} expManager={this.props.expManager}>
                {(contentItem: SingleObject) => (
                  <InvalidComponent
                    id={item.uuid}
                    obj={item}
                    componentRule={componentRule}
                    expManager={this.props.expManager}
                  />
                )}
              </ContentFetch>
            </ComponentSelectorEM>
          </ErrorBoundary>
        )
    }
  }
}

function mapStateToProps(state: any) {
  return {
    hubHandlerId: state.experienceManagerReducer.server.hubHandlerId || null,
    selectedSymbolInstanceId: state.symbolsReducer.server.selectedSymbolInstanceId || null,
    symbolObj: state.pageTreeReducer.server.symbolObj || null,
    symbolContentType: state.symbolsReducer.server.symbolContentType || null,
    replacedMediaList: state.mediaLibraryReducer.replacedMediaList,
  }
}

export default connect(mapStateToProps)(ComponentHandler)
