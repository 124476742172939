import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  string as stringC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { Colors, ColorsOpt, StylesColors, mergeBob2 as mergeBob2Colors, mergeBob3 as mergeBob3Colors} from "./colorsStyle"
import { get2WithNull } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */

// props only, no enable
const BackgroundImagePropsCodec = type({
  position: stringC,
  repeat: stringC,
  size: stringC,
  src: stringC,
})
const BackgroundImageCodec = intersection([type({ enable: booleanC }), BackgroundImagePropsCodec])

// props only, no enable
const BackgroundImagePropsOptCodec = partial({
  position: nullable(stringC),
  repeat: nullable(stringC),
  size: nullable(stringC),
  src: nullable(stringC),
})
const BackgroundImageOptCodec = intersection([partial({ enable: nullable(booleanC) }), BackgroundImagePropsOptCodec])

const StylesBackgroundImageCodec = intersection([
  type({ image: BackgroundImageCodec }),
  partial({
    behaviour: partial({
      active: partial({
        image: BackgroundImageOptCodec,
      }),
      hover: partial({
        image: BackgroundImageOptCodec,
      }),
    }),
    mobile: partial({
      image: BackgroundImageOptCodec,
      behaviour: partial({
        active: partial({
          image: BackgroundImageOptCodec,
        }),
        hover: partial({
          image: BackgroundImageOptCodec,
        }),
      }),
    }),
    tablet: partial({
      image: BackgroundImageOptCodec,
      behaviour: partial({
        active: partial({
          image: BackgroundImageOptCodec,
        }),
        hover: partial({
          image: BackgroundImageOptCodec,
        }),
      }),
    }),
  }),
])

// const GSBackgroundImageCodec = intersection([
//   BackgroundImagePropsCodec,
//   partial({
//     behaviour: partial({
//       active: BackgroundImagePropsOptCodec,
//       hover: BackgroundImagePropsOptCodec,
//     }),
//     mobile: intersection([
//       BackgroundImagePropsOptCodec,
//       partial({
//         behaviour: partial({
//           active: BackgroundImagePropsOptCodec,
//           hover: BackgroundImagePropsOptCodec,
//         }),
//       }),
//     ]),
//     tablet: intersection([
//       BackgroundImagePropsOptCodec,
//       partial({
//         behaviour: partial({
//           active: BackgroundImagePropsOptCodec,
//           hover: BackgroundImagePropsOptCodec,
//         }),
//       }),
//     ]),
//   }),
// ])

type BackgroundImage = TypeOf<typeof BackgroundImageCodec>
type BackgroundImageProps = TypeOf<typeof BackgroundImagePropsCodec>
type BackgroundImageOpt = TypeOf<typeof BackgroundImageOptCodec>
type BackgroundImagePropsOpt = TypeOf<typeof BackgroundImagePropsOptCodec>
type StylesBackgroundImage = TypeOf<typeof StylesBackgroundImageCodec>
// type GSBackgroundImage = TypeOf<typeof GSBackgroundImageCodec>
type BackgroundImageCSS =
  | {
      "background-size": string
      "background-repeat": string
      "background-position": string
      "background-image": string
    }
  | { "background-image": string }

export function responsiveStyle(imageObj: any, desktopDefaultValues?: any, breakpointDefaultValues?: any) {
  const hasEnable = imageObj.hasOwnProperty("enable")
  if (hasEnable && !objValueExists(imageObj, "enable")) return ""

  /**
   * Check if property is available, and fallback to breakpointDefault or the desktopDefault
   */
  const position = imageObj?.position || breakpointDefaultValues?.position || desktopDefaultValues?.position
  const repeat = imageObj?.repeat || breakpointDefaultValues?.repeat || desktopDefaultValues?.repeat
  const size = imageObj?.size || breakpointDefaultValues?.size || desktopDefaultValues?.size
  const src = imageObj?.src || breakpointDefaultValues?.src || desktopDefaultValues?.src

  if (hasEnable && !imageObj.enable)
    return {
      "background-image": "none !important",
    }

  //TODO: handle gradient color
  return {
    "background-size": `${position} !important`,
    "background-repeat": `${repeat} !important`,
    "background-position": `${size} !important`,
    "background-image": `url(${src}) !important`,
  }
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  imageSrc: string,
  foundationStyle: FColor | undefined = undefined // TODO: fix when working on other bobs
): BackgroundImageCSS | "" {
  const styles = StylesBackgroundImageCodec.decode(stylesObj)
  // TODO: should use codec from colors?
  if (isRight(styles)) return cssRender(styles.right, stylesObj, breakpoint, behaviourState, imageSrc, foundationStyle)
  console.warn(decoderErrors(styles))
  return {
    "background-position": "0% 0%",
    "background-repeat": "repeat",
    "background-size": "contain",
    "background-image": "transparent",
  }
}

// export function globalStyleCssRenderUnsafe(
//   gsObj: any,
//   breakpoint: Breakpoint,
//   behaviourState: BehaviourState_,
//   foundationStyle: FColor | undefined = undefined // TODO: Work on when foundation is added to global styles
// ): BackgroundImageCSS | "" {
//   const gs = GSBackgroundImageCodec.decode(gsObj)
//   if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, foundationStyle)
//   console.warn(decoderErrors(gs))
//   return ""
// }

export function cssRender(
  stylesObj: StylesBackgroundImage,
  colorsStyles: StylesColors,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  imageSrc: string,
  foundationStyle: FColor | undefined
): BackgroundImageCSS {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.image, colorsStyles.colors, imageSrc, foundationStyle)
    }
    //hover | active
    else {
      return renderBobOpt(
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.image, stylesObj.image),
        mergeBob2Colors(colorsStyles?.behaviour?.[behaviourState]?.colors, colorsStyles.colors),
        imageSrc,
        foundationStyle
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(
        mergeBob2(stylesObj?.[breakpoint]?.image, stylesObj.image),
        mergeBob2Colors(colorsStyles?.[breakpoint]?.colors, colorsStyles.colors),
        imageSrc,
        foundationStyle
      )
    }
    //hover | active
    else {
      return renderBobOpt(
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.image,
          stylesObj?.behaviour?.[behaviourState]?.image,
          stylesObj?.[breakpoint]?.image,
          stylesObj.image
        ),
        mergeBob3Colors(
          colorsStyles?.[breakpoint]?.behaviour?.[behaviourState]?.colors,
          colorsStyles?.behaviour?.[behaviourState]?.colors,
          colorsStyles?.[breakpoint]?.colors,
          colorsStyles.colors
        ),
        imageSrc,
        foundationStyle
      )
    }
  }
}

// export function globalStyleCssRender(
//   stylesObj: GSBackgroundImage,
//   breakpoint: Breakpoint,
//   behaviourState: BehaviourState_,
//   foundationStyle: FColor | undefined
// ): BackgroundImageCSS {
//   if (breakpoint === "desktop") {
//     if (behaviourState === "default") {
//       return render(stylesObj, foundationStyle)
//     }
//     //hover | active
//     else {
//       return render(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj), foundationStyle)
//     }
//   }
//   //tablet | mobile
//   else {
//     if (behaviourState === "default") {
//       return render(merge2(stylesObj?.[breakpoint], stylesObj), foundationStyle)
//     }
//     //hover | active
//     else {
//       return render(
//         merge3(
//           stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
//           stylesObj.behaviour?.[behaviourState],
//           stylesObj?.[breakpoint],
//           stylesObj
//         ),
//         foundationStyle
//       )
//     }
//   }
// }

export function renderBob(
  imageObj: BackgroundImage,
  colorsObj: Colors,
  imageSrc: string,
  foundationStyle: FColor | undefined
): BackgroundImageCSS {
  if (!imageObj.enable && !colorsObj.isGradient)
    return {
      "background-image": `none !important`,
    }

  return render(imageObj, colorsObj, imageSrc, foundationStyle)
}

export function renderBobOpt(
  imageObj: BackgroundImage,
  colorsObj: ColorsOpt,
  imageSrc: string,
  foundationStyle: FColor | undefined
): BackgroundImageCSS {
  if (!imageObj.enable && !colorsObj.isGradient)
    return {
      "background-image": `none !important`,
    }

  return renderOpt(imageObj, colorsObj, imageSrc, foundationStyle)
}

export function render(
  imageObj: BackgroundImage,
  colorsObj: Colors,
  imageSrc: string,
  foundationStyle: FColor | undefined
): BackgroundImageCSS {
  return {
    "background-size": `${imageObj.size} !important`,
    "background-repeat": `${imageObj.repeat} !important`,
    "background-position": `${imageObj.position} !important`,
    "background-image": handleBackgroundImage(imageObj, colorsObj, imageSrc),
  }
}

export function renderOpt(
  imageObj: BackgroundImage,
  colorsObj: ColorsOpt,
  imageSrc: string,
  foundationStyle: FColor | undefined
): BackgroundImageCSS {
  return {
    "background-size": `${imageObj.size} !important`,
    "background-repeat": `${imageObj.repeat} !important`,
    "background-position": `${imageObj.position} !important`,
    "background-image": handleBackgroundImageOpt(imageObj, colorsObj, imageSrc),
  }
}

export function handleBackgroundImage(imageObj: BackgroundImage, colorsObj: Colors, imageSrc: string): string {
  // image and gradient are enabled
  if (imageObj.enable && imageSrc && imageSrc !== "" && colorsObj.isGradient) {
    return `
        linear-gradient(
        ${colorsObj.gradientAngle ? colorsObj.gradientAngle : 0}deg,           
        ${colorsObj.colorFirst}, 
        ${colorsObj.colorSecond})
        ${`, url(${imageSrc})`} !important
      `
  }
  // only gradient is enabled
  else if ((!imageObj.enable || (!imageSrc && imageSrc === "")) && colorsObj.isGradient) {
    return `
        linear-gradient(
        ${colorsObj.gradientAngle ? colorsObj.gradientAngle : 0}deg,           
        ${colorsObj.colorFirst}, 
        ${colorsObj.colorSecond}) !important
      `
  }
  // only image is enabled
  return imageSrc && imageSrc !== "" ? `url(${imageSrc}) !important` : `url() !important`
}

export function handleBackgroundImageOpt(imageObj: BackgroundImage, colorsObj: ColorsOpt, imageSrc: string): string {
  // image and gradient are enabled
  if (imageObj.enable && imageSrc && imageSrc !== "" && colorsObj.isGradient) {
    return `
        linear-gradient(
        ${colorsObj.gradientAngle ? colorsObj.gradientAngle : 0}deg,           
        ${colorsObj.colorFirst}, 
        ${colorsObj.colorSecond})
        ${`, url(${imageSrc})`} !important
      `
  }
  // only gradient is enabled
  else if ((!imageObj.enable || (!imageSrc && imageSrc === "")) && colorsObj.isGradient) {
    return `
        linear-gradient(
        ${colorsObj.gradientAngle ? colorsObj.gradientAngle : 0}deg,           
        ${colorsObj.colorFirst}, 
        ${colorsObj.colorSecond}) !important
      `
  }
  // only image is enabled
  return imageSrc && imageSrc !== "" ? `url(${imageSrc}) !important` : `url() !important`
}

/**
 *
 * @param imageObj
 * @param defaultBackgroundImageObj
 * @returns BackgroundImage
 *
 */
export function mergeBob2(
  imageObj: BackgroundImageOpt | undefined,
  defaultBackgroundImageObj: BackgroundImage
): BackgroundImage {
  const enable = get2WithNull(imageObj?.enable, defaultBackgroundImageObj.enable)
  const position = get2WithNull(imageObj?.position, defaultBackgroundImageObj.position)
  const repeat = get2WithNull(imageObj?.repeat, defaultBackgroundImageObj.repeat)
  const size = get2WithNull(imageObj?.size, defaultBackgroundImageObj.size)
  const src = get2WithNull(imageObj?.src, defaultBackgroundImageObj.src)

  return {
    enable,
    position,
    repeat,
    size,
    src,
  }
}

/**
 *
 * @param imageObj
 * @param defaultBackgroundImageObj
 * @returns BackgroundImageProps
 *
 */
export function merge2(
  imageObj: BackgroundImagePropsOpt | undefined,
  defaultBackgroundImageObj: BackgroundImageProps
): BackgroundImageProps {
  const position = get2WithNull(imageObj?.position, defaultBackgroundImageObj.position)
  const repeat = get2WithNull(imageObj?.repeat, defaultBackgroundImageObj.repeat)
  const size = get2WithNull(imageObj?.size, defaultBackgroundImageObj.size)
  const src = get2WithNull(imageObj?.src, defaultBackgroundImageObj.src)

  return {
    position,
    repeat,
    size,
    src,
  }
}

/**
 *
 * @param imageObj
 * @param imageDefaultBreakpoint
 * @param defaultBackgroundImageObj
 * @returns BackgroundImage
 */
export function mergeBob3(
  imageObj: BackgroundImageOpt | undefined,
  imageDesktopBehaviour: BackgroundImageOpt | undefined,
  imageDefaultBreakpoint: BackgroundImageOpt | undefined,
  defaultBackgroundImageObj: BackgroundImage
): BackgroundImage {
  const enable =
    imageObj?.enable ??
    imageDesktopBehaviour?.enable ??
    imageDefaultBreakpoint?.enable ??
    defaultBackgroundImageObj.enable
  const position =
    imageObj?.position ??
    imageDesktopBehaviour?.position ??
    imageDefaultBreakpoint?.position ??
    defaultBackgroundImageObj.position
  const repeat =
    imageObj?.repeat ??
    imageDesktopBehaviour?.repeat ??
    imageDefaultBreakpoint?.repeat ??
    defaultBackgroundImageObj.repeat
  const size =
    imageObj?.size ?? imageDesktopBehaviour?.size ?? imageDefaultBreakpoint?.size ?? defaultBackgroundImageObj.size
  const src =
    imageObj?.src ?? imageDesktopBehaviour?.src ?? imageDefaultBreakpoint?.src ?? defaultBackgroundImageObj.src

  return {
    enable,
    position,
    repeat,
    size,
    src,
  }
}

/**
 *
 * @param imageObj
 * @param imageDefaultBreakpoint
 * @param defaultBackgroundImageObj
 * @returns BackgroundImage
 */
export function merge3(
  imageObj: BackgroundImagePropsOpt | undefined,
  imageDesktopBehaviour: BackgroundImagePropsOpt | undefined,
  imageDefaultBreakpoint: BackgroundImagePropsOpt | undefined,
  defaultBackgroundImageObj: BackgroundImageProps
): BackgroundImageProps {
  const position =
    imageObj?.position ??
    imageDesktopBehaviour?.position ??
    imageDefaultBreakpoint?.position ??
    defaultBackgroundImageObj.position
  const repeat =
    imageObj?.repeat ??
    imageDesktopBehaviour?.repeat ??
    imageDefaultBreakpoint?.repeat ??
    defaultBackgroundImageObj.repeat
  const size =
    imageObj?.size ?? imageDesktopBehaviour?.size ?? imageDefaultBreakpoint?.size ?? defaultBackgroundImageObj.size
  const src =
    imageObj?.src ?? imageDesktopBehaviour?.src ?? imageDefaultBreakpoint?.src ?? defaultBackgroundImageObj.src

  return {
    position,
    repeat,
    size,
    src,
  }
}

  //Fallback when not declared
  // export function get2<A>(x: A | undefined, y: A): A {
  //   return typeof x === "undefined" ? y : x
  // }

  // GSBackgroundImage
  export type {
    BackgroundImageCSS,
    StylesBackgroundImage,
    BackgroundImageProps,
    BackgroundImagePropsOpt,
    BackgroundImage,
    BackgroundImageOpt,
  }

