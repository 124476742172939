import { connect } from "react-redux"
import { ApplicationState } from "../../../../store/ApplicationState"
import { useMemo } from "react"
import React from "react"

interface OwnProps {
  id: string
  label: string
  realElementTop: number
  displayBoundaries: string
  MarginTop: JSX.Element
  MarginBottom: JSX.Element
  MarginLeft: JSX.Element
  MarginRight: JSX.Element
  PaddingTop: JSX.Element
  PaddingBottom: JSX.Element
  PaddingLeft: JSX.Element
  marginLeft: number
  marginRight: number
  Template: {
    padding: {
      top: number
      bottom: number
      left: number
      right: number
    }
    margin: {
      top: number | "auto"
      bottom: number | "auto"
      left: number | "auto"
      right: number | "auto"
    }
  }
}

interface ReduxProps {
  objectSelectorHover: ApplicationState["publicPageReducer"]["objectSelectorHover"]
}

type CSEMv2ElementProps = OwnProps & ReduxProps

function CSEMv2Element({
  id,
  displayBoundaries,
  MarginTop,
  MarginBottom,
  MarginLeft,
  MarginRight,
  PaddingBottom,
  PaddingLeft,
  PaddingTop,
  marginLeft,
  marginRight,
  Template,
  label,
  realElementTop,
  objectSelectorHover,
}: CSEMv2ElementProps) {
  /**
   * handle validate visiblity
   * based on objt uuid or instance uuid
   */
  const isVisible = useMemo(() => {
    return objectSelectorHover === id
  }, [id, objectSelectorHover])

  if (isVisible) {
    return (
      <div className='element-hover' style={{ display: displayBoundaries }}>
        <div className='property'>
          {MarginTop}
          {MarginBottom}
          {MarginLeft}
          {MarginRight}
          <div
            className='block'
            style={{
              top: "0",
              right: `0`,
              bottom: "0",
              left: `0`,
              width: `calc(100% - ${marginLeft + marginRight})`,
              height: "100%",
            }}
          >
            <div className='property'>
              {PaddingTop}
              {PaddingBottom}
              {PaddingLeft}
              <div
                className='padding right'
                style={{
                  width: `${Template.padding.right}px`,
                  height: "100%",
                }}
              />
              <div
                className='block'
                style={{
                  top: `${Template.padding.top}px`,
                  right: `${Template.padding.right}px`,
                  bottom: `${Template.padding.bottom}px`,
                  left: `${Template.padding.left}px`,
                  width: `calc(100% - ${Template.padding.right + Template.padding.left}px)`,
                  height: `calc(100% - ${Template.padding.bottom + Template.padding.top}px)`,
                }}
              >
                <div className='property'>
                  <div className='content'>
                    <span
                      style={{
                        top: `${
                          realElementTop + Template.padding.top < 22
                            ? `-${realElementTop + Template.padding.top}px`
                            : "-22px"
                        }`,
                      }}
                      className='object-label'
                    >
                      {label}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <></>
}

const areEqual = (prevProps: CSEMv2ElementProps, nextProps: CSEMv2ElementProps) => {
  if (prevProps.id === prevProps.objectSelectorHover && nextProps.id !== nextProps.objectSelectorHover) {
    // Previous props had a match, but next props don't, so re-render to remove the class
    return false
  }

  if (prevProps.id !== prevProps.objectSelectorHover && nextProps.id === nextProps.objectSelectorHover) {
    // Previous props didn't have a match, but next props do, so re-render to add the class
    return false
  }
  if (prevProps.id !== nextProps.id) return false
  if (prevProps.displayBoundaries !== nextProps.displayBoundaries) return false
  if (prevProps.MarginTop !== nextProps.MarginTop) return false
  if (prevProps.MarginBottom !== nextProps.MarginBottom) return false
  if (prevProps.MarginLeft !== nextProps.MarginLeft) return false
  if (prevProps.MarginRight !== nextProps.MarginRight) return false
  if (prevProps.PaddingBottom !== nextProps.PaddingBottom) return false
  if (prevProps.PaddingLeft !== nextProps.PaddingLeft) return false
  if (prevProps.PaddingTop !== nextProps.PaddingTop) return false
  if (prevProps.marginLeft !== nextProps.marginLeft) return false
  if (prevProps.marginRight !== nextProps.marginRight) return false
  if (prevProps.Template !== nextProps.Template) return false
  if (prevProps.label !== nextProps.label) return false
  if (prevProps.realElementTop !== nextProps.realElementTop) return false
  return true
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    objectSelectorHover: state.publicPageReducer.objectSelectorHover,
  }
}

export default connect(mapStateToProps)(React.memo(CSEMv2Element, areEqual))
