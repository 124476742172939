import React from "react"
import ComponentHandler from "../../../../componentHandler"
import WarningDefaultSystem from "../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import {
  getContent,
  getStringField,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { NavbarV2Props } from "."
import navbarV2Styles from "../../../../../../stylesheets/modules/templates/navbarComponent_v2.module.sass"
import {
  BobBackground,
  isBobBackground,
} from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const NavbarComponentV2: React.FC<NavbarV2Props> = (props) => {
  const renderChildComponents = (background2: BobBackground) => {
    const componentsId = props.obj.children
    if (componentsId && componentsId.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return componentsId.map((componentId: string, idx: any) => {
        const handledItem = handleObjectType(props.obj, componentId, props.pageResponse, props.expManager.emEditorType)
        const columns = handleColumns(
          idx,
          counter,
          background2,
          props.pageResponse.globalStyles[background2.globalStyleId]
        )
        counter = columns.counter

        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <ComponentHandler
              position={idx}
              selectedInstanceId={props.obj.selectedInstanceId}
              component={handledItem}
              pageResponse={props.pageResponse}
              matchOrder={props.matchOrder}
              expManager={props.expManager}
            />
          </div>
        )
      })
    } else {
      if (props.expManager.enable) {
        let counter = { desktop: 0, tablet: 0, mobile: 0 }
        let columns = handleColumns(1, counter, background2, props.pageResponse.globalStyles[background2.globalStyleId])
        return (
          <WarningDefaultSystem>
            <div
              key={1}
              className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            >
              <div style={{ height: `30px`, border: "3px dashed #dbe0e7" }}></div>
            </div>
          </WarningDefaultSystem>
        )
      }
    }
  }

  const renderMenu = () => {
    const background2 = isBobBackground(props.obj.styles.bobs.background2)
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background2)
    return (
      <BobComponentHandler
        className={`navbar-items-background ${navbarV2Styles["navbar-items-background"]}`}
        position={props.position}
        pageResponse={props.pageResponse}
        instanceId={props.obj.selectedInstanceId}
        object={props.obj}
        objectId={props.obj.uuid}
        inlineContent={props.obj.content.inlineContent}
        field={"background2"}
        rules={props.componentRule}
        template={props.obj.styles}
        expManager={props.expManager}
      >
        <AlignmentHOC
          className={`row ${responsiveGuttersClass(
            background2,
            props.pageResponse.globalStyles[background2.globalStyleId]
          )}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {renderChildComponents(background2)}
        </AlignmentHOC>
      </BobComponentHandler>
    )
  }

  const renderIdentity = () => {
    const content = getContent(props.obj, "posts")
    if (content && content.length > 0) {
      const post = content[0]
      return (
        <div className='d-inline-flex'>
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            replacedMediaList={props.replacedMediaList}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field='media1'
            template={props.obj.styles}
            rules={props.componentRule}
            expManager={props.expManager}
            network={post.network}
            post={post}
          />
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field='text1'
            post={post}
            tag='p'
            value={getStringField(post, props.obj, props.pageResponse, "text1")}
            template={props.obj.styles}
            rules={props.componentRule}
            expManager={props.expManager}
            network={post.network}
            className='title text-nowrap'
          />
        </div>
      )
    }
  }

  const background1 = props.obj.styles.bobs.background1
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

  const columnsLeft = handleColumns(
    0,
    { desktop: 0, tablet: 0, mobile: 0 },
    background1,
    props.pageResponse.globalStyles[background1.globalStyleId]
  )
  const columnsRight = handleColumns(
    1,
    { desktop: 0, tablet: 0, mobile: 0 },
    background1,
    props.pageResponse.globalStyles[background1.globalStyleId]
  )
  return (
    <div
      className={`navigation ${navbarV2Styles.navigation} navbar-component_v2 ${navbarV2Styles["navbar-component_v2"]}`}
    >
      <div
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
      >
        <div
          className={`navbar-identity ${navbarV2Styles["navbar-identity"]} col-${columnsLeft.columnsMobile} col-md-${columnsLeft.columnsTablet} col-lg-${columnsLeft.columns}`}
        >
          <AlignmentHOC
            className={`navbar-identity-container ${navbarV2Styles["navbar-identity-container"]}`}
            desktop={alignmentStyles}
            tablet={tabletAlignment}
            mobile={mobileAlignment}
          >
            {renderIdentity()}
          </AlignmentHOC>
        </div>
        <div
          className={`navbar-items ${navbarV2Styles["navbar-items"]} col-${columnsRight.columnsMobile} col-md-${columnsRight.columnsTablet} col-lg-${columnsRight.columns}`}
        >
          <AlignmentHOC
            className={`navbar-items-container ${navbarV2Styles["navbar-items-container"]}`}
            desktop={alignmentStyles}
            tablet={tabletAlignment}
            mobile={mobileAlignment}
          >
            {renderMenu()}
          </AlignmentHOC>
        </div>
      </div>
    </div>
  )
}

export default NavbarComponentV2
