import { ZodBranded, ZodType, z } from "zod"

/*
    A string newtype without validation.
*/
export function schema<TAG extends string>(): ZodBranded<z.ZodString, TAG> {
    return z.string().brand<TAG>();
}

export function wrap<TAG extends string>(schema: ZodBranded<z.ZodString, TAG>, u: string): string & z.BRAND<TAG> {
    return u as string & z.BRAND<TAG>
}

export declare type TypeOf<T extends ZodType<any, any, any>> = T["_output"];
export type { TypeOf as infer };
