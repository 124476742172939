import React from 'react'
import TextInput from '../formContainer/textInput/textInput'
import SelectInput from '../formContainer/selectInput/selectInput'
import FileInput from '../formContainer/fileInput/fileInput'
import CheckboxInput from '../formContainer/checkboxInput/checkboxInput'
import * as inputTypes from '../inputTypes'
import TextAreaInput from '../formContainer/textAraeaInput/textAreaInput'
import { FormInputHandlerProps } from './interface'

const FormInputHandler: React.FC<FormInputHandlerProps> = (props) => {

  const handleProperBobField = (bobType: string) => {
    const objStyles = props.obj.styles
    const bobRule = props.pageResponse.components[objStyles.component].bob
    let properBobField = {}, component
    for (component in bobRule) {
      if (bobRule[component].name === bobType) {
        properBobField = { ...properBobField, [bobRule[component].type]: objStyles.bobs[component] }
      }
    }
    return properBobField
  }

  let inputs: any = {
    [inputTypes.TEXT]: () => {
      return (
        <TextInput
          key={props.inputObj.idx}
          position={props.position}
          obj={props.obj}
          inputObj={props.inputObj}
          componentRule={props.componentRule}
          delay={props.delay}
          error={props.formDataErrors[props.inputObj.id]}
          handleValueChange={(value: string) => props.handleInputValue({ option: props.inputObj, value })}
          bobStyle={handleProperBobField("text_input")}
          overrides={props.overrides}
          lang={props.lang}
          pageResponse={props.pageResponse}
          expManager={props.expManager}
        />
      )
    },
    [inputTypes.TEXTAREA]: () => {
      return (
        <TextAreaInput
          key={props.inputObj.idx}
          position={props.position}
          obj={props.obj}
          inputObj={props.inputObj}
          componentRule={props.componentRule}
          error={props.formDataErrors[props.inputObj.id]}
          handleValueChange={(value: string) => props.handleInputValue({ option: props.inputObj, value })}
          bobStyle={handleProperBobField("text_input")}
          overrides={props.overrides}
          lang={props.lang}
          pageResponse={props.pageResponse}
          expManager={props.expManager}
        />
      )
    },
    [inputTypes.SELECT]: () => {
      return (
        <SelectInput
          key={props.inputObj.idx}
          position={props.position}
          obj={props.obj}
          componentRule={props.componentRule}
          inputObj={props.inputObj}
          error={props.formDataErrors[props.inputObj.id]}
          handleValueChange={(value: any) => props.handleInputValue({ option: props.inputObj, value })}
          bobStyle={handleProperBobField("text_input")}
          overrides={props.overrides}
          lang={props.lang}
          pageResponse={props.pageResponse}
          expManager={props.expManager}
        />
      )
    },
    [inputTypes.FILE]: () => {
      return (
        <FileInput
          key={props.inputObj.idx}
          position={props.position}
          obj={props.obj}
          componentRule={props.componentRule}
          inputObj={props.inputObj}
          error={props.formDataErrors[props.inputObj.id]}
          handleValueChange={(value: any) => props.handleInputValue({ option: props.inputObj, value })}
          bobStyle={handleProperBobField("file_input")}
          overrides={props.overrides}
          lang={props.lang}
          pageResponse={props.pageResponse}
          expManager={props.expManager}
          formId={props.obj.styles.bobs.form1.formId}
        />
      )
    },
    [inputTypes.CHECKBOX]: () => {
      return (
        <CheckboxInput
          key={props.inputObj.idx}
          position={props.position}
          obj={props.obj}
          componentRule={props.componentRule}
          inputObj={props.inputObj}
          error={props.formDataErrors[props.inputObj.id]}
          bobText={props.obj.styles.bobs.text3}
          overrides={props.overrides}
          handleValueChange={(value: any) => props.handleInputValue({ option: props.inputObj, value })}
          bobStyle={handleProperBobField("checkbox_input")}
          lang={props.lang}
          pageResponse={props.pageResponse}
          expManager={props.expManager}
        />
      )
    },
  }
  return (inputs[props.inputObj.fieldType || props.inputObj.field])()
}

export default FormInputHandler
