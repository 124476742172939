import * as inlineContentTypes from '../../../../modules/shared-modules/experienceManager/finder/content/inlineContent/inlineContentTypes'


export function rewriteObjectOnRead(template: any, inlineContent: any, bobType: string) {
  /**
   * Check if it has inlineContent to Use
   */
  if (inlineContent && inlineContent.safeInlineContent) return inlineContent

  console.warn("Using old Content Logic, please publish to use the new InlineContent!")
  //else rewrite
  if (bobType.startsWith("text")) return bobTextReWriteOnRead(template, bobType)
  if (bobType.startsWith("background")) return bobBackgroundReWriteOnRead(template, bobType)
  if (bobType.startsWith("media")) return bobMediaReWriteOnRead(template, bobType)
}

export function linkControllerReWriteOnRead(template: any, bobType: string) {
  return {
    [`${bobType}.${inlineContentTypes.LINK_ENABLE}`]: template.behaviour.link?.enable,
    [`${bobType}.${inlineContentTypes.LINK_LABEL}`]: template.behaviour.link?.label,
    [`${bobType}.${inlineContentTypes.LINK_MAPPED_VALUE}`]: template.behaviour.link?.mappedValue,
    [`${bobType}.${inlineContentTypes.LINK_CUSTOM_URL}`]: template.behaviour.link?.customUrl,
    [`${bobType}.${inlineContentTypes.LINK_LINKED_OBJECT}`]: template.behaviour.link?.linkedObject,
    [`${bobType}.${inlineContentTypes.LINK_TARGET}`]: template.behaviour.link?.target,
  }
}

export function bobTextReWriteOnRead(template: any, bobType: string) {
  return {
    [`${bobType}.${inlineContentTypes.CUSTOM_TEXT}`]: template.customText,
    [`${bobType}.${inlineContentTypes.CONTENT_SRC_TEXT}`]: template.contentSrc,
    [`${bobType}.${inlineContentTypes.MAPPED_VALUE}`]: template.mappedValue,
    [`${bobType}.${inlineContentTypes.EXTRA_WEIGHTS}`]: template.extraWeights,
    [`${bobType}.${inlineContentTypes.TAG}`]: template.tag,
    ...linkControllerReWriteOnRead(template, bobType),
  }
}

export function bobBackgroundReWriteOnRead(template: any, bobType: string) {
  return {
    [`${bobType}.${inlineContentTypes.IMAGE_SRC}`]: template.image.src,
    [`${bobType}.${inlineContentTypes.IMAGE_SRC_ACTIVE}`]: template.behaviour.active?.image?.src,
    [`${bobType}.${inlineContentTypes.IMAGE_SRC_HOVER}`]: template.behaviour.hover?.image?.src,
    [`${bobType}.${inlineContentTypes.IMAGE_ALT}`]: template.image.alt,
    [`${bobType}.${inlineContentTypes.IMAGE_ALT_ACTIVE}`]: template.behaviour.active?.image?.alt,
    [`${bobType}.${inlineContentTypes.IMAGE_ALT_HOVER}`]: template.behaviour.hover?.image?.alt,
    ...linkControllerReWriteOnRead(template, bobType),
  }
}

export function bobMediaReWriteOnRead(template: any, bobType: string) {
  return {
    [`${bobType}.${inlineContentTypes.SRC}`]: template.src,
    [`${bobType}.${inlineContentTypes.SRC_ACTIVE}`]: template.behaviour.active?.src,
    [`${bobType}.${inlineContentTypes.SRC_HOVER}`]: template.behaviour.hover?.src,
    [`${bobType}.${inlineContentTypes.IMAGE_ENABLE}`]: template.image.enable,
    [`${bobType}.${inlineContentTypes.IMAGE_ENABLE_ACTIVE}`]: template.behaviour.active?.image?.enable,
    [`${bobType}.${inlineContentTypes.IMAGE_ENABLE_HOVER}`]: template.behaviour.hover?.image?.enable,
    [`${bobType}.${inlineContentTypes.VIDEO_ENABLE}`]: template.video.enable,
    [`${bobType}.${inlineContentTypes.VIDEO_ENABLE_ACTIVE}`]: template.behaviour.active?.video?.enable,
    [`${bobType}.${inlineContentTypes.VIDEO_ENABLE_HOVER}`]: template.behaviour.hover?.video?.enable,
    [`${bobType}.${inlineContentTypes.CONTENT_SRC_MEDIA}`]: template.contentSrc,
    [`${bobType}.${inlineContentTypes.CONTENT_SRC_ACTIVE}`]: template.behaviour.active?.contentSrc,
    [`${bobType}.${inlineContentTypes.CONTENT_SRC_HOVER}`]: template.behaviour.hover?.contentSrc,
    [`${bobType}.${inlineContentTypes.IMAGE_MAPPED_VALUE}`]: template.image.mappedValue,
    [`${bobType}.${inlineContentTypes.IMAGE_MAPPED_VALUE_ACTIVE}`]: template.behaviour.active?.image?.mappedValue,
    [`${bobType}.${inlineContentTypes.IMAGE_MAPPED_VALUE_HOVER}`]: template.behaviour.hover?.image?.mappedValue,
    [`${bobType}.${inlineContentTypes.VIDEO_MAPPED_VALUE}`]: template.video.mappedValue,
    [`${bobType}.${inlineContentTypes.VIDEO_MAPPED_VALUE_ACTIVE}`]: template.behaviour.active?.video?.mappedValue,
    [`${bobType}.${inlineContentTypes.VIDEO_MAPPED_VALUE_HOVER}`]: template.behaviour.hover?.video?.mappedValue,
    ...linkControllerReWriteOnRead(template, bobType),
  }
}
