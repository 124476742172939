import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  string as stringC,
  partial,
  boolean as booleanC,
  literal,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { get2WithNull } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */
const AttachmentCodec = union([literal("fixed"), literal("scroll")])
// props only, no enable
const BackgroundImagePropsCodec = intersection([
  type({
    position: stringC,
    repeat: stringC,
    size: stringC,
  }),
  partial({ attachment: AttachmentCodec }),
])

const BackgroundImageCodec = intersection([type({ enable: booleanC }), BackgroundImagePropsCodec])

// props only, no enable
const BackgroundImagePropsOptCodec = partial({
  position: nullable(stringC),
  repeat: nullable(stringC),
  size: nullable(stringC),
  attachment: nullable(AttachmentCodec),
})
const BackgroundImageOptCodec = intersection([partial({ enable: nullable(booleanC) }), BackgroundImagePropsOptCodec])

const StylesBackgroundImageCodec = intersection([
  type({ image: BackgroundImageCodec }),
  partial({
    behaviour: partial({
      active: partial({
        image: BackgroundImageOptCodec,
      }),
      hover: partial({
        image: BackgroundImageOptCodec,
      }),
    }),
    mobile: partial({
      image: BackgroundImageOptCodec,
      behaviour: partial({
        active: partial({
          image: BackgroundImageOptCodec,
        }),
        hover: partial({
          image: BackgroundImageOptCodec,
        }),
      }),
    }),
    tablet: partial({
      image: BackgroundImageOptCodec,
      behaviour: partial({
        active: partial({
          image: BackgroundImageOptCodec,
        }),
        hover: partial({
          image: BackgroundImageOptCodec,
        }),
      }),
    }),
  }),
])

type BackgroundImage = TypeOf<typeof BackgroundImageCodec>
type BackgroundImageProps = TypeOf<typeof BackgroundImagePropsCodec>
type BackgroundImageOpt = TypeOf<typeof BackgroundImageOptCodec>
type BackgroundImagePropsOpt = TypeOf<typeof BackgroundImagePropsOptCodec>
type StylesBackgroundImage = TypeOf<typeof StylesBackgroundImageCodec>
type BackgroundImageCSS =
  | {
      "background-size": string
      "background-repeat": string
      "background-position": string
      "background-attachment": string
      "background-image": string
    }
  | { "background-image": string }

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  imageSrc: string,
  foundationStyle: FColor | undefined = undefined // TODO: fix when working on other bobs
): Partial<BackgroundImageCSS> {
  const styles = StylesBackgroundImageCodec.decode(stylesObj)
  // TODO: should use codec from colors?
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, imageSrc, foundationStyle)
  console.warn(decoderErrors(styles))
  return {
    "background-position": "0% 0%",
    "background-repeat": "repeat",
    "background-size": "contain",
    "background-image": "transparent",
    "background-attachment": "scroll",
  }
}

export function cssRender(
  stylesObj: StylesBackgroundImage,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  imageSrc: string,
  foundationStyle: FColor | undefined
): Partial<BackgroundImageCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.image, imageSrc, foundationStyle)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.image,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.image, stylesObj.image),
        imageSrc,
        foundationStyle
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(
        stylesObj.image,
        mergeBob2(stylesObj?.[breakpoint]?.image, stylesObj.image),
        imageSrc,
        foundationStyle
      )
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.image,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.image,
          stylesObj?.behaviour?.[behaviourState]?.image,
          stylesObj?.[breakpoint]?.image,
          stylesObj.image
        ),
        imageSrc,
        foundationStyle
      )
    }
  }
}

export function renderBob(
  imageObj: BackgroundImage,
  imageSrc: string,
  foundationStyle: FColor | undefined
): Partial<BackgroundImageCSS> {
  if (!imageObj.enable) return {}

  return render(imageObj, imageSrc, foundationStyle)
}

export function renderBobOpt(
  defaultImageObj: BackgroundImage,
  imageObj: BackgroundImageOpt,
  imageSrc: string,
  foundationStyle: FColor | undefined
): Partial<BackgroundImageCSS> {
  if (imageObj?.enable === false) {
    if (defaultImageObj.enable)
      return {
        "background-image": `none`,
      }

    return {}
  }

  if (imageObj?.enable) return renderOpt(imageObj, imageSrc, foundationStyle)

  return {}
}

export function render(
  imageObj: BackgroundImage,
  imageSrc: string,
  foundationStyle: FColor | undefined
): BackgroundImageCSS {
  const bgAttach = imageObj.attachment ? { "background-attachment": imageObj.attachment } : {}
  return {
    "background-size": `${imageObj.size}`,
    "background-repeat": `${imageObj.repeat}`,
    "background-position": `${imageObj.position}`,
    "background-image": `url(${imageSrc})`,
    ...bgAttach,
  }
}

export function renderOpt(
  imageObj: BackgroundImagePropsOpt,
  imageSrc: string,
  foundationStyle: FColor | undefined
): Partial<BackgroundImageCSS> {
  let css = {}
  if (valueExists(imageObj?.size)) css = { ...css, "background-size": `${imageObj?.size}` }
  if (valueExists(imageObj?.repeat)) css = { ...css, "background-repeat": `${imageObj?.repeat}` }
  if (valueExists(imageObj?.position)) css = { ...css, "background-position": imageObj?.position }
  if (valueExists(imageSrc)) css = { ...css, "background-image": `url(${imageSrc})` }
  if (valueExists(imageObj?.attachment)) css = { ...css, "background-attachment": `${imageObj?.attachment}` }

  return css
}

/**
 *
 * @param imageObj
 * @param defaultBackgroundImageObj
 * @returns BackgroundImage
 *
 */
export function mergeBob2(
  imageObj: BackgroundImageOpt | undefined,
  defaultBackgroundImageObj: BackgroundImage
): BackgroundImageOpt {
  const enable = get2WithNull(imageObj?.enable, defaultBackgroundImageObj.enable)

  return {
    enable,
    position: imageObj?.position,
    repeat: imageObj?.repeat,
    size: imageObj?.size,
    attachment: imageObj?.attachment,
  }
}

/**
 *
 * @param imageObj
 * @param imageDefaultBreakpoint
 * @param defaultBackgroundImageObj
 * @returns BackgroundImage
 */
export function mergeBob3(
  imageObj: BackgroundImageOpt | undefined,
  imageDesktopBehaviour: BackgroundImageOpt | undefined,
  imageDefaultBreakpoint: BackgroundImageOpt | undefined,
  defaultBackgroundImageObj: BackgroundImage
): BackgroundImageOpt {
  const enable =
    imageObj?.enable ??
    imageDesktopBehaviour?.enable ??
    imageDefaultBreakpoint?.enable ??
    defaultBackgroundImageObj.enable

  return {
    enable,
    position: imageObj?.position,
    repeat: imageObj?.repeat,
    size: imageObj?.size,
    attachment: imageObj?.attachment,
  }
}

export type {
  BackgroundImageCSS,
  StylesBackgroundImage,
  BackgroundImageProps,
  BackgroundImagePropsOpt,
  BackgroundImage,
  BackgroundImageOpt,
}

