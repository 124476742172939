import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import * as BobDefaultNode from "../../../../../modules/shared-modules/constants/bobDefaultNode"
import { cssRenderUnsafe as SelfFlexStyleCssRenderUnsafe } from "../bobStyles/selfFlexStyle"
import { cssRenderUnsafe as HeightStyleCssRenderUnsafe } from "../bobStyles/heightStyleV2"
import { cssRenderUnsafe as MaxHeightStyleCssRenderUnsafe } from "../bobStyles/maxHeightStyle"
import { cssRenderUnsafe as MinHeightStyleCssRenderUnsafe } from "../bobStyles/minHeightStyle"
import { cssRenderUnsafe as WidthStyleCssRenderUnsafe } from "../bobStyles/widthStyleV2"
import { cssRenderUnsafe as MaxWidthStyleCssRenderUnsafe } from "../bobStyles/maxWidthStyle"
import { cssRenderUnsafe as MinWidthStyleCssRenderUnsafe } from "../bobStyles/minWidthStyle"
import { cssRenderUnsafe as PositionCssRenderUnsafe } from "../bobStyles/positionStyle"
import { cssRenderUnsafe as cssRenderUnsafeBoundary } from "../bobStyles/boundaryStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeTextShadow } from "../bobStyles/textShadowStyle"
import { cssRenderUnsafe as cssRenderUnsafeText } from "../bobStyles/textStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeTextArrange } from "../bobStyles/textArrangeStyle"
import { dynamicFontLoader } from "../../../../../modules/shared-modules/utilities/components"
import { cssRenderUnsafe as cssRenderUnsafeColor } from "../bobStyles/colorStyle"
import { cssRenderUnsafe as cssRenderUnsafeCursor } from "../bobStyles/cursorStyle"
import { nBobText } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { PageStylesheet, ColorLabel, FontLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import {
  handleBobTextDecorationLabel,
  handleBobTextShadowLabel,
  handleBobLabel,
} from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"
import { cssRenderUnsafe as TextDecorationCssRenderUnsafe } from "../bobStyles/textDecorationStyle"

const responsiveStyles = (
  styles: nBobText,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
) => {
  const colorLabel = handleBobLabel(styles, stylesheetLabels, breakpoint, behaviourState, "color") as ColorLabel
  const shadowColorLabel = handleBobTextShadowLabel(styles, stylesheetLabels, breakpoint, behaviourState) as ColorLabel
  const decorationColorLabel = handleBobTextDecorationLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const textLabel = handleBobLabel(styles, stylesheetLabels, breakpoint, behaviourState, "fontFamily") as FontLabel

  return {
    ...cssRenderUnsafeText(styles, breakpoint, behaviourState, textLabel),
    ...cssRenderUnsafeTextArrange(styles, breakpoint, behaviourState),
    ...HeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...WidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...SelfFlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeBoundary(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeColor(styles, breakpoint, behaviourState, undefined, colorLabel, false),
    ...cssRenderUnsafeCursor(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeTextShadow(styles, breakpoint, behaviourState, undefined, shadowColorLabel),
    ...PositionCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...TextDecorationCssRenderUnsafe(styles, breakpoint, behaviourState, decorationColorLabel),
  }
}

const handleFonts = (
  templateStyles: nBobText,
  pageLabels: PageStylesheet["labels"] | undefined,
  isExpManager: boolean
) => {
  let FONTLOADED = ""
  let FONTLOADEDTABLET = ""
  let FONTLOADEDMOBILE = ""
  let FONTLOADEDHOVER = ""
  let FONTLOADEDACTIVE = ""
  const label = handleBobLabel(templateStyles, pageLabels, "desktop", "default", "fontFamily") as FontLabel

  if (isExpManager) {
    FONTLOADED = dynamicFontLoader(
      templateStyles ? templateStyles : BobDefaultNode.styles,
      [templateStyles.fontWeight],
      label
    )
    FONTLOADEDTABLET = dynamicFontLoader(
      templateStyles.tablet ? templateStyles.tablet : BobDefaultNode.styles,
      [templateStyles.tablet && templateStyles.tablet.fontWeight],
      label,
      undefined,
      true
    )
    FONTLOADEDMOBILE = dynamicFontLoader(
      templateStyles.mobile ? templateStyles.mobile : BobDefaultNode.styles,
      templateStyles.mobile && [templateStyles.mobile.fontWeight],
      label,
      undefined,
      true
    )
    FONTLOADEDHOVER =
      templateStyles.behaviour && templateStyles.behaviour.hover && templateStyles.behaviour.hover.enable
        ? dynamicFontLoader(
            templateStyles.behaviour.hover,
            [templateStyles.behaviour.hover.fontWeight],
            label,
            undefined,
            true
          )
        : ""
    FONTLOADEDACTIVE =
      templateStyles.behaviour && templateStyles.behaviour.active && templateStyles.behaviour.active.enable
        ? dynamicFontLoader(
            templateStyles.behaviour.active,
            [templateStyles.behaviour.active.fontWeight],
            label,
            undefined,
            true
          )
        : ""
  }
  return `${FONTLOADED} ${FONTLOADEDTABLET} ${FONTLOADEDMOBILE} ${FONTLOADEDHOVER} ${FONTLOADEDACTIVE}`
}

export { responsiveStyles, handleFonts }
