import React, { Fragment } from "react"
// @ts-ignore
import Style from "style-it"
import { handleDOMid } from "../../../../../modules/shared-modules/utilities/symbols"
import styled from "styled-components"
import { responsiveGroupStylesProperties, handleEnableValues } from "../bobHandler"
import { textShadowCss as ShadowStyleTextShadowCss } from "../bobStyles/shadowStyle"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import GlobalStylesUtils from "../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { renderCSSString as renderCSSStringBoundary } from "../bobStyles/boundaryStyle"
import {
  conditionalBoundaryRenderUnsafe as cssRenderUnsafeBoundary,
  conditionalShadowRenderUnsafe,
  alignmentWithAP,
} from "../../../../../modules/shared-modules/utilities/conditionalController"
import { ComponentSpecificAlignment, AlignmentCssRenderUnsafe } from "../bobStyles/alignmentStyle"
import {
  cssRenderUnsafe as cssRenderUnsafeColor,
  renderCSSString as renderCSSStringColor,
} from "../bobStyles/colorStyle"

interface BobIconProps {
  id: string
  iconClassName?: string
  className?: string
  instanceId: string | undefined
  post?: any
  rule: any
  field: string
  template: any
  templatePropsToIgnore?: Array<string>
  globalStyles: any
  pageResponse: PageTypes
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const BobIconDOM: any = styled.div`
  ${(props: any) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: any) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: any) => props.mobile}
  }
`

class BobIcon extends React.Component<BobIconProps> {
  handleStateStyles(
    templateObj: any,
    stateTemplateObj: any,
    templateObjMobile: any,
    templateObjTablet: any,
    className: string,
    behaviourState: BehaviourState_,
    additionalAlignmentProperties: ComponentSpecificAlignment
  ) {
    if (stateTemplateObj) {
      const templateMobile = {
        ...AlignmentCssRenderUnsafe(templateObj, "mobile", behaviourState, additionalAlignmentProperties),
        ...cssRenderUnsafeBoundary(templateObj, "mobile", behaviourState),
        ...cssRenderUnsafeColor(templateObj, "mobile", behaviourState, undefined, undefined, true),
      }
      const mobileShadow = conditionalShadowRenderUnsafe(templateObj, "mobile", behaviourState, "text", undefined)

      const templateTablet = {
        ...AlignmentCssRenderUnsafe(templateObj, "tablet", behaviourState, additionalAlignmentProperties),
        ...cssRenderUnsafeBoundary(templateObj, "tablet", behaviourState),
        ...cssRenderUnsafeColor(templateObj, "tablet", behaviourState, undefined, undefined, true),
      }
      const tabletShadow = conditionalShadowRenderUnsafe(templateObj, "tablet", behaviourState, "text", undefined)

      const mediaQueryRestrictions = this.handleMediaQueryRestrictions(templateObjMobile, templateObjTablet)
      const shadows = conditionalShadowRenderUnsafe(templateObj, "desktop", behaviourState, "text", undefined)
      const boundaries = cssRenderUnsafeBoundary(templateObj, "desktop", behaviourState)
      const color = cssRenderUnsafeColor(templateObj, "desktop", behaviourState, undefined, undefined, true)
      //TODO: Missing breakpoint/behaviour color for icons
      return `
      ${
        stateTemplateObj.enable
          ? `@media all ${mediaQueryRestrictions}{
            ${className}{
              ${this.handleIgnoredTemplatePropBehaviour("spacing", boundaries, "desktop")}
              ${ShadowStyleTextShadowCss(shadows)};
              ${renderCSSStringColor(color)}
            }
          }`
          : ""
      }
      ${
        (stateTemplateObj.enable || templateObjTablet?.enable) && templateObjTablet?.enable !== false
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${className}{
              ${this.handleIgnoredTemplatePropBehaviour("spacing", templateTablet, "tablet")}
              ${ShadowStyleTextShadowCss(tabletShadow)};
              ${renderCSSStringColor(templateTablet)}
            }
          }`
          : ""
      }
      ${
        (stateTemplateObj.enable || templateObjMobile?.enable) && templateObjMobile?.enable !== false
          ? `@media all and (max-width: 766px){
            ${className}{
              ${this.handleIgnoredTemplatePropBehaviour("spacing", templateMobile, "mobile")}
              ${ShadowStyleTextShadowCss(mobileShadow)};
              ${renderCSSStringColor(templateMobile)}
            }
          }`
          : ""
      }
      `
    }
    return ""
  }

  /**
   * returns media queries to apply styles based on enable prop value on the diferent breakpoints
   */
  handleMediaQueryRestrictions(mobileStyles: any, tabletStyles: any) {
    if (tabletStyles?.enable === false && mobileStyles?.enable) return "and (max-width: 766px), (min-width: 993px)"
    if (tabletStyles?.enable === false) return "and (min-width: 993px)"
    if (mobileStyles?.enable === false) return "and (min-width: 767px)"
    return ""
  }

  //Handle breakpoints
  handleIgnoredTemplateProp = (prop: string, breakpoint: Breakpoint, behaviour: BehaviourState_) => {
    if (this.props.templatePropsToIgnore?.includes(prop)) return []
    if (prop === "size") {
      return breakpoint === "desktop"
        ? { fontSize: `${this.props.template.size}px` }
        : responsiveGroupStylesProperties(this.props.template, breakpoint, "icon")
    }
    if (prop === "spacing") {
      return cssRenderUnsafeBoundary(this.props.template, breakpoint, behaviour)
    }
  }

  //handle behaviours
  handleIgnoredTemplatePropBehaviour = (prop: string, template: any, breakpoint: Breakpoint) => {
    if (this.props.templatePropsToIgnore?.includes(prop)) return ""

    if (prop === "spacing") {
      return `
      ${renderCSSStringBoundary(template)} `
    }
  }

  render() {
    const templateObj = this.props.template

    const additionalAlignmentProperties = { flexDirection: "row", self: false } as ComponentSpecificAlignment
    const {
      alignmentStyles: alignment,
      tabletAlignment,
      mobileAlignment,
    } = alignmentWithAP(templateObj, additionalAlignmentProperties)

    const iconClass =
      templateObj.icon && templateObj.iconType && templateObj.icon !== "" && templateObj.iconType !== ""
        ? `${templateObj.icon} ${templateObj.iconType}`
        : this.props.iconClassName
        ? this.props.iconClassName
        : ""
    const classPrefix = `prefix${templateObj.uuid}`

    let hoverStyles: string = ""
    let activeStyles: string = ""
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.hover) {
      const hoverTemplate: any = handleEnableValues(templateObj)
      hoverStyles = this.handleStateStyles(
        templateObj,
        hoverTemplate.behaviour.hover,
        hoverTemplate.mobile?.behaviour?.hover,
        hoverTemplate.tablet?.behaviour?.hover,
        `.${classPrefix}-bob-icon:hover i, .${classPrefix}-bob-icon.isHover i`,
        "hover",
        additionalAlignmentProperties
      )
    }
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.active) {
      const activeTemplate: any = handleEnableValues(templateObj)
      activeStyles = this.handleStateStyles(
        templateObj,
        activeTemplate.behaviour.active,
        activeTemplate.mobile?.behaviour?.active,
        activeTemplate.tablet?.behaviour?.active,
        `.active.${classPrefix}-bob-icon i`,
        "active",
        additionalAlignmentProperties
      )
    }

    const style = {
      ...this.handleIgnoredTemplateProp("size", "desktop", "default"),
      ...cssRenderUnsafeColor(templateObj, "desktop", "default", undefined, undefined, true),
      ...conditionalShadowRenderUnsafe(templateObj, "desktop", "default", "text", undefined),
      ...this.handleIgnoredTemplateProp("spacing", "desktop", "default"),
      ...alignment,
    }
    const tabletStyles = {
      ...this.handleIgnoredTemplateProp("spacing", "tablet", "default"),
      ...tabletAlignment,
      ...conditionalShadowRenderUnsafe(templateObj, "tablet", "default", "text", undefined),
      ...this.handleIgnoredTemplateProp("size", "tablet", "default"),
      ...cssRenderUnsafeColor(templateObj, "tablet", "default", undefined, undefined, true),
    }
    const mobileStyles = {
      ...this.handleIgnoredTemplateProp("spacing", "mobile", "default"),
      ...mobileAlignment,
      ...conditionalShadowRenderUnsafe(templateObj, "mobile", "default", "text", undefined),
      ...this.handleIgnoredTemplateProp("size", "mobile", "default"),
      ...cssRenderUnsafeColor(templateObj, "mobile", "default", undefined, undefined, true),
    }

    const hasStateStyles = hoverStyles.trim() !== "" || activeStyles.trim() !== ""

    return (
      <Fragment>
        {hasStateStyles && (
          <Style>
            {`          
              ${hoverStyles}
              ${activeStyles}
            `}
          </Style>
        )}
        <div
          id={handleDOMid(this.props.field, this.props.id, this.props.instanceId)}
          className={`bob-icon ${classPrefix}-bob-icon ${this.props.className ? this.props.className : ""}`}
          onClick={this.props.onClick}
        >
          <BobIconDOM
            as={"i"}
            className={`${iconClass} spacing-preview-${this.props.template.uuid} ${GlobalStylesUtils.handleClassName(
              this.props.pageResponse.nGlobalStyles,
              this.props.template,
              undefined,
              "-text"
            )}`}
            desktop={style}
            tablet={tabletStyles}
            mobile={mobileStyles}
          />
        </div>
      </Fragment>
    )
  }
}

export default BobIcon
