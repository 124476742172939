import React, { useEffect, useState, RefObject } from "react"
import styled from "styled-components"
import {
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import {
  getOverrideInlineContentValues,
  handleBackgroundColor,
  handleBackgroundImage,
} from "../../../../../modules/shared-modules/utilities/components"
import { PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { isBobGrid } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { handleSrcLink } from "../../../../../modules/shared-modules/mediaLibrary/mediaLibraryUtils"
import { cssRenderUnsafe as renderGridProperties } from "../bobStyles/gridPropertiesStyle"
import { cssRenderUnsafe as HeightStyleCssRenderUnsafe } from "../bobStyles/heightStyleV2"
import { cssRenderUnsafe as MaxHeightStyleCssRenderUnsafe } from "../bobStyles/maxHeightStyle"
import { cssRenderUnsafe as MinHeightStyleCssRenderUnsafe } from "../bobStyles/minHeightStyle"
import { cssRenderUnsafe as WidthStyleCssRenderUnsafe } from "../bobStyles/widthStyleV2"
import { cssRenderUnsafe as MaxWidthStyleCssRenderUnsafe } from "../bobStyles/maxWidthStyle"
import { cssRenderUnsafe as MinWidthStyleCssRenderUnsafe } from "../bobStyles/minWidthStyle"
import { cssRenderUnsafe as cssRenderUnsafeShadow } from "../bobStyles/shadowStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeBoundary } from "../bobStyles/boundaryStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeBorder } from "../bobStyles/borderStyleV2"
import { cssRenderUnsafe as SelfFlexStyleCssRenderUnsafe } from "../bobStyles/selfFlexStyle"
import { responsiveProperties } from "../bobHandler"
import { handleDOMid } from "../../../../../modules/shared-modules/utilities/symbols"

interface ResponsiveCSSProps {
  desktop: any
  tablet: any
  mobile: any
}

interface OwnProps {
  id: string
  pageResponse: PageTypes
  instanceId: string | undefined
  object: SingleObject
  inlineContent: ObjectContent["inlineContent"]
  overrides: ObjectContentOverride | undefined
  template: any
  post: Post | undefined
  field: string
  children: React.ReactNode | undefined
  setRef: (x: React.RefObject<HTMLDivElement>) => void | undefined
}

const GridStyle = styled.div`
  ${(props: ResponsiveCSSProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: ResponsiveCSSProps) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: ResponsiveCSSProps) => props.mobile}
  }
`

const BobGrid: React.FC<OwnProps> = (props): JSX.Element => {
  const [containerRef] = useState<RefObject<HTMLDivElement>>(React.createRef())

  useEffect(() => {
    props.setRef && props.setRef(containerRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const gridStyles = isBobGrid(props.object.styles.bobs.grid1)
  const inlineContent = props.object.content.inlineContent

  const mediaObject = inlineContent
    ? handleSrcLink(props.pageResponse, getOverrideInlineContentValues(`${props.field}.image-src`, props))
    : undefined
  const isVideo = mediaObject?.mediaInfo?.itemType === "video" ? true : false
  let imageSrc = mediaObject && !isVideo ? mediaObject.mediaSrc : ""
  if (
    !isVideo &&
    window.Modernizr &&
    !window.Modernizr.webp &&
    mediaObject?.fallbackExtension &&
    mediaObject.mediaInfo
  ) {
    imageSrc = `https://static-media.fluxio.cloud/${mediaObject.mediaInfo.profileName}/${mediaObject.mediaInfo.id}.${mediaObject.fallbackExtension}`
  }

  const desktopStyles = {
    ...renderGridProperties(gridStyles, "desktop", "default"),
    ...HeightStyleCssRenderUnsafe(gridStyles, "desktop", "default"),
    ...MaxHeightStyleCssRenderUnsafe(gridStyles, "desktop", "default"),
    ...MinHeightStyleCssRenderUnsafe(gridStyles, "desktop", "default"),
    ...WidthStyleCssRenderUnsafe(gridStyles, "desktop", "default"),
    ...MaxWidthStyleCssRenderUnsafe(gridStyles, "desktop", "default"),
    ...MinWidthStyleCssRenderUnsafe(gridStyles, "desktop", "default"),
    ...cssRenderUnsafeShadow(gridStyles, "desktop", "default", undefined, undefined),
    ...cssRenderUnsafeBoundary(gridStyles, "desktop", "default"),
    ...cssRenderUnsafeBorder(gridStyles, "desktop", "default", undefined),
    ...SelfFlexStyleCssRenderUnsafe(gridStyles, "desktop", "default"),
    ...handleBackgroundColor(gridStyles),
    ...handleBackgroundImage(gridStyles, undefined, imageSrc),
    backgroundSize: gridStyles.image.size + " !important",
    backgroundRepeat: gridStyles.image.repeat + " !important",
    backgroundPosition: gridStyles.image.position + " !important",
    backgroundAttachment: gridStyles.attachment + " !important",
  }
  const tabletStyles = {
    ...renderGridProperties(gridStyles, "tablet", "default"),
    ...HeightStyleCssRenderUnsafe(gridStyles, "tablet", "default"),
    ...MaxHeightStyleCssRenderUnsafe(gridStyles, "tablet", "default"),
    ...MinHeightStyleCssRenderUnsafe(gridStyles, "tablet", "default"),
    ...WidthStyleCssRenderUnsafe(gridStyles, "tablet", "default"),
    ...MaxWidthStyleCssRenderUnsafe(gridStyles, "tablet", "default"),
    ...MinWidthStyleCssRenderUnsafe(gridStyles, "tablet", "default"),
    ...cssRenderUnsafeShadow(gridStyles, "tablet", "default", undefined, undefined),
    ...cssRenderUnsafeBoundary(gridStyles, "tablet", "default"),
    ...cssRenderUnsafeBorder(gridStyles, "tablet", "default", undefined),
    ...SelfFlexStyleCssRenderUnsafe(gridStyles, "tablet", "default"),
    ...responsiveProperties(gridStyles, "tablet", { image: { imageType: "backgroundImage" } }, [
      "grid",
      "height",
      "minHeight",
      "maxHeight",
      "width",
      "maxWidth",
      "minWidth",
      "alignment",
      "shadow",
      "spacing",
      "border",
    ]),
  }
  const mobileStyles = {
    ...renderGridProperties(gridStyles, "mobile", "default"),
    ...HeightStyleCssRenderUnsafe(gridStyles, "mobile", "default"),
    ...MaxHeightStyleCssRenderUnsafe(gridStyles, "mobile", "default"),
    ...MinHeightStyleCssRenderUnsafe(gridStyles, "mobile", "default"),
    ...WidthStyleCssRenderUnsafe(gridStyles, "mobile", "default"),
    ...MaxWidthStyleCssRenderUnsafe(gridStyles, "mobile", "default"),
    ...MinWidthStyleCssRenderUnsafe(gridStyles, "mobile", "default"),
    ...cssRenderUnsafeShadow(gridStyles, "mobile", "default", undefined, undefined),
    ...cssRenderUnsafeBoundary(gridStyles, "mobile", "default"),
    ...cssRenderUnsafeBorder(gridStyles, "mobile", "default", undefined),
    ...SelfFlexStyleCssRenderUnsafe(gridStyles, "mobile", "default"),
    ...responsiveProperties(gridStyles, "mobile", { image: { imageType: "backgroundImage" } }, [
      "grid",
      "height",
      "minHeight",
      "maxHeight",
      "width",
      "maxWidth",
      "minWidth",
      "alignment",
      "shadow",
      "spacing",
      "border",
    ]),
  }

  return (
    <GridStyle
      ref={containerRef}
      id={`${handleDOMid("object", props.object.uuid, props.object.selectedInstanceId)} ${handleDOMid(
        props.field,
        props.id,
        props.instanceId
      )}`}
      className='grid'
      desktop={desktopStyles}
      tablet={tabletStyles}
      mobile={mobileStyles}
    >
      {props.children}
    </GridStyle>
  )
}

export default BobGrid
