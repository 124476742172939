import React, { Fragment, RefObject, useState } from "react"
import {
  getContent,
  handleContentBlock,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import ComponentHandler from "../../../../componentHandler"
import ComponentSelectorEMv2 from "../../../componentSelectorEM/ComponentSelectorEMv2"
import { ContainerProps } from "."
import BobContainer from "../../../bob/bobContainer/bobContainer"
import { Post } from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"

const Container = (props: ContainerProps) => {
  const [elRef, setElRef] = useState<RefObject<HTMLDivElement> | undefined>(undefined)

  const childComponentsId = props.obj.children
  const renderChild = (post: Post | undefined) => {
    if (childComponentsId) {
      return childComponentsId.map((childId: string, idx: number) => {
        const handledItem = handleObjectType(props.obj, childId, props.pageResponse, props.expManager.emEditorType)
        return (
          <ComponentHandler
            position={idx}
            key={idx}
            component={handleContentBlock(handledItem, post, props.obj, props.pageResponse, props.overrides)}
            pageResponse={props.pageResponse}
            selectedInstanceId={props.obj.selectedInstanceId}
            matchOrder={props.matchOrder}
            expManager={props.expManager}
            form={props.form}
          />
        )
      })
    }
  }

  const items = getContent(props.obj, "posts", props.overrides)
  let childs = []
  if (items && items.length > 0) {
    childs = items.map((post: Post, idx: number) => {
      return (
        <BobContainer
          key={idx}
          id={props.obj.uuid}
          pageResponse={props.pageResponse}
          instanceId={props.obj.selectedInstanceId}
          inlineContent={props.obj.content.inlineContent}
          overrides={props.overrides}
          object={props.obj}
          post={post}
          template={props.obj.styles}
          field='container1'
          setRef={(containerRef) => setElRef(containerRef)}
          expManager={props.expManager}
          replacedMediaList={props.replacedMediaList}
        >
          {renderChild(post)}
        </BobContainer>
      )
    })
  }

  return (
    <Fragment>
      {props.expManager.enable && !props.expManager.content && (
        <ComponentSelectorEMv2
          uuid={props.obj.uuid}
          obj={props.obj}
          form={props.form}
          masterBob='container1'
          editing={props.editing}
          elRef={elRef}
        />
      )}
      {childs}
    </Fragment>
  )
}

export default React.memo(Container)
