import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { get2WithNull } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const UnitCodec = union([
  literal("px"),
  literal("%"),
  literal("vw"),
  literal("vh"),
  literal("auto"),
])
const MaxWidthPropsCodec = type({ value: numberC, unit: UnitCodec })
const MaxWidthCodec = intersection([
  type({ enable: booleanC }),
  MaxWidthPropsCodec,
])

const MaxWidthPropsOptCodec = partial({
  unit: nullable(UnitCodec),
  value: nullable(numberC),
})
const MaxWidthOptCodec = intersection([
  partial({ enable: nullable(booleanC) }),
  MaxWidthPropsOptCodec,
])

const StylesMaxWidthCodec = intersection([
  type({ maxWidth: MaxWidthCodec }),
  partial({
    behaviour: partial({
      active: partial({ maxWidth: MaxWidthPropsOptCodec }),
      hover: partial({ maxWidth: MaxWidthPropsOptCodec }),
    }),
    mobile: intersection([
      partial({ maxWidth: MaxWidthPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ maxWidth: MaxWidthPropsOptCodec }),
          hover: partial({ maxWidth: MaxWidthPropsOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ maxWidth: MaxWidthPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ maxWidth: MaxWidthPropsOptCodec }),
          hover: partial({ maxWidth: MaxWidthPropsOptCodec }),
        }),
      }),
    ]),
  }),
])

type MaxWidth = TypeOf<typeof MaxWidthCodec>
type MaxWidthProps = TypeOf<typeof MaxWidthPropsCodec>
type MaxWidthOpt = TypeOf<typeof MaxWidthOptCodec>
type StylesMaxWidth = TypeOf<typeof StylesMaxWidthCodec>
type MaxWidthCSS = {
  "max-width": string
}


export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<MaxWidthCSS> {
  const styles = StylesMaxWidthCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return {}
}

export function cssRender(
  stylesObj: StylesMaxWidth,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<MaxWidthCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.maxWidth)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.maxWidth,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.maxWidth, stylesObj.maxWidth)
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.maxWidth, mergeBob2(stylesObj?.[breakpoint]?.maxWidth, stylesObj.maxWidth))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.maxWidth,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.maxWidth,
          stylesObj?.behaviour?.[behaviourState]?.maxWidth,
          stylesObj?.[breakpoint]?.maxWidth,
          stylesObj.maxWidth
        )
      )
    }
  }
}

export function renderBob(maxWidthObj: MaxWidth): Partial<MaxWidthCSS> {
  if (!maxWidthObj.enable) return {}

  return render(maxWidthObj)
}

export function render(maxWidthObj: MaxWidthProps): MaxWidthCSS {
  const widthValue = maxWidthObj.value
  const widthUnit = maxWidthObj.unit
  return {
    "max-width": `${widthValue}${widthUnit}`,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param maxWidthObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(
  defaultMaxWidthObjObj: MaxWidth,
  maxWidthObj: MaxWidthOpt | undefined
): Partial<MaxWidthCSS> {
  if (maxWidthObj?.enable === false) {
    if (defaultMaxWidthObjObj.enable) return { "max-width": "auto" }

    return {}
  }

  if (maxWidthObj?.enable) return renderOpt(maxWidthObj)

  return {}
}

/**
 * Renders MaxWidthOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param maxWidthObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(maxWidthObj: MaxWidthOpt): Partial<MaxWidthCSS> {
  let css = {}
  if (objValueExists(maxWidthObj, "unit") || objValueExists(maxWidthObj, "value")) {
    css = { "max-width": `${maxWidthObj.value}${maxWidthObj.unit}` }
  }

  return css
}

export function mergeBob2(maxWidthObj: MaxWidthOpt | undefined, defaultMaxWidthObj: MaxWidth): MaxWidthOpt {
  // no filter object
  if(!maxWidthObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = maxWidthObj?.enable ?? defaultMaxWidthObj.enable
  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(maxWidthObj, 'value') ||
    objValueExists(maxWidthObj, 'unit')
  ) {
    const value = get2WithNull(maxWidthObj?.value, defaultMaxWidthObj.value)
    const unit = get2WithNull(maxWidthObj?.unit, defaultMaxWidthObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export function mergeBob3(
  maxWidthObj: MaxWidthOpt | undefined,
  widthDesktopBehaviour: MaxWidthOpt | undefined,
  widthDefaultBreakpoint: MaxWidthOpt | undefined,
  defaultMaxWidthObj: MaxWidth
): MaxWidthOpt {
  // no filter object
  if(!maxWidthObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = 
    maxWidthObj?.enable ?? widthDesktopBehaviour?.enable ?? widthDefaultBreakpoint?.enable ?? defaultMaxWidthObj.enable

  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(maxWidthObj, 'value') ||
    objValueExists(maxWidthObj, 'unit')
  ) {
    const value = get2WithNull(maxWidthObj?.value, defaultMaxWidthObj.value)
    const unit = get2WithNull(maxWidthObj?.unit, defaultMaxWidthObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export type { MaxWidthCSS, StylesMaxWidth, MaxWidthProps, MaxWidth, MaxWidthOpt }