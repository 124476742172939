import React from 'react'
import ComponentHandler from '../../componentHandler'
import { ExpManager, PageTypes } from '../../../../modules/shared-modules/experienceManager/types/pageTypes'

interface ObjectHandlerProps {
  component: any,
  pageResponse: PageTypes,
  expManager: ExpManager,
  matchOrder: number
}

const ObjectHandler = (props: ObjectHandlerProps) => {
  const component = props.component
  const pageResponse = props.pageResponse

  /**
   * Render Every object children
   */
  const childrenObjects = component.children.map((objectId: string, idx: number) => {
    return (
      <ComponentHandler
        position={idx}
        key={objectId}
        component={pageResponse.objects[objectId]}
        pageResponse={pageResponse}
        matchOrder={props.matchOrder}
        expManager={props.expManager}
      />
    )
  })
  return childrenObjects
}

export default React.memo(ObjectHandler)