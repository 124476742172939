import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import * as publicPageActions from "../../../store/shared-store/actions/publicPageActions"
import { Tooltip } from "react-tippy"

type Props = {
  toggle?: boolean
  messages?: string
  alertType?: string
}

const WarningSystem: React.FC<Props> = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { warningState } = useSelector((state: any) => state.experienceManagerReducer)

  const actions = bindActionCreators({ ...publicPageActions }, dispatch)

  if (props.toggle) {
    return (
      <Tooltip
        title={warningState ? "Hide Information Notes on Preview" : "Show Information Notes on Preview"}
        position='bottom'
        trigger='mouseenter'
        arrow={true}
        theme='light'
        animation='fade'
        style={{ display: "flex" }}
      >
        <button
          onClick={() => actions.warningSystemEM(!warningState)}
          className={`btn btn-transparent${warningState ? " selected" : ""}`}
        >
          <img src='https://cdn.fluxio.cloud/fluxio/fluxio_icons_infomessages.svg' alt='warning' />
        </button>
      </Tooltip>
    )
  }

  if (warningState)
    return (
      <div className={`alert ${props.alertType}`} role='alert'>
        {props.messages}
      </div>
    )

  return <></>
}

export default React.memo(WarningSystem)
