import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { get2WithNull } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const UnitCodec = union([
  literal("px"),
  literal("%"),
  literal("vw"),
  literal("vh"),
  literal("auto"),
])
const MinHeightPropsCodec = type({ value: numberC, unit: UnitCodec })
const MinHeightCodec = intersection([
  type({ enable: booleanC }),
  MinHeightPropsCodec,
])

const MinHeightPropsOptCodec = partial({
  unit: nullable(UnitCodec),
  value: nullable(numberC),
})
const MinHeightOptCodec = intersection([
  partial({ enable: nullable(booleanC) }),
  MinHeightPropsOptCodec,
])

const StylesMinHeightCodec = intersection([
  type({ minHeight: MinHeightCodec }),
  partial({
    behaviour: partial({
      active: partial({ minHeight: MinHeightPropsOptCodec }),
      hover: partial({ minHeight: MinHeightPropsOptCodec }),
    }),
    mobile: intersection([
      partial({ minHeight: MinHeightPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ minHeight: MinHeightPropsOptCodec }),
          hover: partial({ minHeight: MinHeightPropsOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ minHeight: MinHeightPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ minHeight: MinHeightPropsOptCodec }),
          hover: partial({ minHeight: MinHeightPropsOptCodec }),
        }),
      }),
    ]),
  }),
])

type MinHeight = TypeOf<typeof MinHeightCodec>
type MinHeightProps = TypeOf<typeof MinHeightPropsCodec>
type MinHeightOpt = TypeOf<typeof MinHeightOptCodec>
type StylesMinHeight = TypeOf<typeof StylesMinHeightCodec>
type MinHeightCSS = {
  "min-height": string
}


export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<MinHeightCSS> {
  const styles = StylesMinHeightCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return {
    "min-height": "auto",
  }
}

export function cssRender(
  stylesObj: StylesMinHeight,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<MinHeightCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.minHeight)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.minHeight,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.minHeight, stylesObj.minHeight)
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.minHeight, mergeBob2(stylesObj?.[breakpoint]?.minHeight, stylesObj.minHeight))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.minHeight,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.minHeight,
          stylesObj?.behaviour?.[behaviourState]?.minHeight,
          stylesObj?.[breakpoint]?.minHeight,
          stylesObj.minHeight
        )
      )
    }
  }
}

export function renderBob(minHeightObj: MinHeight): Partial<MinHeightCSS> {
  if (!minHeightObj.enable) return {}

  return render(minHeightObj)
}

export function render(minHeightObj: MinHeightProps): MinHeightCSS {
  const heightValue = minHeightObj.value
  const heightUnit = minHeightObj.unit
  return {
    "min-height": `${heightValue}${heightUnit}`,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param minHeightObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(
  defaultMinHeightObjObj: MinHeight,
  minHeightObj: MinHeightOpt | undefined
): Partial<MinHeightCSS> {
  if (minHeightObj?.enable === false) {
    if (defaultMinHeightObjObj.enable) return { "min-height": "auto" }

    return {}
  }

  if (minHeightObj?.enable) return renderOpt(minHeightObj)

  return {}
}

/**
 * Renders MinHeightOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param minHeightObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(minHeightObj: MinHeightOpt): Partial<MinHeightCSS> {
  let css = {}
  if (objValueExists(minHeightObj, "unit") || objValueExists(minHeightObj, "value")) {
    css = { "min-height": `${minHeightObj.value}${minHeightObj.unit}` }
  }

  return css
}

export function mergeBob2(minHeightObj: MinHeightOpt | undefined, defaultMinHeightObj: MinHeight): MinHeightOpt {
  // no filter object
  if(!minHeightObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = minHeightObj?.enable ?? defaultMinHeightObj.enable
  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(minHeightObj, 'value') ||
    objValueExists(minHeightObj, 'unit')
  ) {
    const value = get2WithNull(minHeightObj?.value, defaultMinHeightObj.value)
    const unit = get2WithNull(minHeightObj?.unit, defaultMinHeightObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export function mergeBob3(
  minHeightObj: MinHeightOpt | undefined,
  minHeightDesktopBehaviour: MinHeightOpt | undefined,
  minHeightDefaultBreakpoint: MinHeightOpt | undefined,
  defaultMinHeightObj: MinHeight
): MinHeightOpt {
  // no filter object
  if(!minHeightObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = 
    minHeightObj?.enable ?? minHeightDesktopBehaviour?.enable ?? minHeightDefaultBreakpoint?.enable ?? defaultMinHeightObj.enable

  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(minHeightObj, 'value') ||
    objValueExists(minHeightObj, 'unit')
  ) {
    const value = get2WithNull(minHeightObj?.value, defaultMinHeightObj.value)
    const unit = get2WithNull(minHeightObj?.unit, defaultMinHeightObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export type { MinHeightCSS, StylesMinHeight, MinHeightProps, MinHeight, MinHeightOpt }