import React, { useState, RefObject } from "react"
import styled, { css } from "styled-components"
import VideoPlayer from "./videoPlayer"
import { cssRenderUnsafe as VideoStyleCssRenderUnsafe } from "../../bobStyles/videoStyle"
import { BobNmedia } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { responsiveStyles } from "../bobMediaV2Handler"
import { handleMediaQueryRestrictions, handleStateStyles } from "../../bobHandlerV2"
import { ResponsiveCSSProps, BobMediaStyles } from "../../bobTypes"
import { PageStylesheet } from "../../../../../../modules/shared-modules/stylesheet/stylesheetTypes"

interface VideoPlayerCustomProps {
  id?: string
  mediaStyles: BobNmedia
  url: string
  controls: boolean
  playPauseAsset: string
  className: string
  poster: string
  preload: "none" | "metadata" | "auto" | ""
  stylesheet: PageStylesheet | undefined
  setRef: (x: React.RefObject<HTMLDivElement>) => void | undefined
}

const CustomVideoContainer: any = styled.div`
  ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.desktop}
  ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    props.hover.isEnabled.desktop
      ? css`
          @media all ${handleMediaQueryRestrictions(props.hover.isEnabled.mobile, props.hover.isEnabled.tablet)} {
            &:hover {
              ${props.hover.desktop}
            }
          }
        `
      : {}}

${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    Object.keys(props.tablet).length > 0 || Object.keys(props.hover.tablet).length > 0
      ? css`
          @media all and (max-width: 992px) and (min-width: 767px) {
            ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.tablet}
            ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
              props.hover.isEnabled.tablet && Object.keys(props.hover.tablet).length > 0
                ? css`
                    &:hover {
                      ${props.hover.tablet}
                    }
                  `
                : {}}
          }
        `
      : {}}

${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    Object.keys(props.mobile).length > 0 || Object.keys(props.hover.mobile).length > 0
      ? css`
          @media all and (max-width: 766px) {
            ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.mobile}
            ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
              props.hover.isEnabled.mobile && Object.keys(props.hover.mobile).length > 0
                ? css`
                    &:hover {
                      ${props.hover.mobile}
                    }
                  `
                : {}}
          }
        `
      : {}}
`

const CustomVideo: any = styled.div`
  ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.desktop}
  ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    props.hover.isEnabled.desktop
      ? css`
          @media all ${handleMediaQueryRestrictions(props.hover.isEnabled.mobile, props.hover.isEnabled.tablet)} {
            &:hover {
              ${props.hover.desktop}
            }
          }
        `
      : {}}

${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    Object.keys(props.tablet).length > 0 || Object.keys(props.hover.tablet).length > 0
      ? css`
          @media all and (max-width: 992px) and (min-width: 767px) {
            ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.tablet}
            ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
              props.hover.isEnabled.tablet && Object.keys(props.hover.tablet).length > 0
                ? css`
                    &:hover {
                      ${props.hover.tablet}
                    }
                  `
                : {}}
          }
        `
      : {}}

${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    Object.keys(props.mobile).length > 0 || Object.keys(props.hover.mobile).length > 0
      ? css`
          @media all and (max-width: 766px) {
            ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.mobile}
            ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
              props.hover.isEnabled.mobile && Object.keys(props.hover.mobile).length > 0
                ? css`
                    &:hover {
                      ${props.hover.mobile}
                    }
                  `
                : {}}
          }
        `
      : {}}
`

const VideoPlayerCustom: React.FC<VideoPlayerCustomProps> = (props): JSX.Element => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const pageLabels = props.stylesheet?.labels

  const desktopContainerStyles = responsiveStyles(props.mediaStyles, pageLabels, "desktop", "default")
  const tabletContainerStyles = responsiveStyles(props.mediaStyles, pageLabels, "tablet", "default")
  const mobileContainerStyles = responsiveStyles(props.mediaStyles, pageLabels, "mobile", "default")
  const hoverContainerStyles = handleStateStyles<BobNmedia, BobMediaStyles>(
    props.mediaStyles,
    responsiveStyles(props.mediaStyles, pageLabels, "desktop", "hover"),
    responsiveStyles(props.mediaStyles, pageLabels, "tablet", "hover"),
    responsiveStyles(props.mediaStyles, pageLabels, "mobile", "hover")
  )

  const desktopVideoStyles = {
    ...VideoStyleCssRenderUnsafe(props.mediaStyles, "desktop", "default"),
  }
  const tabletVideoStyles = {
    ...VideoStyleCssRenderUnsafe(props.mediaStyles, "tablet", "default"),
  }
  const mobileVideoStyles = {
    ...VideoStyleCssRenderUnsafe(props.mediaStyles, "mobile", "default"),
  }
  const hoverStyles = handleStateStyles<BobNmedia, BobMediaStyles>(
    props.mediaStyles,
    VideoStyleCssRenderUnsafe(props.mediaStyles, "desktop", "hover"),
    VideoStyleCssRenderUnsafe(props.mediaStyles, "tablet", "hover"),
    VideoStyleCssRenderUnsafe(props.mediaStyles, "mobile", "hover")
  )
  const DOMid = props.id ? { id: props.id } : {}

  return (
    <CustomVideoContainer
      {...DOMid}
      desktop={desktopContainerStyles}
      tablet={tabletContainerStyles}
      mobile={mobileContainerStyles}
      hover={hoverContainerStyles}
      className={"custom-video-player " + props.className}
    >
      <div className='play-pause-wrap' onClick={() => setIsPlaying(!isPlaying)}>
        <img
          className={`play-pause-asset-wrapp play-pause-asset ${isPlaying ? "playing" : "paused"}`}
          src={props.playPauseAsset}
          alt='play'
        />
      </div>
      <CustomVideo
        as={VideoPlayer}
        setRef={(mediaRef: RefObject<HTMLDivElement>) => props.setRef(mediaRef)}
        desktop={desktopVideoStyles}
        tablet={tabletVideoStyles}
        mobile={mobileVideoStyles}
        hover={hoverStyles}
        className={""}
        style={{ width: "100%", height: "100%" }}
        playing={isPlaying}
        url={props.url}
        controls={props.controls}
        poster={props.poster}
        preload={props.preload}
      />
    </CustomVideoContainer>
  )
}

export default VideoPlayerCustom
