import {
  PAGE_TREE_FINDER_CONTROLLER,
  PAGE_TREE_EDIT_SYMBOL_STATE,
  PAGE_TREE_FINDER_EXPANDED,
} from "./actionTypes"
import { AppDispatch } from "../types"

export function pageTreeFinderController(finderState: any) {
  return {
    type: PAGE_TREE_FINDER_CONTROLLER,
    finderState,
  }
}

export function controlPageTreeFinder(finderState: any) {
  return (dispatch: AppDispatch) => {
    dispatch(pageTreeFinderController(finderState))
  }
}

/************************************************* */

export function pageTreeEditSymbolState(symbolObj: any, tabToOpen: any) {
  return {
    type: PAGE_TREE_EDIT_SYMBOL_STATE,
    symbolObj,
    tabToOpen,
  }
}

export function editSymbolState(symbolObj: any, tabToOpen = "template") {
  return (dispatch: AppDispatch) => {
    dispatch(pageTreeEditSymbolState(symbolObj, tabToOpen))
  }
}

/************************************************* */

export function pageTreeFinderExpanded(isExpanded: boolean) {
  return {
    type: PAGE_TREE_FINDER_EXPANDED,
    isExpanded,
  }
}

export function pageTreeFinderExpand(isExpanded: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch(pageTreeFinderExpanded(isExpanded))
  }
}
