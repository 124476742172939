import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  string as stringC,
  partial,
  boolean as booleanC,
  literal,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { get2WithNull, get2WithNull4Enable, get3WithNull4Enable } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */

// props only, no enable
const ImagePropsCodec = type({
  enable: booleanC,
  size: union([literal("cover"), literal("contain"), literal("auto")]),
  position: stringC,
})
const ImageCodec = intersection([type({ enable: booleanC }), ImagePropsCodec])

// props only, no enable
const ImagePropsOptCodec = partial({
  size: nullable(union([literal("cover"), literal("contain"), literal("auto")])),
  position: nullable(stringC),
})
const ImageOptCodec = intersection([partial({ enable: nullable(booleanC) }), ImagePropsOptCodec])

const StylesImageCodec = intersection([
  type({ image: ImageCodec }),
  partial({
    behaviour: partial({
      active: partial({
        image: ImageOptCodec,
      }),
      hover: partial({
        image: ImageOptCodec,
      }),
    }),
    mobile: partial({
      image: ImageOptCodec,
      behaviour: partial({
        active: partial({
          image: ImageOptCodec,
        }),
        hover: partial({
          image: ImageOptCodec,
        }),
      }),
    }),
    tablet: partial({
      image: ImageOptCodec,
      behaviour: partial({
        active: partial({
          image: ImageOptCodec,
        }),
        hover: partial({
          image: ImageOptCodec,
        }),
      }),
    }),
  }),
])

const GSImageCodec = intersection([
  ImagePropsCodec,
  partial({
    behaviour: partial({
      active: ImagePropsOptCodec,
      hover: ImagePropsOptCodec,
    }),
    mobile: intersection([
      ImagePropsOptCodec,
      partial({
        behaviour: partial({
          active: ImagePropsOptCodec,
          hover: ImagePropsOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      ImagePropsOptCodec,
      partial({
        behaviour: partial({
          active: ImagePropsOptCodec,
          hover: ImagePropsOptCodec,
        }),
      }),
    ]),
  }),
])

type Image = TypeOf<typeof ImageCodec>
type ImageProps = TypeOf<typeof ImagePropsCodec>
type ImageOpt = TypeOf<typeof ImageOptCodec>
type ImagePropsOpt = TypeOf<typeof ImagePropsOptCodec>
type StylesImage = TypeOf<typeof StylesImageCodec>
type GSImage = TypeOf<typeof GSImageCodec>
type ImageCSS = { "object-fit": string; "object-position": string }

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined // TODO: fix when working on other bobs
): Partial<ImageCSS> {
  const styles = StylesImageCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, foundationStyle)
  console.warn(decoderErrors(styles))
  return {}
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined // TODO: Work on when foundation is added to global styles
): Partial<ImageCSS> {
  const gs = GSImageCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, foundationStyle)
  console.warn(decoderErrors(gs))
  return {}
}

export function cssRender(
  stylesObj: StylesImage,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined
): Partial<ImageCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.image, foundationStyle)
    }
    //hover | active
    else {
      return renderBobOpt(mergeBob2(stylesObj?.behaviour?.[behaviourState]?.image, stylesObj.image), foundationStyle)
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(mergeBob2(stylesObj?.[breakpoint]?.image, stylesObj.image), foundationStyle)
    }
    //hover | active
    else {
      return renderBobOpt(
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.image,
          stylesObj?.behaviour?.[behaviourState]?.image,
          stylesObj?.[breakpoint]?.image,
          stylesObj.image
        ),
        foundationStyle
      )
    }
  }
}

export function globalStyleCssRender(
  stylesObj: GSImage,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined
): Partial<ImageCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj, foundationStyle)
    }
    //hover | active
    else {
      return renderOpt(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj), foundationStyle)
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(merge2(stylesObj?.[breakpoint], stylesObj), foundationStyle)
    }
    //hover | active
    else {
      return renderOpt(
        merge3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
          stylesObj.behaviour?.[behaviourState],
          stylesObj?.[breakpoint],
          stylesObj
        ),
        foundationStyle
      )
    }
  }
}

export function renderBob(imageObj: Image, foundationStyle: FColor | undefined): Partial<ImageCSS> {
  return render(imageObj, foundationStyle)
}

export function renderBobOpt(imageObj: ImageOpt, foundationStyle: FColor | undefined): Partial<ImageCSS> {
  if (imageObj) return renderOpt(imageObj, foundationStyle)

  return {}
}

export function render(imageObj: ImageProps, foundationStyle: FColor | undefined): ImageCSS {
  return {
    "object-position": imageObj.position,
    "object-fit": imageObj.size,
  }
}

export function renderOpt(imageObj: ImagePropsOpt, foundationStyle: FColor | undefined): Partial<ImageCSS> {
  let css = {}
  if (valueExists(imageObj?.position)) css = { ...css, "object-position": imageObj?.position }
  if (valueExists(imageObj?.size)) css = { ...css, "object-fit": imageObj?.size }

  return css
}

export function cssProperty(cssValue: string): ImageCSS {
  return { "object-fit": cssValue, "object-position": cssValue }
}

/**
 *
 * @param imageObj
 * @param defaultImageObj
 * @returns Image
 *
 */
export function mergeBob2(imageObj: ImageOpt | undefined, defaultImageObj: Image): ImageOpt {
  const enable = get2WithNull4Enable(imageObj?.enable, defaultImageObj.enable)

  return {
    enable,
    position: imageObj?.position,
    size: imageObj?.size,
  }
}

/**
 *
 * @param imageObj
 * @param defaultImageObj
 * @returns ImageProps
 *
 */
export function merge2(imageObj: ImagePropsOpt | undefined, defaultImageObj: ImageProps): ImagePropsOpt {
  const position = get2WithNull(imageObj?.position, defaultImageObj.position)
  const size = get2WithNull(imageObj?.size, defaultImageObj.size)

  return {
    position,
    size,
  }
}

/**
 *
 * @param imageObj
 * @param imageDefaultBreakpoint
 * @param defaultImageObj
 * @returns Image
 */
export function mergeBob3(
  imageObj: ImageOpt | undefined,
  imageDesktopBehaviour: ImageOpt | undefined,
  imageDefaultBreakpoint: ImageOpt | undefined,
  defaultImageObj: Image
): ImageOpt {
  const enable = get3WithNull4Enable(
    imageObj?.enable,
    imageDesktopBehaviour?.enable,
    imageDefaultBreakpoint?.enable,
    defaultImageObj.enable
  )

  return {
    enable,
    position: imageObj?.position,
    size: imageObj?.size,
  }
}

/**
 *
 * @param imageObj
 * @param imageDefaultBreakpoint
 * @param defaultImageObj
 * @returns Image
 */
export function merge3(
  imageObj: ImagePropsOpt | undefined,
  imageDesktopBehaviour: ImagePropsOpt | undefined,
  imageDefaultBreakpoint: ImagePropsOpt | undefined,
  defaultImageObj: ImageProps
): ImagePropsOpt {
  const position =
    imageObj?.position ??
    imageDesktopBehaviour?.position ??
    imageDefaultBreakpoint?.position ??
    defaultImageObj.position
  const size = imageObj?.size ?? imageDesktopBehaviour?.size ?? imageDefaultBreakpoint?.size ?? defaultImageObj.size

  return {
    position,
    size,
  }
}

export type { ImageCSS, StylesImage, GSImage, ImageProps, ImagePropsOpt, Image, ImageOpt }
