import { BehaviourState_, Breakpoint, ResponsiveData } from "../experienceManager/finder/inputs/bobControllerTypes"
import { FColor, FoundationStyle } from "./foundationStylesTypes"
import { EMEditorType, PageTypes } from "../experienceManager/types/pageTypes"
import cloneDeep from "lodash/cloneDeep"
import { SingleObject } from "../experienceManager/types/singleObjectTypes"
import { SelectedController } from "../experienceManager/types/reduxTypes"
import { handleObjectsListType } from "../utilities/components"
import { objValueExists } from "../utilities/utils"

export function hasFoundationStyleAttached(
  bobTemplate: any,
  controller: SelectedController["controller"],
  property: SelectedController["property"],
  state: BehaviourState_ | undefined,
  breakpoint: Breakpoint
): boolean {
  let isFoundationAttached = bobTemplate[controller].foundationColor?.[property]

  // handle breakpoint
  if (breakpoint !== "desktop")
    isFoundationAttached = bobTemplate[breakpoint]?.[controller]?.foundationColor?.[property]

  // handle state
  if (state && state !== "default") {
    if (breakpoint !== "desktop")
      isFoundationAttached = bobTemplate[breakpoint]?.behaviour?.[state]?.[controller]?.foundationColor?.[property]
    else isFoundationAttached = bobTemplate.behaviour?.[state]?.[controller]?.foundationColor?.[property]
  }

  return isFoundationAttached ? true : false
}

export function getAttachedFoundationStyle(
  bobTemplate: any,
  foundationsList: PageTypes["foundations"],
  controller: SelectedController["controller"],
  property: SelectedController["property"],
  state: BehaviourState_ | undefined,
  breakpoint: Breakpoint
): FoundationStyle | undefined {
  let attachedFoundationId = bobTemplate?.[controller]?.foundationColor?.[property]

  // handle breakpoint
  if (breakpoint !== "desktop")
    attachedFoundationId = bobTemplate?.[breakpoint]?.[controller]?.foundationColor?.[property]

  // handle state
  if (state && state !== "default") {
    if (breakpoint !== "desktop")
      attachedFoundationId = bobTemplate?.[breakpoint]?.behaviour?.[state]?.[controller]?.foundationColor?.[property]
    else attachedFoundationId = bobTemplate?.behaviour?.[state]?.[controller]?.foundationColor?.[property]
  }

  const attachedFoundation = foundationsList?.[attachedFoundationId]
  return attachedFoundation
}

/**
 * Get foundation based on breakpoint and state
 * Default value is the default template foundation
 *
 * if the breakpoint/state has a foundation that one is used
 * if the breakpoint/state has value written remove default template foundation
 *
 * @param bobTemplate
 * @param foundationsList
 * @param controller
 * @param property
 * @param state
 * @param breakpoint
 * @returns
 */
export function getResponsiveAttachedFoundationStyle(
  bobTemplate: any,
  foundationsList: PageTypes["foundations"],
  controller: SelectedController["controller"],
  property: SelectedController["property"],
  state: BehaviourState_ | undefined,
  breakpoint: Breakpoint
): FoundationStyle | undefined {
  const defaultController = bobTemplate[controller]

  /**
   * handle default template
   * this may be used as the default value for the breakpoint/state that doenst have a foundation or value written
   */
  let attachedFoundationId = defaultController?.foundationColor?.[property]

  /**
   * handle breakpoint template
   */
  if (breakpoint !== "desktop") {
    const breakpointFoundation = bobTemplate[breakpoint]?.[controller]?.foundationColor?.[property]
    if (breakpointFoundation) {
      attachedFoundationId = breakpointFoundation
    }
    // should fallback default foundation be used
    else if (!shouldUseFallbackDefaultValue(defaultController, bobTemplate[breakpoint]?.[controller]?.[property])) {
      attachedFoundationId = undefined
    }
  }

  /**
   * handle state template
   */
  if (state && state !== "default") {
    const stateFoundation = bobTemplate.behaviour?.[state]?.[controller]?.foundationColor?.[property]
    if (stateFoundation) {
      attachedFoundationId = stateFoundation
    }
    // should fallback default foundation be used
    else if (
      !shouldUseFallbackDefaultValue(defaultController, bobTemplate.behaviour?.[state]?.[controller]?.[property])
    ) {
      attachedFoundationId = undefined
    }

    /**
     * handle state breakpoint
     */
    if (breakpoint !== "desktop") {
      const stateBreakpointFoundation =
        bobTemplate[breakpoint]?.behaviour?.[state]?.[controller]?.foundationColor?.[property]
      if (stateBreakpointFoundation) {
        attachedFoundationId = stateBreakpointFoundation
      }
      // should fallback default foundation be used
      else if (
        !shouldUseFallbackDefaultValue(
          defaultController,
          bobTemplate[breakpoint]?.behaviour?.[state]?.[controller]?.[property]
        )
      ) {
        attachedFoundationId = undefined
      }
    }
  }

  const attachedFoundation = foundationsList?.[attachedFoundationId]
  return attachedFoundation
}

/**
 * dont use fallback default value if:
 * - default template enable prop exists and is false
 * - breakpoint template value exists
 *
 *  TODO: types
 *
 * @param defaultController
 * @param breakpointControllerValue
 * @returns
 */
export function shouldUseFallbackDefaultValue(defaultController: any, breakpointControllerValue: any): boolean {
  if (
    (objValueExists(defaultController, "enable") && defaultController.enable === false) ||
    breakpointControllerValue
  ) {
    return false
  }
  return true
}

export function attachFoundationStyleToPage(
  foundation: FoundationStyle,
  objectSelected: SingleObject,
  hubContent: PageTypes,
  bobOpen: string,
  breakpoint: Breakpoint,
  state: BehaviourState_ | undefined,
  controller: SelectedController["controller"],
  property: SelectedController["property"],
  emEditorType: EMEditorType
): { hubContent: PageTypes; selectedObject: any } {
  let clonedHubContent = cloneDeep(hubContent)
  let selectedObject: any = cloneDeep(objectSelected)

  //trigger publish btn
  clonedHubContent.isOutdated = true

  // add foundation to foundations list
  if (clonedHubContent.foundations)
    clonedHubContent.foundations = {
      ...clonedHubContent.foundations,
      [foundation.uuid]: foundation,
    }
  else
    clonedHubContent.foundations = {
      [foundation.uuid]: foundation,
    }

  // add foundation to hubObject objects list
  let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)
  let bobTemplate = handleBobTemplate(objects[selectedObject.uuid].styles.bobs[bobOpen], breakpoint, state)
  if (!bobTemplate[controller]) {
    bobTemplate[controller] = {
      foundationColor: {
        [property]: foundation.uuid,
      },
    }
  } else {
    bobTemplate[controller].foundationColor = {
      [property]: foundation.uuid,
    }
  }

  // add foundation to selectedObject
  let selectedObjBobTemplate = handleBobTemplate(selectedObject.styles.bobs[bobOpen], breakpoint, state)
  if (!selectedObjBobTemplate[controller]) {
    selectedObjBobTemplate[controller] = {
      foundationColor: {
        [property]: foundation.uuid,
      },
    }
  } else {
    selectedObjBobTemplate[controller].foundationColor = {
      [property]: foundation.uuid,
    }
  }

  return { hubContent: clonedHubContent, selectedObject }
}

export function dettachFoundationStyleFromPage(
  foundation: FColor,
  hubContent: PageTypes,
  objectSelected: SingleObject,
  bobOpen: string,
  breakpoint: Breakpoint,
  state: BehaviourState_ | undefined,
  controller: SelectedController["controller"],
  property: SelectedController["property"],
  emEditorType: EMEditorType
): { hubContent: PageTypes; selectedObject: any } {
  let clonedHubContent = cloneDeep(hubContent)
  let selectedObject: any = cloneDeep(objectSelected)

  //trigger publish btn
  clonedHubContent.isOutdated = true

  // remove foundation from hubObject objects list
  let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)
  const bobTemplate = handleBobTemplate(objects[selectedObject.uuid].styles.bobs[bobOpen], breakpoint, state)
  bobTemplate[controller][property] = foundation.color
  delete bobTemplate[controller].foundationColor

  // remove foundation from selectedObject
  const controllerTemplate = handleBobTemplate(selectedObject.styles.bobs[bobOpen], breakpoint, state)
  controllerTemplate[controller][property] = foundation.color
  delete controllerTemplate[controller].foundationColor

  return { hubContent: clonedHubContent, selectedObject }
}

  /**
   * Writes object that represents the strcture where a foundation is attached to,
   * giving the path to the foundation from the bob->controller->property in a json object format.
   * This structure is expected by the API
   *
   * @param foundationValue
   * @param controller
   * @param property
   * @param responsiveData
   * @returns
   */
  export function handleFoundationPropertyRelation(
    foundationValue: string | null | undefined,
    controller: SelectedController["controller"],
    property: SelectedController["property"],
    responsiveData: ResponsiveData<string>
  ) {
    let foundationStyle: any = {
      property: {
        [controller]: {
          [property]: foundationValue,
        },
      },
    }

    // handle breakpoint
    if (responsiveData.breakpoint !== "desktop")
      foundationStyle.property = {
        [responsiveData.breakpoint]: {
          ...foundationStyle.property,
        },
      }

    // handle state
    if (responsiveData.state && responsiveData.state !== "default") {
      if (responsiveData.breakpoint !== "desktop")
        foundationStyle.property = {
          [responsiveData.breakpoint]: {
            behaviour: {
              [responsiveData.state]: {
                ...foundationStyle.property[responsiveData.breakpoint],
              },
            },
          },
        }
      else
        foundationStyle.property = {
          behaviour: {
            [responsiveData.state]: {
              ...foundationStyle.property,
            },
          },
        }
    }
    return foundationStyle
  }

  /**
   * Get bob template based on breakpoint and behaviour state
   * @param bobTemplate
   * @param breakpoint
   * @param state
   * @returns default bob template, or breakpoint template, or behaviour state template
   */
  export function handleBobTemplate(
    bobTemplate: { [key: string]: any },
    breakpoint: any,
    state: any
  ): { [key: string]: any } {
    let template = bobTemplate

    /**
     * handle breakpoint
     */
    if (breakpoint !== "desktop") {
      // if breakpoint template doens't exist, create it
      if (!bobTemplate[breakpoint]) bobTemplate[breakpoint] = {}
      template = bobTemplate[breakpoint]
    }

    /**
     * handle state
     */
    if (state && state !== "default") {
      // handle state responsive
      if (breakpoint !== "desktop") {
        // if breakpoint template doens't exist, create it
        if (!bobTemplate[breakpoint]) bobTemplate[breakpoint] = {}
        if (!bobTemplate[breakpoint].behaviour) bobTemplate[breakpoint].behaviour = {}
        if (!bobTemplate[breakpoint].behaviour[state]) bobTemplate[breakpoint].behaviour[state] = {}
        template = bobTemplate[breakpoint]?.behaviour?.[state]
      }
      // handle state default
      else {
        // if state template doens't exist, create it
        if (!bobTemplate.behaviour[state]) bobTemplate.behaviour[state] = {}
        template = bobTemplate.behaviour[state]
      }
    }

    return template
  }