import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { get2WithNull } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const UnitCodec = union([
  literal("px"),
  literal("%"),
  literal("vw"),
  literal("vh"),
  literal("auto"),
])
const MinWidthPropsCodec = type({ value: numberC, unit: UnitCodec })
const MinWidthCodec = intersection([
  type({ enable: booleanC }),
  MinWidthPropsCodec,
])

const MinWidthPropsOptCodec = partial({
  unit: nullable(UnitCodec),
  value: nullable(numberC),
})
const MinWidthOptCodec = intersection([
  partial({ enable: nullable(booleanC) }),
  MinWidthPropsOptCodec,
])

const StylesMinWidthCodec = intersection([
  type({ minWidth: MinWidthCodec }),
  partial({
    behaviour: partial({
      active: partial({ minWidth: MinWidthPropsOptCodec }),
      hover: partial({ minWidth: MinWidthPropsOptCodec }),
    }),
    mobile: intersection([
      partial({ minWidth: MinWidthPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ minWidth: MinWidthPropsOptCodec }),
          hover: partial({ minWidth: MinWidthPropsOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ minWidth: MinWidthPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ minWidth: MinWidthPropsOptCodec }),
          hover: partial({ minWidth: MinWidthPropsOptCodec }),
        }),
      }),
    ]),
  }),
])

type MinWidth = TypeOf<typeof MinWidthCodec>
type MinWidthProps = TypeOf<typeof MinWidthPropsCodec>
type MinWidthOpt = TypeOf<typeof MinWidthOptCodec>
type StylesMinWidth = TypeOf<typeof StylesMinWidthCodec>
type MinWidthCSS = {
  "min-width": string
}


export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<MinWidthCSS> {
  const styles = StylesMinWidthCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return {
    "min-width": "auto",
  }
}

export function cssRender(
  stylesObj: StylesMinWidth,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<MinWidthCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.minWidth)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.minWidth,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.minWidth, stylesObj.minWidth)
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.minWidth, mergeBob2(stylesObj?.[breakpoint]?.minWidth, stylesObj.minWidth))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.minWidth,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.minWidth,
          stylesObj?.behaviour?.[behaviourState]?.minWidth,
          stylesObj?.[breakpoint]?.minWidth,
          stylesObj.minWidth
        )
      )
    }
  }
}

export function renderBob(minWidthObj: MinWidth): Partial<MinWidthCSS> {
  if (!minWidthObj.enable) return {}

  return render(minWidthObj)
}

export function render(minWidthObj: MinWidthProps): MinWidthCSS {
  const widthValue = minWidthObj.value
  const widthUnit = minWidthObj.unit
  return {
    "min-width": `${widthValue}${widthUnit}`,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param minWidthObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(
  defaultMinWidthObjObj: MinWidth,
  minWidthObj: MinWidthOpt | undefined
): Partial<MinWidthCSS> {
  if (minWidthObj?.enable === false) {
    if (defaultMinWidthObjObj.enable) return { "min-width": "auto" }

    return {}
  }

  if (minWidthObj?.enable) return renderOpt(minWidthObj)

  return {}
}

/**
 * Renders MinWidthOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param minWidthObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(minWidthObj: MinWidthOpt): Partial<MinWidthCSS> {
  let css = {}
  if (objValueExists(minWidthObj, "unit") || objValueExists(minWidthObj, "value")) {
    css = { "min-width": `${minWidthObj.value}${minWidthObj.unit}` }
  }

  return css
}

export function mergeBob2(minWidthObj: MinWidthOpt | undefined, defaultMinWidthObj: MinWidth): MinWidthOpt {
  // no filter object
  if(!minWidthObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = minWidthObj?.enable ?? defaultMinWidthObj.enable
  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(minWidthObj, 'value') ||
    objValueExists(minWidthObj, 'unit')
  ) {
    const value = get2WithNull(minWidthObj?.value, defaultMinWidthObj.value)
    const unit = get2WithNull(minWidthObj?.unit, defaultMinWidthObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export function mergeBob3(
  minWidthObj: MinWidthOpt | undefined,
  widthDesktopBehaviour: MinWidthOpt | undefined,
  widthDefaultBreakpoint: MinWidthOpt | undefined,
  defaultMinWidthObj: MinWidth
): MinWidthOpt {
  // no filter object
  if(!minWidthObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = 
    minWidthObj?.enable ?? widthDesktopBehaviour?.enable ?? widthDefaultBreakpoint?.enable ?? defaultMinWidthObj.enable

  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(minWidthObj, 'value') ||
    objValueExists(minWidthObj, 'unit')
  ) {
    const value = get2WithNull(minWidthObj?.value, defaultMinWidthObj.value)
    const unit = get2WithNull(minWidthObj?.unit, defaultMinWidthObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export type { MinWidthCSS, StylesMinWidth, MinWidthProps, MinWidth, MinWidthOpt }