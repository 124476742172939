import { BobTextStyles } from "../../../areas/public-page/shared-public-page/bob/bobTypes"

export const writeRebootCssOVerride = (css: Partial<BobTextStyles>, htmlTag: "a" | "div" | undefined): string => {
  if (htmlTag === "a" && css && hasTextDecoration(css)) {
    let rebootCss = ""
    if ("color" in css) rebootCss += `color: ${css.color};`
    if ("text-decoration-line" in css) rebootCss += `text-decoration-line: ${css["text-decoration-line"]};`
    if ("text-decoration-style" in css) rebootCss += `text-decoration-style: ${css["text-decoration-style"]};`
    if ("text-decoration-color" in css) rebootCss += `text-decoration-color: ${css["text-decoration-color"]};`
    if ("text-decoration-skip-ink" in css) rebootCss += `text-decoration-skip-ink: ${css["text-decoration-skip-ink"]};`
    if ("text-decoration-thickness" in css)
      rebootCss += `text-decoration-thickness: ${css["text-decoration-thickness"]};`

    return `a&:not([href]):not([tabindex]), a&:not([href]):not([tabindex]):hover, a&:not([href]):not([tabindex]):focus {${rebootCss}}`
  }
  return ""
}

const hasTextDecoration = (css: Partial<BobTextStyles>) => {
  return (
    "color" in css ||
    "text-decoration-line" in css ||
    "text-decoration-style" in css ||
    "text-decoration-thickness" in css ||
    "text-decoration-color" in css ||
    "text-decoration-skip-ink" in css
  )
}
