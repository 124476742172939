import React, { Fragment, RefObject, useState } from "react"
import { TextProps } from "."
import { getContent, getStringFieldV2 } from "../../../../../../modules/shared-modules/utilities/components"
import ComponentSelectorEMv2 from "../../../componentSelectorEM/ComponentSelectorEMv2"
import BobText from "../../../bob/bobTextV2/bobTextV2"
import WarningSystem from "../../../../../../modules/shared-modules/experienceManager/warningSystem"

const Text: React.FC<TextProps> = (props): JSX.Element => {
  const [elRef, setElRef] = useState<RefObject<HTMLDivElement> | undefined>(undefined)

  const items = getContent(props.obj, "posts", props.overrides)
  if (items && items.length > 0) {
    return items.map((item: any, idx: any) => {
      return (
        <Fragment key={idx}>
          {props.expManager.enable && !props.expManager.content && (
            <ComponentSelectorEMv2
              uuid={props.obj.uuid}
              masterBob='ntext1'
              obj={props.obj}
              form={props.form}
              editing={props.editing}
              elRef={elRef}
            />
          )}
          <BobText
            setRef={(textRef) => setElRef(textRef)}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            overrides={props.overrides}
            field={"ntext1"}
            post={item}
            value={getStringFieldV2(item, props.obj, props.pageResponse, "ntext1", props.overrides)}
            expManager={props.expManager}
            form={props.form}
          />
        </Fragment>
      )
    })
  } else {
    if (props.expManager.enable) {
      return <WarningSystem alertType='alert-secondary w-100 text-center' messages='Add a single post or a feed' />
    }
  }

  return <Fragment />
}

export default React.memo(Text)
