import React, { Fragment } from 'react'
import BobComponentHandler from '../../../bob/bobComponentHandler'
import ComponentHandler from '../../../../componentHandler'
import {
  getContent,
  handleContentBlock,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import WarningDefaultSystem from "../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { SliderContainerV2Props } from "./"
import carouselComponentStyles from "../../../../../../stylesheets/modules/templates/carouselComponent.module.sass"
import {
  BobBackground,
  isBobBackground,
} from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

type SliderContainerV2State = {
  sliderActive: number
}

class SliderContainerV2 extends React.Component<SliderContainerV2Props, SliderContainerV2State> {
  constructor(props: SliderContainerV2Props) {
    super(props)
    this.state = {
      sliderActive: 0,
    }

    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.goToSlide = this.goToSlide.bind(this)
  }

  sliderContainer = React.createRef<HTMLDivElement>()
  sliderItem = React.createRef<HTMLDivElement>()
  isDown = false
  startX = 0
  scrollLeft = 0

  onMouseDown(e: any) {
    let slider = this.sliderContainer
    this.isDown = true
    if (slider.current) {
      this.startX = e.pageX - slider.current.offsetLeft
      this.scrollLeft = slider.current.scrollLeft
    }
  }

  onMouseMove(e: any) {
    if (!this.isDown) return
    let slider = this.sliderContainer
    if (slider.current) {
      e.preventDefault()
      let x = e.pageX - slider.current.offsetLeft,
        walk = x - this.startX
      slider.current.scrollLeft = this.scrollLeft - walk
    }
  }

  onMouseUp() {
    let slider = this.sliderContainer
    if (slider.current) this.isDown = false
  }

  onMouseLeave() {
    let slider = this.sliderContainer
    if (slider.current) this.isDown = false
  }

  slide(action: string) {
    let slides = this.sliderContainer.current && this.sliderContainer.current.getElementsByClassName("slider-item")
    let newIndex = action === "next" ? this.state.sliderActive + 1 : this.state.sliderActive - 1
    if (slides && (newIndex < 0 || newIndex + 1 > slides.length)) return
    this.setState(
      {
        ...this.state,
        sliderActive: newIndex,
      },
      () => {
        if (slides) {
          let slide = slides[newIndex]
          slide.scrollIntoView({
            behavior: "smooth",
            inline: "start",
            block: "nearest",
          })
        }
      }
    )
  }

  goToSlide(newSlide: number, background1: BobBackground) {
    let slides = this.sliderContainer.current && this.sliderContainer.current.getElementsByClassName("slider-item")
    let amount = background1.grid.columns.length
    newSlide = newSlide * amount
    this.setState(
      {
        ...this.state,
        sliderActive: newSlide,
      },
      () => {
        if (slides) {
          let slide = slides[newSlide]
          slide.scrollIntoView({
            behavior: "smooth",
            inline: "start",
            block: "nearest",
          })
        }
      }
    )
  }

  /**
   * handle navigation arrow visibility
   * if it can not move to that direction, the arrow should be hidden
   * @param action
   * @param childComponentsList
   */
  handleNavState(direction: string, background1: BobBackground, childComponentsList?: any) {
    if (direction === "prev") return this.state.sliderActive !== 0
    if (direction === "next") {
      let itemsPerSlide: number = background1.grid.columns.length
      /**
       * last element in array is not sufiecient (length - 1)
       * because if we have more than one item per slide
       * the last element index doenst reflect the last slide position
       *
       * we need to subtract the number of items per slide
       * and minus one to count with the selected index when we'r on the last slide,
       * the selected index is the first in x items per slide(in the last slide), so we cannot subtract with it
       */
      let lastItem: number = childComponentsList.length - 1 - (itemsPerSlide - 1)
      return this.state.sliderActive !== lastItem
    }
    return {}
  }

  renderComponents(childComponentsId: Array<string>, post: any) {
    return childComponentsId.map((item: string, idx: number) => {
      let handledItem = handleObjectType(this.props.obj, item, this.props.pageResponse, this.props.expManager.emEditorType)
      return (
        <ComponentHandler
          position={idx}
          selectedInstanceId={this.props.obj.selectedInstanceId}
          key={idx}
          // component={handledItem}
          component={handleContentBlock(
            handledItem,
            post,
            this.props.obj,
            this.props.pageResponse,
            this.props.overrides
          )}
          pageResponse={this.props.pageResponse}
          matchOrder={this.props.matchOrder}
          expManager={this.props.expManager}
        />
      )
    })
  }

  renderSlides(childComponentsId: Array<string>, background1TemplateMerged: any) {
    const content = getContent(this.props.obj, "posts", this.props.overrides, undefined, false)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: any, idx: number) => {
        const columns = handleColumns(
          idx,
          counter,
          background1TemplateMerged,
          this.props.pageResponse.globalStyles[background1TemplateMerged.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            ref={this.sliderItem}
            key={idx}
            className={`slider-item ${carouselComponentStyles["slider-item"]} col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            {this.renderComponents(childComponentsId, post)}
          </div>
        )
      })
    } else {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return childComponentsId.map((item: string, idx: number) => {
        const handledItem = handleObjectType(this.props.obj, item, this.props.pageResponse, this.props.expManager.emEditorType)
        const columns = handleColumns(
          idx,
          counter,
          background1TemplateMerged,
          this.props.pageResponse.globalStyles[background1TemplateMerged.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            ref={this.sliderItem}
            key={idx}
            className={`slider-item ${carouselComponentStyles["slider-item"]} col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            <ComponentHandler
              position={idx}
              selectedInstanceId={this.props.obj.selectedInstanceId}
              key={idx}
              component={handledItem}
              pageResponse={this.props.pageResponse}
              matchOrder={this.props.matchOrder}
              expManager={this.props.expManager}
            />
          </div>
        )
      })
    }
  }

  renderNoChilds(background1: BobBackground) {
    /**
     * render no content placeholder
     */
    if (this.props.expManager.enable) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      const childs = background1.grid.columns && background1.grid.columns.length
      let components: any = []
      for (let i = 0; i < childs; i++) {
        const columns = handleColumns(
          i,
          counter,
          background1,
          this.props.pageResponse.globalStyles[background1.globalStyleId]
        )
        counter = columns.counter
        components = [
          ...components,
          <div
            key={i}
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                verticalAlign: "middle",
                height: `${background1.height.enable ? background1.height.value : "100"}${
                  background1.height.enable ? background1.height.unit : "px"
                }`,
                border: "3px dashed #dbe0e7",
              }}
            >
              <div className='alert warning m-0' role='alert'>
                SliderContainerV2 empty, please add childs
              </div>
            </div>
          </div>,
        ]
      }
      return (
        <div className={`slider-body ${carouselComponentStyles["slider-body"]}`}>
          <div className={`slider-nav ${carouselComponentStyles["slider-nav"]}`}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              field='icon1'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              iconClassName='fas fa-chevron-left'
            />
          </div>
          <div className={`slider-container ${carouselComponentStyles["slider-container"]}`}>
            <WarningDefaultSystem>
              <div className={`row`}>{components}</div>
            </WarningDefaultSystem>
          </div>
          <div className={`slider-nav ${carouselComponentStyles["slider-nav"]}`} style={{ color: "#888", opacity: 1 }}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              field='icon2'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              iconClassName='fas fa-chevron-right'
            />
          </div>
        </div>
      )
    }
  }

  renderBottomNavigation(background1: BobBackground) {
    let slidesNumber = this.props.obj.children.length / background1.grid.columns.length
    slidesNumber = Math.ceil(slidesNumber)
    let slides: any = []
    for (let i = 0; i < slidesNumber; i++) {
      slides = [
        ...slides,
        <BobComponentHandler
          key={i}
          onClick={() => this.goToSlide(i, background1)}
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.obj.selectedInstanceId}
          object={this.props.obj}
          objectId={this.props.obj.uuid}
          field='icon3'
          template={this.props.obj.styles}
          rules={this.props.componentRule}
          expManager={this.props.expManager}
          iconClassName='fas fa-chevron-left'
          className={`${i === this.state.sliderActive ? "active" : ""}`}
        />,
      ]
    }
    return <div className={`slider-nav-bottom ${carouselComponentStyles["slider-nav-bottom"]}`}>{slides}</div>
  }

  render() {
    const background1 = isBobBackground(this.props.obj.styles.bobs.background1)
    const childComponentsId = this.props.obj.children
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
    const prevNavEnabled = this.handleNavState("prev", background1, childComponentsId)
    const nextNavEnabled = this.handleNavState("next", background1, childComponentsId)
    return (
      <div className={`containers carousel-component ${carouselComponentStyles["carousel-component"]}`}>
        {childComponentsId && childComponentsId.length > 0 ? (
          <Fragment>
            <div className={`slider-body ${carouselComponentStyles["slider-body"]}`}>
              {this.props.obj.styles.bobs.icon1.enable && (
                <div
                  className={`slider-nav ${carouselComponentStyles["slider-nav"]} left`}
                  onClick={() => prevNavEnabled && this.slide("prev")}
                  style={prevNavEnabled ? { opacity: 1 } : { opacity: 0 }}
                >
                  <BobComponentHandler
                    position={this.props.position}
                    pageResponse={this.props.pageResponse}
                    instanceId={this.props.obj.selectedInstanceId}
                    object={this.props.obj}
                    objectId={this.props.obj.uuid}
                    field='icon1'
                    template={this.props.obj.styles}
                    rules={this.props.componentRule}
                    expManager={this.props.expManager}
                    iconClassName='fas fa-chevron-left'
                  />
                </div>
              )}
              <AlignmentHOC
                ref={this.sliderContainer}
                className={`slider-container ${carouselComponentStyles["slider-container"]} ${responsiveGuttersClass(
                  background1,
                  this.props.pageResponse.globalStyles[background1.globalStyleId]
                )}`}
                desktop={alignmentStyles}
                tablet={tabletAlignment}
                mobile={mobileAlignment}
                onMouseMove={this.onMouseMove}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseLeave={this.onMouseLeave}
              >
                {this.renderSlides(childComponentsId, background1)}
              </AlignmentHOC>
              {this.props.obj.styles.bobs.icon2.enable && (
                <div
                  className={`slider-nav ${carouselComponentStyles["slider-nav"]} right`}
                  onClick={() => nextNavEnabled && this.slide("next")}
                  style={nextNavEnabled ? { opacity: 1 } : { opacity: 0 }}
                >
                  <BobComponentHandler
                    position={this.props.position}
                    pageResponse={this.props.pageResponse}
                    instanceId={this.props.obj.selectedInstanceId}
                    object={this.props.obj}
                    objectId={this.props.obj.uuid}
                    field='icon2'
                    template={this.props.obj.styles}
                    rules={this.props.componentRule}
                    expManager={this.props.expManager}
                    iconClassName='fas fa-chevron-right'
                  />
                </div>
              )}
            </div>
            {this.renderBottomNavigation(background1)}
          </Fragment>
        ) : (
          this.renderNoChilds(background1)
        )}
      </div>
    )
  }
}

export default SliderContainerV2