import React from "react"
import ReactPlayer from "react-player/lazy"

interface LazyVideoPlayerProps {
  url: string
  controls: boolean
  config: any
  loop?: boolean
}

class LazyVideoPlayer extends React.Component<LazyVideoPlayerProps> {
  render() {
    return (
      <ReactPlayer
        url={this.props.url}
        controls={this.props.controls}
        config={this.props.config}
        width='100%'
        height='100%'
        loop={this.props.loop ? this.props.loop : false}
      />
    )
  }
}

export default LazyVideoPlayer
