import React, { Fragment, RefObject, useState } from "react"
import { getContent } from "../../../../../../modules/shared-modules/utilities/components"
import ComponentSelectorEMv2 from "../../../componentSelectorEM/ComponentSelectorEMv2"
import { FormSubmitProps } from "."
import { Post } from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import BobSubmit from "../../../bob/bobSubmit/bobSubmit"

const FormSubmit = (props: FormSubmitProps) => {
  const [elRef, setElRef] = useState<RefObject<HTMLInputElement> | undefined>(undefined)

  const items = getContent(props.obj, "posts", props.overrides)
  let childs = []
  if (items && items.length > 0) {
    childs = items.map((post: Post, idx: number) => {
      return (
        <BobSubmit
          key={idx}
          id={props.obj.uuid}
          pageResponse={props.pageResponse}
          instanceId={props.obj.selectedInstanceId}
          inlineContent={props.obj.content.inlineContent}
          overrides={props.overrides}
          object={props.obj}
          post={post}
          template={props.obj.styles}
          field='submit1'
          setRef={(elRef) => setElRef(elRef)}
          expManager={props.expManager}
          replacedMediaList={props.replacedMediaList}
          form={props.form}
        />
      )
    })
  }

  return (
    <Fragment>
      {props.expManager.enable && !props.expManager.content && (
        <ComponentSelectorEMv2
          uuid={props.obj.uuid}
          obj={props.obj}
          form={props.form}
          masterBob='submit1'
          editing={props.editing}
          elRef={elRef}
        />
      )}
      {childs}
    </Fragment>
  )
}

export default React.memo(FormSubmit)
