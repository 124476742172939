import React from 'react'
import ComponentHandler from '../../../../componentHandler'
import WarningDefaultSystem from '../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem'
import { handleGutts, handleObjectType } from "../../../../../../modules/shared-modules/utilities/components"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { GridContainerProps } from "."
import { isBobBackground } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const GridContainer: React.FC<GridContainerProps> = (props): JSX.Element => {
  const renderChildComponents = (childComponentsId: string[]) => {
    let counter = { desktop: 0, tablet: 0, mobile: 0 }
    return childComponentsId.map((item, idx) => {
      const handledItem = handleObjectType(props.obj, item, props.pageResponse, props.expManager.emEditorType)
      const columns = handleColumns(idx, counter, props.obj.styles.bobs.background1)
      counter = columns.counter
      return (
        <div
          key={idx}
          className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
        >
          <ComponentHandler
            position={idx}
            selectedInstanceId={props.obj.selectedInstanceId}
            component={handledItem}
            pageResponse={props.pageResponse}
            matchOrder={props.matchOrder}
            expManager={props.expManager}
          />
        </div>
      )
    })
  }

  const renderContent = () => {
    const background1 = props.obj.styles.bobs.background1
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
    const childComponentsId = props.obj.children
    const gutters = handleGutts(props.obj.styles.bobs.background1, false)
    if (childComponentsId && childComponentsId.length > 0) {
      return (
        <AlignmentHOC
          className={`row ${gutters} ${responsiveGuttersClass(props.obj.styles.bobs.background1)}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {renderChildComponents(childComponentsId)}
        </AlignmentHOC>
      )
    } else {
      /**
       * render no content placeholder
       */
      if (props.expManager?.enable) {
        let counter = { desktop: 0, tablet: 0, mobile: 0 }
        const Background1 = isBobBackground(props.obj.styles.bobs.background1)
        const childs = Background1.grid.columns && Background1.grid.columns.length
        let components: any[] = []
        for (let i = 0; i < childs; i++) {
          const columns = handleColumns(i, counter, Background1)
          counter = columns.counter
          components = [
            ...components,
            <div
              key={i}
              className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            >
              <div
                style={{
                  width: "100%",
                  height: `${Background1.height.enable ? Background1.height.value : "100"}${
                    Background1.height.enable ? Background1.height.unit : "px"
                  }`,
                  border: "3px dashed #dbe0e7",
                }}
              ></div>
            </div>,
          ]
        }
        return (
          <WarningDefaultSystem>
            <div
              className={`row ${gutters} ${responsiveGuttersClass(props.obj.styles.bobs.background1)}`}
              style={alignmentStyles}
            >
              {components}
            </div>
          </WarningDefaultSystem>
        )
      }
    }
  }

  return <div className='containers grid-container-component'>{renderContent()}</div>
}

export default GridContainer