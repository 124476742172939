import React from 'react'
import { getContent } from "../../../../../../modules/shared-modules/utilities/components"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import WarningSystem from "../../../../../../modules/shared-modules/experienceManager/warningSystem"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import SocialEmbed from "../SocialsEmbed/SocialEmbed/SocialEmbed"
import { SocialCardEmbedProps } from "./interface"

const SocialCardEmbed: React.FC<SocialCardEmbedProps> = (props) => {
  const renderItems = (background1Template: any) => {
    const content = getContent(props.obj, "posts", props.overrides)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: any, idx: any) => {
        const columns = handleColumns(
          idx,
          counter,
          background1Template,
          props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <BobComponentHandler
              object={props.obj}
              position={props.position}
              pageResponse={props.pageResponse}
              objectId={props.obj.uuid}
              post={post}
              inlineContent={props.obj.content.inlineContent}
              overrides={props.overrides}
              field={"background2"}
              rules={props.componentRule}
              template={props.obj.styles}
              expManager={props.expManager}
              className={`social-card-embed-item-${idx}`}
              flexDirection='column'
            >
              <SocialEmbed postObj={post} />
            </BobComponentHandler>
          </div>
        )
      })
    }
    if (props.expManager.enable) {
      return (
        <div className='col-sm-12'>
          <WarningSystem alertType='alert-secondary w-100 text-center' messages='Select a formula to show posts' />
        </div>
      )
    }
    return []
  }

  const background1 = props.obj.styles.bobs.background1
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
  return (
    <section className='social social-card-embed'>
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={{ ...alignmentStyles, flexDirection: "row" }}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderItems(background1)}
      </AlignmentHOC>
    </section>
  )
}

export default SocialCardEmbed