import React from 'react'
import ComponentHandler from '../../../../componentHandler'
import WarningDefaultSystem from '../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem'
import { handleObjectType, disableBodyScroll } from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { MenuProps } from "../menu/index"
import menuToggleComponentStyles from "../../../../../../stylesheets/modules/templates/menuToggleComponent.module.sass"
import { isBobBackground } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

interface MenuToggleState {
  showMenu: boolean
}

class MenuToggle extends React.Component<MenuProps, MenuToggleState> {
  constructor(props: MenuProps) {
    super(props)
    this.state = {
      showMenu: false,
    }
  }

  componentDidMount() {
    /**
     * handle window for page builder iframe or public pages
     */
    const iframe = document.getElementById("board-preview-frame") as HTMLIFrameElement
    const handledWindow = iframe?.contentWindow || window

    handledWindow.addEventListener("click", (e) => this.anchorClicked(e))
  }

  componentWillUnmount() {
    /**
     * handle document and window for page builder iframe or public pages
     */
    const iframe = document.getElementById("board-preview-frame") as HTMLIFrameElement
    const handledWindow = iframe?.contentWindow || window

    handledWindow.removeEventListener("click", (e) => this.anchorClicked(e))
  }

  /**
   * close menu on anchor link click
   */
  anchorClicked = (e: any) => {
    if (this.state.showMenu) {
      const link = e.target?.closest("a")
      if (link) {
        const linkHref = link.getAttribute("href")
        // anchor was clicked, close menu
        if (linkHref?.startsWith("#")) {
          this.handleMenuToggle()
        }
      }
    }
  }

  handleMenuToggle = () => {
    this.setState(
      {
        ...this.state,
        showMenu: !this.state.showMenu,
      },
      () => disableBodyScroll(this.props.expManager, this.state.showMenu)
    )
  }

  renderMenu(componentsId: Array<string>) {
    if (componentsId && componentsId.length > 0) {
      return componentsId.map((componentId, idx) => {
        const handledItem = handleObjectType(
          this.props.obj,
          componentId,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        return (
          <div className={`main-menu-item ${menuToggleComponentStyles["main-menu-item"]}`} key={idx}>
            <ComponentHandler
              position={idx}
              selectedInstanceId={this.props.obj.selectedInstanceId}
              key={idx}
              component={handledItem}
              pageResponse={this.props.pageResponse}
              matchOrder={this.props.matchOrder}
              expManager={this.props.expManager}
            />
          </div>
        )
      })
    } else {
      if (this.props.expManager.enable) {
        let components: Array<JSX.Element> = []
        for (let i = 0; i < 3; i++) {
          const columns = "4"
          components = [
            ...components,
            <div key={i} className={`navbar-menu col-12 col-md-${columns}`}>
              <div
                style={{
                  height: `${isBobBackground(this.props.obj.styles.bobs.background2).height.value}${
                    isBobBackground(this.props.obj.styles.bobs.background2).height.unit
                  }`,
                  border: "3px dashed #dbe0e7",
                }}
              ></div>
            </div>,
          ]
        }
        return (
          <WarningDefaultSystem>
            <div className='row'>{components}</div>
          </WarningDefaultSystem>
        )
      }
    }
  }

  render() {
    const background1 = isBobBackground(this.props.obj.styles.bobs.background1)
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
    const columns = handleColumns(
      0,
      { desktop: 0, tablet: 0, mobile: 0 },
      background1,
      this.props.pageResponse.globalStyles[background1.globalStyleId]
    )
    const childComponentsId = this.props.obj.children

    return (
      <div
        className={`navigation ${menuToggleComponentStyles.navigation} menu-toggle-component ${menuToggleComponentStyles["menu-toggle-component"]}`}
      >
        {this.state.showMenu && (
          <div onClick={this.handleMenuToggle} className='fade modal-backdrop modal-component-backdrop show'></div>
        )}
        <AlignmentHOC
          className={`main-menu ${menuToggleComponentStyles["main-menu"]} row ${responsiveGuttersClass(
            background1,
            this.props.pageResponse.globalStyles[background1.globalStyleId]
          )}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          <div className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}>
            {childComponentsId && childComponentsId.length > 0 && (
              <BobComponentHandler
                position={this.props.position}
                pageResponse={this.props.pageResponse}
                instanceId={this.props.obj.selectedInstanceId}
                object={this.props.obj}
                objectId={this.props.obj.uuid}
                field='icon1'
                template={this.props.obj.styles}
                rules={this.props.componentRule}
                expManager={this.props.expManager}
                iconClassName='fas fa-bars'
                className={`main-menu-toggle ${menuToggleComponentStyles["main-menu-toggle"]}`}
                onClick={this.handleMenuToggle}
              />
            )}
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field={"background2"}
              rules={this.props.componentRule}
              template={this.props.obj.styles}
              expManager={this.props.expManager}
              flexDirection={"column"}
              className={`main-menu-container ${menuToggleComponentStyles["main-menu-container"]} ${
                !this.state.showMenu ? `hide ${menuToggleComponentStyles.hide}` : "show"
              }`}
            >
              <BobComponentHandler
                position={this.props.position}
                pageResponse={this.props.pageResponse}
                instanceId={this.props.obj.selectedInstanceId}
                object={this.props.obj}
                objectId={this.props.obj.uuid}
                field='icon2'
                template={this.props.obj.styles}
                rules={this.props.componentRule}
                expManager={this.props.expManager}
                iconClassName='fas fa-times'
                className={`main-menu-close ${menuToggleComponentStyles["main-menu-close"]} ${
                  !this.state.showMenu ? `hide ${menuToggleComponentStyles.hide}` : "show"
                }`}
                onClick={this.handleMenuToggle}
              />
              {this.renderMenu(childComponentsId)}
            </BobComponentHandler>
          </div>
        </AlignmentHOC>
      </div>
    )
  }
}

export default MenuToggle