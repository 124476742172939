export const styles = {
  'fontFamily': 'Roboto',
  'fontWeight': 'normal',
  'fontSize': '16',
  'letterSpacing': '1',
  'lineHeight': '1',
  'textAlign': 'left',
  'verticalAlign': 'bottom',
  'textTransform': 'none',
  'color': 'rgba(0,0,0,1)'
}

export const stylesOverride = {
  'fontFamily': 'Roboto:300,400,600,700 !important',
  'fontWeight': 'normal !important',
  'fontSize': '16 !important',
  'letterSpacing': '1 !important',
  'lineHeight': '1 !important',
  'textAlign': 'left !important',
  'verticalAlign': 'bottom !important',
  'textTransform': 'none !important',
  'color': 'rgba(0,0,0,1) !important'
}