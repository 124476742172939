import React, { CSSProperties, RefObject } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import {
  hubHandlerSelected,
  selectFinder,
  objectSelectionHover,
  viewPortSizeStateExperienceManager,
  changeResponsiveTab,
} from "../../../../store/shared-store/actions/publicPageActions"
import { selectSymbolInstance, setSymbolContentType } from "../../../../store/shared-store/actions/symbolsActions"
import { editSymbolState } from "../../../../store/shared-store/actions/pageTreeActions"
import {
  handleFilterInstanceObj,
  getObjectFromSymbolObject,
  handleFilterSymbolObject,
  handleUniqueid,
} from "../../../../modules/shared-modules/utilities/symbols"
import {
  CONTENT_TAB,
  DEFAULT_FINDER_TAB,
} from "../../../../modules/shared-modules/experienceManager/types/pageTreeTypes"
import { CONTENT_TYPE_INSTANCE } from "../../../../modules/shared-modules/experienceManager/finder/symbols/symbolTypes"
import { SingleObject } from "../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import Devices from "../../../skynet/experienceManager/utils/devices"
import * as viewPort from "../../../../modules/shared-modules/experienceManager/viewPortTypes/viewPortTypes"
import { BobFlex, BobNmedia, nBobText } from "../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import Permissions from "../../../../modules/utilities/permission"
import SingleObjectUtils from "../../../../modules/shared-modules/experienceManager/singleObjectUtils"
import { FormSettings } from "../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import CSEMv2Element from "./CSEMv2ElementHover"

interface ComponentSelectorEMv2Props {
  obj: SingleObject
  form?: FormSettings | undefined
  uuid: string
  editing: boolean
  elRef: RefObject<HTMLDivElement> | RefObject<HTMLFormElement> | RefObject<HTMLInputElement> | undefined
  masterBob: string
}

const ComponentSelectorEMv2 = React.memo((props: ComponentSelectorEMv2Props): any => {
  const dispatch = useDispatch()

  const actions = bindActionCreators(
    {
      hubHandlerSelected,
      selectFinder,
      objectSelectionHover,
      selectSymbolInstance,
      setSymbolContentType,
      editSymbolState,
      viewPortSizeStateExperienceManager,
      changeResponsiveTab,
    },
    dispatch
  )
  const userInfo = useSelector((state: any) => state.session.userInfo)
  const workspaceInfo = useSelector((state: any) => state.profileReducer.content)
  const selectedSingleObject = useSelector((state: any) => state.publicPageReducer.hubHandlerObject)
  const contentHub = useSelector((state: any) => state.contentHubReducer.hubContent)
  const { symbolObj } = useSelector((state: any) => state.pageTreeReducer)
  const viewPortSizeState = useSelector((state: any) => state.publicPageReducer.viewPortSizeState)
  const viewPortOriginalSizeState = useSelector(
    (state: any) => state.experienceManagerReducer.viewPortOriginalSizeState
  )

  const { objectSelectorModeEM, objectSelectorStateEM, finderType, emEditorType } = useSelector(
    (state: any) => state.experienceManagerReducer
  )

  const id = handleUniqueid(props.obj, contentHub.symbols)

  const handleObjectClick = (e: any) => {
    /**
     * Only trigger if in object selection Mode
     * @param objectSelectorModeEM (boolean)
     */
    if (objectSelectorModeEM) {
      e.stopPropagation()
      e.preventDefault()

      // symbol root object clicked
      if (props.obj.fromSymbolTree && props.obj.type === "root" && props.obj.selectedInstanceId) {
        const symbolInstanceObj = handleFilterInstanceObj(contentHub, props.obj.selectedInstanceId)
        const symbolToUse = handleFilterSymbolObject(symbolInstanceObj, contentHub.symbols, false)
        actions.hubHandlerSelected(symbolToUse.uuid, undefined, symbolToUse)
        actions.selectSymbolInstance(symbolInstanceObj.uuid, symbolInstanceObj)
        actions.selectFinder(handleOpenTab(), symbolToUse)
        //directly open styles for symbol
        actions.editSymbolState({ symbolObj: symbolToUse }, CONTENT_TAB)
        // if fromSymbolTree is different set instance edit mode
        if (props.obj.fromSymbolTree !== selectedSingleObject.fromSymbolTree) {
          actions.setSymbolContentType(CONTENT_TYPE_INSTANCE)
        }
      }
      // symbol child clicked
      else if (props.obj.fromSymbolTree && props.obj.type === "object" && props.obj.selectedInstanceId) {
        //Gets original object without the overrides
        const objToUse = getObjectFromSymbolObject(contentHub, props.obj)
        const symbolInstanceObj = handleFilterInstanceObj(contentHub, props.obj.selectedInstanceId)
        const symbolToUse = handleFilterSymbolObject(symbolInstanceObj, contentHub.symbols, false)
        actions.hubHandlerSelected(props.obj.uuid, undefined, objToUse)
        actions.selectSymbolInstance(symbolInstanceObj.uuid, symbolInstanceObj)
        actions.selectFinder(handleOpenTab(), objToUse)
        //directly open styles for symbol
        actions.editSymbolState({ symbolObj: symbolToUse }, CONTENT_TAB)
        // if fromSymbolTree is different set instance edit mode
        if (props.obj.fromSymbolTree !== selectedSingleObject.fromSymbolTree) {
          actions.setSymbolContentType(CONTENT_TYPE_INSTANCE)
        }
      }
      // object clicked
      else {
        if (!props.obj.selectedInstanceId) {
          actions.editSymbolState(false)
          actions.setSymbolContentType(CONTENT_TYPE_INSTANCE)
        }
        //clear selected instance
        if (!props.obj.fromSymbolTree) actions.selectSymbolInstance(undefined)
        const SingleObjectToUse = SingleObjectUtils.getObjectByUuid(props.obj, props.form, contentHub, emEditorType)

        actions.hubHandlerSelected(SingleObjectToUse.uuid, SingleObjectToUse.parentId, SingleObjectToUse)
        actions.selectFinder(handleOpenTab(), SingleObjectToUse)
      }

      //Return to desktop mode when in Fullscreen
      if (viewPortSizeState === "100%_NO_BARS") {
        const viewport = Devices.handleViewPortMaxSize(viewPort.DESKTOP, 100, viewPortOriginalSizeState)
        actions.viewPortSizeStateExperienceManager(viewport.size, viewport.scale)
        actions.changeResponsiveTab("responsive")
      }
    }
  }

  const handleOpenTab = () => {
    if (finderType === "template" || finderType === CONTENT_TAB) {
      return finderType
    }
    return DEFAULT_FINDER_TAB
  }

  const handleElementEnter = (e: any) => {
    e.stopPropagation()
    /**
     * element hover logic
     * when hover is on a symbol obj, use the selectedInstanceId
     */
    if (objectSelectorModeEM) {
      let obj = props.obj
      // when hovering a symbol object that inst the root object,
      // we must use the root object himself so that the instance on the tree matches the objectSelectorModeEM id
      if (props.obj.fromSymbolTree && props.obj.type !== "root" && !symbolObj) {
        let symbol = contentHub.symbols[props.obj.fromSymbolTree]
        obj = symbol.objects[symbol.rootId]
      }
      let id = handleUniqueid({ ...obj, selectedInstanceId: props.obj.selectedInstanceId }, contentHub.symbols)
      actions.objectSelectionHover(id)
    }
  }

  const handleElementLeave = (e: any) => {
    e.stopPropagation()
    /**
     * element hover logic
     */
    if (objectSelectorModeEM) {
      actions.objectSelectionHover(false)
    }
  }

  const isNumber = (e: any): boolean => {
    return typeof e === "number"
  }

  let isSelected = false
  let classes = "componentSelector "
  if (objectSelectorStateEM) {
    isSelected = true
    classes += "editing "
  }

  // don't render when objectSelectorModeEM and objectSelectorStateEM are turned off.
  // if only objectSelectorModeEM is off, pointer events should be disabled.
  // With this, components events like onmouseenter will begin working.
  if (objectSelectorModeEM || objectSelectorStateEM) {
    const displayBoundaries = objectSelectorModeEM ? "block" : "none"

    const masterObject = props.obj.styles.bobs?.[props.masterBob] as BobFlex | BobNmedia | nBobText
    const Template =
      masterObject && masterObject.spacing && masterObject.spacing.enable
        ? masterObject.spacing
        : {
            margin: { top: 0, bottom: 0, left: 0, right: 0 },
            padding: { top: 0, bottom: 0, left: 0, right: 0 },
          }
    /**
     *
     * Border/Padding/Content layout
     *
     * To emulate visually the borders, paddings and content with the charro feature
     * we need to create a frame around the content with the two options avaiable
     *
     * MARGIN TOP */
    const MarginTop = (
      <div
        className='margin top'
        style={{
          width: "100%",
          height: `${Template.margin.top}px`,
          left: "0",
          top: `-${Template.margin.top}px`,
        }}
      />
    )
    /* Margin top will go from left to right using 100%
     * The height will equal the margin top selected in the interface
     * To visually apply it over the real margin he we need to give it a negative top equal to its height
     *
     * MARGIN BOTTOM */
    const MarginBottom = (
      <div
        className='margin bottom'
        style={{
          width: "100%",
          height: `${Template.margin.bottom}px`,
          right: "0",
          bottom: `-${Template.margin.bottom}px`,
        }}
      />
    )
    /* Margin bottom applies the same principles from margin top but with inverted sides
    * In this case it uses the right and bottom side to align it and the negative value is applied at the bottom

    * MARGIN LEFT */
    const MarginLeft = (
      <div
        className='margin left'
        style={{
          width: `${Template.margin.left}px`,
          left: `-${Template.margin.left}px`,
          height: "100%",
        }}
      />
    )
    /* Margin left uses the full height avaiable
     * The width equals the margin left applied in the interface
     * The position is forced to the left side with top and left at 0
     *
     * MARGIN RIGHT */
    const MarginRight = (
      <div
        className='margin right'
        style={{
          width: `${Template.margin.right}px`,
          right: `-${Template.margin.right}px`,
          height: "100%",
        }}
      />
    )
    /* Margin right uses the same logic for the margin left
     * With the proper use of the margin right for the width
     * And starting at the right and bottom side (0)
     *
     *       ---------------------------------------------------------------------------------------------
     *       |       margin-top: width:100%; height:${margin.top}px; left:0; top: -${margin.top};        |
     *       ---------------------------------------------------------------------------------------------
     *       |        margin-left:     |                                      |        margin-right:     |
     *       | width: ${margin-left}px |                                      | width: ${margin-right}px |
     *       |        height: 100%     |                                      |        height: 100%      |
     *       |         left: 0         |                                      |         right: 0         |
     *       |         top: 0          |                                      |         bottom: 0        |
     *       ---------------------------------------------------------------------------------------------
     *       | margin-bottom: width:100%; height:${margin-bottom}px; right:0; bottom: -${margin.bottom}; |
     *       ---------------------------------------------------------------------------------------------
     *
     * PADDING TOP */
    const PaddingTop = (
      <div
        className='padding top'
        style={{
          width: `calc(100% - ${Template.padding.left + Template.padding.right}px)`,
          height: `${Template.padding.top}px`,
          left: `${Template.padding.left}px`,
        }}
      />
    )
    /* Padding top will use the total width minus both padding side values for the left & right
     * The height will equal to the padding top and will start at where the left padding ends
     *
     * PADDING BOTTOM */
    const PaddingBottom = (
      <div
        className='padding bottom'
        style={{
          width: `calc(100% - ${Template.padding.left + Template.padding.right}px)`,
          height: `${Template.padding.bottom}px`,
          right: `${Template.padding.right}px`,
        }}
      />
    )
    /* Padding bottom will have the same logic applied at the padding top, with the small change to start at the right side where the right padding ends
     * The height will equal the padding bottom
     *
     * PADDING LEFT */
    const PaddingLeft = (
      <div
        className='padding left'
        style={{
          width: `${Template.padding.left}px`,
          height: "100%",
        }}
      />
    )
    /* Padding left will just use the padding left for the width and 100% for the height
     * Since we leave the heavy logic for the top and bottom padding
     *
     *
     *
     *
     *       --------------------------------------------------------------------------------------------------------------------
     *       | padding-top: width:calc(100%-[${padding.left}+${padding.right}]px); height:${padding.top}; left:${padding.left}; |
     *       --------------------------------------------------------------------------------------------------------------------
     */
    const IframeElement = document.getElementById("board-preview-frame") as HTMLIFrameElement

    //Get iframe Y size
    const scrollY = IframeElement.contentWindow?.scrollY ?? 0
    const scrollX = IframeElement.contentWindow?.scrollX ?? 0

    //Element top & left
    const elementTop = props.elRef?.current?.getBoundingClientRect().top ?? 0
    const elementLeft = props.elRef?.current?.getBoundingClientRect().left ?? 0

    /**
     * Real top & left position
     * We first get the page scroll position, to know where the user is looking at,
     * so we can add to the getBoundingClientRect, since this value is calculated
     * with the current related position of the page, adding this 2 values we have
     * the precise distance from the top and left values
     */
    const realElementTop = scrollY + elementTop
    const realElementLeft = scrollX + elementLeft

    const outerDivStyle = {
      position: "absolute",
      left: `${realElementLeft}px`,
      top: `${realElementTop}px`,
      width: props.elRef?.current?.offsetWidth,
      height: props.elRef?.current?.offsetHeight,
      pointerEvents: objectSelectorModeEM ? "auto" : "none", // With this, components events like onmouseenter will begin working.
      zIndex: 9999999,
    } as CSSProperties

    const marginLeft = isNumber(Template.margin.left) ? (Template.margin.left as number) : 0
    const marginRight = isNumber(Template.margin.right) ? (Template.margin.right as number) : 0

    return (
      <div
        className={classes}
        onClick={(e) => handleObjectClick(e)}
        style={outerDivStyle}
        onMouseOver={(e) => handleElementEnter(e)}
        onMouseLeave={(e) => handleElementLeave(e)}
      >
        {Permissions.checkWorspacePermissionPageBuilder(userInfo, workspaceInfo, "addObject") && isSelected && (
          <div
            id='object-selected'
            style={{
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
            }}
            className={`${symbolObj ? "isSymbol" : ""} ${
              props.obj.uuid === selectedSingleObject?.uuid ? "isSelected" : ""
            }`}
          />
        )}
        <CSEMv2Element
          label={props.obj.label}
          MarginTop={MarginTop}
          MarginBottom={MarginBottom}
          MarginLeft={MarginLeft}
          MarginRight={MarginRight}
          PaddingBottom={PaddingBottom}
          PaddingTop={PaddingTop}
          PaddingLeft={PaddingLeft}
          marginLeft={marginLeft}
          marginRight={marginRight}
          Template={Template}
          realElementTop={realElementTop}
          displayBoundaries={displayBoundaries}
          id={id}
        />
      </div>
    )
  }

  return <></>
})

ComponentSelectorEMv2.displayName = "ComponentSelectorEMv2"

export default ComponentSelectorEMv2
