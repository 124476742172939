import React from 'react'
import { getContent } from "../../../../../../modules/shared-modules/utilities/components"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import WarningSystem from "../../../../../../modules/shared-modules/experienceManager/warningSystem"
import SocialEmbed from "../SocialsEmbed/SocialEmbed/SocialEmbed"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { SocialMediaEmbedProps } from "./interface"
import instagramMediaEmbedStyles from "../../../../../../stylesheets/modules/components/instagramMediaEmbed.module.sass"

const SocialMediaEmbed: React.FC<SocialMediaEmbedProps> = (props) => {
  const renderItems = (background1Template: any) => {
    let content = getContent(props.obj, "posts", props.overrides)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: any, idx: number) => {
        let columns = handleColumns(
          idx,
          counter,
          background1Template,
          props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <SocialEmbed type='media' postObj={post} />
          </div>
        )
      })
    }
    if (props.expManager.enable) {
      return (
        <div className='col-sm-12'>
          <WarningSystem alertType='alert-secondary w-100 text-center' messages='Select a formula to show posts' />
        </div>
      )
    }
    return []
  }

  const background1 = props.obj.styles.bobs.background1
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
  return (
    <section className={`social social-media-embed ${instagramMediaEmbedStyles["social-media-embed"]}`}>
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderItems(background1)}
      </AlignmentHOC>
    </section>
  )
}

export default SocialMediaEmbed