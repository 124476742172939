import React, { useState } from 'react'
import ComponentHandler from '../../../../componentHandler'
import BobComponentHandler from '../../../bob/bobComponentHandler'
import { handleColumns, handleObjectType } from "../../../../../../modules/shared-modules/utilities/components"
import WarningDefaultSystem from "../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import { SliderContainerProps } from "."
import sliderContainerStyles from "../../../../../../stylesheets/modules/templates/sliderContainer.module.sass"
import { isBobBackground } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const SliderContainer: React.FC<SliderContainerProps> = (props) => {
  let _sliderScroller: any

  const [sliderActive, setSliderActive] = useState<number>(0)

  const slide = (action: any) => {
    let slides = _sliderScroller.getElementsByClassName(sliderContainerStyles["slider-item"])
    let newIndex = action === "prev" ? sliderActive - 1 : sliderActive + 1
    if (newIndex < 0 || newIndex + 1 > slides.length) {
      return
    }
    setSliderActive(newIndex)
    let slide = slides[newIndex]
    slide.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    })
  }

  const renderSlides = (childComponentsId: any[]) => {
    return childComponentsId.map((objectId, idx) => {
      let handledItem = handleObjectType(props.obj, objectId, props.pageResponse, props.expManager.emEditorType)
      return (
        <div key={idx} className={`slider-item ${sliderContainerStyles["slider-item"]}`}>
          <ComponentHandler
            position={idx}
            selectedInstanceId={props.obj.selectedInstanceId}
            component={handledItem}
            pageResponse={props.pageResponse}
            matchOrder={props.matchOrder}
            expManager={props.expManager}
          />
        </div>
      )
    })
  }

  const renderSlider = () => {
    let childComponentsId = props.obj.children
    if (childComponentsId && childComponentsId.length > 0) {
      return (
        <div className={`slider-body ${sliderContainerStyles["slider-body"]}`}>
          <div
            className={`slider-nav ${sliderContainerStyles["slider-nav"]}`}
            onClick={() => slide("prev")}
            style={sliderActive === 0 ? { opacity: 0 } : { opacity: 1 }}
          >
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              field='icon1'
              template={props.obj.styles}
              rules={props.componentRule}
              expManager={props.expManager}
              iconClassName='fas fa-chevron-left'
            />
          </div>
          <div
            ref={(node) => (_sliderScroller = node)}
            className={`slider-scroller ${sliderContainerStyles["slider-scroller"]}`}
          >
            <div className={`slider-container ${sliderContainerStyles["slider-container"]}`}>
              {renderSlides(childComponentsId)}
            </div>
          </div>
          <div
            className={`slider-nav ${sliderContainerStyles["slider-nav"]}`}
            onClick={() => slide("next")}
            style={sliderActive + 1 === childComponentsId.length ? { opacity: 0 } : { opacity: 1 }}
          >
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              field='icon2'
              template={props.obj.styles}
              rules={props.componentRule}
              expManager={props.expManager}
              iconClassName='fas fa-chevron-right'
            />
          </div>
        </div>
      )
    } else {
      return renderNoChilds()
    }
  }

  const renderNoChilds = () => {
    /**
     * render no content placeholder
     */
    if (props.expManager.enable) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      const Background1 = isBobBackground(props.obj.styles.bobs.background1)
      let childs = Background1.grid.columns && Background1.grid.columns.length
      let components: any[] = []
      for (let i = 0; i < childs; i++) {
        let columns = handleColumns(i, counter, Background1)
        counter = columns.counter
        components = [
          ...components,
          <div
            key={i}
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                verticalAlign: "middle",
                height: `${Background1.height.enable ? Background1.height.value : "100"}${
                  Background1.height.enable ? Background1.height.unit : "px"
                }`,
                border: "3px dashed #dbe0e7",
              }}
            >
              <div className='alert warning m-0' role='alert'>
                Slider Container empty, please add childs
              </div>
            </div>
          </div>,
        ]
      }
      return (
        <div className={`slider-body ${sliderContainerStyles["slider-body"]}`}>
          <div className={`slider-nav ${sliderContainerStyles["slider-nav"]}`}>
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              field='icon1'
              template={props.obj.styles}
              rules={props.componentRule}
              expManager={props.expManager}
              iconClassName='fas fa-chevron-left'
            />
          </div>
          <div
            ref={(node) => (_sliderScroller = node)}
            className={`slider-scroller ${sliderContainerStyles["slider-scroller"]}`}
          >
            <div className={`slider-container ${sliderContainerStyles["slider-container"]}`}>
              <WarningDefaultSystem>
                <div className={`row`}>{components}</div>
              </WarningDefaultSystem>
            </div>
          </div>
          <div className={`slider-nav ${sliderContainerStyles["slider-nav"]}`} style={{ color: "#888", opacity: 1 }}>
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              field='icon2'
              template={props.obj.styles}
              rules={props.componentRule}
              expManager={props.expManager}
              iconClassName='fas fa-chevron-right'
            />
          </div>
        </div>
      )
    }
  }

  return (
    <div className={`containers slider-container-component ${sliderContainerStyles["slider-container-component"]}`}>
      {renderSlider()}
    </div>
  )
}

export default SliderContainer