import { default as NextLink } from "next/link"
import { ExpManager } from "../../../modules/shared-modules/experienceManager/types/pageTypes"

interface LinkProps {
  children: React.ReactNode
  className: string | undefined
  style: Object | undefined
  to: any
  key: React.Key | null | undefined
  passHref: boolean | undefined
  href: URL | undefined
  expManager: ExpManager | undefined
  onClick: () => void | undefined
}

const Link = (props: LinkProps) => {
  return (
    <NextLink passHref key={props.key} href={props.to} scroll={false}>
      <a className={props.className} style={props.style}>
        {props.children}
      </a>
    </NextLink>
  )
}

export { Link }
