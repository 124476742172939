import { isRight } from "fp-ts/lib/Either"
import { type, union, Type, TypeOf, literal, intersection, null as nullC, partial } from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const TextCodec = type({
  textAlign: union([literal("right"), literal("left"), literal("center")]),
  textTransform: union([literal("none"), literal("capitalize"), literal("uppercase"), literal("lowercase")]),
  verticalAlign: union([literal("top"), literal("bottom"), literal("middle")]),
})

const TextOptCodec = partial({
  textAlign: nullable(union([literal("right"), literal("left"), literal("center")])),
  textTransform: nullable(union([literal("none"), literal("capitalize"), literal("uppercase"), literal("lowercase")])),
  verticalAlign: nullable(union([literal("top"), literal("bottom"), literal("middle")])),
})

const StylesTextCodec = intersection([
  TextCodec,
  partial({
    behaviour: partial({
      active: TextOptCodec,
      hover: TextOptCodec,
    }),
    mobile: intersection([
      TextOptCodec,
      partial({
        behaviour: partial({
          active: TextOptCodec,
          hover: TextOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      TextOptCodec,
      partial({
        behaviour: partial({
          active: TextOptCodec,
          hover: TextOptCodec,
        }),
      }),
    ]),
  }),
])

const GSTextCodec = intersection([
  TextCodec,
  partial({
    behaviour: partial({
      active: TextOptCodec,
      hover: TextOptCodec,
    }),
    mobile: intersection([
      TextOptCodec,
      partial({
        behaviour: partial({
          active: TextOptCodec,
          hover: TextOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      TextOptCodec,
      partial({
        behaviour: partial({
          active: TextOptCodec,
          hover: TextOptCodec,
        }),
      }),
    ]),
  }),
])

type Text = TypeOf<typeof TextCodec>
type TextProps = TypeOf<typeof TextCodec>
type TextOpt = TypeOf<typeof TextOptCodec>
type TextPropsOpt = TypeOf<typeof TextOptCodec>
type StylesText = TypeOf<typeof StylesTextCodec>
type GSText = TypeOf<typeof StylesTextCodec>
type TextCSS = {
  "text-align": string
  "text-transform": string
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<TextCSS> {
  const styles = StylesTextCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(`TextStyle ${breakpoint} ${behaviourState} ${decoderErrors(styles)}`)
  return {}
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<TextCSS> {
  const gs = GSTextCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState)
  console.warn(`TextGSStyle ${breakpoint} ${behaviourState} ${decoderErrors(gs)}`)
  return {}
}

export function cssRender(
  stylesObj: StylesText,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<TextCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj?.behaviour?.[behaviourState])
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj?.[breakpoint])
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj?.[breakpoint]?.behaviour?.[behaviourState])
    }
  }
}

export function globalStyleCssRender(
  stylesObj: GSText,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<TextCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj)
    }
    //hover | active
    else {
      return renderOpt(stylesObj?.behaviour?.[behaviourState])
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(stylesObj?.[breakpoint])
    }
    //hover | active
    else {
      return renderOpt(stylesObj?.[breakpoint]?.behaviour?.[behaviourState])
    }
  }
}

export function renderBob(textObj: Text): TextCSS {
  return render(textObj)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param textObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(textObj: TextOpt | undefined): Partial<TextCSS> {
  if (textObj) {
    return renderOpt(textObj)
  }
  return {}
}

/**
 * Renders TextOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param textObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(textObj: TextOpt | undefined): Partial<TextCSS> {
  let css = {}
  if (valueExists(textObj?.textTransform)) {
    css = { ...css, "text-transform": `${textObj?.textTransform}` }
  }
  if (valueExists(textObj?.textAlign)) {
    css = { ...css, "text-align": `${textObj?.textAlign}`, "justify-content": textObj?.textAlign }
  }

  return css
}

export function render(textObj: TextProps): TextCSS {
  return {
    "text-align": textObj.textAlign,
    "text-transform": textObj.textTransform,
  }
}

export type { StylesText, GSText, TextProps, TextPropsOpt, Text, TextOpt, TextCSS }
