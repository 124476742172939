import { ResponsiveData } from "../../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"

class ContentSrcHelper {
  static stateWrite(contentSrcControllerObject: ContentSrcState) {
    return {
      contentSrc: contentSrcControllerObject.contentSrc.value,
    }
  }
}

export const CMS = "post"
export const WRITE = "custom"
export const CONTENT_PAGE = "content-page"

export const mvPage = [
  { label: "Insert", value: WRITE },
  { label: "CMS", value: CMS },
]

export const mvContentPage = [
  { label: "Insert", value: WRITE },
  { label: "CMS", value: CMS },
  { label: "Content Page", value: CONTENT_PAGE },
]

type ContentSrcState = {
  contentSrc: ResponsiveData<string>
}
interface ContentSrcProps {
  contentSrc: ResponsiveData<string>
}

export type { ContentSrcProps, ContentSrcState }
export default ContentSrcHelper
