import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { cssRenderUnsafe as SelfFlexStyleCssRenderUnsafe } from "../bobStyles/selfFlexStyle"
import { cssRenderUnsafe as HeightStyleCssRenderUnsafe } from "../bobStyles/heightStyleV2"
import { cssRenderUnsafe as MaxHeightStyleCssRenderUnsafe } from "../bobStyles/maxHeightStyle"
import { cssRenderUnsafe as MinHeightStyleCssRenderUnsafe } from "../bobStyles/minHeightStyle"
import { cssRenderUnsafe as WidthStyleCssRenderUnsafe } from "../bobStyles/widthStyleV2"
import { cssRenderUnsafe as MaxWidthStyleCssRenderUnsafe } from "../bobStyles/maxWidthStyle"
import { cssRenderUnsafe as MinWidthStyleCssRenderUnsafe } from "../bobStyles/minWidthStyle"
import { cssRenderUnsafe as cssRenderUnsafeBoundary } from "../bobStyles/boundaryStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeRadius } from "../bobStyles/radiusStyle"
import { cssRenderUnsafe as cssRenderUnsafeFilter } from "../bobStyles/filterStyle"
import { cssRenderUnsafe as ShadowStyleCssRenderUnsafe } from "../bobStyles/shadowStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeBorder } from "../bobStyles/borderStyleV2"
import { cssRenderUnsafe as cssRenderUnsafeColors } from "../bobStyles/colorsStyleV2"
import { cssRenderUnsafe as PositionCssRenderUnsafe } from "../bobStyles/positionStyle"
import { cssRenderUnsafe as cssRenderUnsafeCursor } from "../bobStyles/cursorStyle"
import {
  cleanPath,
  getOverrideInlineContentValues,
  getResponsiveOverrideInlineContentValues,
} from "../../../../../modules/shared-modules/utilities/components"
import { MediaObject } from "../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import { handleSrcLink, hasVersion } from "../../../../../modules/shared-modules/mediaLibrary/mediaLibraryUtils"
import {
  FormField,
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import {
  CMS,
  FORM,
  WRITE,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/textController/textContentHelper"
import { DummyPost } from "../../../../../modules/shared-modules/experienceManager/finder/content/contentPageHelper"
import JSONpath from "jsonpath"
import { mergeMedias } from "../bobHandlerV2"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { BobNmedia } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { MediaLabel, PageStylesheet } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import {
  handleBobBorderLabel,
  handleBobColorsLabel,
  handleBobShadowLabel,
  handleMediaStylesheetLabel,
} from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

type Media4Bob = {
  type: "image" | "video" | "other"
  isDirectLink: boolean
  url: string
  alt: string
  size: MediaObject["size"]
}

const responsiveStyles = (
  styles: BobNmedia,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
) => {
  const stylesheetShadowColorLabel = handleBobShadowLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const stylesheetBorderColorLabel = handleBobBorderLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const stylesheetColorsLabel = handleBobColorsLabel(styles, stylesheetLabels, breakpoint, behaviourState)

  return {
    ...HeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...WidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...SelfFlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...ShadowStyleCssRenderUnsafe(styles, breakpoint, behaviourState, undefined, stylesheetShadowColorLabel),
    ...cssRenderUnsafeBorder(styles, breakpoint, behaviourState, stylesheetBorderColorLabel),
    ...cssRenderUnsafeBoundary(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeRadius(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeColors(styles, breakpoint, behaviourState, undefined, stylesheetColorsLabel),
    ...cssRenderUnsafeFilter(styles, breakpoint, behaviourState),
    ...PositionCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeCursor(styles, breakpoint, behaviourState),
  }
}

const handleImageSrcTemplate = (
  isHover: boolean,
  defaultImageUrl: string,
  stateImageUrl: string | undefined
): string => {
  return !isHover ? defaultImageUrl : isHover && stateImageUrl ? stateImageUrl : defaultImageUrl
}

const isHoverEnabled = (templateObj: any) => {
  if (
    templateObj.behaviour.hover?.enable ||
    templateObj.tablet?.behaviour?.hover?.enable ||
    templateObj.mobile?.behaviour?.hover?.enable
  ) {
    return true
  }
  return false
}

const isFilterEnabled = (templateObj: any) => {
  if (templateObj.filter.enable || templateObj.tablet?.filter?.enable || templateObj.mobile?.filter?.enable) {
    return true
  }
  return false
}

const handleSize = (size: { enable: boolean; value: number; unit: string }): string | undefined => {
  if (size.enable) {
    return `${size.value}${size.unit}`
  }
  return undefined
}

const handleMedia = (
  pageResponse: PageTypes,
  post: Post | undefined,
  mediasId: { defaultMediaId: string | undefined; hoverMediaId: string | undefined },
  linkType: "video" | "other",
  singleObject: SingleObject,
  replacedMediaList: { [id: string]: MediaObject } | undefined
): {
  defaultMedia: Media4Bob
  hoverMedia: Media4Bob | undefined
} => {
  let defaultMedia: Media4Bob
  let hoverMedia: Media4Bob | undefined

  /**
   * default media
   */
  // placeholder
  if (!mediasId.defaultMediaId) {
    defaultMedia = {
      type: "image",
      url: "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png",
      isDirectLink: false,
      alt: "Fluxio",
      size: undefined,
    }
  }
  // is from media library
  else if (mediasId.defaultMediaId?.startsWith("media")) {
    const cutMediaId = mediasId.defaultMediaId.replace(/media-/g, "")
    const mediaObject = mergeMedias(singleObject, pageResponse, replacedMediaList).media[cutMediaId]
    const mediaUrl = handleMediaUrl(mediaObject, pageResponse, post?.title)

    if (mediaObject) {
      defaultMedia = {
        type: mediaObject.itemType,
        url: mediaUrl.url,
        isDirectLink: false,
        alt: mediaUrl.alt,
        size: mediaObject.size,
      }
    } else {
      console.warn("returning empty mediaObject")
      defaultMedia = {
        type: "image",
        url: "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png",
        isDirectLink: true,
        alt: "placeholder",
        size: 0,
      }
    }
  }
  // is from a direct link
  else {
    const mediaType = linkType === "video" ? "video" : "image"
    defaultMedia = { type: mediaType, url: mediasId.defaultMediaId, isDirectLink: true, alt: "", size: undefined }
  }

  /**
   * hover media
   */
  // placeholder
  if (!mediasId.hoverMediaId) {
    hoverMedia = undefined
  }
  // is from media library
  else if (mediasId.hoverMediaId?.startsWith("media")) {
    const cutMediaId = mediasId.hoverMediaId.replace(/media-/g, "")
    const mediaObject = mergeMedias(singleObject, pageResponse, replacedMediaList).media[cutMediaId]
    const mediaUrl = handleMediaUrl(mediaObject, pageResponse, post?.title)

    hoverMedia = {
      type: mediaObject.itemType,
      url: mediaUrl.url,
      isDirectLink: false,
      alt: mediaUrl.alt,
      size: mediaObject.size,
    }
  }
  // is from a direct link
  else {
    const mediaType = linkType === "video" ? "video" : "image"
    hoverMedia = { type: mediaType, url: mediasId.hoverMediaId, isDirectLink: true, alt: "", size: undefined }
  }

  return { defaultMedia, hoverMedia }
}

const handleMediaId = (
  item: Post | FormField | undefined,
  pageResponse: PageTypes,
  field: string,
  inlineContent: ObjectContent["inlineContent"],
  overrides: ObjectContentOverride | undefined,
  hoverEnable: boolean,
  mediaField: string
): { defaultMediaId: string; hoverMediaId: string | undefined } => {
  let content = { inlineContent, overrides }
  const contentSrc = getOverrideInlineContentValues(`${field}.contentSrc`, content)

  // custom content
  if (contentSrc === WRITE) {
    const stylesheetLabels = pageResponse.stylesheet?.labels
    const mediaLabel: string | undefined = inlineContent.labels?.[`${field}.src`]
    const mediaLabelValue = stylesheetLabels?.[mediaLabel || ""] as MediaLabel

    return {
      defaultMediaId: handleMediaStylesheetLabel(
        mediaLabelValue,
        getOverrideInlineContentValues(`${field}.src`, content)
      ),
      hoverMediaId: hoverEnable && getOverrideInlineContentValues(`${field}.behaviour-hover-src`, content),
    }
  }
  // cms content
  else if (contentSrc === CMS && item) {
    return {
      defaultMediaId: getMediaStringField(item, content, field, mediaField),
      hoverMediaId: undefined,
    }
  }
  // form content
  else if (contentSrc === FORM && item) {
    return {
      defaultMediaId: getMediaStringField(item, content, field, mediaField),
      hoverMediaId: undefined,
    }
  }
  // content page
  else {
    const item = pageResponse.contentPage?.content || DummyPost
    return {
      defaultMediaId: getMediaStringField(item, content, field, mediaField),
      hoverMediaId: undefined,
    }
  }
}

const handleMediaUrl = (
  mediaObject: MediaObject,
  pageResponse: PageTypes,
  postTitle: Post["title"] | undefined
): {
  url: string
  alt: string
} => {
  const placeholder = "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png"
  const placeholderAlt = "Fluxio"

  // no media object, render placeholder
  if (!mediaObject) {
    console.warn("Missing media object")
    return {
      url: placeholder,
      alt: placeholderAlt,
    }
  }

  //With no profile available we stop and return a placeholder
  const profileName = mediaObject.profileName || pageResponse.profileName
  if (!profileName) {
    console.warn("Missing profileName")
    return {
      url: placeholder,
      alt: placeholderAlt,
    }
  }

  const mediaAlt = mediaObject.alt || postTitle || pageResponse.contentPage?.content?.title || ""

  // supported extension
  if (mediaObject.extension !== "webp" || pageResponse.webpSupport || (window.Modernizr && window.Modernizr.webp)) {
    return {
      url: `https://static-media.fluxio.cloud/${profileName}/${mediaObject.id}${hasVersion(mediaObject.version)}.${
        mediaObject.extension
      }`,
      alt: mediaAlt,
    }
  }

  // Fallback for not supported .webp
  console.warn(`No support for webp, using fallback for ${mediaObject.id}`)
  return {
    url: `https://static-media.fluxio.cloud/${profileName}/${mediaObject.id}${hasVersion(mediaObject.version)}.${
      mediaObject.fallbackExtension
    }`,
    alt: mediaAlt,
  }
}

const getMediaStringField = (
  item: Post | FormField,
  content: { inlineContent: ObjectContent["inlineContent"]; overrides: ObjectContentOverride | undefined },
  bobField: string,
  mediaField: string
): string => {
  let bobContent = getOverrideInlineContentValues(`${bobField}${mediaField}`, {
    inlineContent: content.inlineContent,
    overrides: content.overrides,
  })

  if (bobContent) {
    if (bobContent.startsWith("$")) {
      let value = JSONpath.query(item, bobContent)
      return value && value.length > 0 ? value[0] : ""
    }

    return cleanPath(item, bobContent) || ""
  }

  return ""
}

const properMediaAlt = (mediaAlt: string, postObj: Post) => {
  if (mediaAlt && mediaAlt !== "") return mediaAlt
  if (postObj) {
    if (postObj.picture_orig_alt && postObj.picture_orig_alt !== "") return postObj.picture_orig_alt
    if (postObj.title && postObj.title !== "") return postObj.title
  }
  return ""
}

const responsiveMediaLink = (
  breakpoint: Breakpoint,
  pageResponse: PageTypes,
  inlineContent: ObjectContent["inlineContent"],
  overrides: ObjectContentOverride | undefined,
  field: string
):
  | {
      mediaSrc: string
      mediaAlt: string
      fallbackExtension?: string
      mediaInfo?: MediaObject
    }
  | undefined => {
  const mediaSrc = getResponsiveOverrideInlineContentValues(`${field}.${breakpoint}-src`, {
    inlineContent,
    overrides: overrides,
  })

  if (mediaSrc) {
    return handleSrcLink(pageResponse, mediaSrc)
  }

  return undefined
}

const handleWidthAndHeightReplace = (cssPropertiesObj: any) => {
  if (cssPropertiesObj.width) {
    cssPropertiesObj.maxWidth =
      cssPropertiesObj.width !== "auto !important" ? cssPropertiesObj.width : "auto !important"
    cssPropertiesObj.width = cssPropertiesObj.width === "auto !important" && cssPropertiesObj.width
  }
  if (cssPropertiesObj.height) {
    cssPropertiesObj.maxHeight =
      cssPropertiesObj.height !== "auto !important" ? cssPropertiesObj.height : "auto !important"
    cssPropertiesObj.height = cssPropertiesObj.height === "auto !important" && cssPropertiesObj.height
  }
  return cssPropertiesObj
}

export {
  responsiveStyles,
  handleImageSrcTemplate,
  handleMedia,
  isHoverEnabled,
  isFilterEnabled,
  handleSize,
  handleMediaId,
  properMediaAlt,
  responsiveMediaLink,
  handleWidthAndHeightReplace,
}

export type { Media4Bob }
