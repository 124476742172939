import { Flex } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/flexStyle"
import { Grid } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/gridPropertiesStyle"
import { SelfFlex } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/selfFlexStyle"
import { Shadow as textShadow } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/textShadowStyle"
import { Shadow } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/shadowStyle"
import { Boundary as BoundaryV2 } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/boundaryStyleV2"
import { Colors } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/colorsStyle"
import { Border as BorderV2 } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/borderStyleV2"
import { Height as HeightV2 } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/heightStyleV2"
import { MaxHeight } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/maxHeightStyle"
import { MaxWidth } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/maxWidthStyle"
import { MinHeight } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/minHeightStyle"
import { MinWidth } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/minWidthStyle"
import { Width as WidthV2 } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/widthStyle"
import { Position } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/positionStyle"
import { ZIndex } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/zindexStyle"
import { Radius } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/radiusStyle"
import { BackgroundImage } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/backgroundImageStyleV2"
import { CursorOpt } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/cursorStyle"
import { Filter } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/filterStyle"
import { TextDecorationOpt } from "../../../../areas/public-page/shared-public-page/bob/bobStyles/textDecorationStyle"

type AllBobs =
  | BobBackground
  | BobText
  | BobForm
  | BobFormV2
  | BobInput
  | BobMedia
  | BobIcon
  | BobCode
  | BobFlex
  | BobGrid
  | nBobText
  | BobNmedia
  | BobCodeV2

interface Styles {
  uuid: string
  component: string
  bobs: {
    [x: string]: AllBobs
  }
}

interface StylesV2<T> {
  uuid: string
  component: string
  bobs: {
    [x: string]: T
  }
}

interface GlobalStyles {
  globalStyles: Array<any>
}

/**
 * Bobs
 */
type bobBreakpoint<T> = Partial<Omit<T, "behaviour">> & {
  behaviour?: {
    active?: bobBehaviourBreakpoint<T>
    hover?: bobBehaviourBreakpoint<T>
    link?: any
  }
}
type bobBehaviour<T> = Partial<T> & { enable: boolean }
type bobBehaviourBreakpoint<T> = (Partial<T> & { enable?: boolean }) | undefined

interface BobV2<T> {
  uuid: string
  enable: boolean
  name: string
  globalStyleId: string
  labels?: { [key: string]: string }
  behaviour: {
    active: bobBehaviour<T>
    hover: bobBehaviour<T>
    link?: any
  }
  tablet: bobBreakpoint<T>
  mobile: bobBreakpoint<T>
}

interface BobFlex extends BobV2<BobFlex> {
  colors: Colors & BobLabels
  shadow: Shadow & BobLabels
  radius: Radius
  image: BackgroundImage
  border: BorderV2 & BobLabels
  flex: Flex
  spacing: BoundaryV2
  width: WidthV2
  height: HeightV2
  minWidth: MinWidth
  maxWidth: MaxWidth
  minHeight: MinHeight
  maxHeight: MaxHeight
  zIndex: ZIndex
  position: Position
  selfFlex: SelfFlex
  filter: Filter
  cursor?: CursorOpt
}

interface BobGrid extends BobV2<BobGrid> {
  grid: Grid
  image: any
  attachment: any
  selfFlex: any
}

interface nBobText extends BobV2<nBobText> {
  textTransform: "none" | "capitalize" | "uppercase" | "lowercase"
  tag: string
  textDecoration: string // no tused
  verticalAlign: "top" | "bottom" | "middle"
  fontStyle: string // no tused
  color: string
  textAlign: "right" | "left" | "center"
  fontFamily: string
  letterSpacing: number
  lineHeight: number
  fontSize: number
  fontType: "custom" | "google-fonts"
  fontWeight: number
  extraWeights: any
  spacing: BoundaryV2
  textShadow: textShadow & BobLabels
  selfFlex: SelfFlex
  width: WidthV2
  minWidth: MinWidth
  maxWidth: MaxWidth
  height: HeightV2
  minHeight: MinHeight
  maxHeight: MaxHeight
  zIndex: ZIndex
  position: Position
  cursor?: CursorOpt
  textDecorationV2?: TextDecorationOpt & BobLabels
}

interface BobNmedia extends BobV2<BobNmedia> {
  enable: boolean
  image: {
    enable: boolean
    position: string
    size: "contain" | "cover" | "auto"
    repeat: "repeat" | "no-repeat"
  }
  video: {
    enable: boolean
    controls: boolean
    position: string
    size: "contain" | "cover" | "auto"
  }
  filter: any
  radius: any
  spacing: BoundaryV2
  colors: Colors & BobLabels
  shadow: Shadow & BobLabels
  border: BorderV2 & BobLabels
  selfFlex: SelfFlex
  width: WidthV2
  minWidth: MinWidth
  maxWidth: MaxWidth
  height: HeightV2
  minHeight: MinHeight
  maxHeight: MaxHeight
  zIndex: ZIndex
  position: Position
  cursor?: CursorOpt
}

interface BobCodeV2 extends Bob {
  code: {
    default: string
    desktop?: string
    tablet?: string
    mobile?: string
  }
}

interface BobFormV2 extends BobFlex {}
type BobInput = BobFlex & nBobText

interface BobLabels {
  labels?: Labels
}

type Labels = Partial<{
  color: string
  colorFirst: string
  colorSecond: string
  topColor: string
  bottomColor: string
  leftColor: string
  rightColor: string
  fontFamily: string
}>

/**
 * old bobs
 *
 */
interface Bob {
  uuid: string
  enable: boolean
  name: string
  globalStyleId: string
}

interface BobBackground extends Bob {
  colors: any
  shadow: any
  radius: any
  image: any
  border: any
  lean: any
  height: any
  grid: any
  mobile: any
  tablet: any
  spacing: any
  scroll: any
  customCss: any
}

interface BobText extends Bob {
  verticalAlign: any
  fontFamily: any
  fontWeight: any
  letterSpacing: any
  lineHeight: any
  textAlign: any
  textTransform: any
  color: any
  fontSize: any
  tag: any
  shadow: any
  mobile: any
  tablet: any
  contentSrc: any
  customText: any
  behaviour: any
  customCss: any
}

interface BobMedia extends Bob {
  src: any
  image: any
  video: any
  alignment: any
  position: any
  spacing: any
  colors: any
  height: any
  width: any
  shadow: any
  radius: any
  border: any
  filter: any
  mobile: any
  tablet: any
  contentSrc: any
  behaviour: any
  customCss: any
}

interface BobIcon extends Bob {
  icon: any
  shadow: any
  color: any
  size: any
  spacing: any
  mobile: any
  tablet: any
  customCss: any
}

interface BobForm extends Bob {
  formId: string
  customCss: any
}

interface BobCode extends Bob {
  code: string
  customCss: any // shoudn't exist, deals with old logic
}

/**
 * Special Formatted Objects
 */
interface FormBobStyle {
  bobStyle: object // TODO: is this correct?
}

export function isBobForm(bob: AllBobs): BobForm {
  if ("formId" in bob) return bob
  throw new Error("type is not bobForm")
}

export function isBobFlex(bob: AllBobs): BobFlex {
  if ("flex" in bob) return bob
  throw new Error("type is not bobBackground")
}

export function isBobGrid(bob: AllBobs): BobGrid {
  if ("grid" in bob && "selfFlex" in bob) return bob
  throw new Error("type is not bobGrid")
}

export function isNbobText(bob: AllBobs): nBobText {
  if ("fontFamily" in bob && "width" in bob) return bob
  throw new Error("type is not nBobText")
}

export function isNbobNmedia(bob: AllBobs): BobNmedia {
  if ("video" in bob && "selfFlex" in bob) return bob
  throw new Error("type is not nBobText")
}

export function isBobFormV2(bob: AllBobs): BobFormV2 {
  if ("flex" in bob) return bob
  throw new Error("type is not bobForm")
}

export function isBobInput(bob: AllBobs): BobInput {
  if ("flex" in bob && "fontFamily" in bob) return bob
  throw new Error("type is not bobForm")
}

/**
 * old bobs
 *
 */

export function isBobBackground(bob: AllBobs): BobBackground {
  if ("lean" in bob && "grid" in bob) return bob
  throw new Error("type is not bobBackground")
}

export function isBobText(bob: AllBobs): BobText {
  if ("fontFamily" in bob && !("maxWidth" in bob)) return bob
  throw new Error("type is not bobText")
}

export function isBobMedia(bob: AllBobs): BobMedia {
  if ("filter" in bob && !("maxWidth" in bob)) return bob
  throw new Error("type is not bobMedia")
}

export function isBobIcon(bob: AllBobs): BobIcon {
  if ("icon" in bob) return bob
  throw new Error("type is not bobIcon")
}

export function isBobCode(bob: AllBobs): BobCode {
  if ("code" in bob && typeof bob.code === "string") return bob as BobCode
  throw new Error("type is not BobCode")
}

export function isBobCodeV2(bob: AllBobs): BobCodeV2 {
  if ("code" in bob && typeof bob.code !== "string") return bob as BobCodeV2
  throw new Error("type is not BobCodeV2")
}

export type {
  Styles,
  StylesV2,
  BobV2,
  bobBreakpoint,
  bobBehaviour,
  bobBehaviourBreakpoint,
  BobBackground,
  BobText,
  BobFlex,
  BobNmedia,
  nBobText,
  BobCodeV2,
  BobFormV2,
  BobInput,
  BobForm,
  BobMedia,
  BobGrid,
  BobIcon,
  BobCode,
  FormBobStyle,
  GlobalStyles,
  BobLabels,
}