import { union, Type, TypeOf, intersection, null as nullC, partial, literal } from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */

const CursorCodec = union([
  literal("auto"),
  literal("default"),
  literal("none"),
  literal("context-menu"),
  literal("help"),
  literal("pointer"),
  literal("progress"),
  literal("wait"),
  literal("cell"),
  literal("crosshair"),
  literal("text"),
  literal("vertical-text"),
  literal("alias"),
  literal("copy"),
  literal("move"),
  literal("no-drop"),
  literal("not-allowed"),
  literal("grab"),
  literal("grabbing"),
  literal("e-resize"),
  literal("n-resize"),
  literal("ne-resize"),
  literal("nw-resize"),
  literal("s-resize"),
  literal("se-resize"),
  literal("sw-resize"),
  literal("w-resize"),
  literal("ew-resize"),
  literal("ns-resize"),
  literal("nesw-resize"),
  literal("nwse-resize"),
  literal("col-resize"),
  literal("row-resize"),
  literal("all-scroll"),
  literal("zoom-in"),
  literal("zoom-out"),
])
const CursorOptCodec = nullable(CursorCodec)

const StylesCursorCodec = intersection([
  partial({ cursor: CursorOptCodec }),
  partial({
    behaviour: partial({
      active: partial({
        cursor: CursorOptCodec,
      }),
      hover: partial({
        cursor: CursorOptCodec,
      }),
    }),
    mobile: partial({
      cursor: CursorOptCodec,
      behaviour: partial({
        active: partial({
          cursor: CursorOptCodec,
        }),
        hover: partial({
          cursor: CursorOptCodec,
        }),
      }),
    }),
    tablet: partial({
      cursor: CursorOptCodec,
      behaviour: partial({
        active: partial({
          cursor: CursorOptCodec,
        }),
        hover: partial({
          cursor: CursorOptCodec,
        }),
      }),
    }),
  }),
])

type Cursor = TypeOf<typeof CursorCodec>
type CursorOpt = TypeOf<typeof CursorOptCodec>
type StylesCursor = TypeOf<typeof StylesCursorCodec>
// type GSCursor = TypeOf<typeof GSCursorCodec>
type CursorCSS = {
  cursor:
    | "auto"
    | "default"
    | "none"
    | "context-menu"
    | "help"
    | "pointer"
    | "progress"
    | "wait"
    | "cell"
    | "crosshair"
    | "text"
    | "vertical-text"
    | "alias"
    | "copy"
    | "move"
    | "no-drop"
    | "not-allowed"
    | "grab"
    | "grabbing"
    | "e-resize"
    | "n-resize"
    | "ne-resize"
    | "nw-resize"
    | "s-resize"
    | "se-resize"
    | "sw-resize"
    | "w-resize"
    | "ew-resize"
    | "ns-resize"
    | "nesw-resize"
    | "nwse-resize"
    | "col-resize"
    | "row-resize"
    | "all-scroll"
    | "zoom-in"
    | "zoom-out"
}

export function cursorCss(css: CursorCSS | ""): string {
  if (css.hasOwnProperty("cursor")) return (css as { cursor: string })["cursor"]
  return ""
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<CursorCSS> {
  const styles = StylesCursorCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return { cursor: "default" }
}

export function cssRender(
  stylesObj: StylesCursor,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<CursorCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.cursor)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj?.behaviour?.[behaviourState]?.cursor)
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj?.[breakpoint]?.cursor)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.cursor)
    }
  }
}

/**
 * Renders CursorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param cursorValue
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(cursorValue: CursorOpt | undefined): Partial<CursorCSS> {
  if (cursorValue) {
    return renderOpt(cursorValue)
  }
  return {}
}

/**
 * Renders CursorOpt css for breakpoints/state templates
 * Returns cursor
 * or empty for non written style props
 *
 * @param cursorValue
 * @param foundationStyle
 * @returns
 */
export function renderOpt(cursorValue: CursorOpt | undefined): Partial<CursorCSS> {
  if (cursorValue) {
    let cssValue = cursorValue
    return cssProperty(cssValue)
  }
  // no written style props
  return {}
}

export function cssProperty(cssValue: CursorCSS["cursor"]): CursorCSS {
  return { cursor: cssValue }
}

// GSCursor
export type { CursorCSS, StylesCursor, Cursor, CursorOpt }
