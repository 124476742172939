import React, { useState } from "react"
import ComponentHandler from "../../../../componentHandler"
import WarningDefaultSystem from "../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import {
  getContent,
  getStringField,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { alignment, alignmentWithAP } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { NavbarTopProps } from "."
import { ComponentSpecificAlignment } from "../../../bob/bobStyles/alignmentStyle"
import navbarStyles from "../../../../../../stylesheets/modules/templates/navbarComponent.module.sass"

const NavbarTop: React.FC<NavbarTopProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const handleMenuToggle = () => setShowMenu(!showMenu)

  const renderMenu = (childComponentsId: any[], background2Template: any) => {
    if (childComponentsId && childComponentsId.length > 0) {
      return childComponentsId.map((componentId, idx) => {
        let handledItem = handleObjectType(props.obj, componentId, props.pageResponse, props.expManager.emEditorType)
        return (
          <div key={idx}>
            <ComponentHandler
              position={idx}
              selectedInstanceId={props.obj.selectedInstanceId}
              key={idx}
              component={handledItem}
              pageResponse={props.pageResponse}
              matchOrder={props.matchOrder}
              expManager={props.expManager}
            />
          </div>
        )
      })
    } else {
      if (props.expManager?.enable) {
        let components: any[] = []
        for (let i = 0; i < 3; i++) {
          let columns = "4"
          components = [
            ...components,
            <div key={i} className={`navbar-menu ${navbarStyles["navbar-menu"]} col-12 col-md-${columns}`}>
              <div
                style={{
                  height: `${background2Template.height.value}${background2Template.height.unit}`,
                  border: "3px dashed #dbe0e7",
                }}
              ></div>
            </div>,
          ]
        }
        return (
          <WarningDefaultSystem>
            <div className={`row ${navbarStyles["row"]}`}>{components}</div>
          </WarningDefaultSystem>
        )
      }
    }
  }

  const renderImage = () => {
    let content = getContent(props.obj, "posts")
    if (content && content.length > 0) {
      let post = content[0]
      return (
        <div className='d-inline-flex'>
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            replacedMediaList={props.replacedMediaList}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field='media1'
            template={props.obj.styles}
            rules={props.componentRule}
            expManager={props.expManager}
            network={post.network}
            post={post}
          />
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            post={post}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field='text1'
            tag='p'
            value={getStringField(post, props.obj, props.pageResponse, "text1")}
            template={props.obj.styles}
            rules={props.componentRule}
            expManager={props.expManager}
            network={post.network}
            className='title text-nowrap'
          />
        </div>
      )
    }
  }

  const background1 = props.obj.styles.bobs.background1
  const background2 = props.obj.styles.bobs.background2
  const alignmentAdittionalProperties = {
    flexDirection: "column",
    self: false,
  } as ComponentSpecificAlignment
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignmentWithAP(
    background1,
    alignmentAdittionalProperties
  )
  const {
    alignmentStyles: alignmentStylesMenu,
    tabletAlignment: tabletAlignmentMenu,
    mobileAlignment: mobileAlignmentMenu,
  } = alignment(background2)
  const childComponentsId = props.obj.children
  return (
    <div
      className={`navigation ${navbarStyles["navigation"]} navbar-component ${navbarStyles["navbar-component"]} navbar-top`}
    >
      <AlignmentHOC desktop={alignmentStyles} tablet={tabletAlignment} mobile={mobileAlignment}>
        <div className='navbar-identity'>
          {renderImage()}
          {childComponentsId && childComponentsId.length > 0 && (
            <span className={`navbar-menu-toggle ${navbarStyles["navbar-menu-toggle"]}`} onClick={handleMenuToggle}>
              <i className='fas fa-bars'></i>
            </span>
          )}
        </div>
        <div className={`navbar-items ${navbarStyles["navbar-items"]}`}>
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            inlineContent={props.obj.content.inlineContent}
            field={"background2"}
            rules={props.componentRule}
            template={props.obj.styles}
            expManager={props.expManager}
            className={`navbar-menu ${navbarStyles["navbar-menu"]}`}
          >
            <AlignmentHOC
              className={`navbar-menu-container ${navbarStyles["navbar-menu-container"]} ${
                !showMenu ? "hide" : "show"
              }`}
              desktop={alignmentStylesMenu}
              tablet={tabletAlignmentMenu}
              mobile={mobileAlignmentMenu}
            >
              {renderMenu(childComponentsId, background2)}
            </AlignmentHOC>
          </BobComponentHandler>
        </div>
      </AlignmentHOC>
    </div>
  )
}

export default NavbarTop
