import { type, union, Type, TypeOf, literal, intersection, null as nullC, number as numberC, partial } from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { get2WithNull } from "../bobUtils"

const nullable = <A>(t: Type<A>) => union([t, nullC])

const UnitCodec = union([literal("px"), literal("%"), literal("vh"), literal("auto")])
const ValueCodec = union([
  literal("relative"),
  literal("static"),
  literal("absolute"),
  literal("fixed"),
  literal("sticky"),
])
const SideCodec = type({
  value: numberC,
  unit: UnitCodec,
})
const PositionCodec = type({
  value: ValueCodec,
  top: SideCodec,
  bottom: SideCodec,
  left: SideCodec,
  right: SideCodec,
})

const SideOptCodec = partial({
  value: nullable(numberC),
  unit: nullable(UnitCodec),
})
const PositionOptCodec = partial({
  value: nullable(ValueCodec),
  top: nullable(SideOptCodec),
  bottom: nullable(SideOptCodec),
  left: nullable(SideOptCodec),
  right: nullable(SideOptCodec),
})

const StylesPositionCodec = intersection([
  type({ position: PositionCodec }),
  partial({
    behaviour: partial({
      active: partial({
        position: PositionOptCodec,
      }),
      hover: partial({
        position: PositionOptCodec,
      }),
    }),
    mobile: partial({
      position: PositionOptCodec,
      behaviour: partial({
        active: partial({
          position: PositionOptCodec,
        }),
        hover: partial({
          position: PositionOptCodec,
        }),
      }),
    }),
    tablet: partial({
      position: PositionOptCodec,
      behaviour: partial({
        active: partial({
          position: PositionOptCodec,
        }),
        hover: partial({
          position: PositionOptCodec,
        }),
      }),
    }),
  }),
])

type Position = TypeOf<typeof PositionCodec>
type PositionOpt = TypeOf<typeof PositionOptCodec>
type StylesPosition = TypeOf<typeof StylesPositionCodec>
type PositionCSS = {
  position: "relative" | "static" | "absolute" | "fixed" | "sticky" | "initial"
  top: string
  left: string
  right: string
  bottom: string
}

const DefaultPositionCSS: PositionCSS = {
  position: "initial",
  top: "initial",
  left: "initial",
  right: "initial",
  bottom: "initial",
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<PositionCSS> {
  const styles = StylesPositionCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return DefaultPositionCSS
}

export function cssRender(
  stylesObj: StylesPosition,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<PositionCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj.position)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.position,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.position, stylesObj.position)
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.position, mergeBob2(stylesObj?.[breakpoint]?.position, stylesObj.position))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.position,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.position,
          stylesObj?.behaviour?.[behaviourState]?.position,
          stylesObj?.[breakpoint]?.position,
          stylesObj.position
        )
      )
    }
  }
}

export function render(positionObj: Position): PositionCSS {
  return {
    position: positionObj.value,
    top: `${isNumberOrAuto(positionObj.top.value, positionObj.top.unit)}`,
    bottom: `${isNumberOrAuto(positionObj.bottom.value, positionObj.bottom.unit)}`,
    left: `${isNumberOrAuto(positionObj.left.value, positionObj.left.unit)}`,
    right: `${isNumberOrAuto(positionObj.right.value, positionObj.right.unit)}`,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param positionValue
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(
  defaultSelfPositionObj: Position,
  positionValue: PositionOpt | undefined
): Partial<PositionCSS> {
  if (positionValue) {
    return renderOpt(defaultSelfPositionObj, positionValue)
  }
  return {}
}

/**
 * Renders PositionOpt css for breakpoints/state templates
 * Returns position
 * or empty for non written style props
 *
 * @param positionValue
 * @param foundationStyle
 * @returns
 */
export function renderOpt(defaultSelfPositionObj: Position, positionValue: PositionOpt): Partial<PositionCSS> {
  let css = {}
  if (valueExists(positionValue?.value)) css = { ...css, position: `${positionValue?.value}` }
  if (valueExists(positionValue?.top?.value) || valueExists(positionValue?.top?.unit))
    css = { ...css, top: `${isNumberOrAuto(positionValue?.top?.value, positionValue?.top?.unit)}` }
  if (valueExists(positionValue?.bottom?.value) || valueExists(positionValue?.bottom?.unit))
    css = { ...css, bottom: `${isNumberOrAuto(positionValue?.bottom?.value, positionValue?.bottom?.unit)}` }
  if (valueExists(positionValue?.left?.value) || valueExists(positionValue?.left?.unit))
    css = { ...css, left: `${isNumberOrAuto(positionValue?.left?.value, positionValue?.left?.unit)}` }
  if (valueExists(positionValue?.right?.value) || valueExists(positionValue?.right?.unit))
    css = { ...css, right: `${isNumberOrAuto(positionValue?.right?.value, positionValue?.right?.unit)}` }

  return css
}

export function mergeBob2(positionObj: PositionOpt | undefined, defaultPositionObj: Position): PositionOpt {
  if (!positionObj) {
    return {
      value: undefined,
      top: undefined,
      bottom: undefined,
      left: undefined,
      right: undefined,
    }
  }

  const position: PositionOpt = {
    value: positionObj?.value,
    top: {
      value: positionObj?.top?.value,
      unit: positionObj?.top?.unit,
    },
    bottom: {
      value: positionObj?.bottom?.value,
      unit: positionObj?.bottom?.unit,
    },
    left: {
      value: positionObj?.left?.value,
      unit: positionObj?.left?.unit,
    },
    right: {
      value: positionObj?.right?.value,
      unit: positionObj?.right?.unit,
    },
  }

  if (valueExists(positionObj?.top?.value) || valueExists(positionObj?.top?.unit)) {
    const topValue = get2WithNull(positionObj?.top?.value, defaultPositionObj.top.value)
    const topUnit = get2WithNull(positionObj?.top?.unit, defaultPositionObj.top.unit)
    position.top = {
      ...position.top,
      value: topValue,
      unit: topUnit,
    }
  }

  if (valueExists(positionObj?.bottom?.value) || valueExists(positionObj?.bottom?.unit)) {
    const bottomValue = get2WithNull(positionObj?.bottom?.value, defaultPositionObj.bottom.value)
    const bottomUnit = get2WithNull(positionObj?.bottom?.unit, defaultPositionObj.bottom.unit)
    position.bottom = {
      ...position.bottom,
      value: bottomValue,
      unit: bottomUnit,
    }
  }

  if (valueExists(positionObj?.left?.value) || valueExists(positionObj?.left?.unit)) {
    const leftValue = get2WithNull(positionObj?.left?.value, defaultPositionObj.left.value)
    const leftUnit = get2WithNull(positionObj?.left?.unit, defaultPositionObj.left.unit)
    position.left = {
      ...position.left,
      value: leftValue,
      unit: leftUnit,
    }
  }

  if (valueExists(positionObj?.right?.value) || valueExists(positionObj?.right?.unit)) {
    const rightValue = get2WithNull(positionObj?.right?.value, defaultPositionObj.right.value)
    const rightUnit = get2WithNull(positionObj?.right?.unit, defaultPositionObj.right.unit)
    position.right = {
      ...position.right,
      value: rightValue,
      unit: rightUnit,
    }
  }

  return position
}

export function mergeBob3(
  positionObj: PositionOpt | undefined,
  positionDesktopBehaviour: PositionOpt | undefined,
  positionDefaultBreakpoint: PositionOpt | undefined,
  defaultPositionObj: Position
): PositionOpt {
  if (!positionObj) {
    return {
      value: undefined,
      top: undefined,
      bottom: undefined,
      left: undefined,
      right: undefined,
    }
  }

  let topValue = positionObj?.top?.value
  let topUnit = positionObj?.top?.unit
  let bottomValue = positionObj?.bottom?.value
  let bottomUnit = positionObj?.bottom?.unit
  let leftValue = positionObj?.left?.value
  let leftUnit = positionObj?.left?.unit
  let rightValue = positionObj?.right?.value
  let rightUnit = positionObj?.right?.unit

  if (valueExists(positionObj?.top?.value) || valueExists(positionObj?.top?.unit)) {
    topValue =
      positionObj?.top?.value ??
      positionDesktopBehaviour?.top?.value ??
      positionDefaultBreakpoint?.top?.value ??
      defaultPositionObj.top.value
    topUnit =
      positionObj?.top?.unit ??
      positionDesktopBehaviour?.top?.unit ??
      positionDefaultBreakpoint?.top?.unit ??
      defaultPositionObj.top.unit
  }

  if (valueExists(positionObj?.bottom?.value) || valueExists(positionObj?.bottom?.unit)) {
    bottomValue =
      positionObj?.bottom?.value ??
      positionDesktopBehaviour?.bottom?.value ??
      positionDefaultBreakpoint?.bottom?.value ??
      defaultPositionObj.bottom.value
    bottomUnit =
      positionObj?.bottom?.unit ??
      positionDesktopBehaviour?.bottom?.unit ??
      positionDefaultBreakpoint?.bottom?.unit ??
      defaultPositionObj.bottom.unit
  }

  if (valueExists(positionObj?.right?.value) || valueExists(positionObj?.right?.unit)) {
    rightValue =
      positionObj?.right?.value ??
      positionDesktopBehaviour?.right?.value ??
      positionDefaultBreakpoint?.right?.value ??
      defaultPositionObj.right.value
    rightUnit =
      positionObj?.right?.unit ??
      positionDesktopBehaviour?.right?.unit ??
      positionDefaultBreakpoint?.right?.unit ??
      defaultPositionObj.right.unit
  }

  if (valueExists(positionObj?.left?.value) || valueExists(positionObj?.left?.unit)) {
    leftValue =
      positionObj?.left?.value ??
      positionDesktopBehaviour?.left?.value ??
      positionDefaultBreakpoint?.left?.value ??
      defaultPositionObj.left.value
    leftUnit =
      positionObj?.left?.unit ??
      positionDesktopBehaviour?.left?.unit ??
      positionDefaultBreakpoint?.left?.unit ??
      defaultPositionObj.left.unit
  }

  return {
    value: positionObj?.value,
    top: {
      value: topValue,
      unit: topUnit,
    },
    bottom: {
      value: bottomValue,
      unit: bottomUnit,
    },
    left: {
      value: rightValue,
      unit: rightUnit,
    },
    right: {
      value: leftValue,
      unit: leftUnit,
    },
  }
}

/**
 *
 * @param x
 * return only auto without the number
 */
export function isNumberOrAuto(x: number | undefined | null, y: string | undefined | null): string {
  if (y === "auto") {
    return `${y}`
  }
  return `${x}${y}`
}

export type { PositionCSS, StylesPosition, Position, PositionOpt }
