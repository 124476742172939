import { type, union, Type, TypeOf, intersection, null as nullC, string as stringC, partial } from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { renderImportant, showIfWritten } from "../bobUtils"
import { ColorLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import { handleBobStylesheetLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */
const ColorCodec = stringC
const ColorOptCodec = nullable(stringC)

const StylesColorCodec = intersection([
  type({ color: ColorCodec }),
  partial({
    behaviour: partial({
      active: partial({
        color: ColorOptCodec,
      }),
      hover: partial({
        color: ColorOptCodec,
      }),
    }),
    mobile: partial({
      color: ColorOptCodec,
      behaviour: partial({
        active: partial({
          color: ColorOptCodec,
        }),
        hover: partial({
          color: ColorOptCodec,
        }),
      }),
    }),
    tablet: partial({
      color: ColorOptCodec,
      behaviour: partial({
        active: partial({
          color: ColorOptCodec,
        }),
        hover: partial({
          color: ColorOptCodec,
        }),
      }),
    }),
  }),
])

// const GSColorCodec = intersection([
//   ColorPropsCodec,
//   partial({
//     behaviour: partial({
//       active: ColorPropsOptCodec,
//       hover: ColorPropsOptCodec,
//     }),
//     mobile: intersection([
//       ColorPropsOptCodec,
//       partial({
//         behaviour: partial({
//           active: ColorPropsOptCodec,
//           hover: ColorPropsOptCodec,
//         }),
//       }),
//     ]),
//     tablet: intersection([
//       ColorPropsOptCodec,
//       partial({
//         behaviour: partial({
//           active: ColorPropsOptCodec,
//           hover: ColorPropsOptCodec,
//         }),
//       }),
//     ]),
//   }),
// ])

type Color = TypeOf<typeof ColorCodec>
type ColorOpt = TypeOf<typeof ColorOptCodec>
type StylesColor = TypeOf<typeof StylesColorCodec>
// type GSColor = TypeOf<typeof GSColorCodec>
type ColorCSS = { color: string }

export function colorCss(css: ColorCSS | ""): string {
  if (css.hasOwnProperty("color")) return (css as { color: string })["color"]
  return ""
}

export function responsiveStyle(colorValue: any, desktopDefaultValues?: any, breakpointDefaultValues?: any) {
  /**
   * Check if property is available, and fallback to breakpointDefault or the desktopDefault
   */
  const color = colorValue || breakpointDefaultValues || desktopDefaultValues
  // color prop
  return {
    color: `${color} !important`,
  }
}

export function renderCSSString(stylesObj: Partial<ColorCSS>): string {
  return `
    ${showIfWritten(stylesObj, "color")} 
    `
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined, // TODO: fix when working on other bobs
  stylesheetLabel: ColorLabel | undefined,
  shouldRenderImportant: boolean
): Partial<ColorCSS> {
  const styles = StylesColorCodec.decode(stylesObj)
  if (isRight(styles))
    return cssRender(styles.right, breakpoint, behaviourState, foundationStyle, stylesheetLabel, shouldRenderImportant)
  console.warn(decoderErrors(styles))
  return { color: "#000" }
}

// export function globalStyleCssRenderUnsafe(
//   gsObj: any,
//   breakpoint: Breakpoint,
//   behaviourState: BehaviourState_,
//   foundationStyle: FColor | undefined = undefined // TODO: Work on when foundation is added to global styles
// ): ColorCSS | "" {
//   const gs = GSColorCodec.decode(gsObj)
//   if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, foundationStyle)
//   console.warn(decoderErrors(gs))
//   return ""
// }

export function cssRender(
  stylesObj: StylesColor,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined,
  shouldRenderImportant: boolean
): Partial<ColorCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.color, foundationStyle, stylesheetLabel, shouldRenderImportant)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj?.behaviour?.[behaviourState]?.color,
        foundationStyle,
        stylesheetLabel,
        shouldRenderImportant
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj?.[breakpoint]?.color, foundationStyle, stylesheetLabel, shouldRenderImportant)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.color,
        foundationStyle,
        stylesheetLabel,
        shouldRenderImportant
      )
    }
  }
}

// export function globalStyleCssRender(
//   stylesObj: GSColor,
//   breakpoint: Breakpoint,
//   behaviourState: BehaviourState_,
//   foundationStyle: FColor | undefined
// ): ColorCSS {
//   if (breakpoint === "desktop") {
//     if (behaviourState === "default") {
//       return render(stylesObj, foundationStyle)
//     }
//     //hover | active
//     else {
//       return render(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj), foundationStyle)
//     }
//   }
//   //tablet | mobile
//   else {
//     if (behaviourState === "default") {
//       return render(merge2(stylesObj?.[breakpoint], stylesObj), foundationStyle)
//     }
//     //hover | active
//     else {
//       return render(
//         merge3(
//           stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
//           stylesObj.behaviour?.[behaviourState],
//           stylesObj?.[breakpoint],
//           stylesObj
//         ),
//         foundationStyle
//       )
//     }
//   }
// }

export function renderBob(
  colorValue: Color,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined,
  shouldRenderImportant: boolean
): ColorCSS {
  return render(colorValue, foundationStyle, stylesheetLabel, shouldRenderImportant)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param colorValue
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(
  colorValue: ColorOpt | undefined,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined,
  shouldRenderImportant: boolean
): Partial<ColorCSS> {
  if (colorValue || stylesheetLabel) {
    return renderOpt(colorValue, foundationStyle, stylesheetLabel, shouldRenderImportant)
  }
  return {}
}

export function render(
  colorValue: Color,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined,
  shouldRenderImportant: boolean
): ColorCSS {
  let cssValue = `${colorValue}${renderImportant(shouldRenderImportant)}`
  return cssProperty(cssValue, stylesheetLabel)
}

/**
 * Renders ColorOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param colorValue
 * @param foundationStyle
 * @returns
 */
export function renderOpt(
  colorValue: ColorOpt | undefined,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined,
  shouldRenderImportant: boolean
): Partial<ColorCSS> {
  if (colorValue || stylesheetLabel) {
    let cssValue = `${colorValue}${renderImportant(shouldRenderImportant)}`
    return cssProperty(cssValue, stylesheetLabel)
  }
  // no written style props
  return {}
}

export function cssProperty(cssValue: string, stylesheetLabel: ColorLabel | undefined): ColorCSS {
  return { color: handleBobStylesheetLabel(stylesheetLabel, cssValue) }
}

// GSColor
export type { ColorCSS, StylesColor, Color, ColorOpt }
