import mergeWith from "lodash/mergeWith"
import isArray from "lodash/isArray"
import { responsiveStyle as ShadowStyleResponsiveStyle } from "./bobStyles/shadowStyle"
import { responsiveStyle as responsiveStyleBoundary } from "./bobStyles/boundaryStyle"
import { responsiveStyle as responsiveStyleBorder } from "./bobStyles/borderStyle"
import {responsiveStyle as responsiveStyleRadius} from "./bobStyles/radiusStyle"
import { AlignmentResponsiveStyle } from "./bobStyles/alignmentStyle"
import {responsiveStyle as responsiveStyleFilter} from "./bobStyles/filterStyle"
import { responsiveStyle as responsiveStyleHeight } from "./bobStyles/heightStyle"
import SizeStyle from "./bobStyles/sizeStyle"
import { responsiveStyle as WidthStyleResponsiveStyle } from "./bobStyles/widthStyle"
import PositionStyle from "./bobStyles/objectPositionStyle"
import GridStyle from "./bobStyles/gridStyle"
import WrapStyle from "./bobStyles/wrapStyle"
import ScrollStyle from "./bobStyles/scrollStyle"
import { responsiveStyle as responsiveStyleBackgroundImage } from "./bobStyles/backgroundImageStyle"
import AttachmentStyle from "./bobStyles/attachmentStyle"
import BehaviourStyle from "./bobStyles/behaviourStyle"
import { responsiveStyle as BobTextStyleResponsiveStyle } from "./bobStyles/textStyle"
import { responsiveStyle as responsiveStyleColors } from "./bobStyles/colorsStyle"
import BobIconStyle from "./bobStyles/bobIconStyle"
import {responsiveStyle as responsiveStyleZIndex} from "./bobStyles/zindexStyle"
import { objValueExists } from "../../../../modules/shared-modules/utilities/utils"
import { GlobalStyle } from "../../../../modules/shared-modules/experienceManager/finder/globalStyles/globalStylesTypes"
import { FColor, FoundationStyle } from "../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { Breakpoint } from "../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"

/**
 *
 * @param bobObj
 * @param breakpoint
 * @param optionalProperties
 * This param is used to send aditional values that are needed for a specific style
 * The object must be named with the correct property name and the values inside an object, like so:
 * [property]: { valueName: value, valueName: value, etc}
 * @param excluded
 * The excluded param is used to prevent the loop of styles from returning (?a) specific(s) properties,
 * with the following structure:
 * ['property', 'property', etc...]
 * @param exclusive
 * To prevent the loop from happening, and thos rendering only some properties, instead of exluding everything,
 * we just return some, that are presented in this param, like so:
 * ['property', 'property', etc...]
 */
export function responsiveProperties(
  bobObj: any,
  breakpoint: Breakpoint,
  optionalProperties: any = {},
  excluded: Array<string> = [],
  exclusive?: Array<string>,
  foundationStyle?: Array<FoundationStyle>
) {
  let cssStylesObj = {}
  //We have properties to render
  if (bobObj[breakpoint]) {
    /**
     * With exclusive present we only return this properties
     */
    if (exclusive) {
      for (let property of exclusive) {
        if (bobObj[breakpoint][property]) {
          cssStylesObj = {
            ...cssStylesObj,
            ...stylePropertyHandler(property, bobObj[breakpoint], optionalProperties, foundationStyle),
          }
        }
      }
      return cssStylesObj
    }
    /**
     * Without exclusive property we render all properties
     */
    for (let property in bobObj[breakpoint]) {
      if (!excluded.includes(property)) {
        cssStylesObj = {
          ...cssStylesObj,
          ...stylePropertyHandler(property, bobObj[breakpoint], optionalProperties, foundationStyle),
        }
      }
    }
  }

  return cssStylesObj
}

export function stylePropertyHandler(
  property: string,
  styleObj: any,
  optionalProperties: any,
  foundationStyle?: Array<FoundationStyle>
) {
  // TODO: handle foundationStyle type when added to other bobs
  switch (property) {
    case "spacing":
      return responsiveStyleBoundary(styleObj[property])
    case "shadow":
      return ShadowStyleResponsiveStyle(styleObj[property], optionalProperties[property])
    case "border":
      return responsiveStyleBorder(styleObj[property])
    case "radius":
      return responsiveStyleRadius(styleObj[property])
    case "alignment":
      return AlignmentResponsiveStyle(styleObj[property], optionalProperties[property])
    case "filter":
      return responsiveStyleFilter(styleObj[property], optionalProperties[property])
    case "height":
      return responsiveStyleHeight(styleObj[property])
    case "size":
      return SizeStyle.responsiveStyle(styleObj[property])
    case "width":
      return WidthStyleResponsiveStyle(styleObj[property])
    case "position":
      return PositionStyle.responsiveStyle(styleObj[property])
    case "grid":
      return GridStyle.responsiveStyle(styleObj[property])
    case "wrap":
      return WrapStyle.responsiveStyle(styleObj[property])
    case "scroll":
      return ScrollStyle.responsiveStyle(styleObj[property])
    case "attachment":
      return AttachmentStyle.responsiveStyle(styleObj[property])
    case "behaviour":
      return BehaviourStyle.responsiveStyle(styleObj[property])
    case "colors":
      return responsiveStyleColors(styleObj[property], foundationStyle as Array<FColor>)
    case "image":
      return responsiveStyleBackgroundImage(
        { image: styleObj[property], colors: styleObj.colors },
        optionalProperties[property]
      )
    case "zIndex":
      return responsiveStyleZIndex(styleObj[property])
    default:
      return {}
  }
}

export function responsiveGroupStylesProperties(
  bobObj: any,
  breakpoint: "mobile" | "tablet",
  bobType: "text" | "icon" | "ntext"
) {
  if (bobType === "text" && bobObj[breakpoint]) {
    return BobTextStyleResponsiveStyle(bobObj[breakpoint])
  }
  if (bobType === "ntext" && bobObj[breakpoint]) {
    return BobTextStyleResponsiveStyle(bobObj[breakpoint])
  }
  if (bobType === "icon" && bobObj[breakpoint]) {
    return BobIconStyle.responsiveStyle(bobObj[breakpoint])
  }
  //Define that bobType
  return {}
}

/**
 * builds container classe's for each breackpoint when it exists
 * @param bobObj
 */
export function responsiveWrapClass(bobObj: any) {
  let className: string = ""
  if (bobObj.tablet && bobObj.tablet.wrap) className += `${bobObj.tablet.wrap}-md `
  if (bobObj.mobile && bobObj.mobile.wrap) className += `${bobObj.mobile.wrap}-sm`
  return className
}

/**
 * builds gutters classe's for each breackpoint when it exists
 * @param bobObj
 */
export function responsiveGuttersClass(bobObj: any, globalStyleTemplate?: GlobalStyle, noGuttersDefault?: boolean) {
  // TODO: sould we copy only the prop that is edited instead of merging all of the template?
  let bobObjClone
  if (globalStyleTemplate) bobObjClone = mergeWith({}, bobObj, globalStyleTemplate, mergeCopyArrays)
  else bobObjClone = bobObj
  // desktop gutters value
  let className: string = bobObjClone.grid.gutters ? "" : "no-gutters "
  //Overwrite default
  if (!bobObjClone.grid.hasOwnProperty("gutters")) className = !noGuttersDefault ? "" : "no-gutters"

  if (bobObjClone.tablet && bobObjClone.tablet.grid && objValueExists(bobObjClone.tablet.grid, "gutters"))
    className += bobObjClone.tablet.grid.gutters ? "gutters-md " : "no-gutters-md "
  if (bobObjClone.mobile && bobObjClone.mobile.grid && objValueExists(bobObjClone.mobile.grid, "gutters"))
    className += bobObjClone.mobile.grid.gutters ? "gutters-sm " : "no-gutters-sm "
  return className
}

/**
 * method exists on componets too
 * here it needs to reneder !important flag due to the css being used on a class
 * on the components side it cannot render !important because inline styles doens't accept it
 * @param template
 * @param flexDirection
 * @param self
 */
export function handleAlignment(template: any, flexDirection?: string, self = false) {
  if (!template.alignment) return {}
  let horizontalValue =
    template.alignment.horizontal === "left"
      ? "flex-start"
      : template.alignment.horizontal === "right"
        ? "flex-end"
        : template.alignment.horizontal
  let verticalValue =
    template.alignment.vertical === "top"
      ? "flex-start"
      : template.alignment.vertical === "bottom"
        ? "flex-end"
        : template.alignment.vertical
  return {
    justifyContent: flexDirection === "column" ? verticalValue + " !important" : horizontalValue + " !important",
    alignItems: flexDirection === "column" ? horizontalValue + " !important" : verticalValue + " !important",
    alignSelf: self && flexDirection === "column" ? horizontalValue + " !important" : verticalValue + " !important",
    display: "flex !important",
    flexDirection: flexDirection + " !important",
  }
}

// map contents based on number of columns
// maintain the same pattern of columns if there are multiple lines
export function handleColumns(idx: number, counter: any, backgroundTemplate: any, globalStyleTemplate?: GlobalStyle) {
  let backgroundTemplateClone = backgroundTemplate
  let mergedTemplate
  if (globalStyleTemplate) mergedTemplate = mergeWith({}, backgroundTemplateClone, globalStyleTemplate, mergeCopyArrays)
  else mergedTemplate = backgroundTemplate
  let columns = "12"
  let columnsTablet
  let columnsMobile
  let nthCol = mergedTemplate.grid.columns && mergedTemplate.grid.columns.length
  let nthColTablet = handleDeprecatedCols("tablet", mergedTemplate, nthCol)
  let nthColMobile = handleDeprecatedCols("mobile", mergedTemplate, nthCol)
  counter.desktop = idx % nthCol !== 0 ? counter.desktop + 1 : 0
  counter.tablet = idx % nthColTablet !== 0 ? counter.tablet + 1 : 0
  counter.mobile = idx % nthColMobile !== 0 ? counter.mobile + 1 : 0
  columns = mergedTemplate.grid.columns ? mergedTemplate.grid.columns[counter.desktop] : mergedTemplate.grid.cols

  /**
   * write desktop as a default value on other breakpoints
   *
   * DEPRECATED:
   * Due to the mobile/tablet columns being defaulted to 12 on the past,
   * we need to handle the bobs that were using this behaviour as if the behaviour still exists,
   * instead of defaulting to the desktop styles.
   * The objects that are edited and republished will have the 'newBehaviour' prop so that the fallback stops working for this ones.
   *
   * This should only be true to objects that were never written with the colsMobile and colTablet props on the grid object.
   * This ones were migrated so that the mobile.grid.columns values is equal to the mobileCols value.
   * So no need to fallback to 12
   */
  if (
    !mergedTemplate.grid.newBehaviour &&
    JSON.stringify(mergedTemplate.grid.columns) !== '["12"]' &&
    !mergedTemplate.grid.columnsTablet &&
    !mergedTemplate.grid.columnsMobile &&
    !backgroundTemplate.tablet?.grid?.columns &&
    !backgroundTemplate.mobile?.grid?.columns
  ) {
    columnsTablet = "12"
    columnsMobile = "12"
  } else {
    columnsTablet = columns
    columnsMobile = columns
  }

  /**
   * Check for legacy data and render that, if new structure is not present
   * Write breakpoint value if it exists
   */
  //Mobile
  if (backgroundTemplateClone.mobile && backgroundTemplateClone.mobile.grid?.columns) {
    columnsMobile = backgroundTemplateClone.mobile.grid.columns[counter.mobile]
  }
  //Deprecated
  else if (backgroundTemplateClone.grid.columnsMobile) {
    columnsMobile = backgroundTemplateClone.grid.columnsMobile[counter.mobile]
  }
  //Tablet
  if (backgroundTemplateClone.tablet && backgroundTemplateClone.tablet.grid?.columns) {
    columnsTablet = backgroundTemplateClone.tablet.grid.columns[counter.tablet]
  }
  //Deprecated
  else if (backgroundTemplateClone.grid.columnsTablet) {
    columnsTablet = backgroundTemplateClone.grid.columnsTablet[counter.tablet]
  }
  return { columns, columnsTablet, columnsMobile, counter }
}

export function handleDeprecatedCols(type: string, template: any, nthCol: number) {
  if (type === "tablet")
    return template.tablet?.grid?.columns
      ? template.tablet.grid.columns.length
      : template.grid.columnsTablet
        ? template.grid.columnsTablet.length
        : nthCol
  if (type === "mobile")
    return template.mobile?.grid?.columns
      ? template.mobile.grid.columns.length
      : template.grid.columnsMobile
        ? template.grid.columnsMobile.length
        : nthCol
  return undefined
}

export function mergeCopyArrays(objValue: any, srcValue: any) {
  if (isArray(objValue)) return srcValue
}

export function mergeStyleWithGS(styleObject: any, globalStyleObject?: any) {
  if (!globalStyleObject) return styleObject

  let clonedStyleObject = styleObject
  return mergeWith({}, clonedStyleObject, globalStyleObject, mergeStylesOptions)
}

/**
 * force enable value true when it doenst exist on the template
 * @param styleObject
 */
export function handleEnableValues(styleObject: any) {
  if (styleObject.behaviour.hover.enable) {
    for (let prop in styleObject.behaviour.hover) {
      if (styleObject[prop] && !styleObject[prop].hasOwnProperty("enable") && styleObject[prop].enable)
        styleObject.behaviour.hover[prop].enable = styleObject[prop].enable
    }
  }
  return styleObject
}

/**
 * This function is used to merge the styles values,
 * keeping the key elements, that are needed.
 * 1. Arrays arent merged, we keep the object to merge.
 * 2. We create exceptions for the following properties:
 * - * uuid
 * - * name
 * @param objValue
 * @param srcValue
 * @param key
 */
export function mergeStylesOptions(objValue: any, srcValue: any, key: string) {
  if (key === "uuid") return objValue
  if (key === "name") return objValue
  if (isArray(objValue)) return srcValue
}
