import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getDataSet, writeDataSet } from "../../../../../store/shared-store/actions/publicPageActions"
import { bindActionCreators } from "redux"
import { handleObjectType } from "../../../../../modules/shared-modules/utilities/components"
import ComponentHandler from "../../../componentHandler"
import { handleColumns, responsiveGuttersClass } from "../../../shared-public-page/bob/bobHandler"
import AlignmentHOC from "../../../shared-public-page/fluxio-components/alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../modules/shared-modules/utilities/conditionalController"
import { DataSetContainerProps } from "."
import {
  BobBackground,
  isBobBackground,
} from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const DataSetContainer: React.FC<DataSetContainerProps> = (props) => {
  const dispatch = useDispatch()
  const actions = bindActionCreators({ getDataSet, writeDataSet }, dispatch)

  // const {
  //   fetchingDataSet,
  //   dataSet,
  //   errorFetchingDataSet,
  //   dataSetParams
  // } = useSelector((state: any) => state.publicPageReducer.server);

  useEffect(() => {
    if (!props.expManager.enable || props.expManager.content) {
      if (
        props.obj.content.dataset &&
        (!props.obj.content.dataset.posts || props.obj.content.dataset.posts.length === 0)
      )
        actions.getDataSet(props.obj.content.dataset.uuid, undefined)
    }
    // inside EM use dataset inside object
    else handlePopulateDataSet(props)
  }, [])

  const handlePopulateDataSet = (props: DataSetContainerProps) => {
    if (props.obj.content && props.obj.content.dataset) {
      actions.writeDataSet(props.obj.content.dataset)
    }
  }

  const renderComponents = (childComponents: string[], background1: BobBackground) => {
    if (childComponents && childComponents.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return childComponents.map((objId: string, idx: number) => {
        const handledItem = handleObjectType(props.obj, objId, props.pageResponse, props.expManager.emEditorType)
        const columns = handleColumns(
          idx,
          counter,
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <ComponentHandler
              position={idx}
              key={idx}
              component={handledItem}
              pageResponse={props.pageResponse}
              selectedInstanceId={props.obj.selectedInstanceId}
              matchOrder={props.matchOrder}
              expManager={props.expManager}
            />
          </div>
        )
      })
    }
  }

  const renderChilds = (background1TemplateMerged: BobBackground) => {
    // get only possible childs
    const childsId = props.obj.children
    if (childsId && childsId.length > 0) return renderComponents(childsId, background1TemplateMerged)

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          verticalAlign: "middle",
          height: `${background1TemplateMerged.height.enable ? "100" : "100"}${
            background1TemplateMerged.height.enable ? "%" : "px"
          }`,
          border: "3px dashed #dbe0e7",
        }}
      >
        <div className='alert warning m-0' role='alert'>
          Add results map, results list container, or filters component as a child to handle the chosen data set.
        </div>
      </div>
    )
  }

  const background1 = isBobBackground(props.obj.styles.bobs.background1)
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

  return (
    <div className='containers datase-container-component'>
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderChilds(background1)}
      </AlignmentHOC>
    </div>
  )
}

export default DataSetContainer