import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { get2WithNull } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const UnitCodec = union([
  literal("px"),
  literal("%"),
  literal("vw"),
  literal("vh"),
  literal("auto"),
])
const MaxHeightPropsCodec = type({ value: numberC, unit: UnitCodec })
const MaxHeightCodec = intersection([
  type({ enable: booleanC }),
  MaxHeightPropsCodec,
])

const MaxHeightPropsOptCodec = partial({
  unit: nullable(UnitCodec),
  value: nullable(numberC),
})
const MaxHeightOptCodec = intersection([
  partial({ enable: nullable(booleanC) }),
  MaxHeightPropsOptCodec,
])

const StylesMaxHeightCodec = intersection([
  type({ maxHeight: MaxHeightCodec }),
  partial({
    behaviour: partial({
      active: partial({ maxHeight: MaxHeightPropsOptCodec }),
      hover: partial({ maxHeight: MaxHeightPropsOptCodec }),
    }),
    mobile: intersection([
      partial({ maxHeight: MaxHeightPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ maxHeight: MaxHeightPropsOptCodec }),
          hover: partial({ maxHeight: MaxHeightPropsOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ maxHeight: MaxHeightPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ maxHeight: MaxHeightPropsOptCodec }),
          hover: partial({ maxHeight: MaxHeightPropsOptCodec }),
        }),
      }),
    ]),
  }),
])

type MaxHeight = TypeOf<typeof MaxHeightCodec>
type MaxHeightProps = TypeOf<typeof MaxHeightPropsCodec>
type MaxHeightOpt = TypeOf<typeof MaxHeightOptCodec>
type StylesMaxHeight = TypeOf<typeof StylesMaxHeightCodec>
type MaxHeightCSS = {
  "max-height": string
}


export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<MaxHeightCSS> {
  const styles = StylesMaxHeightCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return {}
}

export function cssRender(
  stylesObj: StylesMaxHeight,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<MaxHeightCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.maxHeight)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.maxHeight,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.maxHeight, stylesObj.maxHeight)
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.maxHeight, mergeBob2(stylesObj?.[breakpoint]?.maxHeight, stylesObj.maxHeight))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.maxHeight,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.maxHeight,
          stylesObj?.behaviour?.[behaviourState]?.maxHeight,
          stylesObj?.[breakpoint]?.maxHeight,
          stylesObj.maxHeight
        )
      )
    }
  }
}

export function renderBob(maxHeightObj: MaxHeight): Partial<MaxHeightCSS> {
  if (!maxHeightObj.enable) return {}

  return render(maxHeightObj)
}

export function render(maxHeightObj: MaxHeightProps): MaxHeightCSS {
  const heightValue = maxHeightObj.value
  const heightUnit = maxHeightObj.unit
  return {
    "max-height": `${heightValue}${heightUnit}`,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param maxHeightObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(
  defaultMaxHeightObj: MaxHeight,
  maxHeightObj: MaxHeightOpt | undefined
): Partial<MaxHeightCSS> {
  if (maxHeightObj?.enable === false) {
    if (defaultMaxHeightObj.enable) return { "max-height": "auto" }

    return {}
  }

  if (maxHeightObj?.enable) return renderOpt(maxHeightObj)

  return {}
}

/**
 * Renders MaxHeightOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param maxHeightObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(maxHeightObj: MaxHeightOpt): Partial<MaxHeightCSS> {
  let css = {}
  if (objValueExists(maxHeightObj, "unit") || objValueExists(maxHeightObj, "value")) {
    css = { "max-height": `${maxHeightObj.value}${maxHeightObj.unit}` }
  }

  return css
}

export function mergeBob2(maxHeightObj: MaxHeightOpt | undefined, defaultMaxHeightObj: MaxHeight): MaxHeightOpt {
  // no filter object
  if(!maxHeightObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = maxHeightObj?.enable ?? defaultMaxHeightObj.enable
  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(maxHeightObj, 'value') ||
    objValueExists(maxHeightObj, 'unit')
  ) {
    const value = get2WithNull(maxHeightObj?.value, defaultMaxHeightObj.value)
    const unit = get2WithNull(maxHeightObj?.unit, defaultMaxHeightObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export function mergeBob3(
  maxHeightObj: MaxHeightOpt | undefined,
  maxHeightDesktopBehaviour: MaxHeightOpt | undefined,
  maxHeightDefaultBreakpoint: MaxHeightOpt | undefined,
  defaultMaxHeightObj: MaxHeight
): MaxHeightOpt {
  // no filter object
  if(!maxHeightObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = 
    maxHeightObj?.enable ?? maxHeightDesktopBehaviour?.enable ?? maxHeightDefaultBreakpoint?.enable ?? defaultMaxHeightObj.enable

  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(maxHeightObj, 'value') ||
    objValueExists(maxHeightObj, 'unit')
  ) {
    const value = get2WithNull(maxHeightObj?.value, defaultMaxHeightObj.value)
    const unit = get2WithNull(maxHeightObj?.unit, defaultMaxHeightObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export type { MaxHeightCSS, StylesMaxHeight, MaxHeightProps, MaxHeight, MaxHeightOpt }