import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { GoogleMap, LoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api'
import { handleColumns, responsiveGuttersClass } from "../../../shared-public-page/bob/bobHandler"
import BobComponentHandler from "../../../shared-public-page/bob/bobComponentHandler"
import {
  handleDataSetAsContentBlock,
  handleObjectType,
} from "../../../../../modules/shared-modules/utilities/components"
import AlignmentHOC from "../../../shared-public-page/fluxio-components/alignmentHOC/alignmentHOC"
import ComponentHandler from "../../../componentHandler"
import { alignment } from "../../../../../modules/shared-modules/utilities/conditionalController"
import { ResultsMapProps } from "."
import resultsMapStyle from "../../../../../stylesheets/modules/templates/resultsMap.module.sass"

const containerStyle = {
  width: "100%",
  height: "100%",
}

const containerFullscreenStyle = {
  width: "100%",
  height: "100%",
  position: "fixed",
  left: "0",
  right: "0",
  zIndex: "999",
}

const center = {
  lat: 38.7334866,
  lng: -8.9292594,
}

type ResultsMapState = {
  infoOpen: boolean
  place: any
  fullScreenMode: boolean
}

class ResultsMap extends React.Component<ResultsMapProps, ResultsMapState> {
  constructor(props: ResultsMapProps) {
    super(props)
    this.state = {
      infoOpen: false,
      place: false,
      fullScreenMode: false,
    }
  }

  child: any = []

  /**
   *
   * @param {postIdx} idx
   * We need this idx from the post array
   * to properly call the inner function down the react tree.
   * if we dont use this we overwrite the same ref over and over
   * leaving only the reference to the last rendered object.
   */
  handleCardClick(idx: any) {
    if (this.child && this.child[idx]) this.child[idx].handleInnerFunction()
  }

  handleMarker(marker: any, place: any) {
    this.setState({
      ...this.state,
      place,
      infoOpen: !this.state.infoOpen,
    })
  }

  handleFullscreen() {
    this.setState({
      ...this.state,
      fullScreenMode: !this.state.fullScreenMode,
    })
  }

  /**
   * For now this render is best used with modal components
   *
   */
  renderChildComponents() {
    let childsId = this.props.obj.children
    if (childsId && childsId.length > 0 && this.props.dataSet) {
      let childComponent = childsId[0]
      let handledItem = handleObjectType(
        this.props.obj,
        childComponent,
        this.props.pageResponse,
        this.props.expManager.emEditorType
      )
      return (
        <ComponentHandler
          onRef={(ref: any) => (this.child[0] = ref)}
          position={0}
          key={0}
          component={handleDataSetAsContentBlock(
            handledItem,
            this.props.dataSet.mapPosts.filter((post: any) => post.id === this.state.place.id),
            this.props.obj
          )}
          pageResponse={this.props.pageResponse}
          selectedInstanceId={this.props.obj.selectedInstanceId}
          matchOrder={this.props.matchOrder}
          expManager={this.props.expManager}
        />
      )
    } else return []
  }

  renderInfoWindowContent(place: any) {
    //let background2Template = this.props.obj.styles.bobs.background2
    //let tabletStyles = responsiveProperties(background2Template, 'tablet', {}, [], [])
    //let mobileStyles = responsiveProperties(background2Template, 'mobile', {}, [], [])
    return (
      <Fragment>
        <BobComponentHandler
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.obj.selectedInstanceId}
          object={this.props.obj}
          objectId={this.props.obj.uuid}
          field='icon1'
          template={this.props.obj.styles}
          rules={this.props.componentRule}
          expManager={this.props.expManager}
          iconClassName='fas fa-times'
          className={`infoWindow-close ${resultsMapStyle["infoWindow-close"]} is-clickable`}
          onClick={() => this.setState({ ...this.state, infoOpen: false })}
        />
        <div className={`cards card-top ${resultsMapStyle["card-top"]}`}>
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            objectId={this.props.obj.uuid}
            inlineContent={this.props.obj.content.inlineContent}
            overrides={this.props.overrides}
            field={"background2"}
            rules={this.props.componentRule}
            template={this.props.obj.styles}
            expManager={this.props.expManager}
            className='card'
            onClick={() => this.handleCardClick(0)}
          >
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              replacedMediaList={this.props.replacedMediaList}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='media1'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={place.network}
              post={place}
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='text1'
              tag='p'
              value={place.title}
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              className='title'
            />
          </BobComponentHandler>
        </div>
      </Fragment>
    )
  }

  renderMarks() {
    if (this.props.dataSet && this.props.dataSet.mapPosts) {
      return (
        <MarkerClusterer maxZoom={9}>
          {(clusterer) =>
            this.props.dataSet.mapPosts.map((location: any, idx: number) => (
              <Marker
                key={idx}
                position={location.latLong}
                clusterer={clusterer}
                onClick={(marker) => this.handleMarker(marker, { ...location, pos: location.latLong })}
              />
            ))
          }
        </MarkerClusterer>
      )
    }
    return []
  }

  renderMap(background1Template: any) {
    let counter = { desktop: 0, tablet: 0, mobile: 0 }
    const columns = handleColumns(
      0,
      counter,
      background1Template,
      this.props.pageResponse.globalStyles[background1Template.globalStyleId]
    )
    return (
      <div className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}>
        <LoadScript googleMapsApiKey='AIzaSyA7FRMfMTaf1r2IF-IC_urfGQYl--CZyas'>
          <GoogleMap
            mapContainerStyle={this.state.fullScreenMode ? containerFullscreenStyle : containerStyle}
            center={center}
            zoom={6}
            options={{ gestureHandling: "cooperative", fullscreenControl: false }}
          >
            <span
              className={`map-fullscreen-trigger ${resultsMapStyle["map-fullscreen-trigger"]}`}
              onClick={() => this.handleFullscreen()}
            >
              {this.state.fullScreenMode ? <i className='fas fa-compress'></i> : <i className='fas fa-expand'></i>}
            </span>
            {this.renderMarks()}
            {this.state.infoOpen && this.state.place && (
              <InfoWindow
                onCloseClick={() => this.setState({ ...this.state, infoOpen: false })}
                position={this.state.place.pos}
              >
                {this.renderInfoWindowContent(this.state.place)}
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    )
  }

  render() {
    const background1 = this.props.obj.styles.bobs.background1
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

    return (
      <div className={`results-map-component ${resultsMapStyle["results-map-component"]}`}>
        <AlignmentHOC
          className={`row ${resultsMapStyle.row} ${responsiveGuttersClass(
            background1,
            this.props.pageResponse.globalStyles[background1.globalStyleId]
          )}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {this.renderMap(background1)}
        </AlignmentHOC>
        {this.renderChildComponents()}
      </div>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    fetchingDataSet: state.publicPageReducer.server.fetchingDataSet,
    dataSet: state.publicPageReducer.server.dataSet,
    errorFetchingDataSet: state.publicPageReducer.server.errorFetchingDataSet,
    dataSetParams: state.publicPageReducer.server.dataSetParams
  }
}

export default connect(mapStateToProps)(ResultsMap)