import React, { useEffect, useState } from "react"
import { CookieBannerProps } from "./interface"
import styles from "../../../../stylesheets/modules/components/cookieBar.module.sass"

type cookieLanguage = {
  acceptString: string
  cookieMsg: string
  customizeString: string
  rejectString: string
  cookiePolicyString: string
  saveString: string
  closeString: string
}

type cookieOptions = {
  analytics: boolean
  cookieHead: boolean
  cookieBody1: boolean
  cookieBody2: boolean
}

const CookieBanner: React.FC<CookieBannerProps> = ({ cookieRules }) => {
  const [showBar, setShowBar] = useState<boolean>(false)
  const [showModal, setModal] = useState<boolean>(false)
  const [showPolicyModal, setPolicyModal] = useState<boolean>(false)
  const [cookieOptions, setCookieOptions] = useState<cookieOptions>({
    analytics: false,
    cookieHead: false,
    cookieBody1: false,
    cookieBody2: false,
  })
  const [cookieStrings, setCookieStrings] = useState<cookieLanguage>({
    acceptString: "Accept Cookies",
    cookieMsg:
      "This website uses cookies to provide you with the best browsing experience. By accepting our cookies you are deemed to have read and accepted our Cookies Policy.",
    customizeString: "Customize",
    rejectString: "Reject All",
    cookiePolicyString: "View Cookie Policy",
    saveString: "Save",
    closeString: "Close",
  })

  useEffect(() => {
    const cookieToCheck = `${"cookie-bar-" + window.location.hostname}`
    const cookieString = document.cookie
    if (cookieRules?.cookieBar?.enable && !cookieString.includes(cookieToCheck)) {
      setShowBar(true)
      const browserLanguage = navigator.language
      if (browserLanguage === "pt-PT") {
        setCookieStrings({
          acceptString: "Aceitar Cookies",
          cookieMsg:
            "Este website utiliza Cookies para oferecer a melhor experiência. Ao aceitar as cookies considera-se que leu e aceitou a nossa Política de Cookies.",
          customizeString: "Personalizar",
          rejectString: "Rejeitar Tudo",
          cookiePolicyString: "Ver Política de Cookies",
          saveString: "Guardar",
          closeString: "Fechar",
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setCookie = () => {
    let expires = new Date()
    expires.setFullYear(expires.getFullYear() + 1)
    document.cookie = `${"cookie-bar-" + window.location.hostname}=accepted; expires=${expires}`
    if (cookieOptions.analytics) {
      document.cookie = `${"cookie-analytics-" + window.location.hostname}=accepted; expires=${expires}`
    }
    if (cookieOptions.cookieHead) {
      document.cookie = `${"cookie-head-" + window.location.hostname}=accepted; expires=${expires}`
    }
    if (cookieOptions.cookieBody1) {
      document.cookie = `${"cookie-body1-" + window.location.hostname}=accepted; expires=${expires}`
    }
    if (cookieOptions.cookieBody2) {
      document.cookie = `${"cookie-body2-" + window.location.hostname}=accepted; expires=${expires}`
    }
    setShowBar(false)
    setModal(false)
  }

  const rejectCookie = () => {
    let expires = new Date()
    expires.setFullYear(expires.getFullYear() + 1)
    document.cookie = `${"cookie-bar-" + window.location.hostname}=accepted; expires=${expires}`
    setShowBar(false)
    setModal(false)
  }

  const acceptAllCookie = () => {
    let expires = new Date()
    expires.setFullYear(expires.getFullYear() + 1)
    document.cookie = `${"cookie-bar-" + window.location.hostname}=accepted; expires=${expires}`
    document.cookie = `${"cookie-analytics-" + window.location.hostname}=accepted; expires=${expires}`
    document.cookie = `${"cookie-head-" + window.location.hostname}=accepted; expires=${expires}`
    document.cookie = `${"cookie-body1-" + window.location.hostname}=accepted; expires=${expires}`
    document.cookie = `${"cookie-body2-" + window.location.hostname}=accepted; expires=${expires}`
    setShowBar(false)
    setModal(false)
  }

  const renderCookieOption = (
    id: keyof cookieOptions,
    cookieTitle: string | undefined,
    cookieDescription: string | undefined
  ) => {
    return (
      <div className={styles["cookie-option"]}>
        <div>
          <input
            value={cookieOptions["cookieHead"] === true ? "true" : "false"}
            onChange={(e) => setCookieOptions({ ...cookieOptions, [id]: e.target.checked })}
            id={id}
            className={styles["cookie-checkbox"]}
            type='checkbox'
          />
        </div>
        <div>
          {cookieTitle && (
            <label htmlFor={id} className={styles["cookie-title"]}>
              {cookieTitle}
            </label>
          )}
          {cookieDescription && <p className={styles["cookie-description"]}>{cookieDescription}</p>}
        </div>
      </div>
    )
  }

  if (showBar) {
    const hasRules =
      cookieRules?.cookieBar?.body1Consent ??
      cookieRules?.cookieBar?.body2Consent ??
      cookieRules?.cookieBar?.headConsent ??
      cookieRules?.cookieBar?.analyticsConsent
    return (
      <div className={styles["cookie-banner"]}>
        <div className='mb-4'>{cookieStrings.cookieMsg}</div>
        <div className={styles["cookie-actions"]}>
          {hasRules && (
            <>
              <button
                onClick={() => {
                  setShowBar(false)
                  setModal(true)
                }}
                className='btn btn-fluxio btn-outline'
              >
                {cookieStrings.customizeString}
              </button>
              <button onClick={rejectCookie} className='btn btn-fluxio btn-outline'>
                {cookieStrings.rejectString}
              </button>
            </>
          )}
          <button onClick={acceptAllCookie} className='btn btn-fluxio btn-primary'>
            {cookieStrings.acceptString}
          </button>
        </div>
      </div>
    )
  }

  const renderCookiePolicy = () => {
    if (cookieRules.cookieBar?.policy?.policyText) {
      //render Modal
      return (
        <button onClick={() => setPolicyModal(true)} className='btn btn-fluxio btn-outline'>
          {cookieStrings.cookiePolicyString}
        </button>
      )
    }
    if (cookieRules.cookieBar?.policy?.policyLink) {
      //render Link
      return (
        <a
          href={cookieRules.cookieBar.policy.policyLink}
          target='_blank'
          rel='noreferrer'
          className='btn btn-fluxio btn-outline'
        >
          {cookieStrings.cookiePolicyString}
        </a>
      )
    }
    return <></>
  }

  const renderPolicyMsg = () => {
    const policyMsg = cookieRules.cookieBar?.policy?.policyText
    if (policyMsg) {
      return (
        <div className={styles["cookie-policy-wrap"]}>
          {policyMsg}{" "}
          <button onClick={() => setPolicyModal(false)} className='btn btn-fluxio btn-outline mt-2 btn-block'>
            {cookieStrings.closeString}
          </button>
        </div>
      )
    }
  }

  if (showModal) {
    return (
      <div className={styles["cookie-banner-modal-drop"]}>
        <div className={styles["cookie-banner-modal"]}>
          {showPolicyModal && renderPolicyMsg()}
          <div className={styles["cookie-options"]}>
            <div className={styles["cookie-option"]}>
              <div>
                <input
                  value={cookieOptions.analytics === true ? "true" : "false"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCookieOptions({ ...cookieOptions, analytics: e.target.checked })
                  }
                  id='cookie-1'
                  className={styles["cookie-checkbox"]}
                  type='checkbox'
                />
              </div>
              <div>
                <label htmlFor='cookie-1' className={styles["cookie-title"]}>
                  Performance & Analytics
                </label>
                <p className={styles["cookie-description"]}>
                  Performance and analytics cookies collect anonymous data about website usage, which is used to improve
                  the website and provide a better user experience.
                </p>
              </div>
            </div>
            {cookieRules.cookieBar?.headConsent?.enable &&
              renderCookieOption(
                "cookieHead",
                cookieRules.cookieBar.headConsent.title,
                cookieRules.cookieBar.headConsent.description
              )}
            {cookieRules.cookieBar?.body1Consent?.enable &&
              renderCookieOption(
                "cookieBody1",
                cookieRules.cookieBar.body1Consent.title,
                cookieRules.cookieBar.body1Consent.description
              )}
            {cookieRules.cookieBar?.body2Consent?.enable &&
              renderCookieOption(
                "cookieBody2",
                cookieRules.cookieBar.body2Consent.title,
                cookieRules.cookieBar.body2Consent.description
              )}
          </div>
          <div className='d-flex justify-content-between'>
            {renderCookiePolicy()}
            <button onClick={setCookie} className='btn btn-fluxio btn-primary'>
              {cookieStrings.saveString}
            </button>
          </div>
        </div>
      </div>
    )
  }
  return <></>
}

export default CookieBanner
