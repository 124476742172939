import React, { Fragment } from "react"
//@ts-ignore
import Style from "style-it"
import { CustomCssHandlerProps } from "./interface"
import { writeCustomCssSelectors } from "../../../../modules/shared-modules/utilities/customCss"

const CustomCssHandler: React.FC<CustomCssHandlerProps> = (props): JSX.Element => {
  const handleCustomCss = (): string => {
    let customCssDesktop = writeCustomCssSelectors(props.obj.styles.bobs.ncode1, "desktop", false)
    let customCssTablet = writeCustomCssSelectors(props.obj.styles.bobs.ncode1, "tablet", false)
    let customCssMobile = writeCustomCssSelectors(props.obj.styles.bobs.ncode1, "mobile", false)
    let customCssDefault = writeCustomCssSelectors(props.obj.styles.bobs.ncode1, "default", false)

    return `${customCssDefault}${customCssDesktop}${customCssTablet}${customCssMobile}`
  }

  const css = (): string => {
    const componentTemplate = props.obj.styles
    if (componentTemplate.bobs && componentTemplate.bobs.ncode1) {
      const CustomCss = handleCustomCss()
      return `${CustomCss}`
    }

    return ""
  }

  const cssRules = css()
  if (cssRules.trim() !== "") {
    return (
      <Fragment>
        <Style>{`${cssRules}`}</Style>
        {props.children}
      </Fragment>
    )
  }

  return props.children
}

export default React.memo(CustomCssHandler)
