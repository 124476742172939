import {
  INVALID_HUB_ID,
  REQUEST_HUB_CONTENT,
  RECEIVE_HUB_CONTENT,
  INVALID_FORMULA_ID,
  REQUEST_FORMULA_CONTENT,
  RECEIVE_FORMULA_CONTENT,
  INVALID_EXPERIENCE,
  REQUEST_EXPERIENCE,
  RECEIVE_EXPERIENCE,
  CONTENT_FETCHED,
  FETCHING_CONTENT,
  CLEAN_HUB_CONTENT,
  ERROR_FETCH_OBJECT_TEMPLATE_BY_ID,
  REQUEST_OBJECT_TEMPLATE_BY_ID,
  SUCCESS_FETCH_OBJECT_TEMPLATE_BY_ID,
  ERROR_OBJECT_TEMPLATE_TO_CLONE,
  REQUEST_OBJECT_TEMPLATE_TO_CLONE,
  SUCCESS_OBJECT_TEMPLATE_TO_CLONE,
  CLEAR_OBJECT_TEMPLATE_TO_CLONE,
  ERROR_FETCH_EXPERIENCE_PREVIEW,
  REQUEST_FETCH_EXPERIENCE_PREVIEW,
  SUCCESS_FETCH_EXPERIENCE_PREVIEW,
  CLEAR_FETCH_EXPERIENCE_PREVIEW,
  ERROR_FETCH_EXPERIENCE_SYMBOL,
  REQUEST_FETCH_EXPERIENCE_SYMBOL,
  SUCCESS_FETCH_EXPERIENCE_SYMBOL,
  CLEAR_FETCH_EXPERIENCE_SYMBOL,
} from "./actionTypes"
import {
  expHub,
  formula,
  experience,
  demoExperience,
  restrictedExperience,
  getObjectTemplateById,
  experiencePreview,
  experienceRestrictedPreview,
  experienceSymbol,
} from "../../../api/contentApi"
import { AppDispatch } from "../types"

export function invalidHubId(hubId: string) {
  return {
    type: INVALID_HUB_ID,
    hubId,
  }
}

export function requestHubContent(hubId: string, local: any) {
  return {
    type: REQUEST_HUB_CONTENT,
    hubId,
    local,
  }
}

export function receiveHubContent(hubId: string, json: any) {
  return {
    type: RECEIVE_HUB_CONTENT,
    hubId,
    hubContent: json,
  }
}

export function fetchExpHubContent(hubId: string, uuid: any, userToken: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestHubContent(hubId, uuid))
    return expHub(hubId, uuid, userToken)
      .then((response: any) => {
        dispatch(receiveHubContent(hubId, response))
      })
      .catch((error: any) => {
        dispatch(invalidHubId(hubId))
        throw error
      })
  }
}

export function invalidFormulaId(formulaId: string) {
  return {
    type: INVALID_FORMULA_ID,
    formulaId,
  }
}

export function requestFormulaContent(formulaId: string, until: any) {
  return {
    type: REQUEST_FORMULA_CONTENT,
    formulaId,
    until,
  }
}

export function receiveFormulaContent(formulaId: string, json: any) {
  return {
    type: RECEIVE_FORMULA_CONTENT,
    formulaId,
    formulaContent: json,
  }
}

export function fetchFormulaContent(localId: string, formulaId: string, until: any, userToken: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFormulaContent(formulaId, until))
    return formula(localId, formulaId, until, userToken)
      .then((response: any) => {
        dispatch(receiveFormulaContent(formulaId, response))
      })
      .catch((error: any) => {
        dispatch(invalidFormulaId(formulaId))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function invalidExperience(error: any) {
  return {
    type: INVALID_EXPERIENCE,
    error,
  }
}

export function requestExperience() {
  return {
    type: REQUEST_EXPERIENCE,
  }
}

export function receiveExperience(response: any, webpSupport: string = "false") {
  return {
    type: RECEIVE_EXPERIENCE,
    response,
    webpSupport,
  }
}

export function fetchExperience(
  wall: any,
  slug: any,
  language = false,
  defaultRoute: any,
  pageName: any,
  webpSupport: string
) {
  return (dispatch: AppDispatch) => {
    return experience(wall, slug, language, defaultRoute, pageName)
      .then((response: any) => {
        dispatch(receiveExperience(response, webpSupport))
      })
      .catch((error: any) => {
        dispatch(invalidExperience(error))
      })
  }
}

export function fetchDemoExperience(demoId: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestExperience())
    return demoExperience(demoId)
      .then((response: any) => {
        dispatch(receiveExperience(response))
      })
      .catch((error: any) => {
        dispatch(invalidExperience(error))
      })
  }
}

export function fetchRestrictedExperience(pageObjectRootId: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestExperience())
    return restrictedExperience(pageObjectRootId, token)
      .then((response: any) => {
        dispatch(receiveExperience(response))
      })
      .catch((error: any) => {
        dispatch(invalidExperience(error))
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function contentFetched(objectUuid: string, response: any) {
  return {
    type: CONTENT_FETCHED,
    objectUuid: objectUuid,
    response,
  }
}

export function contentFetch(objectUuid: string, response: any) {
  return (dispatch: AppDispatch) => {
    dispatch(contentFetched(objectUuid, response))
  }
}

export function fetchingContent(objectUuid: string) {
  return {
    type: FETCHING_CONTENT,
    objectUuid: objectUuid,
  }
}

export function loadingContentFetch(objectUuid: string) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchingContent(objectUuid))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function cleanHubContent() {
  return {
    type: CLEAN_HUB_CONTENT,
  }
}

export function clearHubContent() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanHubContent())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchObjectTemplateById(error: any) {
  return {
    type: ERROR_FETCH_OBJECT_TEMPLATE_BY_ID,
    error,
  }
}

export function requestObjectTemplateById() {
  return {
    type: REQUEST_OBJECT_TEMPLATE_BY_ID,
  }
}

export function successFetchObjectTemplateById(response: any) {
  return {
    type: SUCCESS_FETCH_OBJECT_TEMPLATE_BY_ID,
    response,
  }
}

export function fetchObjectTemplateById(templateId: string, profile: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestObjectTemplateById())
    return getObjectTemplateById(templateId, profile, token)
      .then((response: any) => {
        dispatch(successFetchObjectTemplateById(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchObjectTemplateById(error))
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorObjectTemplateToClone(error: any) {
  return {
    type: ERROR_OBJECT_TEMPLATE_TO_CLONE,
    error,
  }
}

export function requestObjectTemplateToClone() {
  return {
    type: REQUEST_OBJECT_TEMPLATE_TO_CLONE,
  }
}

export function successObjectTemplateToClone(response: any, groupId: any) {
  return {
    type: SUCCESS_OBJECT_TEMPLATE_TO_CLONE,
    response,
    groupId,
  }
}

export function objectTemplateToClone(profile: any, templateUuid: string, groupId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestObjectTemplateToClone())
    return getObjectTemplateById(templateUuid, profile, token)
      .then((response: any) => {
        dispatch(successObjectTemplateToClone(response, groupId))
      })
      .catch((error: any) => {
        dispatch(errorObjectTemplateToClone(error))
        throw error
      })
  }
}

export function clearObjectTemplateToClone() {
  return {
    type: CLEAR_OBJECT_TEMPLATE_TO_CLONE,
  }
}

export function cleanObjectTemplateToClone() {
  return (dispatch: AppDispatch) => {
    dispatch(clearObjectTemplateToClone())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchExperiencePreview(error: any) {
  return {
    type: ERROR_FETCH_EXPERIENCE_PREVIEW,
    error,
  }
}

export function requestFetchExperiencePreview() {
  return {
    type: REQUEST_FETCH_EXPERIENCE_PREVIEW,
  }
}

export function successFetchExperiencePreview(response: any) {
  return {
    type: SUCCESS_FETCH_EXPERIENCE_PREVIEW,
    response,
  }
}

export function fetchExperiencePreview(
  hostUuid: string,
  pageUri: string,
  token: string,
  pageObjectRootId: string | undefined
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchExperiencePreview())
    return experiencePreview(hostUuid, pageUri, token, pageObjectRootId)
      .then((response: any) => {
        dispatch(successFetchExperiencePreview(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchExperiencePreview(error))
        throw error
      })
  }
}

export function fetchExperienceRestrictedPreview(pageObjectRootId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchExperiencePreview())
    return experienceRestrictedPreview(pageObjectRootId, token)
      .then((response: any) => {
        dispatch(successFetchExperiencePreview(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchExperiencePreview(error))
        throw error
      })
  }
}

export function clearFetchExperiencePreview() {
  return {
    type: CLEAR_FETCH_EXPERIENCE_PREVIEW,
  }
}

export function cleanFetchExperiencePreview() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchExperiencePreview())
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchExperienceSymbol(error: any) {
  return {
    type: ERROR_FETCH_EXPERIENCE_SYMBOL,
    error,
  }
}

export function requestFetchExperienceSymbol() {
  return {
    type: REQUEST_FETCH_EXPERIENCE_SYMBOL,
  }
}

export function successFetchExperienceSymbol(response: any) {
  return {
    type: SUCCESS_FETCH_EXPERIENCE_SYMBOL,
    response,
  }
}

export function fetchExperienceSymbol(symbolId: string, profile: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchExperienceSymbol())
    return experienceSymbol(symbolId, profile, token)
      .then((response: any) => {
        dispatch(successFetchExperienceSymbol(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchExperienceSymbol(error))
        throw error
      })
  }
}

export function clearFetchExperienceSymbol() {
  return {
    type: CLEAR_FETCH_EXPERIENCE_SYMBOL,
  }
}

export function cleanFetchExperienceSymbol() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchExperienceSymbol())
  }
}
