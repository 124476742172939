import { User, Workspace } from "../../areas/skynet/profileTypes"

class Permissions {
  static checkWorspacePermission(
    user: User | undefined,
    workspace: Workspace | undefined,
    featureToValidate: keyof Workspace["permissions"]["betaFeatures"]
  ) {
    if (!user || !workspace) return false
    /**
     * check if user is admin
     * overlapps any other permission
     */
    if (this.checkUserPermission(user)) return true
    /**
     * check worskpace permissions
     */
    if (workspace?.permissions) {
      /**
       * check betaFeatures permissions
       */
      if (workspace.permissions.betaFeatures) {
        if (workspace.permissions.betaFeatures[featureToValidate] === false) return false
        return true
      }
    }
    /**
     * fallback
     */
    return true
  }

  static checkWorspacePermissionPageBuilder(
    user: User | undefined,
    workspace: Workspace | undefined,
    featureToValidate: keyof Workspace["permissions"]["betaFeaturesPageBuilder"]
  ) {
    if (!user || !workspace) return false
    /**
     * check if user is admin
     * overlapps any other permission
     */
    if (this.checkUserPermission(user)) return true
    /**
     * check worskpace permissions
     */
    if (workspace?.permissions) {
      /**
       * check betaFeatures permissions
       */
      if (workspace.permissions.betaFeaturesPageBuilder) {
        if (workspace.permissions.betaFeaturesPageBuilder[featureToValidate] === false) return false
        return true
      }
    }
    /**
     * fallback
     */
    return true
  }

  static checkWorspacePermissionPlanLimit(
    user: User | undefined,
    workspace: Workspace | undefined,
    featureToValidate: keyof Workspace["permissions"]["planLimits"],
    quantity: number | undefined
  ) {
    if (!quantity) return false
    /**
     * check if user is admin
     * overlapps any other permission
     */
    if (this.checkUserPermission(user)) return true
    /**
     * check worskpace permissions
     */
    if (workspace?.permissions) {
      /**
       * check planLimits permissions
       */
      if (workspace.permissions.planLimits) {
        // give permission if flag is not present
        if (!(featureToValidate in workspace.permissions.planLimits)) return true
        // block permission
        if (quantity && workspace.permissions.planLimits[featureToValidate] <= quantity) return false
        // fallback
        return true
      }
    }
    /**
     * fallback
     */
    return true
  }

  /**
   * check user permissions
   */
  static checkUserPermission(user: User | undefined) {
    /**
     * check if user is admin
     */
    if (user) return user.isAdmin
    /**
     * fallback
     */
    return true
  }
}

export default Permissions
