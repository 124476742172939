import React from "react"
// @ts-ignore
import ReactCompareImage from "react-compare-image"
import { ComparationSliderProps } from "."
import {
  isBobMedia,
  isBobText,
  isBobIcon,
} from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { getContent } from "../../../../../../modules/shared-modules/utilities/components"

const ComparationSlider: React.FC<ComparationSliderProps> = (props) => {
  const handleRemoveHTMLTags = (text: string) => {
    let div = document.createElement("div")
    div.innerHTML = text
    return div.textContent || div.innerText || undefined
  }

  const handleGetAssets = () => {
    let content: any = getContent(props.obj, "posts", props.overrides)
    let styles = props.obj.styles
    let media1 = isBobMedia(styles.bobs.media1)
    let media2 = isBobMedia(styles.bobs.media2)
    let text1 = isBobText(styles.bobs.text1)
    let text2 = isBobText(styles.bobs.text2)
    let icon1 = isBobIcon(styles.bobs.icon1)

    //Media handle for source type
    if (media1.contentSrc !== "custom") media1 = content[0].picture_orig
    if (media2.contentSrc !== "custom") media2 = content[1].picture_orig

    //Text handle for source type
    if (text1.contentSrc !== "custom") text1 = content[0].title
    if (text2.contentSrc !== "custom") text2 = content[1].title

    return {
      media1: media1.enable ? media1.src : "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png",
      media2: media2.enable ? media2.src : "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png",
      text1: text1.enable ? handleRemoveHTMLTags(text1.customText) : undefined,
      text2: text2.enable ? handleRemoveHTMLTags(text2.customText) : undefined,
      icon1: icon1.enable ? icon1.color : "#fff",
    }
  }

  let { media1, media2, text1, text2, icon1 } = handleGetAssets()

  return (
    <div className='comparation-slider'>
      <ReactCompareImage
        leftImageLabel={text1}
        leftImage={media1 !== "" ? media1 : "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png"}
        rightImage={media2 !== "" ? media2 : "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png"}
        rightImageLabel={text2}
        sliderLineColor={icon1}
      />
    </div>
  )
}

export default ComparationSlider
