import styled, { css } from 'styled-components'
import { Breakpoint } from '../../../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes'
import { BobIcon } from '../../../../../../../modules/shared-modules/experienceManager/types/stylesTypes'

interface DotsProps {
  active: boolean
  distance: number
  template: BobIcon
}

const handleSize = (breakpoint: Breakpoint, props: DotsProps) => {
  const size = breakpoint === 'desktop' ? props.template.size : (props.template[breakpoint]?.size ?? props.template.size)
  return [size, size * 0.66, size * 0.33]
}

const handleMargin = (breakpoint: Breakpoint, props: DotsProps) => {
  const marginTop = breakpoint === 'desktop' ? props.template.spacing.margin.top : (props.template[breakpoint]?.spacing?.margin?.top ?? props.template.spacing.margin.top)
  const marginBottom = breakpoint === 'desktop' ? props.template.spacing.margin.bottom : (props.template[breakpoint]?.spacing?.margin?.bottom ?? props.template.spacing.margin.bottom)
  const marginLeft = breakpoint === 'desktop' ? props.template.spacing.margin.left : (props.template[breakpoint]?.spacing?.margin?.left ?? props.template.spacing.margin.left)
  const marginRight = breakpoint === 'desktop' ? props.template.spacing.margin.right : (props.template[breakpoint]?.spacing?.margin?.right ?? props.template.spacing.margin.right)

  return {
    marginTop: marginTop,
    marginBottom: marginBottom,
    marginLeft: [marginLeft, marginLeft + (marginLeft * 0.66), marginLeft + (marginLeft * 0.33)],
    marginRight: [marginRight, marginRight + (marginRight * 0.66), marginRight + (marginRight * 0.33)]
  }
}

const handlePadding = (breakpoint: Breakpoint, props: DotsProps) => {
  const paddingTop = breakpoint === 'desktop' ? props.template.spacing.padding.top : (props.template[breakpoint]?.spacing?.padding?.top ?? props.template.spacing.padding.top)
  const paddingBottom = breakpoint === 'desktop' ? props.template.spacing.padding.bottom : (props.template[breakpoint]?.spacing?.padding?.bottom ?? props.template.spacing.padding.bottom)
  const paddingLeft = breakpoint === 'desktop' ? props.template.spacing.padding.left : (props.template[breakpoint]?.spacing?.padding?.left ?? props.template.spacing.padding.left)
  const paddingRight = breakpoint === 'desktop' ? props.template.spacing.padding.right : (props.template[breakpoint]?.spacing?.padding?.right ?? props.template.spacing.padding.right)

  return {
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
    paddingLeft: [paddingLeft, paddingLeft + (paddingLeft * 0.66), paddingLeft + (paddingLeft * 0.33)],
    paddingRight: [paddingRight, paddingRight + (paddingRight * 0.66), paddingRight + (paddingRight * 0.33)]
  }
}

const getSize = (breakpoint: Breakpoint) => (props: DotsProps) => {
  const size = handleSize(breakpoint, props)[props.distance] || 0
  const marginTop = size !== 0 ? handleMargin(breakpoint, props).marginTop : 0
  const marginBottom = size !== 0 ? handleMargin(breakpoint, props).marginBottom : 0
  const marginLeft = size !== 0 ? handleMargin(breakpoint, props).marginLeft[props.distance] : 0
  const marginRight = size !== 0 ? handleMargin(breakpoint, props).marginRight[props.distance] : 0
  const paddingTop = size !== 0 ? handlePadding(breakpoint, props).paddingTop : 0
  const paddingBottom = size !== 0 ? handlePadding(breakpoint, props).paddingBottom : 0
  const paddingLeft = size !== 0 ? handlePadding(breakpoint, props).paddingLeft[props.distance] : 0
  const paddingRight = size !== 0 ? handlePadding(breakpoint, props).paddingRight[props.distance] : 0

  return css`
    font-size: ${size}px;
    margin-top: ${marginTop}px;
    margin-bottom: ${marginBottom}px;
    margin-left: ${marginLeft}px;
    margin-right: ${marginRight}px;
    padding-top: ${paddingTop}px;
    padding-bottom: ${paddingBottom}px;
    padding-left: ${paddingLeft}px;
    padding-right: ${paddingRight}px;
  `
}

const dimColor = () => (props: DotsProps) => {
  if (props.active) {
    return ''
  }
  return 'filter: opacity(35%);'
}

export const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Dot = styled.div`
  ${getSize('desktop')}
  ${dimColor()}
  color: ${props => (props.template.color)};
  transition: font-size 300ms ease-in-out, margin 300ms ease-in-out;

  @media only screen and (max-width: 992px) and (min-width: 767px){
    ${getSize('tablet')}
    ${dimColor()}
    color: ${props => (props.template.tablet?.color ?? props.template.color)};
  }
  @media all and (max-width: 766px){
    ${getSize('mobile')}
    ${dimColor()}
    color: ${props => (props.template.mobile?.color ?? props.template.color)};
  }
`
