import React, { useEffect } from "react"
import Image from "next/image"
import InjectMetaData from "../areas/public-page/Metadata/InjectMetaData"
import { createGlobalStyle } from "styled-components"
import { handleSafariOldVersion } from "../modules/shared-modules/utilities/browserHacks"
import { cloneDeep } from "lodash"
import { fetchExperience } from "../store/shared-store/actions/contentActions"
import { wrapper } from "../store/configureStore"
import { ExperienceProps } from "../pagesInterfaces/pageName"
import { GetServerSideProps } from "next"

import CookieBanner from "../areas/public-page/shared-public-page/cookieBanner"
import PageBootstrap from "../areas/public-page/shared-public-page/pageBootstrap/pageBootstrap"

import { parse, UserAgent } from "next-useragent"

const localDevDomain: string = "example.com";

import { NextPage } from "next"
import { routeArrayByNumber } from "../modules/routeCreator"
import {
  fetchPublicContentPagePost,
  successFetchPublicContentPagePost,
} from "../store/shared-store/actions/contentLibraryActions"
import { useDispatch, useSelector } from "react-redux"
import InjectBody1Analytics from "../areas/public-page/analytics/injectBody1Analytics"
import InjectBody2Analytics from "../areas/public-page/analytics/injectBody2Analytics"
interface ExtendedExperienceProps extends ExperienceProps {
  uaString: any
}

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(
  (store) =>
    async ({ req, res, query, params }: any): Promise<any> => {
      res.setHeader("Cache-Control", "public, s-maxage=60, stale-while-revalidate=120")
      const useLocalDevDomain: string | undefined =
        process.env?.is_local === "true" ? localDevDomain : undefined;

      const domain: string = useLocalDevDomain ?? req.headers.host;
      const concanetedUrl = `${domain}/${routeArrayByNumber(params.pageName.length, params.pageName, false)}`
      const localId = null
      const xWebPSupportHeader = req.headers["x-webp-support"]

      await store.dispatch(fetchExperience(query.localId, false, false, localId, concanetedUrl, xWebPSupportHeader))
      const { invalidExperience } = store.getState().contentHubReducer.server

      if (invalidExperience) {
        return {
          notFound: true,
        }
      }

      return {
        props: {
          uaString: req.headers["user-agent"],
          invalidExperience: store.getState().contentHubReducer.server.invalidExperience || null,
          loadingExperience: store.getState().contentHubReducer.server.loadingExperience || null,
          experience: store.getState().contentHubReducer.server.experience || null,
          query: query,

          loadingPreviewContentPagePost: store.getState().contentLibraryReducer.loadingPreviewContentPagePost || null,
          errorFetchPreviewContentPagePost:
            store.getState().contentLibraryReducer.errorFetchPreviewContentPagePost || null,
          successFetchPreviewContentPagePost:
            store.getState().contentLibraryReducer.successFetchPreviewContentPagePost || null,

          loadingPublicContentPagePost: store.getState().contentLibraryReducer.loadingPublicContentPagePost || null,
          errorFetchPublicContentPagePost:
            store.getState().contentLibraryReducer.errorFetchPublicContentPagePost || null,
          successFetchPublicContentPagePost:
            store.getState().contentLibraryReducer.successFetchPublicContentPagePost || null,
        },
      }
    }
)

const GlobalFontStyle = createGlobalStyle`
  ${(props: any) => props.customFontsCSS}
`

const Experience: NextPage<ExtendedExperienceProps> = (props): JSX.Element => {
  const dispatch = useDispatch()
  const reduxState = useSelector(successFetchPublicContentPagePost)

  useEffect(() => {
    if (props.experience.pageData.contentPage && !props.experience.pageData.contentPage.content) {
      const postId = props.experience.postId
      const url = window.location.href
      dispatch(fetchPublicContentPagePost(postId, url))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let ua
  if (props.uaString) {
    ua = parse(props.uaString)
  } else {
    ua = parse(window.navigator.userAgent)
  }

  const isSafariOld = (ua: UserAgent): string => {
    if (ua.isDesktop && ua.browser === "Safari") {
      return ua.browserVersion < 14 ? "true" : "false"
    }

    if (ua.isIos && ua.engineVersion < 604.1) {
      return "true"
    }
    //return ua.browserVersion < 11 ? "true" : "false"
    return "false"
  }

  /**
   * This handler controls the fetch action.
   */

  const handleExperience = () => {
    if (props.loadingExperience) {
      return (
        <div className='pai-nosso'>
          <div className='hub-article-loading'>
            <div className='loading'>
              <Image src={window.loader ? window.loader : "https://cdn.fluxio.cloud/meshapp/balls.svg"} alt='Loading' />
            </div>
          </div>
        </div>
      )
    }
    if (props.experience) {
      const hub = props.experience.pageData
      const contentPagePost = reduxState.response.contentLibraryReducer.successFetchPublicContentPagePost
      if (contentPagePost) {
        let clonedHub = cloneDeep(hub)
        clonedHub.contentPage.content = contentPagePost
        return (
          <div className='meshCenter meshConfig' id={hub.uri}>
            <GlobalFontStyle customFontsCSS={props.experience.pageData.customFontsCss} />
            {hub.template && hub.template.cookieWarning && <CookieBanner cookieRules={props.experience?.site} />}
            <PageBootstrap pageResponse={clonedHub} expManager={{ enable: false, emEditorType: undefined }} />
          </div>
        )
      }
      return (
        <div className='meshCenter meshConfig' id={hub.uri}>
          <GlobalFontStyle customFontsCSS={props.experience.pageData.customFontsCss} />
          {hub.template && hub.template.cookieWarning && <CookieBanner cookieRules={props.experience?.site} />}
          <PageBootstrap pageResponse={hub} expManager={{ enable: false, emEditorType: undefined }} />
        </div>
      )
    }
  }

  return (
    <>
      <InjectMetaData site={props.experience?.site} fonts={props.experience?.fonts.imports} />
      {props.experience && <InjectBody1Analytics site={props.experience.site} />}
      <div className={`pai-nosso ${handleSafariOldVersion(isSafariOld(ua))}`}>{handleExperience()}</div>
      {props.experience && <InjectBody2Analytics site={props.experience.site} />}
    </>
  )
}

export default Experience
