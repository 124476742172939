import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { get2WithNull } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const UnitCodec = union([literal("px"), literal("%"), literal("vw"), literal("vh"), literal("auto")])
const HeightPropsCodec = type({ value: numberC, unit: UnitCodec })
const HeightCodec = intersection([type({ enable: booleanC }), HeightPropsCodec])

const HeightPropsOptCodec = partial({
  unit: nullable(UnitCodec),
  value: nullable(numberC),
})
const HeightOptCodec = intersection([partial({ enable: nullable(booleanC) }), HeightPropsOptCodec])

const StylesHeightCodec = intersection([
  type({ height: HeightCodec }),
  partial({
    behaviour: partial({
      active: partial({ height: HeightPropsOptCodec }),
      hover: partial({ height: HeightPropsOptCodec }),
    }),
    mobile: intersection([
      partial({ height: HeightPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ height: HeightPropsOptCodec }),
          hover: partial({ height: HeightPropsOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ height: HeightPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ height: HeightPropsOptCodec }),
          hover: partial({ height: HeightPropsOptCodec }),
        }),
      }),
    ]),
  }),
])

type Height = TypeOf<typeof HeightCodec>
type HeightProps = TypeOf<typeof HeightPropsCodec>
type HeightOpt = TypeOf<typeof HeightOptCodec>
type StylesHeight = TypeOf<typeof StylesHeightCodec>
type HeightCSS = {
  height: string
}

export function responsiveStyle(heightObj: any) {
  if (!objValueExists(heightObj, "enable")) return ""
  if (!heightObj.enable) return { height: "auto" }

  return { height: heightObj.value + heightObj.unit }
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<HeightCSS> {
  const styles = StylesHeightCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return {
    height: `auto`,
  }
}

export function cssRender(
  stylesObj: StylesHeight,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<HeightCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.height)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj.height, mergeBob2(stylesObj?.behaviour?.[behaviourState]?.height, stylesObj.height))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.height, mergeBob2(stylesObj?.[breakpoint]?.height, stylesObj.height))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.height,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.height,
          stylesObj?.behaviour?.[behaviourState]?.height,
          stylesObj?.[breakpoint]?.height,
          stylesObj.height
        )
      )
    }
  }
}

export function renderBob(heightObj: Height): Partial<HeightCSS> {
  if (!heightObj.enable) return {}

  return render(heightObj)
}

export function render(heightObj: HeightProps): HeightCSS {
  const heightValue = heightObj.value
  const heightUnit = heightObj.unit
  return {
    height: `${heightValue}${heightUnit}`,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param heightObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(defaultHeightObj: Height, heightObj: HeightOpt | undefined): Partial<HeightCSS> {
  if (heightObj?.enable === false) {
    if (defaultHeightObj.enable) return { height: "auto" }

    return {}
  }

  if (heightObj?.enable) return renderOpt(heightObj)

  return {}
}

/**
 * Renders HeightOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param heightObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(heightObj: HeightOpt): Partial<HeightCSS> {
  let css = {}
  if (objValueExists(heightObj, "unit") || objValueExists(heightObj, "value")) {
    css = { height: `${heightObj.value}${heightObj.unit}` }
  }

  return css
}

export function mergeBob2(heightObj: HeightOpt | undefined, defaultHeightObj: Height): HeightOpt {
  // no filter object
  if(!heightObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = heightObj?.enable ?? defaultHeightObj.enable
  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(heightObj, 'value') ||
    objValueExists(heightObj, 'unit')
  ) {
    const value = get2WithNull(heightObj?.value, defaultHeightObj.value)
    const unit = get2WithNull(heightObj?.unit, defaultHeightObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export function mergeBob3(
  heightObj: HeightOpt | undefined,
  heightDesktopBehaviour: HeightOpt | undefined,
  heightDefaultBreakpoint: HeightOpt | undefined,
  defaultHeightObj: Height
): HeightOpt {
  // no filter object
  if(!heightObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = 
    heightObj?.enable ?? heightDesktopBehaviour?.enable ?? heightDefaultBreakpoint?.enable ?? defaultHeightObj.enable

  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(heightObj, 'value') ||
    objValueExists(heightObj, 'unit')
  ) {
    const value = get2WithNull(heightObj?.value, defaultHeightObj.value)
    const unit = get2WithNull(heightObj?.unit, defaultHeightObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

  export type { HeightCSS, StylesHeight, HeightProps, Height, HeightOpt }