import {
  AlignmentCSS,
  ComponentSpecificAlignment,
  AlignmentCssRenderUnsafe,
  AlignmentCssClassRenderUnsafe,
} from "../../bob/bobStyles/alignmentStyle"


export function defaultAlign(style: any): {
  alignmentStyles: {} | AlignmentCSS
  tabletAlignment: {} | AlignmentCSS
  mobileAlignment: {} | AlignmentCSS
} {
  //These are the defaults, when the alignment doesn't need special handlement
  const AlignmentDefaultProperties = { flexDirection: "row", self: false } as ComponentSpecificAlignment
  const alignmentStyles = AlignmentCssRenderUnsafe(style, "desktop", "default", AlignmentDefaultProperties)
  const tabletAlignment = AlignmentCssRenderUnsafe(style, "tablet", "default", AlignmentDefaultProperties)
  const mobileAlignment = AlignmentCssRenderUnsafe(style, "mobile", "default", AlignmentDefaultProperties)
  return { alignmentStyles, tabletAlignment, mobileAlignment }
}
export function defaultClassAlign(style: any): string {
  //These are the defaults, when the alignment doesn't need special handlement
  const AlignmentDefaultProperties = { flexDirection: "row", self: false } as ComponentSpecificAlignment
  const alignmentStyles = AlignmentCssClassRenderUnsafe(style, "desktop", "default", AlignmentDefaultProperties)
  const tabletAlignment = AlignmentCssClassRenderUnsafe(style, "tablet", "default", AlignmentDefaultProperties)
  const mobileAlignment = AlignmentCssClassRenderUnsafe(style, "mobile", "default", AlignmentDefaultProperties)
  return `${alignmentStyles} ${tabletAlignment} ${mobileAlignment}`
}
export function specificAlign(
  style: any,
  AlignmentDefaultProperties: ComponentSpecificAlignment
): { alignmentStyles: {} | AlignmentCSS; tabletAlignment: {} | AlignmentCSS; mobileAlignment: {} | AlignmentCSS } {
  //These are the defaults, when the alignment doesn't need special handlement
  const alignmentStyles = AlignmentCssRenderUnsafe(style, "desktop", "default", AlignmentDefaultProperties)
  const tabletAlignment = AlignmentCssRenderUnsafe(style, "tablet", "default", AlignmentDefaultProperties)
  const mobileAlignment = AlignmentCssRenderUnsafe(style, "mobile", "default", AlignmentDefaultProperties)
  return { alignmentStyles, tabletAlignment, mobileAlignment }
}
