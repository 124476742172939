export function handleSafariOldVersion(isOldVersion: string) {
  if (isOldVersion === "true") {
    return "safari-old-version"
  }
  return ""
}

/**
 * fixes images width/height: auto,
 * safari makes images overflowing they'r container.
 * forcing them to 100% seems to fix the problem
 */
export function safariImageDimensions(objTemplate: any, classPrefix: string) {
  if (!objTemplate.width.enable && !objTemplate.height.enable) {
    /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
    */
    return `
      @media not all and (min-resolution:.001dpcm){ 
        @supports (-webkit-appearance:none) {
          .${classPrefix}-bob-media .bob-image img,
          .${classPrefix}-bob-media .bob-image{          
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    `
  }
  return ""
}
