import React from 'react'
import { InvalidComponentProps } from '.'

const Invalid: React.FC<InvalidComponentProps> = (props): JSX.Element => {
  return (
    <div className='alert alert-danger'>
      Missing component render for <strong>{props.componentRule.name}</strong>
    </div>
  )
}

export default Invalid