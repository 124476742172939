import React, { Fragment } from "react"
import * as BobDefaultNode from "../../../../../modules/shared-modules/constants/bobDefaultNode"
import {
  handleVerticalAlign,
  clearFont,
  dynamicFontLoader,
  getOverrideInlineContentValues,
  datasetRender,
} from "../../../../../modules/shared-modules/utilities/components"
// @ts-ignore
import Style from "style-it"
import { validHtml } from "../../../../../modules/shared-modules/utilities/validation"
import { responsiveProperties, responsiveGroupStylesProperties } from "../bobHandler"
import GlobalStylesUtils from "../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { ExpManager, PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { textShadowCss as ShadowStyleTextShadowCss } from "../bobStyles/shadowStyle"
import { BehaviourState_ } from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { renderCSSString as renderCSSStringBoundary } from "../bobStyles/boundaryStyle"
import {
  conditionalBoundaryRenderUnsafe as cssRenderUnsafeBoundary,
  conditionalShadowRenderUnsafe,
} from "../../../../../modules/shared-modules/utilities/conditionalController"
import {
  CONTENT_PAGE,
  CMS,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/textController/textContentHelper"
import {
  ObjectContent,
  ObjectContentOverride,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { analyticsLinkLabel, getLinkAttributes } from "../bobUtils"
import { GoogleEvent } from "../../analytics/analytics"
import BobTextSplit from "../../../../../split/shared-public-page/bobTextSplit"
import {
  cssRenderUnsafe as cssRenderUnsafeColor,
  renderCSSString as renderCSSStringColor,
} from "../bobStyles/colorStyle"
import * as inlineContentTypes from "../../../../../modules/shared-modules/experienceManager/finder/content/inlineContent/inlineContentTypes"

interface BobTextProps {
  id: string
  className?: string
  instanceId: string | undefined
  post: any
  rule: any
  field: string
  inlineContent: ObjectContent["inlineContent"]
  overrides?: ObjectContentOverride
  value: any
  template: any
  globalStyles: any
  network?: string
  pageResponse: PageTypes
  expManager: ExpManager
}

class BobText extends React.Component<BobTextProps> {
  // apply a function to a value if defined, producing a list with a single element. empty list if undefined
  show(x: undefined | any, f: (v: any) => string): Array<string> {
    if (typeof x === "undefined") return []
    else return [f(x)]
  }

  clearFont(x: string | undefined | null): string | undefined {
    if (typeof x === "undefined" || x === null) return undefined
    else {
      let noPlus = x.replace("+", " ")
      return noPlus.split(":")[0] // why split?
    }
  }

  /**
   * Component Props
   * @param value to show on the text
   * @param template containing the componet rules and template
   */
  handleStateStyles(
    defaultTemplate: any,
    templateObj: any,
    templateObjMobile: any,
    templateObjTablet: any,
    className: string,
    behaviourState: BehaviourState_
  ) {
    if (templateObj) {
      let templateMobile: any = responsiveProperties(
        { mobile: templateObjMobile },
        "mobile",
        { shadow: { shadowType: "text" } },
        ["color"],
        ["behaviour"]
      )
      templateMobile = {
        ...templateMobile,
        ...conditionalShadowRenderUnsafe(defaultTemplate, "mobile", behaviourState, "text", undefined),
        ...cssRenderUnsafeBoundary(defaultTemplate, "mobile", behaviourState),
        ...cssRenderUnsafeColor(defaultTemplate, "mobile", behaviourState, undefined, undefined, true),
        ...responsiveGroupStylesProperties({ mobile: templateObjMobile }, "mobile", "text"),
      }
      let templateTablet: any = responsiveProperties(
        { tablet: templateObjTablet },
        "tablet",
        { shadow: { shadowType: "text" } },
        ["color"],
        ["behaviour"]
      )
      templateTablet = {
        ...templateTablet,
        ...conditionalShadowRenderUnsafe(defaultTemplate, "tablet", behaviourState, "text", undefined),
        ...cssRenderUnsafeBoundary(defaultTemplate, "tablet", behaviourState),
        ...cssRenderUnsafeColor(defaultTemplate, "tablet", behaviourState, undefined, undefined, true),
        ...responsiveGroupStylesProperties({ tablet: templateObjTablet }, "tablet", "text"),
      }

      const mediaQueryRestrictions = this.handleMediaQueryRestrictions(templateObjMobile, templateObjTablet)
      const boundaries: any = cssRenderUnsafeBoundary(defaultTemplate, "desktop", behaviourState)
      const shadows = conditionalShadowRenderUnsafe(defaultTemplate, "desktop", behaviourState, "text", undefined)
      const color: any = cssRenderUnsafeColor(defaultTemplate, "desktop", behaviourState, undefined, undefined, true)

      return `
      ${
        templateObj.enable
          ? `@media all ${mediaQueryRestrictions}{
            ${className}{
              ${renderCSSStringColor(color)}
              ${renderCSSStringBoundary(boundaries)}
              ${ShadowStyleTextShadowCss(shadows)}
              ${[
                this.show(this.clearFont(templateObj["fontFamily"]), (x) => `font-family: "${x}" !important`),
                this.show(templateObj["fontWeight"], (x) => `font-weight: ${x} !important`),
                this.show(templateObj["fontSize"], (x) => `font-size: ${x}px !important`),
                this.show(templateObj["letterSpacing"], (x) => `letter-spacing: ${x}px !important`),
                this.show(templateObj["textAlign"], (x) => `text-align: ${x} !important`),
                this.show(templateObj["verticalAlign"], (x) => `vertical-align: ${x} !important`),
                this.show(templateObj["lineHeight"], (x) => `line-height: ${x} !important`),
                this.show(templateObj["textTransform"], (x) => `text-transform: ${x} !important`),
              ]
                .flat()
                .join(";")}
            }
          }`
          : ""
      }
      ${
        (templateObj.enable || templateObjTablet?.enable) && templateObjTablet?.enable !== false
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${className}{
              ${renderCSSStringColor(templateTablet)}
              ${renderCSSStringBoundary(templateTablet)}
              ${ShadowStyleTextShadowCss(templateTablet)}
              ${[
                this.show(this.clearFont(templateTablet["font-family"]), (x) => `font-family: "${x}"`),
                this.show(templateTablet["font-weight"], (x) => `font-weight: ${x}`),
                this.show(templateTablet["font-size"], (x) => `font-size: ${x}`),
                this.show(templateTablet["letter-spacing"], (x) => `letter-spacing: ${x}`),
                this.show(templateTablet["text-align"], (x) => `text-align: ${x}`),
                this.show(templateTablet["vertical-align"], (x) => `vertical-align: ${x}`),
                this.show(templateTablet["line-height"], (x) => `line-height: ${x}`),
                this.show(templateTablet["text-transform"], (x) => `text-transform: ${x}`),
              ]
                .flat()
                .join(";")}
            }
          }`
          : ""
      }
      ${
        (templateObj.enable || templateObjMobile?.enable) && templateObjMobile?.enable !== false
          ? `@media all and (max-width: 766px){
            ${className}{
              ${renderCSSStringColor(templateMobile)}
              ${renderCSSStringBoundary(templateMobile)} 
              ${ShadowStyleTextShadowCss(templateMobile)}
              ${[
                this.show(this.clearFont(templateMobile["font-family"]), (x) => `font-family: "${x}"`),
                this.show(templateMobile["font-weight"], (x) => `font-weight: ${x}`),
                this.show(templateMobile["font-size"], (x) => `font-size: ${x}`),
                this.show(templateMobile["letter-spacing"], (x) => `letter-spacing: ${x}`),
                this.show(templateMobile["text-align"], (x) => `text-align: ${x}`),
                this.show(templateMobile["vertical-align"], (x) => `vertical-align: ${x}`),
                this.show(templateMobile["line-height"], (x) => `line-height: ${x}`),
                this.show(templateMobile["text-transform"], (x) => `text-transform: ${x}`),
              ]
                .flat()
                .join(";")}
            }
          }`
          : ""
      }
      `
    }
    return ""
  }

  /**
   * returns media queries to apply styles based on enable prop value on the diferent breakpoints
   */
  handleMediaQueryRestrictions(mobileStyles: any, tabletStyles: any) {
    if (tabletStyles?.enable === false && mobileStyles?.enable) return "and (max-width: 766px), (min-width: 993px)"
    if (tabletStyles?.enable === false) return "and (min-width: 993px)"
    if (mobileStyles?.enable === false) return "and (min-width: 767px)"
    return ""
  }

  /**
   * Build the component styles from it's template prop
   */
  handleCmsStyles(templateStyles: any) {
    if (templateStyles) {
      const templateObj: any = templateStyles
      const shadows = conditionalShadowRenderUnsafe(templateObj, "desktop", "default", "text", undefined)
      const boundaries = cssRenderUnsafeBoundary(templateObj, "desktop", "default")
      const color = cssRenderUnsafeColor(templateObj, "desktop", "default", undefined, undefined, true)
      const verticalAlign = handleVerticalAlign(templateObj["verticalAlign"])
      return {
        fontFamily: `"${clearFont(templateObj["fontFamily"])}" !important`,
        fontWeight: templateObj["fontWeight"] + " !important",
        fontSize: templateObj["fontSize"] + "px !important",
        letterSpacing: templateObj["letterSpacing"] + "px !important",
        lineHeight: templateObj["lineHeight"] + " !important",
        textAlign: templateObj["textAlign"] + " !important",
        textTransform: templateObj["textTransform"] + " !important",
        ...color,
        ...shadows,
        ...verticalAlign,
        ...boundaries,
      }
    } else {
      return BobDefaultNode.stylesOverride
    }
  }

  handleFonts(templateStyles: any) {
    let FONTLOADED = ""
    let FONTLOADEDTABLET = ""
    let FONTLOADEDMOBILE = ""
    let FONTLOADEDHOVER = ""
    let FONTLOADEDACTIVE = ""
    if (this.props.expManager.enable) {
      FONTLOADED = dynamicFontLoader(
        templateStyles ? templateStyles : BobDefaultNode.styles,
        [templateStyles.fontWeight],
        undefined
      )
      FONTLOADEDTABLET = dynamicFontLoader(
        templateStyles.tablet ? templateStyles.tablet : BobDefaultNode.styles,
        [templateStyles.tablet && templateStyles.tablet.fontWeight],
        undefined,
        undefined,
        true
      )
      FONTLOADEDMOBILE = dynamicFontLoader(
        templateStyles.mobile ? templateStyles.mobile : BobDefaultNode.styles,
        templateStyles.mobile && [templateStyles.mobile.fontWeight],
        undefined,
        undefined,
        true
      )
      FONTLOADEDHOVER =
        templateStyles.behaviour && templateStyles.behaviour.hover && templateStyles.behaviour.hover.enable
          ? dynamicFontLoader(
              templateStyles.behaviour.hover,
              [templateStyles.behaviour.hover.fontWeight],
              undefined,
              undefined,
              true
            )
          : ""
      FONTLOADEDACTIVE =
        templateStyles.behaviour && templateStyles.behaviour.active && templateStyles.behaviour.active.enable
          ? dynamicFontLoader(
              templateStyles.behaviour.active,
              [templateStyles.behaviour.active.fontWeight],
              undefined,
              undefined,
              true
            )
          : ""
    }
    return `${FONTLOADED} ${FONTLOADEDTABLET} ${FONTLOADEDMOBILE} ${FONTLOADEDHOVER} ${FONTLOADEDACTIVE}`
  }

  clickEvent(e: any, TAG: any) {
    if (TAG === "a") {
      const linkLabel = analyticsLinkLabel(
        this.props.field,
        {
          inlineContent: this.props.inlineContent,
          overrides: this.props.overrides,
        },
        this.props.pageResponse,
        this.props.post
      )
      //get parent id to properly know who has the link configured
      const parentId = e.target.parentElement?.id
      if (parentId) {
        GoogleEvent("link_click", linkLabel, "Link Clicked", 1)
      }
    }
  }

  render() {
    const templateStyles = this.props.template
    const mobileStyles = {
      ...responsiveProperties(templateStyles, "mobile", { shadow: { shadowType: "text" } }, ["color"], ["behaviour"]),
      ...conditionalShadowRenderUnsafe(templateStyles, "mobile", "default", "text", undefined),
      ...cssRenderUnsafeBoundary(templateStyles, "mobile", "default"),
      ...cssRenderUnsafeColor(templateStyles, "mobile", "default", undefined, undefined, true),
      ...responsiveGroupStylesProperties(templateStyles, "mobile", "text"),
    }
    const tabletStyles = {
      ...responsiveProperties(templateStyles, "tablet", { shadow: { shadowType: "text" } }, ["color"], ["behaviour"]),
      ...conditionalShadowRenderUnsafe(templateStyles, "tablet", "default", "text", undefined),
      ...cssRenderUnsafeBoundary(templateStyles, "tablet", "default"),
      ...cssRenderUnsafeColor(templateStyles, "tablet", "default", undefined, undefined, true),
      ...responsiveGroupStylesProperties(templateStyles, "tablet", "text"),
    }

    const TAG = getOverrideInlineContentValues(`${this.props.field}.behaviour-link-enable`, this.props)
      ? "a"
      : getOverrideInlineContentValues(`${this.props.field}.tag`, this.props)
    const FONTSLOADED = this.handleFonts(templateStyles)

    let isValidHtml = false
    if (
      getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props) === CMS ||
      getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props) === CONTENT_PAGE
    ) {
      isValidHtml = validHtml(this.props.value)
    }
    let content: any
    // give a prefix to each class so that the inline styles dont apply to all bob-text-container classes
    const classPrefix = `prefix${templateStyles.uuid}`
    const classes = `bob-text ${classPrefix}-bob-text ${this.props.className || ""} spacing-preview-${
      this.props.template.uuid
    } ${GlobalStylesUtils.handleClassName(
      this.props.pageResponse.nGlobalStyles,
      this.props.template,
      undefined,
      "-text"
    )}`
    // LEGACY check for (this.props.rules.behaviour && this.props.rules.behaviour.hover)
    let hoverStyles: string = ""
    let activeStyles: string = ""
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.hover) {
      hoverStyles = this.handleStateStyles(
        templateStyles,
        templateStyles.behaviour.hover,
        templateStyles.mobile?.behaviour?.hover,
        templateStyles.tablet?.behaviour?.hover,
        `.${classPrefix}-bob-text:hover, .${classPrefix}-bob-text.isHover`,
        "hover"
      )
    }
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.active) {
      activeStyles = this.handleStateStyles(
        templateStyles,
        templateStyles.behaviour.active,
        templateStyles.mobile?.behaviour?.active,
        templateStyles.tablet?.behaviour?.active,
        `.active .${classPrefix}-bob-text`,
        "active"
      )
    }

    const linkAttrs = getLinkAttributes(
      this.props.field,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      },
      this.props.pageResponse,
      this.props.post
    )

    const customAttributesEnable = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_ENABLE}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesMappedField = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_MAPPED_FIELD}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesValue = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_VALUE}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesContentSrc = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_CONTENTSRC}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const datasetValue = datasetRender(
      this.props.post,
      customAttributesEnable,
      customAttributesMappedField,
      customAttributesValue,
      customAttributesContentSrc
    )

    // Render Html when coming from CMS/CONTENT_PAGE
    if (isValidHtml) {
      content = (
        <BobTextSplit
          datasetRender={datasetValue}
          textType={"renderHtml"}
          templateStyles={templateStyles}
          tabletStyles={tabletStyles}
          mobileStyles={mobileStyles}
          inlineContent={this.props.inlineContent}
          overrides={this.props.overrides}
          value={this.props.value}
          tag={TAG}
          classes={classes}
          linkAttrs={linkAttrs}
          id={this.props.id}
          instanceId={this.props.instanceId}
          field={this.props.field}
          network={this.props.network}
          pageResponse={this.props.pageResponse}
          classPrefix={classPrefix}
          handleCmsStyles={this.handleCmsStyles}
          clickEvent={this.clickEvent}
        />
      )
    } else if (getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props) === "custom") {
      content = (
        <BobTextSplit
          datasetRender={datasetValue}
          textType={"renderCustomContent"}
          templateStyles={templateStyles}
          tabletStyles={tabletStyles}
          mobileStyles={mobileStyles}
          inlineContent={this.props.inlineContent}
          overrides={this.props.overrides}
          value={this.props.value}
          tag={TAG}
          classes={classes}
          linkAttrs={linkAttrs}
          id={this.props.id}
          instanceId={this.props.instanceId}
          field={this.props.field}
          network={this.props.network}
          pageResponse={this.props.pageResponse}
          classPrefix={classPrefix}
          handleCmsStyles={this.handleCmsStyles}
          clickEvent={this.clickEvent}
        />
      )
    }
    // Render custom tag and content from CMS and Content Page, with no valid html.
    else {
      content = (
        <BobTextSplit
          datasetRender={datasetValue}
          textType={"renderCustomTag"}
          templateStyles={templateStyles}
          tabletStyles={tabletStyles}
          mobileStyles={mobileStyles}
          inlineContent={this.props.inlineContent}
          overrides={this.props.overrides}
          value={this.props.value}
          tag={TAG}
          classes={classes}
          linkAttrs={linkAttrs}
          id={this.props.id}
          instanceId={this.props.instanceId}
          field={this.props.field}
          network={this.props.network}
          pageResponse={this.props.pageResponse}
          classPrefix={classPrefix}
          handleCmsStyles={this.handleCmsStyles}
          clickEvent={this.clickEvent}
        />
      )
    }

    const hasStateStylesOrFont = hoverStyles.trim() !== "" || activeStyles.trim() !== "" || FONTSLOADED.trim() !== ""

    return (
      <Fragment>
        {hasStateStylesOrFont && (
          <Style>
            {`
              ${FONTSLOADED}
              ${hoverStyles}
              ${activeStyles}
            `}
          </Style>
        )}
        {content}
      </Fragment>
    )
  }
}

export default BobText
