import React, { Fragment } from 'react'
import ContentLoader from "react-content-loader"
import { connect } from 'react-redux'
import { ApplicationState } from "../../../../store/ApplicationState"

interface OwnProps {
  children?: React.ReactNode
  type: "text" | "media"
  fontSize?: number
  contentSrc?: "custom" | "content-page" | "post"
  mappedValue?: string
  mediaWidth?: string
  mediaHeight?: string
  uuid: string
}

interface ReduxProps {
  loadingPublicContentPagePost: ApplicationState["contentLibraryReducer"]["loadingPublicContentPagePost"]
  loadingPreviewContentPagePost: ApplicationState["contentLibraryReducer"]["loadingPreviewContentPagePost"]
  contentFetch: ApplicationState["contentHubReducer"]["contentFetch"]
}

type BobLoadingProps = OwnProps & ReduxProps

class BobLoading extends React.Component<BobLoadingProps> {
  renderMoreLines() {
    const { mappedValue, fontSize } = this.props
    const fixedFontSize = fontSize || 20
    if (mappedValue) {
      if (mappedValue.includes("description_preview")) {
        return (
          <Fragment>
            <rect x='0' y='0' rx='3' ry='3' width='100%' height={fixedFontSize} />
            <rect x='0' y={fixedFontSize + fixedFontSize / 2} rx='3' ry='3' width='100%' height={fixedFontSize} />
          </Fragment>
        )
      }
      if (mappedValue.includes("description")) {
        return (
          <Fragment>
            <rect x='0' y='0' rx='3' ry='3' width='100%' height={fixedFontSize} />
            <rect x='0' y={fixedFontSize + fixedFontSize / 2} rx='3' ry='3' width='100%' height={fixedFontSize} />
            <rect x='0' y={fixedFontSize * 3} rx='3' ry='3' width='100%' height={fixedFontSize} />
            <rect x='0' y={fixedFontSize * 4 + fixedFontSize / 2} rx='3' ry='3' width='100%' height={fixedFontSize} />
          </Fragment>
        )
      }
    }
    return <rect x='0' y='0' rx='3' ry='3' width='100%' height={fixedFontSize} />
  }

  loadingHeight() {
    const { mappedValue, fontSize } = this.props
    const fixedFontSize = fontSize || 20
    if (mappedValue) {
      if (mappedValue.includes("description_preview")) {
        return fixedFontSize * 3
      }
      if (mappedValue.includes("description")) {
        return fixedFontSize * 6
      }
    }
    return fixedFontSize
  }

  isLoading(contentSrc: OwnProps["contentSrc"]) {
    if (contentSrc === "content-page")
      return this.props.loadingPublicContentPagePost || this.props.loadingPreviewContentPagePost
    if (contentSrc === "post") return this.props.contentFetch[`content${this.props.uuid}loading`]
  }

  render() {
    const { contentSrc, type } = this.props
    if ((contentSrc === "content-page" || contentSrc === "post") && this.isLoading(contentSrc)) {
      if (type === "text") {
        return (
          <ContentLoader height={this.loadingHeight()} width={"100%"}>
            {this.renderMoreLines()}
          </ContentLoader>
        )
      }
      if (type === "media") {
        const { mediaHeight, mediaWidth } = this.props
        const fixedWidth = mediaWidth ?? "100%"
        const fixedHeight = mediaHeight ?? 500
        return (
          <ContentLoader height={fixedHeight} width={fixedWidth}>
            <rect x='0' y='0' rx='3' ry='3' width={fixedWidth} height={fixedHeight} />
          </ContentLoader>
        )
      }
    }
    return this.props.children
  }
}

function mapStateToProps(state: ApplicationState): ReduxProps {
  return {
    loadingPublicContentPagePost: state.contentLibraryReducer.loadingPublicContentPagePost,
    loadingPreviewContentPagePost: state.contentLibraryReducer.loadingPreviewContentPagePost,

    contentFetch: state.contentHubReducer,
  }
}

export default connect(mapStateToProps)(BobLoading)