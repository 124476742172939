import React from 'react'
import {
  getContent,
  getStringField,
  handleContentBlock,
  handleGutts,
  handleObjectType,
  hasChildOfType,
} from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import WarningSystem from "../../../../../../modules/shared-modules/experienceManager/warningSystem"
import ComponentHandler from "../../../../componentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { CardLeftProps, CardLeftState } from "."
import { Post } from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import cardStyles from "../../../../../../stylesheets/modules/components/card.module.sass"
import cardLeftRightStyles from "../../../../../../stylesheets/modules/templates/cardLeftRight.module.sass"
import cardsStyles from "../../../../../../stylesheets/modules/templates/cards.module.sass"

class CardLeft extends React.Component<CardLeftProps, CardLeftState> {
  constructor(props: CardLeftProps) {
    super(props)
    this.state = {
      childComponents: [],
      hasChildModal: false,
    }
  }

  child: any[] = []

  componentDidMount() {
    this.handleWriteState(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: CardLeftProps) {
    if (
      (!this.props.obj.children && nextProps.obj.children && nextProps.obj.children.length) ||
      (this.props.obj.children &&
        nextProps.obj.children &&
        this.props.obj.children.length !== nextProps.obj.children.length)
    ) {
      this.handleWriteState(nextProps)
    }
  }

  handleWriteState(props: CardLeftProps) {
    let componentsId = props.obj.children
    if (componentsId && componentsId.length > 0) {
      this.setState({
        childComponents: componentsId,
        hasChildModal: hasChildOfType(
          "modal",
          componentsId,
          props.obj,
          props.pageResponse,
          props.expManager.emEditorType
        ),
      })
    }
  }

  /**
   *
   * @param {postIdx} idx
   * We need this idx from the post array
   * to properly call the inner function down the react tree.
   * if we dont use this we overwrite the same ref over and over
   * leaving only the reference to the last rendered object.
   */
  handleCardClick(idx: number) {
    if (this.child && this.child[idx]) this.child[idx].handleInnerFunction()
  }

  renderComponents(post: Post, postIdx: number) {
    let componentsId = this.props.obj.children
    if (componentsId && componentsId.length > 0) {
      return componentsId.map((componentId, idx) => {
        let handledItem = handleObjectType(this.props.obj, componentId, this.props.pageResponse, this.props.expManager.emEditorType)
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={this.props.obj.selectedInstanceId}
            onRef={(ref) => (this.child[postIdx] = ref)}
            component={handleContentBlock(
              handledItem,
              post,
              this.props.obj,
              this.props.pageResponse,
              this.props.overrides
            )}
            pageResponse={this.props.pageResponse}
            matchOrder={this.props.matchOrder}
            expManager={this.props.expManager}
            key={idx}
          />
        )
      })
    }
  }

  renderCards() {
    let content = getContent(this.props.obj, "posts", this.props.overrides)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      const childs = this.props.obj.children
      const hasChildModal =
        childs?.length > 0 &&
        hasChildOfType("modal", childs, this.props.obj, this.props.pageResponse, this.props.expManager.emEditorType)
      return content.map((post: Post, idx: number) => {
        let columns = handleColumns(idx, counter, this.props.obj.styles.bobs.background1)
        let leftColumnsCard = handleColumns(
          0,
          { desktop: 0, tablet: 0, mobile: 0 },
          this.props.obj.styles.bobs.background2
        )
        let rightColumnsCard = handleColumns(
          1,
          { desktop: 0, tablet: 0, mobile: 0 },
          this.props.obj.styles.bobs.background2
        )
        let gutters = handleGutts(this.props.obj.styles.bobs.background2, true)
        counter = columns.counter
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field={"background2"}
              rules={this.props.componentRule}
              template={this.props.obj.styles}
              expManager={this.props.expManager}
              className={`card ${hasChildModal ? "is-clickable" : ""}`}
              onClick={() => this.handleCardClick(idx)}
            >
              <div className={`row ${gutters} ${responsiveGuttersClass(this.props.obj.styles.bobs.background2)}`}>
                <div
                  className={`col-${leftColumnsCard.columnsMobile} col-md-${leftColumnsCard.columnsTablet} col-lg-${leftColumnsCard.columns}`}
                >
                  <BobComponentHandler
                    position={this.props.position}
                    pageResponse={this.props.pageResponse}
                    replacedMediaList={this.props.replacedMediaList}
                    instanceId={this.props.obj.selectedInstanceId}
                    object={this.props.obj}
                    objectId={this.props.obj.uuid}
                    inlineContent={this.props.obj.content.inlineContent}
                    overrides={this.props.overrides}
                    field='media1'
                    template={this.props.obj.styles}
                    rules={this.props.componentRule}
                    expManager={this.props.expManager}
                    network={post.network}
                    post={post}
                  />
                </div>
                <div
                  className={`col-${rightColumnsCard.columnsMobile} col-md-${rightColumnsCard.columnsTablet} col-lg-${rightColumnsCard.columns}`}
                >
                  <BobComponentHandler
                    position={this.props.position}
                    pageResponse={this.props.pageResponse}
                    instanceId={this.props.obj.selectedInstanceId}
                    object={this.props.obj}
                    objectId={this.props.obj.uuid}
                    post={post}
                    inlineContent={this.props.obj.content.inlineContent}
                    overrides={this.props.overrides}
                    field={"background3"}
                    rules={this.props.componentRule}
                    template={this.props.obj.styles}
                    expManager={this.props.expManager}
                    className='card-body'
                    flexDirection='column'
                  >
                    <BobComponentHandler
                      position={this.props.position}
                      pageResponse={this.props.pageResponse}
                      instanceId={this.props.obj.selectedInstanceId}
                      object={this.props.obj}
                      objectId={this.props.obj.uuid}
                      post={post}
                      inlineContent={this.props.obj.content.inlineContent}
                      overrides={this.props.overrides}
                      field='text1'
                      tag='p'
                      value={getStringField(
                        post,
                        this.props.obj,
                        this.props.pageResponse,
                        "text1",
                        this.props.overrides
                      )}
                      template={this.props.obj.styles}
                      rules={this.props.componentRule}
                      expManager={this.props.expManager}
                      network={post.network}
                      className='title'
                    />
                    <BobComponentHandler
                      position={this.props.position}
                      pageResponse={this.props.pageResponse}
                      instanceId={this.props.obj.selectedInstanceId}
                      object={this.props.obj}
                      objectId={this.props.obj.uuid}
                      post={post}
                      inlineContent={this.props.obj.content.inlineContent}
                      overrides={this.props.overrides}
                      field='text2'
                      tag='p'
                      value={getStringField(
                        post,
                        this.props.obj,
                        this.props.pageResponse,
                        "text2",
                        this.props.overrides
                      )}
                      template={this.props.obj.styles}
                      rules={this.props.componentRule}
                      expManager={this.props.expManager}
                      network={post.network}
                      className='preview'
                    />
                    <BobComponentHandler
                      position={this.props.position}
                      pageResponse={this.props.pageResponse}
                      instanceId={this.props.obj.selectedInstanceId}
                      object={this.props.obj}
                      objectId={this.props.obj.uuid}
                      post={post}
                      inlineContent={this.props.obj.content.inlineContent}
                      overrides={this.props.overrides}
                      field='text3'
                      tag='p'
                      value={getStringField(
                        post,
                        this.props.obj,
                        this.props.pageResponse,
                        "text3",
                        this.props.overrides
                      )}
                      template={this.props.obj.styles}
                      rules={this.props.componentRule}
                      expManager={this.props.expManager}
                      network={post.network}
                      className='message'
                    />
                    {this.renderComponents(post, idx)}
                  </BobComponentHandler>
                </div>
              </div>
            </BobComponentHandler>
          </div>
        )
      })
    } else {
      if (this.props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Select a formula to show posts' />
          </div>
        )
      }
    }
  }

  render() {
    const background1 = this.props.obj.styles.bobs.background1
    const gutters = handleGutts(background1, false)
    /**
     * Responsive Alignment Styles
     */
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

    return (
      <section className={`cards ${cardsStyles.cards} card-left ${cardLeftRightStyles["card-left"]}`}>
        <AlignmentHOC
          className={`card-deck ${cardStyles["card-deck"]} row ${gutters} ${responsiveGuttersClass(
            this.props.obj.styles.bobs.background1
          )}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {this.renderCards()}
        </AlignmentHOC>
      </section>
    )
  }
}


export default CardLeft