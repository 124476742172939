import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { cssRenderUnsafe as FlexStyleCssRenderUnsafe } from "../bobStyles/flexStyle"
import { cssRenderUnsafe as SelfFlexStyleCssRenderUnsafe } from "../bobStyles/selfFlexStyle"
import { cssRenderUnsafe as HeightStyleCssRenderUnsafe } from "../bobStyles/heightStyleV2"
import { cssRenderUnsafe as MaxHeightStyleCssRenderUnsafe } from "../bobStyles/maxHeightStyle"
import { cssRenderUnsafe as MinHeightStyleCssRenderUnsafe } from "../bobStyles/minHeightStyle"
import { cssRenderUnsafe as WidthStyleCssRenderUnsafe } from "../bobStyles/widthStyleV2"
import { cssRenderUnsafe as MaxWidthStyleCssRenderUnsafe } from "../bobStyles/maxWidthStyle"
import { cssRenderUnsafe as MinWidthStyleCssRenderUnsafe } from "../bobStyles/minWidthStyle"
import { cssRenderUnsafe as ShadowStyleCssRenderUnsafe } from "../bobStyles/shadowStyleV2"
import { cssRenderUnsafe as BorderStyleCssRenderUnsafe } from "../bobStyles/borderStyleV2"
import { cssRenderUnsafe as RadiusStyleCssRenderUnsafe } from "../bobStyles/radiusStyle"
import { cssRenderUnsafe as BoundaryStyleCssRenderUnsafe } from "../bobStyles/boundaryStyleV2"
import { cssRenderUnsafe as BackgroundColorCssRenderUnsafe } from "../bobStyles/colorsStyleV2"
import { cssRenderUnsafe as ZIndexCssRenderUnsafe } from "../bobStyles/zindexStyle"
import { cssRenderUnsafe as PositionCssRenderUnsafe } from "../bobStyles/positionStyle"
import { cssRenderUnsafe as cssRenderUnsafeCursor } from "../bobStyles/cursorStyle"
//import { cssRenderUnsafe as BackgroundVideoCssRenderUnsafe } from "../bobStyles/backgroundVideoStyle"
import { BobFormV2 } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { PageStylesheet } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import {
  handleBobBorderLabel,
  handleBobColorsLabel,
  handleBobShadowLabel,
} from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

const responsiveStyles = (
  styles: BobFormV2,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
) => {
  const stylesheetShadowColorLabel = handleBobShadowLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const stylesheetBorderColorLabel = handleBobBorderLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const stylesheetColorsLabel = handleBobColorsLabel(styles, stylesheetLabels, breakpoint, behaviourState)

  return {
    ...HeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...WidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...FlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...SelfFlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...ShadowStyleCssRenderUnsafe(styles, breakpoint, behaviourState, undefined, stylesheetShadowColorLabel),
    ...BoundaryStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...BorderStyleCssRenderUnsafe(styles, breakpoint, behaviourState, stylesheetBorderColorLabel),
    ...RadiusStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...ZIndexCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...PositionCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeCursor(styles, breakpoint, behaviourState),
    ...BackgroundColorCssRenderUnsafe(styles, breakpoint, behaviourState, undefined, stylesheetColorsLabel),
  }
}

export { responsiveStyles }
