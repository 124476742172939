import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import BobComponentHandler from '../../../../bob/bobComponentHandler'
import ComponentHandler from '../../../../../componentHandler'
import {
  getContent,
  getStringField,
  handleContentBlock,
  handleObjectType,
} from "../../../../../../../modules/shared-modules/utilities/components"
import AlignmentHOC from "../../../alignmentHOC"
import { alignmentWithAP } from "../../../../../../../modules/shared-modules/utilities/conditionalController"
import { Post } from "../../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { ComponentSpecificAlignment } from "../../../../bob/bobStyles/alignmentStyle"
import { ModalV4Props } from "./interface"
import modalStyles from "../../../../../../../stylesheets/modules/components/modal.module.sass"
import elementModalV4Styles from "../../../../../../../stylesheets/modules/templates/elementModal_v4.module.sass"

type ModalComponentV4State = {
  showModal: boolean
  modalMountContainer: HTMLElement | null | undefined
}

class ModalV4 extends React.Component<ModalV4Props, ModalComponentV4State> {
  node: any
  parentRef: any

  constructor(props: ModalV4Props) {
    super(props)
    this.state = {
      showModal: false,
      modalMountContainer: null,
    }

    this.parentRef = React.createRef()
  }

  componentDidMount = () => {
    // access inner methods from parent
    if (this.props.onRef) this.props.onRef(this)

    // get iframe body node when inside page builder
    // use it to create the modal inside the iframe
    let modalMountContainer
    if (this.props.expManager.enable) {
      const previewIframe = document.querySelector("#board-preview-frame") as HTMLIFrameElement
      modalMountContainer = previewIframe.contentWindow?.document.querySelector(".page-container") as HTMLElement
    } else {
      modalMountContainer = document.querySelector(".page-container") as HTMLElement
    }
    this.setState({ modalMountContainer: modalMountContainer })
  }

  componentWillUnmount = () => {
    // access inner methods from parent
    if (this.props.onRef) this.props.onRef(undefined)
    else return
  }

  handleInnerFunction = () => this.toggleModal()

  /**
   * must replicate body class logic inside EM because modal-backdrop-open is inside the browser preview iframe
   * the logic to inject the body class is based on modal-backdrop-open class and it cant be accessed via the iframe
   */
  handleBodyClass = () => {
    if (this.props.expManager.enable) {
      let boardPreview = document.getElementById("board-preview-frame") as HTMLIFrameElement
      if (boardPreview) {
        let iframeBody = boardPreview.contentWindow?.document.body
        if (!this.state.showModal) {
          iframeBody?.classList.remove("modal-open")
        } else if (document.body.className.indexOf("modal-open") === -1) {
          iframeBody?.classList.add("modal-open")
        }
      }
    } else {
      if (this.state.showModal) document.body.style.overflow = "hidden"
      else document.body.style.overflow = "auto"
    }
  }

  toggleModal = () => {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => this.handleBodyClass()
    )
  }

  handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    /**
     * This trigger works when the user clicks outside of the modal body
     */
    this.toggleModal()
  }

  handleModalContainerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
  }

  renderModalComponents = (post?: Post) => {
    let componentsId = this.props.obj.children
    if (componentsId && componentsId.length > 0)
      return componentsId.map((componentId: string, idx: number) => {
        let handledItem = handleObjectType(
          this.props.obj,
          componentId,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={this.props.obj.selectedInstanceId}
            key={idx}
            component={handleContentBlock(
              handledItem,
              post,
              this.props.obj,
              this.props.pageResponse,
              this.props.overrides
            )}
            pageResponse={this.props.pageResponse}
            matchOrder={this.props.matchOrder}
            expManager={this.props.expManager}
          />
        )
      })
  }

  renderModalPosts = () => {
    let postsArr = this.props.obj.content.formula ? getContent(this.props.obj, "posts") : []
    if (postsArr.length > 0) {
      return postsArr.map((post: Post, idx: number) => {
        return (
          <Fragment key={idx}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              replacedMediaList={this.props.replacedMediaList}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='media1'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              post={post}
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='text1'
              tag='h1'
              value={getStringField(post, this.props.obj, this.props.pageResponse, "text1", this.props.overrides)}
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              className='title'
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='text2'
              tag='p'
              value={getStringField(post, this.props.obj, this.props.pageResponse, "text2", this.props.overrides)}
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              className='description'
            />
            {this.renderModalComponents(post)}
          </Fragment>
        )
      })
    }
    return this.renderModalComponents()
  }

  render = () => {
    const background1 = this.props.obj.styles.bobs.background1
    const alignmentAdittionalProperties = { flexDirection: "column", self: false } as ComponentSpecificAlignment
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignmentWithAP(
      background1,
      alignmentAdittionalProperties
    )

    return (
      <Modal
        className={`modal-fluxio ${modalStyles["modal-fluxio"]} ${elementModalV4Styles["modal-fluxio"]} elements ${elementModalV4Styles.elements} modal-component_v4 ${elementModalV4Styles["modal-component_v4"]}`}
        backdropClassName='modal-component-backdrop'
        container={this.state.modalMountContainer}
        size='lg'
        show={this.state.showModal}
        onHide={this.toggleModal}
        enforceFocus={false}
      >
        <Modal.Header closeButton={false}>
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            objectId={this.props.obj.uuid}
            field='icon1'
            template={this.props.obj.styles}
            rules={this.props.componentRule}
            expManager={this.props.expManager}
            iconClassName='fas fa-times aaa'
            className={`modal-close`}
            onClick={() => this.toggleModal()}
          />
        </Modal.Header>
        <Modal.Body>
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            onClick={this.handleBackgroundClick}
            objectId={this.props.obj.uuid}
            inlineContent={this.props.obj.content.inlineContent}
            overrides={this.props.overrides}
            field={"background1"}
            rules={this.props.componentRule}
            template={this.props.obj.styles}
            expManager={this.props.expManager}
            className='modal-body'
            flexDirection='column'
          >
            <AlignmentHOC
              className='modal-body-container'
              desktop={alignmentStyles}
              tablet={tabletAlignment}
              mobile={mobileAlignment}
              onClick={this.handleModalContainerClick}
            >
              {this.state.showModal && this.renderModalPosts()}
            </AlignmentHOC>
          </BobComponentHandler>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ModalV4