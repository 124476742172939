import React, { useState, useEffect } from "react"
import ReactPlayer from "react-player/lazy"

interface VideoPlayerLazyProps {
  url: string
  controls: boolean
  config: any
}

const VideoPlayerLazy: React.FC<VideoPlayerLazyProps> = (props): JSX.Element => {
  // <ReactPlayer> must render client side to work, hasLoaded enforces that
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  useEffect(() => {
    setHasLoaded(true)
  }, [])

  if (hasLoaded)
    return <ReactPlayer url={props.url} controls={props.controls} config={props.config} width='100%' height='100%' />

  return <></>
}

export default VideoPlayerLazy
