import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { loadMoreDataSet } from "../../../../../store/shared-store/actions/publicPageActions"
import { bindActionCreators } from "redux"
import {
  handleDataSetAsContentBlock,
  handleObjectType,
} from "../../../../../modules/shared-modules/utilities/components"
import ComponentHandler from "../../../componentHandler"
import { handleColumns, mergeStyleWithGS, responsiveGuttersClass } from "../../../shared-public-page/bob/bobHandler"
import AlignmentHOC from "../../../shared-public-page/fluxio-components/alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../modules/shared-modules/utilities/conditionalController"
import { ResultsListContainerProps } from "."

const LoadMoreButtonStyle = {
  fontFamily: "Poppins",
  fontWeight: "600",
  fontSize: "16px",
  letterSpacing: "0px",
  lineHeight: "1",
  textAlign: "center",
  textTransform: "uppercase",
  color: "rgb(87, 109, 99)",
}
const ResultsListContainer: React.FC<ResultsListContainerProps> = (props): JSX.Element => {
  const loadMoreResults = () => {
    props.actions.loadMoreDataSet(props.dataSet.uuid, props.dataSetFilterParams, props.dataSet.nextCursor)
  }

  const renderComponents = (childComponent: any, background1Template: any) => {
    let counter = { desktop: 0, tablet: 0, mobile: 0 }
    const handledItem = handleObjectType(props.obj, childComponent, props.pageResponse, props.expManager.emEditorType)
    const columns = handleColumns(
      0,
      counter,
      background1Template,
      props.pageResponse.globalStyles[background1Template.globalStyleId]
    )
    counter = columns.counter

    return (
      <div className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`} key={0}>
        <ComponentHandler
          position={0}
          key={0}
          component={handleDataSetAsContentBlock(handledItem, props.dataSet.posts, props.obj)}
          pageResponse={props.pageResponse}
          selectedInstanceId={props.obj.selectedInstanceId}
          matchOrder={props.matchOrder}
          expManager={props.expManager}
        />
      </div>
    )
  }

  const renderPagination = () => {
    if (props.dataSet.nextCursor)
      return (
        <div className='d-flex align-items-center justify-content-center w-100 mt-4'>
          <button
            //@ts-ignore
            style={LoadMoreButtonStyle}
            className='btn btn-primeary btn-sm'
            disabled={props.fetchingLoadMoreDataSet}
            onClick={() => loadMoreResults()}
          >
            {props.fetchingLoadMoreDataSet ? (
              <i style={{ color: "#6e6f75" }} className='fas fa-spinner fa-spin' />
            ) : (
              "Ver mais Resultados"
            )}
          </button>
        </div>
      )
  }

  const renderChilds = (background1Template: any) => {
    const message = !props.dataSet
      ? "Select a DataSet to display its contents"
      : !props.dataSet.posts
      ? "No posts in this dataset"
      : ""
    if (!props.dataSet) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            verticalAlign: "middle",
            height: "100px",
            border: "3px dashed #dbe0e7",
          }}
        >
          <div className='alert warning m-0' role='alert'>
            {message}
          </div>
        </div>
      )
    } else if (!props.dataSet.posts || (props.dataSet.posts && props.dataSet.posts.length === 0)) {
      return (
        <div className='d-flex align-items-center justify-content-center'>
          <h5 style={{ color: "#6e6f75" }}>Sem resultados para a sua pesquisa.</h5>
        </div>
      )
    }
    // get only possible childs
    const childsId = props.obj.children
    if (childsId && childsId.length > 0)
      return (
        <Fragment>
          {renderComponents(childsId[0], background1Template)}
          {renderPagination()}
        </Fragment>
      )

    return (
      <div className='d-flex align-items-center justify-content-center'>
        <div className='alert warning m-0' role='alert'>
          Add a child object to show results.
        </div>
      </div>
    )
  }

  const background1 = mergeStyleWithGS(
    props.obj.styles.bobs.background1,
    props.pageResponse.globalStyles && props.pageResponse.globalStyles[props.obj.styles.bobs.background1.globalStyleId]
  )
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

  if (props.fetchingDataSet)
    return (
      <div className='d-flex align-items-center justify-content-center'>
        <i style={{ color: "#6e6f75", fontSize: "35px" }} className='fa fa-spin fa-spinner'></i>
      </div>
    )

  return (
    <div className='datase-filter-component'>
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={{ ...alignmentStyles, flexDirection: "row" }}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderChilds(background1)}
      </AlignmentHOC>
    </div>
  )
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators({ loadMoreDataSet }, dispatch),
  }
}

function mapStateToProps(state: any) {
  return {
    fetchingDataSet: state.publicPageReducer.server.fetchingDataSet,
    dataSet: state.publicPageReducer.server.dataSet,
    dataSetFilterParams: state.publicPageReducer.server.dataSetFilterParams,
    fetchingLoadMoreDataSet: state.publicPageReducer.server.fetchingLoadMoreDataSet
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsListContainer)