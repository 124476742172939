import React, { Fragment } from 'react'
import {
  Modal
} from 'react-bootstrap'
import BobComponentHandler from '../../../../shared-public-page/bob/bobComponentHandler'
import ComponentHandler from '../../../../componentHandler'
import { handler } from "../../../../../../modules/routeCreator"
import {
  getContent,
  getStringField,
  handleContentBlock,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import AlignmentHOC from "../../../../shared-public-page/fluxio-components/alignmentHOC"
import { alignmentWithAP } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { withRouter } from "next/router"
import { ModalV2Props } from "."
import { ComponentSpecificAlignment } from "../../../../shared-public-page/bob/bobStyles/alignmentStyle"
import modalStyles from "../../../../../../stylesheets/modules/components/modal.module.sass"
import elementModalV2Styles from "../../../../../../stylesheets/modules/templates/elementModal_v2.module.sass"

class ModalV2 extends React.Component<ModalV2Props, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      showModal: false,
    }
    this.handleModal = this.handleModal.bind(this)
    this.handleModalContainerClick = this.handleModalContainerClick.bind(this)
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
    this.handleInnerFunction = this.handleInnerFunction.bind(this)
  }

  componentDidMount() {
    // access inner methods from parent
    if (this.props.onRef) this.props.onRef(this)
    else return
  }

  componentWillUnmount() {
    // access inner methods from parent
    if (this.props.onRef) this.props.onRef(undefined)
    else return
  }

  handleInnerFunction() {
    this.handleModal()
  }

  /**
   * must replicate body class logic inside EM because modal-backdrop-open is inside the browser preview iframe
   * the logic to inject the body class is based on modal-backdrop-open class and it cant be accessed via the iframe
   */
  handleBodyClass() {
    if (this.props.expManager.enable) {
      let boardPreview = document.getElementById("board-preview-frame") as HTMLIFrameElement
      if (boardPreview) {
        let iframeBody = boardPreview.contentWindow?.document.body
        if (!this.state.showModal) {
          iframeBody?.classList.remove("modal-open")
        } else if (document.body.className.indexOf("modal-open") === -1) {
          iframeBody?.classList.add("modal-open")
        }
      }
    }
  }

  handleModal(post?: any) {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => {
        if (!this.props.expManager.enable && post) {
          if (this.state.showModal)
            this.props.router.push(handler(this.props.router, this.props.matchOrder, post.slug), undefined, {
              scroll: false,
            })
          else
            this.props.router.push(handler(this.props.router, this.props.matchOrder, ""), undefined, {
              scroll: false,
            })
        }
        this.handleBodyClass()
      }
    )
  }

  handleBackgroundClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    /**
     * This trigger works when the user clicks outside of the modal body
     */
    this.handleModal()
  }

  handleModalContainerClick(e: Event) {
    e.stopPropagation()
  }

  renderModalComponents(post?: any) {
    let componentsId = this.props.obj.children
    if (componentsId && componentsId.length > 0)
      return componentsId.map((componentId: any, idx: any) => {
        let handledItem = handleObjectType(
          this.props.obj,
          componentId,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={this.props.obj.selectedInstanceId}
            key={idx}
            component={handleContentBlock(
              handledItem,
              post,
              this.props.obj,
              this.props.pageResponse,
              this.props.overrides
            )}
            pageResponse={this.props.pageResponse}
            matchOrder={this.props.matchOrder}
            expManager={this.props.expManager}
          />
        )
      })
  }

  renderModalPosts() {
    let postsArr = this.props.obj.content.formula ? getContent(this.props.obj, "posts") : []
    if (postsArr.length > 0) {
      return postsArr.map((post: any, idx: any) => {
        return (
          <Fragment key={idx}>
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              replacedMediaList={this.props.replacedMediaList}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='media1'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              post={post}
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field='text1'
              tag='h1'
              value={getStringField(post, this.props.obj, this.props.pageResponse, "text1", this.props.overrides)}
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              className='title'
            />
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              field='text2'
              tag='p'
              value={getStringField(post, this.props.obj, this.props.pageResponse, "text2", this.props.overrides)}
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              network={post.network}
              className='description'
            />
            {this.renderModalComponents(post)}
          </Fragment>
        )
      })
    }
    return this.renderModalComponents()
  }

  renderModal() {
    const isOpen = this.state.showModal
    const background1 = this.props.obj.styles.bobs.background1
    /**
     * Responsive Alignment Styles
     */
    const alignmentAdittionalProperties = { flexDirection: "column", self: false } as ComponentSpecificAlignment
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignmentWithAP(
      background1,
      alignmentAdittionalProperties
    )
    return (
      <Modal
        className={`modal-fluxio ${modalStyles["modal-fluxio"]} ${elementModalV2Styles["modal-fluxio"]} ${elementModalV2Styles["modal_v2_ssr"]}`}
        backdropClassName={`modal-component-backdrop ${elementModalV2Styles["modal_ssr_backdrop"]}`}
        show={isOpen}
        onHide={this.handleModal}
        size='xl'
        enforceFocus={false}
        backdrop
      >
        <Modal.Header closeButton={false}>
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            objectId={this.props.obj.uuid}
            field='icon1'
            template={this.props.obj.styles}
            rules={this.props.componentRule}
            expManager={this.props.expManager}
            iconClassName='fas fa-times aaa'
            className={`modal-close`}
            onClick={this.handleModal}
          />
        </Modal.Header>
        <BobComponentHandler
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.obj.selectedInstanceId}
          object={this.props.obj}
          onClick={this.handleBackgroundClick}
          objectId={this.props.obj.uuid}
          inlineContent={this.props.obj.content.inlineContent}
          overrides={this.props.overrides}
          field={"background1"}
          rules={this.props.componentRule}
          template={this.props.obj.styles}
          expManager={this.props.expManager}
          className='modal-body'
          flexDirection='column'
        >
          <AlignmentHOC
            className='modal-body-container'
            desktop={alignmentStyles}
            tablet={tabletAlignment}
            mobile={mobileAlignment}
            onClick={() => this.handleModalContainerClick}
          >
            {this.state.showModal && this.renderModalPosts()}
          </AlignmentHOC>
        </BobComponentHandler>
      </Modal>
    )
  }

  render() {
    return (
      <section
        className={`elements ${elementModalV2Styles.elements} modal-component_V2 ${elementModalV2Styles["modal-component_v2"]}`}
      >
        {this.renderModal()}
      </section>
    )
  }
}

export default withRouter(ModalV2)