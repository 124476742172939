const ControllerVersion: { [key: string]: { [key: string]: number } | undefined } = {
  /**
   * all:
   * media:
   * icon:
   */
  alignment: {
    media: 1,
    text: 1,
    background: 1,
    icon: 1,
  },
  border: {
    media: 1,
    text: 1,
    background: 1,
    icon: 1,
  },
  spacing: {
    media: 1,
    text: 1,
    background: 1,
    icon: 1,
  },
  colors: {
    media: 1,
    text: 1,
    background: 1,
    icon: 1,
  },
  shadow: {
    media: 1,
    text: 1,
    background: 1,
    icon: 1,
  },
}

export function isLastVersion(currentVersion: number | undefined, lastVersion: number) {
  if (currentVersion !== lastVersion) {
    return false
  }
  return true
}

export default ControllerVersion
