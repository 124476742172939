import React, { useEffect, useState } from "react"
import { EmbedContainerV2Props } from "./interface"
import { isBobCode } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import DangerousHtml from "../../../dangerousHtml/dangerousHtml"

const EmbedContainerV2: React.FC<EmbedContainerV2Props> = (props) => {
  const [isMouted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const Embed1 = isBobCode(props.obj.styles.bobs.embed1)

  if (Embed1.code && Embed1.code !== "") {
    if (isMouted) {
      return <DangerousHtml style={{}} html={Embed1.code} expManager={props.expManager} />
    }
  }

  if (props.expManager.enable) {
    return (
      <div className='alert warning m-0' role='alert'>
        Go to the elements tab and add your embed code there.
      </div>
    )
  }
  return <></>
}

export default EmbedContainerV2
