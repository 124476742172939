import React, { useEffect, useRef, useState } from 'react'
// @ts-ignore
import Style from 'style-it'
import { handleBackgroundStyles, handleTextStyles } from "../../../../bob/bobFormComponents"
import BobComponentHandler from "../../../../bob/bobComponentHandler"
import styled from "styled-components"
import { responsiveProperties, responsiveGroupStylesProperties } from "../../../../bob/bobHandler"
import { SelectInputProps } from "./interface"
import { ResponsiveCSSProps } from "../../interface"
import { conditionalColorsRenderUnsafe as cssRenderUnsafeColors } from "../../../../../../../modules/shared-modules/utilities/conditionalController"
import { cssRenderUnsafe as cssRenderUnsafeColor } from "../../../../bob/bobStyles/colorStyle"

const SelectInputElement = styled.div`
  ${(props: ResponsiveCSSProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: ResponsiveCSSProps) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: ResponsiveCSSProps) => props.mobile}
  }
`

const SelectInput: React.FC<SelectInputProps> = (props): JSX.Element => {
  const [value, setValue] = useState<string>("")
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      props.handleValueChange(value)
    }
    // eslint-disable-next-line
  }, [value])

  const handleValueChange = (inputValue: string) => {
    setValue(inputValue)
  }

  const handleErrorBorder = () => {
    if (props.error)
      return {
        border: "2px solid red",
      }
    return {}
  }

  let renderErrorBorder = handleErrorBorder()
  let backgroundStyles: any = handleBackgroundStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles)
  let textStyles: any = handleTextStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles, props.expManager)

  let styles = { ...backgroundStyles, ...textStyles.styles, ...renderErrorBorder }
  let mobileStyles = responsiveProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", undefined, [
    "colors",
  ])
  mobileStyles = {
    ...mobileStyles,
    ...responsiveGroupStylesProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "text"),
    ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "default", true),
    ...cssRenderUnsafeColor(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "mobile",
      "default",
      undefined,
      undefined,
      true
    ),
  }
  let tabletStyles = responsiveProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", undefined, [
    "colors",
  ])
  tabletStyles = {
    ...tabletStyles,
    ...responsiveGroupStylesProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "text"),
    ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "default", true),
    ...cssRenderUnsafeColor(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "tablet",
      "default",
      undefined,
      undefined,
      true
    ),
  }
  return (
    <Style>
      {`
      ${textStyles.fontLoaded}          
    `}
      <div className='form-group'>
        <BobComponentHandler
          position={props.position}
          pageResponse={props.pageResponse}
          instanceId={props.obj.selectedInstanceId}
          inlineContent={props.obj.content.inlineContent}
          overrides={props.overrides}
          object={props.obj}
          objectId={props.obj.uuid}
          field={props.bobStyle.text.name}
          tag='label'
          value={props.inputObj.label || props.inputObj.name}
          template={props.obj.styles}
          rules={props.componentRule}
          expManager={props.expManager}
          network={props.obj.network}
        />
        <div className='select-container'>
          <SelectInputElement
            as='select'
            desktop={styles}
            mobile={mobileStyles}
            tablet={tabletStyles}
            className='form-control'
            name={props.inputObj.name}
            value={value}
            onChange={(e: any) => handleValueChange(e.target.value)}
          >
            {/* default value */}
            <option style={{ display: "none" }}>{props.inputObj.label || props.inputObj.name}</option>
            {props.inputObj.selectChoices.map((option: any, idx: any) => {
              return (
                <option key={idx} value={option}>
                  {option}
                </option>
              )
            })}
          </SelectInputElement>
        </div>
        {props.error && (
          <p className='simple-form-error'>{props.lang === "en" ? "This field is required" : "Campo obrigatório"}</p>
        )}
      </div>
    </Style>
  )
}

export default SelectInput;
