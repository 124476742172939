import React, { useState, useEffect, RefObject } from "react"

interface VideoPlayerProps {
  id?: string
  url: string
  playing?: boolean
  controls: boolean
  poster: string
  preload: "none" | "metadata" | "auto" | ""
  className: string
  style?: {}
  ref: any
  setRef: (x: React.RefObject<HTMLVideoElement>) => void | undefined
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props): JSX.Element => {
  const [videoRef] = useState<RefObject<HTMLVideoElement>>(React.createRef())

  useEffect(() => {
    props.setRef && props.setRef(videoRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.playing === true) videoRef.current?.play()
    if (props.playing === false) videoRef.current?.pause()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.playing])

  const DOMid = props.id ? { id: props.id } : {}

  return (
    <video
      {...DOMid}
      ref={videoRef}
      src={props.url}
      controls={props.controls}
      poster={props.poster}
      preload={props.preload}
      className={props.className}
      style={props.style}
    />
  )
}

export default VideoPlayer
