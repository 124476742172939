import { ApiPostType } from "../../modules/shared-modules/experienceManager/types/contentTypes"
import { Post } from "../../modules/shared-modules/experienceManager/types/objectContentTypes"
import apiEnv from "./apiEnv"

export function getLibrary(
  profile: string,
  token: any,
  order: string,
  orderBy: string,
  limit: number,
  search: string,
  cursor?: string
) {
  // when filters are not present, body must not exist
  const endpoint = `${apiEnv()}v1/profiles/${profile}/contentLibrary/content`
  const body = {
    limit,
    order,
    orderBy,
    search: search !== "" ? search : undefined,
    cursor: cursor !== "" ? cursor : undefined,
  }
  const request = new Request(endpoint, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function savePost(profileId: string, token: any, postObj: any, action: string) {
  const body = {
    title: postObj.title,
    picture: postObj.image,
    description: postObj.description,
    message: postObj.message,
    external_link: postObj.url,
    experience_order: postObj.experience_order !== "" ? postObj.experience_order : null,
    profileTags: postObj.profileTags,
  }
  const endpoint = `${apiEnv()}v1/profiles/${profileId}/content/posts`
  const request = new Request(endpoint, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function validContentPagePostUrl(
  title: string,
  postId: string,
  contentPageId: string,
  profileId: string,
  token: any
) {
  const body = {
    title,
    contentPageId,
    postId,
  }
  const endpoint = `${apiEnv()}v1/profiles/${profileId}/content/validatePageUrl`
  const request = new Request(endpoint, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function setDefaultPostToTest(objectRootId: string, post: Post, profileId: string, token: any) {
  const endpoint = `${apiEnv()}v1/profiles/${profileId}/pages/root/${objectRootId}/defaultPost/${post.sourceId}`
  const request = new Request(endpoint, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function getPostsToTest(objectRootId: string, profileId: string, token: any) {
  const endpoint = `${apiEnv()}v1/profiles/${profileId}/pages/root/${objectRootId}/posts`
  const request = new Request(endpoint, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function getPreviewContentPagePost(hostId: string, pageUri: string, slug: string) {
  const endpoint = `${apiEnv()}v1/contentPageContent`
  const body = {
    hostId,
    pageUri,
    slug,
  }
  const request = new Request(endpoint, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function getPublicContentPagePost(postId?: string, url?: string) {
  const endpoint = `${apiEnv()}v1/contentPageContent`
  let body = {}
  if (postId) {
    body = { postId }
  } else if (url) {
    body = { url }
  }
  const request = new Request(endpoint, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function detachContentPagePost(objectRootId: string, profile: string, token: any) {
  const endpoint = `${apiEnv()}v1/profiles/${profile}/pages/root/${objectRootId}/defaultPost/`
  const request = new Request(endpoint, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function contentPageListByPost(sourceId: string, profile: string, token: any) {
  const endpoint = `${apiEnv()}v1/profiles/${profile}/content/posts/${sourceId}/contentPages`
  const request = new Request(endpoint, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function fetchContentPageListByWorkspace(profile: string, token: any) {
  const endpoint = `${apiEnv()}v1/profiles/${profile}/contentPages`
  const request = new Request(endpoint, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      throw response
    })
    .catch((error) => {
      throw error
    })
}

export function createPost(post: any, profileId: string, token: any) {
  const urlApi = `${apiEnv()}v1/profiles/${profileId}/content2/posts`
  const body = {
    title: post.title,
    picture: post.image,
    cover: post.cover,
    description: post.description,
    message: post.message,
    external_link: post.url,
    experience_order: post.experience_order !== "" ? post.experience_order : null,
    profileTags: post.profileTags,
    contentPage: post.contentPage,
  }
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response) => {
        throw response
      })
    })
    .catch((error) => {
      throw error
    })
}

export function updatePost(post: any, profileId: string, token: any) {
  const urlApi = `${apiEnv()}v1/profiles/${profileId}/content2/posts/${post.sourceId}`
  const body = {
    title: post.title,
    picture: post.image,
    cover: post.cover,
    description: post.description,
    message: post.message,
    external_link: post.url,
    experience_order: post.experience_order !== "" ? post.experience_order : null,
    profileTags: post.profileTags,
    contentPage: post.contentPage,
  }
  const request = new Request(urlApi, {
    method: "PATCH",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response) => {
        throw response
      })
    })
    .catch((error) => {
      throw error
    })
}

export function updatePostWithSchema(postBody: ApiPostType, postId: string, profileId: string, token: any) {
  const urlApi = `${apiEnv()}v1/profiles/${profileId}/content3/posts/${postId}`
  const request = new Request(urlApi, {
    method: "PATCH",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(postBody),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response) => {
        throw response
      })
    })
    .catch((error) => {
      throw error
    })
}
