import React from 'react'
import { getContent, getStringField } from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import WarningSystem from "../../../../../../modules/shared-modules/experienceManager/warningSystem"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { CardExpandContentV2Props } from "."
import cardStyles from "../../../../../../stylesheets/modules/components/card.module.sass"
import cardsStyles from "../../../../../../stylesheets/modules/templates/cards.module.sass"
import {
  BobBackground,
  isBobBackground,
} from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const CardExpandContentV2: React.FC<CardExpandContentV2Props> = (props) => {
  const renderCards = (background1Template: BobBackground) => {
    const content = getContent(props.obj, "posts", props.overrides)
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: any, idx: number) => {
        const columns = handleColumns(
          idx,
          counter,
          background1Template,
          props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        counter = columns.counter
        const CARD = (
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            post={post}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            field={"background2"}
            rules={props.componentRule}
            template={props.obj.styles}
            expManager={props.expManager}
            className={`card ${props.itemIsActive}`}
          >
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              replacedMediaList={props.replacedMediaList}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              inlineContent={props.obj.content.inlineContent}
              overrides={props.overrides}
              field='media1'
              template={props.obj.styles}
              rules={props.componentRule}
              expManager={props.expManager}
              network={post.network}
              post={post}
            />
            <div className='card-body'>
              <BobComponentHandler
                position={props.position}
                pageResponse={props.pageResponse}
                instanceId={props.obj.selectedInstanceId}
                object={props.obj}
                objectId={props.obj.uuid}
                post={post}
                inlineContent={props.obj.content.inlineContent}
                overrides={props.overrides}
                field='text1'
                tag='p'
                value={getStringField(post, props.obj, props.pageResponse, "text1", props.overrides)}
                template={props.obj.styles}
                rules={props.componentRule}
                expManager={props.expManager}
                network={post.network}
                className='title'
              />
              <BobComponentHandler
                position={props.position}
                pageResponse={props.pageResponse}
                instanceId={props.obj.selectedInstanceId}
                object={props.obj}
                objectId={props.obj.uuid}
                post={post}
                inlineContent={props.obj.content.inlineContent}
                overrides={props.overrides}
                field='text2'
                tag='p'
                value={getStringField(post, props.obj, props.pageResponse, "text2", props.overrides)}
                template={props.obj.styles}
                rules={props.componentRule}
                expManager={props.expManager}
                network={post.network}
                className='preview'
              />
              <BobComponentHandler
                position={props.position}
                pageResponse={props.pageResponse}
                instanceId={props.obj.selectedInstanceId}
                object={props.obj}
                objectId={props.obj.uuid}
                post={post}
                inlineContent={props.obj.content.inlineContent}
                overrides={props.overrides}
                field='text3'
                tag='p'
                value={getStringField(post, props.obj, props.pageResponse, "text3", props.overrides)}
                template={props.obj.styles}
                rules={props.componentRule}
                expManager={props.expManager}
                network={post.network}
                className='message'
              />
            </div>
          </BobComponentHandler>
        )
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            {CARD}
          </div>
        )
      })
    } else {
      if (props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Select a formula to show posts' />
          </div>
        )
      }
    }
  }

  /**
   * Responsive Alignment Styles
   */
  const background1 = isBobBackground(props.obj.styles.bobs.background1)
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
  return (
    <section className={`expands cards ${cardsStyles.cards}`}>
      <AlignmentHOC
        className={`card-deck ${cardStyles["card-deck"]} row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderCards(background1)}
      </AlignmentHOC>
    </section>
  )
}


export default CardExpandContentV2