import React, { Fragment } from 'react'
import {
  Modal,
} from 'react-bootstrap';
import {
  getContent,
  getStringField,
  handleContentBlock,
  handleObjectType,
} from "../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../shared-public-page/bob/bobComponentHandler"
import WarningSystem from "../../../../../modules/shared-modules/experienceManager/warningSystem"
import ComponentHandler from "../../../componentHandler"
import { route, handler } from "../../../../../modules/routeCreator"
import { handleColumns, responsiveGuttersClass } from "../../../shared-public-page/bob/bobHandler"
import AlignmentHOC from "../../../shared-public-page/fluxio-components/alignmentHOC/alignmentHOC"
import { alignment } from "../../../../../modules/shared-modules/utilities/conditionalController"
import { CardModalProps, CardModalState } from "./index"
import {
  BobBackground,
  isBobBackground,
  isBobMedia,
  isBobText,
} from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { Post } from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { withRouter } from "next/router"
import cardStyles from "../../../../../stylesheets/modules/components/card.module.sass"
import modalStyles from "../../../../../stylesheets/modules/components/modal.module.sass"
import cardsStyles from "../../../../../stylesheets/modules/templates/cards.module.sass"
import cardModalStyles from "../../../../../stylesheets/modules/templates/cardModal.module.sass"

class CardModal extends React.Component<CardModalProps, CardModalState> {
  constructor(props: CardModalProps) {
    super(props)
    this.state = {
      showModal: false,
      modalPost: undefined,
      hasChilds: this.hasChilds(props),
    }
    this.handleModal = this.handleModal.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps: CardModalProps) {
    if (
      (!this.props.obj.children && nextProps.obj.children && nextProps.obj.children.length) ||
      (this.props.obj.children &&
        nextProps.obj.children &&
        this.props.obj.children.length !== nextProps.obj.children.length)
    ) {
      this.setState(
        {
          hasChilds: this.hasChilds(nextProps),
          modalPost: this.handleModalFromUrl(nextProps),
        },
        () => {
          if (this.state.modalPost) this.setState({ ...this.state, showModal: true })
        }
      )
    }
  }

  hasChilds(props: any) {
    let childs = props.obj.children
    return childs && childs.length
  }

  handleModalFromUrl(props: CardModalProps) {
    if (!this.props.expManager.enable) {
      let activePost = props.obj.children.filter((objectId: string) => {
        const Uri = this.props.pageResponse.objects[objectId].uri
        const starsWith = route(this.props.matchOrder, this.props.router)
        if (Uri && starsWith && typeof starsWith === "string") return Uri.startsWith(starsWith)
        return false
      })
      return activePost[0]
    }
  }

  handleModal(post: any) {
    this.setState(
      {
        showModal: !this.state.showModal,
        modalPost: post,
      },
      () => {
        if (!this.props.expManager.enable) {
          if (this.state.showModal && post)
            this.props.router.push(handler(this.props.router, this.props.matchOrder, post.slug))
          else this.props.router.push(handler(this.props.router, this.props.matchOrder, ""))
        }
      }
    )
  }

  onMouseEnter(e: any) {
    if (!e || !e.target) return
    const container = e.target
    const childImage = container.querySelector(".card-modal-image")
    const childTitle = container.querySelector(".title")
    const childPreview = container.querySelector(".preview")
    const childMessage = container.querySelector(".message")
    const Media1 = isBobMedia(this.props.obj.styles.bobs.media1)
    const Text1 = isBobText(this.props.obj.styles.bobs.text1)
    const Text2 = isBobText(this.props.obj.styles.bobs.text2)
    const Text3 = isBobText(this.props.obj.styles.bobs.text3)
    if (Media1.behaviour.hover.enable && childImage) childImage.classList.add("isHover")
    if (Text1.behaviour.hover.enable && childTitle) childTitle.classList.add("isHover")
    if (Text2.behaviour.hover.enable && childPreview) childPreview.classList.add("isHover")
    if (Text3.behaviour.hover.enable && childMessage) childMessage.classList.add("isHover")
  }

  onMouseLeave(e: any) {
    if (!e || !e.target) return
    const container = e.target
    const childImage = container.querySelector(".card-modal-image")
    const childTitle = container.querySelector(".title")
    const childPreview = container.querySelector(".preview")
    const childMessage = container.querySelector(".message")
    const Media1 = isBobMedia(this.props.obj.styles.bobs.media1)
    const Text1 = isBobText(this.props.obj.styles.bobs.text1)
    const Text2 = isBobText(this.props.obj.styles.bobs.text2)
    const Text3 = isBobText(this.props.obj.styles.bobs.text3)
    if (Media1.behaviour.hover.enable && childImage) childImage.classList.remove("isHover")
    if (Text1.behaviour.hover.enable && childTitle) childTitle.classList.remove("isHover")
    if (Text2.behaviour.hover.enable && childPreview) childPreview.classList.remove("isHover")
    if (Text3.behaviour.hover.enable && childMessage) childMessage.classList.remove("isHover")
  }

  renderModalComponents(post: Post) {
    const componentsId = this.props.obj.children
    if (componentsId && componentsId.length > 0)
      return componentsId.map((componentId, idx) => {
        const handledItem = handleObjectType(
          this.props.obj,
          componentId,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        return (
          <ComponentHandler
            position={idx}
            selectedInstanceId={this.props.obj.selectedInstanceId}
            pageResponse={this.props.pageResponse}
            matchOrder={this.props.matchOrder}
            expManager={this.props.expManager}
            key={idx}
            component={handleContentBlock(
              handledItem,
              post,
              this.props.obj,
              this.props.pageResponse,
              this.props.overrides
            )}
          />
        )
      })
  }

  renderModal() {
    const isOpen = this.state.showModal && this.state.modalPost ? true : false
    return (
      <Modal
        className={`modal-fluxio ${modalStyles["modal-fluxio"]} ${cardModalStyles["modal-fluxio"]} ${
          cardModalStyles["card_modal_ssr"]
        } ${!this.state.hasChilds ? `isFeed ${cardModalStyles.isFeed}` : ""}`}
        show={isOpen}
        onHide={() => this.handleModal}
        backdropClassName={`modal-component-backdrop ${cardModalStyles["modal_ssr_backdrop"]}`}
        size='xl'
        enforceFocus={false}
        backdrop
      >
        <BobComponentHandler
          position={this.props.position}
          pageResponse={this.props.pageResponse}
          instanceId={this.props.obj.selectedInstanceId}
          object={this.props.obj}
          objectId={this.props.obj.uuid}
          post={this.state.modalPost}
          inlineContent={this.props.obj.content.inlineContent}
          overrides={this.props.overrides}
          field={"background3"}
          rules={this.props.componentRule}
          template={this.props.obj.styles}
          expManager={this.props.expManager}
          className='modal-container'
          flexDirection='column'
        >
          {this.state.modalPost ? (
            <Fragment>
              <Modal.Header closeButton={false}>
                <BobComponentHandler
                  position={this.props.position}
                  pageResponse={this.props.pageResponse}
                  instanceId={this.props.obj.selectedInstanceId}
                  object={this.props.obj}
                  objectId={this.props.obj.uuid}
                  field='icon1'
                  template={this.props.obj.styles}
                  rules={this.props.componentRule}
                  expManager={this.props.expManager}
                  iconClassName='fas fa-times aaa'
                  className={`modal-close`}
                  onClick={this.handleModal}
                />
              </Modal.Header>
              <Modal.Body>
                {this.state.hasChilds ? (
                  this.renderModalComponents(this.state.modalPost)
                ) : (
                  <Fragment>
                    <BobComponentHandler
                      position={this.props.position}
                      pageResponse={this.props.pageResponse}
                      instanceId={this.props.obj.selectedInstanceId}
                      object={this.props.obj}
                      objectId={this.props.obj.uuid}
                      field='image3'
                      template={this.props.obj.styles}
                      rules={this.props.componentRule}
                      expManager={this.props.expManager}
                      network={this.state.modalPost.network}
                      post={this.state.modalPost}
                      className={`modal-close`}
                    />
                    <BobComponentHandler
                      position={this.props.position}
                      pageResponse={this.props.pageResponse}
                      instanceId={this.props.obj.selectedInstanceId}
                      object={this.props.obj}
                      objectId={this.props.obj.uuid}
                      post={this.state.modalPost}
                      inlineContent={this.props.obj.content.inlineContent}
                      field='text4'
                      tag='h1'
                      value={getStringField(
                        this.state.modalPost,
                        this.props.obj,
                        this.props.pageResponse,
                        "text4",
                        this.props.overrides
                      )}
                      template={this.props.obj.styles}
                      rules={this.props.componentRule}
                      expManager={this.props.expManager}
                      network={this.state.modalPost.network}
                      className='title'
                    />
                    <BobComponentHandler
                      position={this.props.position}
                      pageResponse={this.props.pageResponse}
                      instanceId={this.props.obj.selectedInstanceId}
                      object={this.props.obj}
                      objectId={this.props.obj.uuid}
                      post={this.state.modalPost}
                      inlineContent={this.props.obj.content.inlineContent}
                      overrides={this.props.overrides}
                      field='text5'
                      tag='p'
                      value={getStringField(
                        this.state.modalPost,
                        this.props.obj,
                        this.props.pageResponse,
                        "text5",
                        this.props.overrides
                      )}
                      template={this.props.obj.styles}
                      rules={this.props.componentRule}
                      expManager={this.props.expManager}
                      network={this.state.modalPost.network}
                      className='description'
                    />
                  </Fragment>
                )}
              </Modal.Body>
            </Fragment>
          ) : (
            []
          )}
        </BobComponentHandler>
      </Modal>
    )
  }

  renderCards(content: any[], background1TemplateMerged: BobBackground) {
    if (this.state.hasChilds) content = [content[0]]
    let counter = { desktop: 0, tablet: 0, mobile: 0 }
    if (content && content.length > 0) {
      return content.map((post: Post, idx: number) => {
        const columns = handleColumns(
          idx,
          counter,
          background1TemplateMerged,
          this.props.pageResponse.globalStyles[background1TemplateMerged.globalStyleId]
        )
        counter = columns.counter
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              post={post}
              inlineContent={this.props.obj.content.inlineContent}
              overrides={this.props.overrides}
              field={"background2"}
              rules={this.props.componentRule}
              template={this.props.obj.styles}
              expManager={this.props.expManager}
              className='card'
              onClick={() => this.handleModal(post)}
              onMouseEnter={(e: any) => this.onMouseEnter(e)}
              onMouseLeave={(e: any) => this.onMouseLeave(e)}
            >
              <BobComponentHandler
                position={this.props.position}
                pageResponse={this.props.pageResponse}
                replacedMediaList={this.props.replacedMediaList}
                instanceId={this.props.obj.selectedInstanceId}
                object={this.props.obj}
                objectId={this.props.obj.uuid}
                inlineContent={this.props.obj.content.inlineContent}
                overrides={this.props.overrides}
                field='media1'
                template={this.props.obj.styles}
                rules={this.props.componentRule}
                expManager={this.props.expManager}
                network={post.network}
                post={post}
                className='card-modal-image'
              />
              <BobComponentHandler
                position={this.props.position}
                pageResponse={this.props.pageResponse}
                instanceId={this.props.obj.selectedInstanceId}
                object={this.props.obj}
                objectId={this.props.obj.uuid}
                post={post}
                inlineContent={this.props.obj.content.inlineContent}
                overrides={this.props.overrides}
                field={"background4"}
                rules={this.props.componentRule}
                template={this.props.obj.styles}
                expManager={this.props.expManager}
                className='card-body'
                flexDirection='column'
              >
                <BobComponentHandler
                  position={this.props.position}
                  pageResponse={this.props.pageResponse}
                  instanceId={this.props.obj.selectedInstanceId}
                  object={this.props.obj}
                  objectId={this.props.obj.uuid}
                  inlineContent={this.props.obj.content.inlineContent}
                  overrides={this.props.overrides}
                  field='text1'
                  tag='p'
                  value={getStringField(post, this.props.obj, this.props.pageResponse, "text1", this.props.overrides)}
                  template={this.props.obj.styles}
                  rules={this.props.componentRule}
                  expManager={this.props.expManager}
                  network={post.network}
                  className='title'
                />
                <BobComponentHandler
                  position={this.props.position}
                  pageResponse={this.props.pageResponse}
                  instanceId={this.props.obj.selectedInstanceId}
                  object={this.props.obj}
                  objectId={this.props.obj.uuid}
                  inlineContent={this.props.obj.content.inlineContent}
                  overrides={this.props.overrides}
                  field='text2'
                  tag='p'
                  value={getStringField(post, this.props.obj, this.props.pageResponse, "text2", this.props.overrides)}
                  template={this.props.obj.styles}
                  rules={this.props.componentRule}
                  expManager={this.props.expManager}
                  network={post.network}
                  className='preview'
                />
                <BobComponentHandler
                  position={this.props.position}
                  pageResponse={this.props.pageResponse}
                  instanceId={this.props.obj.selectedInstanceId}
                  object={this.props.obj}
                  objectId={this.props.obj.uuid}
                  inlineContent={this.props.obj.content.inlineContent}
                  overrides={this.props.overrides}
                  field='text3'
                  tag='p'
                  value={getStringField(post, this.props.obj, this.props.pageResponse, "text3", this.props.overrides)}
                  template={this.props.obj.styles}
                  rules={this.props.componentRule}
                  expManager={this.props.expManager}
                  network={post.network}
                  className='message'
                />
              </BobComponentHandler>
            </BobComponentHandler>
          </div>
        )
      })
    } else {
      if (this.props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Select a formula to show posts' />
          </div>
        )
      }
    }
  }

  render() {
    const background1 = isBobBackground(this.props.obj.styles.bobs.background1)
    const background2 = isBobBackground(this.props.obj.styles.bobs.background2)
    /**
     * Responsive Alignment Styles
     */
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

    const content = getContent(this.props.obj, "posts", this.props.overrides)
    return (
      <section className={`cards ${cardsStyles.cards} card-modal ${cardModalStyles["card-modal"]}`}>
        <AlignmentHOC
          className={`row card-deck ${cardStyles["card-deck"]} ${cardModalStyles["card-deck"]} ${responsiveGuttersClass(
            background2,
            this.props.pageResponse.globalStyles[background2.globalStyleId]
          )}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {this.renderCards(content, background1)}
        </AlignmentHOC>
        {this.renderModal()}
      </section>
    )
  }
}


export default withRouter(CardModal)