import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  number as numberC,
  string as stringC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */

// props only, no enable
const ShadowPropsCodec = type({
  inset: booleanC,
  offsetH: numberC,
  offsetV: numberC,
  spread: numberC,
  blur: numberC,
  color: stringC,
})
const ShadowCodec = intersection([type({ enable: booleanC }), ShadowPropsCodec])

// props only, no enable
const ShadowPropsOptCodec = partial({
  inset: nullable(booleanC),
  offsetH: nullable(numberC),
  offsetV: nullable(numberC),
  spread: nullable(numberC),
  blur: nullable(numberC),
  color: nullable(stringC),
})
const ShadowOptCodec = intersection([partial({ enable: nullable(booleanC) }), ShadowPropsOptCodec])

const StylesShadowCodec = intersection([
  type({ shadow: ShadowCodec }),
  partial({
    behaviour: partial({
      active: partial({
        shadow: ShadowOptCodec,
      }),
      hover: partial({
        shadow: ShadowOptCodec,
      }),
    }),
    mobile: partial({
      shadow: ShadowOptCodec,
      behaviour: partial({
        active: partial({
          shadow: ShadowOptCodec,
        }),
        hover: partial({
          shadow: ShadowOptCodec,
        }),
      }),
    }),
    tablet: partial({
      shadow: ShadowOptCodec,
      behaviour: partial({
        active: partial({
          shadow: ShadowOptCodec,
        }),
        hover: partial({
          shadow: ShadowOptCodec,
        }),
      }),
    }),
  }),
])

const GSShadowCodec = intersection([
  ShadowPropsCodec,
  partial({
    behaviour: partial({
      active: ShadowPropsOptCodec,
      hover: ShadowPropsOptCodec,
    }),
    mobile: intersection([
      ShadowPropsOptCodec,
      partial({
        behaviour: partial({
          active: ShadowPropsOptCodec,
          hover: ShadowPropsOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      ShadowPropsOptCodec,
      partial({
        behaviour: partial({
          active: ShadowPropsOptCodec,
          hover: ShadowPropsOptCodec,
        }),
      }),
    ]),
  }),
])

type Shadow = TypeOf<typeof ShadowCodec>
type ShadowProps = TypeOf<typeof ShadowPropsCodec>
type ShadowOpt = TypeOf<typeof ShadowOptCodec>
type ShadowPropsOpt = TypeOf<typeof ShadowPropsOptCodec>
type StylesShadow = TypeOf<typeof StylesShadowCodec>
type GSShadow = TypeOf<typeof GSShadowCodec>
type ShadowType = "box" | "text"
type ShadowCSS = { "box-shadow": string } | { "text-shadow": string }
type Scroll = {
  value: "static" | "fixed" | "sticky"
  enable: boolean
}

export function boxShadowCss(css: ShadowCSS | ""): string {
  if (css.hasOwnProperty("box-shadow")) return (css as { "box-shadow": string })["box-shadow"]
  return ""
}

export function textShadowCss(css: ShadowCSS | ""): string {
  if (css.hasOwnProperty("text-shadow")) return (css as { "text-shadow": string })["text-shadow"]
  return ""
}

export function responsiveStyle(
  shadowObj: any,
  optionalProperties: any,
  desktopDefaultValues?: any,
  breakpointDefaultValues?: any
) {
  const hasEnable = shadowObj.hasOwnProperty("enable")
  if (hasEnable && !objValueExists(shadowObj, "enable")) return ""

  /**
   * Check if property is available, and fallback to breakpointDefault or the desktopDefault
   */
  const inset = shadowObj?.inset || breakpointDefaultValues?.inset || desktopDefaultValues?.inset
  const offsetH = shadowObj?.offsetH || breakpointDefaultValues?.offsetH || desktopDefaultValues?.offsetH
  const offsetV = shadowObj?.offsetV || breakpointDefaultValues?.offsetV || desktopDefaultValues?.offsetV
  const blur = shadowObj?.blur || breakpointDefaultValues?.blur || desktopDefaultValues?.blur
  const color = shadowObj?.color || breakpointDefaultValues?.color || desktopDefaultValues?.color
  const spread = shadowObj?.spread || breakpointDefaultValues?.spread || desktopDefaultValues?.spread
  // other props like text-shadow
  if (optionalProperties && optionalProperties.shadowType) {
    if (hasEnable && !shadowObj.enable)
      return {
        [`${optionalProperties.shadowType}-shadow`]: "none !important",
      }
    return {
      [`${optionalProperties.shadowType}-shadow`]: `${
        inset ? "inset " : ""
      }${offsetH}px ${offsetV}px ${blur}px ${color} !important`,
    }
  }
  // box shadow prop
  if (hasEnable && !shadowObj.enable) return { "box-shadow": "none !important" }
  return {
    "box-shadow": `${inset ? "inset " : ""}${offsetH}px ${offsetV}px ${blur}px ${spread}px ${color} !important`,
  }
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  shadowType: ShadowType,
  foundationStyle: FColor | undefined = undefined // TODO: fix when working on other bobs
): ShadowCSS | "" {
  const styles = StylesShadowCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, shadowType, foundationStyle)
  console.warn(decoderErrors(styles))
  return ""
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  shadowType: ShadowType,
  foundationStyle: FColor | undefined = undefined // TODO: Work on when foundation is added to global styles
): ShadowCSS | "" {
  const gs = GSShadowCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, shadowType, foundationStyle)
  console.warn(decoderErrors(gs))
  return ""
}

export function zIndexFix(
  desktopDefaultShadowEnable: boolean,
  desktopDefaultScroll: Scroll,
  objPosition?: number
): { "z-index": string; position: string } | "" {
  if (desktopDefaultShadowEnable === false) {
    return ""
  }
  if (typeof objPosition === "number") {
    const defaultZIndex = 20
    const computedZIndex = defaultZIndex - objPosition
    const cssPosition =
      (desktopDefaultScroll.enable && desktopDefaultScroll.value === "static") || !desktopDefaultScroll.enable
        ? "relative"
        : desktopDefaultScroll.value
    if (computedZIndex <= 0) {
      return {
        "z-index": "1 !important",
        position: `${cssPosition} !important`,
      }
    }
    return {
      "z-index": `${computedZIndex} !important`,
      position: `${cssPosition} !important`,
    }
  }
  return ""
}

export function cssRender(
  stylesObj: StylesShadow,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  shadowType: ShadowType,
  foundationStyle: FColor | undefined
): ShadowCSS {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.shadow, shadowType, foundationStyle)
    }
    //hover | active
    else {
      return renderBob(
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.shadow, stylesObj.shadow),
        shadowType,
        foundationStyle
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBob(mergeBob2(stylesObj?.[breakpoint]?.shadow, stylesObj.shadow), shadowType, foundationStyle)
    }
    //hover | active
    else {
      return renderBob(
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.shadow,
          stylesObj?.behaviour?.[behaviourState]?.shadow,
          stylesObj?.[breakpoint]?.shadow,
          stylesObj.shadow
        ),
        shadowType,
        foundationStyle
      )
    }
  }
}

export function globalStyleCssRender(
  stylesObj: GSShadow,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  shadowType: ShadowType,
  foundationStyle: FColor | undefined
): ShadowCSS {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj, shadowType, foundationStyle)
    }
    //hover | active
    else {
      return render(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj), shadowType, foundationStyle)
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return render(merge2(stylesObj?.[breakpoint], stylesObj), shadowType, foundationStyle)
    }
    //hover | active
    else {
      return render(
        merge3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
          stylesObj.behaviour?.[behaviourState],
          stylesObj?.[breakpoint],
          stylesObj
        ),
        shadowType,
        foundationStyle
      )
    }
  }
}

export function renderBob(shadowObj: Shadow, shadowType: ShadowType, foundationStyle: FColor | undefined): ShadowCSS {
  if (!shadowObj.enable) return cssProperty("none !important", shadowType)

  return render(shadowObj, shadowType, foundationStyle)
}

export function render(shadowObj: ShadowProps, shadowType: ShadowType, foundationStyle: FColor | undefined): ShadowCSS {
  const isBox = shadowType === "box" ? ` ${shadowObj.spread}px` : ""
  const cssValue = `${shadowObj.inset ? "inset " : ""}${shadowObj.offsetH}px ${shadowObj.offsetV}px ${
    shadowObj.blur
  }px${isBox} ${shadowObj.color} !important`
  // TODO: foundation color reverted
  // }px${isBox} ${foundationStyle ? foundationStyle.color : shadowObj.color} !important`
  return cssProperty(cssValue, shadowType)
}

export function cssProperty(cssValue: string, shadowType: ShadowType): ShadowCSS {
  return shadowType === "box" ? { "box-shadow": cssValue } : { "text-shadow": cssValue }
}

/**
 *
 * @param shadowObj
 * @param defaultShadowObj
 * @returns Shadow
 *
 */
export function mergeBob2(shadowObj: ShadowOpt | undefined, defaultShadowObj: Shadow): Shadow {
  const enable = get2WithNull(shadowObj?.enable, defaultShadowObj.enable)
  const inset = get2WithNull(shadowObj?.inset, defaultShadowObj.inset)
  const offsetH = get2WithNull(shadowObj?.offsetH, defaultShadowObj.offsetH)
  const offsetV = get2WithNull(shadowObj?.offsetV, defaultShadowObj.offsetV)
  const blur = get2WithNull(shadowObj?.blur, defaultShadowObj.blur)
  const color = get2WithNull(shadowObj?.color, defaultShadowObj.color)
  const spread = get2WithNull(shadowObj?.spread, defaultShadowObj.spread)

  return {
    enable,
    inset,
    offsetH,
    offsetV,
    blur,
    color,
    spread,
  }
}

/**
 *
 * @param shadowObj
 * @param defaultShadowObj
 * @returns ShadowProps
 *
 */
export function merge2(shadowObj: ShadowPropsOpt | undefined, defaultShadowObj: ShadowProps): ShadowProps {
  const inset = get2WithNull(shadowObj?.inset, defaultShadowObj.inset)
  const offsetH = get2WithNull(shadowObj?.offsetH, defaultShadowObj.offsetH)
  const offsetV = get2WithNull(shadowObj?.offsetV, defaultShadowObj.offsetV)
  const blur = get2WithNull(shadowObj?.blur, defaultShadowObj.blur)
  const color = get2WithNull(shadowObj?.color, defaultShadowObj.color)
  const spread = get2WithNull(shadowObj?.spread, defaultShadowObj.spread)

  return {
    inset,
    offsetH,
    offsetV,
    blur,
    color,
    spread,
  }
}

/**
 *
 * @param shadowObj
 * @param shadowDefaultBreakpoint
 * @param defaultShadowObj
 * @returns Shadow
 */
export function mergeBob3(
  shadowObj: ShadowOpt | undefined,
  shadowDesktopBehaviour: ShadowOpt | undefined,
  shadowDefaultBreakpoint: ShadowOpt | undefined,
  defaultShadowObj: Shadow
): Shadow {
  const enable =
    shadowObj?.enable ?? shadowDesktopBehaviour?.enable ?? shadowDefaultBreakpoint?.enable ?? defaultShadowObj.enable
  const inset =
    shadowObj?.inset ?? shadowDesktopBehaviour?.inset ?? shadowDefaultBreakpoint?.inset ?? defaultShadowObj.inset
  const offsetH =
    shadowObj?.offsetH ??
    shadowDesktopBehaviour?.offsetH ??
    shadowDefaultBreakpoint?.offsetH ??
    defaultShadowObj.offsetH
  const offsetV =
    shadowObj?.offsetV ??
    shadowDesktopBehaviour?.offsetV ??
    shadowDefaultBreakpoint?.offsetV ??
    defaultShadowObj.offsetV
  const blur = shadowObj?.blur ?? shadowDesktopBehaviour?.blur ?? shadowDefaultBreakpoint?.blur ?? defaultShadowObj.blur
  const color =
    shadowObj?.color ?? shadowDesktopBehaviour?.color ?? shadowDefaultBreakpoint?.color ?? defaultShadowObj.color
  const spread =
    shadowObj?.spread ?? shadowDesktopBehaviour?.spread ?? shadowDefaultBreakpoint?.spread ?? defaultShadowObj.spread

  return {
    enable,
    inset,
    offsetH,
    offsetV,
    blur,
    color,
    spread,
  }
}

/**
 *
 * @param shadowObj
 * @param shadowDefaultBreakpoint
 * @param defaultShadowObj
 * @returns Shadow
 */
export function merge3(
  shadowObj: ShadowPropsOpt | undefined,
  shadowDesktopBehaviour: ShadowPropsOpt | undefined,
  shadowDefaultBreakpoint: ShadowPropsOpt | undefined,
  defaultShadowObj: ShadowProps
): ShadowProps {
  const inset =
    shadowObj?.inset ?? shadowDesktopBehaviour?.inset ?? shadowDefaultBreakpoint?.inset ?? defaultShadowObj.inset
  const offsetH =
    shadowObj?.offsetH ??
    shadowDesktopBehaviour?.offsetH ??
    shadowDefaultBreakpoint?.offsetH ??
    defaultShadowObj.offsetH
  const offsetV =
    shadowObj?.offsetV ??
    shadowDesktopBehaviour?.offsetV ??
    shadowDefaultBreakpoint?.offsetV ??
    defaultShadowObj.offsetV
  const blur = shadowObj?.blur ?? shadowDesktopBehaviour?.blur ?? shadowDefaultBreakpoint?.blur ?? defaultShadowObj.blur
  const color =
    shadowObj?.color ?? shadowDesktopBehaviour?.color ?? shadowDefaultBreakpoint?.color ?? defaultShadowObj.color
  const spread =
    shadowObj?.spread ?? shadowDesktopBehaviour?.spread ?? shadowDefaultBreakpoint?.spread ?? defaultShadowObj.spread

  return {
    inset,
    offsetH,
    offsetV,
    blur,
    color,
    spread,
  }
}

//Fallback when not declared
export function get2<A>(x: A | undefined, y: A): A {
  return typeof x === "undefined" ? y : x
}

//Temp with nulls
export function get2WithNull<A>(x: A | null | undefined, y: A): A {
  return typeof x === "undefined" || x === null ? y : x
}

export type { ShadowCSS, StylesShadow, GSShadow, ShadowProps, ShadowPropsOpt, Shadow, ShadowOpt }
