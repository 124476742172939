import React, { useState } from 'react'
import { handleBackgroundStyles, handleTextStyles } from "../../../../bob/bobFormComponents"
// @ts-ignore
import Style from "style-it"
import BobComponentHandler from "../../../../bob/bobComponentHandler"
import {
  getOverrideInlineContentValues,
  getStringField,
} from "../../../../../../../modules/shared-modules/utilities/components"
import styled from "styled-components"
import { responsiveProperties, responsiveGroupStylesProperties } from "../../../../bob/bobHandler"
import { CheckboxInputProps } from "./interface"
import { ResponsiveCSSProps } from "../../interface"
import { CONTENT_SRC_TEXT } from "../../../../../../../modules/shared-modules/experienceManager/finder/content/inlineContent/inlineContentTypes"
import { conditionalColorsRenderUnsafe as cssRenderUnsafeColors } from "../../../../../../../modules/shared-modules/utilities/conditionalController"
import { cssRenderUnsafe as cssRenderUnsafeColor } from "../../../../bob/bobStyles/colorStyle"

const CheckboxInputLabel = styled.div`
  ${(props: ResponsiveCSSProps) => props.desktop}
  &:after {
    ${(props: ResponsiveCSSProps) => props.desktop.background}
  }
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: ResponsiveCSSProps) => props.tablet}
    &:after {
      ${(props: ResponsiveCSSProps) => props.tablet.background}
    }
  }
  @media all and (max-width: 766px) {
    ${(props: ResponsiveCSSProps) => props.mobile}
    &:after {
      ${(props: ResponsiveCSSProps) => props.mobile.background}
    }
  }
`

const CheckboxInput: React.FC<CheckboxInputProps> = (props): JSX.Element => {
  const [value, setValue] = useState<{ value: boolean; apiValue: string }>({
    value: false,
    apiValue: "false",
  })

  const handleValueChange = (value: boolean) => {
    let newApiValue = value ? "true" : "false"
    setValue({ value: value, apiValue: newApiValue })
    props.handleValueChange(newApiValue)
  }

  const renderLabel = () => {
    if (!props.bobText || !props.bobText.enable) return props.inputObj.label || props.inputObj.name

    if (
      getOverrideInlineContentValues(`text3.${CONTENT_SRC_TEXT}`, {
        inlineContent: props.obj.content.inlineContent,
        overrides: props.overrides,
      }) === "post"
    ) {
      return (
        <BobComponentHandler
          position={props.position}
          pageResponse={props.pageResponse}
          instanceId={props.obj.selectedInstanceId}
          object={props.obj}
          objectId={props.obj.uuid}
          inlineContent={props.obj.content.inlineContent}
          overrides={props.overrides}
          field='text3'
          tag='p'
          value={props.inputObj.label || props.inputObj.name} // TODO: fix type
          template={props.obj.styles}
          rules={props.componentRule}
          expManager={props.expManager}
          className='title'
        />
      )
    }

    return (
      <BobComponentHandler
        position={props.position}
        pageResponse={props.pageResponse}
        instanceId={props.obj.selectedInstanceId}
        object={props.obj}
        objectId={props.obj.uuid}
        inlineContent={props.obj.content.inlineContent}
        overrides={props.overrides}
        field='text3'
        tag='p'
        value={getStringField(props.obj as any, props.obj, props.pageResponse, "text1", props.overrides)} // TODO: fix type
        template={props.obj.styles}
        rules={props.componentRule}
        expManager={props.expManager}
        className='title'
      />
    )
  }

  let backgroundStyles: any = handleBackgroundStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles)
  let textStyles: any = handleTextStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles, props.expManager)

  // responsive styles for background
  let mobileStylesBackground = responsiveProperties(props.bobStyle.bobInput, "mobile")
  mobileStylesBackground = {
    ...mobileStylesBackground,
    ...responsiveGroupStylesProperties(props.bobStyle.bobInput, "mobile", "text"),
  }
  let tabletStylesBackground = responsiveProperties(props.bobStyle.bobInput, "tablet")
  tabletStylesBackground = {
    ...tabletStylesBackground,
    ...responsiveGroupStylesProperties(props.bobStyle.bobInput, "tablet", "text"),
  }

  // responsive styles for text
  let mobileStylesText = responsiveProperties(props.bobStyle.text, "mobile", undefined, ["colors"])
  mobileStylesText = {
    ...mobileStylesText,
    ...responsiveGroupStylesProperties(props.bobStyle.text, "mobile", "text"),
    ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "default", true),
    ...cssRenderUnsafeColor(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "mobile",
      "default",
      undefined,
      undefined,
      true
    ),
  }
  let tabletStylesText = responsiveProperties(props.bobStyle.text, "tablet", undefined, ["colors"])
  tabletStylesText = {
    ...tabletStylesText,
    ...responsiveGroupStylesProperties(props.bobStyle.text, "tablet", "text"),
    ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "default", true),
    ...cssRenderUnsafeColor(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "tablet",
      "default",
      undefined,
      undefined,
      true
    ),
  }

  return (
    <Style>
      {`
        ${textStyles.fontLoaded};
      `}
      <div className='custom-control custom-checkbox'>
        <input
          type='checkbox'
          className='custom-control-input'
          id={`formCheckbox-${props.inputObj.id}`}
          checked={value.value}
          onChange={(e: any) => handleValueChange(e.target.checked)}
        />
        <CheckboxInputLabel
          as='label'
          desktop={{ background: backgroundStyles, text: textStyles.styles }}
          mobile={{
            background: mobileStylesBackground,
            text: mobileStylesText,
          }}
          tablet={{
            background: tabletStylesBackground,
            text: tabletStylesText,
          }}
          className='custom-control-label'
          htmlFor={`formCheckbox-${props.inputObj.id}`}
        >
          {renderLabel()}
        </CheckboxInputLabel>
        {props.error && (
          <p className='simple-form-error'>{props.lang === "en" ? "This field is required" : "Campo obrigatório"}</p>
        )}
      </div>
    </Style>
  )
}

export default CheckboxInput