import {
  handleBondaries,
  handleShadow,
  handleRadius,
  dynamicFontLoader,
  handleTextShadow,
  handleVerticalAlign,
  clearFont,
} from "../../../../modules/shared-modules/utilities/components"
import { styles as BobDefaultNodeStyles } from "../../../../modules/shared-modules/constants/bobDefaultNode"
import { BobBackground, BobText } from "../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import cloneDeep from "lodash/cloneDeep"
import { conditionalBorderRenderUnsafe as cssRenderUnsafeBorder } from "../../../../modules/shared-modules/utilities/conditionalController"

export function handleBackgroundImage(templateStyles: any) {
  let url = ``
  if (templateStyles.colors.isGradient)
    url = `linear-gradient(${templateStyles.colors.gradientAngle ? templateStyles.colors.gradientAngle : 0}deg, ${
      templateStyles.colors.colorFirst
    }, ${templateStyles.colors.colorSecond}), url(${templateStyles.image.src})`
  return url
}

export function handleBackgroundStyles(template: BobBackground, globalStyles: any) {
  let backgroundTemplateStyles: any = handleBakgroundGlobalStyles(template, globalStyles)
  const bondariesObj: any = handleBondaries(backgroundTemplateStyles)
  const borders = cssRenderUnsafeBorder(backgroundTemplateStyles, "desktop", "default", true)
  const shadows: any = handleShadow(backgroundTemplateStyles)
  const radiusStyles: any = handleRadius(backgroundTemplateStyles)
  let styles: any = {
    ...bondariesObj,
    ...shadows,
    ...borders,
    ...radiusStyles,
  }
  return {
    height: backgroundTemplateStyles.height.enable
      ? `${backgroundTemplateStyles.height.value}${backgroundTemplateStyles.height.unit} !important`
      : "auto !important",
    ...styles,
    backgroundColor:
      backgroundTemplateStyles.colors.enable && !backgroundTemplateStyles.colors.isGradient
        ? backgroundTemplateStyles.colors.colorFirst + " !important"
        : "transparent !important",
    backgroundImage:
      backgroundTemplateStyles.colors.enable && backgroundTemplateStyles.colors.isGradient
        ? `linear-gradient(${backgroundTemplateStyles.colors.gradientAngle}deg, ${backgroundTemplateStyles.colors.colorFirst}, ${backgroundTemplateStyles.colors.colorSecond})  !important`
        : "",
  }
}

export function handleBakgroundGlobalStyles(template: BobBackground, globalStyles: any) {
  if (!template.globalStyleId || !globalStyles || !globalStyles[template.globalStyleId]) return template

  let clonedBobTemplate = cloneDeep(template)
  clonedBobTemplate.colors = globalStyles[template.globalStyleId].colors
  clonedBobTemplate.border = globalStyles[template.globalStyleId].border
  clonedBobTemplate.shadow = globalStyles[template.globalStyleId].shadow
  clonedBobTemplate.radius = globalStyles[template.globalStyleId].radius
  clonedBobTemplate.image = globalStyles[template.globalStyleId].image
  return clonedBobTemplate
}

export function handleTextStyles(template: BobText, globalStyles: any, expManager: { enable: boolean }) {
  let fontTemplateStyles = handleTextGlobalStyles(template, globalStyles)
  let FONTLOADED = ""
  if (expManager.enable) {
    FONTLOADED = dynamicFontLoader(
      fontTemplateStyles ? fontTemplateStyles : BobDefaultNodeStyles,
      [fontTemplateStyles.fontWeight],
      undefined
    )
  }
  let templateObj = fontTemplateStyles
  let textShadows = handleTextShadow(templateObj)
  let verticalAlign = handleVerticalAlign(templateObj["verticalAlign"])
  let styles: any = { ...textShadows, ...verticalAlign }
  return {
    styles: {
      fontFamily: clearFont(templateObj["fontFamily"]) + " !important",
      fontWeight: templateObj["fontWeight"] + " !important",
      fontSize: templateObj["fontSize"] + "px !important",
      letterSpacing: templateObj["letterSpacing"] + "px !important",
      lineHeight: templateObj["lineHeight"] + " !important",
      textAlign: templateObj["textAlign"] + " !important",
      textTransform: templateObj["textTransform"] + " !important",
      color: templateObj["color"] + " !important",
      ...styles,
    },
    fontLoaded: FONTLOADED,
  }
}

export function handleTextGlobalStyles(template: BobText, globalStyles: any) {
  if (!template.globalStyleId || !globalStyles || !globalStyles[template.globalStyleId]) return template

  let clonedBobTemplate = cloneDeep(template)
  clonedBobTemplate.fontFamily = globalStyles[template.globalStyleId].fontFamily
  clonedBobTemplate.fontWeight = globalStyles[template.globalStyleId].fontWeight
  clonedBobTemplate.fontSize = globalStyles[template.globalStyleId].fontSize
  clonedBobTemplate.letterSpacing = globalStyles[template.globalStyleId].letterSpacing
  clonedBobTemplate.lineHeight = globalStyles[template.globalStyleId].lineHeight
  clonedBobTemplate.color = globalStyles[template.globalStyleId].color
  clonedBobTemplate.tag = globalStyles[template.globalStyleId].tag
  clonedBobTemplate.shadow = globalStyles[template.globalStyleId].shadow

  return clonedBobTemplate
}

export function removeImportantFromStyle(stylesWithImportant: any) {
  for (let key in stylesWithImportant) {
    let style = stylesWithImportant[key]
    stylesWithImportant[key] = style.replace("!important", "")
  }
  return stylesWithImportant
}

export function handlePlaceholderStyles(styles: any) {
  return `
    ::placeholder{
      color: ${styles.color};
      font-size: ${styles.fontSize || styles["font-size"]};
    }
    `
}
