export const BUTTON = 'button'
export const BUTTON_RIGHT = 'button_right'
export const CARD_EXPAND_CONTAINER = 'card_expand_container'
export const CARD_LEFT = 'card_left'
export const CARD_LEFT_V2 = 'card_left_v2'
export const CARD_MODAL = 'card_modal' // deprecated ?
export const CARD_RIGHT = 'card_right'
export const CARD_RIGHT_V2 = 'card_right_v2'
export const CARD_TOP = 'card_top'
export const DROPDOWN = 'dropdown'
export const DROPDOWN_V2 = 'dropdown_v2'
export const EMBED_CONTAINER = 'embed_container'
export const EMBED_CONTAINER_V2 = "embed_container_v2"
export const EXPAND_CONTENT = 'expand_content'
export const EXPAND_CONTENT_V2 = 'expand_content_v2'
export const GRID_CONTAINER = 'grid_container'
export const GRID_CONTAINER_V2 = 'grid_container_v2'
export const CONTAINER = 'container'
export const MEDIA = 'media'
export const MENUCOMPONENT = 'menu'
export const MENUCOMPONENT_V2 = 'menu_v2'
export const MENUTOGGLECOMPONENT = 'menu_toggle'
export const MENUTOGGLECOMPONENTV2 = 'menu_toggle_v2'
export const MODAL = 'modal'
export const MODAL_V2 = 'modal_v2'
export const MODAL_V3 = 'modal_v3'
export const MODAL_V4 = 'modal_v4'
export const NAVBARCOMPONENTV2 = 'navbar_v2'
export const NAVBARCOMPONENT = 'navbar'
export const NAVBARTOP = 'navbar_top'
export const SHOWCASE = 'showcase'
export const SLIDER_CONTAINER = 'slider_container'
export const SLIDER_CONTAINER_V2 = 'slider_container_2'
export const SLIDER_CONTAINER_V3 = 'slider_container_v3'
export const SOCIAL_CARD_EMBED = 'social_card_embed'
export const SOCIAL_MEDIA_EMBED = 'social_media_embed'
export const SOCIAL_CARD_SHARE = 'social_card_share'
export const TABS_CONTAINER = 'tabs_container'
export const TABS_CONTAINER_V2 = "tabs_container_v2"
export const TABS_CONTAINER_DROPDOWN = 'tabs_container_dropdown_v2'
export const TABS_CONTENT = 'tabs_content'
export const TABS_CONTENT_VERTICAL = 'tabs_content_vertical'
export const TABS_CONTENT_V2 = 'tabs_content_v2'
export const TABS_CONTENT_VERTICAL_V2 = 'tabs_content_vertical_v2'
export const TEXT_BLOCK = 'text_block'
export const IMAGE_COMPARATION_SLIDER = 'image_comparison_slider'
export const FORM_CONTAINER = 'simple_form_container'
export const FORM = "form"
export const INPUT = "input"
export const SUBMIT = "submit"
export const RESULTS_MAP = 'results_map'
export const DATASET_CONTAINER = 'dataset_container'
export const RESULTS_LIST_CONTAINER = 'results_list_container'
export const FILTERS = 'filters'
export const GRID = "grid"
export const TEXT = "text"
export const MEDIAV2 = "media_v2"