import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { cssRenderUnsafe as FlexStyleCssRenderUnsafe } from "../bobStyles/flexStyle"
import { cssRenderUnsafe as SelfFlexStyleCssRenderUnsafe } from "../bobStyles/selfFlexStyle"
import { cssRenderUnsafe as HeightStyleCssRenderUnsafe } from "../bobStyles/heightStyleV2"
import { cssRenderUnsafe as MaxHeightStyleCssRenderUnsafe } from "../bobStyles/maxHeightStyle"
import { cssRenderUnsafe as MinHeightStyleCssRenderUnsafe } from "../bobStyles/minHeightStyle"
import { cssRenderUnsafe as WidthStyleCssRenderUnsafe } from "../bobStyles/widthStyleV2"
import { cssRenderUnsafe as MaxWidthStyleCssRenderUnsafe } from "../bobStyles/maxWidthStyle"
import { cssRenderUnsafe as MinWidthStyleCssRenderUnsafe } from "../bobStyles/minWidthStyle"
import { cssRenderUnsafe as ShadowStyleCssRenderUnsafe } from "../bobStyles/shadowStyleV2"
import { cssRenderUnsafe as BorderStyleCssRenderUnsafe } from "../bobStyles/borderStyleV2"
import { cssRenderUnsafe as RadiusStyleCssRenderUnsafe } from "../bobStyles/radiusStyle"
import { cssRenderUnsafe as BoundaryStyleCssRenderUnsafe } from "../bobStyles/boundaryStyleV2"
import { cssRenderUnsafe as BackgroundColorCssRenderUnsafe } from "../bobStyles/colorsStyleV2"
import { cssRenderUnsafe as BackgroundImageCssRenderUnsafe } from "../bobStyles/backgroundImageStyleV2"
import { cssRenderUnsafe as ZIndexCssRenderUnsafe } from "../bobStyles/zindexStyle"
import { cssRenderUnsafe as PositionCssRenderUnsafe } from "../bobStyles/positionStyle"
import { cssRenderUnsafe as cssRenderUnsafeCursor } from "../bobStyles/cursorStyle"
//import { cssRenderUnsafe as BackgroundVideoCssRenderUnsafe } from "../bobStyles/backgroundVideoStyle"
import { MediaObject } from "../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import {
  getMediaStringField,
  getOverrideInlineContentValues,
} from "../../../../../modules/shared-modules/utilities/components"
import { handleSrcLink } from "../../../../../modules/shared-modules/mediaLibrary/mediaLibraryUtils"
import { DummyPost } from "../../../../../modules/shared-modules/experienceManager/finder/content/contentPageHelper"
import {
  CMS,
  CONTENT_PAGE,
  WRITE,
} from "../../../../skynet/experienceManager/finder/inputs/contentSrcController/contentSrcHelper"
import {
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { mergeMedias } from "../bobHandlerV2"
import { BobFlex } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { PageStylesheet } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import {
  handleBobBorderLabel,
  handleBobColorsLabel,
  handleBobShadowLabel,
} from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

const responsiveStyles = (
  styles: BobFlex,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  imageUrl: string
) => {
  const stylesheetShadowColorLabel = handleBobShadowLabel(styles, stylesheetLabels, breakpoint, behaviourState)
  const stylesheetBorderColorLabel = handleBobBorderLabel(styles, stylesheetLabels, breakpoint, behaviourState)

  return {
    ...HeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinHeightStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...WidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MaxWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...MinWidthStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...FlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...SelfFlexStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...ShadowStyleCssRenderUnsafe(styles, breakpoint, behaviourState, undefined, stylesheetShadowColorLabel),
    ...BoundaryStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...BorderStyleCssRenderUnsafe(styles, breakpoint, behaviourState, stylesheetBorderColorLabel),
    ...RadiusStyleCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...handleBackgroundImage(styles, stylesheetLabels, breakpoint, behaviourState, imageUrl),
    ...ZIndexCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...PositionCssRenderUnsafe(styles, breakpoint, behaviourState),
    ...cssRenderUnsafeCursor(styles, breakpoint, behaviourState),
  }
}

const handleBackgroundImage = (
  styles: any,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  imageUrl: string
) => {
  const stylesheetColorsLabel = handleBobColorsLabel(styles, stylesheetLabels, breakpoint, behaviourState)

  const colorsStyle = BackgroundColorCssRenderUnsafe(
    styles,
    breakpoint,
    behaviourState,
    undefined,
    stylesheetColorsLabel
  )
  const backgroundImageStyle = BackgroundImageCssRenderUnsafe(styles, breakpoint, behaviourState, imageUrl)

  // when colors is using gradient and backgroundImage exists,
  // join them on the same background-image css
  if (
    "background-image" in colorsStyle &&
    typeof backgroundImageStyle === "object" &&
    "background-image" in backgroundImageStyle
  ) {
    const mergedBackgroundImageStyle = {
      "background-image": `${colorsStyle["background-image"]}, ${backgroundImageStyle["background-image"]}`,
    }
    return {
      ...backgroundImageStyle,
      ...mergedBackgroundImageStyle,
    }
  }

  // return color and background-image css
  return {
    ...colorsStyle,
    ...backgroundImageStyle,
  }
}

// TODO: i think this is not used
const GetMedia = (
  field: string,
  inlineContent: ObjectContent["inlineContent"],
  pageResponse: PageTypes,
  singleObject: SingleObject,
  postObj?: Post,
  overrides?: ObjectContentOverride,
  replacedMediaList?: { [id: string]: MediaObject }
): {
  mediaSrc: string
  mediaAlt: string
  fallbackExtension?: string
  mediaInfo?: MediaObject
} => {
  // custom content
  if (getOverrideInlineContentValues(`${field}.contentSrc`, { inlineContent, overrides }) === WRITE) {
    return handleSrcLink(pageResponse, getOverrideInlineContentValues(`${field}.src`, { inlineContent, overrides }))
  }
  // cms content
  else if (
    getOverrideInlineContentValues(`${field}.contentSrc`, { inlineContent, overrides }) === CMS &&
    postObj &&
    inlineContent
  ) {
    const mediaUrl = getMediaStringField(postObj, inlineContent, field, overrides)
    return handleSrcLink(mergeMedias(singleObject, pageResponse, replacedMediaList), mediaUrl)
  }
  // content page
  else if (getOverrideInlineContentValues(`${field}.contentSrc`, { inlineContent, overrides }) === CONTENT_PAGE) {
    const postObj = pageResponse.contentPage?.content || DummyPost
    const mediaUrl = getMediaStringField(postObj, inlineContent, field, overrides)
    return handleSrcLink(mergeMedias(singleObject, pageResponse, replacedMediaList), mediaUrl)
  } else {
    return { mediaSrc: "", mediaAlt: "" }
  }
}

export { responsiveStyles, GetMedia }
