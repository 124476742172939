import React from "react"
import {
  ObjectContent,
  ObjectContentOverride,
} from "../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { PageTypes } from "../../modules/shared-modules/experienceManager/types/pageTypes"
import styled from "styled-components"
import { handleDOMid } from "../../modules/shared-modules/utilities/symbols"
import { getOverrideInlineContentValues } from "../../modules/shared-modules/utilities/components"
import BobLoading from "../../areas/public-page/shared-public-page/bob/bobLoading"
import { getComposedUuid } from "../../modules/shared-modules/utilities/utils"
import he from "he"

interface BobTextSplitProps {
  textType: "renderHtml" | "renderCustomContent" | "renderCustomTag"
  templateStyles: any
  tabletStyles: any
  mobileStyles: any
  inlineContent: ObjectContent["inlineContent"]
  overrides?: ObjectContentOverride
  value: any
  tag: any
  classes: any
  linkAttrs: any
  id: string
  instanceId: string | undefined
  field: string
  network?: string
  pageResponse: PageTypes
  classPrefix: any
  handleCmsStyles: (templateStyles: any) => void
  clickEvent: (e: any, tag: any) => void
  datasetRender: { "data-trackinfo": string } | {}
}

interface BobTextSplitState {
  customTextCanRender: boolean
}

interface StyledTextProps {
  desktop: any
  tablet?: any
  mobile?: any
  safeCustomTextTag: boolean
}

const BobTextDiv = styled.div`
  ${(props: StyledTextProps) => props.desktop}
  ${(props: StyledTextProps) => (props.safeCustomTextTag ? innerTagsStyles : "")}
@media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: StyledTextProps) => props.tablet}
    ${(props: StyledTextProps) => (props.safeCustomTextTag ? innerTagsStyles : "")}
  }
  @media all and (max-width: 766px) {
    ${(props: StyledTextProps) => props.mobile}
    ${(props: StyledTextProps) => (props.safeCustomTextTag ? innerTagsStyles : "")}
  }
`

/**
 * Must force bob styles on inner text tags
 * so that the text element default styles are overriden by bob styles
 *
 * Applies only to customText with safe flag
 */
const innerTagsStyles = `
 h1, h2, h3, h4, h5, h6, p, a {
   font-family: inherit !important;
   font-size: inherit !important;
   font-weight: inherit !important;
   line-height: inherit !important;
   letter-spacing: inherit !important;
   margin: 0 !important;
 }`

class BobTextSplit extends React.Component<BobTextSplitProps, BobTextSplitState> {
  constructor(props: BobTextSplitProps) {
    super(props)
    this.state = {
      customTextCanRender: false,
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      customTextCanRender: true,
    })
  }

  renderHtml = (styles: any, classPrefix: string, classes: string) => {
    const desktopStyles: any = this.props.handleCmsStyles(styles.templateStyles)
    return (
      <BobTextDiv
        {...this.props.datasetRender}
        desktop={desktopStyles}
        mobile={styles.mobileStyles}
        tablet={styles.tabletStyles}
        id={handleDOMid(this.props.field, this.props.id, this.props.instanceId)}
        dangerouslySetInnerHTML={{ __html: this.props.value }}
        className={`${
          this.props.network === "grafly" ? "ql-editor" : ""
        } dangerousHTML ${classPrefix}-bob-text-container bob-text ${classes}`}
        safeCustomTextTag={false}
      />
    )
  }

  renderCustomContent = (styles: any, tag: any, classes: string, linkAttrs: any) => {
    const desktopStyles = this.props.handleCmsStyles(styles.templateStyles)
    const customText = getOverrideInlineContentValues(`${this.props.field}.customText`, this.props)
    const safeCustomTextTag = this.props.inlineContent[`${this.props.field}.safeCustomTextTag`]

    // legacy fix: for older bobText's use tag, for new ones use DIV so that the html is valid.
    // when link is enabled tag must be 'a'
    let TAG = safeCustomTextTag ? "div" : tag
    TAG = getOverrideInlineContentValues(`${this.props.field}.behaviour-link-enable`, this.props) ? "a" : TAG

    return (
      <BobTextDiv
        {...this.props.datasetRender}
        onClick={(e) => {
          this.props.clickEvent(e, tag)
        }}
        as={TAG}
        {...linkAttrs}
        id={handleDOMid(this.props.field, this.props.id, this.props.instanceId)}
        dangerouslySetInnerHTML={{ __html: customText }}
        className={` dangerousHTML ql-text ${classes}`}
        desktop={desktopStyles}
        mobile={styles.mobileStyles}
        tablet={styles.tabletStyles}
        safeCustomTextTag={safeCustomTextTag}
      />
    )
  }

  /**
   * Apply legacy fix to invalid html
   *
   * @param styles
   * @param tag
   * @param classes
   * @param linkAttrs
   * @returns
   */
  renderCustomContentLegacyFix = (styles: any, tag: any, classes: string, linkAttrs: any) => {
    const desktopStyles = this.props.handleCmsStyles(styles.templateStyles)
    const customText = getOverrideInlineContentValues(`${this.props.field}.customText`, this.props)
    const safeCustomTextTag = this.props.inlineContent[`${this.props.field}.safeCustomTextTag`]

    // when link is enabled tag must be 'a'
    let TAG = getOverrideInlineContentValues(`${this.props.field}.behaviour-link-enable`, this.props) ? "a" : tag

    return (
      <BobTextDiv
        {...this.props.datasetRender}
        onClick={(e) => {
          this.props.clickEvent(e, tag)
        }}
        as={TAG}
        {...linkAttrs}
        id={handleDOMid(this.props.field, this.props.id, this.props.instanceId)}
        className={` dangerousHTML ql-text ${classes}`}
        desktop={desktopStyles}
        mobile={styles.mobileStyles}
        tablet={styles.tabletStyles}
        dangerouslySetInnerHTML={{ __html: this.state.customTextCanRender ? customText : "" }}
        safeCustomTextTag={safeCustomTextTag}
      />
    )
  }

  renderCustomTag = (styles: any, tag: string, linkAttrs: any, classes: string) => {
    const desktopStyles = this.props.handleCmsStyles(styles.templateStyles)
    // parse and render timestamp
    const value = this.props.value
    //if (value !== "" && moment.unix(value).isValid()) value = moment.unix(value).format("DD/MM/YYYY")

    // when link is enabled tag must be 'a'
    let TAG = getOverrideInlineContentValues(`${this.props.field}.behaviour-link-enable`, this.props) ? "a" : tag

    return (
      <BobTextDiv
        {...this.props.datasetRender}
        as={TAG}
        id={handleDOMid(this.props.field, this.props.id, this.props.instanceId)}
        {...linkAttrs}
        className={classes}
        desktop={desktopStyles}
        mobile={styles.mobileStyles}
        tablet={styles.tabletStyles}
        safeCustomTextTag={false}
      >
        <BobLoading
          type='text'
          uuid={getComposedUuid(this.props.pageResponse.objects[this.props.id])}
          contentSrc={getOverrideInlineContentValues(`${this.props.field}.contentSrc`, this.props)}
          mappedValue={getOverrideInlineContentValues(`${this.props.field}.mappedValue`, this.props)}
          fontSize={styles.templateStyles.fontSize}
        >
          {value && value !== "" ? (typeof value !== "number" ? he.decode(value) : value) : ""}
        </BobLoading>
      </BobTextDiv>
    )
  }

  render = () => {
    /**
     * Render cms html
     */
    if (this.props.textType === "renderHtml") {
      return this.renderHtml(
        {
          templateStyles: this.props.templateStyles,
          tabletStyles: this.props.tabletStyles,
          mobileStyles: this.props.mobileStyles,
        },
        this.props.classPrefix,
        this.props.classes
      )
    }

    /**
     * Render inline custom content
     * When safeCustomTextTag flag is false/undefined a legacy fix should be applied
     *
     * Legacy fix
     */
    if (this.props.textType === "renderCustomContent") {
      const safeCustomTextTag = getOverrideInlineContentValues(`${this.props.field}.safeCustomTextTag`, this.props)
      if (safeCustomTextTag)
        return this.renderCustomContent(
          {
            templateStyles: this.props.templateStyles,
            tabletStyles: this.props.tabletStyles,
            mobileStyles: this.props.mobileStyles,
          },
          this.props.tag,
          this.props.classes,
          this.props.linkAttrs
        )
      else
        return this.renderCustomContentLegacyFix(
          {
            templateStyles: this.props.templateStyles,
            tabletStyles: this.props.tabletStyles,
            mobileStyles: this.props.mobileStyles,
          },
          this.props.tag,
          this.props.classes,
          this.props.linkAttrs
        )
    }

    /**
     * Render cms html with no valid html
     */
    if (this.props.textType === "renderCustomTag") {
      return this.renderCustomTag(
        {
          templateStyles: this.props.templateStyles,
          tabletStyles: this.props.tabletStyles,
          mobileStyles: this.props.mobileStyles,
        },
        this.props.tag,
        this.props.linkAttrs,
        this.props.classes
      )
    }
  }
}

export default BobTextSplit
