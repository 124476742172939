import React, { Fragment } from "react"
import { getFormProp } from "../../../../../modules/shared-modules/utilities/components"
import {
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { ExpManager, PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
// @ts-ignore
import Style from "style-it"
import styled, { css } from "styled-components"
import { handleDOMid } from "../../../../../modules/shared-modules/utilities/symbols"
import GlobalStylesUtils from "../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { emPreviewClass, emScrollId } from "../bobUtils"
import { MediaObject } from "../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import publicUtils from "../../../../../stylesheets/modules/utils/public-components.module.sass"
import { responsiveStyles } from "../bobInput/bobInputHandler"
import { handleMediaQueryRestrictions, handleStateStyles } from "../bobHandlerV2"
import { BobInputStyles, ResponsiveCSSProps } from "../bobTypes"
import { NGS_CLASS_SUFIX } from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import * as gsType from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import { isBobInput } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { handleFonts } from "../bobTextV2/bobTextV2Handler"

interface OwnProps {
  id: string
  pageResponse: PageTypes
  instanceId: string | undefined
  object: SingleObject
  inlineContent: ObjectContent["inlineContent"]
  overrides: ObjectContentOverride | undefined
  template: any
  post: Post | undefined
  field: string
  expManager: ExpManager
  setRef: (x: React.RefObject<HTMLInputElement>) => void | undefined
  replacedMediaList: { [id: string]: MediaObject } | undefined
  form: SingleObject["content"]["form"]
}

type BobInputProps = OwnProps

const InputDiv = styled.input`
  ${(props: ResponsiveCSSProps<BobInputStyles>) => css`
    ${props.desktop}
    ::placeholder {
      color: ${props.desktop.color};
    }
  `}
  ${(props: ResponsiveCSSProps<BobInputStyles>) =>
    props.hover.isEnabled.desktop
      ? css`
          @media all ${handleMediaQueryRestrictions(props.hover.isEnabled.mobile, props.hover.isEnabled.tablet)} {
            &:hover {
              ${props.hover.desktop};
              ::placeholder {
                color: ${props.hover.desktop.color};
              }
            }
          }
        `
      : {}}

  ${(props: ResponsiveCSSProps<BobInputStyles>) =>
    Object.keys(props.tablet).length > 0 || Object.keys(props.hover.tablet).length > 0
      ? css`
          @media all and (max-width: 992px) and (min-width: 767px) {
            ${(props: ResponsiveCSSProps<BobInputStyles>) => props.tablet}
            ::placeholder {
              color: ${props.tablet.color};
            }
            ${(props: ResponsiveCSSProps<BobInputStyles>) =>
              props.hover.isEnabled.tablet && Object.keys(props.hover.tablet).length > 0
                ? css`
                    &:hover {
                      ${props.hover.tablet}
                      ::placeholder {
                        color: ${props.hover.tablet.color};
                      }
                    }
                  `
                : {}}
          }
        `
      : {}}

  ${(props: ResponsiveCSSProps<BobInputStyles>) =>
    Object.keys(props.mobile).length > 0 || Object.keys(props.hover.mobile).length > 0
      ? css`
          @media all and (max-width: 766px) {
            ${(props: ResponsiveCSSProps<BobInputStyles>) => props.mobile}
            ::placeholder {
              color: ${props.mobile.color};
            }
            ${(props: ResponsiveCSSProps<BobInputStyles>) =>
              props.hover.isEnabled.mobile && Object.keys(props.hover.mobile).length > 0
                ? css`
                    &:hover {
                      ${props.hover.mobile}
                      ::placeholder {
                        color: ${props.hover.mobile.color};
                      }
                    }
                  `
                : {}}
          }
        `
      : {}}
`

class BobInput extends React.Component<BobInputProps> {
  private inputRef: React.RefObject<HTMLInputElement>

  constructor(props: BobInputProps) {
    super(props)
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    this.props.setRef && this.props.setRef(this.inputRef)
  }

  render() {
    //@ts-ignore
    const templateStyles = isBobInput(this.props.object.styles.bobs.submit1)
    const settings = this.props.object.settings
    const pageLabels = this.props.pageResponse.stylesheet?.labels

    const DesktopStyles = responsiveStyles(templateStyles, pageLabels, "desktop", "default")
    const TabletStyles = responsiveStyles(templateStyles, pageLabels, "tablet", "default")
    const MobileStyles = responsiveStyles(templateStyles, pageLabels, "mobile", "default")
    const hoverStyles = handleStateStyles<BobInput, BobInputStyles>(
      templateStyles,
      responsiveStyles(templateStyles, pageLabels, "desktop", "hover"),
      responsiveStyles(templateStyles, pageLabels, "tablet", "hover"),
      responsiveStyles(templateStyles, pageLabels, "mobile", "hover")
    )

    const DOMid = this.props.expManager.enable
      ? { id: `${handleDOMid(this.props.field, this.props.id, this.props.instanceId)}` }
      : {}
    const EMpreviewClass = emPreviewClass(this.props.expManager.enable, templateStyles.uuid)
    const EMScrollId = emScrollId(this.props.expManager.enable, this.props.object.uuid)
    const GlobalStylesClass = GlobalStylesUtils.handleClassNameV2(
      this.props.pageResponse.nGlobalStyles,
      templateStyles,
      "-box",
      {
        [gsType.GS_SHADOW]: NGS_CLASS_SUFIX,
        [gsType.GS_BOUNDARY]: NGS_CLASS_SUFIX,
      }
    )
    const customClasses = settings?.[this.props.field]?.class
      ? ` ${settings?.[this.props.field]?.class?.join(" ")}`
      : ""
    const classes = `${publicUtils["flx_flex"]}${EMpreviewClass ? ` ${EMpreviewClass} ` : ""}${
      GlobalStylesClass ? ` ${GlobalStylesClass} ` : ""
    }${customClasses}`

    const loadedFonts = handleFonts(templateStyles, pageLabels, this.props.expManager.enable)
    const hasFont = loadedFonts.trim() !== ""

    let label = getFormProp(this.props.form, "submitLabel", this.props.object.content, this.props.field) as string

    return (
      <Fragment>
        {hasFont && (
          <Style>
            {`
            ${loadedFonts}
          `}
          </Style>
        )}
        <InputDiv
          type='submit'
          ref={this.inputRef}
          desktop={DesktopStyles}
          mobile={MobileStyles}
          tablet={TabletStyles}
          hover={hoverStyles}
          {...EMScrollId}
          {...DOMid}
          className={classes}
          value={label}
        />
      </Fragment>
    )
  }
}

export default BobInput
