import React, { useEffect, useState, RefObject } from "react"
// @ts-ignore
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { handleDOMid } from "../../../../../../modules/shared-modules/utilities/symbols"
import styled, { css } from "styled-components"
import {
  ObjectContentOverride,
  Post,
} from "../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { handleImageSrcTemplate, isHoverEnabled, Media4Bob, responsiveStyles } from "../bobMediaV2Handler"
import { ExpManager, PageTypes } from "../../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import GlobalStylesUtils from "../../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { NGS_CLASS_SUFIX } from "../../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import * as gsType from "../../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import { loadThreshold } from "../../../../../../modules/shared-modules/utilities/utils"
import { MediaObject } from "../../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import { SingleObject } from "../../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { BobNmedia, isNbobNmedia } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import { cssRenderUnsafe as ImageStyleCssRenderUnsafe } from "../../bobStyles/imageStyle"
import { emPreviewClass, emScrollId } from "../../bobUtils"
import { handleMediaQueryRestrictions, handleStateStyles } from "../../bobHandlerV2"
import { ResponsiveCSSProps, BobMediaStyles } from "../../bobTypes"

// type MediaImageState = {
//   isHover: boolean
// }

interface MediaImageProps {
  media: { defaultMedia: Media4Bob; hoverMedia: Media4Bob | undefined }
  object: SingleObject
  pageResponse: PageTypes
  replacedMediaList?: { [id: string]: MediaObject }
  instanceId: string | undefined
  post: Post | undefined
  overrides: ObjectContentOverride | undefined
  field: string
  expManager: ExpManager
  setRef: (x: React.RefObject<HTMLDivElement>) => void | undefined
}

const MediaImageImg = styled.div`
  ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.desktop}
  ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    props.hover.isEnabled.desktop
      ? css`
          @media all ${handleMediaQueryRestrictions(props.hover.isEnabled.mobile, props.hover.isEnabled.tablet)} {
            &:hover {
              ${props.hover.desktop}
            }
          }
        `
      : {}}

${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    Object.keys(props.tablet).length > 0 || Object.keys(props.hover.tablet).length > 0
      ? css`
          @media all and (max-width: 992px) and (min-width: 767px) {
            ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.tablet}
            ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
              props.hover.isEnabled.tablet && Object.keys(props.hover.tablet).length > 0
                ? css`
                    &:hover {
                      ${props.hover.tablet}
                    }
                  `
                : {}}
          }
        `
      : {}}

${(props: ResponsiveCSSProps<BobMediaStyles>) =>
    Object.keys(props.mobile).length > 0 || Object.keys(props.hover.mobile).length > 0
      ? css`
          @media all and (max-width: 766px) {
            ${(props: ResponsiveCSSProps<BobMediaStyles>) => props.mobile}
            ${(props: ResponsiveCSSProps<BobMediaStyles>) =>
              props.hover.isEnabled.mobile && Object.keys(props.hover.mobile).length > 0
                ? css`
                    &:hover {
                      ${props.hover.mobile}
                    }
                  `
                : {}}
          }
        `
      : {}}
`

const MediaImage: React.FC<MediaImageProps> = (props): JSX.Element => {
  const [imgRef] = useState<RefObject<HTMLImageElement>>(React.createRef())
  const [isHover, setIsHover] = useState<boolean>(false)

  useEffect(() => {
    props.setRef && props.setRef(imgRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mediaStyles = isNbobNmedia(props.object.styles.bobs.nmedia1)
  const settings = props.object.settings
  const pageLabels = props.pageResponse.stylesheet?.labels

  /**
   * Styles
   */
  const desktopStyles = {
    ...ImageStyleCssRenderUnsafe(mediaStyles, "desktop", "default"),
    ...responsiveStyles(mediaStyles, pageLabels, "desktop", "default"),
  }

  const mobileStyles = {
    ...ImageStyleCssRenderUnsafe(mediaStyles, "mobile", "default"),
    ...responsiveStyles(mediaStyles, pageLabels, "mobile", "default"),
  }
  const tabletStyles = {
    ...ImageStyleCssRenderUnsafe(mediaStyles, "tablet", "default"),
    ...responsiveStyles(mediaStyles, pageLabels, "tablet", "default"),
  }
  const hoverStyles = handleStateStyles<BobNmedia, BobMediaStyles>(
    mediaStyles,
    {
      ...ImageStyleCssRenderUnsafe(mediaStyles, "desktop", "hover"),
      ...responsiveStyles(mediaStyles, pageLabels, "desktop", "hover"),
    },
    {
      ...ImageStyleCssRenderUnsafe(mediaStyles, "tablet", "hover"),
      ...responsiveStyles(mediaStyles, pageLabels, "tablet", "hover"),
    },
    {
      ...ImageStyleCssRenderUnsafe(mediaStyles, "mobile", "hover"),
      ...responsiveStyles(mediaStyles, pageLabels, "mobile", "hover"),
    }
  )
  // hoverStyles.desktop?.fontSize TODO: typed styles cannot return {}

  const imageOnHover = () => {
    if (mediaStyles.behaviour.hover?.enable) setIsHover(true)
  }

  const imageUnHover = () => {
    if (mediaStyles.behaviour.hover?.enable) setIsHover(false)
  }

  // RESPONSIVE_MEDIA const fallbackTabletSrc =
  //   tabletMedia?.fallbackExtension && tabletMedia?.mediaInfo
  //     ? `https://static-media.fluxio.cloud/${
  //         tabletMedia.mediaInfo.profileName
  //       }/${tabletMedia.mediaInfo.id}${
  //         tabletMedia.mediaInfo.version
  //           ? `_${tabletMedia.mediaInfo.version}`
  //           : ""
  //       }.${fallbackExtension}`
  //     : undefined
  // const fallbackMobileSrc =
  //   mobileMedia?.fallbackExtension && mobileMedia?.mediaInfo
  //     ? `https://static-media.fluxio.cloud/${
  //         mobileMedia.mediaInfo.profileName
  //       }/${mobileMedia.mediaInfo.id}${
  //         mobileMedia.mediaInfo.version
  //           ? `_${mobileMedia.mediaInfo.version}`
  //           : ""
  //       }.${fallbackExtension}`
  //     : undefined

  const DOMid = props.expManager.enable
    ? { id: `${handleDOMid(props.field, props.object.uuid, props.instanceId)}` }
    : {}
  const EMpreviewClass = emPreviewClass(props.expManager.enable, mediaStyles.uuid)
  const EMScrollId = emScrollId(props.expManager.enable, props.object.uuid)
  const GlobalStylesClass = GlobalStylesUtils.handleClassNameV2(props.pageResponse.nGlobalStyles, mediaStyles, "-box", {
    [gsType.GS_SHADOW]: NGS_CLASS_SUFIX,
    [gsType.GS_BOUNDARY]: NGS_CLASS_SUFIX,
  })
  const customClasses = settings?.[props.field]?.class ? ` ${settings?.[props.field]?.class?.join(" ")}` : ""
  const classes = `${EMpreviewClass}${GlobalStylesClass ? ` ${GlobalStylesClass}` : ""}${customClasses}`

  return (
    <LazyLoadComponent
      threshold={loadThreshold(props.media.defaultMedia.size)}
      visibleByDefault={props.expManager.enable}
    >
      {/* const tabletMedia = responsiveMediaLink("tablet", props.pageResponse, inlineContent, props.overrides, props.field)
        const mobileMedia = responsiveMediaLink("mobile", props.pageResponse, inlineContent, props.overrides, props.field)
        RESPONSIVE_MEDIA {{mobileMedia && (
                media="(max-width: 767px)"
                srcSet={mobileMedia.mediaSrc}
              {fallbackMobileSrc && (
                  media="(max-width: 767px)"
                  srcSet={fallbackMobileSrc}
              )}
          )}
          {tabletMedia && (
                media="(max-width: 992px) and (min-width: 768px)"
                srcSet={tabletMedia.mediaSrc}
              {fallbackTabletSrc && (
                  media="(max-width: 992px) and (min-width: 768px)"
                  srcSet={fallbackTabletSrc}
              )}
          )}} */}
      <MediaImageImg
        as={"img"}
        desktop={desktopStyles}
        mobile={mobileStyles}
        tablet={tabletStyles}
        hover={hoverStyles}
        ref={imgRef}
        {...DOMid}
        {...EMScrollId}
        className={classes}
        src={handleImageSrcTemplate(isHover, props.media.defaultMedia.url, props.media.hoverMedia?.url)}
        alt={props.media.defaultMedia.alt}
        onMouseEnter={() => imageOnHover()}
        onMouseLeave={() => imageUnHover()}
        onError={(node: any) =>
          mediaStyles.behaviour && !mediaStyles.behaviour.hover.enable
            ? (node.target.style.display = "none")
            : undefined
        }
      />
      {/*
       * preload hover image so there ins't a delay when loading it for the first time
       * (TODO: this is unecessary when image is already cached...)
       *
       */}
      {isHoverEnabled(mediaStyles) && props.media.hoverMedia && (
        <div style={{ backgroundImage: `url(${props.media.hoverMedia?.url})`, display: "none" }} />
      )}
    </LazyLoadComponent>
  )
}

export default MediaImage
