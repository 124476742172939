import React from "react"
// @ts-ignore
import imagesLoaded from "imagesloaded"
import { ExpManager } from "../../../../modules/shared-modules/experienceManager/types/pageTypes"
import styled from "styled-components"
import PageLoaderImageSplit from "../../../../split/shared-public-page/pageLoaderImageSplit"

interface PageLoaderProps {
  options: {
    enable: boolean
    delay: number | undefined
    media: string | undefined
    code: string | undefined
  }
  expManager: ExpManager
}

const PageLoaderContainer: any = styled.div`
  ${(props: any) => `
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s, opacity 0.3s linear;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #000;
    z-index: 999999999999;
  `}
`

const PageLoader = (props: PageLoaderProps) => {
  const handleImagesLoaded = () => {
    imagesLoaded(document.querySelector(".page-container"), function (a: any) {
      const loader = document.querySelector<HTMLElement>("#page-loader")
      if (loader && loader.style.display !== "none") {
        loader.style.opacity = "0"
        loader.style.visibility = "hidden"
      }
    })
  }

  if (props.options.delay)
    setTimeout(() => {
      handleImagesLoaded()
    }, props.options.delay)
  else handleImagesLoaded()

  // show when enabled and outside pagebuilder
  if (props.options.enable && !props.expManager.enable) {
    if (!props.options.code)
      return (
        <PageLoaderContainer id='page-loader'>
          {props.options.media ? <PageLoaderImageSplit options={props.options} /> : "Loading"}
        </PageLoaderContainer>
      )
    return <PageLoaderContainer id='page-loader' dangerouslySetInnerHTML={{ __html: props.options.code }} />
  }

  return <></>
}

export default React.memo(PageLoader)
