import React, { useEffect, useRef, useState } from "react"
// @ts-ignore
import Style from "style-it"
import { handleBackgroundStyles, handleTextStyles } from "../../../../bob/bobFormComponents"
import BobComponentHandler from "../../../../bob/bobComponentHandler"
import axios from "axios"
import apiEnv from "../../../../../../../api/shared-api/apiEnv"
import styled from "styled-components"
import { responsiveProperties, responsiveGroupStylesProperties } from "../../../../bob/bobHandler"
import { FileInputProps } from "./interface"
import { ResponsiveCSSProps } from "../../interface"
import { cssRenderUnsafe as cssRenderUnsafeColor } from "../../../../bob/bobStyles/colorStyle"
import { conditionalColorsRenderUnsafe as cssRenderUnsafeColors } from "../../../../../../../modules/shared-modules/utilities/conditionalController"


const FileInputButton = styled.div`
  ${(props: ResponsiveCSSProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: ResponsiveCSSProps) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: ResponsiveCSSProps) => props.mobile}
  }
`

const FileInput: React.FC<FileInputProps> = (props): JSX.Element => {
  const [value, setValue] = useState<any>(undefined)
  const [fileName, setFileName] = useState<string>("")
  const [errorUpload, setErrorUpload] = useState<boolean>(false)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      handleUploadFile()
    }
    // eslint-disable-next-line
  }, [value])

  // ref for input html element
  const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>

  const handleValueChange = (e: Event) => {
    let target = e.target as any
    if (target.files && target.files.length > 0) {
      const reader = new FileReader()
      const fileData = target.files
      reader.addEventListener("load", () => {
        setValue(fileData[0])
        setFileName(fileData[0].name)
        setErrorUpload(false)
      })
      reader.readAsDataURL(target.files[0])
    }
  }

  const handleUploadFile = async (): Promise<any> => {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
      },
    }
    const formData = new FormData()
    formData.append("file", value)
    await axios
      .post(`${apiEnv()}v1/forms/${props.formId}/files`, formData, config)
      .then((response) => {
        return props.handleValueChange(response.data.url)
      })
      .catch(() => {
        return setErrorUpload(true)
      })
  }

  const renderButton = (backgroundStyles: any, textStyles: any) => {
    let styles = { ...backgroundStyles, ...textStyles.styles }
    let mobileStyles = responsiveProperties(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "mobile",
      undefined,
      ["colors"]
    )
    mobileStyles = {
      ...mobileStyles,
      ...responsiveGroupStylesProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "text"),
      ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "default", true),
      ...cssRenderUnsafeColor(
        { ...props.bobStyle.text, ...props.bobStyle.bobInput },
        "mobile",
        "default",
        undefined,
        undefined,
        true
      ),
    }
    let tabletStyles = responsiveProperties(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "tablet",
      undefined,
      ["colors"]
    )
    tabletStyles = {
      ...tabletStyles,
      ...responsiveGroupStylesProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "text"),
      ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "default", true),
      ...cssRenderUnsafeColor(
        { ...props.bobStyle.text, ...props.bobStyle.bobInput },
        "tablet",
        "default",
        undefined,
        undefined,
        true
      ),
    }

    return (
      <FileInputButton
        as='button'
        desktop={styles}
        mobile={mobileStyles}
        tablet={tabletStyles}
        type='button'
        className={`btn btn-secondary`}
        onClick={() => fileInput.current && fileInput.current.click()}
      >
        <i className='fas fa-paperclip'></i>
      </FileInputButton>
    )
  }

  let backgroundStyles: any = handleBackgroundStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles)
  let textStyles: any = handleTextStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles, props.expManager)

  return (
    <Style>
      {`
        ${textStyles.fontLoaded}          
      `}
      <div className='form-group d-flex align-items-center'>
        <input
          ref={fileInput}
          type='file'
          onChange={(e: any) => handleValueChange(e)}
          style={{ display: "none", height: "0", width: "0" }}
        />
        {renderButton(backgroundStyles, textStyles)}
        {fileName ? (
          <p className='simple-form-message'>{fileName}</p>
        ) : (
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            inlineContent={props.obj.content.inlineContent}
            overrides={props.overrides}
            object={props.obj}
            objectId={props.obj.uuid}
            field={props.bobStyle.text.name}
            tag='label'
            value={props.inputObj.label || props.inputObj.name}
            template={props.obj.styles}
            rules={props.componentRule}
            expManager={props.expManager}
            network={props.obj.network}
          />
        )}
        {errorUpload && (
          <p className='simple-form-error'>
            {props.lang === "en"
              ? "There was an error uploading your file, please try again"
              : "Erro ao enviar o ficheiro, por favor tente novamente."}
          </p>
        )}
      </div>
    </Style>
  )
}

export default FileInput
