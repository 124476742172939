import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"

class ScrollStyle {
  static responsiveStyle(scrollObj: any) {
    if (!objValueExists(scrollObj, "enable")) return ""

    if (!scrollObj.enable) return { position: "static !important" }

    if (scrollObj.value === "magnetic")
      return {
        position: "sticky !important",
        top: "0 !important",
        "z-index": "999 !important",
      }
    if (scrollObj.value === "fixed")
      return {
        position: "fixed !important",
        zIndex: "999 !important",
        width: "100% !important",
      }
    if (scrollObj.value === "static") return { position: "static !important" }
  }
}

export default ScrollStyle
