import React from "react"
import ReactPlayer from "react-player"
import LazyVideoPlayer from "./lazyVideoPlayer"

interface VideoPlayerSplitProps {
  videoType: "normal" | "lazy"
  url: string
  controls: boolean
  config: any
  playing?: boolean
  loop?: boolean
  muted?: boolean
  style?: any
  className?: string
}

type VideoPlayerSplitState = {
  hasLoaded: boolean
}

class VideoPlayerSplit extends React.Component<VideoPlayerSplitProps, VideoPlayerSplitState> {
  constructor(props: VideoPlayerSplitProps) {
    super(props)
    this.state = {
      hasLoaded: false,
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      hasLoaded: true,
    })
  }

  render(): React.ReactNode {
    if (this.state.hasLoaded) {
      if (this.props.videoType === "lazy") {
        return <LazyVideoPlayer url={this.props.url} controls={this.props.controls} config={this.props.config} />
      } else {
        return (
          <ReactPlayer
            url={this.props.url}
            controls={this.props.controls}
            playing={this.props.playing}
            config={this.props.config}
            width='100%'
            height='100%'
            loop={this.props.loop}
            muted={this.props.muted}
            className={this.props.className}
            style={this.props.style}
          />
        )
      }
    }
    return []
  }
}

export default VideoPlayerSplit
