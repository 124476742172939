import React, { CSSProperties, useEffect, useRef, useState } from "react"
import ComponentHandler from "../../../../componentHandler"
import WarningDefaultSystem from "../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem"
import { disableBodyScroll, handleObjectType } from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { handleColumns, responsiveGuttersClass } from "../../../bob/bobHandler"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { MenuV2Props } from "."
import menuComponentStyles from "../../../../../../stylesheets/modules/templates/menuComponent.module.sass"
import menuComponentV2Styles from "../../../../../../stylesheets/modules/templates/menuComponentV2.module.sass"
import { isBobBackground } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const MenuV2: React.FC<MenuV2Props> = (props) => {
  const [showMenu, _setShowMenu] = useState<boolean>(false)
  const [flexDirection, setFlexDirection] = useState<CSSProperties["flexDirection"]>("row")

  const showMenuRef = useRef(showMenu)
  const setShowMenu = (data: any) => {
    showMenuRef.current = data
    _setShowMenu(data)
  }

  useEffect(
    () => {
      getFrame()
      return () => removeFrame()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      disableBodyScroll(props.expManager, showMenu)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showMenu]
  )

  const getFrame = () => {
    /**
     * handle window for page builder iframe or public pages
     */
    const iframe = document.getElementById("board-preview-frame") as HTMLIFrameElement
    const handledWindow = iframe?.contentWindow || window

    /**
     * Add resize eventListener
     * check window size on mount
     */
    handledWindow.addEventListener("resize", () => windowResized(handledWindow))
    windowResized(handledWindow)

    /**
     * Add click eventListener
     */
    handledWindow.addEventListener("click", (e) => anchorClicked(e))
  }

  const removeFrame = () => {
    /**
     * handle document and window for page builder iframe or public pages
     */
    const iframe = document.getElementById("board-preview-frame") as HTMLIFrameElement
    const handledWindow = iframe?.contentWindow || window

    /**
     * Clean resize eventListeners
     */
    handledWindow.removeEventListener("resize", () => windowResized(handledWindow))

    /**
     * Clean click reventListeners
     */
    handledWindow.removeEventListener("click", (e) => anchorClicked(e))
  }

  /**
   * in mobile the menu container flex direction is forced to column
   * because of this the alignment logic must be switched to column too
   */
  const windowResized = (window: Window) => {
    if (window.matchMedia("(max-width: 768px)").matches) setFlexDirection("column")
    else setFlexDirection("row")
  }

  /**
   * close menu on anchor link click
   */
  const anchorClicked = (e: any) => {
    const link = e.target.closest("a")
    if (link) {
      const linkHref = link.getAttribute("href")
      // anchor was clicked, close menu
      if (linkHref?.startsWith("#")) {
        if (showMenuRef.current) setShowMenu(false)
      }
    }
  }

  const handleMenuToggle = () => setShowMenu(!showMenu)

  const renderMenu = (componentsId: string[]) => {
    if (componentsId && componentsId.length > 0) {
      return componentsId.map((componentId, idx) => {
        const handledItem = handleObjectType(props.obj, componentId, props.pageResponse, props.expManager.emEditorType)
        return (
          <div className={`main-menu-item ${menuComponentV2Styles["main-menu-item"]}`} key={idx}>
            <ComponentHandler
              position={idx}
              selectedInstanceId={props.obj.selectedInstanceId}
              key={idx}
              component={handledItem}
              pageResponse={props.pageResponse}
              matchOrder={props.matchOrder}
              expManager={props.expManager}
            />
          </div>
        )
      })
    } else {
      if (props.expManager.enable) {
        let components: Array<JSX.Element> = []
        for (let i = 0; i < 3; i++) {
          const columns = "4"
          components = [
            ...components,
            <div key={i} className={`navbar-menu col-12 col-md-${columns}`}>
              <div
                style={{
                  height: `${isBobBackground(props.obj.styles.bobs.background2).height.value}${
                    isBobBackground(props.obj.styles.bobs.background2).height.unit
                  }`,
                  border: "3px dashed #dbe0e7",
                }}
              ></div>
            </div>,
          ]
        }
        return (
          <WarningDefaultSystem>
            <div className='row'>{components}</div>
          </WarningDefaultSystem>
        )
      }
    }
  }

  const background1 = isBobBackground(props.obj.styles.bobs.background1)
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
  const columns = handleColumns(
    0,
    { desktop: 0, tablet: 0, mobile: 0 },
    background1,
    props.pageResponse.globalStyles[background1.globalStyleId]
  )
  const childComponentsId = props.obj.children

  return (
    <div
      className={`navigation ${menuComponentStyles.navigation} ${menuComponentV2Styles.navigation} menu-component_v2 ${menuComponentV2Styles["menu-component_v2"]}`}
    >
      <AlignmentHOC
        className={`main-menu ${menuComponentV2Styles["main-menu"]} row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        <div className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}>
          {childComponentsId && childComponentsId.length > 0 && (
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              field='icon1'
              template={props.obj.styles}
              rules={props.componentRule}
              expManager={props.expManager}
              iconClassName='fas fa-bars'
              className={`main-menu-toggle ${menuComponentV2Styles["main-menu-toggle"]}`}
              onClick={handleMenuToggle}
            />
          )}
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            inlineContent={props.obj.content.inlineContent}
            flexDirection={flexDirection}
            field={"background2"}
            rules={props.componentRule}
            template={props.obj.styles}
            expManager={props.expManager}
            className={`main-menu-container ${menuComponentV2Styles["main-menu-container"]} ${
              !showMenu ? "hide" : "show"
            }`}
          >
            <BobComponentHandler
              position={props.position}
              pageResponse={props.pageResponse}
              instanceId={props.obj.selectedInstanceId}
              object={props.obj}
              objectId={props.obj.uuid}
              field='icon2'
              template={props.obj.styles}
              rules={props.componentRule}
              expManager={props.expManager}
              iconClassName='fas fa-times'
              className={`main-menu-close ${menuComponentV2Styles["main-menu-close"]}`}
              onClick={handleMenuToggle}
            />
            {renderMenu(childComponentsId)}
          </BobComponentHandler>
        </div>
      </AlignmentHOC>
    </div>
  )
}

export default MenuV2
