import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { get2WithNull } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const UnitCodec = union([literal("px"), literal("%"), literal("vw"), literal("vh"), literal("auto")])
const WidthPropsCodec = type({ value: numberC, unit: UnitCodec })
const WidthCodec = intersection([type({ enable: booleanC }), WidthPropsCodec])

const WidthPropsOptCodec = partial({
  unit: nullable(UnitCodec),
  value: nullable(numberC),
})
const WidthOptCodec = intersection([partial({ enable: nullable(booleanC) }), WidthPropsOptCodec])

const StylesWidthCodec = intersection([
  type({ width: WidthCodec }),
  partial({
    behaviour: partial({
      active: partial({ width: WidthPropsOptCodec }),
      hover: partial({ width: WidthPropsOptCodec }),
    }),
    mobile: intersection([
      partial({ width: WidthPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ width: WidthPropsOptCodec }),
          hover: partial({ width: WidthPropsOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ width: WidthPropsOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ width: WidthPropsOptCodec }),
          hover: partial({ width: WidthPropsOptCodec }),
        }),
      }),
    ]),
  }),
])

type Width = TypeOf<typeof WidthCodec>
type WidthProps = TypeOf<typeof WidthPropsCodec>
type WidthOpt = TypeOf<typeof WidthOptCodec>
type StylesWidth = TypeOf<typeof StylesWidthCodec>
type WidthCSS = {
  width: string
}

export function responsiveStyle(widthObj: any) {
  if (!objValueExists(widthObj, "enable")) return ""
  if (!widthObj.enable) return { width: "auto" }

  return { width: widthObj.value + widthObj.unit }
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<WidthCSS> {
  const styles = StylesWidthCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return {
    width: `auto`,
  }
}

export function cssRender(
  stylesObj: StylesWidth,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<WidthCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.width)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj.width, mergeBob2(stylesObj?.behaviour?.[behaviourState]?.width, stylesObj.width))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.width, mergeBob2(stylesObj?.[breakpoint]?.width, stylesObj.width))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.width,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.width,
          stylesObj?.behaviour?.[behaviourState]?.width,
          stylesObj?.[breakpoint]?.width,
          stylesObj.width
        )
      )
    }
  }
}

export function renderBob(widthObj: Width): Partial<WidthCSS> {
  if (!widthObj.enable) return {}

  return render(widthObj)
}

export function render(widthObj: WidthProps): WidthCSS {
  const widthValue = widthObj.value
  const widthUnit = widthObj.unit
  return {
    width: `${widthValue}${widthUnit}`,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param widthObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(defaultWidthObj: Width, widthObj: WidthOpt | undefined): Partial<WidthCSS> {
  if (widthObj?.enable === false) {
    if (defaultWidthObj.enable) return { width: "auto" }

    return {}
  }

  if (widthObj?.enable) return renderOpt(widthObj)

  return {}
}

/**
 * Renders WidthOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param widthObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(widthObj: WidthOpt): Partial<WidthCSS> {
  let css = {}
  if (objValueExists(widthObj, "unit") || objValueExists(widthObj, "value")) {
    css = { width: `${widthObj.value}${widthObj.unit}` }
  }

  return css
}

export function mergeBob2(widthObj: WidthOpt | undefined, defaultWidthObj: Width): WidthOpt {
  // no filter object
  if(!widthObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = widthObj?.enable ?? defaultWidthObj.enable
  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(widthObj, 'value') ||
    objValueExists(widthObj, 'unit')
  ) {
    const value = get2WithNull(widthObj?.value, defaultWidthObj.value)
    const unit = get2WithNull(widthObj?.unit, defaultWidthObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export function mergeBob3(
  widthObj: WidthOpt | undefined,
  widthDesktopBehaviour: WidthOpt | undefined,
  widthDefaultBreakpoint: WidthOpt | undefined,
  defaultWidthObj: Width
): WidthOpt {
  // no filter object
  if(!widthObj){
    return {
      enable: undefined,
      value: undefined,
      unit: undefined,
    }
  }

  const enable = 
    widthObj?.enable ?? widthDesktopBehaviour?.enable ?? widthDefaultBreakpoint?.enable ?? defaultWidthObj.enable

  
  //if one of the values is set in the breakpoint we render the entire shadow property
  if(
    objValueExists(widthObj, 'value') ||
    objValueExists(widthObj, 'unit')
  ) {
    const value = get2WithNull(widthObj?.value, defaultWidthObj.value)
    const unit = get2WithNull(widthObj?.unit, defaultWidthObj.unit)    

    return {
      enable,
      value,
      unit,
    }
  }

  //only enable is written
  return {
    enable,
    value: undefined,
    unit: undefined,
  }
}

export type { WidthCSS, StylesWidth, WidthProps, Width, WidthOpt }