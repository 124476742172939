import React from 'react'
import ComponentHandler from '../../../../componentHandler'
import {
  disableBodyScroll,
  getContent,
  getStringField,
  handleObjectType,
} from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { responsiveGuttersClass } from "../../../bob/bobHandler"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { MenuProps } from "../menu/index"
import menuToggleComponentStyles from "../../../../../../stylesheets/modules/templates/menuToggleComponent.module.sass"
import { isBobBackground } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

interface MenuToggleV2State {
  showMenu: boolean
}

class MenuToggleV2 extends React.Component<MenuProps, MenuToggleV2State> {
  constructor(props: MenuProps) {
    super(props)
    this.state = {
      showMenu: false,
    }
  }

  componentDidMount() {
    /**
     * handle window for page builder iframe or public pages
     */
    const iframe = document.getElementById("board-preview-frame") as HTMLIFrameElement
    const handledWindow = iframe?.contentWindow || window

    handledWindow.addEventListener("click", (e) => this.anchorClicked(e))
  }

  componentWillUnmount() {
    /**
     * handle document and window for page builder iframe or public pages
     */
    const iframe = document.getElementById("board-preview-frame") as HTMLIFrameElement
    const handledWindow = iframe?.contentWindow || window

    handledWindow.removeEventListener("click", (e) => this.anchorClicked(e))
  }

  /**
   * close menu on anchor link click
   */
  anchorClicked = (e: any) => {
    if (this.state.showMenu) {
      const link = e.target?.closest("a")
      if (link) {
        const linkHref = link.getAttribute("href")
        // anchor was clicked, close menu
        if (linkHref?.startsWith("#")) {
          this.handleMenuToggle()
        }
      }
    }
  }

  handleMenuToggle = () => {
    this.setState(
      {
        ...this.state,
        showMenu: !this.state.showMenu,
      },
      () => disableBodyScroll(this.props.expManager, this.state.showMenu)
    )
  }

  renderMenu(componentsId: Array<string>) {
    if (componentsId && componentsId.length > 0) {
      return componentsId.map((componentId, idx) => {
        const handledItem = handleObjectType(
          this.props.obj,
          componentId,
          this.props.pageResponse,
          this.props.expManager.emEditorType
        )
        return (
          <div className={`main-menu-item ${menuToggleComponentStyles["main-menu-item"]}`} key={idx}>
            <ComponentHandler
              position={idx}
              selectedInstanceId={this.props.obj.selectedInstanceId}
              key={idx}
              component={handledItem}
              pageResponse={this.props.pageResponse}
              matchOrder={this.props.matchOrder}
              expManager={this.props.expManager}
            />
          </div>
        )
      })
    }
  }

  renderToggle = () => {
    const content = getContent(this.props.obj, "posts")
    if (content && content.length > 0) {
      const post = content[0]
      return (
        <div className='d-flex is-clickable' onClick={this.handleMenuToggle}>
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            objectId={this.props.obj.uuid}
            post={post}
            inlineContent={this.props.obj.content.inlineContent}
            overrides={this.props.overrides}
            field='text1'
            tag='p'
            value={getStringField(post, this.props.obj, this.props.pageResponse, "text1", this.props.overrides)}
            template={this.props.obj.styles}
            rules={this.props.componentRule}
            expManager={this.props.expManager}
            className='main-menu-toggle-text w-auto'
          />
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            objectId={this.props.obj.uuid}
            field='icon1'
            template={this.props.obj.styles}
            rules={this.props.componentRule}
            expManager={this.props.expManager}
            iconClassName='fas fa-bars'
            className={`main-menu-toggle ${menuToggleComponentStyles["main-menu-toggle"]}`}
          />
        </div>
      )
    }
  }

  render() {
    const background1 = isBobBackground(this.props.obj.styles.bobs.background1)
    const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
    const childComponentsId = this.props.obj.children

    return (
      <div
        className={`navigation ${menuToggleComponentStyles.navigation} menu-toggle-component ${menuToggleComponentStyles["menu-toggle-component"]}`}
      >
        {this.state.showMenu && (
          <div onClick={this.handleMenuToggle} className='fade modal-backdrop modal-component-backdrop show'></div>
        )}
        <AlignmentHOC
          className={`main-menu ${menuToggleComponentStyles["main-menu"]} ${responsiveGuttersClass(
            background1,
            this.props.pageResponse.globalStyles[background1.globalStyleId]
          )}`}
          desktop={alignmentStyles}
          tablet={tabletAlignment}
          mobile={mobileAlignment}
        >
          {this.renderToggle()}
          <BobComponentHandler
            position={this.props.position}
            pageResponse={this.props.pageResponse}
            instanceId={this.props.obj.selectedInstanceId}
            object={this.props.obj}
            objectId={this.props.obj.uuid}
            inlineContent={this.props.obj.content.inlineContent}
            overrides={this.props.overrides}
            field={"background2"}
            rules={this.props.componentRule}
            template={this.props.obj.styles}
            expManager={this.props.expManager}
            flexDirection={"column"}
            className={`main-menu-container ${menuToggleComponentStyles["main-menu-container"]} ${
              !this.state.showMenu ? `hide ${menuToggleComponentStyles.hide}` : "show"
            }`}
          >
            <BobComponentHandler
              position={this.props.position}
              pageResponse={this.props.pageResponse}
              instanceId={this.props.obj.selectedInstanceId}
              object={this.props.obj}
              objectId={this.props.obj.uuid}
              field='icon2'
              template={this.props.obj.styles}
              rules={this.props.componentRule}
              expManager={this.props.expManager}
              iconClassName='fas fa-times'
              className={`main-menu-close ${menuToggleComponentStyles["main-menu-close"]} ${
                !this.state.showMenu ? `hide ${menuToggleComponentStyles.hide}` : "show"
              }`}
              onClick={this.handleMenuToggle}
            />
            {this.renderMenu(childComponentsId)}
          </BobComponentHandler>
        </AlignmentHOC>
      </div>
    )
  }
}

export default MenuToggleV2