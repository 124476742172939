import React, { Fragment } from "react"
import {
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { getOverrideInlineContentValues } from "../../../../../modules/shared-modules/utilities/components"
import { handleMediaId, handleMedia, handleSize } from "./bobMediaV2Handler"
import { ExpManager, PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import BobLoading from "../bobLoading"
import { getComposedUuid } from "../../../../../modules/shared-modules/utilities/utils"
import { MediaObject } from "../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { isNbobNmedia } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import MediaImage from "./mediaImage/mediaImage"
import MediaVideo from "./mediaVideo/mediaVideo"

// TODO: reduce props
interface BobMediaProps {
  object: SingleObject
  pageResponse: PageTypes
  replacedMediaList: { [id: string]: MediaObject } | undefined
  instanceId: string | undefined
  post: Post | undefined
  overrides: ObjectContentOverride | undefined
  field: string
  expManager: ExpManager
  setRef: (x: React.RefObject<HTMLDivElement>) => void | undefined
}

const BobMedia: React.FC<BobMediaProps> = (props): JSX.Element => {
  const mediaStyles = isNbobNmedia(props.object.styles.bobs.nmedia1)
  const hoverStyles = mediaStyles.behaviour?.hover
  const inlineContent = props.object.content.inlineContent

  const media = handleMedia(
    props.pageResponse,
    props.post,
    handleMediaId(
      props.post,
      props.pageResponse,
      props.field,
      props.object.content.inlineContent,
      props.overrides,
      hoverStyles?.enable,
      ".image-mappedValue"
    ),
    getOverrideInlineContentValues(`${props.field}.linkType`, {
      inlineContent: inlineContent,
      overrides: props.overrides,
    }),
    props.object,
    props.replacedMediaList
  )

  const renderMedia = () => {
    if (media.defaultMedia.type === "video")
      return (
        <MediaVideo
          media={media}
          setRef={(mediaRef) => props.setRef(mediaRef)}
          replacedMediaList={props.replacedMediaList}
          pageResponse={props.pageResponse}
          instanceId={props.object.selectedInstanceId}
          object={props.object}
          overrides={props.overrides}
          field={props.field}
          post={props.post}
          expManager={props.expManager}
        />
      )
    else
      return (
        <MediaImage
          media={media}
          setRef={(mediaRef) => props.setRef(mediaRef)}
          replacedMediaList={props.replacedMediaList}
          pageResponse={props.pageResponse}
          instanceId={props.object.selectedInstanceId}
          object={props.object}
          overrides={props.overrides}
          field={props.field}
          post={props.post}
          expManager={props.expManager}
        />
      )
  }

  return (
    <Fragment>
      <BobLoading
        type='media'
        uuid={getComposedUuid(props.pageResponse.objects[props.object.uuid])}
        contentSrc={getOverrideInlineContentValues(`${props.field}.contentSrc`, {
          inlineContent: inlineContent,
          overrides: props.overrides,
        })}
        mediaWidth={handleSize(mediaStyles.width)}
        mediaHeight={handleSize(mediaStyles.height)}
        mappedValue={getOverrideInlineContentValues(`${props.field}.image-mappedValue`, {
          inlineContent: inlineContent,
          overrides: props.overrides,
        })}
      >
        {renderMedia()}
      </BobLoading>
    </Fragment>
  )
}

export default BobMedia
