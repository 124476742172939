import styled from 'styled-components'
import { ResponsiveCSSProps } from './interface'

const AlignmentHOC = styled.div`
  ${(props: ResponsiveCSSProps) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px){
    ${(props: ResponsiveCSSProps) => props.tablet}
  }
  @media all and (max-width: 766px){
    ${(props: ResponsiveCSSProps) => props.mobile}
  }
`

export default AlignmentHOC