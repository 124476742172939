import * as inlineContentTypes from './experienceManager/finder/content/inlineContent/inlineContentTypes'

/**
 * 
 * @param props 
 * This method will write the new keys, properly updating embed and embedEnable
 * when video-enable is true, key used in the old system, since there was no support
 * for video upload.
 */
//TODO: Props types to be imported from mediaContentController 
export function handleMediaContentWrite(props: any) {
  const inlineContent = props.finderSelectedObject.content.inlineContent
  //HandleDeprecatedValues
  const resolvedDeprecatedValues = {
    //If both image-enable and video-enable are false we make enable false, else we read from the original one 
    enable: (!inlineContent[`${props.bobKey}.${inlineContentTypes.IMAGE_ENABLE}`] &&
      !inlineContent[`${props.bobKey}.${inlineContentTypes.VIDEO_ENABLE}`]) ?
      false : props.finderSelectedObject.styles.bobs[props.bobKey].enable,
    //same has the above, but for hover
    enableHover: (!inlineContent[`${props.bobKey}.${inlineContentTypes.IMAGE_ENABLE_HOVER}`] &&
      !inlineContent[`${props.bobKey}.${inlineContentTypes.VIDEO_ENABLE_HOVER}`]) ?
      false : props.finderSelectedObject.styles.bobs[props.bobKey].behaviour.hover?.enable,
    //same has the above, but for active
    enableActive: (!inlineContent[`${props.bobKey}.${inlineContentTypes.IMAGE_ENABLE_ACTIVE}`] &&
      !inlineContent[`${props.bobKey}.${inlineContentTypes.VIDEO_ENABLE_ACTIVE}`]) ?
      false : props.finderSelectedObject.styles.bobs[props.bobKey].behaviour.active?.enable,
    //If the embed-enable is false we see if we have the deprecated video-enable to fetch its value
    [inlineContentTypes.EMBED_ENABLE]: inlineContent[`${props.bobKey}.${inlineContentTypes.EMBED_ENABLE}`] ||
      inlineContent[`${props.bobKey}.${inlineContentTypes.VIDEO_ENABLE}`],
    //We write this false if the user touchs the mediaTypeController
    [inlineContentTypes.VIDEO_ENABLE]: inlineContent[`${props.bobKey}.${inlineContentTypes.VIDEO_ENABLE}`],
    //if we dont have an embed we get the one from the src if video-enable is true
    [inlineContentTypes.EMBED]: inlineContent[`${props.bobKey}.${inlineContentTypes.EMBED}`] ||
      (inlineContent[`${props.bobKey}.${inlineContentTypes.VIDEO_ENABLE}`] ? inlineContent[`${props.bobKey}.${inlineContentTypes.SRC}`] : ''),

  }
  return resolvedDeprecatedValues
}

/**
 * When the page is rendering a bobMedia, that still uses the old fields
 * for the video-enable, this method rewrites it to properly populate
 * embed and embedEnable fields
 */
export function bobMediaRenderReadFix(inlineContent: any, bobKey: string) {
  /**
       * if the video-enable is true, we have a deprecated style.
       * This style can be using the new structure already, so for that
       * we are now going to see if embedEnable is true, if not we change it.
       */
  if (inlineContent[`${bobKey}.video-enable`]) {
    //there is deprecated value, but its updated with the proper key
    if (inlineContent[`${bobKey}.embedEnable`])
      return inlineContent
    //We need to populate embed and embedEnable here
    const fixedInlineContent = {
      ...inlineContent,
      [`${bobKey}.embedEnable`]: true,
      [`${bobKey}.embed`]: inlineContent[`${bobKey}.src`]
    }
    return fixedInlineContent
  }
  return inlineContent
}
