import { v4 as uuidv4 } from "uuid"
import { Link } from "../../../../split/shared-modules/LinkManager/Link"
import React from "react"
import { LinkManagerProps } from "./interface"

const LinkManager: React.FC<LinkManagerProps> = (props): any => {
  const { children } = props

  let key = props.innerKey ? props.innerKey : uuidv4()

  return (
    <Link
      className={props.className}
      style={props.style}
      key={key}
      to={props.to}
      passHref
      href={props.to}
      onClick={props.onClick}
      expManager={props.expManager}
    >
      {children}
    </Link>
  )
}

export default LinkManager
