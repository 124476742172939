import React from 'react'
import VideoPlayerSplit from "../../../../../split/shared-public-page/bobMediaVideoPlayer/videoPlayerSplit"

interface CustomMediaPlayerProps {
  url: string
  controls: boolean
  config: any
  playPauseAsset: string
}

type CustomMediaPlayerState = {
  isPlaying: boolean
}

class CustomMediaPlayer extends React.Component<CustomMediaPlayerProps, CustomMediaPlayerState> {
  constructor(props: CustomMediaPlayerProps) {
    super(props)
    this.state = {
      isPlaying: false,
    }
    this.handlePlayClick = this.handlePlayClick.bind(this)
  }

  handlePlayClick() {
    this.setState({
      ...this.state,
      isPlaying: !this.state.isPlaying,
    })
  }

  render() {
    const { url, controls, config, playPauseAsset } = this.props
    return (
      <div className='custom-video-player'>
        <div className='play-pause-wrap' onClick={this.handlePlayClick}>
          <img
            className={`play-pause-asset ${this.state.isPlaying ? "playing" : "paused"}`}
            src={playPauseAsset}
            alt='play'
          />
        </div>
        <VideoPlayerSplit
          videoType='normal'
          url={url}
          controls={controls}
          playing={this.state.isPlaying}
          config={config}
        />
      </div>
    )
  }
}

export default CustomMediaPlayer