import React from "react"
import { v4 as uuidv4 } from "uuid"
//@ts-ignore
import parse5 from "parse5"
import flatten from "lodash/flatten"
//@ts-ignore
import postscribe from "postscribe"
import WarningSystem from "../../../../modules/shared-modules/experienceManager/warningSystem"
import { ExpManager } from "../../../../modules/shared-modules/experienceManager/types/pageTypes"

interface DangerousHtmlProps {
  html: string
  expManager: ExpManager
  style: any
  className?: string
  onMouseEnter?: MouseEvent
  onMouseLeave?: MouseEvent
  id?: string
}

type DangerousHtmlState = {
  id: string
  scripts: any
  srcs: any
}

class DangerousHtml extends React.Component<
  DangerousHtmlProps,
  DangerousHtmlState
> {
  constructor(props: DangerousHtmlProps) {
    super(props)
    this.state = {
      id: uuidv4(),
      ...this.handleScripts(props.html),
    }
  }

  findScripts(node: any, keyPos: any) {
    if (node.tagName && node.tagName === "script") {
      return (node[keyPos] || []).map((n: any) => n.value)
    }

    return flatten(
      (node.childNodes || []).map((nodeChild: any) =>
        this.findScripts(nodeChild, keyPos)
      )
    )
  }

  handleScripts(html: any) {
    if (!html || typeof html !== "string") {
      return {
        scripts: undefined,
        srcs: undefined,
      }
    }
    return {
      scripts: this.findScripts(parse5.parseFragment(html), "childNodes"),
      srcs: this.findScripts(parse5.parseFragment(html), "attrs"),
    }
  }

  componentDidMount() {
    if (!this.props.expManager.enable) {
      for (const script of this.state.srcs || []) {
        script !== "" &&
          postscribe(`#${this.state.id}`, `<script src=${script}></script>`)
      }
      // eslint-disable-next-line
      let fns = (this.state.scripts || [])
        .map((src: any) => {
          // eslint-disable-next-line
          return new Function("require", src)
        })
        .forEach((fn: any) => {
          return fn()
        })
    }
  }

  render() {
    if (typeof this.props.html !== "string")
      return (
        <WarningSystem
          alertType="alert-warning"
          messages="Missing valid props to DangerousHtml component"
        />
      )

    return React.createElement("div", {
      id: this.props.id ? this.props.id : this.state.id,
      style: this.props.style,
      dangerouslySetInnerHTML: {
        __html: this.props.html,
      },
      className: `dangerousHtml ${this.props.className ?? ""}`,
      onMouseEnter: this.props.onMouseEnter,
      onMouseLeave: this.props.onMouseLeave,
    })
  }
}

export default DangerousHtml
