import { responsiveProperties } from "../../../areas/public-page/shared-public-page/bob/bobHandler"
import ControllerVersion from "../experienceManager/finder/inputs/controllerVersion"
import {
  defaultAlign,
  specificAlign,
} from "../../../areas/public-page/shared-public-page/fluxio-components/utils/componentAlignment"
import { handleAlignment } from "./components"
import { BehaviourState_, Breakpoint } from "../experienceManager/finder/inputs/bobControllerTypes"
import {
  BorderCSS,
  cssRenderUnsafe as cssRenderUnsafeBorder,
  renderCSSString as renderCSSStringBorder,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/borderStyle"
import { cssRenderUnsafe as cssRenderUnsafeBorder_old } from "../../../areas/public-page/shared-public-page/bob/bobStyles/borderStyle_old"
import {
  BoundaryCSS,
  cssRenderUnsafe as cssRenderUnsafeBoundary,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/boundaryStyle"
import { cssRenderUnsafe as cssRenderUnsafeBoundary_old } from "../../../areas/public-page/shared-public-page/bob/bobStyles/boundaryStyle_old"
import {
  ColorsCSS,
  cssRenderUnsafe as cssRenderUnsafeColors,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/colorsStyle"
import { cssRenderUnsafe as cssRenderUnsafeColors_old } from "../../../areas/public-page/shared-public-page/bob/bobStyles/colorsStyle_old"
import {
  ShadowCSS,
  cssRenderUnsafe as cssRenderUnsafeShadow,
  ShadowType,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/shadowStyle"
import { cssRenderUnsafe as cssRenderUnsafeShadow_old } from "../../../areas/public-page/shared-public-page/bob/bobStyles/shadowStyle_old"
import { FColor } from "../foundationStyles/foundationStylesTypes"

/**
 * Alignment version
 *
 */

export function alignment(template: any) {
  const controllerVersion = template.controllerVersion?.alignment
  /**
   * return version 1 fix
   */
  if (controllerVersion === 1) return defaultAlign(template)
  /**
   * return old logic
   */
  const alignmentStyles = handleAlignment(template)
  const tabletAlignment = responsiveProperties(template, "tablet", { alignment: {} }, [], ["alignment"])
  const mobileAlignment = responsiveProperties(template, "mobile", { alignment: {} }, [], ["alignment"])
  return { alignmentStyles, tabletAlignment, mobileAlignment }
}

export function alignmentWithAP(template: any, alignmentAdittionalProperties: any) {
  const controllerVersion = template.controllerVersion?.alignment
  /**
   * return version 1 fix
   */
  if (controllerVersion === 1) {
    return specificAlign(template, alignmentAdittionalProperties)
  }
  /**
   * return old logic
   */
  const alignmentStyles = handleAlignment(template, alignmentAdittionalProperties.flexDirection)
  const tabletAlignment = responsiveProperties(
    template,
    "tablet",
    { alignment: { flexDirection: alignmentAdittionalProperties.flexDirection } },
    [],
    ["alignment"]
  )
  const mobileAlignment = responsiveProperties(
    template,
    "mobile",
    { alignment: { flexDirection: alignmentAdittionalProperties.flexDirection } },
    [],
    ["alignment"]
  )
  return { alignmentStyles, tabletAlignment, mobileAlignment }
}

/**
 * Border version
 *
 */

export function conditionalBorderRenderUnsafe(
  template: any,
  breakpoint: Breakpoint,
  state: BehaviourState_,
  renderImportant: boolean
): BorderCSS | {} {
  const controllerVersion = template.controllerVersion?.border
  /**
   * return version 1 fix
   */
  if (controllerVersion === 1) return cssRenderUnsafeBorder(template, breakpoint, state)
  /**
   * return old logic
   */
  return cssRenderUnsafeBorder_old(template, breakpoint, state)
}

export function conditionalBorderCss(borderStyle: BorderCSS | {}, controllerVersion: number | undefined): string {
  /**
   * return version 1 fix
   */
  if (controllerVersion === 1) return renderCSSStringBorder(borderStyle)
  /**
   * return old logic
   */
  const borderStyle_old = borderStyle as BorderCSS
  return `
    border-bottom-style: ${borderStyle_old["border-bottom-style"]};
    border-bottom-width: ${borderStyle_old["border-bottom-width"]};
    border-bottom-color: ${borderStyle_old["border-bottom-color"]};
    border-top-style: ${borderStyle_old["border-top-style"]};
    border-top-width: ${borderStyle_old["border-top-width"]};
    border-top-color: ${borderStyle_old["border-top-color"]};
    border-left-style: ${borderStyle_old["border-left-style"]};
    border-left-width: ${borderStyle_old["border-left-width"]};
    border-left-color: ${borderStyle_old["border-left-color"]};
    border-right-style: ${borderStyle_old["border-right-style"]};
    border-right-width: ${borderStyle_old["border-right-width"]};
    border-right-color: ${borderStyle_old["border-right-color"]};
  `
}

/**
 * Boundary version
 *
 */

export function conditionalBoundaryRenderUnsafe(
  template: any,
  breakpoint: Breakpoint,
  state: BehaviourState_
): BoundaryCSS | {} {
  const controllerVersion = template.controllerVersion?.spacing
  /**
   * return version 1 fix
   */
  if (controllerVersion === 1) return cssRenderUnsafeBoundary(template, breakpoint, state)
  /**
   * return old logic
   */
  return cssRenderUnsafeBoundary_old(template, breakpoint, state)
}

/**
 * Shadow version
 *
 */

export function conditionalShadowRenderUnsafe(
  template: any,
  breakpoint: Breakpoint,
  state: BehaviourState_,
  shadowType: ShadowType,
  foundationStyle: FColor | undefined
): ShadowCSS | {} {
  const controllerVersion = template.controllerVersion?.shadow
  /**
   * return version 1 fix
   */
  if (controllerVersion === 1) return cssRenderUnsafeShadow(template, breakpoint, state, shadowType, foundationStyle)
  /**
   * return old logic
   */
  return cssRenderUnsafeShadow_old(template, breakpoint, state, shadowType, foundationStyle)
}

/**
 * Colors version
 *
 */

export function conditionalColorsRenderUnsafe(
  template: any,
  breakpoint: Breakpoint,
  state: BehaviourState_,
  shouldRenderImportant: boolean
): ColorsCSS | {} {
  const controllerVersion = template.controllerVersion?.colors
  /**
   * return version 1 fix
   */
  if (controllerVersion === 1) return cssRenderUnsafeColors(template, breakpoint, state, undefined)
  /**
   * return old logic
   */
  return cssRenderUnsafeColors_old(template, breakpoint, state)
}

/**
 * writeVersion
 *
 */

export function writeVersion(controller: string, bobKey: string): { [x: string]: number } | undefined {
  const BobKey = bobKey.slice(0, -1)
  const capitalizedBobKey = BobKey.charAt(0).toUpperCase() + BobKey.slice(1)
  const version = ControllerVersion[controller]

  if (version?.all) {
    return {
      [controller]: version.all,
    }
  } else if (version?.[BobKey]) {
    return {
      [`${controller}${capitalizedBobKey}`]: version[BobKey],
    }
  }
  return undefined
}
