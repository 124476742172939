import { NextRouter } from "next/router";


export function routeArrayByNumber(
  matchOrder: number | undefined = 0,
  route: string | string[] | undefined,
  uri: any
): string {
  if (!route) return ""

  if (typeof route === "string") return route

  let stringPath = ""

  for (let i = 0; i < matchOrder; i++) {
    stringPath += `${route[i]}/`
  }

  return `${stringPath}${uri ? uri : ""}`
}

export function handler(router: NextRouter, matchOrder: number | undefined = 0, uri: any) {
  let route = router.query
  const { pageName } = route
  return routeArrayByNumber(matchOrder, pageName, uri)
}

export function route(matchOrder: number | undefined = 0, route: NextRouter): string {
  const { pageName } = route.query
  if (!pageName) return "NO-ROUTE"

  if (typeof pageName === "string") return pageName

  return pageName[matchOrder]
}

