import React, { useState, useEffect, useRef } from "react"
import BobComponentHandler from "../../../../bob/bobComponentHandler"
import WarningSystem from "../../../../../../../modules/shared-modules/experienceManager/warningSystem"
import {
  getContent,
  getStringField,
  handleObjectType,
} from "../../../../../../../modules/shared-modules/utilities/components"
import { clickEvent } from "../../../../analytics/analytics"
//import CountryCode from '../../../../modules/routeCreator/countryCode';
import ComponentHandler from "../../../../../componentHandler"
import AlignmentHOC from "../../../alignmentHOC/alignmentHOC"
import { handleColumns, responsiveGuttersClass } from "../../../../bob/bobHandler"
import { alignment } from "../../../../../../../modules/shared-modules/utilities/conditionalController"
import { Post } from "../../../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { DropdownV2Props } from "./interface"
import elementDropdownV2Styles from "../../../../../../../stylesheets/modules/templates/elementDropdown_v2.module.sass"
import {
  BobBackground,
  isBobBackground,
} from "../../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const DropdownV2: React.FC<DropdownV2Props> = (props) => {
  let dropdownNodeRef = useRef<HTMLElement>(null)

  const [dropdownOpen, _setDropdownOpen] = useState<Boolean>(false)
  const [isHover, setIsHover] = useState<Boolean>(false)

  const dropdownOpenRef = useRef(dropdownOpen)
  const setDropdownOpen = (data: any) => {
    dropdownOpenRef.current = data
    _setDropdownOpen(data)
  }

  const buttonOnHover = () => setIsHover(true)

  const buttonUnHover = () => setIsHover(false)

  useEffect(
    () => {
      if (!props.expManager.enable) document.addEventListener("mousedown", handleClick, false)
      //When inside Iframe at EM
      const IframeElement = document.getElementById("board-preview-frame") as HTMLIFrameElement
      if (IframeElement) {
        IframeElement.contentDocument?.addEventListener("mousedown", handleClick, false)
      }
      return () => {
        if (!props.expManager.enable) document.removeEventListener("mousedown", handleClick, false)
        //When inside Iframe at EM
        const IframeElement = document.getElementById("board-preview-frame") as HTMLIFrameElement
        if (IframeElement) {
          IframeElement.contentDocument?.removeEventListener("mousedown", handleClick, false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleClick = (e: Event) => {
    if (dropdownNodeRef.current && dropdownNodeRef.current.contains(e.target as Node)) {
      //inside element
    } else {
      if (dropdownOpenRef.current) setDropdownOpen(false)
    }
  }

  const handleButtonClick = (buttonLabel: string) => {
    clickEvent(`button-${buttonLabel}`, "Dropdown")
    setDropdownOpen(!dropdownOpen)
  }

  const renderDropdownItems = () => {
    const itemsId = props.obj.children
    if (itemsId && itemsId.length > 0) {
      return itemsId.map((objId: string, idx: number) => {
        const handledItem = handleObjectType(props.obj, objId, props.pageResponse, props.expManager.emEditorType)
        return (
          <div key={objId} className={`dropdown-item ${elementDropdownV2Styles["dropdown-item"]}`}>
            <ComponentHandler
              position={idx}
              selectedInstanceId={props.obj.selectedInstanceId}
              component={handledItem}
              pageResponse={props.pageResponse}
              matchOrder={props.matchOrder}
              expManager={props.expManager}
            />
          </div>
        )
      })
    }
  }

  const renderDropdown = (background1Template: BobBackground) => {
    const content = getContent(props.obj, "posts")
    if (content && content.length > 0) {
      let counter = { desktop: 0, tablet: 0, mobile: 0 }
      return content.map((post: Post, idx: number) => {
        const columns = handleColumns(
          idx,
          counter,
          background1Template,
          props.pageResponse.globalStyles[background1Template.globalStyleId]
        )
        counter = columns.counter
        const buttonLabel = getStringField(post, props.obj, props.pageResponse, "text1", props.overrides)
        return (
          <div
            className={`col-${columns.columnsMobile} col-md-${columns.columnsTablet} col-lg-${columns.columns}`}
            key={idx}
          >
            <div className='dropdown'>
              <BobComponentHandler
                position={props.position}
                pageResponse={props.pageResponse}
                instanceId={props.obj.selectedInstanceId}
                object={props.obj}
                objectId={props.obj.uuid}
                post={post}
                inlineContent={props.obj.content.inlineContent}
                overrides={props.overrides}
                field={"background2"}
                rules={props.componentRule}
                template={props.obj.styles}
                expManager={props.expManager}
                className={`btn btn-fluxio btn-lg dropdown-toggle ${elementDropdownV2Styles["dropdown-toggle"]} ${
                  dropdownOpen ? `isOpen ${elementDropdownV2Styles.isOpen}` : ""
                }`}
                tag='button'
                onClick={() => handleButtonClick(buttonLabel)}
                onMouseEnter={buttonOnHover}
                onMouseLeave={buttonUnHover}
              >
                <BobComponentHandler
                  position={props.position}
                  pageResponse={props.pageResponse}
                  replacedMediaList={props.replacedMediaList}
                  instanceId={props.obj.selectedInstanceId}
                  object={props.obj}
                  objectId={props.obj.uuid}
                  inlineContent={props.obj.content.inlineContent}
                  overrides={props.overrides}
                  field='media1'
                  template={props.obj.styles}
                  rules={props.componentRule}
                  expManager={props.expManager}
                  network={post.network}
                  post={post}
                  className={`${isHover ? "isHover" : ""}`}
                />
                <BobComponentHandler
                  position={props.position}
                  pageResponse={props.pageResponse}
                  instanceId={props.obj.selectedInstanceId}
                  object={props.obj}
                  objectId={props.obj.uuid}
                  post={post}
                  inlineContent={props.obj.content.inlineContent}
                  overrides={props.overrides}
                  field='text1'
                  tag='p'
                  value={buttonLabel}
                  template={props.obj.styles}
                  rules={props.componentRule}
                  expManager={props.expManager}
                  network={post.network}
                  className={`label ${elementDropdownV2Styles.label} ${isHover ? "isHover" : ""}`}
                />
                <BobComponentHandler
                  position={props.position}
                  pageResponse={props.pageResponse}
                  instanceId={props.obj.selectedInstanceId}
                  object={props.obj}
                  objectId={props.obj.uuid}
                  field='icon1'
                  template={props.obj.styles}
                  rules={props.componentRule}
                  expManager={props.expManager}
                  iconClassName='fas fa-chevron-down'
                />
              </BobComponentHandler>
              <div
                className={`dropdown-menu ${elementDropdownV2Styles["dropdown-menu"]} ${
                  dropdownOpen ? `isOpen ${elementDropdownV2Styles.isOpen}` : ""
                }`}
              >
                {dropdownOpen && (
                  <BobComponentHandler
                    position={props.position}
                    pageResponse={props.pageResponse}
                    instanceId={props.obj.selectedInstanceId}
                    object={props.obj}
                    key={idx}
                    objectId={props.obj.uuid}
                    inlineContent={props.obj.content.inlineContent}
                    overrides={props.overrides}
                    field={"background3"}
                    flexDirection='column'
                    rules={props.componentRule}
                    template={props.obj.styles}
                    expManager={props.expManager}
                  >
                    {renderDropdownItems()}
                  </BobComponentHandler>
                )}
              </div>
            </div>
          </div>
        )
      })
    } else {
      if (props.expManager.enable) {
        return (
          <div className='col-sm-12'>
            <WarningSystem alertType='alert-secondary w-100 text-center' messages='Add a single post or a feed' />
          </div>
        )
      }
    }
  }

  /**
   * Responsive Alignment Styles
   */
  const background1 = props.obj.styles.bobs.background1
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)

  return (
    <section
      className={`elements ${elementDropdownV2Styles.elements} button dropdown_v2 ${elementDropdownV2Styles.dropdown_v2}`}
      ref={dropdownNodeRef}
    >
      <AlignmentHOC
        className={`row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        {renderDropdown(isBobBackground(background1))}
      </AlignmentHOC>
    </section>
  )
}

export default DropdownV2
