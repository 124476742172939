import React from "react"
import {
  FormField,
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { ExpManager, PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { SingleObject } from "../../../../../modules/shared-modules/experienceManager/types/singleObjectTypes"
import { isBobFormV2 } from "../../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import styled, { css } from "styled-components"
import { handleDOMid } from "../../../../../modules/shared-modules/utilities/symbols"
import GlobalStylesUtils from "../../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { emPreviewClass, emScrollId } from "../bobUtils"
import { MediaObject } from "../../../../../modules/shared-modules/experienceManager/types/contentTypes"
import publicUtils from "../../../../../stylesheets/modules/utils/public-components.module.sass"
import { responsiveStyles } from "./bobFormHandler"
import { handleMediaQueryRestrictions, handleStateStyles } from "../bobHandlerV2"
import { BobFormStyles, ResponsiveCSSProps } from "../bobTypes"
import { NGS_CLASS_SUFIX } from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import * as gsType from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import axios from "axios"
import apiEnv from "../../../../../api/shared-api/apiEnv"
import simpleFormStyles from "../../../../../stylesheets/modules/templates/simpleForm.module.sass"
import { withGoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

interface OwnProps {
  id: string
  pageResponse: PageTypes
  instanceId: string | undefined
  object: SingleObject
  inlineContent: ObjectContent["inlineContent"]
  overrides: ObjectContentOverride | undefined
  template: any
  post: Post | undefined
  field: string
  children: React.ReactNode | undefined
  expManager: ExpManager
  setRef: (x: React.RefObject<HTMLFormElement>) => void | undefined
  replacedMediaList: { [id: string]: MediaObject } | undefined
  form: SingleObject["content"]["form"]
  googleReCaptchaProps?: any
}

interface BobFormState {
  submitFeedback:
    | {
        type: "success" | "error"
        text: string
      }
    | undefined
}

type BobFormProps = OwnProps

const FormDiv = styled.form`
  ${(props: ResponsiveCSSProps<BobFormStyles>) => props.desktop}
  ${(props: ResponsiveCSSProps<BobFormStyles>) =>
    props.hover.isEnabled.desktop
      ? css`
          @media all ${handleMediaQueryRestrictions(props.hover.isEnabled.mobile, props.hover.isEnabled.tablet)} {
            &:hover {
              ${props.hover.desktop}
            }
          }
        `
      : {}}

  ${(props: ResponsiveCSSProps<BobFormStyles>) =>
    Object.keys(props.tablet).length > 0 || Object.keys(props.hover.tablet).length > 0
      ? css`
          @media all and (max-width: 992px) and (min-width: 767px) {
            ${(props: ResponsiveCSSProps<BobFormStyles>) => props.tablet}
            ${(props: ResponsiveCSSProps<BobFormStyles>) =>
              props.hover.isEnabled.tablet && Object.keys(props.hover.tablet).length > 0
                ? css`
                    &:hover {
                      ${props.hover.tablet}
                    }
                  `
                : {}}
          }
        `
      : {}}

  ${(props: ResponsiveCSSProps<BobFormStyles>) =>
    Object.keys(props.mobile).length > 0 || Object.keys(props.hover.mobile).length > 0
      ? css`
          @media all and (max-width: 766px) {
            ${(props: ResponsiveCSSProps<BobFormStyles>) => props.mobile}
            ${(props: ResponsiveCSSProps<BobFormStyles>) =>
              props.hover.isEnabled.mobile && Object.keys(props.hover.mobile).length > 0
                ? css`
                    &:hover {
                      ${props.hover.mobile}
                    }
                  `
                : {}}
          }
        `
      : {}}
`

class BobForm extends React.Component<BobFormProps, BobFormState> {
  private formRef: React.RefObject<HTMLFormElement>

  constructor(props: BobFormProps) {
    super(props)
    this.state = {
      submitFeedback: undefined,
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.props.setRef && this.props.setRef(this.formRef)
  }

  setSubmitFeedback = (submitFeedback: BobFormState["submitFeedback"]) => this.setState({ submitFeedback })

  onSubmit = async (event: any): Promise<any> => {
    event.preventDefault()
    const form = this.props.form
    const reacaptchToken = form?.captcha ? await this.props.googleReCaptchaProps.executeRecaptcha("form") : undefined
    const formData = new FormData(event.target)
    let formObject = Object.fromEntries(formData.entries())

    // change formObject keys created with input name to input id
    if (form)
      formObject = Object.assign(
        {},
        ...Object.keys(formObject).map((nameKey: string) => {
          const fieldId = Object.values(form?.fields).filter((field: FormField) => field.name === nameKey)[0].id
          return { [fieldId]: formObject[nameKey] }
        })
      )

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
      },
    }
    const body = {
      token: reacaptchToken,
      values: formObject,
    }

    axios
      .post(`${apiEnv()}v1/forms/${form?.uuid}/submit`, JSON.stringify(body), config)
      .then((res: { data: any }) => {
        // error response
        if (res.data?.error)
          this.setSubmitFeedback({
            type: "error",
            text: form?.lang === "pt" ? "Algo correu mal." : "Something went wrong.",
          })
        // success response
        else
          this.setSubmitFeedback({
            type: "success",
            text: form?.lang === "pt" ? "Formulário enviado com sucesso." : "Form submitted successfully.",
          })
      })
      .catch((error) => {
        console.warn("err", error)
        this.setSubmitFeedback({
          type: "error",
          text: form?.lang === "pt" ? "Algo correu mal." : "Something went wrong.",
        })
      })
  }

  render() {
    //@ts-ignore
    const templateStyles = isBobFormV2(this.props.object.styles.bobs.nform1)
    const settings = this.props.object.settings
    const pageLabels = this.props.pageResponse.stylesheet?.labels

    const DesktopStyles = responsiveStyles(templateStyles, pageLabels, "desktop", "default")
    const TabletStyles = responsiveStyles(templateStyles, pageLabels, "tablet", "default")
    const MobileStyles = responsiveStyles(templateStyles, pageLabels, "mobile", "default")
    const hoverStyles = handleStateStyles<BobForm, BobFormStyles>(
      templateStyles,
      responsiveStyles(templateStyles, pageLabels, "desktop", "hover"),
      responsiveStyles(templateStyles, pageLabels, "tablet", "hover"),
      responsiveStyles(templateStyles, pageLabels, "mobile", "hover")
    )

    const DOMid = this.props.expManager.enable
      ? { id: `${handleDOMid(this.props.field, this.props.id, this.props.instanceId)}` }
      : {}
    const EMpreviewClass = emPreviewClass(this.props.expManager.enable, templateStyles.uuid)
    const EMScrollId = emScrollId(this.props.expManager.enable, this.props.object.uuid)
    const GlobalStylesClass = GlobalStylesUtils.handleClassNameV2(
      this.props.pageResponse.nGlobalStyles,
      templateStyles,
      "-box",
      {
        [gsType.GS_SHADOW]: NGS_CLASS_SUFIX,
        [gsType.GS_BOUNDARY]: NGS_CLASS_SUFIX,
      }
    )
    const customClasses = settings?.[this.props.field]?.class
      ? ` ${settings?.[this.props.field]?.class?.join(" ")}`
      : ""
    const classes = `${publicUtils["flx_flex"]}${EMpreviewClass ? ` ${EMpreviewClass} ` : ""}${
      GlobalStylesClass ? ` ${GlobalStylesClass} ` : ""
    }${customClasses}`

    return (
      <FormDiv
        onSubmit={(e) => this.onSubmit(e)}
        ref={this.formRef}
        desktop={DesktopStyles}
        mobile={MobileStyles}
        tablet={TabletStyles}
        hover={hoverStyles}
        {...EMScrollId}
        {...DOMid}
        className={classes}
      >
        {this.props.children}
        {this.state.submitFeedback && (
          <div className={`simple-form-component ${simpleFormStyles["simple-form-component"]}`}>
            <div className='submit-feedback ml-0'>
              <span
                className={
                  this.state.submitFeedback.type
                    ? this.state.submitFeedback.type === "error"
                      ? "submit-error"
                      : "submit-success"
                    : ""
                }
              >
                {this.state.submitFeedback.text}
              </span>
            </div>
          </div>
        )}
      </FormDiv>
    )
  }
}

const BobFormRecaptchaWrapChild = withGoogleReCaptcha(BobForm)

const BobFormRecaptchaWrap: React.FC<BobFormProps> = (props): JSX.Element => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={props.form?.captcha?.key}>
      <BobFormRecaptchaWrapChild {...props} />
    </GoogleReCaptchaProvider>
  )
}

export default BobFormRecaptchaWrap
