import React, { CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ComponentSelectorEMProps } from '.'
import {
  hubHandlerSelected,
  selectFinder,
  objectSelectionHover,
  viewPortSizeStateExperienceManager,
  changeResponsiveTab,
} from "../../../../store/shared-store/actions/publicPageActions"
import { selectSymbolInstance, setSymbolContentType } from "../../../../store/shared-store/actions/symbolsActions"
import { editSymbolState } from "../../../../store/shared-store/actions/pageTreeActions"
import {
  handleFilterInstanceObj,
  handleFilterSymbolObject,
  getObjectFromSymbolObject,
  handleUniqueid,
} from "../../../../modules/shared-modules/utilities/symbols"
import { handleObjectsListType } from "../../../../modules/shared-modules/utilities/components"
import {
  CONTENT_TAB,
  DEFAULT_FINDER_TAB,
} from "../../../../modules/shared-modules/experienceManager/types/pageTreeTypes"
import { CONTENT_TYPE_INSTANCE } from "../../../../modules/shared-modules/experienceManager/finder/symbols/symbolTypes"
import { isBobBackground } from "../../../../modules/shared-modules/experienceManager/types/stylesTypes"
import Devices from "../../../skynet/experienceManager/utils/devices"
import * as viewPort from "../../../../modules/shared-modules/experienceManager/viewPortTypes/viewPortTypes"

const ComponentSelectorEM: React.FC<ComponentSelectorEMProps> = (props): JSX.Element => {
  const dispatch = useDispatch()

  const actions = bindActionCreators(
    {
      hubHandlerSelected,
      selectFinder,
      objectSelectionHover,
      selectSymbolInstance,
      setSymbolContentType,
      editSymbolState,
      viewPortSizeStateExperienceManager,
      changeResponsiveTab,
    },
    dispatch
  )
  const selectedSingleObject = useSelector((state: any) => state.publicPageReducer.hubHandlerObject)
  const contentHub = useSelector((state: any) => state.contentHubReducer.hubContent)
  const { symbolObj } = useSelector((state: any) => state.pageTreeReducer)
  const viewPortSizeState = useSelector((state: any) => state.publicPageReducer.viewPortSizeState)
  const viewPortOriginalSizeState = useSelector(
    (state: any) => state.experienceManagerReducer.viewPortOriginalSizeState
  )

  const { objectSelectorModeEM, objectSelectorStateEM, finderType, emEditorType } = useSelector(
    (state: any) => state.experienceManagerReducer
  )

  const { objectSelectorHover } = useSelector((state: any) => state.publicPageReducer)

  const handleObjectClick = (e: any) => {
    /**
     * Only trigger if in object selection Mode
     * @param objectSelectorModeEM (boolean)
     */
    if (objectSelectorModeEM) {
      e.stopPropagation()
      e.preventDefault()

      // symbol root object clicked
      if (props.obj.fromSymbolTree && props.obj.type === "root" && props.obj.selectedInstanceId) {
        const symbolInstanceObj = handleFilterInstanceObj(contentHub, props.obj.selectedInstanceId)
        const symbolToUse = handleFilterSymbolObject(symbolInstanceObj, contentHub.symbols, false)
        actions.hubHandlerSelected(symbolToUse.uuid, undefined, symbolToUse)
        actions.selectSymbolInstance(symbolInstanceObj.uuid, symbolInstanceObj)
        actions.selectFinder(handleOpenTab(), symbolToUse)
        //directly open styles for symbol
        actions.editSymbolState({ symbolObj: symbolToUse }, CONTENT_TAB)
        // if fromSymbolTree is different set instance edit mode
        if (props.obj.fromSymbolTree !== selectedSingleObject.fromSymbolTree) {
          actions.setSymbolContentType(CONTENT_TYPE_INSTANCE)
        }
      }
      // symbol child clicked
      else if (props.obj.fromSymbolTree && props.obj.type === "object" && props.obj.selectedInstanceId) {
        //Gets original object without the overrides
        const objToUse = getObjectFromSymbolObject(contentHub, props.obj)
        const symbolInstanceObj = handleFilterInstanceObj(contentHub, props.obj.selectedInstanceId)
        const symbolToUse = handleFilterSymbolObject(symbolInstanceObj, contentHub.symbols, false)
        actions.hubHandlerSelected(props.obj.uuid, undefined, objToUse)
        actions.selectSymbolInstance(symbolInstanceObj.uuid, symbolInstanceObj)
        actions.selectFinder(handleOpenTab(), objToUse)
        //directly open styles for symbol
        actions.editSymbolState({ symbolObj: symbolToUse }, CONTENT_TAB)
        // if fromSymbolTree is different set instance edit mode
        if (props.obj.fromSymbolTree !== selectedSingleObject.fromSymbolTree) {
          actions.setSymbolContentType(CONTENT_TYPE_INSTANCE)
        }
      }
      // object clicked
      else {
        if (!props.obj.selectedInstanceId) {
          actions.editSymbolState(false)
          actions.setSymbolContentType(CONTENT_TYPE_INSTANCE)
        }
        //clear selected instance
        if (!props.obj.fromSymbolTree) actions.selectSymbolInstance(undefined)
        const SingleObjectToUse = handleObjectsListType(contentHub, props.obj, emEditorType)[props.obj.uuid]
        actions.hubHandlerSelected(SingleObjectToUse.objectUuid, SingleObjectToUse.parentId, SingleObjectToUse)
        actions.selectFinder(handleOpenTab(), SingleObjectToUse)
      }

      //Return to desktop mode when in Fullscreen
      if (viewPortSizeState === "100%_NO_BARS") {
        const viewport = Devices.handleViewPortMaxSize(viewPort.DESKTOP, 100, viewPortOriginalSizeState)
        actions.viewPortSizeStateExperienceManager(viewport.size, viewport.scale)
        actions.changeResponsiveTab("responsive")
      }
    }
  }

  const handleOpenTab = () => {
    if (finderType === "template" || finderType === CONTENT_TAB) {
      return finderType
    }
    return DEFAULT_FINDER_TAB
  }

  /**
   * handle validate visiblity
   * based on objt uuid or instance uuid
   */
  const handleVisibilityOnHover = () => {
    if (objectSelectorHover) {
      let id = handleUniqueid(props.obj, contentHub.symbols)
      if (objectSelectorHover === id) return true
      return false
    }
    return false
  }

  const handleElementEnter = (e: any) => {
    e.stopPropagation()
    /**
     * element hover logic
     * when hover is on a symbol obj, use the selectedInstanceId
     */
    if (objectSelectorModeEM) {
      let obj = props.obj
      // when hovering a symbol object that inst the root object,
      // we must use the root object himself so that the instance on the tree matches the objectSelectorModeEM id
      if (
        props.obj.fromSymbolTree &&
        props.obj.type !== "root" &&
        props.expManager.emEditorType !== "symbol" &&
        !symbolObj
      ) {
        let symbol = contentHub.symbols[props.obj.fromSymbolTree]
        obj = symbol.objects[symbol.rootId]
      }
      let id = handleUniqueid({ ...obj, selectedInstanceId: props.obj.selectedInstanceId }, contentHub.symbols)
      actions.objectSelectionHover(id)
    }
  }

  const handleElementLeave = (e: any) => {
    e.stopPropagation()
    /**
     * element hover logic
     */
    if (objectSelectorModeEM) {
      actions.objectSelectionHover(false)
    }
  }

  let outerDivStyle = {
    position: "relative",
  } as CSSProperties

  if (props.expManager.enable && !props.expManager.content) {
    let isSelected = false
    let classes = ""
    if (objectSelectorStateEM) {
      isSelected = true
      classes += "editing "
    }
    const displayBoundaries = objectSelectorModeEM ? "block" : "none"

    // TODO: should every component have a background1?
    const background1Obj = props.obj.styles.bobs?.background1
      ? isBobBackground(props.obj.styles.bobs.background1)
      : undefined
    const Template =
      background1Obj && background1Obj.spacing && background1Obj.spacing.enable
        ? background1Obj.spacing
        : {
            margin: { top: 0, bottom: 0, left: 0, right: 0 },
            padding: { top: 0, bottom: 0, left: 0, right: 0 },
          }
    /**
     *
     * Border/Padding/Content layout
     *
     * To emulate visually the borders, paddings and content with the charro feature
     * we need to create a frame around the content with the two options avaiable
     *
     * MARGIN TOP */
    const MarginTop = (
      <div
        className='margin top'
        style={{
          width: "100%",
          height: `${Template.margin.top}px`,
          left: "0",
          top: `-${Template.margin.top}px`,
        }}
      />
    )
    /* Margin top will go from left to right using 100%
     * The height will equal the margin top selected in the interface
     * To visually apply it over the real margin he we need to give it a negative top equal to its height
     *
     * MARGIN BOTTOM */
    const MarginBottom = (
      <div
        className='margin bottom'
        style={{
          width: "100%",
          height: `${Template.margin.bottom}px`,
          right: "0",
          bottom: `-${Template.margin.bottom}px`,
        }}
      />
    )
    /* Margin bottom applies the same principles from margin top but with inverted sides
    * In this case it uses the right and bottom side to align it and the negative value is applied at the bottom

    * MARGIN LEFT */
    const MarginLeft = <div className='margin left' style={{ width: `${Template.margin.left}px`, height: "100%" }} />
    /* Margin left uses the full height avaiable
     * The width equals the margin left applied in the interface
     * The position is forced to the left side with top and left at 0
     *
     * MARGIN RIGHT */
    const MarginRight = <div className='margin right' style={{ width: `${Template.margin.right}px`, height: "100%" }} />
    /* Margin right uses the same logic for the margin left
     * With the proper use of the margin right for the width
     * And starting at the right and bottom side (0)
     *
     *       ---------------------------------------------------------------------------------------------
     *       |       margin-top: width:100%; height:${margin.top}px; left:0; top: -${margin.top};        |
     *       ---------------------------------------------------------------------------------------------
     *       |        margin-left:     |                                      |        margin-right:     |
     *       | width: ${margin-left}px |                                      | width: ${margin-right}px |
     *       |        height: 100%     |                                      |        height: 100%      |
     *       |         left: 0         |                                      |         right: 0         |
     *       |         top: 0          |                                      |         bottom: 0        |
     *       ---------------------------------------------------------------------------------------------
     *       | margin-bottom: width:100%; height:${margin-bottom}px; right:0; bottom: -${margin.bottom}; |
     *       ---------------------------------------------------------------------------------------------
     *
     * PADDING TOP */
    const PaddingTop = (
      <div
        className='padding top'
        style={{
          width: `calc(100% - ${Template.padding.left + Template.padding.right}px)`,
          height: `${Template.padding.top}px`,
          left: `${Template.padding.left}px`,
        }}
      />
    )
    /* Padding top will use the total width minus both padding side values for the left & right
     * The height will equal to the padding top and will start at where the left padding ends
     *
     * PADDING BOTTOM */
    const PaddingBottom = (
      <div
        className='padding bottom'
        style={{
          width: `calc(100% - ${Template.padding.left + Template.padding.right}px)`,
          height: `${Template.padding.bottom}px`,
          right: `${Template.padding.right}px`,
        }}
      />
    )
    /* Padding bottom will have the same logic applied at the padding top, with the small change to start at the right side where the right padding ends
     * The height will equal the padding bottom
     *
     * PADDING LEFT */
    const PaddingLeft = <div className='padding left' style={{ width: `${Template.padding.left}px`, height: "100%" }} />
    /* Padding left will just use the padding left for the width and 100% for the height
     * Since we leave the heavy logic for the top and bottom padding
     *
     *
     *
     *
     *       --------------------------------------------------------------------------------------------------------------------
     *       | padding-top: width:calc(100%-[${padding.left}+${padding.right}]px); height:${padding.top}; left:${padding.left}; |
     *       --------------------------------------------------------------------------------------------------------------------
     */
    return (
      <div
        className={classes}
        onClick={(e) => handleObjectClick(e)}
        style={outerDivStyle}
        onMouseOver={(e) => handleElementEnter(e)}
        onMouseLeave={(e) => handleElementLeave(e)}
      >
        {isSelected && (
          <div
            id='object-selected'
            className={`${symbolObj ? "isSymbol" : ""} ${
              props.obj.uuid === selectedSingleObject?.uuid ? "isSelected" : ""
            }`}
          />
        )}
        {handleVisibilityOnHover() && (
          <div className='element-hover' style={{ display: displayBoundaries }}>
            <div className='property'>
              {MarginTop}
              {MarginBottom}
              {MarginLeft}
              {MarginRight}
              <div
                className='block'
                style={{
                  top: "0",
                  right: `${Template.margin.right}px`,
                  bottom: "0",
                  left: `${Template.margin.left}px`,
                  width: `calc(100% - ${Template.margin.left + Template.margin.right})`,
                  height: "100%",
                }}
              >
                <div className='property'>
                  {PaddingTop}
                  {PaddingBottom}
                  {PaddingLeft}
                  <div
                    className='padding right'
                    style={{
                      width: `${Template.padding.right}px`,
                      height: "100%",
                    }}
                  />
                  <div
                    className='block'
                    style={{
                      top: `${Template.padding.top}px`,
                      right: `${Template.padding.right}px`,
                      bottom: `${Template.padding.bottom}px`,
                      left: `${Template.padding.left}px`,
                      width: `calc(100% - ${Template.padding.right + Template.padding.left}px)`,
                      height: `calc(100% - ${Template.padding.bottom + Template.padding.top}px)`,
                    }}
                  >
                    <div className='property'>
                      <div className='content'>
                        <span className='object-label'>
                          {props.obj.isContentBlockChild ? (
                            <>
                              <i className='fa fa-th-list' aria-hidden='true'></i>{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {props.obj.label}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.children}
      </div>
    )
  }
  return (
    /**
     * Div must be rendered because if we remove it now it will have implications on the components render.
     * At least the tabs component, when in position: sticky has a diferent behaviour.
     */
    <div style={outerDivStyle}>{props.children}</div>
  )
}

export default ComponentSelectorEM;

