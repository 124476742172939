import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
} from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"
import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { get2WithNull } from "../bobUtils"

const nullable = <A>(t: Type<A>) => union([t, nullC])

const SelfAlignCodec = union([
  literal("auto"),
  literal("flex-start"),
  literal("center"),
  literal("flex-end"),
  literal("stretch"),
])
const SelfGrowCodec = type({
  enable: booleanC,
  value: numberC,
})
const SelfShrinkCodec = type({
  enable: booleanC,
  value: numberC,
})
const OrderCodec = type({
  enable: booleanC,
  value: numberC,
})

const SelfFlexCodec = type({
  selfAlign: SelfAlignCodec,
  selfGrow: SelfGrowCodec,
  selfShrink: SelfShrinkCodec,
  order: OrderCodec,
})

const SelfGrowOptCodec = partial({
  enable: nullable(booleanC),
  value: nullable(numberC),
})

const SelfShrinkOptCodec = partial({
  enable: nullable(booleanC),
  value: nullable(numberC),
})

const OrderOptCodec = partial({
  enable: nullable(booleanC),
  value: nullable(numberC),
})

const SelfFlexOptCodec = partial({
  selfAlign: nullable(SelfAlignCodec),
  selfGrow: nullable(SelfGrowOptCodec),
  selfShrink: nullable(SelfShrinkOptCodec),
  order: nullable(OrderOptCodec),
})

const StylesSelfFlexCodec = intersection([
  type({ selfFlex: SelfFlexCodec }),
  partial({
    behaviour: partial({
      active: partial({
        selfFlex: SelfFlexOptCodec,
      }),
      hover: partial({
        selfFlex: SelfFlexOptCodec,
      }),
    }),
    mobile: partial({
      selfFlex: SelfFlexOptCodec,
      behaviour: partial({
        active: partial({
          selfFlex: SelfFlexOptCodec,
        }),
        hover: partial({
          selfFlex: SelfFlexOptCodec,
        }),
      }),
    }),
    tablet: partial({
      selfFlex: SelfFlexOptCodec,
      behaviour: partial({
        active: partial({
          selfFlex: SelfFlexOptCodec,
        }),
        hover: partial({
          selfFlex: SelfFlexOptCodec,
        }),
      }),
    }),
  }),
])

type SelfFlex = TypeOf<typeof SelfFlexCodec>
type SelfFlexOpt = TypeOf<typeof SelfFlexOptCodec>
type StylesSelfFlex = TypeOf<typeof StylesSelfFlexCodec>
type SelfFlexCSS = {
  "align-self": string
  "flex-grow": string | undefined
  "flex-shrink": string | undefined
  order: string | undefined
}

const DefaultSelfFlexCSS = {
  "align-self": "auto",
  "flex-grow": "0",
  "flex-shrink": "0",
  order: "0",
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<SelfFlexCSS> {
  const styles = StylesSelfFlexCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return DefaultSelfFlexCSS
}

export function cssRender(
  stylesObj: StylesSelfFlex,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<SelfFlexCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj.selfFlex)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.selfFlex,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.selfFlex, stylesObj.selfFlex)
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.selfFlex, mergeBob2(stylesObj?.[breakpoint]?.selfFlex, stylesObj.selfFlex))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.selfFlex,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.selfFlex,
          stylesObj?.behaviour?.[behaviourState]?.selfFlex,
          stylesObj?.[breakpoint]?.selfFlex,
          stylesObj.selfFlex
        )
      )
    }
  }
}

export function render(selfFlexObj: SelfFlex): SelfFlexCSS {
  return {
    "align-self": selfFlexObj.selfAlign,
    "flex-grow": selfFlexObj.selfGrow.enable ? `${selfFlexObj.selfGrow.value}` : undefined,
    "flex-shrink": selfFlexObj.selfShrink.enable ? `${selfFlexObj.selfShrink.value}` : undefined,
    order: selfFlexObj.order.enable ? `${selfFlexObj.order.value}` : undefined,
  }
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param selfFlexValue
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(
  defaultSelfFlexObj: SelfFlex,
  selfFlexValue: SelfFlexOpt | undefined
): Partial<SelfFlexCSS> {
  if (selfFlexValue) {
    return renderOpt(defaultSelfFlexObj, selfFlexValue)
  }
  return {}
}

/**
 * Renders SelfFlexOpt css for breakpoints/state templates
 * Returns selfFlex
 * or empty for non written style props
 *
 * @param selfFlexValue
 * @param foundationStyle
 * @returns
 */
export function renderOpt(defaultSelfFlexObj: SelfFlex, selfFlexValue: SelfFlexOpt): Partial<SelfFlexCSS> {
  let css = {}
  if (valueExists(selfFlexValue?.selfAlign)) css = { ...css, "align-self": `${selfFlexValue?.selfAlign}` }
  if (valueExists(selfFlexValue?.selfGrow?.enable)) {
    if (selfFlexValue?.selfGrow?.enable === false) {
      if (defaultSelfFlexObj.selfGrow.enable) css = { ...css, "flex-grow": `initial` }
    }
    if (selfFlexValue?.selfGrow?.enable === true && valueExists(selfFlexValue?.selfGrow?.value))
      css = { ...css, "flex-grow": `${selfFlexValue?.selfGrow?.value}` }
  }
  if (valueExists(selfFlexValue?.selfShrink?.enable)) {
    if (selfFlexValue?.selfShrink?.enable === false) {
      if (defaultSelfFlexObj.selfShrink.enable) css = { ...css, "flex-shrink": `initial` }
    }
    if (selfFlexValue?.selfShrink?.enable === true && valueExists(selfFlexValue?.selfShrink?.value))
      css = { ...css, "flex-shrink": `${selfFlexValue?.selfShrink?.value}` }
  }
  if (valueExists(selfFlexValue?.order?.enable)) {
    if (selfFlexValue?.order?.enable === false) {
      if (defaultSelfFlexObj.order.enable) css = { ...css, order: `initial` }
    }
    if (selfFlexValue?.order?.enable === true && valueExists(selfFlexValue?.order?.value))
      css = { ...css, order: `${selfFlexValue?.order?.value}` }
  }

  return css
}

export function mergeBob2(selfFlexObj: SelfFlexOpt | undefined, defaultSelfFlexObj: SelfFlex): SelfFlexOpt {
  const selfGrowEnable = get2WithNull(selfFlexObj?.selfGrow?.enable, defaultSelfFlexObj.selfGrow.enable)
  const seflShrinkEnable = get2WithNull(selfFlexObj?.selfShrink?.enable, defaultSelfFlexObj.selfShrink.enable)
  const orderEnable = get2WithNull(selfFlexObj?.order?.enable, defaultSelfFlexObj.order.enable)

  return {
    selfGrow: {
      enable: selfGrowEnable,
      value: selfFlexObj?.selfGrow?.value
    },
    selfShrink: {
      enable: seflShrinkEnable,
      value: selfFlexObj?.selfShrink?.value
    },
    order: {
      enable: orderEnable,
      value: selfFlexObj?.order?.value
    },
    selfAlign: selfFlexObj?.selfAlign,
  }
}

export function mergeBob3(
  selfFlexObj: SelfFlexOpt | undefined,
  selfFlexDesktopBehaviour: SelfFlexOpt | undefined,
  selfFlexDefaultBreakpoint: SelfFlexOpt | undefined,
  defaultSelfFlexObj: SelfFlex
): SelfFlexOpt {
  const selfGrowEnable =
    selfFlexObj?.selfGrow?.enable ??
    selfFlexDesktopBehaviour?.selfGrow?.enable ??
    selfFlexDefaultBreakpoint?.selfGrow?.enable ??
    defaultSelfFlexObj.selfGrow.enable
  const seflShrinkEnable =
    selfFlexObj?.selfShrink?.enable ??
    selfFlexDesktopBehaviour?.selfShrink?.enable ??
    selfFlexDefaultBreakpoint?.selfShrink?.enable ??
    defaultSelfFlexObj.selfShrink.enable
  const orderEnable =
    selfFlexObj?.order?.enable ??
    selfFlexDesktopBehaviour?.order?.enable ??
    selfFlexDefaultBreakpoint?.order?.enable ??
    defaultSelfFlexObj.order.enable

  return {
    selfGrow: {
      enable: selfGrowEnable,
      value: selfFlexObj?.selfGrow?.value
    },
    selfShrink: {
      enable: seflShrinkEnable,
      value: selfFlexObj?.selfShrink?.value
    },
    order: {
      enable: orderEnable,
      value: selfFlexObj?.order?.value
    },
    selfAlign: selfFlexObj?.selfAlign,
  }
}

export type { SelfFlexCSS, StylesSelfFlex, SelfFlex, SelfFlexOpt }
