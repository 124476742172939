import React, { useState } from 'react'
import ComponentHandler from '../../../../componentHandler'
import WarningDefaultSystem from '../../../../../../modules/experienceManager/WarningDefaultSystem/warningDefaultSystem'
import { handleObjectType } from "../../../../../../modules/shared-modules/utilities/components"
import BobComponentHandler from "../../../bob/bobComponentHandler"
import AlignmentHOC from "../../alignmentHOC/alignmentHOC"
import { responsiveGuttersClass } from "../../../bob/bobHandler"
import { alignment } from "../../../../../../modules/shared-modules/utilities/conditionalController"
import { MenuProps } from "."
import menuComponentsStyles from "../../../../../../stylesheets/modules/templates/menuComponent.module.sass"
import { isBobBackground } from "../../../../../../modules/shared-modules/experienceManager/types/stylesTypes"

const Menu: React.FC<MenuProps> = (props) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleMenuToggle = () => setShowMenu(!showMenu)

  const renderMenu = (componentsId: string[]) => {
    if (componentsId && componentsId.length > 0) {
      return componentsId.map((componentId: string, idx: number) => {
        const handledItem = handleObjectType(props.obj, componentId, props.pageResponse, props.expManager.emEditorType)
        return (
          <div key={idx}>
            <ComponentHandler
              position={idx}
              selectedInstanceId={props.obj.selectedInstanceId}
              key={idx}
              component={handledItem}
              pageResponse={props.pageResponse}
              matchOrder={props.matchOrder}
              expManager={props.expManager}
            />
          </div>
        )
      })
    } else {
      if (props.expManager.enable) {
        const Background2 = isBobBackground(props.obj.styles.bobs.background2)
        let components: any[] = []
        for (let i = 0; i < 3; i++) {
          const columns = "4"
          components = [
            ...components,
            <div key={i} className={`navbar-menu col-12 col-md-${columns}`}>
              <div
                style={{
                  height: `${Background2.height.value}${Background2.height.unit}`,
                  border: "3px dashed #dbe0e7",
                }}
              ></div>
            </div>,
          ]
        }
        return (
          <WarningDefaultSystem>
            <div className='row'>{components}</div>
          </WarningDefaultSystem>
        )
      }
    }
  }

  const background1 = isBobBackground(props.obj.styles.bobs.background1)
  const { alignmentStyles, tabletAlignment, mobileAlignment } = alignment(background1)
  const columns = background1.grid.columns[0]
  const childComponentsId = props.obj.children

  return (
    <div
      className={`navigation ${menuComponentsStyles.navigation} menu-component ${menuComponentsStyles["menu-component"]}`}
    >
      <AlignmentHOC
        className={`main-menu ${menuComponentsStyles["main-menu"]} row ${responsiveGuttersClass(
          background1,
          props.pageResponse.globalStyles[background1.globalStyleId]
        )}`}
        desktop={alignmentStyles}
        tablet={tabletAlignment}
        mobile={mobileAlignment}
      >
        <div className={`col-12 col-md-${columns}`}>
          {childComponentsId && childComponentsId.length > 0 && (
            <span className={`main-menu-toggle ${menuComponentsStyles["main-menu-toggle"]}`} onClick={handleMenuToggle}>
              <i className='fas fa-bars'></i>
            </span>
          )}
          <BobComponentHandler
            position={props.position}
            pageResponse={props.pageResponse}
            instanceId={props.obj.selectedInstanceId}
            object={props.obj}
            objectId={props.obj.uuid}
            inlineContent={props.obj.content.inlineContent}
            field={"background2"}
            rules={props.componentRule}
            template={props.obj.styles}
            expManager={props.expManager}
            className={`main-menu-container ${menuComponentsStyles["main-menu-container"]} ${
              !showMenu ? "hide" : "show"
            }`}
          >
            {renderMenu(childComponentsId)}
          </BobComponentHandler>
        </div>
      </AlignmentHOC>
    </div>
  )
}

export default Menu