import { NextRouter, withRouter } from "next/router"
import React, { Fragment } from "react"
import {
  datasetRender,
  getOverrideInlineContentValues,
  handleComponentLinks,
  handleFlow,
  handleHeight,
  handleImageFilter,
  handleRadius,
} from "../../../../modules/shared-modules/utilities/components"
// @ts-ignore
import Style from "style-it"
import { handleDOMid } from "../../../../modules/shared-modules/utilities/symbols"
import styled from "styled-components"
import { responsiveProperties, responsiveWrapClass } from "../../shared-public-page/bob/bobHandler"
//@ts-ignore
import { handleSrcLink, hasVersion } from "../../../../modules/shared-modules/mediaLibrary/mediaLibraryUtils"
import { ExpManager, PageTypes } from "../../../../modules/shared-modules/experienceManager/types/pageTypes"
import GlobalStylesUtils from "../../../../modules/shared-modules/globalStyles/globalStylesUtils"
import {
  boxShadowCss as ShadowStyleBoxShadowCss,
  zIndexFix as ShadowStyleZIndexFix,
} from "../../shared-public-page/bob/bobStyles/shadowStyle"
import { BehaviourState_ } from "../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { renderCSSString as renderCSSStringBoundary } from "../../shared-public-page/bob/bobStyles/boundaryStyle"
import {
  conditionalBoundaryRenderUnsafe as cssRenderUnsafeBoundary,
  conditionalShadowRenderUnsafe,
  conditionalBorderRenderUnsafe as cssRenderUnsafeBorder,
  conditionalBorderCss as renderCSSStringBorder,
  conditionalColorsRenderUnsafe as cssRenderUnsafeColors,
} from "../../../../modules/shared-modules/utilities/conditionalController"
import {
  ComponentSpecificAlignment,
  AlignmentCssRenderUnsafe,
} from "../../shared-public-page/bob/bobStyles/alignmentStyle"
import {
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { analyticsLinkLabel, getLinkAttributes } from "../../shared-public-page/bob/bobUtils"
import { GoogleEvent } from "../../shared-public-page/analytics/analytics"
import { getResponsiveAttachedFoundationStyle } from "../../../../modules/shared-modules/foundationStyles/foundationStylesUtils"
import { FColor } from "../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { cssRenderUnsafe as cssRenderUnsafeBackground } from "../../shared-public-page/bob/bobStyles/backgroundImageStyle"
import { renderCSSString as renderCSSStringColors } from "../../shared-public-page/bob/bobStyles/colorsStyle"
import * as inlineContentTypes from "../../../../modules/shared-modules/experienceManager/finder/content/inlineContent/inlineContentTypes"

interface OwnProps {
  id: string
  className?: string
  rule: any
  template: any
  globalStyles: any
  instanceId?: string
  objPosition: number
  flexDirection?: string
  onMouseEnter?: any
  onMouseLeave?: any
  inlineContent: ObjectContent["inlineContent"]
  overrides?: ObjectContentOverride
  pageResponse: PageTypes
  post?: Post
  field: string
  expManager: ExpManager
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  router: NextRouter
}

interface BobBackgroundStyledDiv {
  desktop: any
  tablet: any
  mobile: any
}

type BobBackgroundImageProps = OwnProps

const BoBackgroundDiv = styled.div`
  ${(props: BobBackgroundStyledDiv) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: BobBackgroundStyledDiv) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: BobBackgroundStyledDiv) => props.mobile}
  }
`
const BobBackgroundContainer: any = styled.div`
  ${(props: BobBackgroundStyledDiv) => props.desktop}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: BobBackgroundStyledDiv) => props.tablet}
  }
  @media all and (max-width: 766px) {
    ${(props: BobBackgroundStyledDiv) => props.mobile}
  }
`

class BobBackgroundImage extends React.Component<BobBackgroundImageProps> {
  constructor(props: BobBackgroundImageProps) {
    super(props)
  }

  handleStateStyles(
    defaultTemplate: any,
    templateObj: any,
    templateObjMobile: any,
    templateObjTablet: any,
    className: string,
    imageSrc: string,
    behaviourState: BehaviourState_
  ) {
    if (templateObj) {
      const foundationsList = this.props.pageResponse.foundations
      const shadowFoundationStyle = getResponsiveAttachedFoundationStyle(
        defaultTemplate,
        foundationsList,
        "shadow",
        "color",
        behaviourState,
        "desktop"
      ) as FColor
      const mobileShadowFoundationStyle = getResponsiveAttachedFoundationStyle(
        defaultTemplate,
        foundationsList,
        "shadow",
        "color",
        behaviourState,
        "mobile"
      ) as FColor
      const tabletShadowFoundationStyle = getResponsiveAttachedFoundationStyle(
        defaultTemplate,
        foundationsList,
        "shadow",
        "color",
        behaviourState,
        "tablet"
      ) as FColor

      const templateMobile: any = {
        ...responsiveProperties({ mobile: templateObjMobile }, "mobile", { image: { imageType: "backgroundImage" } }, [
          "grid",
          "alignment",
          "shadow",
          "spacing",
          "colors",
          "image",
          "border",
        ]),
        ...conditionalShadowRenderUnsafe(defaultTemplate, "mobile", behaviourState, "box", mobileShadowFoundationStyle),
        ...cssRenderUnsafeBoundary(defaultTemplate, "mobile", behaviourState),
        ...cssRenderUnsafeBorder(defaultTemplate, "mobile", behaviourState, true),
        ...cssRenderUnsafeColors(defaultTemplate, "mobile", behaviourState, true),
        ...cssRenderUnsafeBackground(defaultTemplate, "mobile", behaviourState, imageSrc),
      }
      const templateTablet: any = {
        ...responsiveProperties({ tablet: templateObjTablet }, "tablet", { image: { imageType: "backgroundImage" } }, [
          "grid",
          "alignment",
          "shadow",
          "spacing",
          "colors",
          "image",
          "border",
        ]),
        ...conditionalShadowRenderUnsafe(defaultTemplate, "tablet", behaviourState, "box", tabletShadowFoundationStyle),
        ...cssRenderUnsafeBoundary(defaultTemplate, "tablet", behaviourState),
        ...cssRenderUnsafeBorder(defaultTemplate, "tablet", behaviourState, true),
        ...cssRenderUnsafeColors(defaultTemplate, "tablet", behaviourState, true),
        ...cssRenderUnsafeBackground(defaultTemplate, "tablet", behaviourState, imageSrc),
      }
      const mediaQueryRestrictions = this.handleMediaQueryRestrictions(templateObjMobile, templateObjTablet)
      const heightStyles: any = handleHeight(templateObj, defaultTemplate)
      const backgroundImageStyles: any = cssRenderUnsafeBackground(defaultTemplate, "desktop", behaviourState, imageSrc)
      const backgroundColorStyles = cssRenderUnsafeColors(defaultTemplate, "desktop", behaviourState, true)
      const bondariesObj = cssRenderUnsafeBoundary(defaultTemplate, "desktop", behaviourState)
      const imageFilterStyles = handleImageFilter(templateObj)
      const flowStyles = handleFlow(templateObj)
      const borders = cssRenderUnsafeBorder(defaultTemplate, "desktop", behaviourState, true)
      const shadows = conditionalShadowRenderUnsafe(
        defaultTemplate,
        "desktop",
        behaviourState,
        "box",
        shadowFoundationStyle
      )
      const radiusStyles = handleRadius(templateObj, defaultTemplate)

      return `
      ${
        templateObj.enable
          ? `@media all ${mediaQueryRestrictions}{
            ${className}{
              height: ${heightStyles.height};
              ${renderCSSStringBoundary(bondariesObj)}               
              ${renderCSSStringColors(backgroundColorStyles)}             
              background-size: ${backgroundImageStyles["background-size"]};
              background-repeat: ${backgroundImageStyles["background-repeat"]};
              background-position: ${backgroundImageStyles["background-position"]};
              ${renderCSSStringBorder(borders, this.props.template.controllerVersion?.border)}
              background-image: ${backgroundImageStyles["background-image"]};              
              background-attachment: ${templateObj.attachment + " !important"};
              filter: ${imageFilterStyles.filter?.filter};
              webkit-filter: ${imageFilterStyles.filter?.WebkitFilter};
              position: ${flowStyles.position};
              z-index: ${flowStyles.zIndex};
              width: ${flowStyles.width};
              top: ${flowStyles.top};
              ${ShadowStyleBoxShadowCss(shadows)}
              border-bottom-left-radius: ${radiusStyles.borderBottomLeftRadius}; 
              border-bottom-right-radius: ${radiusStyles.borderBottomRightRadius}; 
              border-top-left-radius: ${radiusStyles.borderTopLeftRadius}; 
              border-top-right-radius: ${radiusStyles.borderTopRightRadius}; 
            }
          }`
          : ""
      }
      ${
        (templateObj.enable || templateObjTablet?.enable) && templateObjTablet?.enable !== false
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${className}{
              height: ${templateTablet.height};     
              ${renderCSSStringBoundary(templateTablet)}
              ${renderCSSStringColors(templateTablet)}     
              background-size: ${templateTablet["background-size"]};
              background-repeat: ${templateTablet["background-repeat"]};
              background-position: ${templateTablet["background-position"]};
              ${renderCSSStringBorder(templateTablet, this.props.template.controllerVersion?.border)}
              background-image: ${templateTablet["background-image"]};
              filter: ${templateTablet.filter?.filter};
              position: ${templateTablet.position};
              z-index: ${templateTablet["z-index"]};
              width: ${templateTablet.width};
              top: ${templateTablet.top};
              ${ShadowStyleBoxShadowCss(templateTablet)}
              border-bottom-left-radius: ${templateTablet["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${templateTablet["border-bottom-left-radius"]}; 
              border-top-left-radius: ${templateTablet["border-bottom-left-radius"]}; 
              border-top-right-radius: ${templateTablet["border-bottom-left-radius"]};  
            }
          }`
          : ""
      }
      ${
        (templateObj.enable || templateObjMobile?.enable) && templateObjMobile?.enable !== false
          ? `@media all and (max-width: 766px){
            ${className}{
              height: ${templateMobile.height};
              ${renderCSSStringBoundary(templateMobile)}
              ${renderCSSStringColors(templateMobile)}   
              background-size: ${templateMobile["background-size"]};
              background-repeat: ${templateMobile["background-repeat"]};
              background-position: ${templateMobile["background-position"]};
              ${renderCSSStringBorder(templateMobile, this.props.template.controllerVersion?.border)} 
              background-image: ${templateMobile["background-image"]};
              filter: ${templateMobile.filter?.filter};
              position: ${templateMobile.position};
              z-index: ${templateMobile["z-index"]};
              width: ${templateMobile.width};
              top: ${templateMobile.top};
              ${ShadowStyleBoxShadowCss(templateMobile)}
              border-bottom-left-radius: ${templateMobile["border-bottom-left-radius"]}; 
              border-bottom-right-radius: ${templateMobile["border-bottom-left-radius"]}; 
              border-top-left-radius: ${templateMobile["border-bottom-left-radius"]}; 
              border-top-right-radius: ${templateMobile["border-bottom-left-radius"]}; 
            }
          }`
          : ""
      }
      `
    }
    return ""
  }

  /**
   * returns media queries to apply styles based on enable prop value on the diferent breakpoints
   */
  handleMediaQueryRestrictions(mobileStyles: any, tabletStyles: any) {
    if (tabletStyles?.enable === false && mobileStyles?.enable) return "and (max-width: 766px), (min-width: 993px)"
    if (tabletStyles?.enable === false) return "and (min-width: 993px)"
    if (mobileStyles?.enable === false) return "and (min-width: 767px)"
    return ""
  }

  handleOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, templateStyles: any) {
    // trigger parent onclick
    if (this.props.onClick) this.props.onClick(e)
    // check template onclick action
    // only run outside experience manager
    if (this.props.rule.actions && !this.props.expManager.enable) {
      if (
        templateStyles.actions &&
        templateStyles.actions.enable &&
        templateStyles.actions.onClick &&
        templateStyles.actions.onClick !== ""
      ) {
        // https://stackoverflow.com/questions/939326/execute-javascript-code-stored-as-a-string
        // eslint-disable-next-line
        let F = new Function(templateStyles.actions.onClick)
        return F()
      }
    }
  }

  /**
   *
   * TODO: This logic only works for the first level of routes
   * needs to be redone
   */
  handleActiveState(template: any) {
    const templateStylesBehaviour = template.behaviour
    /**
     * Both Active state and link behaviour are enabled
     */
    if (
      templateStylesBehaviour.active &&
      templateStylesBehaviour.active.enable &&
      templateStylesBehaviour.link?.enable &&
      templateStylesBehaviour.link?.target === "_self"
    ) {
      /**
       * Both customUrl for behaviour and the page link match
       */
      if (templateStylesBehaviour.link.customUrl === this.props.router.query.localId) {
        return "active"
      }
    }
    return ""
  }

  clickEvent(e: any, TAG: any) {
    if (TAG === "a") {
      const linkLabel = analyticsLinkLabel(
        this.props.field,
        {
          inlineContent: this.props.inlineContent,
          overrides: this.props.overrides,
        },
        this.props.pageResponse,
        this.props.post
      )
      //get parent id to properly know who has the link configured
      const parentId = e.target.parentElement?.id
      if (parentId) {
        GoogleEvent("link_click", linkLabel, "Link Clicked", 1)
      }
    }
  }

  handleLink() {
    const isPostPath = getOverrideInlineContentValues(
      `${this.props.field}.behaviour-link-mappedValue`,
      this.props
    )?.startsWith("$")
    let linkAttrs: any = {}
    //(templateStyles.behaviour && (templateStyles.behaviour.link.enable && ((templateStyles.behaviour.link.customUrl && templateStyles.behaviour.link.customUrl !== '') || isPostPath)))
    if (
      getOverrideInlineContentValues(`${this.props.field}.behaviour-link-enable`, this.props) &&
      ((getOverrideInlineContentValues(`${this.props.field}.behaviour-link-customUrl`, this.props) &&
        getOverrideInlineContentValues(`${this.props.field}.behaviour-link-customUrl`, this.props) !== "") ||
        isPostPath)
    ) {
      linkAttrs["href"] = handleComponentLinks(
        this.props.inlineContent,
        this.props.field,
        this.props.pageResponse,
        this.props.overrides,
        this.props.post
      )
      linkAttrs["target"] = getOverrideInlineContentValues(`${this.props.field}.behaviour-link-target`, this.props)
    }
    return linkAttrs
  }

  isFilterEnabled = (templateStyles: any) => {
    if (
      templateStyles.filter.enable ||
      templateStyles.tablet?.filter?.enable ||
      templateStyles.mobile?.filter?.enable
    ) {
      return true
    }
    return false
  }

  render() {
    const templateStyles = this.props.template
    const foundationsList = this.props.pageResponse.foundations

    /**
     * Foundations
     */
    const shadowFoundationStyle = getResponsiveAttachedFoundationStyle(
      templateStyles,
      foundationsList,
      "shadow",
      "color",
      "default",
      "desktop"
    ) as FColor
    const mobileShadowFoundationStyle = getResponsiveAttachedFoundationStyle(
      templateStyles,
      foundationsList,
      "shadow",
      "color",
      "default",
      "mobile"
    ) as FColor
    const tabletShadowFoundationStyle = getResponsiveAttachedFoundationStyle(
      templateStyles,
      foundationsList,
      "shadow",
      "color",
      "default",
      "tablet"
    ) as FColor
    // Colors
    // const colorsColorFirstFoundationStyle = getResponsiveAttachedFoundationStyle(
    //   templateStyles,
    //   foundationsList,
    //   "colors",
    //   "colorFirst",
    //   "default",
    //   "desktop"
    // ) as FColor
    const mobileColorsColorFirstFoundationStyle = getResponsiveAttachedFoundationStyle(
      templateStyles,
      foundationsList,
      "colors",
      "colorFirst",
      "default",
      "mobile"
    ) as FColor
    const tabletColorsColorFirstFoundationStyle = getResponsiveAttachedFoundationStyle(
      templateStyles,
      foundationsList,
      "colors",
      "colorFirst",
      "default",
      "tablet"
    ) as FColor
    // const colorsColorSecondFoundationStyle = getResponsiveAttachedFoundationStyle(
    //   templateStyles,
    //   foundationsList,
    //   "colors",
    //   "colorSecond",
    //   "default",
    //   "desktop"
    // ) as FColor
    const mobileColorsColorSecondFoundationStyle = getResponsiveAttachedFoundationStyle(
      templateStyles,
      foundationsList,
      "colors",
      "colorSecond",
      "default",
      "mobile"
    ) as FColor
    const tabletColorsColorSecondFoundationStyle = getResponsiveAttachedFoundationStyle(
      templateStyles,
      foundationsList,
      "colors",
      "colorSecond",
      "default",
      "tablet"
    ) as FColor

    /**
     * Background image src
     */
    const mediaObject = this.props.inlineContent
      ? handleSrcLink(
          this.props.pageResponse,
          getOverrideInlineContentValues(`${this.props.field}.image-src`, this.props)
        )
      : undefined
    let imageSrc = mediaObject ? mediaObject.mediaSrc : ""
    if (!this.props.pageResponse.webpSupport && mediaObject?.fallbackExtension && mediaObject?.mediaInfo) {
      imageSrc = `https://static-media.fluxio.cloud/${mediaObject.mediaInfo.profileName}/${
        mediaObject.mediaInfo.id
      }${hasVersion(mediaObject.mediaInfo.version)}.${mediaObject.fallbackExtension}`
      console.warn("Fallback, webp not supported: ", imageSrc)
    }

    /**
     * Responsive Styles
     */
    const alignmentAdittionalProperties = {
      flexDirection: this.props.flexDirection,
      self: true,
    } as ComponentSpecificAlignment
    const mobileStyles = {
      ...responsiveProperties(
        templateStyles,
        "mobile",
        undefined,
        ["grid", "alignment", "shadow", "spacing", "colors", "image", "border"],
        undefined,
        [mobileColorsColorFirstFoundationStyle, mobileColorsColorSecondFoundationStyle]
      ),
      ...conditionalShadowRenderUnsafe(templateStyles, "mobile", "default", "box", mobileShadowFoundationStyle),
      ...cssRenderUnsafeBoundary(templateStyles, "mobile", "default"),
      ...cssRenderUnsafeBorder(templateStyles, "mobile", "default", true),
      ...cssRenderUnsafeColors(templateStyles, "mobile", "default", true),
      ...cssRenderUnsafeBackground(templateStyles, "mobile", "default", imageSrc),
    }
    const tabletStyles = {
      ...responsiveProperties(
        templateStyles,
        "tablet",
        undefined,
        ["grid", "alignment", "shadow", "spacing", "colors", "image", "border"],
        undefined,
        [tabletColorsColorFirstFoundationStyle, tabletColorsColorSecondFoundationStyle]
      ),
      ...conditionalShadowRenderUnsafe(templateStyles, "tablet", "default", "box", tabletShadowFoundationStyle),
      ...cssRenderUnsafeBoundary(templateStyles, "tablet", "default"),
      ...cssRenderUnsafeBorder(templateStyles, "tablet", "default", true),
      ...cssRenderUnsafeColors(templateStyles, "tablet", "default", true),
      ...cssRenderUnsafeBackground(templateStyles, "tablet", "default", imageSrc),
    }
    const mobileStylesContainer = AlignmentCssRenderUnsafe(
      templateStyles,
      "mobile",
      "default",
      alignmentAdittionalProperties
    )
    const tabletStylesContainer = AlignmentCssRenderUnsafe(
      templateStyles,
      "tablet",
      "default",
      alignmentAdittionalProperties
    )
    const linkAttrs = getLinkAttributes(
      this.props.field,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      },
      this.props.pageResponse,
      this.props.post
    )
    const TAG = getOverrideInlineContentValues(`${this.props.field}.behaviour-link-enable`, this.props) ? "a" : "div"
    const containerClass =
      templateStyles.wrap && templateStyles.wrap === "container"
        ? "container"
        : templateStyles.wrap && templateStyles.wrap !== "container"
        ? "container-fluid"
        : ""
    const backgroundImageStyles: any = cssRenderUnsafeBackground(templateStyles, "desktop", "default", imageSrc)
    const backgroundColorStyles = cssRenderUnsafeColors(templateStyles, "desktop", "default", true)
    const heightStyles: any = handleHeight(templateStyles)
    const bondariesObj = cssRenderUnsafeBoundary(templateStyles, "desktop", "default")
    const imageFilterStyles = handleImageFilter(templateStyles)
    const flowStyles = handleFlow(templateStyles)
    const borders = cssRenderUnsafeBorder(templateStyles, "desktop", "default", true)
    const shadows = conditionalShadowRenderUnsafe(templateStyles, "desktop", "default", "box", shadowFoundationStyle)
    const shadowZIndex = ShadowStyleZIndexFix(
      templateStyles.shadow.enable,
      templateStyles.scroll,
      this.props.objPosition
    )
    const radiusStyles = handleRadius(templateStyles)
    const alignmentStyles = this.props.rule.alignment
      ? AlignmentCssRenderUnsafe(templateStyles, "desktop", "default", alignmentAdittionalProperties)
      : {}
    const backgroundProperties = {
      height: heightStyles.height,
      ...imageFilterStyles.filter,
      ...flowStyles,
      ...shadows,
      ...shadowZIndex,
      ...borders,
      ...radiusStyles,
      ...bondariesObj,
      ...backgroundColorStyles,
      backgroundAttachment: templateStyles.attachment + " !important",
      ...backgroundImageStyles,
    }
    const classPrefix = `prefix${templateStyles.uuid}`
    // LEGACY check for (this.props.rules.behaviour && this.props.rules.behaviour.hover)
    let hoverStyles: string = ""
    let activeStyles: string = ""
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.hover) {
      hoverStyles = this.handleStateStyles(
        templateStyles,
        templateStyles.behaviour.hover,
        templateStyles.mobile?.behaviour?.hover,
        templateStyles.tablet?.behaviour?.hover,
        `.${classPrefix}-image-filter:hover`,
        imageSrc,
        "hover"
      )
    }
    if (this.props.rule && this.props.rule.behaviour && this.props.rule.behaviour.active) {
      activeStyles = this.handleStateStyles(
        templateStyles,
        templateStyles.behaviour.active,
        templateStyles.mobile?.behaviour?.active,
        templateStyles.tablet?.behaviour?.active,
        `.${classPrefix}-image-filter.active`,
        imageSrc,
        "active"
      )
    }

    const hasStateStyles = hoverStyles.trim() !== "" || activeStyles.trim() !== ""

    const customAttributesEnable = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_ENABLE}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesMappedField = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_MAPPED_FIELD}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesValue = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_VALUE}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const customAttributesContentSrc = getOverrideInlineContentValues(
      `${this.props.field}.${inlineContentTypes.CUSTOM_ATTRIBUTES_CONTENTSRC}`,
      {
        inlineContent: this.props.inlineContent,
        overrides: this.props.overrides,
      }
    )
    const datasetValue = datasetRender(
      this.props.post,
      customAttributesEnable,
      customAttributesMappedField,
      customAttributesValue,
      customAttributesContentSrc
    )

    return (
      <Fragment>
        {hasStateStyles && (
          <Style>
            {`          
              ${hoverStyles}
              ${activeStyles}
            `}
          </Style>
        )}
        <BoBackgroundDiv
          {...datasetValue}
          tablet={tabletStyles}
          mobile={mobileStyles}
          desktop={backgroundProperties}
          as={TAG}
          id={handleDOMid(this.props.field, this.props.id, this.props.instanceId, this.props.post)}
          className={`bob-background spacing-preview-${this.props.template.uuid} ${classPrefix}-image-filter ${
            this.props.className ? this.props.className : ""
          } ${this.handleActiveState(templateStyles)} ${GlobalStylesUtils.handleClassName(
            this.props.pageResponse.nGlobalStyles,
            this.props.template,
            undefined,
            "-box"
          )}`}
          onClick={(e: any) => {
            this.handleOnClick(e, templateStyles)
            this.clickEvent(e, TAG)
          }}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          {...linkAttrs}
        >
          <BobBackgroundContainer
            desktop={alignmentStyles}
            mobile={mobileStylesContainer}
            tablet={tabletStylesContainer}
            className={`${containerClass} ${responsiveWrapClass(templateStyles)}`}
          >
            {this.props.children}
          </BobBackgroundContainer>
          {this.isFilterEnabled(templateStyles) && (
            <div className='image-filter-overlay' style={imageFilterStyles.overlay}></div>
          )}
        </BoBackgroundDiv>
      </Fragment>
    )
  }
}

export default withRouter(BobBackgroundImage)
