import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  string as stringC,
  array as arrayC,
  partial,
  boolean as booleanC,
} from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { isRight } from "fp-ts/Either"
import { decoderErrors } from "../codec/codecUtils"

const nullable = <A>(t: Type<A>) => union([t, nullC])

const ItemSizeCodec = union([literal("auto"), literal("columnSize")])
const InitialPosValueCodec = union([literal("start"), literal("middle"), literal("end"), stringC])
const GuttersCodec = type({
  enable: booleanC,
  value: numberC,
})
const InitialPosCodec = type({
  enable: booleanC,
  value: InitialPosValueCodec,
})

const GridCodec = type({
  columns: arrayC(stringC),
  columnsTotal: numberC,
  gutters: GuttersCodec,
  initialPos: InitialPosCodec,
  itemSize: ItemSizeCodec,
})

const ItemSizeOptCodec = nullable(ItemSizeCodec)
const InitialPosValueOptCodec = nullable(InitialPosValueCodec)
const InitialPosOptCodec = partial({
  enable: nullable(booleanC),
  value: InitialPosValueOptCodec,
})
const GuttersOptCodec = partial({
  enable: nullable(booleanC),
  value: nullable(numberC),
})

const GridOptCodec = partial({
  columns: nullable(arrayC(stringC)),
  columnsTotal: nullable(numberC),
  gutters: nullable(GuttersOptCodec),
  initialPos: InitialPosOptCodec,
  itemSize: ItemSizeOptCodec,
})

const StylesGridCodec = intersection([
  type({ grid: GridCodec }),
  partial({
    behaviour: partial({
      active: partial({
        grid: GridOptCodec,
      }),
      hover: partial({
        grid: GridOptCodec,
      }),
    }),
    mobile: partial({
      grid: GridOptCodec,
      behaviour: partial({
        active: partial({
          grid: GridOptCodec,
        }),
        hover: partial({
          grid: GridOptCodec,
        }),
      }),
    }),
    tablet: partial({
      grid: GridOptCodec,
      behaviour: partial({
        active: partial({
          grid: GridOptCodec,
        }),
        hover: partial({
          grid: GridOptCodec,
        }),
      }),
    }),
  }),
])

type Grid = TypeOf<typeof GridCodec>
type GridOpt = TypeOf<typeof GridOptCodec>
type StylesGrid = TypeOf<typeof StylesGridCodec>
type GridProperties = Grid

type GridCSS = {
  "--bs-columns": number
  "--bs-gap": string
}

// const DefaultGridProperties = {
//   columns: ["12"],
//   columnsTotal: 1,
//   gutters: {
//     enable: false,
//     size: "0px",
//   },
//   initialPos: "",
//   itemSize: "",
// }

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<GridCSS> {
  const styles = StylesGridCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(decoderErrors(styles))
  return {}
}

export function cssRender(
  stylesObj: StylesGrid,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<GridCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj.grid)
    }
    //hover | active
    else {
      return renderOpt(mergeBob2(stylesObj?.behaviour?.[behaviourState]?.grid, stylesObj.grid))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(mergeBob2(stylesObj?.[breakpoint]?.grid, stylesObj.grid))
    }
    //hover | active
    else {
      return renderOpt(
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.grid,
          stylesObj?.behaviour?.[behaviourState]?.grid,
          stylesObj?.[breakpoint]?.grid,
          stylesObj.grid
        )
      )
    }
  }
}

export function render(gridObj: Grid): Partial<GridCSS> {
  let gridProps = {}
  if (gridObj.gutters.enable && gridObj.gutters.value) {
    gridProps = {
      "--bs-gap": gridObj.gutters.value,
    }
  } else {
    gridProps = {
      "--bs-gap": 0,
    }
  }
  if (gridObj.columnsTotal) {
    gridProps = {
      ...gridProps,
      "--bs-columns": `${gridObj.columnsTotal}`,
    }
  }
  return gridProps
}

export function mergeBob2(
  gridObj: GridOpt | undefined,
  defaultGridObj: Grid
): { columnsTotal: Grid["columnsTotal"]; gutters: Grid["gutters"] } {
  const columnsTotal = gridObj?.columnsTotal ?? defaultGridObj.columnsTotal
  const gutters = {
    enable: gridObj?.gutters?.enable ?? defaultGridObj.gutters.enable,
    value: gridObj?.gutters?.value ?? defaultGridObj.gutters.value,
  }

  return {
    columnsTotal,
    gutters,
  }
}

export function mergeBob3(
  gridObj: GridOpt | undefined,
  gridDesktopBehaviour: GridOpt | undefined,
  gridDefaultBreakpoint: GridOpt | undefined,
  defaultGridObj: Grid
): { columnsTotal: Grid["columnsTotal"]; gutters: Grid["gutters"] } {
  const columnsTotal =
    gridObj?.columnsTotal ??
    gridDesktopBehaviour?.columnsTotal ??
    gridDefaultBreakpoint?.columnsTotal ??
    defaultGridObj.columnsTotal
  const gutters = {
    enable:
      gridObj?.gutters?.enable ??
      gridDesktopBehaviour?.gutters?.enable ??
      gridDefaultBreakpoint?.gutters?.enable ??
      defaultGridObj.gutters.enable,
    value:
      gridObj?.gutters?.value ??
      gridDesktopBehaviour?.gutters?.value ??
      gridDefaultBreakpoint?.gutters?.value ??
      defaultGridObj.gutters.value,
  }

  return {
    columnsTotal,
    gutters,
  }
}

export function renderOpt(gridObj?: GridOpt): Partial<GridCSS> {
  let gridProps = {}
  if (gridObj?.gutters?.enable && gridObj.gutters.value) {
    gridProps = {
      "--bs-gap": gridObj.gutters.value,
    }
  } else {
    gridProps = {
      "--bs-gap": 0,
    }
  }
  if (gridObj?.columnsTotal) {
    gridProps = {
      ...gridProps,
      "--bs-columns": `${gridObj.columnsTotal}`,
    }
  }
  return gridProps
}

export type { GridProperties, StylesGrid, Grid, GridOpt }
