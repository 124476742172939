import React, { useEffect, useRef, useState } from "react"
// @ts-ignore
import Style from "style-it"
import { handlePlaceholderStyles, handleBackgroundStyles, handleTextStyles } from "../../../../bob/bobFormComponents"
import BobComponentHandler from "../../../../bob/bobComponentHandler"
import styled from "styled-components"
import { responsiveProperties, responsiveGroupStylesProperties } from "../../../../bob/bobHandler"
import { TextInputProps, ResponsiveCSSProps } from "./interface"
import { formInput } from "../../../../analytics/analytics"
import { conditionalColorsRenderUnsafe as cssRenderUnsafeColors } from "../../../../../../../modules/shared-modules/utilities/conditionalController"
import { conditionalBorderRenderUnsafe as cssRenderUnsafeBorder } from "../../../../../../../modules/shared-modules/utilities/conditionalController"
import { cssRenderUnsafe as cssRenderUnsafeColor } from "../../../../bob/bobStyles/colorStyle"

const TextInputElement = styled.div`
  ${(props: ResponsiveCSSProps) => props.desktop}
  ${(props: ResponsiveCSSProps) => handlePlaceholderStyles(props.desktop)}
  @media only screen and (max-width: 992px) and (min-width: 767px) {
    ${(props: ResponsiveCSSProps) => props.tablet}
    ${(props: ResponsiveCSSProps) => handlePlaceholderStyles(props.tablet)}
  }
  @media all and (max-width: 766px) {
    ${(props: ResponsiveCSSProps) => props.mobile}
    ${(props: ResponsiveCSSProps) => handlePlaceholderStyles(props.mobile)}
  }
`

const TextInput: React.FC<TextInputProps> = (props): JSX.Element => {
  const [value, setValue] = useState<string>(props.inputObj.value ?? "")
  const isInitialMount = useRef(true)

  let timer: any = null

  useEffect(() => {
    return () => clearTimer()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (props.delay) {
        // eslint-disable-next-line
        timer = setTimeout(() => props.handleValueChange(value), props.delay)
      } else props.handleValueChange(value)
    }
  }, [value])

  const clearTimer = () => {
    if (timer) clearTimeout(timer)
  }

  const handleValueChange = (value: string) => {
    clearTimer()
    setValue(value)
  }

  const handleErrorBorder = () => {
    if (props.error)
      return {
        border: "2px solid red",
      }
    return {}
  }

  let renderErrorBorder = handleErrorBorder()
  let backgroundStyles: any = handleBackgroundStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles)
  let textStyles: any = handleTextStyles(props.bobStyle.bobInput, props.pageResponse.globalStyles, props.expManager)

  let styles = {
    ...backgroundStyles,
    ...textStyles.styles,
    ...renderErrorBorder,
  }
  let mobileStyles = responsiveProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", undefined, [
    "colors",
  ])
  mobileStyles = {
    ...mobileStyles,
    ...responsiveGroupStylesProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "text"),
    ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "default", true),
    ...cssRenderUnsafeColor(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "mobile",
      "default",
      undefined,
      undefined,
      true
    ),
    ...cssRenderUnsafeBorder({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "mobile", "default", true),
  }
  let tabletStyles = responsiveProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", undefined, [
    "colors",
  ])
  tabletStyles = {
    ...tabletStyles,
    ...responsiveGroupStylesProperties({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "text"),
    ...cssRenderUnsafeColors({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "default", true),
    ...cssRenderUnsafeColor(
      { ...props.bobStyle.text, ...props.bobStyle.bobInput },
      "tablet",
      "default",
      undefined,
      undefined,
      true
    ),
    ...cssRenderUnsafeBorder({ ...props.bobStyle.text, ...props.bobStyle.bobInput }, "tablet", "default", true),
  }
  return (
    <Style>
      {`
      ${textStyles.fontLoaded}          
    `}
      <div className='form-group'>
        <BobComponentHandler
          position={props.position}
          pageResponse={props.pageResponse}
          instanceId={props.obj.selectedInstanceId}
          inlineContent={props.obj.content.inlineContent}
          overrides={props.overrides}
          object={props.obj}
          objectId={props.obj.uuid}
          field={props.bobStyle.text.name}
          tag='label'
          value={props.inputObj.label || props.inputObj.name}
          template={{ ...props.obj.styles }}
          rules={props.componentRule}
          expManager={props.expManager}
          network={props.obj.network}
        />
        <TextInputElement
          as='input'
          type='text'
          value={value}
          desktop={styles}
          mobile={mobileStyles}
          tablet={tabletStyles}
          placeholder={props.inputObj.label || props.inputObj.name}
          onChange={(e: any) => handleValueChange(e.target.value)}
          className='form-control'
          onFocus={(e: any) => {
            const formName = e.target?.form?.name
            if (formName) {
              formInput(e, formName)
            }
          }}
        />
        {props.error && (
          <p className='simple-form-error'>{props.lang === "en" ? "This field is required" : "Campo obrigatório"}</p>
        )}
      </div>
    </Style>
  )
}

export default TextInput
