import { isRight } from 'fp-ts/lib/Either'
import {type, union, literal, Type, TypeOf, intersection, null as nullC, partial, boolean as booleanC} from 'io-ts'
import { BehaviourState_, Breakpoint } from '../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes'
import { get2WithNull } from "../bobUtils"
import { decoderErrors } from "../codec/codecUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

// enable: true
// horizontal: "right"
// vertical: "center"
const VerticalCodec = union([literal("top"), literal("center"), literal("bottom")])
const HorizontalCodec = union([literal("left"), literal("center"), literal("right")])
const AlignmentPropsCodec = type({ vertical: VerticalCodec, horizontal: HorizontalCodec })
const AlignmentCodec = intersection([type({ enable: booleanC }), AlignmentPropsCodec])

const AlignmentPropsOptCodec = partial({ vertical: nullable(VerticalCodec), horizontal: nullable(HorizontalCodec) })
const AlignmentOptCodec = intersection([partial({ enable: nullable(booleanC) }), AlignmentPropsOptCodec])

const StylesAlignmentCodec = intersection([
  type({ alignment: AlignmentCodec }),
  partial({
    behaviour: partial({
      active: partial({ alignment: AlignmentOptCodec }),
      hover: partial({ alignment: AlignmentOptCodec }),
    }),
    mobile: intersection([
      partial({ alignment: AlignmentOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ alignment: AlignmentOptCodec }),
          hover: partial({ alignment: AlignmentOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ alignment: AlignmentOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ alignment: AlignmentOptCodec }),
          hover: partial({ alignment: AlignmentOptCodec }),
        }),
      }),
    ]),
  }),
])

// const GSAlignmentCodec = intersection(
//   [
//     type({ alignment: AlignmentPropsCodec }),
//     partial({
//       behaviour: partial({
//         active: partial({ alignment: AlignmentPropsOptCodec }),
//         hover: partial({ alignment: AlignmentPropsOptCodec })
//       }),
//       mobile: intersection(
//         [
//           partial({ alignment: AlignmentPropsOptCodec }),
//           partial({
//             behaviour: partial({
//               active: partial({ alignment: AlignmentPropsOptCodec }),
//               hover: partial({ alignment: AlignmentPropsOptCodec })
//             })
//           })
//         ]
//       ),
//       tablet: intersection([
//         partial({ alignment: AlignmentPropsOptCodec }),
//         partial({
//           behaviour: partial({
//             active: partial({ alignment: AlignmentPropsOptCodec }),
//             hover: partial({ alignment: AlignmentPropsOptCodec })
//           })
//         })
//       ])
//     })
//   ])

type Alignment = TypeOf<typeof AlignmentCodec>
type AlignmentProps = TypeOf<typeof AlignmentPropsCodec>
type AlignmentOpt = TypeOf<typeof AlignmentOptCodec>
//type AlignmentPropsOpt = TypeOf<typeof AlignmentPropsOptCodec>
type StylesAlignment = TypeOf<typeof StylesAlignmentCodec>
// type GSAlignment = TypeOf<typeof GSAlignmentCodec>
type AlignmentCSS = {
  "justify-content": string
  "align-items": string
  "align-self": string
  display: string
  "flex-direction": string
}
type ComponentSpecificAlignment = {
  flexDirection?: "column" | "row"
  self?: boolean
}

export function AlignmentResponsiveStyle(alignmentObj: any, optionalProperties: any) {
  if (!alignmentObj.enable) return {}

  let horizontalValue =
    alignmentObj.horizontal === "left"
      ? "flex-start !important"
      : alignmentObj.horizontal === "right"
      ? "flex-end !important"
      : alignmentObj.horizontal + " !important"
  let verticalValue =
    alignmentObj.vertical === "top"
      ? "flex-start !important"
      : alignmentObj.vertical === "bottom"
      ? "flex-end !important"
      : alignmentObj.vertical + " !important"
  return {
    "justify-content": optionalProperties.flexDirection === "column" ? verticalValue : horizontalValue,
    "align-items": optionalProperties.flexDirection === "column" ? horizontalValue : verticalValue,
    "align-self":
      optionalProperties.self && optionalProperties.flexDirection === "column" ? horizontalValue : verticalValue,
    display: "flex !important",
    "flex-direction": optionalProperties.flexDirection && optionalProperties.flexDirection + " !important",
  }
}

export function AlignmentCssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  objectSpecific: ComponentSpecificAlignment
): AlignmentCSS {
  const styles = StylesAlignmentCodec.decode(stylesObj)
  if (isRight(styles)) return AlignmentCssRender(styles.right, breakpoint, behaviourState, objectSpecific)
  console.warn(`breakpoint: ${breakpoint} / behaviourState: ${behaviourState}`, decoderErrors(styles))
  return {
    "justify-content": "center !important", //flex-start
    "align-items": "center !important", //stretch
    "align-self": "center !important", //auto
    display: "flex !important",
    "flex-direction": `${objectSpecific.flexDirection} !important`,
  }
}

export function AlignmentCssClassRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  objectSpecific: ComponentSpecificAlignment
): string {
  const styles = StylesAlignmentCodec.decode(stylesObj)
  if (isRight(styles)) return AlignmentCssClassRender(styles.right, breakpoint, behaviourState, objectSpecific)
  console.warn(decoderErrors(styles))
  return `d-flex justify-content-center align-items-center align-self-center flex-${objectSpecific.flexDirection}`
}

export function AlignmentCssRender(
  stylesObj: StylesAlignment,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  objectSpecific: ComponentSpecificAlignment
): AlignmentCSS {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return AlignmentRenderBob(stylesObj.alignment, objectSpecific)
    }
    //hover | active
    else {
      return AlignmentRenderBob(
        AlignmentMergeBob2(stylesObj?.behaviour?.[behaviourState]?.alignment, stylesObj.alignment),
        objectSpecific
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return AlignmentRenderBob(
        AlignmentMergeBob2(stylesObj?.[breakpoint]?.alignment, stylesObj.alignment),
        objectSpecific
      )
    }
    //hover | active
    else {
      return AlignmentRenderBob(
        AlignmentMergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.alignment,
          stylesObj?.behaviour?.[behaviourState]?.alignment,
          stylesObj?.[breakpoint]?.alignment,
          stylesObj.alignment
        ),
        objectSpecific
      )
    }
  }
}
export function AlignmentCssClassRender(
  stylesObj: StylesAlignment,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  objectSpecific: ComponentSpecificAlignment
): string {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return AlignmentRenderBobClass(stylesObj.alignment, objectSpecific, breakpoint)
    }
    //hover | active
    else {
      return AlignmentRenderBobClass(
        AlignmentMergeBob2(stylesObj?.behaviour?.[behaviourState]?.alignment, stylesObj.alignment),
        objectSpecific,
        breakpoint
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return AlignmentRenderBobClass(
        AlignmentMergeBob2(stylesObj?.[breakpoint]?.alignment, stylesObj.alignment),
        objectSpecific,
        breakpoint
      )
    }
    //hover | active
    else {
      return AlignmentRenderBobClass(
        AlignmentMergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.alignment,
          stylesObj?.behaviour?.[behaviourState]?.alignment,
          stylesObj?.[breakpoint]?.alignment,
          stylesObj.alignment
        ),
        objectSpecific,
        breakpoint
      )
    }
  }
}

export function AlignmentRenderBob(alignmentObj: Alignment, objectSpecific: ComponentSpecificAlignment): AlignmentCSS {
  if (!alignmentObj.enable) {
    //See https://fluxio.atlassian.net/wiki/spaces/DEV/pages/133464172/Alignment+changes
    //Browser defaults should be the ones being applied, instead of custom ones
    return {
      "justify-content": "center !important", //flex-start
      "align-items": "center !important", //stretch
      "align-self": "center !important", //auto
      display: "flex !important",
      "flex-direction": `${objectSpecific.flexDirection} !important`,
    }
  }

  return AlignmentRender(alignmentObj, objectSpecific)
}

export function AlignmentRenderBobClass(
  alignmentObj: Alignment,
  objectSpecific: ComponentSpecificAlignment,
  breakpoint: Breakpoint
): string {
  if (!alignmentObj.enable) {
    //See https://fluxio.atlassian.net/wiki/spaces/DEV/pages/133464172/Alignment+changes
    //Browser defaults should be the ones being applied, instead of custom ones
    const breakpointClass = AlignmentRenderBreakpointClass(breakpoint)
    return `d${breakpointClass}-flex justify-content${breakpointClass}-center align-items${breakpointClass}-center align-self${breakpointClass}-center flex${AlignmentRenderBreakpointClass(
      breakpoint
    )}-${objectSpecific.flexDirection}`
  }

  return AlignmentRenderClass(alignmentObj, objectSpecific, breakpoint)
}

export function AlignmentRender(
  alignmentObj: AlignmentProps,
  objectSpecific: ComponentSpecificAlignment
): AlignmentCSS {
  const horizontalValue =
    alignmentObj.horizontal === "left"
      ? "flex-start !important"
      : alignmentObj.horizontal === "right"
      ? "flex-end !important"
      : alignmentObj.horizontal + " !important"
  const verticalValue =
    alignmentObj.vertical === "top"
      ? "flex-start !important"
      : alignmentObj.vertical === "bottom"
      ? "flex-end !important"
      : alignmentObj.vertical + " !important"
  return {
    "justify-content": objectSpecific.flexDirection === "column" ? verticalValue : horizontalValue,
    "align-items": objectSpecific.flexDirection === "column" ? horizontalValue : verticalValue,
    "align-self": objectSpecific.self
      ? objectSpecific.flexDirection === "column"
        ? horizontalValue
        : verticalValue
      : "auto !important",
    display: "flex !important",
    "flex-direction": `${objectSpecific.flexDirection} !important`,
  }
}
export function AlignmentRenderClass(
  alignmentObj: AlignmentProps,
  objectSpecific: ComponentSpecificAlignment,
  breakpoint: Breakpoint
): string {
  const breakpointClass = AlignmentRenderBreakpointClass(breakpoint)
  const horizontalValue =
    alignmentObj.horizontal === "left" ? "start" : alignmentObj.horizontal === "right" ? "end" : alignmentObj.horizontal
  const verticalValue =
    alignmentObj.vertical === "top" ? "start" : alignmentObj.vertical === "bottom" ? "end" : alignmentObj.vertical
  const justifyContent = `justify-content${breakpointClass}-${
    objectSpecific.flexDirection === "column" ? verticalValue : horizontalValue
  }`
  const alignItems = `align-items${breakpointClass}-${
    objectSpecific.flexDirection === "column" ? horizontalValue : verticalValue
  }`
  const alignSelf = `align-self${breakpointClass}-${
    objectSpecific.self ? (objectSpecific.flexDirection === "column" ? horizontalValue : verticalValue) : "auto"
  }`
  const flexDirection = `flex${breakpointClass}-${objectSpecific.flexDirection}`
  return `d${breakpointClass}-flex ${flexDirection} ${alignSelf} ${alignItems} ${justifyContent}`
}

export function AlignmentRenderBreakpointClass(breakpoint: Breakpoint): string {
  if (breakpoint === "desktop") return ""
  if (breakpoint === "tablet") return "-md"
  return "-sm"
}

export function AlignmentMergeBob2(alignmentObj: AlignmentOpt | undefined, defaultAlignmentObj: Alignment): Alignment {
  const enable = get2WithNull(alignmentObj?.enable, defaultAlignmentObj.enable)
  const vertical = get2WithNull(alignmentObj?.vertical, defaultAlignmentObj.vertical)
  const horizontal = get2WithNull(alignmentObj?.horizontal, defaultAlignmentObj.horizontal)

  return {
    enable,
    vertical,
    horizontal,
  }
}

export function AlignmentMergeBob3(
  alignmentObj: AlignmentOpt | undefined,
  alignmentDesktopBehaviour: AlignmentOpt | undefined,
  alignmentDefaultBreakpoint: AlignmentOpt | undefined,
  defaultAlignmentObj: Alignment
): Alignment {
  const enable =
    alignmentObj?.enable ??
    alignmentDesktopBehaviour?.enable ??
    alignmentDefaultBreakpoint?.enable ??
    defaultAlignmentObj.enable
  const vertical =
    alignmentObj?.vertical ??
    alignmentDesktopBehaviour?.vertical ??
    alignmentDefaultBreakpoint?.vertical ??
    defaultAlignmentObj.vertical
  const horizontal =
    alignmentObj?.horizontal ??
    alignmentDesktopBehaviour?.horizontal ??
    alignmentDefaultBreakpoint?.horizontal ??
    defaultAlignmentObj.horizontal

  return {
    enable,
    vertical,
    horizontal,
  }
}

export type { ComponentSpecificAlignment, AlignmentCSS }