import React from "react"
import Image from "next/image"

interface PageLoaderImageSplitProps {
  options: {
    enable: boolean
    delay: number | undefined
    media: string | undefined
    code: string | undefined
  }
}

const PageLoaderImageSplit = (props: PageLoaderImageSplitProps) => {
  return props.options.media ? (
    <Image
      priority={true}
      loader={() => props.options.media as string}
      src={props.options.media}
      alt='loading'
      width='100px'
      height='100px'
    />
  ) : (
    <></>
  )
}

export default React.memo(PageLoaderImageSplit)
